import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Camera from '@material-ui/icons/Camera';
import MessagingFeedEntry00 from '../../visualization/components/MessagingFeedEntry00'
import UsaIcon from '../../usa/components/UsaIcon'
import UsaTextArea00 from '../../usa/components/UsaTextArea00'
import {cl, globs, constant, getTime, getRandomString} from '../../components/utils/utils';
import {wsTrans, doGetPostBasic} from '../../usa/utils/utils'

class MessageReply00 extends React.Component{
  
  constructor(props) {
    super(props);
    this.state={
      body:"",
      images:[],
    }
  }
  
  saveReply=async()=>{
    let images=[]
    this.state.images.forEach(img=>{
      images.push({name: img.image.name, id: img.id, size: img.image.size})
    })
    await this.sendImages()
    let e=this.props.parms.entry
    cl(e)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: {// the messageId will be added by the server
        to: e.fromId,
        subject: e.subject,
        threadId: e.threadId, //getRandomString(16),
        body: this.state.body,
        modTime: Math.floor(getTime()),//Math.floor(this.state.dispTime.getTime()/1000),
        images: images,
    }})
    return this.props.onChange({msgReply:false})
  }
  
  closeReply=()=>{
//     let vals={gjReply:false}
    return this.props.onChange({msgReply:false})
  }
  
  result=(type)=>{
    cl(type)
    switch (type){
      case "reply-OK":
        this.saveReply()
        break
      case "reply-Cancel":
        this.closeReply()
        break
      default:
        break
    }
  }
  
  onChange=(type, vals)=>{
    this.setState(vals)
    return this.props.onChange(vals)
  }
  
/****************** Added to accommodate images **************************************/  
  
  markImage=(e)=>{
    let expImage={id: getRandomString(16), image: e.target.files[0]}// expanded image, w/ our name
    let images=this.state.images.slice(0)
    images.push(expImage)
    cl(images)
    cl(expImage.id)
    this.setState({body: this.state.body+`{File:${expImage.id}}`, images: images})
  }
  
  sendImages=()=>{
    let data = new FormData()
    this.state.images.forEach((img,i)=>{
      data.append(`id${i}`, img.id)
      data.append(`file${i}`, img.image)
    })
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl(url)
    return doGetPostBasic(url, method, data, type)// promise
  }
  
/****************** end Added to accommodate images **************************************/  
  showReply=(height, width)=>{
    let e=this.props.parms.entry
    return(
      <div>
        <div style={{height: height, width: width, overflowY: "auto"}}>
          <MessagingFeedEntry00 
            parms={{entry: e, reply: true, avatars: this.props.parms.avatars}}
            onChange={vals=>this.onChange("gjEntry", vals)}
            />
        </div>
        <div style={{height: 20}}/>
        <UsaTextArea00 parms={{
          height: 130, 
          width: width-20,
          value: this.state.body,
          valueId: "body",
          onChange: v=>{this.onChange("reply", v)}
        }}/>
      <UsaIcon icon={"reply-OK"} result={this.result}/>
      <UsaIcon icon={"reply-Cancel"} result={this.result}/>
      <IconButton style={{padding: 0}}
        variant="contained"
        component="label"
        title="Insert Image"
      >
        <Camera/>
        <input hidden multiple type="file" onChange={this.markImage}/>
      </IconButton>
      
      </div>
    )
  }
  
  render(){
    let p=this.props.parms.pos
    let color=this.props.parms.color
    return(
      <div style={{position: "absolute", left: p.x, top: p.y, width: p.w, height: p.h+200, textAlign: "center", borderRadius: 10, backgroundColor: color,
        overflowY: "auto", zIndex: 1,
      }} 
        >
        {this.showReply(p.h, p.w)}
      </div>
    )
  }
}

export default MessageReply00;
