import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import C18SubMenuHeader00 from './C18SubMenuHeader00'
import {wsTrans,getParamId2} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadAccountInfo,loadGatewaysInfo,getGatewayInfo,
  getGatewayIndex, addToAdminLog, getZoneInfo,loadSiteData} from './C18utils'
import {cl, globs, constant,getTime,getTimeI, copyToClipboard,
  dateToDisplayDate,
} from '../../components/utils/utils';
import {openWS,sendSocket} from '../../components/utils/ws';
import {pInd} from '../../components/utils/paramIds'
import {dbVals,sendArrayNowPromise} from '../../components/utils/http';
import history from "../../history"

const progressSize=300

class C18ManageGateways extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded: false,
      addName:"",
      addId:"",
      gatewayIdInfo:"",
      downloadProgress:0,
      progressMsg:"Resetting Controller",
      fwVers:"1.12.12",
      fwDest:"flash",
      fwSel:"",
      gwidOverride:false,
      throttlePacks:false,
      forceFdr:false,
      pageType:"basic",
      icGateway: true,
      startingOffset:0,
      updTime:0,
      log2Data:{},
      udpOta:true,
//       smallBlocks:false,
    }
    this.subscribe_newData=globs.events.subscribe("data",this.newData)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveGateways)
    this.subscribe_toClientEvent=globs.events.subscribe("toClient",this.toClient)
    this.subscribe_toKeyUp=globs.events.subscribe("keyUp",this.keyUp)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
    this.createCanvasImage()
//     this.openRtdWS()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Gateways", url:`/usa/c18/admin/manageGateways2`},
            ]},
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
    this.subscribe_toClientEvent.remove()
    this.subscribe_newData.remove()
    this.subscribe_toKeyUp.remove()
    clearInterval(this.updateInterval)
    this.closeRtdWs()
  }

  sendKey=async(keyVal)=>{
    let st=this.state
    let button = document.getElementById(`rc-btn-${keyVal}`)
    window.setTimeout(function() {
      button.blur();        
    },500);
    let query={gatewayId:st.gatewaySel,
      cmd:"multiCmd",
      subCmd:"sendKey",
      keyVal:keyVal,
    }
    // get rid of focus
//     cl(query)
//     let res=await
    wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
      sessionId: globs.userData.session.sessionId, body:
      query})
//     cl(res)

  }

  keyUp=(cmd)=>{
    let st=this.state
    let mapKeys={
      "q":4,
      "w":5,
      "e":6,
      "a":1,
      "s":2,
      "d":3,
      "Escape":4,
      "Backspace":4,
      "Delete":4,
      "PageDown":6,
      "Enter":6,
      "ArrowUp":5,
      "ArrowLeft":1,
      "ArrowDown":2,
      "ArrowRight":3,
      "Shift":0,
      " ":0,
    }
    if(st.pageType=="remote"){
      let key=cmd.key
      if(key in mapKeys){key=mapKeys[key]}
//       cl(key)
      let val=+key
//       cl(val)
      if([0,1,2,3,4,5,6].includes(val)){
//         cl(val)
        // trigger appropriate button
        let button = document.getElementById(`rc-btn-${val}`)
        button.click();
        // button.focus();
        // window.setTimeout(function() {
        //   button.blur();        
        // },500);
        // this.sendKey(val)
      }
    }
  }
  
  loadNewAddedSensorGateways=async()=>{
    let query={
        $or:[
          {siteId:""},
          {accountId: globs.userData.session.accountId},
        ],
        gatewayType:"addedSensor"
      }
//     cl(query)
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId, body: query})
    let gws={}
    resp.data.forEach(re=>{
      gws[re.gatewayId]=re
    })
//     cl(gws)
    return gws
  }

//   loadPearlInfo=()=>{
//     let sites0={}
//     globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayType==1900})
//       .forEach(gw=>{sites0[gw.siteId]=1})
//     let siteIds=Object.keys(sites0)
//     cl(siteIds)
//   }

  updateGWs=async()=>{
    let st=this.state
    await loadGatewaysInfo(true)
    await this.checkAddInfo(st.addId)
//     cl(st.fwSel)// pa_dbg-v1.12.16.bin
    let parts0=st.fwSel.split("_v")
    var progressMsg=st.progressMsg
    if(parts0.length>1){
      let p1=parts0[1].split(".")
//       cl(p1)
      let p2=p1[2].split("_")
      let fwVers=`${p1[0]}.${p1[1]}.${p2[0]}`
      let gateway=globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==st.gatewaySel})[0]
//       cl(gateway)
      let fwVers2=gateway.fwVersion
//       cl(fwVers,fwVers2,fwVers==fwVers2)
      if(fwVers==fwVers2){progressMsg="Success!"}
//       let progressMsg=(fwVers==fwVers2)?"Success!":st.progressMsg
    }

//     this.loadPearlInfo()
    this.setState({update:(this.state.update||0)+1,progressMsg:progressMsg})
  }

  loadGatewayZoneSettings=(gwSel)=>{
    if(this.state.pageType!="intercontroller"){return}
    let zone=globs.zonesInfo.info.filter(z=>{return z.gatewayId==gwSel})[0]
    if(zone){
      let z=dbVals.z[zone.siteZoneIndex]
      let clientZoneAddrs=Object.keys([...Array(30)]).map(i=>{
        if(z){
          return +z[255]
          [this.interCont.zoneSetsAddrId+i*this.interCont.zoneSetsAddrMult]||0
        }
      })
      let isGW=(zone.commMode==3)
      this.mySetState({zoneAddrs:clientZoneAddrs,icGateway:isGW})
//       cl(clientZoneAddrs)
    }
  }

  loadSiteZoneInfo=async(siteSel)=>{
    if(this.state.pageType!="intercontroller"){return}
    let st=this.state
// these are the settings used for intercontroller
    if(st.pageType=="intercontroller"){
      await loadSiteData(siteSel)
//       cl("loaded ",siteSel)
      this.interCont={
        commModeId:getParamId2("1900","configuration_zones","comm_mode"),
        zoneSetsAddrId:getParamId2("1900","pearl_zone_config","RS485_Address"),
        zoneSetsNameId:getParamId2("1900","pearl_zone_config","Name"),

        zoneContAddrId:getParamId2("1900","pearl_rs485_config","Address"),
        zoneSetsAddrMult:pInd[1900].pearl_zone_config[2],
        zoneContAddrMult:pInd[1900].pearl_rs485_config[2],
      }
      let siteZones=globs.zonesInfo.info.filter(z=>{return z.siteId==siteSel})
//       cl(dbVals)
//       cl(this.interCont.zoneContAddrId+2*this.interCont.zoneContAddrMult);
//       cl(Array(5))
//       cl(Object.keys(Array(5)))
//       cl(Object.keys([...Array(5)]))
      siteZones.forEach(z=>{
        if(z[z.siteZoneIndex]){
          z.commMode=dbVals.z[z.siteZoneIndex][255][this.interCont.commModeId]||0
          z.zoneAddr=dbVals.z[z.siteZoneIndex][240]
            [this.interCont.zoneContAddrId+2*this.interCont.zoneContAddrMult]||0
        }
      })
      siteZones=siteZones.sort((a,b)=>{
        if(a.siteZoneIndex>b.siteZoneIndex){return 1}
        if(a.siteZoneIndex<b.siteZoneIndex){return -1}
        return 0
      })
      this.mySetState({siteZones:siteZones})
//       cl(this.interCont)
//       cl(siteZones)
    }
  }
  
  loadInfo=async()=>{
    await loadAccountInfo()
    await loadSitesInfo()
    await loadZonesInfo()
    await loadGatewaysInfo()
    await this.updateGWs()
    this.updateInterval=setInterval(e=>{
      this.updateGWs()
//       loadGatewaysInfo(true)
//       this.loadPearlInfo()
//       this.setState({update:(this.state.update||0)+1})
    },20000)
    await this.loadFirmwares()
    let asGateways=await this.loadNewAddedSensorGateways()
//     cl(asGateways)
    this.saveGatewayNames(false)
//     cl(this.gatewayNames)
//     cl(globs.gatewaysInfo.info)
//     await loadZonesInfo()
//     this.saveZoneNames(false)// save the names to compare later
    let siteSel=(globs.sitesInfo.info[0]||{}).siteId
    await this.loadSiteZoneInfo(siteSel)
    let gwInfo=this.getGatewaySel(siteSel)
    this.loadGatewayZoneSettings(gwInfo?.gatewaySel)
    var zoneSel
    // if(gwInfo){zoneSel=this.getZoneSel(gwInfo.gatewaySel)}
    // if(!zoneSel?.length)siteSel=null
    
//     cl(gatewaySel)
//     Object.assign(vals,{gatewaySel:this.getGatewaySel(vals.siteSel)})
//     let zoneSel=this.getZoneSel(siteSel)
    Object.assign(gwInfo||{},{loaded:true, siteSel:siteSel,zoneSel:zoneSel,
      asGateways:asGateways})
//     cl(gwInfo)
    this.setState({loaded:true, siteSel:siteSel,zoneSel:zoneSel,
      asGateways:asGateways})
//     cl(globs)
  }
  
  mySetState=(vals)=>{
//     cl("setting state")
    this.setState(vals)
  }
  
  getGatewaySel=(siteId)=>{
    let ggi=globs.gatewaysInfo.info
    for(let i=0;i<ggi.length;i++){
      if(ggi[i].siteId==siteId){
        let gw=ggi.filter(gw=>{return gw.gatewayId==ggi[i].gatewayId})[0]
//         cl(gw)
        return{gatewaySel:gw.gatewayId,isAddedSensor:gw.gatewayType=="addedSensor"}
//         cl(gw)
//         return ggi[i].gatewayId
      }
    }
  }
  
  getZoneSel=(gatewayId)=>{
    if(!gatewayId){return}
    let gw=(globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==gatewayId})[0])||{}
    if(gw.gatewayType=="addedSensor"){
      return gw.zoneId
    }else{
//       cl(gatewayId)
//       cl(globs.zonesInfo.info)
      let z = {}
      z = globs.zonesInfo.info.filter(
        zi=>{
          return zi.gatewayId==gatewayId
        })[0]
      return z?.zoneId
    }
//       return globs.zonesInfo.info.filter(
//         zi=>{
//           return zi.gatewayId==(((gatewayId[0]||{}).zoneId)||0)
//         })    }
  }

  saveToAdminLog=(adds,o,n)=>{
//     cl(o)
//     cl(n)
    let addObj={
      userId:globs.userData.session.userId,
      siteId:o.siteId,
      gatewayId: o.gatewayId,
      time:Math.floor(getTime())
    }
    if (n.type == "addGateway" || n.type == "deleteGateway") {
      adds.push(
        Object.assign({},addObj,
        {
        action: n.type,
        oldVal: "",
        newVal: n.name,
      }))
      return
    }
    if(o.name!=n.name){
      adds.push(
        Object.assign({},addObj,
        {
        action:"gatewayName",
        oldVal:o.name,
        newVal:n.name,
      }))
    }
  }
  
  saveGateways=(cmd)=>{
//     cl(cmd)
    if(cmd=="save"){
      if(this.state.pageType=="intercontroller"){return this.icSave()}
      this.saveGatewayNames(true)// save to db
    }
//     history.goBack()
  }
  
  saveGatewayNameToDb=async(upd)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "update", 
      sessionId: globs.userData.session.sessionId, body: upd
//       {
//         gatewayId: id,
//         name: name,
//       }
    })
    globs.events.publish("saveOK",true)
  }
  
  saveGatewayNames=(doSave)=>{// do Save, means save to db, not means copy current settings
    if(!doSave){this.gatewayNames={}}
    let ggi=globs.gatewaysInfo.info
//     cl(ggi)
    let adminAdds=[]
    let st=this.state
    for(let i=0;i<ggi.length;i++){
      if(doSave){
        let newZoneId=(ggi[i].gatewayId==st.gatewaySel)&&(ggi[i].zoneId!=st.zoneSel)
//         cl(this.gatewayNames[ggi[i].gatewayId].resetOnDisc,ggi[i].resetOnDisc)
        if((this.gatewayNames[ggi[i].gatewayId].name!=ggi[i].name)||
          (newZoneId)||
          this.gatewayNames[ggi[i].gatewayId].resetOnDisc!=ggi[i].resetOnDisc
        ){
          let upd={
            gatewayId:ggi[i].gatewayId,
            name:this.gatewayNames[ggi[i].gatewayId].name,
            resetOnDisc:this.gatewayNames[ggi[i].gatewayId].resetOnDisc,
          }
          if(newZoneId){upd.zoneId=st.zoneSel}
//           cl(upd)
          this.saveGatewayNameToDb(upd)
          this.saveToAdminLog(adminAdds,ggi[i],{name: this.gatewayNames[ggi[i].gatewayId].name})
          ggi[i].name=this.gatewayNames[ggi[i].gatewayId].name
          if(newZoneId){ggi[i].zoneId=st.zoneSel}
        }
      }else{
        this.gatewayNames[ggi[i].gatewayId]=Object.assign({},ggi[i])//.name
      }
    }
//     cl(this.gatewayNames)
//     cl(adminAdds)
    if(doSave){addToAdminLog(adminAdds)}
    globs.events.publish("saveOK",true)
  }
  
//   addGateway=(id,name)=>{
//
//   }

  replaceGateway=async(gwOld,gwNew)=>{
    cl("replace")
    cl(gwOld,gwNew)
    let st=this.state
    let res=await this.props.parms.getPopup({
      text:"Are you sure you want to replace this gateway?",
      buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      if(this.rgAddGateway(gwNew)){// if not on another account
        this.rgDeleteGateway(gwOld)
        this.rgUpdateZones(gwOld, gwNew)
      }
    }
  }

  rgUpdateZones=async(gwOld,gwNew)=>{
    let query={gatewayId:gwOld,newGwId:gwNew}
      await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update",
        sessionId: globs.userData.session.sessionId, body:query})
  }

  rgDeleteGateway=async(gwId)=>{
    let st=this.state
    globs.gatewaysInfo.info.splice(getGatewayIndex(st.gatewaySel),1)// remove local
    delete this.gatewayNames[st.gatewaySel]
    let gwInfo=this.getGatewaySel(st.siteSel)// new selected gw
// setting acctId="" also removes data from mqtt on server
    await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "update",
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:st.gatewaySel, siteId:"", name:"",accountId:""}})
    this.mySetState(gwInfo)// new selected gw
  }

  rgAddGateway=async(gwId)=>{
    let st=this.state
    let g={
      accountId: globs.userData.session.accountId,
      siteId: st.siteSel,
      gatewayId: st.addId,
      name: "Replacement Gateway",
    }
    // checks if gw is on another account - also, reads data
    let res=await this.updateGateway(g)
    if(res){
      globs.gatewaysInfo.info.push(g)
      this.gatewayNames[g.gatewayId].name=g.name
      this.mySetState({addName:"",addId:"",gatewaySel:gwId})
    } else{return false}
  }

  deleteGateway=async(gw)=>{// this should remove the site and account info from the gateway
// why not just delete it? because it was created by the controller, not us!
// also need to message mqtt to remove zones from memory array for gw
//     cl(gw)
    let st=this.state
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this gateway?", buttons:["Cancel","Yes"]})
    
    if(res=="Yes"){
//       cl(st.gatewaySel)
//       cl(globs.gatewaysInfo.info)
//       cl(getGatewayIndex(st.gatewaySel))
//       cl(globs.gatewaysInfo.info[getGatewayIndex(st.gatewaySel)])
      let name = this.gatewayNames[st.gatewaySel].name
//       cl(globs.gatewaysInfo.info.length)
      globs.gatewaysInfo.info.splice(getGatewayIndex(st.gatewaySel),1)
      globs.zonesInfo.info=globs.zonesInfo.info.filter(
        z=>{return z.gatewayId!=st.gatewaySel})

//       delete globs.gatewaysInfo.info[getGatewayIndex(st.gatewaySel)]
//       cl(globs.gatewaysInfo.info.length)
      delete this.gatewayNames[st.gatewaySel]
      let gwInfo=this.getGatewaySel(st.siteSel)
//       cl(gatewaySel)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "update", // remove the siteId and name from the gateway
        sessionId: globs.userData.session.sessionId, body: {gatewayId:st.gatewaySel, siteId:"", name:"",accountId:""}})
      await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "delete",
        sessionId: globs.userData.session.sessionId, body: {gatewayId:st.gatewaySel}})
      let adminAdds = []
      this.saveToAdminLog(adminAdds, {siteId: st.siteSel,gatewayId:st.gatewaySel}, {type: "deleteGateway", name: name})
//       cl(adminAdds)
      addToAdminLog(adminAdds)
      this.mySetState(gwInfo)
    }
  }

  updateGateway=async(gw)=>{// this is just used to add a gateway
//     cl(gw)
//     cl(this.state)
//     cl(this.props)
//     cl(globs.gatewaysInfo)
    let st=this.state
    let gotGW=globs.gatewaysInfo.info.filter(gWay=>{return gWay.gatewayId==gw.gatewayId})[0]
    if(gotGW){return false}
//     cl(gotGW)
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "update", 
      sessionId: globs.userData.session.sessionId, body: gw})
    // cl(resp)
    if(resp.result=="duplicateKey"){
        let res=await this.props.parms.getPopup({
          text:"That Gateway ID is already in use, on a different account. It was not added.\
 Ask the owner of that account to delete it there, and then you can add it here.", 
          buttons:["OK"]})
        return false
    }else{
      await this.connectToWS(gw.siteId)
      wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "retrieve",
        sessionId: globs.userData.session.sessionId, body:
        {gatewayId:gw.gatewayId,cmd:"readWholeController",}})//readZone0, readWholeController

    }
    return true
  }
  
//   checkAddedSensor=(gatewayId)=>{
//     cl(gatewayId)
//     if(this.state.asGateways[gatewayId]){}
//     
//   }

  validateGateway=(vals)=>{
//     if(c=="O"): c="0"
//     if(c=="Q"): c="0"
//     if(c=="I"): c="1"
//     if(c=="L"): c="1"
//     if(c=="S"): c="5"
//     if(c=="Z"): c="2"
//     if(c=="_"): c="A"
//     if(c=="-"): c="B"
    
    var doSimChars=(c)=>{
      let dups="0O0Q1I1L5S2Z8B"// 8B
      let pos=dups.indexOf(c)
      return(pos>=0)?dups[pos&0xFE]:c
    }
    let chars="0123456789ABCDEFGHJKMNPRTUVWXY"
    let gwId=Array.from(vals.addId.toUpperCase())
//     let gwId=vals.addId.toUpperCase()
    vals.gatewayIdInfo=""
    if(gwId.length&&(gwId.length!=16)){
      vals.gatewayIdInfo="A Gateway ID has 16 Characters"
    }else{
//       cl(this.state.gwidOverride)
      let tot=0
      vals.gatewayIdInfo=""
      for(let i=0;i<gwId.length;i++){
        let c=doSimChars(gwId[i])
        let pos=chars.indexOf(c)
        if(pos<0){
          vals.gatewayIdInfo="A Gateway ID only has Numbers and Letters"
          return
        }
//         if(!this.state.gwidOverride){gwId[i]=c}
        gwId[i]=c
        tot+=pos
      }
//       cl(tot)
      this.checkAddInfo(vals.addId)
//       if((tot%30)&&(!this.state.gwidOverride)){
      // this 
      if(tot%30){
        vals.gatewayIdInfo="Please Double Check: Gateway ID Could not be Validated"
      }else{
        if(gwId.length==16){
          cl("got 16")
          let gw=(globs.gatewaysInfo.info.filter(
            gw=>{return gw.gatewayId==gwId})[0])||{}
          cl(gw)
        }
      }
//       cl(tot)
    }
    gwId=gwId.join("")
    cl(gwId)
    vals.fixedAddId=gwId
//     return gwId
    
  }

  connectToWS=async(siteId)=>{
    await openWS(constant.wsUrl)
    let regWS={
      cmd:"regWS",
      s:siteId,
      sessionId: globs.userData.session.sessionId,
    }
    sendSocket(regWS)
  }
  
  updatePearlFirmware=async(fName)=>{
    cl("update pearl")
    let st=this.state
    await this.connectToWS(st.siteSel)
//     await openWS(constant.wsUrl)
//     let regWS={
//       cmd:"regWS",
//       s:st.siteSel,
//       sessionId: globs.userData.session.sessionId,
//     }
//
//     sendSocket(regWS)
    cl(st)
    let query={gatewayId:st.gatewaySel,
      cmd:"downloadFirmware",
      fwSel:st.fwSel,
      fwDest:st.fwDest,
      throttlePacks:st.throttlePacks,
      forceFdr:st.forceFdr,
      udpOta:st.udpOta,
      offset:+st.startingOffset,
    }
    cl(query)
    if(st.udpOta){
      delete query.fwDest
      delete query.throttlePacks
    }
    wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
      sessionId: globs.userData.session.sessionId, body:
      query})
  }
  
  flashPearlFirmware=async(fName)=>{
//     cl("flash pearl")
    let st=this.state
    if(!st.udpOta){return}
    await this.connectToWS(st.siteSel)
//     cl(st)
    let query={gatewayId:st.gatewaySel,
      cmd:"flashFirmware",
      bootloader:st.otaBootloader,
      offset:+st.startingOffset,
      forceFdr:st.forceFdr,
      fwSel:st.fwSel,
    }
//     if(st.udpOta){
//       delete query.fwDest
//       delete query.throttlePacks
//     }
    cl(query)
    wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
      sessionId: globs.userData.session.sessionId, body:
      query})
    let progressMsg="Flash and Reset"
    this.setState({downloadProgress:progressSize,progressMsg:progressMsg})
  }

  calcCRCPearlFirmware=async(fName)=>{
//     cl("flash pearl")
    let st=this.state
    if(!st.udpOta){return}
    clearInterval(this.updateInterval)
    await this.connectToWS(st.siteSel)
//     cl(st)
    let query={gatewayId:st.gatewaySel,
      cmd:"flashFirmware",
      calcCRC:true,
      bootloader:st.otaBootloader,
//       offset:st.startingOffset,
      forceFdr:st.forceFdr,
      fwSel:st.fwSel,
    }
//     if(st.udpOta){
//       delete query.fwDest
//       delete query.throttlePacks
//     }
    cl(query)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
      sessionId: globs.userData.session.sessionId, body:
      query})
    cl(res)
    let progressMsg="Calculating CRC on Pearl"
    this.setState({update:(this.state.update||0)+1,progressMsg:progressMsg,
      downloadProgress:progressSize,fileCRC:res.data.fileCRC})
  }

  onChange=async(type,vals)=>{
    let st=this.state
//     cl(st)
//     cl(type,vals)
//     cl(this.state.gwidOverride)
    if(!vals){vals={}}
    vals.gatewayIdInfo=""
    var gw
    switch(type){
//       case "siteSel":
//         cl(this.getGatewaySel(vals.siteSel))
//         Object.assign(vals,{gatewaySel:(this.getGatewaySel(vals.siteSel)||{}).gatewaySel})
//         Object.assign(vals,{zoneSel:this.getZoneSel(vals.gatewaySel),addName:"",addId:""})
// //         cl(vals)
//         this.mySetState(vals)
//         break
      case "siteSel":
//         cl(this.getGatewaySel(vals.siteSel))
        let gw0=this.getGatewaySel(vals.siteSel)||{}
        await this.loadSiteZoneInfo(vals.siteSel)
        this.loadGatewayZoneSettings(gw0.gatewaySel)
        gw =globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==gw0.gatewaySel})[0]
//         cl(gw)
        if(gw){
          Object.assign(vals,{gatewaySel:gw0.gatewaySel,gatewayType:gw.gatewayType})
          Object.assign(vals,{zoneSel:this.getZoneSel(vals.gatewaySel),addName:"",addId:""})
        }
        this.mySetState(vals)
        break
      case "gatewayName":
//         this.setGatewayName(st.gatewaySel,vals.gatewayName)
        globs.events.publish("savePageEnable",true)
        this.gatewayNames[st.gatewaySel].name=vals.gatewayName
        this.mySetState(vals)
        break
      case "resetOnDisc":
        globs.events.publish("savePageEnable",true)
        this.gatewayNames[st.gatewaySel].resetOnDisc=vals.resetOnDisc
        this.mySetState(vals)
        break
      case "gatewayAdd":// handles the text box
//         cl(st.asGateways)
//         cl(st)
        this.validateGateway(vals)
        if(vals.addId){
          vals.isAddedSensor= st.asGateways[vals.addId]?true:false
        }
        vals.gwidOverride=false
        cl(vals)
        this.mySetState(vals)
        cl(this.state.gwidOverride)
        break
      case "gatewaySel":
        gw =globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==vals.gatewaySel})[0]
//         cl(gw)
        this.loadGatewayZoneSettings(vals.gatewaySel)
        vals.log2Data={}
        await this.closeRtdWs()
        if(st.pageType=="rtd"){this.openRtdWS()}
        Object.assign(vals,{zoneSel:this.getZoneSel(vals.gatewaySel),
          isAddedSensor:gw.gatewayType=="addedSensor",addName:"",addId:"",
          clientId:gw.clientId,
          gatewayType:gw.gatewayType,resetOnDisc:gw.resetOnDisc||false
        })
        this.mySetState(vals)
        break
      case "zoneSel":
        globs.events.publish("savePageEnable",true)
        this.mySetState(vals)
        break
      case "addGateway":
        cl(st.gwidOverride,st.fixedAddId)
        let addId=(st.gwidOverride)?st.addId:st.fixedAddId
        cl(addId)
        let g={
          accountId: globs.userData.session.accountId,
          siteId: st.siteSel,
          gatewayId: addId,
//           name: st.addName,
          name: st.addName||"New Gateway",
        }
        if(st.isAddedSensor){
          g.zoneId=st.zoneSel
          g.gatewayType="addedSensor"
        }
        // cl(g)
//         cl(globs)
        let res=await this.updateGateway(g)
        // cl(res)
        if(res){
          globs.gatewaysInfo.info.push(g)
          // cl(this.gatewayNames)
          if (this.gatewayNames[g.gatewayId]) {
            this.gatewayNames[g.gatewayId].name=g.name
          } else {
            this.gatewayNames[g.gatewayId]=Object.assign({},g)//.name
          }
          Object.assign(vals,{addGw: {}, addName:"",addId:"",gatewaySel:addId})
          let adminAdds = []
          this.saveToAdminLog(adminAdds, g, {type: "addGateway", name: g.name})
          addToAdminLog(adminAdds)
          this.mySetState(vals)
        }
        break
      case "replaceGateway":
        cl(st.addId)
        this.replaceGateway(st.gatewaySel,st.fixedAddId)
        break
      case "deleteGateway":
        this.deleteGateway(st.gatewaySel)
        break
      default:
        break
      case "fwSel":
        this.mySetState(vals)
        break
      case "fwUpdate":
//         cl(vals)
        this.updatePearlFirmware(vals.fwSel)
        break
      case "fwFlash":
//         cl(vals)
        this.flashPearlFirmware(vals.fwSel)
        break
      case "fwCalcCRC":
        this.calcCRCPearlFirmware(vals.fwSel)
        break
      case "fwDest":
      case "throttlePacks":
      case "forceFdr":
      case "udpOta":
      case "otaBootloader":
      case "startingOffset":
//       case "smallBlocks":
        this.mySetState(vals)
        break
      case "pageMenu":
        if(vals.pageType=="intercontroller"){
          this.loadGatewayZoneSettings(st.siteSel)
        }
        if(vals.pageType=="remote"){
          this.piTimer=setInterval(this.updatePearlImage,200)
        }
        if(vals.pageType=="rtd"){
          vals.log2Data={}
          await this.setState(vals)// so pageType is right
          this.openRtdWS()
        }else{
          await this.closeRtdWs()
        }
        this.mySetState(vals)
        break
      case "icGateway":
        globs.events.publish("savePageEnable",true)
        this.mySetState(vals)
        break
      case "icConfigure":
        globs.events.publish("savePageEnable",true)
        this.icConfigure()
        break
      case 'copyValue':
        cl(vals)
        if(vals?.value === "undefined") vals.value = ""
        await copyToClipboard(vals.value);
        break;
    }
  }
  
  showSitesOpts=()=>{
    return globs.sitesInfo.info.map((s,i)=>{
      return(
        <option key={i} value={s.siteId}>{s.name}</option>
      )
    })
  }
  
  showZonesOpts=()=>{
    return globs.zonesInfo.info.filter(zi=>{return zi.siteId==this.state.siteSel}).map((z,i)=>{
      return(
        <option key={i} value={z.zoneId}>{z.zoneName}</option>
      )
    })
  }
  
  showGatewayOpts=()=>{
//     cl(globs)
    // cl(this.gatewayNames)
//     cl(globs.gatewaysInfo.info)
    return globs.gatewaysInfo.info.map((g,i)=>{
//       cl(g)
//       cl(this.gatewayNames[g.gatewayid])
      if(g.siteId==this.state.siteSel){
//         cl(g)
        return(
          <option key={i} value={g.gatewayId}>{this.gatewayNames[g.gatewayId]?.name||"No Name"}</option>
        )
      }
    })
  }
  
  showSelectSite=()=>{
    return (
      <div className="custom-select">
        <label htmlFor="">Select Site</label>
        <C18Select00 id=""
          parms={{list:true}}
          value={this.state.siteSel}
          onChange={e=>this.onChange("siteSel",{siteSel: e.currentTarget.value})}
        >
          {this.showSitesOpts()}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div> 
    )
  }
  
  showSelectZone=()=>{
    if(this.state.isAddedSensor){
      return (
        <>
          <div className="custom-select">
            <label htmlFor="">Select Zone</label>
            <C18Select00 id=""
              value={this.state.zoneSel}
              onChange={e=>this.onChange("zoneSel",{zoneSel: e.currentTarget.value})}
            >
              {this.showZonesOpts()}
            </C18Select00>
            {true&&
              <span className="material-icons down-arrow">
                keyboard_arrow_down
              </span>
            }
          </div> 
        </>
      )
    }
  }

  checkAddInfo=async(gwId)=>{
    // this gets current info about the gateway, to display to user
    let st=this.state
    let query={gatewayId:gwId}
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "retrieve2",
      sessionId: globs.userData.session.sessionId, body: query})
    let gw=resp.data[0]
//     cl(resp)
    if(gw){this.setState({addGw:gw})}
  }

  showAddGatewayInfo=()=>{
    let st=this.state
    if (st.addGw == {} || st.addId == "") return
//     cl(st.addGw)
    let gotGW=globs.gatewaysInfo.info.filter(g=>{return g.gatewayId==st.addId})[0]
//     cl(gotGW)
    let msg=""
    if(!st.addGw?.acctOK){msg="This Gateway belongs to another account."}
    if(gotGW){msg="This Gateway is already a part of your account!"}
//     cl(msg)
//     cl(gotGW)
    let showMsg=(gotGW||!st.addGw?.acctOK)?true:false
    if(st.addGw&&(!st.gatewayIdInfo)){
      let elapse=getTimeI()-st.addGw?.updateTime
      return(
        <div>
        {(showMsg)&&
          <span style={{color:"red"}}>{msg}<br/></span>
        }
        {`Last Contact: ${elapse ? (`${elapse} seconds ago`): "Unknown"}`}<br/>
        {`Firmware Version: ${st.addGw?.fwVersion ? st.addGw?.fwVersion : "Unknown"}`}<br/>
        </div>
      )
    }
  }
  
  showSelectGateway=()=>{
    let isOwner=globs.userData.session.userId==globs.accountInfo.info.owner
    return (
      <div>
        <div className="custom-select">
          <label htmlFor="">Select Gateway</label>
          <C18Select00 id=""
            parms={{list:true}}
            value={this.state.gatewaySel}
            onChange={e=>this.onChange("gatewaySel",{gatewaySel: e.currentTarget.value})}
          >
            {this.showGatewayOpts()}
          </C18Select00>
          {false&&
            <span className="material-icons down-arrow">
              keyboard_arrow_down
            </span>
          }
        </div>
        {isOwner&&
          <div>
            <C18Button00 type="button" className="danger" onClick={e=>this.onChange("deleteGateway")}>Delete</C18Button00>
          </div>
        }
       </div>
    )
  }
  showGatewayIdInfo=()=>{
    let st=this.state
//     cl(st.gatewayIdInfo)
    return(
      <div onClick={e=>{
        cl("setting over true")
        this.setState({gwidOverride:true,gatewayIdInfo:""})}}>
      {st.gatewayIdInfo}
      </div>
    )
  }
  
  loadFirmwares=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/dirList", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body:{
        type:"fwImages",
      }
    })
//     cl(res)
    this.firmwares=res.data.filter(
      r=>{return (r.indexOf("_Menu")<0)&&(r!="cfg")}
    )
  }
  
  showFirmwareOpts=()=>{
    return this.firmwares.map((im,i)=>{
      return(
        <option key={i} value={im}>{im}</option>
      )
    })
  }

  toClient=(obj)=>{
    cl(obj)
    let st=this.state
    let da=obj.data
    var prog
    if(da.index>=0){
      prog=progressSize*(da.index+1)/da.cnt
    }else{
      prog=0-st.downloadProgress
    }
    cl(prog)
    prog=100*prog/300
    let updTime=getTime()
    if(prog>=100){
      updTime=0
      prog=0}
    this.setState({downloadProgress:prog, updTime:updTime})
  }

  newData=(zData)=>{
//     cl(zData)
    let st=this.state
    let pid=getParamId2("1900","pearl_snaps","operation_progress")
    let pid2=getParamId2("1900","pearl_snaps","download_crc")
//     cl(pid2)
//     let zInd=this.zone.siteZoneIndex
    let params=zData.params
    let opProg=0
    let contCRC=0
    for(let i=0;i<params.length;i++){
      let p=params[i]
      if(/*(p.z==zInd)&&*/(p.c==240)&&(p.i==pid)){opProg=p.d}
      if((p.c==240)&&(p.i==pid2)){contCRC=p.d}
    }
    if(contCRC){
      cl(contCRC)
      cl(st)
      let comp=(contCRC==st.fileCRC)?"Succeeded!":"Failed"
      let msg=`CRC Check ${comp}`
      this.setState({progressMsg:msg})
    }
//     this.setState({downloadProgress:opProg})
//     let opType=(opProg<100)?st.operationType:"none"
    if(opProg>0){
      cl(opProg)
      if(opProg>=100){opProg=0}
      this.setState({downloadProgress:opProg})
    }
  }

  showSending=(st)=>{
    if(st.updTime!=0){
      let sendStr=(getTime()-st.updTime<5)?"Sending . . .":"Stalled."
      return(
        <span>
        {sendStr}
        </span>
      )
    }
  }

  showFwProgress=()=>{
    let st=this.state
    let mult=progressSize / 100
    cl(st)
    if(st.downloadProgress){
      if(st.downloadProgress==progressSize){
        return(
          <tr><td colSpan="2">
            <h3>{st.progressMsg}</h3>
          </td></tr>
        )
      }else{
        let result=(st.downloadProgress>=0)?"":"Failed"
        return(
          <tr><td colSpan="2">
          <br/>
            <label>Download Progress:</label>
            <div style={{width:progressSize,borderStyle:"solid",
              borderWidth:1,borderRadius:10
            }}>
              <div style={{width:mult*Math.abs(st.downloadProgress),
               height:20,
               backgroundColor:(st.downloadProgress>=0)?"#88FF88":"#FF8888",
               borderRadius:10,textAlign:"center"}}>
               {result}
              </div>
            <div>
            {this.showSending(st)}
            </div>
            </div>
          </td></tr>
        )
      }
    }
  }

  icSaveZoneAddrs=async(st)=>{
    st.siteZones.forEach(async z=>{
      let arr=[]
      arr.push({
        c:240,
        d:z.zoneAddr,
        f:2,
        i:this.interCont.zoneContAddrId+2*this.interCont.zoneContAddrMult,
        t:getTime(),
        z:z.siteZoneIndex,
      })
      arr.push({
        c:255,
        d:z.commMode,
        f:2,
        i:this.interCont.commModeId,
        t:getTime(),
        z:z.siteZoneIndex,
      })
//         z.commMode=dbVals.z[z.siteZoneIndex][255][this.interCont.commModeId]||0
      await sendArrayNowPromise(arr,z.virtual,z.gatewayType,z.controllerId,false)
      cl(arr)
    })
  }

  icSaveZoneSettings=async(st)=>{
    cl(st)
    let zo=st.siteZones.filter(z=>{return z.commMode=3})[0]// gateway
    if(!zo){return}
    let arr=[]
    st.zoneAddrs.forEach((a,i)=>{
      let z2=st.siteZones.filter(z=>{return z.zoneAddr==a})[0]
      arr.push({
        c:255,
        d:a,
        f:2,
        i:this.interCont.zoneSetsAddrId+i*this.interCont.zoneSetsAddrMult,
        t:getTime(),
        z:zo.siteZoneIndex,
      })
      arr.push({
        c:255,
        d:z2?.zoneName||"None",
        f:2,
        i:this.interCont.zoneSetsNameId+i*this.interCont.zoneSetsAddrMult,
        t:getTime(),
        z:zo.siteZoneIndex,
      })
    })
    cl(arr)
    await sendArrayNowPromise(arr,zo.virtual,zo.gatewayType,zo.controllerId,false)
  }

  icSave=()=>{
    cl("icSave")
    let st=this.state
    this.icSaveZoneAddrs(st)
    this.icSaveZoneSettings(st)
  }

  icConfigure=()=>{
//     cl("configure")
    let st=this.state
//     cl(st)
    let clients=st.zoneAddrs.slice(0)
    clients.forEach((c,i)=>{clients[i]=0})
    let ind=0;
    st.siteZones.forEach(z=>{
      z.zoneAddr=z.siteZoneIndex+1;
      z.commMode=(z.gatewayId==st.gatewaySel)?3:2// mark gateway
      if(z.commMode!=3){// not gateway
        clients[ind++]=z.zoneAddr
      }
    })
    this.mySetState({zoneAddrs:clients})
  }

  showIcClients=(st)=>{
//     let rows=
    let rows=(st.zoneAddrs||[])
      .filter(z=>{return z!=0})
      .map((a,i)=>{
        let zo=st.siteZones.filter(z2=>{return z2.zoneAddr==a})[0]
        let name=(zo)?zo.zoneName:"xxx";
//         cl(zo)
        return(
          <tr key={i}><td width="50">{a}</td><td>{name}</td></tr>
        )
    })
//     cl(rows)
    if(st.icGateway){
      return(

        <table><tbody>
        <tr><td colSpan="2"><h4>Clients</h4></td></tr>
        <tr><th>Addr</th><th>Name</th></tr>
        {rows}
        </tbody></table>
      )
    }
  }

  showIntercontrollerConfigure=(st)=>{
    if(st.icGateway){
      return(
        <C18Button00 type="button" className="filled"
        onClick={()=>this.onChange("icConfigure",{})}>Configure</C18Button00>
      )
    }
  }

  showIntercontroller=(st)=>{
    if(st.pageType=="intercontroller"){
      return(
        <div>
        <h3>Intercontroller</h3>
          <input type="checkbox" id="icGateway"
          checked={st.icGateway}
          onChange={e=>this.onChange("icGateway",{icGateway:e.currentTarget.checked})}
          />&nbsp;
          <label htmlFor="icGateway" style={{display:"inline-block"}}>
          Intercontroller Gateway</label><br/>
          {this.showIntercontrollerConfigure(st)}
          {this.showIcClients(st)}
        </div>
      )
    }
  }

  showOTA=(st,lcTime,fwUpdateDisable)=>{
    if(st.pageType=="ota"){
      return(
        <div>
          {((st.gatewayType==1900)&&(this.firmwares.length>0)&&(true||(lcTime<200)))&&
            <div>
              <p>Update Pearl Firmware</p>
              <table style={{width:"auto"}}><tbody>
              <tr>
                <td>
                  Destination:
                  <input type="radio" name="dest" id="flash"
                    checked={st.fwDest=="flash"}
                    style={{marginLeft:10}}
                    onChange={e=>this.onChange("fwDest",{fwDest:"flash"})}
                  />
                  <label htmlFor="flash"
                    style={{display:"inline-block"}}
                  >Flash</label>
                  <input type="radio" name="dest" id="usb"
                    checked={st.fwDest=="usb"}
                    style={{marginLeft:10}}
                    onChange={e=>this.onChange("fwDest",{fwDest:"usb"})}
                  />
                  <label htmlFor="usb"
                    style={{display:"inline-block"}}
                  >USB</label>
                </td>
                <td>
                </td>
              </tr>
              <tr>
              <td>
                <input type="checkbox" id="throttlePacks"
                checked={st.throttlePacks}
                onChange={e=>this.onChange("throttlePacks",{throttlePacks:e.currentTarget.checked})}
                />&nbsp;
                <label htmlFor="throttlePacks" style={{display:"inline-block"}}>
                Throttle Packs</label><br/>
                <input type="checkbox" id="forceFdr"
                checked={st.forceFdr}
                onChange={e=>this.onChange("forceFdr",{forceFdr:e.currentTarget.checked})}
                />&nbsp;
                <label htmlFor="forceFdr" style={{display:"inline-block"}}>
                Reset to Defaults</label><br/>
                <input type="checkbox" id="udpOta"
                checked={st.udpOta||false}
                onChange={e=>this.onChange("udpOta",{udpOta:e.currentTarget.checked})}
                />&nbsp;
                <label htmlFor="udpOta" style={{display:"inline-block"}}>
                OTA over UDP</label><br/>
                <input type="checkbox" id="otaBootloader"
                checked={st.otaBootloader||false}
                onChange={e=>this.onChange("otaBootloader",{otaBootloader:e.currentTarget.checked})}
                />&nbsp;
                <label htmlFor="otaBootloader" style={{display:"inline-block"}}>
                OTA Bootloader</label>
                <input type="text" id="startingOffset"
                value={st.startingOffset||""}
                onChange={e=>this.onChange("startingOffset",{startingOffset:e.currentTarget.value})}
                />&nbsp;
                <label htmlFor="startingOffset" style={{display:"inline-block"}}>
                Starting Offset</label>
              </td>
              <td></td>
              </tr>
              <tr><td>
                <label htmlFor="gateway-addid">Select Firmware</label>
              </td><td></td></tr>
              <tr>
              <td>
                <div className="custom-select" style={{marginBottom:0}}>
                  <C18Select00 id=""
                    parms={{list:true}}
                    value={st.fwSel}
                    onChange={e=>this.onChange("fwSel",{fwSel: e.currentTarget.value})}
                  >
                    {this.showFirmwareOpts()}
                  </C18Select00>
                </div>
              </td>
              <td>
                <C18Button00 type="button" className="filled" disabled={fwUpdateDisable}
                onClick={()=>this.onChange("fwUpdate",{fwSel:st.fwSel})}>Update</C18Button00>
                <br/><br/>
                <C18Button00 type="button" className="filled" disabled={fwUpdateDisable}
                onClick={()=>this.onChange("fwCalcCRC",{fwSel:st.fwSel})}>
                Calc CRC</C18Button00>
                <br/><br/>
                <C18Button00 type="button" className="filled" disabled={fwUpdateDisable}
                onClick={()=>this.onChange("fwFlash",{fwSel:st.fwSel})}>
                Flash</C18Button00>
              </td>
              </tr>
              {this.showFwProgress()}
              </tbody></table>
              <div className="clearfloat"/><br/>
            </div>
          }
        </div>
      )
    }
  }

  showBasic=(st,lastContact,pearlFwVersion,zoneInfo,resetOnDisc,
    lcTime,fwUpdateDisable,addDisable,name)=>{
    if(st.pageType=="basic"){
      return(
        <div>
          <div>Gateway ID: {st.gatewaySel}
            { st.gatewaySel && (<button
            type="button"
            className="material-icons-outlined copy-button"
            title="Copy"
            onClick={() => this.onChange('copyValue', {value: st.gatewaySel})}
          >
            content_copy
          </button>) }
          </div>
          {lastContact}
          {pearlFwVersion}
          <div>Zone Name: {zoneInfo?.zoneName}</div>
          <div className="clearfloat"/><br/>

          <label htmlFor="gateway-name">Gateway Name</label>
          <C18Input00 type="text" id="gateway-name"
            value={name||""}
            onChange={e=>this.onChange("gatewayName",{gatewayName:e.currentTarget.value})}
          /><br/>
          <input type="checkbox" id="resetOnDisc"
          checked={resetOnDisc||false}
          onChange={e=>this.onChange("resetOnDisc",{resetOnDisc:e.currentTarget.checked})}
          />&nbsp;
          <label htmlFor="resetOnDisc" style={{display:"inline-block"}}>
          Reset on Disconnect</label><br/>
          <p>Add Gateway</p>
          {false&&
            <>
              <label htmlFor="gateway-addname">Gateway Name</label>
              <C18Input00 type="text" id="gateway-addname"
                value={st.addName}
                onChange={e=>this.onChange("gatewayAdd",{addName:e.currentTarget.value})}
              />
            </>
          }
          <label htmlFor="gateway-addid">Gateway ID</label>
          <C18Input00 type="text" id="gateway-addid" className="with-right-button"
            value={st.addId}
            onChange={e=>this.onChange("gatewayAdd",{addId:e.currentTarget.value})}
          />
          <C18Button00 type="button" className="filled" disabled={addDisable}
          onClick={()=>this.onChange("addGateway",{gatewayName:st.addName})}>Add</C18Button00>
          &nbsp;
          <C18Button00 type="button" className="filled" disabled={addDisable}
          onClick={()=>this.onChange("replaceGateway",{gatewayName:st.addName})}>Replace</C18Button00>
          <div className="clearfloat"/>
          {this.showAddGatewayInfo()}
          {this.showGatewayIdInfo()}
          {this.showSelectZone()}
        </div>
      )
    }
  }

  showPearlImage=()=>{
    let st=this.state
//           <canvas id="myCanvas" width="200" height="100"
//           style={{border:"1px solid #000000"}}>
//           </canvas>
    if(st.pageType=="remote"){
      return(
        <div className="remote-control-wrapper">
          <div>
          <img className="remote-control-canvas" src={this.canvasUrl}/>
          <table className="remote-control-table">
            <tbody>
              <tr>
                <td>
                <button
                    id="rc-btn-4"
                    type="button"
                    className="material-icons-outlined"
                    title="back"
                    onClick={() => this.sendKey(4)}
                >
                  reply
                </button>
                </td>
                <td>
                <button
                    id="rc-btn-5"
                    type="button"
                    className="material-icons-outlined"
                    title="up"
                    onClick={() => this.sendKey(5)}
                >
                  arrow_upward
                </button>
                </td>
                <td>
                <button
                    id="rc-btn-6"
                    type="button"
                    className="material-icons-outlined"
                    title="enter"
                    onClick={() => this.sendKey(6)}
                >
                  keyboard_return
                </button>
                </td>
                <td>
                <button
                    id="rc-btn-0"
                    type="button"
                    className="material-icons-outlined"
                    title="refresh"
                    onClick={() => this.sendKey(0)}
                >
                  refresh
                </button>
                </td>
              </tr>
              <tr>
                <td>
                <button
                    id="rc-btn-1"
                    type="button"
                    className="material-icons-outlined"
                    title="left"
                    onClick={() => this.sendKey(1)}

                >
                  arrow_back
                </button>
                </td>
                <td>
                <button
                    id="rc-btn-2"
                    type="button"
                    className="material-icons-outlined"
                    title="down"
                    onClick={() => this.sendKey(2)}
                >
                  arrow_downward
                </button>
                </td>
                <td>
                <button
                    id="rc-btn-3"
                    type="button"
                    className="material-icons-outlined"
                    title="right"
                    onClick={() => this.sendKey(3)}
                >
                  arrow_forward
                </button>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      )
    }
  }

  dispCount=0

  updatePearlImage=async()=>{
    let st=this.state
//     cl(st)
    if(st.pageType!="remote"){
      clearInterval(this.piTimer)
      return;
    }
//     cl("update")
    let query={clientId:st.clientId}
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/pearlScreen", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body:
      query})
    if(!res.data){return}
//     cl(res)
    let buf=res.data.buf.data
//     cl(buf)
    let ctx=this.canvas.getContext("2d")
    ctx.willReadFrequently=true
    let imgData=ctx.getImageData(0,0,128,64)

    for(let i=0;i<8;i++){// 8 tiles vertically
      for(let j=0;j<16;j++){// 16 tiles across
        for(let k=0;k<8;k++){// down the lines of the tile
          let index=128*i+8*j+k// index of the line
          if(index<1024){
            let byte=buf[index]
            for(let l=0;l<8;l++){// each tile height is 4k
              let dest= 4096*i+32*j+4*k+512*(7-l)
              imgData.data[dest+0]=0// red
              imgData.data[dest+3]=0xFF// alpha
              if(byte&0x80){
                imgData.data[dest+1]=0xFF// blue
                imgData.data[dest+2]=0xFF// green
              }else{
                imgData.data[dest+1]=0
                imgData.data[dest+2]=0
              }
              byte=byte<<1
            }
          }
        }
      }
    }

//     for(let i=0;i<64;i++){
//       for(let j=0;j<16;j++){
//         let index=16*i+j
//         if(index<488){
//           let byte=buf[index]
//           for(let k=0;k<8;k++){
//             let index2=8*index+k
//             let index3=4*index2
//             imgData.data[index3+0]=0
//             imgData.data[index3+3]=0xFF
//             if(byte&0x01){
//               imgData.data[index3+1]=0xFF
//               imgData.data[index3+2]=0xFF
//             }else{
//               imgData.data[index3+1]=0
//               imgData.data[index3+2]=0
//             }
//             byte=byte>>1
//           }
//         }
//       }
//     }

    //     for(let i=0;i<100;i++){
//       for(let j=0;j<200;j++){
//         let ind=800*i+4*j
//         imgData.data[ind]=0xFF
//         imgData.data[ind+1]=44
//         imgData.data[ind+2]=44
//         imgData.data[ind+3]=0xFF
//       }
//     }
//     for(let i=0;i<20000;i++){imgData.data[i]=44}
    ctx.putImageData(imgData,0,0)
    this.canvasUrl=this.canvas.toDataURL("image/png")
    this.setState({upd:(st.upd||0)+1})

  }

  createCanvasImage=()=>{
    this.canvas=document.createElement("CANVAS")
    this.canvas.width=128
    this.canvas.height=64
    this.canvas.willReadFrequently=true
//     cl(imgData)
  }

  showFixed=(val,places)=>{
    return (val||0).toFixed(places)
  }

  showDth=(st)=>{
    if(!st.log2Data[constant.UDP_LOG_DTH_HUM]){return}
    let keys=Object.keys(st.log2Data[constant.UDP_LOG_DTH_TEMP]||{})
    let temps=st.log2Data[constant.UDP_LOG_DTH_TEMP]||[]
    let hums=st.log2Data[constant.UDP_LOG_DTH_HUM]||[]
//     cl(keys)
//     cl(st.log2Data)
    let rows=keys
      .filter(k=>{return(temps[k]!=0)||(hums[k]!=0)})
      .map((k,i)=>{
        let nv=this.getProjectNameVersion(st,k)
        if((hums[k]||[])[1]){
          return(
            <tr key={i}>
              <td style={{fontSize:10}}>{k}<br/>{nv.n}<br/>{nv.v}</td>
              <td width="40">{this.showFixed(temps[k][0],2)}</td>
              <td width="40">{this.showFixed(temps[k][1],2)}</td>
              <td width="40">{this.showFixed(temps[k][2],2)}</td>
              <td width="40">{this.showFixed(hums[k][0],2)}</td>
              <td width="40">{this.showFixed(hums[k][1],2)}</td>
            </tr>
          )
        }
      })
      return(
        <div>
          <h3>DTH</h3>
          <table style={{width:"initial"}}><tbody>
            <tr>
              <th width="50">xBoard</th>
              <th width="50">T1</th><th width="50">T2</th><th width="50">T3</th>
              <th width="50">H1</th><th width="50">H2</th>
            </tr>
            {rows}
          </tbody></table>
          <div style={{height:20}}/>
        </div>

      )
  }

  procAnalogValue=(val)=>{
    return (val/409.6).toFixed(2)
  }

  getUptime=(ticks)=>{// show s, m, h, d, m, y
    let secs=ticks*256/1000
//     cl(secs)
    if(secs<3600){
      if(secs<60){
        return `${Math.floor(secs)}s`
      }else{
        return `${Math.floor(secs/60)}m`
      }
    }else{
        if(secs<86400){
          return `${Math.floor(secs/3600)}h`
        }else{
          if(secs<7*86400){
            return `${Math.floor(secs/86400)}d`
          }else{
            return `${Math.floor(secs/7*86400)}w`
          }
        }
      }
    }

  getProjectNameVersion=(st,addr)=>{
//     cl(addr)
//     cl(st.log2Data[constant.UDP_LOG_XBOARD_VERSION]||{})
//     cl((st.log2Data[constant.UDP_LOG_XBOARD_VERSION]||{})[addr])
//     cl(st.log2Data[constant.UDP_LOG_XBOARD_VERSION]||{}[addr]||[][0])
    let arr=(((st.log2Data[constant.UDP_LOG_XBOARD_VERSION]||{})[addr]||[])[0]||[])
    let ch=((st.log2Data[constant.UDP_LOG_XBOARD_OUT_CHANS]||{})[addr]||[])[0]||0
    let ticks=((st.log2Data[constant.UDP_LOG_XBOARD_TICKS]||{})[addr]||[])[0]||0
//     cl(ticks)

    let upTime=(ticks)?`up ${this.getUptime(ticks[0]||0)}`:""
//     cl(ch)
//     cl(ticks[0])
    let index=arr[0]
//     cl(index)
    let version=""
    if(arr[1]){
      version=`${arr[1]}.${arr[2]}`
    }
    return {n:constant.PROJECT_NAMES[arr[0]||0],v:version,c:ch||0,u:upTime}
  }

  showAnalogIn=(st)=>{
    if(!st.log2Data[constant.UDP_LOG_INPUT_VALUE]){return}
    let keys=Object.keys(st.log2Data[constant.UDP_LOG_INPUT_VALUE]||{})
    let analogs=st.log2Data[constant.UDP_LOG_INPUT_VALUE]
//     let hums=st.log2Data[constant.UDP_LOG_DTH_HUM]
//     cl(keys)
    let div=409.6
    let rowStyle={fontSize:12}
    let rows=keys
      .filter(k=>{return(analogs[k]!=0)})
      .map((k,i)=>{
        let nv=this.getProjectNameVersion(st,k)
        return(
          <React.Fragment key={i}>
          <tr>
            <td style={rowStyle}>{k}<br/>{nv.n}<br/>{nv.v}</td><td>Analog In</td>
            <td>{this.procAnalogValue(analogs[k][0])}</td>
            <td>{this.procAnalogValue(analogs[k][1])}</td>
            <td>{this.procAnalogValue(analogs[k][2])}</td>
            <td>{this.procAnalogValue(analogs[k][3])}</td>
            <td>{this.procAnalogValue(analogs[k][4])}</td>
          </tr>
          <tr><td colSpan="6" height="10"></td></tr>
          </React.Fragment>
        )
      })
      return(
        <div>
          <table style={{width:"initial"}}><tbody>
            <tr key="head"><th width="50">Addr</th><th width="150">Value</th>
            <th width="50">An1</th><th width="50">An2</th><th width="50">An3</th>
            <th width="50">An4</th><th width="50">An5</th></tr>
          {rows}
          </tbody></table>
          <div style={{height:20}}/>
        </div>
      )
  }

  makeBitLine=(val,relay,index)=>{
    let ret=[...Array(8).keys()].map((k,i)=>{
      var v
      if(index==0){
        if((val&(1<<i))?1:0){
          v=((relay&(1<<i))?1:0)?"H":"O"
        }else{
          v="A"
        }
      }else{
        v=(val&(1<<i))?1:0
      }
      return(
        <td width="40" key={i}>{v}</td>
      )
    })
//     cl(ret)
    return ret
  }

  makeAnalogLine=(val)=>{
    let ret=[...Array(8).keys()].map((k,i)=>{
      let v=(val[i]||(val[i]==0))?val[i]:"-"
      return(
        <td key={i}>{v}</td>
      )
    })
    return ret
//     if(val&&(val!=0)){
//       return val
//     }else{
//       return "-"
//     }
  }

  makeRowTitle=(st,title,row,k)=>{
    let nv=this.getProjectNameVersion(st,k)
//       <span style={{fontSize:10}}>{nv.n}<br/>{nv.v}<br/>{`ch${nv.c}`}</span>
//       {title}<br/>
    let rowStyle={fontSize:12}
    return (
      <td key="title">
      <table><tbody>
      <tr><td style={rowStyle}>{title}</td></tr>
      <tr><td style={rowStyle}>{nv.n}</td></tr>
      <tr><td style={rowStyle}>{nv.v}</td></tr>
      <tr><td style={rowStyle}>{`ch ${nv.c}`}</td></tr>
      <tr><td style={rowStyle}>{nv.u}</td></tr>
      </tbody></table>
      </td>
    )
  }

  showSwPos=(st)=>{
    if(!st.log2Data[constant.UDP_LOG_XBOARD_STATUS_SWPOS]){return}
    let keys=Object.keys(st.log2Data[constant.UDP_LOG_XBOARD_STATUS_SWPOS]||{})
    let inData=[
      st.log2Data[constant.UDP_LOG_XBOARD_STATUS_SWPOS],
      st.log2Data[constant.UDP_LOG_XBOARD_STATUS_RELAYSTATE],
      st.log2Data[constant.UDP_LOG_AUTO_STATE],
      st.log2Data[constant.UDP_LOG_DISCRETE_INPUT_STATE],
      st.log2Data[constant.UDP_LOG_ANALOG_OUT],
      st.log2Data[constant.UDP_LOG_SWITCH_CAL],
      st.log2Data[constant.UDP_LOG_SWITCH_VAL],
      st.log2Data[constant.UDP_LOG_XBOARD_TICKS],
    ]
//     cl(st.log2Data)
//     cl(inData)
    let titles=["Switch Position","Relay State","Auto State","Discrete Inputs",
      "Analog Out","Switch Cal", "Switch Val"]
    let rows2=[]
    for(let i=0;i<keys.length;i++){
//       let rows=[]// 1 for each module
      let key=keys[i]
      let rowTitle=this.makeRowTitle(st,key,i,key)
//       cl(rowTitle)
      let rows=[
        <tr key="head">
          <th width="150">Value</th>
          <th>1</th><th>2</th><th>3</th><th>4</th>
          <th>5</th><th>6</th><th>7</th><th>8</th>
        </tr>
      ]
      for(let j=0;j<7;j++){
        if((inData[j]||{})[key]){
          if(j<4){
            rows.push(
              <tr key={`${key}-${3*i+j}`}>
                <td>{titles[j]}</td>
                {this.makeBitLine((inData[j]||{})[key],(inData[1]||{})[key],j)}
              </tr>
            )
          }else{
            rows.push(
              <tr key={`${key}-${3*i+j}`}>
                <td>{titles[j]}</td>
                {(this.makeAnalogLine((inData[j]||{})[key]))}
              </tr>
            )
          }
        }
      }
      rows.push(
          <tr key={i}><td colSpan="10" height="10"></td></tr>
      )
//       cl(rowTitle)
      rows2.push(
        <table key={i} style={{width:"initial"}}><tbody>
          <tr>{rowTitle}
          <td>
            <table><tbody>
            {rows}
            </tbody></table>
          </td></tr>
        </tbody></table>
      )
    }
    return(
      <div>
      <h3>Outputs</h3>
        {rows2}
      </div>
    )
//     return(
//       <div>
//       <h3>Outputs</h3>
//
//         <table style={{width:"initial"}}><tbody>
//         <tr>
//           <th width="50">xBoard</th><th width="150">Value</th>
//           <th>1</th><th>2</th><th>3</th><th>4</th>
//           <th>5</th><th>6</th><th>7</th><th>8</th>
//         </tr>
//         <tr>
//         <td>
//         {this.makeRowTitle(st,key,j,key)}
//         </td>
//         <td>
//           <table><tbody>
//           {rows}
//           </tbody></table>
//         </td></tr>
//         </tbody></table>
//         <div style={{height:20}}/>
//       </div>
//     )
  }

  showEquipment=(st)=>{
    if(!st.log2Data[constant.UDP_LOG_SET_EQUIPMENT_NAME]||
      st.log2Data[constant.UDP_LOG_SET_EQUIPMENT_NAME].length<128
    ){return}
    let equip=[]
    if(st.log2Data[constant.UDP_LOG_SET_EQUIPMENT_TYPE]){
      for(let i=0;i<128;i++){
        let ty=st.log2Data[constant.UDP_LOG_SET_EQUIPMENT_TYPE][i][0]
        if(ty){
          equip.push({
            ch:i,
            ty:ty,
            nm:st.log2Data[constant.UDP_LOG_SET_EQUIPMENT_NAME][i][0],
            po:st.log2Data[constant.UDP_LOG_SET_EQUIPMENT_POS][i][0],
            ov:st.log2Data[constant.UDP_LOG_SET_EQUIPMENT_POS_REASON][i][0],
          })
        }
      }
    }
    let rows=[]
//     cl(equip)
    for(let i=0;i<equip.length/8;i++){
//       cl(i)
      let row=[]
      for(let j=0;j<8;j++){
        let id=8*i+j
        if(id<equip.length){
          let eq=equip[id]
          row.push(
            <td key={j}>
              <div style={{width:80,borderStyle:"solid",borderWidth:1}}>
              <table><tbody>
              <tr><td align="center">{eq.nm}</td></tr>
              <tr><td align="center">{constant.CHAN_TYPE_SHORT[eq.ty]}</td></tr>
              <tr><td align="center">{eq.po}</td></tr>
              <tr><td align="center">{constant.CHAN_OVERRIDES[eq.ov]}</td></tr>
              </tbody></table>
              </div>
            </td>
          )
        }else{
          row.push(
            <td key={j}>
            </td>
          )
        }
      }
      rows.push(<tr key={i}>{row}</tr>)
    }
    return(
      <div>
        <h3>Equipment</h3>
        <table style={{width:"initial"}}><tbody>{rows}</tbody></table>
        <div style={{height:20}}/>
      </div>
    )
  }

  showSensors=(st)=>{
//     cl(st.log2Data)
    let tab=st.log2Data[constant.UDP_LOG_SENSOR]
    if(!tab){return}
    let keys=Object.keys(tab)
    let sensors=keys.map(k=>{return {n:constant.SENSOR_NAMES[k],v:tab[k][0]}})
    let rows=[]
    for(let i=0;i<sensors.length/8;i++){
//       cl(i)
      let row=[]
      for(let j=0;j<8;j++){
        let id=8*i+j
        if(id<sensors.length){
          let se=sensors[id]
          row.push(
            <td key={j}>
              <div style={{width:80,borderStyle:"solid",borderWidth:1}}>
              <table><tbody>
              <tr><td align="center">{se.n}</td></tr>
              <tr><td align="center">{se.v}</td></tr>
              </tbody></table>
              </div>
            </td>
          )
        }else{
          row.push(
            <td key={j}>
            </td>
          )
        }
      }
      rows.push(<tr key={i}>{row}</tr>)
    }

    return(
      <div>
        <h3>Sensors</h3>
        <table style={{width:"initial"}}><tbody>{rows}</tbody></table>
        <div style={{height:20}}/>
      </div>
    )
  }

  getCurVal=(id)=>{

  }

  showSystem=(st)=>{
//     cl(st.log2Data)
    let types=["coolSp","heatSp","deHumSp","humSp","tempStage",
      "humStage",
      "pearlFw",
      "upTime",
      "contTime"]
    let tStage=["H6","H5","H4","H3","H2","H1","N","C1","C2","C3","C4","C5","C6",""]
      [(st.log2Data[constant.UDP_LOG_TEMP_STAGE]||{})[0]||13]
    let hStage=["N","DH1","DH2","DHLT","HUM",""]
      [(st.log2Data[constant.UDP_LOG_HUM_STAGE]||{})[0]||5]
    let fwV=(st.log2Data[constant.UDP_LOG_FW_VERS]||{})[0]||[]
//     cl(st.log2Data[constant.UDP_LOG_FW_VERS])
    let fwStr=(fwV[0])?`${fwV[0]}.${fwV[1]}.${fwV[2]}`:""
    let ticks=((st.log2Data[constant.UDP_LOG_UP_TIME]||{})[0])||[]
//     cl(ticks[0])
    let upTime=(ticks[0]||0)?this.getUptime((ticks[0]||0)/256):""// to match the module time
    let vals=[
      (st.log2Data[constant.UDP_LOG_COOL_SP]||{})[0],
      (st.log2Data[constant.UDP_LOG_HEAT_SP]||{})[0],
      (st.log2Data[constant.UDP_LOG_DEHUM_SP]||{})[0],
      (st.log2Data[constant.UDP_LOG_HUM_SP]||{})[0],
      tStage,
      hStage,
      fwStr,
      upTime,
      (st.log2Data[constant.UDP_LOG_CONT_TIME]||{})[0],
    ]
    let rows=types.map((t,i)=>{
      return(
        <tr key={i}><td>{t}</td><td>{vals[i]}</td></tr>
      )
    })
    return(
      <div>
        <h3>System</h3>
        <table style={{width:"initial"}}><tbody>{rows}</tbody></table>
        <div style={{height:20}}/>
      </div>
    )

  }

  showRTD=(st)=>{
    if(st.pageType=="rtd"){
      return(
        <div><h2>{`RTD ${(st.rtdOnline)?"Online":"Offline"}`}</h2>
          <div style={{height:20}}/>
          {this.showSystem(st)}
          {this.showDth(st)}
          {this.showAnalogIn(st)}
          {this.showSwPos(st)}
          {this.showEquipment(st)}
          {this.showSensors(st)}
        </div>
      )
    }
  }

  rtdReadField=(pack,type,len)=>{
    var val
    switch(type){
      case "u08":
        val=pack.msg.getUint8(pack.ofs,true)
        pack.ofs+=1
        break
      case "u16":
        val=pack.msg.getUint16(pack.ofs,true)
        pack.ofs+=2
        break
      case "u32":
        val=pack.msg.getUint32(pack.ofs,true)
        pack.ofs+=4
        break
      case "f32":
        val=pack.msg.getFloat32(pack.ofs,true)
        pack.ofs+=4
        break
      case "t1":
        let strlen=len-constant.UDP_LOG2_PACK_HEAD_SIZE
        let valBuf=pack.msg.buffer.slice(pack.ofs,pack.ofs+strlen)
        let valChars=(Array.from(new Uint8Array(valBuf))).map(c=>{
          return String.fromCharCode(c)
        })
        val=valChars.join("")
        pack.ofs+=strlen
        break
    }
    return val
  }

  rtdGetItems=(pack)=>{
    var vals=[]
    for(let i=0;i<5;i++){
      vals[i]=this.rtdReadField(pack,"u16")
    }
    return vals
  }

//   co={
//     UDP_LOG_SET_RELAY_PENDING:0,
//     UDP_LOG_SET_ANALOG_PENDING:1,
//     UDP_LOG_SET_EQUIPMENT_POS:2,
//     UDP_LOG_SET_EQUIPMENT_POS_REASON:3,
//     UDP_LOG_XBOARD_STATUS_SWPOS:4,
//     UDP_LOG_XBOARD_STATUS_RELAYSTATE:5,
//     UDP_LOG_SENSOR_MODULE_STATUS:6,
//     UDP_LOG_DTH_TEMP:7,
//     UDP_LOG_DTH_HUM:8,
//     UDP_LOG_INPUT_VALUE:9,
//     UDP_LOG_TOTAL:10,
//   }

  rtdGetVal=(pack,id,ix,len)=>{
//     cl(id)
    var val
    switch(id){
      case constant.UDP_LOG_DTH_TEMP:
        return this.rtdReadField(pack,"f32")
      case constant.UDP_LOG_DTH_HUM:
        return this.rtdReadField(pack,"f32")
//       case constant.UDP_LOG_INPUT_VALUE:
//         return this.rtdReadField(pack,"f32")
      case constant.UDP_LOG_SET_EQUIPMENT_NAME:
        return this.rtdReadField(pack,"t1",len)
      case constant.UDP_LOG_XBOARD_VERSION:
        return [
          this.rtdReadField(pack,"u08"),
          this.rtdReadField(pack,"u08"),
          this.rtdReadField(pack,"u08"),
        ]
      case constant.UDP_LOG_XBOARD_TICKS:
//         cl("xBoard")
        return [
          this.rtdReadField(pack,"u16"),
          this.rtdReadField(pack,"u16"),
          this.rtdReadField(pack,"u16"),
        ]
      case constant.UDP_LOG_AUTO_STATE:
      case constant.UDP_LOG_XBOARD_OUT_CHANS:
//         return this.rtdReadField(pack,"u08")
      case constant.UDP_LOG_XBOARD_IN_CHANS:
      case constant.UDP_LOG_TEMP_STAGE:
      case constant.UDP_LOG_HUM_STAGE:
        return this.rtdReadField(pack,"u08")
      case constant.UDP_LOG_SENSOR:
        val=this.rtdReadField(pack,constant.STR_FORMAT[ix])
        return (ix==constant.UCI_DT_FLOAT)?val.toFixed(2):val
      case constant.UDP_LOG_ANALOG_OUT:
      case constant.UDP_LOG_SWITCH_CAL:
      case constant.UDP_LOG_SWITCH_VAL:
      case constant.UDP_LOG_COOL_SP:
      case constant.UDP_LOG_HEAT_SP:
        val=this.rtdReadField(pack,"f32")
//         cl(val)
        return val.toFixed(2)
//       case constant.UDP_LOG_FW_VERS:// fw for modules
//         val=[
//           this.rtdReadField(pack,"u08"),
//           this.rtdReadField(pack,"u08"),
//           this.rtdReadField(pack,"u08"),
//         ]
//         cl("firmware")
//         cl(val)
//         return val
      case constant.UDP_LOG_CONT_TIME:
        val=this.rtdReadField(pack,"u32")
        let da=new Date(1000*(val+60*(new Date()).getTimezoneOffset()))
//         cl(da)
        let da2=dateToDisplayDate(da,"hh:mm:ss")
        return da2
      case constant.UDP_LOG_UP_TIME:
        val=this.rtdReadField(pack,"u32")
        return val
      case constant.UDP_LOG_HEARTBEAT:
        cl("heartbeat")
        return 0
//       case constant.UDP_LOG_HEAT_SP:
//         val=this.rtdReadField(pack,"u16")
//         cl(`cool sp: ${val}`)
//         return val


//       case constant.UDP_LOG_SET_EQUIPMENT_POS:
//       case constant.UDP_LOG_XBOARD_STATUS_SWPOS:
//       case constant.UDP_LOG_XBOARD_STATUS_RELAYSTATE:
//       case constant.UDP_LOG_DISCRETE_INPUT_STATE:
//       case constant.UDP_LOG_SET_EQUIPMENT_POS:
//         return this.rtdReadField(pack,"u16")
//       case constant.UDP_LOG_XBOARD_VERSION:
//         cl(pack.msg)
      default:
        return this.rtdReadField(pack,"u16")
    }
  }

  procDthTemp=(pack,addr,ix,val)=>{
    cl(addr,ix,val)
  }

  rtdHandleData=async(msg)=>{
// header is 2 + 4 + 12 = 18 bytes
//     cl(msg.data)
//     cl(msg.data.size)
    let pack={
      msg:new DataView(await msg.data.arrayBuffer()),
      ofs:18,// skip header
      len:msg.data.size,
      log2Data:Object.assign({},this.state.log2Data),
    }
//     cl(pack.msg)
    pack.ts=pack.msg.getUint32(2,true)
    while(pack.ofs<pack.len){
      let ofs=pack.ofs
      let [len,ts,id,ch,ix]=this.rtdGetItems(pack)
//       cl(id)
      if(!pack.log2Data[id]){pack.log2Data[id]={}}
      if(!pack.log2Data[id][ch]){pack.log2Data[id][ch]=[]}
      ts+=pack.ts;
//       cl(len)
      let val=this.rtdGetVal(pack,id,ix,len)
      if(id==constant.UDP_LOG_SENSOR){ix=0}
      pack.log2Data[id][ch][ix]=val
      pack.ofs=ofs+len
      if(![2,3,4,5,7,8,9,10,11,12,15,16,17,18,19,20,21,22,23,
        26,27,28,29,30,31,32,33,34,35
      ].includes(id)){
        cl(pack.ofs,len,ts,id,ch,ix,val)
        cl(pack.log2Data)
      }
//       cl(pack.ofs,len,ts,id,ch,ix,val)
    }
//     cl(pack.log2Data)
    this.setState({log2Data:pack.log2Data})
//     cl(items)
//     let dView=new DataView(await msg.data.arrayBuffer())
//     cl(dView)
//     let ts=dView.getUint32(2,true)
//     cl(ts)
//     let lenx=dView.getUint32(18,true)
//     cl(lenx)
//     let view=new Uint8Array(bin)
//     cl(view)
// //     cl(bin)
//     cl(view[0],view[1])
  }

  initLog2DataChPos=()=>{
    let log2Data=Object.assign({},this.state.log2Data)
    log2Data[constant.UDP_LOG_SET_EQUIPMENT_POS]=
      [...Array(128).keys()].map((k,i)=>{return [0]})
    log2Data[constant.UDP_LOG_SET_EQUIPMENT_POS_REASON]=
      [...Array(128).keys()].map((k,i)=>{return [0]})
    log2Data[constant.UDP_LOG_SET_EQUIPMENT_TYPE]=
      [...Array(128).keys()].map((k,i)=>{return [0]})
    log2Data[constant.UDP_LOG_SET_EQUIPMENT_NAME]=
      [...Array(128).keys()].map((k,i)=>{return [`c${i+1}`]})
    this.setState({log2Data:log2Data})
//     cl(log2Data)
  }

  sendLog2Command=(cmd,ws)=>{
      let obj={
        cmd:cmd,
        val:this.state.clientId,
//         sessionId:globs.userData.session.sessionId
      }
      ws.send(JSON.stringify(obj))
  }

  closeRtdWs=async()=>{
    if(this.log2WS){
      await this.log2WS.close()
      this.log2WS=null
    }
  }

  enableRtd=()=>{
    if(this.log2WS){
//       cl("enable rtd")
      this.sendLog2Command("enableRtd",this.log2WS)
      setTimeout(ws=>this.enableRtd(),45*1000)
    }
  }

  openRtdWS=()=>{
    if(this.log2WS){return}
    let uri=`${constant.wsLog2Url}`
    cl(uri)
    var onOpen=(r,e)=>{
      cl("log2WS Open")
      this.enableRtd(ws)
//       this.sendLog2Command("enableRtd",ws)
      this.sendLog2Command("setClientId",ws)
      this.initLog2DataChPos()
      this.setState({rtdOnline:true})
    }
    var onClose=(r,e)=>{
      cl("log2WS Close")
      this.log2WS=null
      this.setState({rtdOnline:false})
    }
    var onError=(r,e)=>{
      cl("log2WS Error")
    }
    var recvSocket=(msg)=>{
//       cl("log2WS Recv")
      this.rtdHandleData(msg)
    }

    let ws=new WebSocket(uri);
    this.log2WS=ws
    ws.onopen = e=>onOpen(e);
    ws.onclose = e=>onClose(e);
    ws.onmessage = e=>recvSocket(e);
    ws.onerror = e=>onError(e);
  }

//   function openWS(uri){
// /* this is to manage *the* websocket with the server*/
//   let gws=globs.webSocket
//   return new Promise((r, e)=>{
// //     cl(gws.open)
//     if(gws.open){r(); return}
//     cl("open ws: " + uri);
// //     cl("still opening")
//     if(gws.res){gws.res.push(r); return}// add it to the list of responses
//     gws.res=[r]// create the list of responses
//     let ws = new WebSocket(uri);
//     iv.ws = ws;
//     ws.onopen = ()=>onOpen(r, e);
//     ws.onclose = e=>onClose(e);
//     ws.onmessage = e=>recvSocket(e);
//     ws.onerror = e=>onError(e);
//   });
// }
  
  render(){
//     cl(this.state.zoneSel)
    let st=this.state
//     cl(st)
    if(st.loaded){
//       let gatewayInfo=getGatewayInfo(st.gatewaySel)
      let name=this.gatewayNames[st.gatewaySel]?.name
      let resetOnDisc=this.gatewayNames[st.gatewaySel]?.resetOnDisc
//       let addDisable=(st.addId=="")// ||(st.gatewayIdInfo)
      let gotGW=globs.gatewaysInfo.info.filter(gWay=>{return gWay.gatewayId==st.addId})[0]
      let addDisable=gotGW||(!st.gwidOverride)&&((!st.addId)||st.gatewayIdInfo)||(st.siteSel==null)

      let fwUpdateDisable=(!st.fwSel)
      let gw=(globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==st.gatewaySel})[0])||{}
      var lastContact,lcTime
      // cl(gw)

//       if(gw.updateTime){
//         lcTime=getTimeI()-gw.updateTime
//         lastContact=<div>{`Last Contact: ${lcTime} seconds ago`}</div>
//       }
//       cl(getTimeI()-gw.updateTime)
      if (st.gatewaySel) {
        lcTime=getTimeI()-gw.updateTime
        if(gw.connected||(lcTime<120)){
          lastContact=<div>{`Last Contact: ${lcTime ? (`${lcTime} seconds ago`) : "Unknown"}`}</div>
        }else{
          lastContact=(
            <div style={{color:"red"}}>Not Connected</div>
            )
        }
        var pearlFwVersion=""
        if(st.gatewayType==1900){
          pearlFwVersion=(
            <span style={{padding:0,margin:0}}>
          {`Firmware Version: ${gw.fwVersion}`}
        </span>
        )
        }
      } 

//       cl(gw)
      //       cl(name)
//       cl(gatewayInfo)
//       let zoneInfo=getZoneInfo(st.zoneSel)
//         disabled={(!st.addName)||st.gatewayIdInfo}
//     cl(st.addId,st.gatewayIdInfo)

    let zoneInfo=getZoneInfo(st.zoneSel)

    return(
      <div>
        <C18SubMenuHeader00 parms={{
          items:[
            {v:"basic",t:"Basic"},
            {v:"ota",t:"OTA"},
            {v:"intercontroller",t:"Intercontroller"},
            {v:"remote",t:"RC"},
            {v:"rtd",t:"RTD"},
          ],
          pageType: this.state.pageType,
          onChange: o=>this.onChange("pageMenu",o),
        }}/>
        <div className="clearfloat"></div>
        {this.showPearlImage()}
        <table><tbody>
        <tr><td>
          {this.showSelectSite()}
          <p>Site ID: {st.siteSel}
          <button
            type="button"
            className="material-icons-outlined copy-button"
            title="Copy"
            onClick={() => this.onChange('copyValue', {value: st.siteSel})}
          >
            content_copy
          </button>

          </p>
          {this.showSelectGateway()}<br/>
        </td>
        </tr>
        </tbody></table>
        {this.showBasic(st,lastContact,pearlFwVersion,zoneInfo,resetOnDisc,
          lcTime,fwUpdateDisable,addDisable,name)}
        {this.showOTA(st,lcTime,fwUpdateDisable)}
        {this.showIntercontroller(st)}
        {this.showRTD(st)}
      </div>
    )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ManageGateways;
