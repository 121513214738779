import React from 'react';
import {cl, globs,getTime} from '../../components/utils/utils';
import {wsTrans} from '../../usa/utils/utils'
import UsaIcon from '../../usa/components/UsaIcon'
import GrowJournalWidgetEntry00 from './GrowJournalWidgetEntry00'
import ConfigLogFeedEntry00 from './ConfigLogFeedEntry00'
import TaskItemEntry00 from './TaskItemEntry00'
import MessagingFeedEntry00 from './MessagingFeedEntry00'
import GrowJournalReply00 from '../../usa/components/GrowJournalReply00'
import MessageReply00 from '../../usa/components/MessageReply00'
import C18SidebarHeader00 from '../../usa/components/C18SidebarHeader00'
import C18SidebarGrowJournal00 from '../../usa/components/C18SidebarGrowJournal00'
import {getGroupInfo} from '../../usa/components/C18utils'
// import C18SidebarGrowJournalHeader00 from '../../usa/components/C18SidebarGrowJournalHeader00'
// import C18SidebarGrowJournalEntry00 from '../../usa/components/C18SidebarGrowJournalEntry00'
import C18SidebarImages00 from '../../usa/components/C18SidebarImages00'
import C18SidebarTasks00 from '../../usa/components/C18SidebarTasks00'
import C18SidebarAlarms00 from '../../usa/components/C18SidebarAlarms00'
import C18SidebarAuditLog00 from '../../usa/components/C18SidebarAuditLog00'
import C18SidebarGeneralInfo00 from '../../usa/components/C18SidebarGeneralInfo00'
import parse from 'html-react-parser'
// import Gauge2 from './Gauge';
// import {init, dbVals} from '../../components/utils/http'
// import InTemp from './InTemp.js';
// import {getParmValue, eqTypes, getChannelType} from '../utils/utils'
// import history from '../../history'
import C18WeatherInfo from '../../usa/components/C18WeatherInfo'
import ChatBotUI from './ChatBotUI';

class Feed01 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
//     cl(props)
//     cl(props.parms.wdg.parms.inc)
    let inc=props.parms.wdg.parms.inc
    if(!inc){inc=[]}
    this.state={
      loaded: false,
      loadMessage: "loading...",
      gjReply: false,
      incGJ: inc.includes("GJ"),
      incMsg: inc.includes("Msg"),
      incTasks: inc.includes("Tasks"),
      incAlarms: inc.includes("Alarms"),
      incActivity: inc.includes("Activity"),
      incNotification: inc.includes("Notification"),
      incAdmin: inc.includes("Admin"),
      incIDoser: inc.includes("IDoser"),
      mode: "",
    }
//     cl(props)
    props.notify({id: "drag", func: this.onDrag})
    this.subscribe_gjUpdated = globs.events.subscribe("GrowJournalUpdated", this.gjUpdated)
    this.loadAll()
  }
  
  showSetState=(obj)=>{
    cl(obj)
    this.setState(obj)
  }
  
  gjUpdated=()=>{
    cl("update grow journal")
    this.loadGJ()
  }
  
  componentWillUnmount=()=>{
    this.subscribe_gjUpdated.remove()
  }
  
  getTimePeriod=()=>{
    let end=Math.floor(getTime())+3*3600
    let begin=end-14*86400
    return [begin,end]
  }
  
  
  loadAll=async()=>{
//     cl("loadall")
    var tot=[]
//     cl(this.props)
//     cl(this.state)
//     cl(this.props.parms)
//     cl(this.props)
// cl(this.props.parms.level)
    this.gi=getGroupInfo(globs.userData.session.groupId)
    if(this.props.parms.zone || (this.props.parms.level!="zone")||this.gi){
//       cl(this.state.incGJ)
      let gj=(false&&this.state.incGJ)?this.loadGJ():Promise.resolve([])
      let cfl=(false&&this.state.incActivity)?this.loadConfigLog():Promise.resolve([])
      let msg=(false&&this.state.incMsg)?this.loadMessages():Promise.resolve([])
      let tasks=(false&&this.state.incTasks)?this.loadTaskItems():Promise.resolve([])
      Promise.all([gj,cfl,msg,tasks]).then(async vals=>{
        let [gj,cfl,msg, tasks]=vals
        tot=gj
          .concat(cfl)
          .concat(msg)
          .concat(tasks)
        tot.sort((a,b)=>{
          if(a.t<b.t){return 1}
          if(a.t>b.t){return -1}
          return 0
        })
//         await this.loadAvatars(tot)
        this.setState({entries: tot, loaded: true})
      })
//         this.setState({entries: tot, loaded: true})
    }else{
//       cl("not valid")
      this.state.loadMessage="No Valid Zone Specified"
//       this.setState({loadMessage: "No Valid Zone Specified", loaded: true})
    }
//     cl("loaded")
//     cl(tot)
//     this.setState({entries: tot, loaded: true})
//     cl(this.state)
  }
  
  loadGJ=async()=>{
//     cl(this.props.parms.zone)
    if(!this.props.parms.zone) return
//     cl(this.props.parms.zone.zoneId)
//     let end=Math.floor(getTime())
//     let begin=end-14*86400
//     cl(this.props.parms)
    let [begin,end]=this.getTimePeriod()
    cl("load gj")
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId,
      body: {zoneId: this.props.parms.zone.zoneId, 
        siteId: globs.userData.session.siteId,
        dispTime: {$gt: begin, $lte: end}}})
    let entries=r.data
    cl(entries)
    entries.forEach(e=>{e.t=e.dispTime; e.u=e.userId; e.ty="GJ"})
//     cl(entries)
//     await this.loadAvatars(entries)
    return entries
//     this.setState({entries: entries, loaded: true})
  }
  
  loadConfigLog=async()=>{
/* config data:
c: 2
d: 1
f: 1
i: 963
t: 1612110648
u: "7r22cX-CBioQjq5y"
z: 0
*/    
//     cl(this.props)
    let [begin,end]=this.getTimePeriod()
//     cl({z: this.props.parms.zone.siteZoneIndex, s: globs.userData.session.siteId,
//         $and:[{t:{$gt: begin}}, {t:{$lte: end}}]})
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/configLog", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {z: this.props.parms.zone.siteZoneIndex, s: globs.userData.session.siteId,
        $and:[{t:{$gt: begin}}, {t:{$lte: end}}]}
      
    })
//     cl(r.data)
    let configs=[]
    let len=r.data.length
    if(len>5){len=5}
    
    for(let i=0; i<len; i++){configs.push(r.data[i])}
    configs.forEach(c=>{c.ty="Activity"})
//     cl(configs)
    return configs
    
  }
  
  loadTaskItems=async()=>{// has to have time field of 't'
//     return[]
    let [begin,end]=this.getTimePeriod()
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      // z: this.props.parms.zone.zoneIndex, s: globs.userData.session.siteId,
      body: {dateTime: {$gt: begin, $lte: end}}})
    let events=r.data.events
//     cl(events)
    let tasks=[]
    r.data.tasks.forEach(t=>{tasks[t.taskId]=t})
//     cl(tasks)
    let ret=[]
    events.forEach(e=>{
      let task=tasks[e.taskId]
//       cl(task)
      ret.push({
        u:task.assignee,
        t:e.dateTime,
        subject:task.subject,
        category: task.category,
        location: task.location,
        taskId: task.taskId,
        ty: "Tasks",
        
      })
    })
//     cl(ret)
    
//     return r.data
//     let tasks=[]
//     r.data.forEach(t=>{
//       t.t=t.beginTime
//       tasks.push(t)
//     })
    return ret//tasks
//     let len=r.data.length
// //     if(len>5){len=5}
//     
//     for(let i=0; i<len; i++){configs.push(r.data[i])}
// //     cl(configs)
//     return configs
    
  }
  
  loadMessages=async()=>{
//     cl("mess")
    let [begin,end]=this.getTimePeriod()
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {toId: globs.userData.session.userId, 
        time: {$gt: begin, $lte: end}
      }})
    let messages=r.data
    messages.forEach(m=>{m.t=m.modTime; m.u=m.fromId; m.ty="Msg"})
//     cl(r.data)
    return messages
//     cl(messages)
//     for(let i=0; i<5; i++){configs.push(r.data[i])}
//     return configs
    
  }
  
  
  
  loadAvatars=async(entries)=>{// adds avatars to entries
    let userList={}
//     cl(entries)
    entries.forEach(e=>{
//       cl(e)
//       let userId=(e.u)?e.u:e.userId
      userList[e.u]=1
    })
    let arr=Object.keys(userList)
//     cl(arr)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {userId:{$in: arr}}})
    let users=r.data
    this.avatars={}// this object can be added on to by thread displays
    users.forEach(u=>{
      this.avatars[u.userId]=u.avatar
    })
    entries.forEach(e=>{
      e.avatar=this.avatars[e.u]
    })
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  
  onDrag=(o)=>{// o has the updated info
//     cl("od")
    cl(o)
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
//         cl(o)
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
    let url=this.props.parms.wdg.parms.link
//     history.push(url)
  }
  
  onChange=(type, vals)=>{
//     cl(type,vals)
//     cl(vals)
    switch(type){
      case "msgEntry":
      case "gjEntry":
//         cl(vals)
        switch(vals.type){
          case "showImage":
            vals.type="image"
            return this.props.onChange(vals)
//           case "closeReply":
//             break
// //             return this.props.onChange(vals)
          default:
            cl(vals)
            this.setState(vals)
            break
        }
        break
      case "header":
//         cl(vals)
        this.props.onChange(Object.assign({},vals,{type:"sidebarMode"}))
        this.setState(vals)
//         this.props.parms.onChange(type,vals)
        break
      default:
        break
    }
//     cl(type)
//     cl(vals)
  }
  
  result=(type)=>{
    switch(type){
      case "new-Grow Journal":
        this.props.onChange({type: "cmd", showCreateGrowJournal: true})
        break
      case "new-Message":
        this.props.onChange({type: "cmd", showCreateMessage: true})
        break
      case "new-To Do":
        this.props.onChange({type: "cmd", showCreateToDo: true})
        break
      default:
        break
    }
//     cl(type)
  }
  
  showNewIcons=()=>{
    return[
      <div key={0}>Create:
        {parse("&nbsp;&nbsp;") }
        <UsaIcon key={0} icon={"new-Grow Journal"} result={this.result}/>
        {parse("&nbsp;&nbsp;") }
        <UsaIcon key={1} icon={"new-Message"} result={this.result}/>
        {parse("&nbsp;&nbsp;") }
        <UsaIcon key={2} icon={"new-To Do"} result={this.result}/>
      
      </div>,
    ]
  }
  
  showGrowJournalEntry=(i,e)=>{
    return
//     cl(e)
    return <GrowJournalWidgetEntry00 
      key={i} 
      parms={{
        entry: e,
        level: this.props.parms.level,
        avatars: this.avatars,
      }}
      onChange={vals=>this.onChange("gjEntry", vals)}
      />
  }
  
  showConfigLogEntry=(i,e)=>{
//     cl(e)
    return <ConfigLogFeedEntry00 key={i}
      parms={{
        entry: e,
        avatars: this.avatars,
      }}
      onChange={vals=>this.onChange("clEntry", vals)}
    />
  }
  
  showTaskitemEntry=(i,e)=>{
    return <TaskItemEntry00 key={i}
      parms={{
        entry: e,
        avatars: this.avatars,
      }}
      onChange={vals=>this.onChange("tiEntry", vals)}
    />
  }
  
  showMessageEntry=(i,e)=>{
//     cl(e)
    return <MessagingFeedEntry00 key={i}
      parms={{
        entry: e,
        avatars: this.avatars,
      }}
      onChange={vals=>this.onChange("msgEntry", vals)}
    />
  }
  
  showGrowJournal=()=>{
//     cl(this.props)
    if(this.props.parms.loadMore) {
      globs.events.publish("loadGrowJournalEntries",this.props.parms.loadEvent)
    }
    let zoneId=(this.gi)?this.gi.groupId:this.props.parms.zoneId
//     cl(zoneId)
    return(
      <div>
        <C18SidebarGrowJournal00 parms={{
          level:this.props.parms.level,
          siteId:this.props.parms.siteId,
          zoneId:zoneId,//this.props.parms.zoneId,
          mode:this.props.parms.mode,// mode, zuci, pageType are just for fui pages
          sideBarNoteId:this.props.parms.sideBarNoteId,
          zuci:this.props.parms.zuci,
          pageType:this.props.parms.pageType,
          onChange:this.props.parms.onChange,
          getPopup:this.props.parms.getPopup,
        }}/>
      </div>
    )
  }
  
  showImages=()=>{
    return(
      <div>
        <C18SidebarImages00 parms={{
          level:this.props.parms.level,
          siteId:this.props.parms.siteId,
          zoneId:this.props.parms.zoneId,
          mode:this.props.parms.mode,// mode, zuci, pageType are just for fui pages
          zuci:this.props.parms.zuci,
          pageType:this.props.parms.pageType,
          onChange:this.props.onChange,
        }}/>
      </div>
    )
  }
  
  showTasks=()=>{
    return(
      <div>
        <C18SidebarTasks00
        parms={{
          level:this.props.parms.level,
          siteId:this.props.parms.siteId,
          zoneId:this.props.parms.zoneId,
          mode:this.props.parms.mode,// mode, zuci, pageType are just for fui pages
          sideBarNoteId:this.props.parms.sideBarNoteId,
          zuci:this.props.parms.zuci,
          pageType:this.props.parms.pageType,
          onChange:this.props.onChange,
          getPopup:this.props.parms.getPopup,
        }}/>
      </div>
    )
  }
  
  showAlarms=()=>{
//     cl("render showAlarms")
//     cl(this.props)
    return(
      <div>
        <C18SidebarAlarms00 parms={{
          level:this.props.parms.level,
          siteId:this.props.parms.siteId,
          zoneId:this.props.parms.zoneId,
          sensor:this.props.parms.sensor,
          mode:this.props.parms.mode,// mode, zuci, pageType are just for fui pages
          zuci:this.props.parms.zuci,
          pageType:this.props.parms.pageType,
          onChange:this.props.parms.onChange,
        }}/>
      </div>
    )
  }
  
  showAuditLog=()=>{
    if(this.props.parms.loadMore) {
      globs.events.publish("loadAuditlogEntries",this.props.parms.loadEvent)
    }
    return(
      <div>
        <C18SidebarAuditLog00 parms={{
          level:this.props.parms.level,
          siteId:this.props.parms.siteId,
          zoneId:this.props.parms.zoneId,
          mode:this.props.parms.mode,// mode, zuci, pageType are just for fui pages
          zuci:this.props.parms.zuci,
          pageType:this.props.parms.pageType,
          onChange:this.props.parms.onChange,
        }}
        />
      </div>
    )
  }
  
  showGeneralInfo=()=>{
    return(
      <div>
        <C18SidebarGeneralInfo00 parms={{
          level:this.props.parms.level,
          siteId:this.props.parms.siteId,
          zoneId:this.props.parms.zoneId,
          mode:this.props.parms.mode,// mode, zuci, pageType are just for fui pages
          zuci:this.props.parms.zuci,
          pageType:this.props.parms.pageType,
          onChange:this.props.parms.onChange,
        }}
        />
      </div>
    )
  }

  showWeatherInfo=()=>{
    return(
      <div>
        <C18WeatherInfo parms={{
          level:this.props.parms.level,
          siteId:this.props.parms.siteId,
          zoneId:this.props.parms.zoneId,
          mode:this.props.parms.mode,
          zuci:this.props.parms.zuci,
          pageType:this.props.parms.pageType,
          forcast: this.props.parms.forcast,
          onChange:this.props.parms.onChange,
        }}
        />
      </div>
    )
  }
 
  showLinkBot=()=>{
    return(
      <ChatBotUI />
    )
  }

  showItems=()=>{
    // cl(this.props.parms.mode)
    switch(this.props.parms.mode){
      case "images":
        return this.showImages()
      case "growJournal":
        return this.showGrowJournal()
      case "tasks":
        return this.showTasks()
      case "alarms":
        return this.showAlarms()
      case "auditLog":
        return this.showAuditLog()
      case "generalInfo":
        return this.showGeneralInfo()
      case "weatherInfo":
        return this.showWeatherInfo()
      case "linkBot":
        return this.showLinkBot()
          
    }
    
  }
  
  showItemsO=()=>{
//     cl(this.state)
    let ents=this.state.entries
//     for(let i = 0 ; i < 2; i++){ents.push(this.state.entries[i])}
//     cl(ents)
    return(
      <div style={{textAlign: "left"}}>
        {this.showNewIcons()}
        {ents.map((e,i)=>{
          switch(e.ty){
            case "GJ":
              return this.showGrowJournalEntry(i,e)
            case "Msg":
              return this.showMessageEntry(i,e)
            case "Tasks":
              return this.showTaskitemEntry(i,e)
            case "Activity":
              return this.showConfigLogEntry(i,e)
          }
//           if(e.growJournalId){return this.showGrowJournalEntry(i,e)}
//           if(e.i){return this.showConfigLogEntry(i,e)}
//           if(e.messageId){return this.showMessageEntry(i,e)}
        })}
      </div>
    )
  }
  
  gjReply=(pos, color)=>{
    return(
      <GrowJournalReply00 
        parms={{pos: pos, color: color, entry: this.state.entry,
          avatars: this.avatars,
        }}
        onChange={vals=>this.onChange("gjEntry", vals)}
      />
    )
  }
  
  msgReply=(pos, color)=>{
    return(
      <MessageReply00 
        parms={{pos: pos, color: color, entry: this.state.entry,
          avatars: this.avatars,
        }}
        onChange={vals=>this.onChange("gjEntry", vals)}
      />
    )
  }
  
  replies=(pos,color)=>{
    if(this.state.gjReply){return this.gjReply(pos,color)}
    if(this.state.msgReply){return this.msgReply(pos,color)}
  }
  
  showHeader=()=>{
//     cl(this.props)
    return(
      <C18SidebarHeader00 parms={{
        onChange: o=>this.onChange("header",o),
        level:this.props.parms.level,
        mode:this.props.parms.mode,
      }}
      />
    )
  }
  
  render() {
//     cl("render Feed01")
//     cl(this.state)
    let p = this.props.parms;
//     cl(p)
    let p2=p.wdg.parms
    let dm=5
    let color=p2.bgColor
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
//     cl(p.x,dm)
//     cl(this.state.drag.x)
//     cl(this.state.dragStart.x)
    if(!p.x){x=0; y=0}
    if(this.state.loaded){
      if(this.state.gjReply || this.state.msgReply){
        let pos={x: x, y: y, w: p.w-2*dm, h: p.h-2*dm}
        return this.replies(pos, color)
//         return this.gjReply(pos, color)
      }else{
        //onClick={this.onClick} onContextMenu={this.cm} onMouseDown={this.md}
//             <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, textAlign: "center", borderRadius: 10, backgroundColor: color,
//               overflowY: "auto",
//             }} >
        return(
            <div>
              {this.showHeader()}
              {this.showItems()}
            </div>
          );
      }
    }else{
      return <div>{this.state.loadMessage}</div>
    }
  }
}

export default Feed01;
