import React from 'react';
import C18Select01 from '../../usa/components/C18Select01'
import C18Duration00 from '../../usa/components/C18Duration00'
import {cl,globs} from '../../components/utils/utils';

class Co2_800Generator extends React.Component{
  constructor(props) {
    super(props);
    this.state={}
    this.updateFui()
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
//     this.props.getValue(props.ind, {type: "array", count: 2})
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
  }

    updateFui=()=>{
      if(!this.changed){
        this.props.getValue(this.props.ind, {type: "array", count: 2})
      }
    }

    savePageEvent=()=>{
      this.changed=false
    }

  onChange=(type,vals)=>{
    cl(type,vals)
    let st=this.state
    let pr=this.props
    this.changed=true
    switch(type){
      case "upd":
        if("mode" in vals){
//           let offTime=
          let vals2=pr.value.slice(0)
          let v=(vals.mode=="0")?60:0
          vals2[0]=v
          vals2[1]=v
          pr.onChange(pr.ind,
            {value: vals2})
        }
        break
      case "dur":
        let vals2=pr.value.slice(0)
        vals2[vals.onOff=="onTime"?0:1]=vals.duration
        cl(vals2)
        pr.onChange(pr.ind,
          {value: vals2})
        break
      default:
        break
    }
  }

  showDur=(parms)=>{
    let st=this.state
    let pr=this.props
//     let val=st[parms.onOff]
    let val=pr.value[parms.onOff=="onTime"?0:1]||0
    return(
      <div>
        <label>{parms.title}</label>
        <C18Duration00
        parms={{
          duration:val,//so.mixtime,
          onChange:e=>{
            this.onChange("dur",{onOff:parms.onOff,duration:e.duration})
          },
          saveOK:true,
          unit:"(HH:MM:SS)",
        }}
        />
        <div className="clearfloat"></div><br/>
      </div>
    )
  }

  showOnOffTimes=()=>{
    return(
      <div>
      <input type="text"/>
      </div>
    )
  }

  showGenTankSelect=()=>{
    let st=this.state
    let pr=this.props
    let tankMode=(+(pr.value[1]||0))?0:1
    let opts=[
      {v:0,t:"Tank"},
      {v:1,t:"Generator"},
    ]
    return(
      <div>
        <C18Select01 parms={{
          label:"Mode",
          valueName:"mode",
  //         inpMode:st.inpMode,
          mode:tankMode,
          opts:opts,
          onChange:this.onChange,
        }}/>
      </div>
    )
  }

  render(){
    let st=this.state
    let pr=this.props
//     cl(this.props)
//     let section =(this.props.custom.unit)
    let showOnOff=(+(pr.value[1]||0))?true:false
    return(
      <div>
      {this.showGenTankSelect()}

      {showOnOff&&this.showDur({
        title:"CO2 On Time",
        onOff:"onTime",
      })}
      {showOnOff&&this.showDur({
        title:"CO2 Off Time",
        onOff:"offTime",
      })}

      </div>
    )
  }
}

export default Co2_800Generator ;
