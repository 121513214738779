import React from 'react';
import C18Duration00 from '../../usa/components/C18Duration00'
import {pInd} from '../../components/utils/paramIds'
import {getParamId,getPearlUsed} from '../../usa/utils/utils';
import {dbVals,putZValue,sendArray} from '../../components/utils/http'
import {HSVtoRGB,makeColor} from '../../usa/components/C18utils'
// import FUISelect from './FUISelect';
// import {proportionals} from '../utilsFui'
import {cl, globs,constant, getTimeI,proportionals} from '../../components/utils/utils';

class HumDeHumFull extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      cycleOn:30,
      cycleOff:60,
      dhltTemp:45,
      heatBoost:4.5,
      tempUnit:(["F","C"][props.current.units[0]||0])
//       show: chType!=0
    }
    
    this.loadInfo()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  savePageEvent=()=>{
    this.saveHumDeHumData()
  }

  loadInfo=()=>{
    let pr=this.props
    this.zuci=pr.zuci.split("-")
    let isPearl=pr.current.gwType==1900
//     humidityOverride = new List<EquipmentType>() - from LinkConn:
//     {
//         EQ_VENT, EQ_CURT, EQ_ONOFF, EQ_PUMP, EQ_HID, EQ_CO2, EQ_MZONE, EQ_MZONE2, EQ_MIXV, EQ_MIXV_A,
//     };

    this.hums=[constant.EQ_VENT, constant.EQ_CURT, constant.EQ_ONOFF, constant.EQ_PUMP, constant.EQ_HID, constant.EQ_CO2, constant.EQ_MZONE, constant.EQ_MZONE2, constant.EQ_MIXV, constant.EQ_MIXV_AN]
//     this.proportionals=[constant.EQ_VENT, constant.EQ_CURT, constant.EQ_MIXV, 
//       constant.EQ_MZONE2, ,constant.EQ_GEN_PID, constant.EQ_VAROUT, constant.EQ_MIXV_AN]
    this.dehumInfo={
      cycleOn:"Humidity Override On Time",
      cycleOff:"Humidity Override Off Time",
      dhltTemp:"Dehumidify Low Outside Temperature Threshold",
      heatBoost:"Dehumidify Heat Boost",
    }
    let defaults={
      cycleOn:30,
      cycleOff:30,
      dhltTemp:40,
      heatBoost:5,
    }
    this.zuci=pr.zuci.split("-")
    let ci=pr.current.channelInfo
//     cl(pr)
    this.chans={}
//     cl(this.hums)
    let i2=1
    var pearlUsed=[],chans=[]
    let zInd=+this.zuci[0]
    if(isPearl){
      pearlUsed=getPearlUsed(zInd)
      chans=Object.keys(pearlUsed).map(k=>{return pearlUsed[k].chInd})
    }
//     cl(pearlUsed)
    ci.forEach((ch,i)=>{ 
//       cl(ch)
//       cl(ch.channelType)
//       cl([1,2,3].includes(ch.channelType))
//       cl(this.hums.includes(ch.channelType))
//       cl(pr.current.gwType)
      if(isPearl){
        if(chans.includes(i)){
//           cl(ch)
          ch.chId=i+1
          if(!ch.channelName){ch.channelName="None"}
          if(this.hums.includes(+ch.channelType) ){this.chans[i]=ch}
        }
      }else{
        if(ch.used||(pr.current.gwType==1900)){ch.chId=i2++}
        if(this.hums.includes(+ch.channelType) ){this.chans[i]=ch}
      }
    })
    let hdhSettings=this.getSaveHDH("get")
    let z=this.zuci[0]
    let vals={}
    Object.keys(this.dehumInfo).forEach(k=>{
      let v=dbVals.z[z][255][getParamId("configuration_zone_settings",this.dehumInfo[k])]
      if((v==undefined)||(v==null)){v=defaults[k]}
      vals[k]=v
    })
    vals.hdhSettings=hdhSettings
//     cl(hdhSettings)
    Object.assign(this.state,vals)
//     this.state.hdhSettings=hdhSettings
  }
  
//http://ngsg.link4cloud.com:3104/usa/c18/fui/HumDeHum/1-0-240-0
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    globs.events.publish("savePageEnable",true)
    switch(type){
      case "upd":
        this.setState(vals)
        break
      case "hum":
//         cl(vals)
        let hs=Object.assign({},st.hdhSettings)
        let v=vals.val
//         cl(vals)
        hs[vals.ch][vals.humStage]=(v.length>0)?+v:v
        this.setState({hdhSettings:hs})
        break
      default:
        break
    }
    
  }
  
  saveGlobalHdh=(z,hdhDBSettings)=>{
    let st=this.state
    Object.keys(this.dehumInfo).forEach(k=>{
      let pid=getParamId("configuration_zone_settings",this.dehumInfo[k])
      if (putZValue(z,255,pid,st[k])){
        hdhDBSettings.push({
          c:255,// zone wide
          d:st[k],
          f:1,
          i:pid,
          t:getTimeI(),
          z:z,
        })
      }
    })
  }
  
  getSaveHDH=(getSave)=>{
// 0, 1, "0", and "1" are valid values for the stage override
// the "none" or null condition is represented by -1
    let st=this.state
    let z=this.zuci[0]
    let tab=pInd[1800].channels_configuration
    let hdhSettings={}// used for both get and save
    let fields=["stageDehumidfy1","stageDehumidfy2","stageColdDehumidfy","stageHumidfy"]
    if(getSave=="get"){
      Object.keys(this.chans).forEach(k=>{
        let ch=dbVals.z[z][k]
        hdhSettings[k]=fields.map(f=>{
          let v=ch[getParamId("configuration_channels",f)]
          if(((typeof v=="string")&&(v.length==0))||(v==null)){return ""}
          return +v
        })
      })
      return hdhSettings
    }else{
      let now=getTimeI()
      let hdhDBSettings=[]
      Object.keys(this.chans).forEach(k=>{
        let ch=dbVals.z[z][k]
        fields.forEach((f,i)=>{
          let pid=getParamId("configuration_channels",f)
          let v=st.hdhSettings[k][i]
//           if((typeof v=="string")&&(v.length==0)){v=null}
//           cl(v)
//           if(v!=null){v=+v}
//           if(v.length==0)
          if (putZValue(z,k,pid,v)){
            hdhDBSettings.push({
              c:+k,// zone wide
              d:v,//st.hdhSettings[k][i],
              f:1,
              i:pid,
              t:now,
              z:z,
            })
          }
        })
      })
      this.saveGlobalHdh(z,hdhDBSettings)
//       cl(hdhDBSettings)
      sendArray(hdhDBSettings,this.props.current.virtual).then(e=>{globs.events.publish("saveOK",true)})
    }
    
  }
  
  saveHumDeHumData=()=>{
    this.getSaveHDH("save")
    
  }
  
  showHDHSelect=(ch,humStage)=>{// ch is the channel index
//     cl(ch)
    let st=this.state
    let ci=this.chans[ch]
    let val=st.hdhSettings[ch][humStage]
//     cl(val)
    if((typeof val=="string")&&(val.length==0)){val=-1}
    var color
    var opts
//     if(ch==6){cl(ci)}
    if(proportionals.includes(+ci.channelType)){
//       cl(val)
      color=(val==-1)?"#EEFFFF":color=makeColor(0,0.3,val/100,0.4,1)
//       if(val==-1){
//         color=
//       }else{
//         color=makeColor(0,0.3,val/100,0.4,1)
//       }
      opts=[-1,...Array(100).keys()].map(k=>{
        let show=(k>=0)?`${k}%`:"---"
        return(
          <option key={k} value={k}>{show}</option>
        )
      })
    }else{
      color=["#EEFFFF","#EEAAAA","#EEEE88"][+val+1]
      opts=[
        <option key="1" value="">---</option>,
        <option key="2" value="0">Off</option>,
        <option key="3" value="1">On</option>
      ]
    }
    return(
      <td key={humStage}>
        <select
        style={{padding: 10,borderRadius:10,backgroundColor:color}}
          value={val}
          onChange={e=>this.onChange("hum",{ch:ch,humStage:humStage,val:e.target.value})}
        >
        {opts}
        </select>
      </td>
    )
  }
  
  showHDHHeads=()=>{
    let heads=["Chan","Name","DeHum1","DeHum2","DeHumLT","Hum"]
    return (
      <tr key="heads">
      {heads.map((h,i)=>{return <th key={i}><h3>{h}</h3></th>})}
      </tr>
    )
//     return(
//       <tr>
//       <th>Chan</th>
//       <th>Name</th>
//       <th>DeHum1</th>
//       <th>DeHum2</th>
//       <th>DeHumLT</th>
//       <th>Hum</th>
//       </tr>
//     )
  }
  
  showChannels=()=>{
    let pr=this.props
    let ci=pr.current.channelInfo
//     cl(this.chans)
    let rows=Object.keys(this.chans).map(k=>{
      let ch=this.chans[k]
//       cl(Array(4))
//       cl(Array(4).keys())
//       cl([...Array(4).keys()])
//       let hdhRow=Array(4).keys().map(humS=>{
      let hdhRow=[...Array(4).keys()].map(humS=>{
        return this.showHDHSelect(k,humS)
      })
      return <tr key={k}><td height="49">{ch.chId}</td><td>{ch.channelName}</td>{hdhRow}</tr>
    })
    return(
      <div>
      <table><tbody>
      {this.showHDHHeads()}
      {rows}
      </tbody></table>
      </div>
    )
  }
  
  render(){
    let st=this.state
    let unit=<span style={{cursor:"default",marginLeft:-60}}>&deg;{st.tempUnit}</span>
    
    return(
      <div>
      <div style={{overflowX:"auto"}}>
      <div className="clearfloat"/><br/>
      <h2>Hum and DeHum Stage Overrides:</h2>
      {this.showChannels()}
      </div>
      <table><tbody>
      <tr>
      <td>
        <label>Hum/Dehum Cycle On Time</label>
        <C18Duration00 parms={{
        width:100,
        duration:st.cycleOn,
        onChange:e=>this.onChange("upd",{cycleOn:e.duration}),
        saveOK:true,//this.props.parms.saveOK
        }}/>
        <div className="clearfloat"/><br/>
      </td>
      <td>
        <label>Use DHLT when outside temperature goes below</label>
        <input 
          style={{width:100}}
          type="number"
          value={st.dhltTemp}
          onChange={e=>this.onChange("upd",{dhltTemp:e.currentTarget.value})}
          min="0"
          max="80"
          step="0.1"
        />{unit}
      </td>
      </tr>
      <tr>
      <td>
        <label>Hum/Dehum Cycle Off Time</label>
        <C18Duration00 parms={{
        width:110,
        duration:st.cycleOff,
        onChange:e=>this.onChange("upd",{cycleOff:e.duration}),
        saveOK:true,//this.props.parms.saveOK
        }}/>
      </td>
      <td>
        <label>When any DeHum stage is active, adjust temp setpoint by:</label>
        <input 
          style={{width:100}}
          type="number"
          value={st.heatBoost}
          onChange={e=>this.onChange("upd",{heatBoost:e.currentTarget.value})}
          min="-10"
          max="10"
          step="0.1"
        />{unit}
      </td>
      </tr>
      </tbody></table>
      </div>
    )
  }
}

export default HumDeHumFull ;
