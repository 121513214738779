import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18DateFormat00 from './C18DateFormat00'
import C18Anchor00 from './C18Anchor00'
import history from "../../history"
import {cl,globs,constant,dateToDisplayDate,calcTimezoneOffset} from '../../components/utils/utils';
import {wsTrans} from '../utils/utils'
import { DateRangePicker } from 'react-date-range';
import {loadSitesInfo,loadZonesInfo,loadPrivsInfo,privs,
  loadUsersInfo,loadReportsInfo} from './C18utils'

class C18ViewReport extends React.Component{
  constructor(props) {
    super(props);

    let startDate = new Date()
    let endDate = new Date()
    this.rowsLength=-1;
    
    this.state={
      userId: globs.userData.session.accountId,
      accountId: globs.userData.session.accountId,
      title: "",
      description: "",
      users: [],
      sites: [],
      zones: [],
      content: [],
      customStart:0,
      customEnd:0,
      skip:0,
      limit:50,
      period: "",
      absrel: "absolute",
      startDate: startDate, //new Date(),
      endDate: endDate, //new Date(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      data: [],
      showFull: false,
      showTsk: false,
      loaded: false,
    }
    this.loadInfo()
//     this.makeReport()
    this.setBreadCrumbs()
}

//   componentDidMount=()=>{
//     cl("did mount")
//   }

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Reports", url:`/usa/c18/reports/reportList`},
              {t:"View Report", url:`/usa/c18/reports/viewReport/${this.props.parms.reportId}`},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
    await loadUsersInfo()
    await loadPrivsInfo()
    await loadReportsInfo()
    await this.makeReport(this.state.skip)
  }
  
  makeReport=async(skip)=> {
    // cl(skip)
    if(skip<0){skip=0}
    let end=this.state.count-this.state.limit
    if(end<0){end=0}
//     cl(end)
    if(skip>end){skip=end}
//     cl("make report")
    let gri=globs.reportsInfo.info
//     cl(gri)
    let newState = {loaded: true}
    let report = gri.find((r) => r.reportId == this.props.parms.reportId)
    // cl(report)
    if (report) {
        report.writePriv=(privs("account",0,constant.AREA_PRIVS_WRITE)!=0)
      // load data
//         cl("loading data")
//         cl(report)
        // process absrel & date
        let startEnd = this.makeStartEndDate(report)
//         cl(startEnd)
//         this.setState({startDate: startEnd[0], endDate: startEnd[1]})
//         this.setState(report)
        
        // let reportId="AqEU3-v7a25fNSuW"
        let query={reportId:report.reportId,fromItem:startEnd[0],toItem:startEnd[1],
            skip:skip,limit:this.state.limit,}
        // cl(query)
        let ret = await wsTrans("usa", {cmd: "cRest", uri: "/s/reportWriter", method: "retrieve", 
          sessionId: globs.userData.session.sessionId,
          body: query})
        // cl(ret)
        if (ret) {
          // cl(ret.data)
          newState = Object.assign(report,{startDate: startEnd[0], endDate: startEnd[1],
            skip:skip,limit:this.state.limit,
            data: ret.data.report,count:ret.data.count, loaded: true, timezone: report.timezone || this.state.timezone})
//           this.setState({data: ret.data.report,count:ret.data.count})
          // cl(report)
          // if (report.content.includes("sensors")) {
          //   let hist=await wsTrans("usa", {cmd: "cRest", uri: "/s/historySummary", method: "retrieve", 
          //   sessionId: globs.userData.session.sessionId, body: {sensors: []}})
          //   cl(hist)
          // }
        }
    }
    this.setState(newState)
  }

  makeStartEndDate=(r)=> {
    let endDate = new Date()
    let startDate = new Date()
    let end_ts = endDate.getTime() // now time in report tz
    let start_ts = startDate.getTime()
    let currDay

//     r.period = "thisWeek"

    switch (r.period) {
      case "today":
        // cl([startDate.getHours(), startDate, endDate])
        startDate.setHours(0, 0, 0, 0)
        // cl([startDate, endDate])
        start_ts = startDate.getTime()
        break
      case "yesterday":
        startDate.setDate(startDate.getDate() - 1)
        startDate.setHours(0, 0, 0, 0)
        start_ts = startDate.getTime()
        endDate.setDate(startDate.getDate() + 1)
        endDate.setHours(0, 0, 0, 0)
        end_ts = endDate.getTime()
        break
      case "last7":
        startDate.setDate(endDate.getDate() - 7)
        start_ts = startDate.getTime()
        break
      case "last30":
        startDate.setDate(endDate.getDate() - 30)
        start_ts = startDate.getTime()
        break
      case "last60":
        startDate.setDate(endDate.getDate() - 60)
        start_ts = startDate.getTime()
        break
      case "lastWeek":
        currDay = startDate.getDay()
        startDate.setDate((startDate.getDate() - currDay) - 7)
        startDate.setHours(0, 0, 0, 0)
        start_ts = startDate.getTime()
        endDate.setDate((endDate.getDate() + (7 - currDay) - 7))
        endDate.setHours(0, 0, 0, 0)
        end_ts = endDate.getTime()
        break
      case "thisWeek":
        currDay = startDate.getDay()
        startDate.setDate(startDate.getDate() - currDay)
        startDate.setHours(0, 0, 0, 0)
        start_ts = startDate.getTime()
        break
      case "lastMonth":
        startDate.setMonth(startDate.getMonth() - 1, 1)
        startDate.setHours(0, 0, 0, 0)
        start_ts = startDate.getTime()
        endDate.setMonth(endDate.getMonth(), 1)
        endDate.setHours(0, 0, 0, 0)
        end_ts = endDate.getTime()
        break
      case "thisMonth":
        startDate.setMonth(endDate.getMonth(), 1)
        startDate.setHours(0, 0, 0, 0)
        start_ts = startDate.getTime()
        break
      case "custom":
        if (r.absrel == "absolute") {
          end_ts = r.customEnd
        } else {
          let diff = r.customStart - r.endDate
          end_ts = endDate.getTime()
          start_ts = end_ts - diff
        }
        break
    }
    startDate = new Date(start_ts)
    endDate = new Date(end_ts)

//     cl(r.timezone)
//     cl([startDate, endDate])
    
    // apply timezone correction
    // cl([r.timeZone, this.state.timezone])
    endDate = new Date(endDate.toLocaleString('en-US', {timeZone: r.timezone || this.state.timezone}));
    startDate = new Date(startDate.toLocaleString('en-US', {timeZone: r.timezone || this.state.timezone}))

//     cl([startDate.getTime(), endDate.getTime()])

    return [startDate.getTime(), endDate.getTime()]
  }

  onClick=(type, val)=> {
    let st=this.state
    let skip=st.skip
    let limit=st.limit
    // cl([type, val])
    switch (type) {
      case 'edit':
        history.push(`/usa/c18/reports/editReport/${val}`)
        break
      case 'download':
        this.downloadReport()
        break
      case 'showFull':
        this.setState({showFull: val})
        break
      case "begin":
        return this.makeReport(0)
      case "back":
        return this.makeReport(skip-limit)
      case "forward":
        return this.makeReport(skip+limit)
      case "end":
        return this.makeReport(st.count-limit)
      case "GrowJ":
        // cl(type)
        return history.push(val)
      case "Tasks":
        // cl(type)
        return history.push(val)
      case "showTsk":
        // cl("show tasks")
        this.setState({showTsk: val})
        break
    }
  }

  downloadReport=async()=>{
    // cl(this.state)
//     let cp=this.state.currentPreset
    let da0=new Date()
    let tzo=da0.getTimezoneOffset()
//     cl(tzo)
//     let query=`session=${globs.userData.session.sessionId}&from=${Math.floor(cp.fromTime)}&to=${Math.floor(cp.toTime)}&tzo=${tzo}`
//     this.sensors.forEach((s,i)=>{
//       query+=`&s${i}=${s.s}&z${i}=${s.z}&c${i}=${s.c}&i${i}=${s.i}`
//     })
//     cl(query)
    let da=dateToDisplayDate(da0,"yyyy_mm_dd_hh_mm_ss",tzo)
    // cl(da)
    let query=`sessionId=${globs.userData.session.sessionId}&type=report&reportid=${this.state.reportId}`
    let url=`${constant.expressUrl}/usa/csv/report_download_${da}.csv?${query}`
    // cl(url)
    // cl("***********************reload")
    window.location.href=url
  }
  


  showHeader=()=> {
    
    return (
      <div className="section-header">
        <div className="section-controls">
          { (this.state.content.includes("tasks") || this.state.allContent) && 
            <button style={{marginRight: 10}} onClick={()=>this.onClick('showTsk', !this.state.showTsk)} type="button" className="outlined">{(this.state.showTsk) ? "Show Completed Tasks" : "Hide Completed Tasks"}</button>
          }
          <button onClick={()=>this.onClick('showFull', !this.state.showFull)} type="button" className="outlined">{(this.state.showFull) ? "Hide Report Body" : "Expand Report Body"}</button>
          {this.state.writePriv&&
            <a onClick={()=>this.onClick('edit', this.state.reportId)} className="settings-button material-icons-outlined flip" aria-label="edit report">build</a>
          }
          {(this.rowsLength > 0) &&
            <button onClick={()=>this.onClick('download', "")} type="button" className="material-icons-outlined download">file_download</button>   
          }
          
        </div>
        <div className="clearfloat"></div>
      </div>
    )
  }
  
  makeGJLinkUrl=(gjr)=>{
//     cl(gjr)
    var url
    let configPart=""
    let cats=gjr.category.split(", ")
//     cl(cats)
    cats.forEach(ca=>{
      let parts=ca.split("-")
      let cnt=(ca.match(/-/g)||[]).length
      if(parts.length==5){
        configPart=`/${parts[0]}/${parts[1]}-${parts[2]}-${parts[3]}-${parts[4]}`
      }
    })
//     cl(configPart)
    switch(gjr.level){
      case "account":
        url=`/usa/c18/sites/none/SPgrowJournal`
        break
      case "site":
//         cl(gjr)
        url=`/usa/c18/sites/${gjr.siteId}/SPgrowJournal`
        break
      case "zone":
        url=`/usa/c18/sites/${gjr.siteId}/zones/${gjr.zoneId}/SPgrowJournal`
        break
      case "config":
        url=`/usa/c18/sites/${gjr.siteId}/fui${configPart}/SPgrowJournal`
        break
      default:
        url=`/usa/c18/sites/SPgrowJournal`
        break
    }
    return url
  }

  makeTaskLinkUrl=(tsk)=>{
//     cl(gjr)
    // cl(tsk)
    var url=`/usa/c18/tasks`
    let configPart=""
    let cats=tsk.category.split(", ")
    // cl(cats)
    cats.forEach(ca=>{
      let parts=ca.split("-")
      let cnt=(ca.match(/-/g)||[]).length
      if(parts.length==5){
        configPart=`/${parts[0]}/${parts[1]}-${parts[2]}-${parts[3]}-${parts[4]}`
      }
    })
    // cl(configPart)
// TODO add level for tasks?
    // if (tsk.siteId && tsk.siteId != "sites" && tsk.siteId != "taskEdit") {
    //   url=`/usa/c18/sites/${tsk.siteId}/SPtasks`
    // }
    // if (tsk.zoneId && tsk.zoneId != "") {
    //   url=`/usa/c18/sites/${tsk.siteId}/zones/${tsk.zoneId}/SPtasks`
    // }
    // if (configPart != "") {
    //   url=`/usa/c18/sites/${tsk.siteId}/fui${configPart}/SPtasks`
    // }
    switch(tsk.scope){
      case "account":
        url=`/usa/c18/tasks`
        break
      case "site":
//         cl(gjr)
        url=`/usa/c18/sites/${tsk.siteId}/SPtasks`
        break
      case "zone":
        url=`/usa/c18/sites/${tsk.siteId}/zones/${tsk.zoneId}/SPtasks`
        break
      case "config":
        // history.push(`/usa/c18/sites/${msg.siteId}/fui/${msg.pageType}/${msg.zuci}`)
        // http://ngsg.link4cloud.com:3104/usa/c18/fui/channel_Irrigation_Scheduled/0-0-0-0   
        url=`/usa/c18/sites/${tsk.siteId}/fui${configPart}/SPtasks`
        break
    }
    return url
  }
  
//   makeGJLink=(gjr)=>{// grow journal report object
//     cl(gjr)
//     var url
//     switch(gjr.level){
//       case "account":
//         url=`/usa/c18/sites/none/SPgrowJournal`
//         return (
//           <C18Anchor00 to={url}><strong>GrowJ</strong></C18Anchor00>
//         )
//         break
//       case "site":
// //         cl(gjr)
//         url=`/usa/c18/sites/${gjr.siteId}/SPgrowJournal`
//         return (
//           <C18Anchor00 to={url}><strong>GrowJ</strong></C18Anchor00>
//         )
//         break
//       case "zone":
//       case "config":
//         url=`/usa/c18/sites/${gjr.siteId}/zones/${gjr.zoneId}/SPgrowJournal`
//         return (
//           <C18Anchor00 to={url}><strong>GrowJ</strong></C18Anchor00>
//         )
//         break
//       default:
//         url=`/usa/c18/sites/SPgrowJournal`
//         return (
//           <C18Anchor00 to={url}><strong>GrowJ</strong></C18Anchor00>
//         )
//         break
//     }
//     
//   }
  
  makeCategory=(type,category)=>{
    if(((type=="GrowJ") || type == "Tasks") &&category){
//       cl(category)
      let tags2=category.split(", ")
//       cl(tags2)
      return tags2.map((t,i)=>{
        return(
            <span id="/sidebar/growJournalEntry/tags" key={i} className={`grow-journal-tag ${t}`}>{t}</span>
        )
      })
    }

    if(category.includes("pid")) {
      if(!category.includes("undefined")) {
        console.log(category)
        let value = category.split(":")
        category = value[2].trim()
      }
      else {
        let value = category.split(":")
        category = `${value[0].trim()}:${value[1].trim()}`
      }
    }

    return category
  }

  showReport=()=> {
    let banding=(globs.usersInfo.uiInfo||{}).tableBanding||true
    let rows = []
    let data = this.state.data
    // cl(data)
    let body
    data.forEach((d, i)=>{
      let tableBand=(((i+1)%2)&&banding)?"tableBand":null
      body = (d.body) ? d.body : ""
      var rowStyle,onClick
      if (d.type == "Tasks") {
        onClick= e=>this.onClick(d.type, this.makeTaskLinkUrl(d))
        // check if task is completed to determine whether to hide or not
        rowStyle={"cursor":"pointer", "display": (this.state.showTsk && d.status == "done") ? "none" : ""}
        // task status is subject
        // if (d.status) d.subject+= `[Status: ${d.status}]`
      }
      if(d.type=="GrowJ"){
        onClick= e=>this.onClick(d.type, this.makeGJLinkUrl(d))
        rowStyle={"cursor":"pointer"}
      }
        rows.push(
          <tr key={i} style={rowStyle} className={tableBand} onClick={onClick}>
            <td>{d.type}</td>
            <td>{d.zone}</td>
            <td>{d.user}</td>
            <td>{d.time}</td>
            <td>{this.makeCategory(d.type,d.category)}</td>
            <td>{(this.state.showFull || d.subject.length <= 50) ? d.subject : d.subject.substring(0, 50) + "..."}</td>
            <td>{(this.state.showFull || body.length <= 50) ? body : body.substring(0, 50) + "..."}</td>
          </tr>
        )
    })

    this.rowsLength = rows.length;
    // <td>{(this.state.showFull || d.body.length <= 0) ? d.body : d.body.substring(0, 50) + "..."}</td>
    if (!this.state.loaded) {
       return(
        <div className="table-container">
         <h3 style={{textAlign: "center"}}>
            Loading report...
          </h3>
        </div>
        )
    }
    return(
      <div className="table-container">
        { rows.length ?
        <table className="list report"><tbody>
          <tr>
            <th>Type</th>
            <th>Location</th>
            <th>User</th>
            <th>Time ({this.state.timezone})</th>
            <th>Category</th>
            <th>Subject</th>
            <th>Body</th>
          </tr>
          {rows}
        </tbody></table>
        :
          <h3 style={{textAlign: "center"}}>
          No data found for this report
          </h3>
        }
      </div>
    )

  }

  showPaging=()=> {
    let first=this.state.skip+1
    let last=first+this.state.limit-1
    let count=this.state.count
    if(last>count){last=count}
    if(!count) {
      first = 1
      last = 1
      count = 1
    }
//     cl([first,last,count])
    return (
      <div className="report-paging paging">
        <button type="button" className="back-to-beginning" aria-label="first page"
          onClick={e=>this.onClick("begin")}>&#171;</button>
        <button type="button" className="back" aria-label="previous page"
          onClick={e=>this.onClick("back")}>&#8249;</button>
        <span className="page-range">{`${first}-${last} of ${count}`}</span> 
        <button type="button" className="forward" aria-label="next page"
          onClick={e=>this.onClick("forward")}>&#8250;</button>
        <button type="button" className="forward-to-end" aria-label="last page"
          onClick={e=>this.onClick("end")}>&#187;</button>
      </div>
    )
  }

  showCharts=()=>{

  }

  showViewReport=()=> {
    let showReport = this.showReport()
    let showHeader = "";
    if (this.rowsLength > -1) {
      showHeader = this.showHeader()
    }
    return(
            <div className="reports-and-graphs">
              {showHeader}
              {showReport}
              {this.showPaging()}
            <div className="clearfloat"></div>
            </div>
    )
  }  
  
  render(){
//     cl(this.state)
    return this.showViewReport();
  }
}

export default C18ViewReport;
