import React from 'react';
import C18Input00 from './C18Input00'
import C18Anchor00 from './C18Anchor00'
import C18InputError00 from './C18InputError00'
import C18Button00 from './C18Button00'
import C18Select01 from './C18Select01'
import {wsTrans} from '../utils/utils'
import {addToAdminLog, saveToAdminLog,loadUsersInfo, getUserIndex, loadDevicesInfo,loadAccountInfo,privs,acctFeature} from './C18utils';
import {cl, globs, constant, allCountries, allStates, allTimezones, allThemes, getTime} from '../../components/utils/utils';
import history from '../../history'
import C18SubMenuHeader00 from './C18SubMenuHeader00'

class C18EditUser00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      showSuper:privs("super","",constant.AREA_PRIVS_READ),
      name:"",
      email:"",
      thirdParty:"",
      thirdPartyIdResult: null,
      tabs:"AddUser",
    }
//     this.roles=[
//       {v:"superman",t:"Superman"},
//       {v:"superadmin",t:"Super Admin"},
//       {v:"mortal",t:"Mortal"},
//     ]
    this.loadInfo()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveUser)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      let name=(this.name)?`Edit ${this.name}`:"Add User"
      
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Users", url:`/usa/c18/admin/manageUsers3`},
              {t:`${name}`, url:`/usa/c18/admin/editUser/${this.userId}`},
            ]},
          pageTitle:name
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
//     cl("unmount")
    this.subscribe_savePageEvent.remove()
//     let popInfo={text:"Changes were made to an Area. Do you want to Save them?.", buttons:["No","Save"]}
//     checkSave(this,this.saveArea,popInfo)
  }
  
  addUser=async (name,email)=>{
//     cl(this.state)
//     cl(this.inviteUser)
    cl(`add user: ${email}`)
    let adminAdds = []
    saveToAdminLog(adminAdds, "addUser", this.state.addEmail)
    addToAdminLog(adminAdds)
    cl(globs.usersInfo.info)
    let user={name: name, email: email,active:true}
    let res= await wsTrans("usa", {cmd: "cRest", uri: "/s/manageUsers", sessionId: globs.userData.session.sessionId, 
      method: "create", body: user})
    user.userId=res.data.userId
    user.token=res.data.token
    globs.usersInfo.info.push(user)
    cl(user)
//     this.inviteUser.push({email:email})
//     cl(this.inviteUser)
//     this.mySetState({addUserLink: res.data.token,addEmail:"", userSel:`pending-${this.state.addEmail}`})
    this.setState({addUserLink: res.data.token})
  }
  
  saveUser=async(cmd)=>{
    if(this.userId){
      let st=this.state
      let ind=getUserIndex(this.userId)
      Object.assign(globs.usersInfo.info[ind],{name:st.name,email:st.email,role:st.role,active:st.active})
//       cl(globs.usersInfo.info)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "update", 
        sessionId: globs.userData.session.sessionId,
        body: {userId: this.userId,name:st.name,email:st.email,role:st.role,active:st.active}})
//       cl(this.state)
      if(this.state.owner){
        globs.accountInfo.info.owner=this.userId
        await wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "update", 
          sessionId: globs.userData.session.sessionId, body: {
            owner: this.userId,
          }})
      }
    }else{
      if(this.state.thirdParty) {
        let ret = await wsTrans("usa", {cmd: "cRest", uri: "/s/thirdParty", method: "update", 
        sessionId: globs.userData.session.sessionId, 
        body: {userId:this.state.thirdParty.trim()}})
        // cl(ret)
        this.addUserToLocal(this.state.thirdPartyIdResult.data)
        let adminAdds = []
        saveToAdminLog(adminAdds, "addThirdParty", this.state.thirdParty.trim())
        addToAdminLog(adminAdds)
        // put success msg, check ret?
        this.setState({thirdParty: "", thirdPartyError:false, thirdPartyMsg: "Third Party User added successfully!"})
      }
      else {
        await this.addUser(this.state.name,this.state.email)
      } 
    }
    globs.events.publish("saveOK",true)
    // if(!this.state.showSuper){history.goBack()}
  }

  loadInfo=async()=>{
//     cl(this.props)
    await loadAccountInfo()
//     cl(globs.accountInfo.info)
    await loadUsersInfo()
    this.roles=globs.usersInfo.info.filter(u=>{return u.isRole})
    this.roleIDs=this.roles.map(r=>{return r.userId})
    // cl(this.roles)
    // cl(this.roleIDs)
//     let parts=this.props.parms.url.split("/")
//     this.userId=parts[5]
    this.userId=this.props.parms.adminInfo
//     cl(globs.accountInfo.info)
//     cl(globs.accountInfo.info.owner==this.props.parms.adminInfo)
    var user={email:""}
//     cl(user)
    if(this.userId){
      globs.usersInfo.info.forEach(u=>{
        if(u.userId==this.userId){user=u}
      })
      this.name=user.name||""
//       cl(user)
    }
    this.setBreadCrumbs()
    user.owner=globs.accountInfo.info.owner==this.props.parms.adminInfo
//     let active=user.active
//     cl(active)
    this.setState({loaded:true,name:user.name||"",email:user.email,role:user.role||"mortal",active:user.active||false,owner:user.owner||false})
//     cl(this.state.owner)
  }

  addUserToLocal=(user)=>{
    // cl(user)
    let got=globs.usersInfo.info.filter(u=>{
      // cl(u)
      return u.userId==user.userId})
    if(got.length==0){
      globs.usersInfo.info.push(user)
    }
  }

  addThirdParty=async(state)=>{
    var saveOK=true
    var msg

    if (state.thirdParty == "") {
      msg = "Please enter a valid user ID."
      saveOK=false
    } 

    let trimedThirdPartyValue = state.thirdParty.trim();
    if (trimedThirdPartyValue.length !== 16) {
      msg = "Please enter a valid user ID."
      saveOK=false
    }

    if (trimedThirdPartyValue.length === 16) {
      //  check if user exists
      // cl(globs.userData.session.accountId)
      let ret = await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
        sessionId: globs.userData.session.sessionId, 
        body: {userId:state.thirdParty.trim(), accountId: globs.userData.session.accountId}})
      // cl(ret.data)
      if (ret.data.length) {
        if (ret.data[0].accountId == globs.userData.session.accountId){
          msg="Existing user on this account. Please enter a valid user ID."
          saveOK=false
        } else {
          ret = await wsTrans("usa", {cmd: "cRest", uri: "/s/thirdParty", method: "retrieve", 
          sessionId: globs.userData.session.sessionId, 
          body: {userId:state.thirdParty.trim(), accountId: globs.userData.session.accountId}})
          // cl(ret)
          if (ret.data.length){
            msg="Existing third party user on this account. Please enter a valid user ID."
            saveOK=false
          }
        }
      } else {
        msg = "User not found. Please enter a valid user ID."
        saveOK=false
      }
      this.setState({thirdPartyIdResult: ret})
    }

    globs.events.publish("savePageEnable",saveOK)
    
    this.setState({thirdPartyError:true, thirdPartyMsg: msg})
  }

  showUserRole=(st)=>{
    // cl(st)
    // cl(globs.usersInfo.info)
    let opts=this.roles.map(r=>{
      return{
        v:r.userId,
        t:r.name
      }
    })
    // cl(opts)
    let role=(this.roleIDs.includes(st.role))?st.role:-1
    // cl(role)
    // cl(st.role)
    // cl(this.roleIDs)
//     let roles=
//     let opts=
    return(
      <C18Select01 parms={{
        label:"Role",
        valueName:"role",
        role:role,
        opts:[{v:-1,t:"Custom"}].concat(opts),
        onChange:this.onChange,
      }}/>
    )
  }

  showAddUser=()=>{
    let showSuper=privs("super","",constant.AREA_PRIVS_READ)
    let st=this.state
    return (
      <div>
        <br/><br/>
        {!this.userId&&
          <h3>Enter Name and Email for new User:</h3>
        }
        <h3></h3>
        {this.showUserName(st)}
        {this.showUserEmail(st)}
        {this.showUserRole(st)}
        {this.userId&&this.showManageAccess(st)}
        {this.userId&&this.showCheckActive(st)}
        {showSuper&&this.userId&&this.showCheckOwner(st)}
        {showSuper&&this.showAddUserLink()}
      </div>
    )
  }

  showAddThirdParty=()=>{
    return(
      <div>
        <br/><br/>
        {!this.userId&&acctFeature("3rdParty")&&this.showThirdParty()}
      </div>
    )
  }

  showTabs=()=>{
    switch(this.state.tabs){
      case "AddUser":
        return(this.showAddUser())
      case "AddThirdParty":
          return(this.showAddThirdParty())
    }
  }
  
  
  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "upd":
      case "input":
        let st=Object.assign({},this.state,vals)
        if ('thirdParty' in vals) {
          this.addThirdParty(st)
        }
        else {
          globs.events.publish("savePageEnable",true)
          this.checkFields(st)
        }
        this.setState(vals)
        break
      case "switchTab":
        if (this.state.tabs != vals.pageType) {
          // clear out inputs (so save doesn't apply)
          this.setState({tabs: vals.pageType, thirdParty: "", name: "", email: ""})
          globs.events.publish("savePageEnable",false)
        }
        // switch(this.state.tabs){
        // case "AddUser":
        //   this.setState({ tabs: "AddThirdParty" });
        //   break
        // case "AddThirdParty":
        //   this.setState({tabs:"AddUser"})
        //   break
        // default:
        //     break
        // }
        // break
      default:
        break
    }
  }

  showThirdParty=()=>{
    let st=this.state
    return(
      <>
      <h3>Enter ID for new Third Party User:</h3>
      <h3></h3>
      {this.showThirdPartyID()}
      </>
    )
  }

  showThirdPartyID=()=>{
    return(
      <>
      <C18InputError00 parms={{
        type:'text',
        title:"ID",
        id:"if-thirdParty",
        valueId: "thirdParty",
        required: true,
        value: this.state.thirdParty,
        onChange: this.onChange,
        message:this.state.thirdPartyMsg,
        messageType: this.state.thirdPartyError ? "error" : "",
        inlineStyle: true
      }}/>
      <br />
     </>
    )
  }
  
  showUserName=(st)=>{
//     cl(st.name)
    return(
      <div className="text-input-wrapper">
        <label htmlFor="new-user-name">Name</label>
        <input id="new-user-name" type="text" 
          value={st.name} 
          onChange={e=>this.onChange("upd",{name:e.currentTarget.value})}
          />
        <br />
      </div>
    )
  }
  
  showUserEmail=(st)=>{
    return(
      <>
      
        <C18InputError00 parms={{
          type:'email',
//           visible:this.state.email,
          title:"Email",
          id:"if-email",
          required: true,
          value: this.state.email,
          valueId: "email",
          onChange: this.onChange,
          message:this.state.emailError,
          messageType: "error"
        }}/>
        <br />
      </>
    )
  }
  
//   showUserRole=(st)=>{
//     return(
//       <>
//         <span className="custom-select">
//           <label htmlFor="user-role">Role [for future]</label>
//           <select id="user-role"
//             value={this.state.role}
//             onChange={e=>this.onChange("upd",{role:e.currentTarget.value})}
//           >
//             {this.roles.map((r,i)=>{
//               return(
//                 <option key={i} value={r.v}>{r.t}</option>
//               )
//             })}
//           </select>
//           <span className="material-icons down-arrow">
//             keyboard_arrow_down
//           </span>
//         </span>
//         <div className="clearfloat"></div>
//       </>
//     )
//   }
  
  showManageAccess=()=>{
    let st=this.state
    cl(this.roleIDs)
    cl(st.role)
    let userRoleId=(this.roleIDs.includes(st.role))?st.role:this.userId
    cl(userRoleId)
    return(
      <>
        <div className="float-right-box">
          <C18Anchor00 to={`/usa/c18/admin/manageSiteAccess2/${userRoleId}`} className="button outlined">Manage Access</C18Anchor00>
        </div>
        <div className="clearfloat"></div>
      </>
    )
  }
  
  showCheckActive=(st)=>{
    return(
      <div className="checkbox-list">
        <span>
          <C18Input00 type="checkbox" aria-label={`Active read`}
            checked={st.active}
            onChange={e=>this.onChange("upd",{active:e.currentTarget.checked})}
          />
          <label>Active</label>
        </span>
      </div>
    )
  }

  showCheckOwner=(st)=>{
//     cl(st)
    return(
      <div className="checkbox-list">
        <span>
          <C18Input00 type="checkbox" aria-label={`Active read`}
            checked={st.owner}
            onChange={e=>this.onChange("upd",{owner:e.currentTarget.checked})}
          />
          <label>Owner</label>
        </span>
      </div>
    )
  }

  
  showAddUserLink=()=>{
    if(this.state.addUserLink){
      let url = constant.feUrl + `/usa/c18/inviteUser/${this.state.addUserLink}`;
      return(
        <p>
        This is the link in the email: 
        <a href={url}>{url}</a>
        </p>
      );
    }
  }

  validEmail=(email)=>{
    let atPos = email.indexOf("@");
    let perPos = email.lastIndexOf(".");
//     cl(atPos,perPos)
    return ((atPos > 0) && (perPos > atPos));
  }
  
  checkFields=async(state)=>{
    var saveOK=true
    var msg
    
    if (state.email == "" || (!this.validEmail(state.email) && (state.email !== ""))) {
      cl(this.validEmail(state.email))
      msg = "Please enter a valid email."
      saveOK=false
    }
    //else if (state.email == "") {
     // cl(this.validEmail(state.email))
     // msg = "Please do not leave the email section blank."
     // saveOK=false
    //}

    if (!this.props.parms.adminInfo && this.validEmail(state.email)) { // if a new user
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/unique", method: "retrieve", 
        body: {companyName:"", email: state.email, password: ""}})
      
//       cl(this.props)

      if(!res.ue){
        msg= "That email address is already in the system."

        saveOK=false
      }else{
        msg=""
        saveOK=true
      }
//       cl(saveOK)
    }
    globs.events.publish("savePageEnable",saveOK)
    
//     globs.events.publish("savePageEnable",true)
    
    this.setState({emailError:msg})
//     if(!res.ue && (this.state.alertId > 5)) return this.setAlert(5);// email used
//     if(this.state.alertId === 12) this.setAlert(14);
  }
   
  render(){
//     cl(this.userId)
//     cl("render")
//               <button type="button" className="outlined left-margin">Cancel and Return</button>
    let showSuper=privs("super","",constant.AREA_PRIVS_READ)
    let tabs = [{v:"AddUser",t: (this.userId) ? "Edit User" : "Add User"}]
    if (!this.userId&&acctFeature("3rdParty")) tabs.push({v:"AddThirdParty",t:"Add Third Party User"})
    if(this.state.loaded){
      let st=this.state
//       cl(st)
//       cl(st)
//         {this.userId&&this.showUserRole(st)}
      return(
        <div>
          <div className="reports-and-graphs">
            <C18SubMenuHeader00 parms={{
              items: tabs,
              pageType: this.state.tabs,
              onChange: o=>this.onChange("switchTab",o),
            }}/>
            {this.showTabs()}
          </div>
        <br/><br/>
        {/* {!this.userId&&
          <h3>Enter Name and Email for new User:</h3>
        }
        {this.showUserName(st)}
        {this.showUserEmail(st)}
        {this.userId&&this.showManageAccess(st)}
        {this.userId&&this.showCheckActive(st)}
        {showSuper&&this.userId&&this.showCheckOwner(st)}
        {showSuper&&this.showAddUserLink()}
        <br/><br/>
        {!this.userId&&acctFeature("3rdParty")&&this.showThirdParty()} */}
        </div>
      );
    } else{
      return <div>loading...</div>
    }
  } 
}
  
export default C18EditUser00 ;
 
