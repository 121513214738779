import React from 'react';
import {acctFeature} from './C18utils'
import config from '../../components/utils/config';
import {cl,globs} from '../../components/utils/utils';

class C18SidebarHeader00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
    }
    this.subscribe_newContextEvent=globs.events.subscribe("newContext",this.newContext)
  }
  
  componentDidMount=()=>{
    this.mounted=true
  }
  componentWillUnmount=()=>{
    this.subscribe_newContextEvent.remove()
    this.mounted=false
  }
  
  onChange=(type,vals)=>{
//     cl(type)
    this.setState({mode:type})
    this.props.parms.onChange({mode:type})
  }
  
  newContext=(vals)=>{
//     cl(vals)
    let p=this.props.parms
    if((vals.level!="zone")&&(p.mode=="generalInfo")){
      p.onChange({mode:"growJournal"})
    }
//     cl(p)
  }

  hideRefresh=()=>{
    this.setState({refreshing: false})
  }

  refreshEntries=()=>{
    this.setState({refreshing: true})
    switch(this.props.parms.mode) {
      case "growJournal": 
       globs.events.publish("RefreshGrowJournalEntries");
       break;
      case "alarms":
       globs.events.publish("RefreshAlarmEntries");
       break;
      case "auditLog":
       globs.events.publish("RefreshAuditLogEntries");
       break;
      case "images":
       globs.events.publish("RefreshImagesEntries");
       break;
      case "generalInfo":
       globs.events.publish("RefreshGeneralInfoEntries");
       break;
      case "tasks":
       globs.events.publish("RefreshTasksEntries");
       break;
    }
    window.setTimeout(this.hideRefresh, 1000)
  }
  
  render(){
//         <!-- <button type="button" className="selected" aria-pressed="true">General Info</button> -->
//         <button type="button" className={(this.state.mode=="tasks")?"selected":""} 
//           aria-pressed={(this.state.mode=="tasks")?"true":"false"} onClick={o=>this.onChange("tasks")}>Tasks</button>
    let mode=this.props.parms.mode
    return(
      <div>
        <div data-context="/sidebar/header" id="/sidebar/header" className="sidebar-header user-toggle-field filled">
        <button data-context="/sidebar/header/growJournal" id="/sidebar/header/growJournal" type="button" className={(this.props.parms.mode=="growJournal")?"selected":""} 
          aria-pressed={(mode=="growJournal")?"true":"false"} onClick={o=>this.onChange("growJournal")}>Grow Journal</button>
        <button data-context="/sidebar/header/alarms" id="/sidebar/header/alarms" type="button" className={(mode=="alarms")?"selected":""} 
          aria-pressed={(mode=="alarms")?"true":"false"} onClick={o=>this.onChange("alarms")}>Alarms</button>
        <button data-context="/sidebar/header/auditLog" id="/sidebar/header/auditLog" type="button" className={(mode=="auditLog")?"selected":""} 
          aria-pressed={(mode=="auditLog")?"true":"false"} onClick={o=>this.onChange("auditLog")}>Audit Log</button>
        <button data-context="/sidebar/header/images" id="/sidebar/header/images" type="button" className={(mode=="images")?"selected":""} 
          aria-pressed={(mode=="images")?"true":"false"} onClick={o=>this.onChange("images")}>Media</button>
        {(this.props.parms.level=="zone")&&
          <button data-context="/sidebar/header/generalInfo" id="/sidebar/header/generalInfo" type="button" className={(mode=="generalInfo")?"selected":""} 
            aria-pressed={(mode=="generalInfo")?"true":"false"} onClick={o=>this.onChange("generalInfo")}>General Info</button>
        }
        {acctFeature("tasks")&&
          <button data-context="/sidebar/header/tasks" id="/sidebar/header/tasks" type="button" className={(mode=="tasks")?"selected":""}
            aria-pressed={(mode=="tasks")?"true":"false"} onClick={o=>this.onChange("tasks")}>Tasks</button>
        }
       <button data-context="/sidebar/header/weatherInfo" id="/sidebar/header/weatherInfo" type="button" className={(mode=="weatherInfo")?"selected":""} 
         aria-pressed={(mode=="weatherInfo")?"true":"false"} onClick={o=>this.onChange("weatherInfo")}>Weather</button>
       {acctFeature("linkbot")&&
       <button id="/sidebar/header/linkBot" type="button" className={(this.props.parms.mode=="linkBot")?"selected":""} 
         aria-pressed={(mode=="linkBot")?"true":"false"} onClick={o=>this.onChange("linkBot")}></button>
        } 
      </div>
      <button type="button" style={{verticalAlign:"baseline",marginTop:"-105px",float:"right"}}
        className="material-icons settings-button" title="Refresh Entries"
        disabled={this.state.refreshing||false}
        onClick={o=>this.refreshEntries()}
      >{this.state.refreshing ?
        <span className="spinnerGraph">
          <img style={{height:15,width:15}} src="/img/spinner.gif" />
        </span>
        :
        <>refresh</>
      }
      </button>
      <br/>

      </div>
     
    )
  }
}
      
export default C18SidebarHeader00;
