import React from 'react';
import {skipThisGwType} from '../utilsFui'
import {cl, globs} from '../../components/utils/utils';

class TextDisplay extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.props.getValue(props.ind, {type: "local"})
  }

  render(){
    if(skipThisGwType(this.props)){return null}
    return(
      <div dangerouslySetInnerHTML={{__html: this.props.custom.text}}></div>
    );
  }
}

  export default TextDisplay ;
