import React from 'react';
import {getParamId2} from '../../usa/utils/utils';
import {pInd} from '../../components/utils/paramIds'
import {dbVals,putZValue,sendArray} from '../../components/utils/http'
import {cl, globs,getTimeI,interpolateColor} from '../../components/utils/utils';

class GenericSensors00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      cals:[],
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
//     this.subscribe_sideBarClicked=globs.events.subscribe("sideBarClicked", this.sideBarClicked)
//     cl(this.props)
//     cl(globs)
    this.loadInfo()
  }

  savePageEvent=(cmd)=>{
    let parms=this.loadSaveGenSensorCals(this.gwType,"save")
    cl(parms)
//     cl(this.props)
    sendArray(parms,this.props.current.virtual).then(e=>{
      cl("save ok")
      globs.events.publish("saveOK",true)
    })
  }

  loadSaveGenSensorCals=(gwType,getSave)=>{
    let st=this.state
    let z=+this.zuci[0]
    let cals=[]
    let get=(getSave=="get")
    let arr=[]
    let now=getTimeI()
//     cl(gwType,getSave)
    if(gwType==1800){
      let tab=pInd[1800].controller_configuration_settings
      let fields=["Generic 1 Calibration","Generic 2 Calibration"]
      if(get){
        cals =[...Array(2)].map((a,i)=>{
          let pid=getParamId2(gwType,"configuration_unit_settings",fields[i])
          return dbVals.z[z][240][pid]||0
        })
        return cals
      }else{
        cl("saving")
        st.cals.forEach((c,i)=>{
          let pid=getParamId2(gwType,"configuration_unit_settings",fields[i])
          dbVals.z[z][240][pid]=c
          arr.push({
            c:240,
            d:c,
            f:1,
            i:pid,
            t:now,
            z:z,
          })
        })
        return arr
      }
    }else{
      let pid=+getParamId2(gwType,"pearl_gen_sens_conf","calibrate")
      let tab=pInd[1900].pearl_gen_sens_conf
      let mult=tab[2]
      let cnt=tab[3]
//       cl(pid,mult,cnt)
      if(get){
        cals =[...Array(cnt)].map((a,i)=>{
          let v=dbVals.z[z][240][pid+mult*i]||0
          return v
        })
        return cals
      }else{
//         cl("saving")
        st.cals.forEach((c,i)=>{
//           let pid=getParamId2(gwType,"pearl_gen_sens_conf",fields[i])
          dbVals.z[z][240][pid+i*mult]=c
//           if(i<2){
            arr.push({
              c:240,
              d:c,
              f:1,
              i:pid+i*mult,
              t:now,
              z:z,
            })
//           }
        })
//         cl(arr)
        return arr
//         return arr
      }
    }
  }

  loadInfo=()=>{// assume that the siteData is loaded
    this.zuci=this.props.zuci.split("-")
    this.siteId=globs.userData.session.siteId
    this.zone=globs.zonesInfo.info.filter(z=>{return (z.siteId==this.siteId)&&
      (z.siteZoneIndex==+this.zuci[0])})[0]
    this.gwType=this.zone.gatewayType||1800
//     cl(this.gwType)
//     cl(this.siteId)
//     cl(this.zone)
    this.state.cals=this.loadSaveGenSensorCals(this.gwType,"get")

  }

  onChange=(type,value)=>{
    cl(type,value)
    var cals
    let st=this.state
    switch(type){
      case "genCal":
        globs.events.publish("savePageEnable",true)
        let cals=st.cals.slice(0)
        cals[value.ind]=+value.val
        this.setState({cals:cals})
        break
    }
  }

  showGenericSensor=(c,i)=>{
    return(
      <div key={i}>
      <label>{`Generic ${i+1}:`}</label>
        <input type="number" style={{width:150}}
          value={c}
          size="5"
          onChange={e=>this.onChange("genCal",{ind:i,val:e.currentTarget.value})}
        />
        <div className="clearfloat"/><br/>
      </div>
    )
  }

  showGenericSensors=()=>{
    let st=this.state
    let rows=st.cals.map((c,i)=>{return this.showGenericSensor(c,i)})
    return(
      <div>
      {rows}
      </div>
    )
  }

  render(){

//     cl(this.state)
    return(
      <div>
        {this.showGenericSensors()}
      </div>
    )
  }
}

export default GenericSensors00 ;
