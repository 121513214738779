  var makeSensorList=(adminPage)=>{
    let sensorList={
      selectSensors:[
        {v:"INS",t:"Inside Sensors 1800",l:0,o:0,s:[
          {v:"zoS",t:"Zone Setpoint",p:-1},
          {v:"inT",t:"Inside Temperature",p:-1},
          {v:"inH",t:"Inside Humidity",p:-1},
          {v:"inL",t:"Inside Light",p:-1},
          {v:"dli",t:"Daily Light Integral",p:-1},
          {v:"inC",t:"Inside CO2",p:-1},
          {v:"vpd",t:"VPD",p:-1},

          {v:"stT",t:"Temperature Stage",p:-1},
          {v:"stH",t:"Humidity Stage",p:-1},
          {v:"spH",t:"Low Temperature Setpoint",p:-1},
          {v:"spC",t:"High Temperature Setpoint",p:-1},
          {v:"spU",t:"Low Humidity Setpoint",p:-1},
          {v:"spD",t:"High Humidity Setpoint",p:-1},
          {v:"alL",t:"Low Alarm Relay",p:-1},
          {v:"alH",t:"High Alarm Relay",p:-1},
        ]},
        {v:"OTS",t:"Outside Sensors",l:0,o:0,s:[
          {v:"ouT",t:"Outside Temperature",p:-1},
          {v:"ouH",t:"Outside Humidity",p:-1},
          {v:"ouL",t:"Outside Light",p:-1},
          {v:"bpT",t:"Black Plate Temp",p:-1},

          {v:"oWs",t:"Wind Speed",p:-1},
          {v:"oWd",t:"Wind Direction",p:-1},
          {v:"dPr",t:"Differential Pressure",p:-1},
          {v:"bPr",t:"Barometric Pressure",p:-1},
          {v:"ran",t:"Rain",p:-1},
          {v:"sno",t:"Snow",p:-1},
        ]},
        {v:"GNS",t:"Generic Sensors",l:0,o:0,s:[
          {v:"gn0",t:"Generic Sensor 1",l:1,p:-1},
          {v:"gn1",t:"Generic Sensor 2",l:1,p:-1},
          {v:"gn2",t:"Generic Sensor 3",l:1,p:-1},
          {v:"gn3",t:"Generic Sensor 4",l:1,p:-1},
          {v:"gn4",t:"Generic Sensor 5",l:1,p:-1},
          {v:"gn5",t:"Generic Sensor 6",l:1,p:-1},
          {v:"gn6",t:"Generic Sensor 7",l:1,p:-1},
          {v:"gn7",t:"Generic Sensor 8",l:1,p:-1},
          {v:"gn8",t:"Generic Sensor 9",l:1,p:-1},
          {v:"gn9",t:"Generic Sensor 10",l:1,p:-1},
          {v:"gn10",t:"Generic Sensor 11",l:1,p:-1},
          {v:"gn11",t:"Generic Sensor 12",l:1,p:-1},
          {v:"gn12",t:"Generic Sensor 13",l:1,p:-1},
          {v:"gn13",t:"Generic Sensor 14",l:1,p:-1},
          {v:"gn14",t:"Generic Sensor 15",l:1,p:-1},
          {v:"gn15",t:"Generic Sensor 16",l:1,p:-1},
          {v:"gn16",t:"Generic Sensor 17",l:1,p:-1},
          {v:"gn17",t:"Generic Sensor 18",l:1,p:-1},
          {v:"gn18",t:"Generic Sensor 19",l:1,p:-1},
          {v:"gn19",t:"Generic Sensor 20",l:1,p:-1},
          {v:"gn20",t:"Generic Sensor 21",l:1,p:-1},
          {v:"gn21",t:"Generic Sensor 22",l:1,p:-1},
          {v:"gn22",t:"Generic Sensor 23",l:1,p:-1},
          {v:"gn23",t:"Generic Sensor 24",l:1,p:-1},
          {v:"gn24",t:"Generic Sensor 25",l:1,p:-1},
          {v:"gn25",t:"Generic Sensor 26",l:1,p:-1},
          {v:"gn26",t:"Generic Sensor 27",l:1,p:-1},
          {v:"gn27",t:"Generic Sensor 28",l:1,p:-1},
          {v:"gn28",t:"Generic Sensor 29",l:1,p:-1},
          {v:"gn29",t:"Generic Sensor 30",l:1,p:-1},
          {v:"gn30",t:"Generic Sensor 31",l:1,p:-1},
          {v:"gn31",t:"Generic Sensor 32",l:1,p:-1},
          {v:"gn32",t:"Generic Sensor 33",l:1,p:-1},
          {v:"gn33",t:"Generic Sensor 34",l:1,p:-1},
          {v:"gn34",t:"Generic Sensor 35",l:1,p:-1},
          {v:"gn35",t:"Generic Sensor 36",l:1,p:-1},
          {v:"gn36",t:"Generic Sensor 37",l:1,p:-1},
          {v:"gn37",t:"Generic Sensor 38",l:1,p:-1},
          {v:"gn38",t:"Generic Sensor 39",l:1,p:-1},
          {v:"gn39",t:"Generic Sensor 40",l:1,p:-1},
          {v:"gn40",t:"Generic Sensor 41",l:1,p:-1},
          {v:"gn41",t:"Generic Sensor 42",l:1,p:-1},
          {v:"gn42",t:"Generic Sensor 43",l:1,p:-1},
          {v:"gn43",t:"Generic Sensor 44",l:1,p:-1},
          {v:"gn44",t:"Generic Sensor 45",l:1,p:-1},
          {v:"gn45",t:"Generic Sensor 46",l:1,p:-1},
          {v:"gn46",t:"Generic Sensor 47",l:1,p:-1},
          {v:"gn47",t:"Generic Sensor 48",l:1,p:-1},
          {v:"gn48",t:"Generic Sensor 49",l:1,p:-1},
          {v:"gn49",t:"Generic Sensor 50",l:1,p:-1},
          {v:"gn50",t:"Generic Sensor 51",l:1,p:-1},
          {v:"gn51",t:"Generic Sensor 52",l:1,p:-1},
          {v:"gn52",t:"Generic Sensor 53",l:1,p:-1},
          {v:"gn53",t:"Generic Sensor 54",l:1,p:-1},
          {v:"gn54",t:"Generic Sensor 55",l:1,p:-1},
          {v:"gn55",t:"Generic Sensor 56",l:1,p:-1},
          {v:"gn56",t:"Generic Sensor 57",l:1,p:-1},
          {v:"gn57",t:"Generic Sensor 58",l:1,p:-1},
          {v:"gn58",t:"Generic Sensor 59",l:1,p:-1},
          {v:"gn59",t:"Generic Sensor 60",l:1,p:-1},
          {v:"gn60",t:"Generic Sensor 61",l:1,p:-1},
          {v:"gn61",t:"Generic Sensor 62",l:1,p:-1},
          {v:"gn62",t:"Generic Sensor 63",l:1,p:-1},
          {v:"gn63",t:"Generic Sensor 64",l:1,p:-1},
          {v:"gn64",t:"Generic Sensor 65",l:1,p:-1},
          {v:"gn65",t:"Generic Sensor 66",l:1,p:-1},
          {v:"gn66",t:"Generic Sensor 67",l:1,p:-1},
          {v:"gn67",t:"Generic Sensor 68",l:1,p:-1},
          {v:"gn68",t:"Generic Sensor 69",l:1,p:-1},
          {v:"gn69",t:"Generic Sensor 70",l:1,p:-1},
          {v:"gn70",t:"Generic Sensor 71",l:1,p:-1},
          {v:"gn71",t:"Generic Sensor 72",l:1,p:-1},
          {v:"gn72",t:"Generic Sensor 73",l:1,p:-1},
          {v:"gn73",t:"Generic Sensor 74",l:1,p:-1},
          {v:"gn74",t:"Generic Sensor 75",l:1,p:-1},
          {v:"gn75",t:"Generic Sensor 76",l:1,p:-1},
          {v:"gn76",t:"Generic Sensor 77",l:1,p:-1},
          {v:"gn77",t:"Generic Sensor 78",l:1,p:-1},
          {v:"gn78",t:"Generic Sensor 79",l:1,p:-1},
          {v:"gn79",t:"Generic Sensor 80",l:1,p:-1},
          {v:"gn80",t:"Generic Sensor 81",l:1,p:-1},
          {v:"gn81",t:"Generic Sensor 82",l:1,p:-1},
          {v:"gn82",t:"Generic Sensor 83",l:1,p:-1},
          {v:"gn83",t:"Generic Sensor 84",l:1,p:-1},
          {v:"gn84",t:"Generic Sensor 85",l:1,p:-1},
          {v:"gn85",t:"Generic Sensor 86",l:1,p:-1},
          {v:"gn86",t:"Generic Sensor 87",l:1,p:-1},
          {v:"gn87",t:"Generic Sensor 88",l:1,p:-1},
          {v:"gn88",t:"Generic Sensor 89",l:1,p:-1},
          {v:"gn89",t:"Generic Sensor 90",l:1,p:-1},
          {v:"gn90",t:"Generic Sensor 91",l:1,p:-1},
          {v:"gn91",t:"Generic Sensor 92",l:1,p:-1},
          {v:"gn92",t:"Generic Sensor 93",l:1,p:-1},
          {v:"gn93",t:"Generic Sensor 94",l:1,p:-1},
          {v:"gn94",t:"Generic Sensor 95",l:1,p:-1},
          {v:"gn95",t:"Generic Sensor 96",l:1,p:-1},
          {v:"gn96",t:"Generic Sensor 97",l:1,p:-1},
          {v:"gn97",t:"Generic Sensor 98",l:1,p:-1},
          {v:"gn98",t:"Generic Sensor 99",l:1,p:-1},
          {v:"gn99",t:"Generic Sensor 100",l:1,p:-1},
          {v:"gn100",t:"Generic Sensor 101",l:1,p:-1},
          {v:"gn101",t:"Generic Sensor 102",l:1,p:-1},
          {v:"gn102",t:"Generic Sensor 103",l:1,p:-1},
          {v:"gn103",t:"Generic Sensor 104",l:1,p:-1},
          {v:"gn104",t:"Generic Sensor 105",l:1,p:-1},
          {v:"gn105",t:"Generic Sensor 106",l:1,p:-1},
          {v:"gn106",t:"Generic Sensor 107",l:1,p:-1},
          {v:"gn107",t:"Generic Sensor 108",l:1,p:-1},
          {v:"gn108",t:"Generic Sensor 109",l:1,p:-1},
          {v:"gn109",t:"Generic Sensor 110",l:1,p:-1},
          {v:"gn110",t:"Generic Sensor 111",l:1,p:-1},
          {v:"gn111",t:"Generic Sensor 112",l:1,p:-1},
          {v:"gn112",t:"Generic Sensor 113",l:1,p:-1},
          {v:"gn113",t:"Generic Sensor 114",l:1,p:-1},
          {v:"gn114",t:"Generic Sensor 115",l:1,p:-1},
          {v:"gn115",t:"Generic Sensor 116",l:1,p:-1},
          {v:"gn116",t:"Generic Sensor 117",l:1,p:-1},
          {v:"gn117",t:"Generic Sensor 118",l:1,p:-1},
          {v:"gn118",t:"Generic Sensor 119",l:1,p:-1},
          {v:"gn119",t:"Generic Sensor 120",l:1,p:-1},
          {v:"gn120",t:"Generic Sensor 121",l:1,p:-1},
          {v:"gn121",t:"Generic Sensor 122",l:1,p:-1},
          {v:"gn122",t:"Generic Sensor 123",l:1,p:-1},
          {v:"gn123",t:"Generic Sensor 124",l:1,p:-1},
          {v:"gn124",t:"Generic Sensor 125",l:1,p:-1},
          {v:"gn125",t:"Generic Sensor 126",l:1,p:-1},
          {v:"gn126",t:"Generic Sensor 127",l:1,p:-1},
          {v:"gn127",t:"Generic Sensor 128",l:1,p:-1},
        ]},

        {v:"ANS",t:"Analog Sensors",l:0,o:0, s:
          [
            {v:"ANT",t:"Analog Temperatures",l:1,o:0, s:
              [
                {v:"at0", t:"Analog Temperature 1",l: 1, p:-1},
                {v:"at1", t:"Analog Temperature 2",l: 1, p:-1},
                {v:"at2", t:"Analog Temperature 3",l: 1, p:-1},
                {v:"at3", t:"Analog Temperature 4",l: 1, p:-1},
                {v:"at4", t:"Analog Temperature 5",l: 1, p:-1},

                {v:"at5", t:"Analog Temperature 6",l: 1, p:-1},
                {v:"at6", t:"Analog Temperature 7",l: 1, p:-1},
                {v:"at7", t:"Analog Temperature 8",l: 1, p:-1},
                {v:"at8", t:"Analog Temperature 9",l: 1, p:-1},
                {v:"at9", t:"Analog Temperature 10",l: 1, p:-1},
                {v:"at10", t:"Analog Temperature 11",l: 1, p:-1},
                {v:"at11", t:"Analog Temperature 12",l: 1, p:-1},
                {v:"at12", t:"Analog Temperature 13",l: 1, p:-1},
                {v:"at13", t:"Analog Temperature 14",l: 1, p:-1},
                {v:"at14", t:"Analog Temperature 15",l: 1, p:-1},
                {v:"at15", t:"Analog Temperature 16",l: 1, p:-1},
                {v:"at16", t:"Analog Temperature 17",l: 1, p:-1},
                {v:"at17", t:"Analog Temperature 18",l: 1, p:-1},
                {v:"at18", t:"Analog Temperature 19",l: 1, p:-1},
                {v:"at19", t:"Analog Temperature 20",l: 1, p:-1},
                {v:"at20", t:"Analog Temperature 21",l: 1, p:-1},
                {v:"at21", t:"Analog Temperature 22",l: 1, p:-1},
                {v:"at22", t:"Analog Temperature 23",l: 1, p:-1},
                {v:"at23", t:"Analog Temperature 24",l: 1, p:-1},
              ]
            },
            {v:"AVO",t:"Voltage",l:1,o:0, s:
              [
                {v:"vo0", t:"Voltage 1",l: 1, p:-1},
                {v:"vo1", t:"Voltage 2",l: 1, p:-1},
                {v:"vo2", t:"Voltage 3",l: 1, p:-1},
                {v:"vo3", t:"Voltage 4",l: 1, p:-1},
                {v:"vo4", t:"Voltage 5",l: 1, p:-1},
                {v:"vo5", t:"Voltage 6",l: 1, p:-1},
                {v:"vo6", t:"Voltage 7",l: 1, p:-1},
                {v:"vo7", t:"Voltage 8",l: 1, p:-1},
              ]
            },
            {v:"DVO",t:"Digital Voltage",l:1,o:0, s:
              [
                {v:"dv0", t:"DigVolt 1",l: 1, p:-1},
                {v:"dv1", t:"DigVolt 2",l: 1, p:-1},
                {v:"dv2", t:"DigVolt 3",l: 1, p:-1},
                {v:"dv3", t:"DigVolt 4",l: 1, p:-1},
                {v:"dv4", t:"DigVolt 5",l: 1, p:-1},
                {v:"dv5", t:"DigVolt 6",l: 1, p:-1},
                {v:"dv6", t:"DigVolt 7",l: 1, p:-1},
                {v:"dv7", t:"DigVolt 8",l: 1, p:-1},
              ]
            },
            {v:"AVP",t:"Vent Position",l:1,o:0, s:
              [
                {v:"vp0", t:"Vent Position 1",l: 1, p:-1},
                {v:"vp1", t:"Vent Position 2",l: 1, p:-1},
                {v:"vp2", t:"Vent Position 3",l: 1, p:-1},
                {v:"vp3", t:"Vent Position 4",l: 1, p:-1},
                {v:"vp4", t:"Vent Position 5",l: 1, p:-1},
              ]
            },
            {v:"ASM",t:"Soil Moisture",l:1,o:0, s:
              [
                {v:"sm0", t:"Soil Moisture 1",l: 1, p:-1},
                {v:"sm1", t:"Soil Moisture 2",l: 1, p:-1},
                {v:"sm2", t:"Soil Moisture 3",l: 1, p:-1},
                {v:"sm3", t:"Soil Moisture 4",l: 1, p:-1},
                {v:"sm4", t:"Soil Moisture 5",l: 1, p:-1},
              ]
            },
          ]
        },
        {v:"ADS",t:"Added Sensors",l:0,o:0,s:[
          {v:"zoS",t:"Zone Setpoint",p:-1},
        ]},
//         {v:"AXC",t:"Auxiliary Values",l:0,o:0, s:
//           [
//             {v:"AXV",t:"Aux Variables",l:1,o:0, s:
//               [
//                 {v:"at0", t:"Analog Temperature 1",l: 1, p:-1},
//               ]
//             },
//             {v:"AXP",t:"Aux Persistent Variables",l:1,o:0, s:
//               [
//                 {v:"vp0", t:"Vent Position 1",l: 1, p:-1},
//               ]
//             },
//             {v:"AXA",t:"Aux Alarms",l:1,o:0, s:
//               [
//                 {v:"sm0", t:"Soil Moisture 1",l: 1, p:-1},
//               ]
//             },
//           ]
//         },
//         {v:"STG",t:"Stages",l:0,o:0,s:[
//           {v:"stT",t:"Temperature Stage",p:-1},
//           {v:"stH",t:"Humidity Stage",p:-1},
//         ]},
//         {v:"STP",t:"Setpoints",l:0,o:0,s:[
//           {v:"spH",t:"Heat Setpoint",p:-1},
//           {v:"spC",t:"Cool Setpoint",p:-1},
//           {v:"spU",t:"Humidify Setpoint",p:-1},
//           {v:"spD",t:"DeHum Setpoint",p:-1},
//         ]},
//         {v:"HLA",t:"High / Low Alarms",l:0,o:0,s:[
//           {v:"alH",t:"High Alarm",p:-1},
//           {v:"alL",t:"Low Alarm",p:-1},
//         ]},
        {v:"CHP",t:"Channel Position",l:0,o:0,s:[
          {v:"c00",t:"Channel 1",p:-1},
          {v:"c01",t:"Channel 2",p:-1},
          {v:"c02",t:"Channel 3",p:-1},
          {v:"c03",t:"Channel 4",p:-1},
          {v:"c04",t:"Channel 5",p:-1},
          {v:"c05",t:"Channel 6",p:-1},
          {v:"c06",t:"Channel 7",p:-1},
          {v:"c07",t:"Channel 8",p:-1},
          {v:"c08",t:"Channel 9",p:-1},
          {v:"c09",t:"Channel 10",p:-1},
          {v:"c10",t:"Channel 11",p:-1},
          {v:"c11",t:"Channel 12",p:-1},
        ]},
        {v:"MXT",t:"Mixing Tanks",l:0,o:0, s:
          [
            {v:"MX0",t:"Mixing Tank 1",l:1,o:0, s:
              [
                {v:"ec0", t:"EC",l: 1, p:-1},
                {v:"ph0", t:"pH", l: 1, p:-1},
                {v:"tp0", t:"Temp", l: 1, p:-1},
              ]
            },
            {v:"MX1",t:"Mixing Tank 2",l:1,o:0, s:
              [
                {v:"ec1", t:"EC", l: 1, p:-1},
                {v:"ph1", t:"pH", l: 1, p:-1},
                {v:"tp1", t:"Temp", l: 1, p:-1},
              ]
            },
            {v:"MX2",t:"Mixing Tank 3",l:1,o:0, s:
              [
                {v:"ec2", t:"EC", l: 1, p:-1},
                {v:"ph2", t:"pH", l: 1, p:-1},
                {v:"tp2", t:"Temp", l: 1, p:-1},
              ]
            },
            {v:"MX3",t:"Mixing Tank 4",l:1,o:0, s:
              [
                {v:"ec3", t:"EC", l: 1, p:-1},
                {v:"ph3", t:"pH", l: 1, p:-1},
                {v:"tp3", t:"Temp", l: 1, p:-1},
              ]
            },
            {v:"MX4",t:"Mixing Tank 5",l:1,o:0, s:
              [
                {v:"ec4", t:"EC", l: 1, p:-1},
                {v:"ph4", t:"pH", l: 1, p:-1},
                {v:"tp4", t:"Temp", l: 1, p:-1},
              ]
            },
            {v:"MX5",t:"Mixing Tank 6",l:1,o:0, s:
              [
                {v:"ec5", t:"EC", l: 1, p:-1},
                {v:"ph5", t:"pH", l: 1, p:-1},
                {v:"tp5", t:"Temp", l: 1, p:-1},
              ]
            },
            {v:"MX6",t:"Mixing Tank 7",l:1,o:0, s:
              [
                {v:"ec6", t:"EC", l: 1, p:-1},
                {v:"ph6", t:"pH", l: 1, p:-1},
                {v:"tp6", t:"Temp", l: 1, p:-1},
              ]
            },
            {v:"MX7",t:"Mixing Tank 8",l:1,o:0, s:
              [
                {v:"ec7", t:"EC", l: 1, p:-1},
                {v:"ph7", t:"pH", l: 1, p:-1},
                {v:"tp7", t:"Temp", l: 1, p:-1},
              ]
            },
          ]
        },
        {v:"CAM",t:"Cameras",l:0,o:0,s:[
          {v:"a00",t:"Camera 1",p:-1},
        ]},
        {v:"MOD",t:"Modbus Devices",l:0,o:0,s:[
          {v:"mb00",t:"Modbus 1",p:-1},
        ]},

      ],

      selectSensors800:[
        {v:"INS",t:"Inside Sensors 1800",l:0,o:0,s:[
          {v:"zoS",t:"Zone Setpoint",p:-1},
          {v:"inT",t:"Inside Temperature",p:-1},
          {v:"inH",t:"Inside Humidity",p:-1},
          {v:"inL",t:"Inside Light",p:-1},
          {v:"inC",t:"Inside CO2",p:-1},
        ]},
        {v:"OTS",t:"Outside Sensors",l:0,o:0,s:[
          {v:"ouT",t:"Outside Temperature",p:-1},
          {v:"ouL",t:"Outside Light",p:-1},
          {v:"oWs",t:"Wind Speed",p:-1},
          {v:"oWd",t:"Wind Direction",p:-1},
          {v:"ran",t:"Rain",p:-1},
        ]},
      ],
    }
    return sensorList[adminPage]
  }

  var procSensorName=(se0,id,name,set,arr)=>{
//     cl(se0)
//     cl(arr)
    if(se0.s){
      se0.s.forEach(se=>{
        return procSensorName(se,id,name,set,arr)
      })
    }else{
//       cl(se0)
//       cl(arr[se0.v])
      if(set){
        if(arr[se0.v]){se0.t=arr[se0.v]}
      }else{
        arr[se0.v]=se0.t
      }
    }
  }

  var getSetSensorName=(id,name,set,arr,sl)=>{
//     cl(id,name)
    if(!arr){return}
//     cl(arr)
//     let sl=this.state.sensorList
//     cl(sl)
    sl.forEach(se0=>{
      procSensorName(se0,id,name,set,arr)
    })
  }

  var fixSensorList=(zc,sensorList)=>{// update this.state.sensorList to handle expansion controllers
    sensorList.forEach(sl=>{
      if(sl.s){
        fixSensorList(zc,sl.s)
      }else{
        for(let i=1;i<4;i++){
          if(zc[i]){
            let se=Object.assign({},sl)
            se.v=`e${i}${se.v}`
            se.t=`E${i}-${se.t}`
            sensorList.push(se)
          }
        }
        sl.v=`e0${sl.v}`
      }
    })
  }

export {makeSensorList,getSetSensorName,fixSensorList,
}
