import React from 'react';
import {loadZonesInfo,loadGatewaysInfo,getZoneInfo,setSiteZoneTypes,
  loadSiteData,checkSave,saveTable,getSiteName,getZoneName} from '../C18utils'
import UsaIcon from '../UsaIcon';
import UsaSelect00 from '../UsaSelect00'
import UsaDuration00 from '../UsaDuration00'
import C18Duration00 from '../C18Duration00'
import {wsTrans,sVolUnit,bVolUnit} from '../../../usa/utils/utils'
import {cl,globs, constant, getRandomString, secsToHms,hmsToSecs} from '../../../components/utils/utils';
import {init, dbVals} from '../../../components/utils/http';
import {pi,pInd} from '../../../components/utils/paramIds';

const verticalSpace=<div style={{height: 10}}/>

class Areas extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      name:"",
//       gatewayId:getZoneInfo(props.parms.zone).gatewayId,
      tank:"",
      volume:"",
      notes:"",
      loaded: false,
      areaSel: "",
      areaOpts: {},
      tankSel: "",
      tankOpts: {},
      areaValveAddr: 0,
      areaValveTime: 0,
      areaValveSel: -1,
      areaValves:[
        {addr: 4, time: 600}
      ],
    }
    this.colors=[
      "#800000", "#804000", "#808000", "#408000", "#008000", "#008040", "#008080", "#004080", 
      "#000080", "#400080", "#800080", "#800040", "#404040", "#FF9999", "#FFCC99", "#FFFF99", 
      "#CCFF99", "#99FF99", "#99FFCC", "#99FFFF", "#99CCFF", "#9999FF", "#CC99FF", "#FF99FF", 
      "#FF99CC", "#CCCCCC" ]
    this.loadAreas()
//     this.loadNutrients()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
    if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
//     this.loadInfo()
    this.setBreadCrumbs()
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
    let popInfo={text:"Changes were made to an Area. Do you want to Save them?.", buttons:["No","Save"]}
    checkSave(this,this.saveArea,popInfo)
  }
  
  setBreadCrumbs=()=>{
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
              {t:"iDoser", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/nutrients`},
              {t:"Areas", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/areas`},
            ]},
        },
      )
    }
  }
  
  savePage=(cmd)=>{
    if(cmd=="save"){
      this.saveArea()
    }
  }
  
  loadTankNames=()=>{
    
  }

//   addPearlValves=(valvOpts)=>{
//     dbVals.z.forEach((z,zInd)){
//
//     }
//
//   }
  
  loadValvesEtc=async()=>{// loads tanks, too
// the valves will be identified by "addr": channel + unit * 256 + zone*1024
    await init()
    await loadSiteData(globs.userData.session.siteId)
//     cl(dbVals)
//     cl(globs.userData.session.siteId)
    setSiteZoneTypes(globs.userData.session.siteId)
    let chTypeInd=pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelType"]
    let irrModeInd=pInd[1800].config_channels_configuration[0]+pi[1800].config_channels_configuration["irrigation_mode"]
    cl(irrModeInd)

    let chNameInd=pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelName"]
    let chUsedInd=pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["used"]
    let chMixingTankInd=pInd[1800].config_channels_configuration[0]+pi[1800].config_channels_configuration["CD_tankSensor_fval"]
    let ecMixingTankNameInd=pInd[1800].config_ecph[0]+pi[1800].config_ecph["name"]
//     cl(chTypeInd)
//     cl(pInd[1800].channels_configuration)
    let valveOpts={}//[]//{}
    let tanks=[]
    let tankNames={}
//     cl(globs.zonesInfo.info)
//     cl(dbVals.z)
    dbVals.z.forEach((z,zInd)=>{
//       cl(globs.siteZoneTypes[zInd])
//       let zInd=z.siteZoneIndex
//       cl(zInd,globs.siteZoneTypes[zInd])
      if(true||globs.siteZoneTypes[zInd]=="1800"){
//         cl(zInd)
        for(let i=0;i<40;i++){
          if(z[i]&&(true||globs.siteZoneTypes[zInd]=="1800")){
            let addr=i | (zInd<<10)
            
            if((z[i][chTypeInd]==constant.EQ_IRR)&&
              (z[i][irrModeInd]==0)){// scheduled
//               cl(i,zInd)
//               cl(zInd,i,z[i][chNameInd])
              valveOpts[addr]={t: z[i][chNameInd]}
            }
            if((z[i][chTypeInd]==constant.EQ_FVALVE)&&(+z[i][chUsedInd])){// getting tanks
//             if(z[i][chTypeInd]==constant.EQ_FVALVE){
              let tankAddr=192+(+z[i][chMixingTankInd]) | zInd<<10
//               cl(tankAddr,z[i][chMixingTankInd],zInd<<10,i,chMixingTankInd)
              tanks.push({addr: addr, tankAddr: tankAddr})
            }
          }
        }
        for(let i=192;i<199;i++){
          if(z[i]){
            let addr=i | (zInd<<10)
            let name=z[i][ecMixingTankNameInd]
//             if(name){cl(zInd,addr,name)}
            if(name){tankNames[addr]=name}
          }
        }
      }
//       this.addPearlValves(valveOpts)
    })
    let tankOpts={}
//     cl(tanks)
    tanks.forEach(t=>{
//       cl(t.tankAddr)
      let zId=1+((t.tankAddr >> 10) & 0x03F)
      let tId=1+(t.tankAddr&0xFF)-192
//       cl(t.tankAddr)
      tankOpts[t.tankAddr]=(tankNames[t.tankAddr])?tankNames[t.tankAddr]:`Zone ${zId}, Tank ${tId}`
    })
    cl(valveOpts)
    return [valveOpts, tankOpts]
  }

  getSiteGateways=()=>{
    let pa=this.props.parms
    return globs.gatewaysInfo.info.filter(gw=>{
      return gw.siteId==pa.site
    }).map(gw=>{return gw.gatewayId})
  }
  
  loadAreas=async()=>{
    await loadZonesInfo()
    await loadGatewaysInfo()
    let siteGateways=this.getSiteGateways()
//     globs.gatewaysInfo.info.filter(gw=>{
//       return gw.siteId==pa.site
//     }).map(gw=>{return gw.gatewayId})
//     cl(siteGateways)
    this.gatewayId=getZoneInfo(this.props.parms.zone)?.gatewayId
    let arP= wsTrans("usa", {cmd: "cRest", uri: "/s/areas", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let vaP= wsTrans("usa", {cmd: "cRest", uri: "/s/areaValves", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:{$in:siteGateways}}})
    let [arR,vaR]=await Promise.all([arP,vaP])
    cl(vaR)
    
    
//     this.nutrients={}
//     let nutrientSel=r.data[0].nutrientId
    let areaValves={}
    cl(vaR.data)
    vaR.data.forEach(v=>{
      let zInd=(v.addr>>10)&0x3F
      let zo=globs.zonesInfo.info.filter(z=>{
        return (z.gatewayId==v.gatewayId)&&(z.gatewayZoneIndex==zInd)
      })[0]||{}
//       cl(v)
//       cl(zo)
      if(!areaValves[v.areaId]){areaValves[v.areaId]=[]}
      let vZone=zo.siteZoneIndex
      let vChan=v.addr&0x3F
      v.valveId=(vZone<<10)|vChan
//       cl(vZone,vChan)
      areaValves[v.areaId].push(v)
//       areaValves[v.areaId][v.addr]=v
    })
//     cl(this.areaValves)
    cl(areaValves)
    let areas={}
    arR.data.forEach(a=>{
//       a.t=a.name// for the select Opts
      areas[a.areaId]=a
    })
    cl(areas)
    let areaSel=(arR.data[0])?arR.data[0].areaId:""
    let [valveOpts, tankOpts]=await this.loadValvesEtc()
//     cl(valveOpts)
//     cl(tankOpts)
    let valveSel=Object.keys(valveOpts)[0]
    let tankSel=Object.keys(tankOpts)[0]
    this.setState({areaOpts: areas, areaSel: areaSel, areaValves: areaValves, valveOpts: valveOpts, valveSel: valveSel, 
      tankOpts: tankOpts, tankSel: tankSel, loaded: true})
  }
  
  saveAreaValves=async()=>{
// areaOpts is an obj: {areaId: {info}}
// this.areaValves is an object of objects: {areaId: {addr: {the info}}}
    let valves={}
    this.state.areaValves.forEach(v=>{valves[v.addr]=v})
    this.areaValves[this.state.areaSel]=valves
    let valves1=[]
    let siteId=globs.userData.session.siteId
    for(let k in valves){
      valves1.push({
        siteId: globs.userData.session.siteId,
        areaId: this.state.areaSel,
        addr: k,
        time: valves[k].time,
      })
    }
//     cl(valves1)
    
//     let valves=[]
//     let valves1={}
//     let valves0=this.state.areaValves.slice(0)
//     this.state.areaValves.forEach(v=>{
//       valves1[v.addr]={
//         siteId: globs.userData.session.siteId,
//         areaId: this.state.areaSel,
//         addr: v.addr,
//         time: v.time,
//       }
//     })
//     for(let k in valves1){valves.push(valves1[k])}
//     cl(valves)
    cl("save area valves")
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/areaValves", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId, areaId: this.state.areaSel, valves: valves1}})
  }
  
  deleteAreaO=async(areaId)=>{
//     cl("delete"+areaId)
//     cl(this.state.areaOpts)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/areas", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: {areaId:areaId}})
    await wsTrans("usa", {cmd: "cRest", uri: "/s/areaValves", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: {areaId:areaId}})
    let areas=Object.assign({}, this.state.areaOpts)
    delete areas[areaId]
    this.setState({areaOpts: areas})
  }
  
  deleteArea=async(areaId)=>{
//     cl("delete")
//     let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this area?", buttons:["Cancel","Yes"]})
//     if(res=="Yes"){
//       cl("yes")
      await wsTrans("usa", {cmd: "cRest", uri: "/s/areas", method: "delete", 
        sessionId: globs.userData.session.sessionId,
        body: {areaId:+areaId,gatewayId:this.gatewayId}})
//       cl("yes")
      await wsTrans("usa", {cmd: "cRest", uri: "/s/areaValves", method: "delete", 
        sessionId: globs.userData.session.sessionId,
        body: {areaId:+areaId,gatewayId:this.gatewayId}})
//       cl("deleted")
//     }
  }
  
  saveArea=async()=>{
    let pa=this.props.parms
    globs.events.publish("savePageEnable",false)
    this.modified=false
    let area=Object.assign({},this.state.areaOpts[this.state.areaSel])
    cl(this.state.areaSel)
    cl(this.state.areaOpts)
    cl(area)
    delete area["_id"]
    await wsTrans("usa", {cmd: "cRest", uri: "/s/areas", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: area})
    let valves=[]
    cl(this.state)
    let av=(this.state.areaValves[this.state.areaSel]||[]).slice(0)
//     cl(this.state)
//     cl(av)
    av.forEach((v0,i)=>{
      let v=Object.assign({},v0)
//       v.addr=+v.valveId
      let zInd=(v.valveId>>10)&0x3F
      let ch=v.valveId&0x3F
      cl(zInd)
      let zo=globs.zonesInfo.info.filter(z=>{
        return (z.siteId==pa.site)&&(z.siteZoneIndex==zInd)})[0]||{}
      v.addr=(zo.gatewayZoneIndex<<10) | ch
      cl(zo)
      v.gatewayId=zo.gatewayId
      delete v["_id"]
      delete v.text
      delete v.focus
      delete v.valveId
      av[i]=v
    })
    cl(av)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/areaValves", method: "update",
      sessionId: globs.userData.session.sessionId,
//       body: {gatewayId:this.gatewayId, areaId: +this.state.areaSel, valves: av}})
      body: {gatewayId:{$in:this.getSiteGateways()},
      areaId: +this.state.areaSel, valves: av}})
    await saveTable("areas",this.gatewayId)
    await saveTable("areaValves",this.gatewayId)
    globs.events.publish("saveOK",true)
  }
  
  saveAreaO=async()=>{
    let s=this.state
    cl(s)
    let areas=Object.assign({}, this.state.areaOpts)
    areas[s.areaId]={
      siteId: globs.userData.session.siteId,
      areaId: s.areaId,
      name:s.name,
      t:s.name,// text, used by the Select control
      tankId:s.tankSel,
      volume:s.volume,
      notes:s.notes,
    }
    cl(areas)
    await this.saveAreaValves()
    this.setState({areaOpts: areas, editMode: false})
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/areas", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: areas[s.areaId]})
  }
  
  getAnArea=(areaId)=>{
    let valves=[]
    let av=this.areaValves[areaId]
    for(let k in av){
      valves.push({addr:k, time:av[k].time})
    }
    let area=this.state.areaOpts[areaId]
    this.setState(
      Object.assign({editMode: true, areaValves: valves, tankSel: area.tankId},area))
  }
  
  showAreaSelect=()=>{
//     cl(this.state)
    return(
      <UsaSelect00 parms={{
        title: "Select\u00A0Area",
        select: true, // !this.state.editMode,
        inline: true,
        valueId: "areaSel",//valueId, // "widgetSel",
        value: this.state.areaSel, //value, // this.state.widgetSel,
//         textId: "widgetName",
        opts: this.state.areaOpts,
        onChange: x=>this.onChange("area",x)
      }}
      />
    )
  }
  
  newArea=()=>{
//area_id, name, tank, symbol, note, volume
    
    this.setState({
      areaId: getRandomString(16),
      name:"",
      tankId:this.state.tankSel,
      volume:"",
      notes:"",
      editMode:true,
    })
  }
  
  valveResult=(type)=>{
    cl(type)
    let parts=type.split("-")
    let id=+parts[0].substr(5)
    var valves, areaValveAddr, areaValveTime
    switch(parts[1]){
      case "Add":
        areaValveAddr=Object.keys(this.state.valveOpts)[0]
        areaValveTime=0
        valves=this.state.areaValves.slice(0)
        valves.splice(id,0,{
          addr:areaValveAddr,
          time:areaValveTime,
        })
        this.setState({areaValveSel: id, areaValveAddr: areaValveAddr, areaValveTime: areaValveTime,
          areaValves: valves
        })
        break
      case "Delete":
        valves=this.state.areaValves.slice(0)
        valves.splice(id,1)
        this.setState({areaValves: valves})
        break
      case "OK":
        cl(this.state.areaValveAddr)
        valves=this.state.areaValves.slice(0)
        valves[id]={addr:this.state.areaValveAddr, time:this.state.areaValveTime}
        this.setState({areaValveSel: -1, areaValves: valves})
        break
      case "Cancel":
        this.setState({areaValveSel: -1})
        break
      case "Edit":
        areaValveAddr=this.state.areaValves[id].addr
        areaValveTime=this.state.areaValves[id].time
        this.setState({areaValveSel: id, areaValveAddr: areaValveAddr, areaValveTime: areaValveTime})
        break
      default:
        break
    }
  }
  
  result=(type)=>{
//     cl(type)
    if(type.substr(0,5)=="Valve"){return this.valveResult(type)}
    switch(type){
      case "Areas-OK":
        this.saveArea()
        break
      case "Areas-Cancel":
        this.setState({editMode: false})
        break
      case "Areas-Edit":
        this.getAnArea(this.state.areaSel)
        break
      case "Areas-Add":
        this.newArea()
        break
      case "Areas-Delete":
        this.deleteArea(this.state.areaSel)
        break
      default:
        break
    }
  }
  
  makeNewValve=(areaId)=>{
    return {
      valveId:Object.keys(this.state.valveOpts)[0],
      areaId:areaId,
      gatewayId:this.gatewayId,
      time:0,
    }
  }
  
//   checkSave=async()=>{
//     if(!this.modified){return}
//     let res=await this.props.parms.getPopup({text:"Please save your changes before editing a new Area.", buttons:["Skip","Save"]})
//     globs.events.publish("savePageEnable",false)
//     this.modified=false
//     if(res=="Save"){
//       cl("should save")
//       this.saveArea()
//     }
//   }
  
  setModified=()=>{
    globs.events.publish("savePageEnable",true)
    this.modified=true
  }
  
  onChange=async(type, vals, ind, e)=>{
    cl(type,vals)
    var av,ao,areaId,res
    switch(type){
      case "upd":
        this.setModified()
        ao=this.state.areaOpts
        Object.assign(ao[this.state.areaSel]||{},vals)
        this.setState({areaOpts:ao})
        break
      case "valve":
        this.setModified()
        av=Object.assign({},this.state.areaValves)
        Object.assign(av[this.state.areaSel][vals.index],{valveId:vals.addr})
        cl(av)
        this.setState({areaValves:av})
        break
      case "valveTime":
//         cl(type,vals)
        this.setModified()
        av=Object.assign({},this.state.areaValves)
        Object.assign(av[this.state.areaSel][vals.index],{time:vals.duration})
        this.setState({areaValves:av})
        break
      case "addValve":
        this.setModified()
        av=Object.assign({},this.state.areaValves)
        if(!av[this.state.areaSel])av[this.state.areaSel]=[]
        av[this.state.areaSel].push(this.makeNewValve(this.state.areaSel))
        this.setState({areaValves:av})
        break
      case "deleteValve":
        res=await this.props.parms.getPopup({text:"Are you sure you want to delete this valve?", buttons:["Cancel","Yes"]})
        if(res=="Yes"){
          this.setModified()
          av=Object.assign({},this.state.areaValves)
          av[this.state.areaSel].splice(vals.index,1)
          this.setState({areaValves:av})
        }
        break
      case "addArea":
        this.setModified()
        ao=Object.assign(this.state.areaOpts)
        let max=1
        Object.keys(ao).forEach(k=>{
          if(!isNaN(k)){
            cl(k)
            if(max<+k){max=+k}
          }
        })
        
        areaId=+max+1//getRandomString(16)
        cl(areaId)
        ao[areaId]={
          areaId:areaId,
          gatewayId:this.gatewayId,
          name:"New Watering Area",
          notes:"",
          tankId:Object.keys(this.state.tankOpts)[0],
          volume:0,
        }
        av=Object.assign({},this.state.areaValves)
        av[areaId]=[this.makeNewValve(areaId)]
        this.setState({areaSel:areaId,areaOpts:ao,areaValves:av})
        break
      case "deleteArea":
        res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Area?", buttons:["Cancel","Yes"]})
        if(res=="Yes"){
          this.deleteArea(this.state.areaSel)
          ao=Object.assign(this.state.areaOpts)
          av=Object.assign({},this.state.areaValves)
          delete ao[this.state.areaSel]
          delete av[this.state.areaSel]
          areaId=Object.keys(ao)[0]
          this.setState({areaSel:areaId,areaOpts:ao,areaValves:av})
        }
        break
      case "areaO":
        this.setState(Object.assign({editMode: false},vals))
        break
      case "area":// selecting a new area
//         cl("check Area")
        let popInfo={text:"Please save your changes before editing a new Area.", buttons:["Skip","Save"]}
//         cl(this.props.parms.pageModified)
        await checkSave(this,this.saveArea,popInfo)
        this.setState(vals)
        break
      case "valves":
        this.setState(vals)
        break
      case "fields":
        this.setState(vals)
        break
      case "areaValves":
        let vals2={}
        vals2[vals]=e.currentTarget.value
        cl(vals2)
        this.setState(vals2)
        cl([type, vals, ind, e.currentTarget.value])
        break
    }
    
  }
  
  showName=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Name",
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "name",//valueId, // "widgetSel",
        value: this.state.name, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showTank=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Tank",
//         select: true,
//         width: 400,
        select: true, // !this.state.editMode,
        valueId: "tankSel",//valueId, // "widgetSel",
        value: this.state.tankSel, //value, // this.state.widgetSel,
        opts: this.state.tankOpts,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showVolume=()=>{
    return(
      <>
      <UsaSelect00 parms={{
        title: "Volume",
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "volume",//valueId, // "widgetSel",
        value: this.state.volume, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
      <span className="units">L</span>
      </>
    )
  }
  
  showNotes=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Notes",
        multiline: true,
        rowsMax: 8,
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "notes",//valueId, // "widgetSel",
        value: this.state.notes, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showValveAdd=()=>{
    let i=this.state.areaValves.length
    return(
      <tr>
      <td>
        <UsaIcon icon={`Valve${i}-Add`} result={this.result} inline/>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
    )
  }
  
  makeOptions=(opts)=>{
// now, opts is an object, of {k: {t: "the text"}}
// this allows the structure to be used for other things
//     let ret=[]
    let ret=Object.keys(opts).map((k,i)=>{
//       cl(opts[k])
      return <option key={i} value={k}>{opts[k].t}</option>
    })
//     cl(ret)
    return ret
//     for (let k in opts){
//       ret.push(<option key={i} value={o.v}>{o.t}</option>)
//     }
//     return opts.map((o,i)=>{
//       return (
//         <option key={i} value={o.v}>{o.t}</option>
//       )
//     })
  }
  
  showSelect=(type, val,valId,ind,opts)=>{
    return(
      <select style={{marginLeft: "0.3em",}}
        value={val} 
        onChange={e=>this.onChange(type, valId,ind,e)}
      >
      {this.makeOptions(opts)}
      </select>
    )
  }
  
  showInput=(type, val, valId, ind)=>{
    return(
      <input
        value={val}
        type="number"
        onChange={e=>this.onChange(type, valId, ind, e)}
      />
    )
  }
  
  showValveSelection=(addr)=>{
//     cl(this.state)
    return this.showSelect("areaValves", this.state.areaValveAddr, "areaValveAddr",0,this.state.valveOpts)
  }
  
  showValveTime=()=>{
    return (//this.showInput("areaValveTime",this.state.areaValveTime, "areaValveTime",0)
    <UsaDuration00
      value={this.state.areaValveTime}
      valueId="areaValveTime"
      onChange={e=>this.onChange("valves",e)}
    />
    )
  }
  
  getValveName=(addr)=>{
    let vo=this.state.valveOpts
    for(let i=0;i<vo.length;i++){
      if(vo[i].v==addr){return vo[i].t}
    }
//     this.state.valveOpts[+addr]
//     return "this"
  }
  
  showValveLine=(name, addr, time, i)=>{
//     cl(addr)
    cl(this.state.valveOpts)
    if(this.state.areaValveSel==i){
      return <>
      <td>
        {this.showValveSelection(addr)}
      </td><td>
        {this.showValveTime()}
      </td>
      </>
    }else{
//       cl(addr)
//       cl(+addr)
//       cl(this.state.valveOpts[+addr])
//       cl(this.state.valveOpts)
      if(this.state.valveOpts[addr]){
        return(
          <><td>{this.state.valveOpts[addr].t}</td><td>{`for ${secsToHms(time)}`}</td></>
        )
      }else{return null}
    }
  }
  
  showValveIcons=(i)=>{
    if(this.state.areaValveSel==i){
      return(
        <>
          <td>
            <UsaIcon icon={`Valve${i}-OK`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Valve${i}-Cancel`} result={this.result} inline/>
          </td>
          <td>
          </td>
        </>
      )
    }else{
      return(
        <>
          <td>
            <UsaIcon icon={`Valve${i}-Add`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Valve${i}-Edit`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Valve${i}-Delete`} result={this.result} inline/>
          </td>
        </>
      )
    }
  }
  
  showValves=()=>{
    cl(this.state.areaValves)
    return(
      <div>
      <h3>Irrigation Valves</h3>
        <table><tbody>
        {this.state.areaValves.map((v,i)=>{
  //         cl(n)
          let name="and"//this.nutrients[n.nutrientId].name
          return(
            <tr key={i}>
            {this.showValveIcons(i)}
            {this.showValveLine(name, v.addr, v.time, i)}
            </tr>
          )
        })}
        {this.showValveAdd()}
        </tbody></table>
      </div>
    )
    
  }
  
  showEditFields=()=>{
    if(this.state.editMode){
      return(
      <div>
        {verticalSpace}
        {this.showName()}
        {verticalSpace}
        {this.showTank()}
        {verticalSpace}
        {this.showVolume()}
        {verticalSpace}
        {this.showNotes()}
        {verticalSpace}
        {this.showValves()}
      </div>
      )
    }else{
      return null
    }
  }
  
  showMainIcons=()=>{
    if(this.state.editMode){
      return([
        <UsaIcon key={0} space="0.5" icon="Areas-OK" result={this.result} inline/>,
        <UsaIcon key={1} icon="Areas-Cancel" result={this.result} inline/>,
      ])
    }else{
      return([
        <UsaIcon key={0} space="0.5" icon="Areas-Add" result={this.result} inline/>,
        <UsaIcon key={1} icon="Areas-Edit" result={this.result} inline/>,
        <UsaIcon key={2} icon="Areas-Delete" result={this.result} inline/>,
      ])
    }
  }
  
  renderO(){
    if(this.state.loaded){
      return(
        <div style={{padding: 10}}>
        <h3>Areas
        {this.showMainIcons()}
        </h3>
        {this.showAreaSelect()}
        {this.showEditFields()}
        
        </div>
      )
    }else{
      return <div>loading...</div>
    }
  }
  
  showAreaSelect=()=>{
//     cl(this.state)
    return(
              <span className="custom-select">
                <label htmlFor="idoser-area">Select Area</label>
                <select id="idoser-area"
                  value={this.state.areaSel}
                  onChange={e=>this.onChange("area",{areaSel:e.currentTarget.value})}
                >
                {Object.keys(this.state.areaOpts).map((areaId,i)=>{
                  return(
                    <option key={i} value={areaId}>{this.state.areaOpts[areaId].name}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>
    )
  }
  
  showTankSelect=(area)=>{
    let to=this.state.tankOpts
    return(
              <span className="custom-select">
                <label htmlFor="idoser-tank">Tank</label>
                <select id="idoser-tank"
                  value={area?.tankId} 
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("upd",{tankId:e.currentTarget.value})}
                >
                {Object.keys(to).map((tankId,i)=>{
                  return(
                    <option key={i} value={tankId}>{to[tankId]}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>
    )
    
  }
  
  showAreaColor=(area)=>{
    let st=this.state
//     cl(this.state)
    let rng=[...Array(13).keys()]
    return(
      <div>
        <label>Color</label>
        <table><tbody>
        <tr><td width="30">
          <div style={{width:30,height:30,backgroundColor:area?.color}}/>
          </td>
          <td>
            <table style={{width:130}}><tbody>
            {
            [...Array(2).keys()].map(ro=>{
//               cl(ro)
              return(
                <tr key={ro} width="130">
                {rng.map(i=>{
                  return(
                    <td key={i} width="10"><div style={{width:10,height:10,
                      cursor:"pointer",
                      backgroundColor:this.colors[13*ro+i]}}
                      onClick={e=>{this.onChange("upd",{color:this.colors[13*ro+i]})}}
                    />
                    </td>
                  )
                })}
                </tr>
              )
            })
              
            }
            </tbody></table>
          </td>
        </tr>
        </tbody></table>
        
        <br/>
      </div>
    )
  }
  
  showAreaEdit=()=>{
     let area=this.state.areaOpts[this.state.areaSel]
//      cl(this.state)
//               <br />
//               {this.showTankSelect(area)}
//               <label htmlFor="idoser-volume">Volume</label>
//               <input type="number" min="0"
//               value={Math.round((area?.volume||0)/constant.LITERS_PER_GALLON)}
//               disabled={!area||!this.props.parms.saveOK}
//               onChange={e=>this.onChange("upd",{volume:e.currentTarget.value*constant.LITERS_PER_GALLON})}
//               />
//               <span className="units">Gal</span>
//
//               <div className="clearfloat"></div>
//               <br />
//
    return(
      <div>
              <label htmlFor="idoser-name">Name</label>
              <input id="idoser-name" type="text" 
              value={area?.name||""} 
              disabled={!area||!this.props.parms.saveOK}
              onChange={e=>this.onChange("upd",{name:e.currentTarget.value})}
              />
              <br/>
              {this.showAreaColor(area)}


              <label htmlFor="idoser-notes">Notes</label>
              <textarea id="idoser-notes" 
              value={area?.notes||""} 
              disabled={!area||!this.props.parms.saveOK}
              onChange={e=>this.onChange("upd",{notes:e.currentTarget.value})}
              />
      </div>
      
    )
  }
  
  showValveSelect=(av,index)=>{
    let vo=this.state.valveOpts
    let pa=this.props.parms
    cl(vo)
//     cl(this.state)
//     cl(globs.zonesInfo.info)
//     cl(this.props)
//     cl(av)
    return(
                <span className="custom-select floatleft">
                  <label>Valve</label>
                  <select
                    value={av.valveId}
                    disabled={!this.props.parms.saveOK}
                    onChange={e=>this.onChange("valve",{addr:e.currentTarget.value,index:index})}
                  >
                  {Object.keys(vo).map((valveId,i)=>{
                    let zInd=(valveId>>10)&0x3F
//                     cl(zInd)
                    let zo=globs.zonesInfo.info.filter(z=>{
                      return (z.siteId==pa.site)&&(z.siteZoneIndex==zInd)
                    })[0]||{}
//                     cl(zo)
                    let vName=`${zo.zoneName} - ${vo[valveId].t}`
                    return(
                      <option key={i} value={valveId}>{vName}</option>
                    )
                  })}
                  </select>
                  <span className="material-icons down-arrow">
                    keyboard_arrow_down
                  </span>
                </span>
    )
  }
  
//   onFocus=(v,i)=>{
//     let av=Object.assign({},this.state.areaValves)
//     Object.assign(av[this.state.areaSel][i],{focus:true,text:secsToHms(v.time)})
//     this.setState({areaValves:av})
//   }
//   
//   onBlur=(v,i)=>{
// //     cl(v)
//     let av=Object.assign({},this.state.areaValves)
//     Object.assign(av[this.state.areaSel][i],{focus:false,time:hmsToSecs(v.text)})
//     this.setState({areaValves:av})
//   }
  
  showIrrigationValves=()=>{
    //pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}" 
    let av=this.state.areaValves[this.state.areaSel]||[]
    cl(av)
//                     <input className="narrow inline-block" type="text" pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
//                       value={(v.focus)?v.text:secsToHms(v.time)}
//                       onFocus={e=>this.onFocus(v,i)}
//                       onBlur={e=>this.onBlur(v,i)}
//                       onChange={e=>this.onChange("valveTime",{text:e.currentTarget.value,index:i})}
//                     />
    return(
      <div>
              <h2>Irrigation Valves</h2>
              {av.map((v,i)=>{
//                 cl(valveId)
//                 cl(v)
//                     <label>Time</label>
//                     <C18Duration00 parms={{
//                       duration:v.time,
//                       onChange:e=>this.onChange("valveTime",Object.assign(e,{index:i})),
//                       saveOK:this.props.parms.saveOK
//                     }}
//                     />
                return(
                  <div key={i} className="line">
                    {this.showValveSelect(v,i)}

                    <button type="button" className="material-icons trash" aria-label="delete nutrient"
                    style={{marginTop:20}}
                      onClick={e=>this.onChange("deleteValve",{index:i})}
                      disabled={!this.props.parms.saveOK}
                      >
                      delete_outline
                    </button>
                  </div>
                )
              })}

              <button className="material-icons-outlined add" aria-label="add nutrient"
                disabled={!this.state.areaSel||!this.props.parms.saveOK}
                onClick={e=>this.onChange("addValve")}>
                add
              </button>
      </div>
      
    )
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div id="iDoser_areas">
          <div className="clearfloat"></div>
                {this.showAreaSelect()}
              <button type="button" className="material-icons trash after-selector" aria-label="delete nutrient"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("deleteArea")}>
                delete_outline
              </button>

              <button type="button" className="material-icons-outlined add after-selector" aria-label="add nutrient"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("addArea")}>
                add
              </button>


                <div className="clearfloat"></div>
                <hr /><br />

  {this.showAreaEdit()}
                <br />

  {this.showIrrigationValves()}
        </div>
      )
    }else{return<div>loading. . .</div>}
  }
}

export default Areas ;

