import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {wsTrans} from '../utils/utils'
import {loadUsersInfo} from './C18utils'
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';
import history from "../../history"
import C18Confirm00 from './C18Confirm00'

class C18MessageList00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      sortMode:-1,
      loaded:false,
      AllChecked:false,
      msgsRead:[],
      popup:{
        opacity:0,
        text:"Are you sure?",
        buttons:["Close"],
        resolve:null,
      },
    }
    this.setBreadCrumbs()
    this.loadInfo()
    this.doLoadMessages=setInterval(this.doLoadMessages,10000)
  }
  
  componentWillUnmount=()=>{
//     if(this.subscribe_wsServerOK){this.subscribe_wsServerOK.remove()}
    clearInterval(this.doLoadMessages)
  }
  
  setBreadCrumbs=()=>{
//     cl(this.props.parms)
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Messages", url:`/usa/c18/messaging/messageList`},
            ]},
        },
      )
      this.props.parms.onChange(
        {
          cmd: "pageTitle",
          pageTitle: "Messages"
        }
      )
    }
  }
  
  loadMessages=async()=>{
    let userId=globs.userData.session.userId
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {
        query:{recipientId:userId},
        skip:0,
        limit:10,
      }})
//     cl(res.data)
//     let messages=res.data.filter(msg=>{return msg.userId!=userId})
    let messages=res.data
    messages.forEach(m=>{
      // cl(m)
      m.fromName=this.userArr[m.userId]?.name
    })
    let notifications=await wsTrans("usa", {cmd: "cRest", uri: "/s/notifications", method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {
        userId:userId,
      }})
//     cl(notifications)
    notifications.data.forEach(n=>{
//       let fromName="who"
//       cl(n)
//       cl(this.userArr)
      let fromName=this.userArr[n.userId].name
//       cl(n.userId,fromName)
      messages.push({
        ts:n.date,
        subject:n.subject,
        userId:n.from,
        fromName:fromName,
        chatText:n.body,
        type:n.type||"gj",
        siteId:n.site,
        zoneId:n.zone,
        level:n.level,
        pageType:n.pageType,
        zuci:n.zuci,
        flags:n.flags,
        notificationId:n.notificationId,
        taskId:n.taskId,
        growJournalId:n.growJournalId
      })
    })
    // cl(messages)
//     cl(res)
//     messages.sort((a,b)=>{
//       if(a.ts>b.ts){return -1}
//       if(a.ts<b.ts){return 1}
//       return 0
//     })
    return this.sortMessages(messages,this.state.sortMode)
//     return messages
  }
  
  sortMessages=(messages,sortMode)=>{
    let field=["","ts","fromName","subject"][Math.abs(sortMode)]
    let dir=(sortMode>0)?1:-1
    messages.sort((a,b)=>{
      if(a[field]>b[field]){return dir}
      if(a[field]<b[field]){return 0-dir}
      return 0
    })
    return messages

//     var field
//     if(this.simple){
//       field=["","complete","title","dueDate","handler0","site",
//         "zone"][Math.abs(sortMode)]
//     }else{
//       field=["","title","priority","pcntComplete","status","handler0",
//         "dueDate"][Math.abs(sortMode)]
//     }
// //     cl(field)
//     let dir=(sortMode>0)?1:-1
//     tasks.sort((a,b)=>{
//       if(a[field]>b[field]){return dir}
//       if(a[field]<b[field]){return 0-dir}
//       return 0
//     })
//     return tasks
  }
  
  
  doLoadMessages=async()=>{
    let messages=await this.loadMessages()
    this.setState({messages:messages})
  }
  
  loadInfo=async()=>{
    await loadUsersInfo()
    this.userArr={}
    globs.usersInfo.info.forEach(ui=>{this.userArr[ui.userId]=ui})
//     cl("loaded users")
    let messages=await this.loadMessages()
//     cl(messages)
    this.setState({loaded:true,messages:messages})
  }
  
  goToGJ=(msg)=>{// mark as read - also does 'task' types
    let tabs={gj:"SPgrowJournal",task:"SPtasks"}
    let tab=`/${tabs[msg.type]}`||""
    // cl(msg)
    msg.flags=msg.flags&~constant.CHAT_FLAG_UNREAD
//     cl(msg.flags)
    wsTrans("usa", {cmd: "cRest", uri: "/s/notifications", method:"update",
      sessionId: globs.userData.session.sessionId,
      body: {
        flags:msg.flags,
        notificationId:msg.notificationId,
      }})

    let targetId = tabs[msg.type].includes("SPgrowJournal") || tabs[msg.type].includes("SPtasks") ? tabs[msg.type] == "SPtasks"? msg.taskId : msg.growJournalId:""
     // cl(msg)
    switch(msg.level){
      case "account":
        history.push(`/usa/c18/sites${tab}/${targetId}`)
        // history.push(`/usa/c18/tasks/`)
// http://ngsg.link4cloud.com:3104/usa/c18/sites        
        break
      case "site":
        history.push(`/usa/c18/sites/${msg.siteId}${tab}/${targetId}`)
// http://ngsg.link4cloud.com:3104/usa/c18/sites/d1fIue45CWvP@Nik        
        break
        case "sites":
          history.push(`/usa/c18/sites${tab}/${targetId}`)
        // history.push(`/usa/c18/tasks/`)
    // http://ngsg.link4cloud.com:3104/usa/c18/sites        
          break
      case "zone":
      case "zones":
        // cl(`/usa/c18/sites/${msg.siteId}/zones/${msg.zoneId}${tab}/${targetId}`)
        history.push(`/usa/c18/sites/${msg.siteId}/zones/${msg.zoneId}${tab}/${targetId}`)
// http://ngsg.link4cloud.com:3104/usa/c18/sites/d1fIue45CWvP@Nik/zones/IlmXrmHKPHRlXd7R        
        break
      case "config":
        // cl("config")
        if (msg.pageType == "sensor") {
          history.push(`/usa/c18/sites/${msg.siteId}/zones/${msg.zoneId}/sensor/${msg.zuci}${tab}/${targetId}`)
        } else {
          // cl(`/usa/c18/sites/${msg.siteId}/fui/${msg.pageType}/${msg.zuci}${tab}`)
          history.push(`/usa/c18/sites/${msg.siteId}/fui/${msg.pageType}/${msg.zuci}${tab}/${targetId}`)
        }
// http://ngsg.link4cloud.com:3104/usa/c18/fui/channel_Irrigation_Scheduled/0-0-0-0        
        break
    }
  }

  readMsg = async (selectedMsg) =>{
    if(["gj","task"].includes(selectedMsg.type)){
      let tabs={gj:"SPgrowJournal",task:"SPtasks"}
      selectedMsg.flags=selectedMsg.flags&~constant.CHAT_FLAG_UNREAD
      await wsTrans("usa", {cmd: "cRest", uri: "/s/notifications", method:"update",
        sessionId: globs.userData.session.sessionId,
        body: {
          flags:selectedMsg.flags,
          notificationId:selectedMsg.notificationId,
        }})
    }
    else{
      selectedMsg.flags=selectedMsg.flags&~constant.CHAT_FLAG_UNREAD
      await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method:"update",
        sessionId: globs.userData.session.sessionId,
        body: {
          flags:selectedMsg.flags,
          messageId:selectedMsg.messageId,
      }})
    }
  }

  resolvePopup=(e,resolve)=>{
    //     cl(e)
        let popup=this.state.popup
        popup.opacity=0
        this.setState({popup:popup})
        if(resolve){resolve(e)}
  }
  
  onChange=async (type,vals)=>{
//     cl([type,vals])
    let st=this.state
    switch(type){
      case "create":
        vals.e.preventDefault()
        history.push("/usa/c18/messaging/messageEdit")
//         cl(vals)
        break
      case "msg":
//         cl(this.state.messages[vals.selMessage])
        let msg=st.messages[vals.selMessage]
//         cl(msg)
        if(["gj","task"].includes(msg.type)){
          return this.goToGJ(msg)
        }else{
          msg.flags=msg.flags&~constant.CHAT_FLAG_UNREAD
          wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method:"update",
            sessionId: globs.userData.session.sessionId,
            body: {
              flags:msg.flags,
              messageId:msg.messageId,
            }})
          let messageId=msg.messageId
          history.push(`/usa/c18/messaging/messageDisplay/${messageId}`)
  //         cl(vals)
        }
        break
      case "sort":
        if(Math.abs(vals.sortMode)==st.sortMode){
          vals.sortMode=0-st.sortMode
        }
        let messages=this.sortMessages(st.messages.slice(0),vals.sortMode)
        Object.assign(vals,{messages:messages})
        this.setState(vals)
        break
      case "selectMsg":
        if (vals.checked){
          this.setState(prevState => ({
            msgsRead: [...prevState.msgsRead,vals.selMessage]
          }));
        }
        else{
          this.setState(prevState => ({
            msgsRead: prevState.msgsRead.filter(msgIndex => msgIndex !== vals.selMessage)
          }));
        }
        break
      case "markMsgRead":
        let msgCount = this.state.msgsRead
        let AllMsg = st.messages

        if(msgCount.length>0){
          for(let i=0;i<this.state.msgsRead.length;i++){
     
            let MsgSelect = AllMsg[msgCount[i]];

            this.readMsg(MsgSelect)
          }
        }
        else if(this.state.AllChecked){
          for(let i=0;i<AllMsg.length;i++){
            let MsgSelect = AllMsg[i];
            
            this.readMsg(MsgSelect)
            
          }
          this.setState({AllChecked:false})
        }else{
          return new Promise((r,e)=>{
            let popup={
              opacity:1,
              text:"Please select any message to mark as Read",
              buttons:["Close"],
              resolve:e=>this.resolvePopup(e,r),
            }
          this.setState({popup:popup})
          })
        }
        break
    }
  }
  
  showMessageHead=()=>{
    let sort=this.state.sortMode// 1-5 for the columns
//     cl(this.state)
    let icons=[]
    for(let i=0;i<3;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }
    return(
      <tr>
          <th><input type="checkbox" id="" onClick={(e) => {
              e.stopPropagation()
              !e.target.checked ? this.setState({AllChecked:false}) : this.setState({AllChecked:true})
              }} checked={this.state.AllChecked} /></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:1})}
          >Date <span className="material-icons-outlined">{icons[0]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:2})}
          >From <span className="material-icons-outlined">{icons[1]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:3})}
          >Subject <span className="material-icons-outlined">{icons[2]}</span></button></th>
          <th>Body</th>
      </tr>
    )
  }
  
  showHandler=(msg)=>{
    var path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    var name
    if(msg.userId){
      let av=this.userArr[msg.userId]?.avatar
      if(av){
        path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
      }
      name=(this.userArr[msg.userId]||{}).name||"Unknown"
    }
    return (
      <table><tbody>
      <tr><td style={{width:50}}><img src={path} width="50"/></td><td>{name}</td></tr>
      </tbody></table>
    )
  }

  showMessages=()=>{
    let st=this.state
    let banding=globs.usersInfo.uiInfo?.tableBanding||true
    return(
      <table><tbody>
      {this.showMessageHead()}
      {st.messages.map((msg,i)=>{
//         cl(msg)
        let tableBand=(((i+1)%2)&&banding)?"tableBand":null
//         cl(msg)
        let da=new Date(1000*msg.ts)
        let daShow=dateToDisplayDate(da,"mm/dd/yyyy h:mm ap")
        let from=(this.userArr[msg.userId]||{}).name||"Unknown"
        let subject=msg.subject||"No Subject"
        if(subject.length>25){subject=subject.substring(0,20)+"..."}
        let body=msg.chatText
        if(body.length>45){body=body.substring(0,40)+"..."}
        let notRead=msg.flags&constant.CHAT_FLAG_UNREAD
        return(
          <tr key={i} style={{cursor:"pointer",fontWeight:(notRead)?700:400}}
            className={tableBand}
            onClick={e=>this.onChange("msg",{selMessage:i})}
          >
          <td>
            <input type="checkbox" id="" onClick={(e) => {
              e.stopPropagation()
              this.onChange("selectMsg",{selMessage:i,checked:e.target.checked})}} checked={this.state.AllChecked ? this.state.AllChecked :null} />
          </td>
          <td>{daShow}</td>
          <td>{this.showHandler(msg)}</td>
          <td>{subject}</td>
          <td title={body}>{body}</td>
          </tr>
        )
      })}
      </tbody></table>
    )
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div>
          <C18Confirm00 parms={this.state.popup}/>
          <div className="section-controls floatright">
            <C18Anchor00 to="" onClick={(e)=>this.onChange("create", {e:e})} className="material-icons-outlined add" aria-label="add report">
              add
            </C18Anchor00>
            <button type="button" title="Mark All Read" onClick={(e)=>this.onChange("markMsgRead", {e:e})} style={{padding:2,marginLeft:10}} className="material-icons-outlined add" aria-label="add report">
              mark_email_read
            </button>
          </div>
          {this.showMessages()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18MessageList00
