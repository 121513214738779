import React from 'react';
import C18Select00 from './C18Select00'
import C18Select01 from './C18Select01'
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import C18Images00 from './C18Images00'
import {privs} from './C18utils'
import history from "../../history"
import {wsTrans,doGetPostBasic} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,getSiteName,getZoneName,makeImagePath,
  taskCategories,taskStatus
} from './C18utils';
import {cl,globs,constant,getTime,dateToDisplayDate} from '../../components/utils/utils';

class C18TaskDetail00 extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={
      loaded:false,
      task:{},
      items:{
        created:"Created",
        approved:"Approved",
        readyToStart:"Ready To Start",
        inProgress:"In Progress",
        problem:"Problem",
        onHold:"On Hold",
        cancelled:"Cancelled",
        waitingForReview:"Waiting For Review",
        complete:"Complete",
      },
      status:"",
      itemSel:"problem",
      listOpen:false,
      overIndex:-1,
    }
    this.loadInfo()
  }
  
  setBreadCrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    let base="/usa/c18/sites"
    this.baseUrl=base
    if(p){
      let bc=[
        {t:"Sites", url:base},
      ]
      if((p.scope=="site")||(p.scope=="zones")){
        bc.push(
          {t:siteName, url:`${base}/${p.site}`}
        )
      }
      if(p.scope=="zones"){
        bc.push(
          {t:zoneName, url:`${base}/${p.site}/zones/${p.zone}`},
        )
      }
      bc.push(
        {t:"Tasks", url:base+"/tasks"},
      )
      bc.push(
          {t:this.state.task.title, url:`${base}/${p.site}/zones/${p.zone}/tasks`},
      )
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: bc},
        },
      )
    }
  }
  
  loadTask=async(taskId)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {
        taskId:{$in: [taskId]},// taskIds: ,
      }})
    return res.data[0]
  }
  
  loadUpdates=async(taskId)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/taskUpdates", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {
        taskId:taskId,// taskIds: ,
      }})
//     cl(updates)
    return res.data
  }
  
  loadInfo=async()=>{
    await loadUsersInfo()
    let ta=await this.loadTask(this.props.parms.adminInfo)
//     cl(ta)
    let updates=await this.loadUpdates(ta.taskId)
//     cl(updates)
    this.setState({task:ta,updates:updates,status:ta.status,pcntComplete:ta.pcntComplete||0,
      timeSpent:ta.timeSpent||0,note:"",loaded:true})
      if(ta.title.length>35){
        ta.title=ta.title.substring(0,32)+"..."
      }
    this.props.parms.onChange({cmd:"pageTitle",data:{pageTitle:ta.title}})
    this.setBreadCrumbs()
    
  }
  
  
/***************** Custom Color Select *******************************/  
// I have no idea why this is here!
/*
  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "drop":
        this.setState({listOpen:!this.state.listOpen})
        break
      case "item":
        this.setState({itemSel:vals.key,listOpen:false})
        break
      case "upd":
        this.setState(vals)
        break
    }
  }
  
  showSelection=()=>{
//       style={{width:100, height:40, borderRadius:10, backgroundColor:"#CCCCCC"}}
    return(
      <>
      <div className="custom-select custom-color-select-selection"
      onClick={e=>this.onChange("drop",{})}
      >
      <span className={`bubble-text status-${this.state.itemSel}`}>{this.state.items[this.state.itemSel]}</span>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
      </>
    )
  }
  
//   showTestSelect=()=>{
//     return(
//       <div className="custom-select">
//         <label htmlFor="">Select Zone</label>
//         <C18Select00 
//           value={this.state.zoneSel}
//           onChange={e=>this.onChange("zoneSel",{zoneSel: e.currentTarget.value})}
//         id="">
//         <option>one</option>
//         <option>two</option>
//         </C18Select00>
//         <span className="material-icons down-arrow">
//           keyboard_arrow_down
//         </span>
//       </div>
//     )
//   }
  
  mouseOver=(id)=>{
    this.setState({overIndex:id})
  }
  
  mouseOut=(id)=>{
    this.setState({overIndex:-1})
  }
  
  showList=()=>{
    let it=this.state.items
//  style={{padding: "0px 20px", backgroundColor:"blue"}}    
    return(
      <div className="custom-color-select-list" style={{display:(this.state.listOpen)?"block":"none"}}>
      <table><tbody>
        {Object.keys(it).map((k,i)=>{
          return(
            <tr key={i} >
            <td className={(i==this.state.overIndex)?"over":""}
              onMouseOver={e=>this.mouseOver(i)} 
              onMouseOut={e=>this.mouseOut(i)}
              onClick={e=>this.onChange("item",{key:k})}
              >
              <span className={`bubble-text status-${k}`}>{it[k]}</span><br/>
            </td></tr>
          )
          return k
        })}
      </tbody></table>
      </div>
    )
  }

  customColorSelect=()=>{
    return(
      <div className="custom-color-select-container">
      {this.showSelection()}
      {this.showList()}
      </div>
    )
  }*/
  
/***************** End Custom Color Select *******************************/

  showBody=()=>{
    return(
      <div>
      {this.state.task.body}
      </div>
    )
  }
  
  updateStatus=async()=>{
    cl("update")
    let now=Math.floor(getTime())
    let st=this.state
    let update={
      taskId:st.task.taskId,
      updateTime:now,
      status:st.status,
      pcntComplete:st.pcntComplete,
    }
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "update", 
      sessionId: globs.userData.session.sessionId, body: update})
    Object.assign(update,{userId:globs.userData.session.userId,timeSpent:st.timeSpent,note:st.note})
    await wsTrans("usa", {cmd: "cRest", uri: "/s/taskUpdates", method: "create", 
      sessionId: globs.userData.session.sessionId, body: update})
    let updates=st.updates.slice(0)
    updates.push(update)
    this.setState({updates:updates})
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
//     cl(this.props)
    cl(this.baseUrl)
    let pa=this.props.parms
    switch(type){
      case "image":
        let imageId=vals.image.id
        pa.onChange({cmd:"image",path:makeImagePath(imageId)})
        break
      case "upd":
        this.setState(vals)
        break
      case "update":
        this.updateStatus()
        break
      case "editTask":
        history.push(`${this.baseUrl}/taskEdit/${pa.adminInfo}`)
        break
    }
  }
  
//   showImageArray=()=>{
//     let images=this.state.task.images
// //     let st=this.state
// //     cl(st.task.images)
//     return(
//       images.map((im,i)=>{
//         let path=makeImagePath(im.id,"jpg")
//         let totPath=`${constant.expressUrl}/usa/images/uploads/${path}`
// //         cl(totPath)
// //         let path=
//         return(
//           <div key={i} style={{display:"inline-block",margin:10,boxShadow:"5px 5px 10px #808080",
//             cursor:"pointer"}}>
//             <img src={totPath} width="100"
//               onClick={e=>this.onChange("image",{image:images[i]})}
//             />
//           </div>
//         )
//       })
//     )
//   }
  
  showImages=()=>{
    return(
      <div>
        <C18Images00 parms={{
          images:this.state.task.images,
          edit:false,
          onChange:this.props.parms.onChange,
  
        }}/>
      </div>
    )
  }
  
  showPriority=(priority)=>{
    let priorities={
      low:"Low",
      medium:"Medium",
      high:"High",
      urgent:"Urgent",
    }
    return (priority)?(
      <span className={`bubble-text priority-${priority}`}>{priorities[priority]}</span>
    ):null
  }
  
  showCategory=(category)=>{
    let categoryName=taskCategories.filter(ca=>{return ca.v==category})[0]?.t||""
    return(category)?(
      <span className={`bubble-text category-${category}`}>{categoryName}</span>
    ):null
  }
  
  showDates=(ta)=>{
    cl(ta)
    let assignDa=new Date(Date.parse(ta.assignDate))
    let dueDa=new Date(Date.parse(ta.dueDate))
    cl([dueDa,ta.dueDate])
    let tzo=dueDa.getTimezoneOffset()
    let assignShow=dateToDisplayDate(assignDa,"mm/dd/yyyy",0-tzo)
    let dueShow=dateToDisplayDate(dueDa,"mm/dd/yyyy",0-tzo)
    
//     cl([assignDa,dueDa,tzo,assignShow])
    return(
      <div>
        <div style={{width:100,display:"inline-block"}}>Assigned:</div>
        <div style={{width:100,display:"inline-block"}}>{assignShow}</div>
        <div className="clearfloat"/>
        <div style={{width:100,display:"inline-block"}}>Due:</div>
        <div style={{width:100,display:"inline-block"}}>{dueShow}</div>
      </div>
    )
  }
  
  showHandlers=(handlers)=>{
    let handArr=[]
    handArr=handlers.map(h=>{
      return globs.usersInfo.info.filter(u=>{return u.userId==h})[0]?.name
    })
    let handlerNames=handArr.join(", ")
    return(
      <div>
        <div style={{width:100,display:"inline-block"}}>Handlers:</div>
        <div style={{width:100,display:"inline-block"}}>{handlerNames}</div>
      </div>
    )
  }
  
  showStatus=()=>{
    return(
      <C18Select01 parms={{
        label:"Status",
        status:this.state.status,
        valueName:"status",
        opts:taskStatus,
        onChange: this.onChange,
      }}/>
    )
  }
  
  showPcntCompete=()=>{
    return(
      <>
        <label>Percent Complete</label>
        <input 
          type="number" 
          className="alignbottom" 
          min="0" 
          value={this.state.pcntComplete}
          onChange={e=>this.onChange("upd",{pcntComplete:e.target.value})}
        />
      </>
    )
  }
  
  showTimeSpent=()=>{
    return(
      <>
        <label>Time Spent Today</label>
        <input 
          type="number" 
          className="alignbottom" 
          min="0" 
          value={this.state.timeSpent}
          onChange={e=>this.onChange("upd",{timeSpent:e.target.value})}
        />
        Hours
      </>
    )
  }
  
  showNote=()=>{
    return(
      <>
        <label>Notes</label>
        <textarea style={{width:500,height:200}}
          value={this.state.note}
          onChange={e=>this.onChange("upd",{note:e.target.value})}
        />
      </>
    )
  }
  
  showUpdates=()=>{
    let st=this.state
    let updates=st.updates.slice(0)
    updates.sort((a,b)=>{
      if(a.updateTime>b.updateTime){return -1}
      if(a.updateTime<b.updateTime){return 1}
      return 0
    })
    updates.splice(5)
    
    return(
      <div style={{width:800}}>
      <h3>Recent Progress</h3>
      <table><tbody>
      <tr>
      <th>Time</th>
      <th>Status</th>
      <th>Handler</th>
      <th>Hours</th>
      <th>Completion</th>
      <th>Note</th>
      </tr>
        {
          updates.map((up,i)=>{
            let da=new Date(1000*up.updateTime||0)
            let daShow=dateToDisplayDate(da,"mm/dd/yyyy h:mm ap")
            cl(up)
            let status=taskStatus.filter(ts=>{return ts.v==(up.status||"created")})[0].t
            let handler=globs.usersInfo.info.filter(u=>{return u.userId==up.userId})[0]?.name
            let fullNote=up.note||""
            let note=fullNote
            if(note.length>35){
              note=note.substring(0,32)+"..."
            }
//             cl(status)
//             cl(up)
            return(
              <tr key={i}>
              <td>{daShow}</td>
              <td>{status}</td>
              <td>{handler}</td>
              <td>{`${up.timeSpent}`}</td>
              <td>{`${up.pcntComplete} %`}</td>
              <td title={fullNote} style={{cursor:"default"}}>{note}</td>
              </tr>
            )
          })
        }
      </tbody></table>
      </div>
    )
  }
  
  showDetail=()=>{
    let ta=this.state.task
    let admin=(privs("account",0,constant.AREA_PRIVS_ADMIN)!=0)
    return(
      <div id="/tasks/viewTask">
        {this.showBody()}
        <div className="clearfloat"/><br/>
        {this.showImages()}
        <div className="clearfloat"/><br/>
        {this.showPriority(ta.priority)}
        <span style={{display:"inline-block",width:20}}></span>
        {this.showCategory(ta.category)}
        <div className="clearfloat"/><br/>
        {this.showDates(ta)}
        <div className="clearfloat"/><br/>
        {this.showHandlers(ta.handlers)}
        <div className="clearfloat"/><br/>
        
        {this.showUpdates()}
        <div className="clearfloat"/><br/>
        <h3>Today's Work</h3>
        {this.showStatus()}
        <div className="clearfloat"/>
        {this.showPcntCompete()}
        <div className="clearfloat"/><br/>
        {this.showTimeSpent()}
        <div className="clearfloat"/><br/>
        {this.showNote()}
        <div className="clearfloat"/><br/>
        {admin&&
          <C18Button00 type="button" className="filled" onClick={()=>this.onChange("editTask",)}>
            Edit Task</C18Button00>
        }
        <C18Button00 type="button" className="filled"
        style={{marginLeft:10}}
        onClick={()=>this.onChange("update",)}>
          Update</C18Button00>
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
      return this.showDetail()
//       return(
//         <div>
//           <div className="section-controls floatright">
//             {this.customColorSelect()}
//           </div>
//         detail
//         </div>
//       )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18TaskDetail00;
