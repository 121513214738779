import React from 'react';
import FUISelect from './FUISelect';
// import Checkbox from './Checkbox';
// import TimeOfDay from './TimeOfDay';
import {cl, globs,constant,proportionals} from '../../components/utils/utils';

class HumDeHum extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let setVal={type: "array", count: 4}
//     cl(this.props)
    this.chan=+props.zuci.split("-")[2]
    if(this?.props?.custom?.overrides){
      if(this.props.custom.overrides.c){
        this.chan=this.props.custom.overrides.c
      }
      setVal.overrides=this.props.custom.overrides
    }
    var hp
    let chType=+props.current.channelInfo[this.chan].channelType
    let isProp=proportionals.includes(chType)
    if(isProp){
      hp=[{v:-1, t:"---"}]
      for(let i=0;i<100;i++){hp.push({v:i, t:`${i} %`})}
    }else{
      hp=[
      {v:-1, t:"---"},
      {v:0, t:"Off"},
      {v:1, t:"On"}
      ]
    }
    this.humProps={options:hp}
    this.props.getValue(props.ind, setVal) // {type: "array", count: 4, overrides: {c: 1}}
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    
//     cl(props.current.channelInfo)
//     cl(props.current.channelInfo[this.chan].channelType)
//     cl()
    this.state={
      show: chType!=0
    }
  }
  
  setValues=()=>{
    let setVal={type: "array", count: 4}
    if(this?.props?.custom?.overrides){
      if(this.props.custom.overrides.c){
        this.chan=this.props.custom.overrides.c
      }
      setVal.overrides=this.props.custom.overrides
    }
//     cl(setVal)
    this.props.getValue(this.props.ind, setVal) // {type: "array", count: 4, overrides: {c: 1}}
  }


  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
  }
  
  updateFui=()=>{
//     cl("update fui")
//     cl(this.props)
    this.setValues()// causes a re-render
  }

  getValue=(index,valueDescription)=>{
  }
  
  onChange=(index,value)=>{
//     cl(this.props)
    let vals=this.props.value.slice(0)
    vals[index]=value.value
    vals.overrides={c:1}
    if(this?.props?.custom?.overrides){
      vals.overrides=this?.props?.custom?.overrides
    }
//     cl(vals)
    this.props.onChange(this.props.ind, {value: vals})
    this.changed=true// blocks updating
  }
  
  render(){
//     cl(this.props)
//     cl(this.props.current.channelNames)
//     cl(this.props.custom.overrides.c)
//     if(this.props.ind==5){cl(this.props.value)}
    
    let pid0=this.props.cont.props
    let key=0
    let vals=[0,0,0,0]
    if(this.props.value)vals=this.props.value
//     cl(this.props.value)
    let name=this.props.current.channelInfo[this.chan].channelName
    if(!name){name=`Equipment ${this.chan+1}`}
    
    if(this.state.show){
      return(
        <div>
        <h2>{name}</h2><br/>
        <FUISelect key={key} getValue={this.getValue} onChange={this.onChange} custom={this.humProps} 
        ind={key} value={vals[key]} saveOK={this.props.saveOK}
        cont={{title: "DH 1", width: 60, inline: true, pid: pid0+key++}} />
        <FUISelect key={key} getValue={this.getValue} onChange={this.onChange} custom={this.humProps} 
        ind={key}  value={vals[key]} saveOK={this.props.saveOK}
        cont={{title: "DH 2", width: 60, inline: true, pid: pid0+key++}} />
        <FUISelect key={key} getValue={this.getValue} onChange={this.onChange} custom={this.humProps} 
        ind={key} value={vals[key]} saveOK={this.props.saveOK}
        cont={{title: "DHLT", width: 60, inline: true, pid: pid0+key++}} />
        <FUISelect key={key} getValue={this.getValue} onChange={this.onChange} custom={this.humProps} 
        ind={key} value={vals[key]} saveOK={this.props.saveOK}
        cont={{title: "HUM", width: 60, inline: true, pid: pid0+key++}} />
        <div style={{height: 10}}/>
        </div>
      );
      
    }else{return null}
  }
}

export default HumDeHum ;
