import React from 'react';
import history from '../../history'
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,sortUsersInfo,getUserIndex,getSiteName,getZoneName} from './C18utils'
import {wsTrans} from '../utils/utils'
import {cl,constant,globs} from '../../components/utils/utils';

class C18SidebarGeneralInfo00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      mode:"sites",
      cropInfo:"crop info",
      zoneInfo:"zone info",
      managedBy:"",
    }
    this.loadInfo()
    this.subscribe_newContextEvent=globs.events.subscribe("newContext",this.newContext)
    this.subscribe_refreshEvent=globs.events.subscribe("RefreshGeneralInfoEntries",this.loadInfo)
  }
  
  componentDidMount=()=>{
    this.mounted=true
  }
  componentWillUnmount=()=>{
    this.subscribe_newContextEvent.remove()
    this.subscribe_refreshEvent.remove();
    this.mounted=false
  }
  
  newContext=async(vals)=>{// page has changed
    cl(vals)
    let p={level:vals.level,siteId:vals.siteId,zoneId:vals.zoneId}
    this.loadGeneralInfo(p)
//     this.setState({upd:(this.state.upd||0)+1,siteId:p.siteId,zoneId:p.zoneid})
  }
  
  onChange=(type,vals)=>{
    switch(type){
      case "readAll":
        let p=this.props.parms
        history.push(`/usa/c18/sites/${p.siteId}/zones/${p.zoneId}/generalInfo`)
        cl(type)
        break
      default:
        break
    }
  }
  
  loadInfo=async()=>{
    await loadUsersInfo()
    let p=this.props.parms
//     cl(p)
//     this.state.loaded=true
    this.loadGeneralInfo(p)
//     cl(gi)
//     let cropInfo=gi.cropInfo||"Please enter information about the crops"
//     let zoneInfo=gi.zoneInfo||"Please enter information about this zone"
//     let managedBy=gi.managedBy||globs.usersInfo.info[0].userId
//     this.setState({loaded:true,managedBy:managedBy,cropInfo:cropInfo,zoneInfo:zoneInfo})
  }
  
  loadGeneralInfo=async(p)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/generalInfo", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {
        zoneId:p.zoneId
      }})
//     cl(res.data)
//     let gi=res.data[0]
    let gi=res.data[0]||{cropInfo:"Please enter information about the crops", zoneInfo:"Please enter information about this zone",
      managedBy:globs.usersInfo.info[0].userId}
    this.setState({loaded:true,managedBy:gi.managedBy,cropInfo:gi.cropInfo,zoneInfo:gi.zoneInfo})
  }
  
  
  render(){
    if(this.state.loaded){
      let uInd=getUserIndex(this.state.managedBy)
//       cl(uInd)
//       cl(globs.usersInfo.info[uInd])
      let av=globs.usersInfo.info[uInd]?.avatar//this.users[entry.userId].avatar
      let name=(globs.usersInfo.info[uInd]?.name)||"Unknown User"
      var path
      if(av){
        path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
      }else{
        path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
      }
      return(
        <div>
        <div className="info-section">
          <div className="title">CROP INFO</div>
          <button type="button" className="read-all"
          onClick={e=>{this.onChange("readAll")}}>Read all</button>
          <div className="clearfloat"></div>
          <div className="body-expanded">{this.state.cropInfo}</div>
        </div>

        <br />
        
        <div className="info-section">
          <div className="title">ZONE INFO</div>
          <button type="button" className="read-all"
          onClick={e=>{this.onChange("readAll")}}>Read all</button>
          <div className="clearfloat"></div>
          <div className="body-expanded">{this.state.zoneInfo}</div>
        </div>

        <br />

        <div className="info-section managed-by">
          <div className="title">MANAGED BY</div>
          <div className="floatright">
            <a href=""><img src={path} alt={name}/><span>{name}</span></a>
          </div>
        </div>

        <div className="clearfloat"></div>

        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18SidebarGeneralInfo00;
