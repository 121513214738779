import React from 'react';
import {cl,globs} from '../../components/utils/utils';

class C18MuiInput extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
    }
  }
  
  render(){
//     cl(this.props)
    let pr=this.props
//     cl(pr)
    if(globs.noMui){
      return(
        <>
        <input
          id={pr.id}
          type={pr.type}
          title={pr.title}
          // inputMode={pr.inputmode}
          step={pr.inputProps?.step}
          min={pr.inputProps?.min}
          max={pr.inputProps?.max}
          maxLength={pr.maxLength}
          inputMode={pr.inputProps?.inputMode}
          checked={pr.checked}
          value={pr.value}
          //{pr.unit  === 'minutes' && !pr.saved ? (pr.value/60).toFixed(0) : pr.value}
          onChange={pr.onChange}
          onClick={pr.onClick}
          onBlur={pr.onBlur}
          onFocus={pr.onFocus}
          ref={pr.refx}
          disabled={!pr.saveOK}
          style={{backgroundColor:pr.bgColor}}
        >
          {pr.children}
        </input>
        {pr.unit&&
          <span className="units">{pr.unit}</span>
        }
        {(!pr.noBreak)&&
          <><div className="clearfloat"></div><br/></>
        }
        </>
      )
    }else{
      return React.createElement("input",pr)
    }
  }
}
      
export default C18MuiInput;
 
