import React from 'react';
// import CravoInput from './CravoInput'
import UsaDuration00 from '../../usa/components/UsaDuration00'
import { p, pi, pInd } from '../../components/utils/paramIds';
import { cl, globs, getTime } from '../../components/utils/utils';
import { sendArray, checkSendArray, dbVals } from '../../components/utils/http';
import { showSelect, showInput, showFUISelect } from '../../components/utils/reactUtils';
import C18Duration00 from '../../usa/components/C18Duration00';

const INPUTIDX_INTEMP = 0
const INPUTIDX_OUTTEMP = 1
const INPUTIDX_HUM = 2
const INPUTIDX_CO2 = 3
const INPUTIDX_LITE = 4
const INPUTIDX_WINSPD = 5
const INPUTIDX_WINDIR = 12
const INPUTIDX_BHEAT1 = 6
const INPUTIDX_BHEAT2 = 7
const INPUTIDX_BHEAT3 = 8
const INPUTIDX_BHEAT4 = 9
const INPUTIDX_BHEAT5 = 10
const INPUTIDX_RAIN = 18

class PIDAdvancedSettings extends React.Component {
  constructor(props) {
    super(props);
    let [z, u, c, i] = props.zuci.split("-")
    let ids = this.getDBIds()
    this.z=z
    this.c2 = +c + 40 * u
    //     cl(c2)
//     cl("reading values")
    this.state=this.getCurrentVals(this.z,this.c2,ids)
//     this.state = {
//       supplyPump: this.getParam(z, c2, ids.supplyPump),
//       p: this.getParam(z, c2, ids.P),
//       i: this.getParam(z, c2, ids.I),
//       d: this.getParam(z, c2, ids.D),
//       override_dir: this.getParam(z, c2, ids.override_dir),
//       override_action_target: this.getParam(z, c2, ids.override_action_target),
//       override_trigger: this.getParam(z, c2, ids.override_trigger),
//       sensor: this.getParam(z, c2, ids.override_sensor),
//       override_limit: this.getParam(z, c2, ids.override_limit),
//       //       output_type: this.getParam(z,c2,ids.supplyPump),
//       hold_delay: this.getParam(z, c2, ids.hold_delay),
//       c: c,
//       z: z,
//     }
//     cl(ids)
    //     cl(this.state)
    this.props.getValue(props.ind, { type: "array", count: 5 })// basically fake
    this.props.saves.push(this.onSave)
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)

    //     cl(this.props.value)
    //     this.getDBVars()
  }

  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
//     this.subscribe_savePageEvent.remove()
  }

  getCurrentVals=(z,c2,ids)=>{
//     cl("get current vals")
    return {
      supplyPump: this.getParam(z, c2, ids.supplyPump),
      p: this.getParam(z, c2, ids.P),
      i: this.getParam(z, c2, ids.I),
      d: this.getParam(z, c2, ids.D),
      override_dir: this.getParam(z, c2, ids.override_dir),
      override_action_target: this.getParam(z, c2, ids.override_action_target),
      override_trigger: this.getParam(z, c2, ids.override_trigger),
      sensor: this.getParam(z, c2, ids.override_sensor),
      override_limit: this.getParam(z, c2, ids.override_limit),
      //       output_type: this.getParam(z,c2,ids.supplyPump),
      hold_delay: this.getParam(z, c2, ids.hold_delay),
      c: c2,
      z: z,
    }
  }

  updateFui=(cmd)=>{
//     cl(cmd)
//     cl("update fui")
    if(cmd=="goingToDefaults"){
      let ids = this.getDBIds()
      this.setState(this.getCurrentVals(this.z,this.c2,ids))
    }
  }

  getParam = (z, c, id) => {
    let ret = (((dbVals.z || {})[+z] || {})[+c] || {})[+id]
    return (ret) ? ret : 0
  }

  putParam = (z, c, id, t, parms, val) => {// tab refers to our local (channel, id) tables
    parms.push({
      c: c,// zone wide
      d: val,
      f: 1,
      i: id,
      t: t,
      z: z,
    })
  }



  //   onChange=(type, valId, ind, e)=>{
  //     var vals={}
  //     let arr=this.props.value.slice(0)
  //     switch(type){
  //       case "inp":
  //         arr[ind]=e.currentTarget.value
  //         break
  //       case "check":
  //         arr[ind]=(e.currentTarget.checked)?1:0
  //         break
  //     }
  //     this.setState({arr: arr})
  //     this.props.onChange(this.props.ind, {value: arr})
  //   }

  getDBIds = () => {
    let b = pInd[1800].config_channels_configuration[0]
    let ids = {}
    ids.supplyPump = b + pi[1800].config_channels_configuration.CD_supply_pump_generic
    ids.P = b + pi[1800].config_channels_configuration.P
    ids.I = b + pi[1800].config_channels_configuration.I
    ids.D = b + pi[1800].config_channels_configuration.D
    ids.override_sensor = b + pi[1800].config_channels_configuration.override_sensor
    ids.override_dir = b + pi[1800].config_channels_configuration.override_dir
    ids.override_trigger = b + pi[1800].config_channels_configuration.override_trigger
    ids.override_action_target = b + pi[1800].config_channels_configuration.override_action_target
    ids.override_limit = b + pi[1800].config_channels_configuration.override_limit
    ids.hold_delay = b + pi[1800].config_channels_configuration.hold_delay
    //     cl(ids)
    return ids
  }

  //   getDBVars=()=>{
  //     let ids=this.getDBIds()
  //     cl(this.state)
  //     cl(ids)
  //   }

  onSave = () => {
    //     cl(this.state)
//     let [z, u, c, i] = this.props.zuci.split("-")
    let t = Math.floor(getTime())
    let ids = this.getDBIds()
    let parms = []
    let z=this.z
    let c2=this.c2// = +c + 40 * u
    //     cl(c2)
    this.putParam(z, c2, ids.supplyPump, t, parms, this.state.supplyPump)
    this.putParam(z, c2, ids.P, t, parms, this.state.p)
    this.putParam(z, c2, ids.I, t, parms, this.state.i)
    this.putParam(z, c2, ids.D, t, parms, this.state.d)
    this.putParam(z, c2, ids.override_sensor, t, parms, this.state.sensor)
    this.putParam(z, c2, ids.override_dir, t, parms, this.state.override_dir)
    this.putParam(z, c2, ids.override_trigger, t, parms, this.state.override_trigger)
    this.putParam(z, c2, ids.override_action_target, t, parms, this.state.override_action_target)
    this.putParam(z, c2, ids.override_limit, t, parms, this.state.override_limit)
    this.putParam(z, c2, ids.hold_delay, t, parms, this.state.hold_delay)
//         cl(this.state)
//     cl(parms)
    checkSendArray(parms, this.props.current.virtual).then(r => { globs.events.publish("saveOK", true) })

    //     cl("saving")
  }

  showInput = (val, valId, ind, onChange) => {
    return (
      <input
        value={val}
        style={{ width: 50, }}
        type="number"
        onChange={e => onChange("inp", valId, ind, e)}
      />
    )
  }

  onChange = (type, valId, ind, e) => {//type, valId,ind,e
//     cl(type,valId,ind,e)
    globs.events.publish("savePageEnable", true)
    this.changed=true
    var vals
    switch (type) {
      case "hold_delay":
        vals = {hold_delay:valId.duration}
        break
      default:
//         cl([type, valId, ind, e])
        vals = {}
        vals[valId] = e.currentTarget.value
        break
    }
    //     this.props.onChange(vals)
//     cl(vals)
    this.setState(vals)
    //     cl([a,b,c,d])

  }

  showTab1 = () => {
    let spOpts = { 0: { t: "None" } }
    for (let i = 0; i < 12; i++) { spOpts[i + 1] = { t: `Pump ${1 + i}` } }
    return (
      <table><tbody>
        <tr><td>
          {showSelect("supply", this.state.supplyPump, "supplyPump", -1, spOpts, this.onChange)}
        </td></tr>
        <tr><td>The supply pump will activate<br />
          when mixvalve opens past the<br />
          threshold set on the selected<br />
          pump's programming screen.
        </td></tr>
      </tbody></table>
    )
  }

  showTab2 = () => {
    return (
      <table><tbody>
        <tr><td>Setpoint</td></tr>
        <tr><td>
          <table><tbody>
            <tr><td>P</td><td>{showInput("fields", this.state.p, "p", -1, this.onChange)}</td></tr>
            <tr><td>I</td><td>{showInput("fields", this.state.i, "i", -1, this.onChange)}</td></tr>
            <tr><td>D</td><td>{showInput("fields", this.state.d, "d", -1, this.onChange)}</td></tr>
          </tbody></table>
        </td></tr>
      </tbody></table>
    )
  }

  showTab3 = () => {
    let so = {}
    so[-1] = { t: "None" }
    so[INPUTIDX_INTEMP] = { t: "Inside Temperature" }
    so[INPUTIDX_OUTTEMP] = { t: "Outside Temperature" }
    so[INPUTIDX_HUM] = { t: "Relative Humidity" }
    so[INPUTIDX_CO2] = { t: "CO2" }
    so[INPUTIDX_LITE] = { t: "Outside Light" }
    so[INPUTIDX_WINSPD] = { t: "Wind Speed" }
    so[INPUTIDX_WINDIR] = { t: "Wind Direction" }
    so[INPUTIDX_BHEAT1] = { t: "Analog Temperature 1" }
    so[INPUTIDX_BHEAT2] = { t: "Analog Temperature 2" }
    so[INPUTIDX_BHEAT3] = { t: "Analog Temperature 3" }
    so[INPUTIDX_BHEAT4] = { t: "Analog Temperature 4" }
    so[INPUTIDX_BHEAT5] = { t: "Analog Temperature 5" }
    so[INPUTIDX_RAIN] = { t: "Rain" }
    let od = {
      0: { t: "Greater Than" },
      1: { t: "Less Than" },
    }
    let oat = {
      0: { t: "at least" },
      1: { t: "at most" },
    }
    //     cl(this.state)
    return (
      <table><tbody>
        <tr><td>if the sensor
          {showSelect("fields", this.state.sensor, "sensor", -1, so, this.onChange)}
          is</td></tr>
        <tr><td>
          {showSelect("fields", this.state.override_dir, "override_dir", -1, od, this.onChange)}
          {showInput("fields", this.state.override_trigger, "override_trigger", -1, this.onChange)}
        </td></tr>
        <tr><td>then limit
          {showSelect("fields", this.state.override_action_target, "override_action_target", -1, oat, this.onChange)}
          to
          {showInput("fields", this.state.override_limit, "override_limit", -1, this.onChange)}
        </td></tr>
      </tbody></table>
    )
  }

  showTab4 = () => {
    return (
      <table><tbody>
        <tr><td>
          <UsaDuration00
            value={this.state.hold_delay}
            valueId="hold_delay"
            onChange={v => this.onChange("hold_delay", v)}
          />

        </td></tr>
      </tbody></table>
    )
  }

  showAdvanced = () => {
    let spOpts = { 0: { t: "None" } }
    for (let i = 0; i < 12; i++) { spOpts[i + 1] = { t: `Pump ${1 + i}` } }
    let so = {}
    so[-1] = { t: "None" }
    so[INPUTIDX_INTEMP] = { t: "Inside Temperature" }
    so[INPUTIDX_OUTTEMP] = { t: "Outside Temperature" }
    so[INPUTIDX_HUM] = { t: "Relative Humidity" }
    so[INPUTIDX_CO2] = { t: "CO2" }
    so[INPUTIDX_LITE] = { t: "Outside Light" }
    so[INPUTIDX_WINSPD] = { t: "Wind Speed" }
    so[INPUTIDX_WINDIR] = { t: "Wind Direction" }
    so[INPUTIDX_BHEAT1] = { t: "Analog Temperature 1" }
    so[INPUTIDX_BHEAT2] = { t: "Analog Temperature 2" }
    so[INPUTIDX_BHEAT3] = { t: "Analog Temperature 3" }
    so[INPUTIDX_BHEAT4] = { t: "Analog Temperature 4" }
    so[INPUTIDX_BHEAT5] = { t: "Analog Temperature 5" }
    so[INPUTIDX_RAIN] = { t: "Rain" }
    let od = {
      0: { t: "Greater Than" },
      1: { t: "Less Than" },
    }
    let oat = {
      0: { t: "at least" },
      1: { t: "at most" },
    }
    return (

      <div>
        <br></br>
        <div style={{ display: "flex", width: "100%" }}>

          <div style={{ flex: 1 }}>
            SUPPLY PUMP
          </div>

          <div style={{ flex: 1, marginLeft: "10px" }}>
            PID
          </div>

          <div style={{ flex: 1, marginLeft: "-50px" }}>
            OVERRIDE
          </div>

          <div style={{ flex: 1, marginLeft: "10px" }}>
            HOLD POSITION DELAY
          </div>

        </div>

        <br></br>
        <div style={{ display: "flex", width: "100%" }}>

          <div style={{ flex: 1 }}>
            {showFUISelect("supply", this.state.supplyPump, "supplyPump", -1, spOpts, this.onChange, this.getValue)}
            The supply pump will activate
            when mixvalve opens past the
            threshold set on the selected
            pump's programming screen.
          </div>

          <div style={{ flex: 1, marginLeft: "10px" }}>
            Setpoint
            <br></br>
            <br></br>
            <div style={{ display: "flex", width: "60%" }}>

              <div style={{ flex: 0 }}>
                P
              </div>

              <div style={{ flex: 1, marginLeft: "10px" }}>
                {showInput("fields", this.state.p, "p", -1, this.onChange, "100%")}
              </div>

            </div>
            <br></br>
            <div style={{ display: "flex", width: "60%" }}>

              <div style={{ flex: 0 }}>
                I
              </div>

              <div style={{ flex: 1, marginLeft: "15px" }}>
                {showInput("fields", this.state.i, "i", -1, this.onChange, "100%")}
              </div>

            </div>
            <br></br>
            <div style={{ display: "flex", width: "60%" }}>

              <div style={{ flex: 0 }}>
                D
              </div>

              <div style={{ flex: 1, marginLeft: "10px" }}>
                {showInput("fields", this.state.d, "d", -1, this.onChange, "100%")}
              </div>

            </div>

          </div>

          <div style={{ flex: 1, marginLeft: "-50px" }}>
            <br></br>
            <div style={{ display: "flex", width: "100%" }}>

              <div style={{ flex: 1 }}>
                If the sensor
              </div>

              <div style={{ flex: 1, marginLeft: "5px" }}>
                {showFUISelect("fields", this.state.sensor, "sensor", -1, so, this.onChange, this.getValue)}
              </div>

              <div style={{ flex: 1, marginLeft: "5px" }}>
                is
              </div>

            </div>

            <br></br>
            <div style={{ display: "flex", width: "100%" }}>

              <div style={{ flex: 1 }}>
                {showFUISelect("fields", this.state.override_dir, "override_dir", -1, od, this.onChange, this.getValue)}
              </div>

              <div style={{ flex: 1, marginLeft: "20px" }}>
                {showInput("fields", this.state.override_trigger, "override_trigger", -1, this.onChange, "100px")}
              </div>

            </div>

            <br></br>
            <div style={{ display: "flex", width: "100%" }}>

              <div style={{ flex: 1 }}>
                then limit
              </div>

              <div style={{ flex: 1, marginLeft: "5px" }}>
                {showFUISelect("fields", this.state.override_action_target, "override_action_target", -1, oat, this.onChange, this.getValue)}
              </div>

              <div style={{ flex: 1, marginLeft: "5px" }}>
                to
              </div>

              <div style={{ flex: 1, marginLeft: "5px" }}>
                {showInput("fields", this.state.override_limit, "override_limit", -1, this.onChange, "100px")}
              </div>

            </div>

          </div>


          <div style={{ flex: 1, marginLeft: "10px" }}>
            <br></br>
            <div style={{ display: "flex", width: "100%" }}>
              <C18Duration00 parms={{
               width:"60%",
               duration:this.state.hold_delay,
               onChange:e=>this.onChange("hold_delay", e),
               saveOK:true,
              }}/>
            </div>
          </div>

        </div>

      </div>

    )
  }

  getValue=(index,valueDescription)=>{
  }

  render() {
    return (
      <div>
        {this.showAdvanced()}
      </div>
    )
  }

}

export default PIDAdvancedSettings;
