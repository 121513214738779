// import ReactDOM from 'react-dom'
import React, { useRef, useState } from 'react'
// import { Canvas, Camera, useFrame } from '@react-three/fiber'
import {cl,globs,constant,getTime,dateToDisplayDate} from '../../components/utils/utils';

class C18ServerLog00 extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={
      loaded:false,



    }
    this.loadInfo()
  }

  loadInfo=async()=>{

  }

  render=()=>{
    return(
        <div>serverlog2</div>
    )
  }
}

export default C18ServerLog00;
