import React from 'react';
import C18TabsHeader00 from './C18TabsHeader00'
import {wsTrans, doGetPostBasic} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,sortUsersInfo,getSiteName,getZoneName} from './C18utils'
import {cl,globs,constant,getTime} from '../../components/utils/utils';

class C18Messaging00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      mode:"text",
    }
//     this.tabs=["Text","EMail"]
    this.tabs=[
      {v:"text",t:"Text"},
      {v:"email",t:"Email"},
    ]
    
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveGeneralInfo)
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    if(this.props.parms){
      let siteName=getSiteName(this.props.parms.site)
      let zoneName=getZoneName(this.props.parms.zone)
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Messaging", url:`/usa/c18/messaging`},
            ]},
        },
      )
    }
  }
  
  loadInfo=()=>{
    this.state.loaded=true
  }
  
  onChange=(type,vals)=>{
    switch(type){
      case "tabs":
        this.setState({mode:vals.tab})
        break
      case "upd":
//         cl(type, vals)
        globs.events.publish("savePageEnable",true)
        this.setState(vals)
        break
      default:
        break
    }
  }
  
  showMessaging=()=>{
    return(
      <div>
      <div style={{
        width: "calc(20% - 64px)", 
        height:"70vh - 200px", 
        margin:"0px 32px",
        backgroundColor:"#CCCCCC", 
        float:"left"}}>
      </div>
      <div style={{
        width: "calc(80% - 64px)", 
        height:"70vh", 
        height:500, 
        margin:"0px 32px",
        backgroundColor:"#CCFFCC", 
        float:"left"}}>
      
      </div>
      </div>
    )
    
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div>
          <h1>{`Messaging`}</h1>
          <div id="content-area">
            <section className="link4">

        <div>
          <div className="tabs">
            <C18TabsHeader00
              tabId={this.state.mode}
              tabs={this.tabs}
              onChange={o=>this.onChange("tabs",o)}
            />
            <div className="tab-panels">
              <div className="tab-panel selected" role="tabpanel">
                {this.showMessaging()}
                <div className="clearfloat"></div>
              </div> 
            </div>
          </div>
        </div>
            
            </section>
          </div>
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
  
}
      
export default C18Messaging00;
