import React from 'react';
import C18Button00 from './C18Button00'
import {checkConnected} from './C18utils'
import {cl,globs,getTime} from '../../components/utils/utils';

class C18SaveHeader00 extends React.Component{
  constructor(props) {
    super(props);
    let pr=props
//     cl(props)
//     cl(globs)
//     cl(globs.contacts)
    this.state={
      saveEnable: false,
      saving: false,
      failing: false,// on save error
      connected: true,//this.checkConnected(),
    }
    this.subscribe_savePageEnable=globs.events.subscribe("savePageEnable",this.enDisSave)
    this.subscribe_saveOK=globs.events.subscribe("saveOK",this.saveOK)
    this.subscribe_keyUp=globs.events.subscribe("keyUp",this.keyUp)
    this.connectInt=setInterval(e=>{
//       cl("check")
      this.setState({connected:checkConnected(pr.site,pr.zInd)})
    },10000)
  }

//   checkConnected=(site,zInd)=>{
//     let pr=this.props
//     if(pr.zInd==undefined){return true}
//     if(globs.contacts){
//       let now=getTime()
//       let contact=globs.contacts[pr.site][pr.zInd]
//       let connected=(now-contact)<120
//       this.setState({connected:connected})
// //     cl(contact)
//     }
//   }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEnable.remove()
    this.subscribe_saveOK.remove()
    this.subscribe_keyUp.remove()
    clearInterval(this.connectInt)
  }
  
  keyUp=(e)=>{
//     cl(key)
    let key=e.key
    if((this.state.saveEnable)&&(key=="Enter")){this.onClick("save")}
  }
  
  enDisSave=(val)=>{
//     cl("msg")
//     cl(val)
    this.setState({saveEnable:val})
    this.props.onChange({saveEnable:val})
  }
  
  saveOK=()=>{
//     cl("save ok")
//     cl("C18SaveHeader, doing saveOK")
    clearTimeout(this.slideTimer)
    this.props.onChange({saveEnable:false})
    this.setState({saving: false, failing: false, saveEnable: false})
    globs.events.publish("PageSavedSuccessfully")
//     cl("C18SaveHeader saveOK Done")
  }
  
  clearSlideIn=()=>{
    cl("clearSlideIn")
    this.setState({saving: false, failing: true})
  }
  
  onClick=(type)=>{
//     cl(type)
    switch(type){
      case "save":
        this.setState({saving:true,saveEnable:false})
        this.props.onChange({saveEnable:false})
        this.slideTimer=setTimeout(this.clearSlideIn,5000)
        globs.events.publish("savePageEvent",type)
        break
      case "reload":
//         cl("reload")
        globs.events.publish("reloadPage")
        break
      case "copyCSV":
        globs.events.publish("copyCSV")
        break
      default:
        cl("***********************reload")
//         window.location.reload()
        break
    }
  }
  
  render(){
    var showButtons=()=>{
//       cl(st.connected)
      let reloadCode=this.props?.parms?.reloadCode||"cancel"
//       cl(this.props)
//       cl(reloadCode)
      if(st.connected||(globs.userData?.session?.groupId)){
        return(
          <>
            <C18Button00 type="button" className="small" onClick={o=>this.onClick("copyCSV")}>
              CSV
            </C18Button00>
            <C18Button00 type="button" className="small" onClick={o=>this.onClick(reloadCode)}>
              Reload
            </C18Button00>
            <C18Button00 type="button" className="small filled" disabled={disable} onClick={o=>this.onClick("save")}>
              Save
            </C18Button00>
          </>
        )
      }else{
        return(
          <div style={{fontSize:24,color:"#FF0000"}}>Disconnected</div>
        )
      }
    }
//     cl(this.state)
    let st=this.state
    let disable=!st.saveEnable
    return(
      <div id="save-header">
        <div className="save-cancel-box float-right-box">
        {showButtons()}
          <div className="clearfloat"></div>
          <div id="save-notification-slideout" className={`${(this.state.saving)?"open":""}`}>
            &nbsp;&nbsp;&nbsp;Saving. . .
          </div>
          <div id="error-notification-slideout" className={`${(this.state.failing)?"open":""}`}>
            &nbsp;&nbsp;&nbsp;Error - Not Saved!
          </div>
        </div>
        <div className="clearfloat"></div>
      </div>
    )
  }
}
      
export default C18SaveHeader00;
