import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {wsTrans} from '../utils/utils';
import {loadUsersInfo,sortUsersInfo,getUserIndex,loadZonesInfo,loadPrivsInfo,getZoneInfo, loadSitesInfo,addToAdminLog,loadAccountInfo,
  saveToAdminLog,privs} from './C18utils'
import {cl, globs, constant, getTime,getRandomString} from '../../components/utils/utils';
import C18Confirm00 from './C18Confirm00'


class C18ManageUsers3 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded: true,
      showSuper:privs("super","",constant.AREA_PRIVS_READ),
      sortMode:1,
      resendButton:[],
      popup:{
        opacity:0,
        text:"Invitation email has been sent to your email.",
        buttons:["Close"],
        resolve:null,
      }
    }
    this.loadInfo()
    this.setBreadCrumbs()
    
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveUsers)
//     this.loadInfo()
//     this.setBreadCrumbs()
}

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Users", url:`/usa/c18/admin/manageUsers3`},
            ]},
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
//     cl("unmount")
  }
  
  getZoneName=(zoneId)=>{
    return 
  }

  getUserPrivs=(userId)=>{
    let privs=[]
    globs.privsInfo.info.forEach(p=>{
      if(p.userId==userId){
        
      }
    })
  }
  
  makeIndexes=()=>{
    this.zi={}
    globs.zonesInfo.info.forEach(z=>{
      this.zi[z.zoneId]=z
    })
    this.si={}
    globs.sitesInfo.info.forEach(s=>{
      this.si[s.siteId]=s
    })
//     cl(this.zi)
//     cl(this.si)
  }
  
  getPrivName=(priv)=>{
    switch(priv.level){
      case "account":
        break
      case "site":
//         cl(priv.siteId)
//         cl((globs.sitesInfo.info[this.si[priv.siteId]]||{}).name)
        return (this.si[priv.siteId]||{}).name
        break
      case "zone":
        return (this.zi[priv.zoneId]||{}).zoneName
        break
    }
  }
  
  getPrivs=(userId)=>{
//     cl(userId)
    let privs=[]
    globs.privsInfo.info.forEach(p=>{
      if(p.userId==userId){
//         cl(p)
        if((p.level=="zone")&&(p.flags&constant.AREA_PRIVS_READ)){
          let pn=this.getPrivName(p)
          if(pn){
            privs.push(pn)
//             cl(pn)
          }
        }
      }
    })
//     cl(privs)
    return privs.join(", ")
  }
  
  getPending=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/manageUsers", sessionId: globs.userData.session.sessionId, 
      method: "retrieve", body: {}})
    return res.data
  }
  
  loadUsers=()=>{
    let users=[]
//     cl(globs.usersInfo.info)
    globs.usersInfo.info.forEach(u=>{
//       cl(u)
      let status=u.status||"none"//||"admin"
//       if(!u.token){// not pending
//         let u2=
        users.push({avatar:u.avatar,userId:u.userId,name:u.name,email:u.email,status:(u.token)?"pending":status,token:u.token,p3:u.p3,
          access:this.getPrivs(u.userId),active:u.active||false})
//       }
    })
//     cl(users)
//     users.forEach(u=>{cl(u.name, u.userId)})
    this.sortUsers(users,this.state.sortMode)
//     this.pending.forEach(p=>{
//       users.push({name:"Pending User",email:p.email,role:"pending",access:"",token:p.token,userId:`pending-${p.email}`,active:true})
//     })
//     cl(this.pending)
    return users
  }
  
  loadInfo=async()=>{
    await loadUsersInfo()// all users for account
//     cl(globs.usersInfo.info)
    await loadAccountInfo()
    cl(globs)
//     cl(globs.usersInfo.info)
    await loadSitesInfo()
    await loadZonesInfo()
    this.makeIndexes()
    await loadPrivsInfo({accountId:globs.userData.session.accountId})
    this.pending=await this.getPending()
//     cl(this.pending)
    let users=this.loadUsers()
//     cl(users)
//     let users=[]
//     users0.forEach(u=>{if(!u.token){users.push(u)}})
//     cl(users)
//     cl(globs.usersInfo.info[0])
    this.setState({loaded:true,users:users,pending:this.pending})
  }
  
  sortUsers=(users,sortMode)=>{
//     users.forEach(u=>{cl(u.active)})
    var dir
//     cl(sortMode)
    var lcComp=(type,a,b)=>{
      switch(type){
        case 1:
          let alc=(a||"").toLowerCase()
          let blc=(b||"").toLowerCase()
          if(alc>blc){return dir}
          if(alc<blc){return 0-dir}
//           if(a.toLowerCase()>b.toLowerCase()){return dir}
//           if(a.toLowerCase()<b.toLowerCase()){return 0-dir}
          return 0
        case 2:
          cl(a,b)
          if(a>b){return dir}
          if(a<b){return 0-dir}
          return 0
      }
    }
    var sortRoutines=[
      (a,b)=>{return lcComp(1,a.name,b.name)},
      (a,b)=>{return lcComp(1,a.email,b.email)},
      (a,b)=>{return lcComp(1,a.status,b.status)},
      (a,b)=>{return lcComp(1,a.access,b.access)},
      (a,b)=>{return lcComp(2,a.active,b.active)},
    ]
    dir=(sortMode>0)?1:-1
    users.sort(sortRoutines[Math.abs(sortMode)-1])
  }
  
  reSort=(col)=>{
    var sortMode
    if(col==Math.abs(this.state.sortMode)){
      sortMode=0-this.state.sortMode
    }else{
      sortMode=col
    }
    let users=this.state.users.slice(0)
    this.sortUsers(users,sortMode)
    this.setState({sortMode:sortMode,users:users})
  }
  
//   saveToAdminLog=(adds, type, data)=>{
//     let addObj={
//       userId:globs.userData.session.userId,
//       time:Math.floor(getTime())
//     }
//     adds.push(
//       Object.assign({},addObj,
//       {
//       action: type,
//       oldVal: "",
//       newVal: data,
//       })
//     )
//   }
  
  deletePending=(email)=>{
    for(let i=0;i<this.pending.length;i++){
      if(this.pending[i].email==email){
        this.pending.splice(i,1)
        return
      }
    }
  }

  sendTestNote=async(userId)=>{
    cl("sending test msg")
    let notes = []
    notes.push({
      date:Math.floor(getTime()),
      subject:"Test Notification",
      body: "This is a test notification", 
      userId:userId,
      from:userId,
      pageType:"",
      type:"test",
      flags:constant.CHAT_FLAG_UNREAD,
      notificationId:getRandomString(16),
    })
    await wsTrans("usa", {cmd: "cRest", uri: "/s/notifications", method:"create",
      sessionId: globs.userData.session.sessionId,body:{notes:notes}
    })
  }

  deleteUser=async(userId)=>{
//     cl(userId)
    let gui=globs.usersInfo.info
    let ind=getUserIndex(userId)
    let email=(gui[ind]||{}).email||"Pending User"
    let res=await this.props.parms.getPopup({text:`Are you sure you want to delete ${email}?`, buttons:["Cancel","Yes"]})
    let isOtto=globs.accountInfo.info.name=="Automated Testing"
//     cl(res)
    cl("checking")
    cl(gui[ind])
    cl(gui[ind].p3)
    if(res=="Yes"){
//       cl("doing")
//       let userId=this.state.userSel
//       return
      let adminAdds = []
      saveToAdminLog(adminAdds, "deleteUser", userId)
      cl(userId)
      
      if(userId.indexOf("pending-")==0){
        cl("delete pending")
        let email=userId.substr(8)
        this.deletePending(email)
        let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/manageUsers", sessionId: globs.userData.session.sessionId, 
          method: "delete", body: {email:email}})
      }else{
        if(gui[ind].p3){
          cl("delete 3p")
          await wsTrans("usa", {cmd: "cRest", uri: "/s/thirdParty", method: "delete",
            sessionId: globs.userData.session.sessionId,
            body: {userId: userId}})
        }else{
          cl("delete regular")
          if(isOtto){
            cl(globs.userData.session.accountId)
            await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "delete",
              sessionId: globs.userData.session.sessionId,
              body: {accountId: globs.userData.session.accountId}})
            await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "delete",
              sessionId: globs.userData.session.sessionId,
              body: {siteId: globs.userData.session.siteId}})
            await wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "delete",
              sessionId: globs.userData.session.sessionId,
              body: {accountId: globs.userData.session.accountId}})
          }
          await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "delete",
            sessionId: globs.userData.session.sessionId,
            body: {userId: userId}})
        }
      }
      globs.usersInfo.info.splice(ind,1)
      cl(globs.usersInfo.info)
      
            
      addToAdminLog(adminAdds)
//       for(let i=0;i<gui.length;i++){
//         if(gui[i].userId==userId){
//           gui.splice(i,1)
//           break
//         }
//       }
      let users=this.loadUsers()
      this.setState({users:users})
    }
  }
  resolvePopup=(e,resolve)=>{
    //     cl(e)
        let popup=this.state.popup
        popup.opacity=0
        this.setState({popup:popup})
        if(resolve){resolve(e)}
  }
  
  resendInvitation=(vals)=>{

    wsTrans("usa", {cmd: "cRest", uri: "/s/manageUsers", sessionId: globs.userData.session.sessionId, 
      method: "update", body: {email:vals.email,token:vals.token}})
    this.setState(prevState => ({
        resendButton: [...prevState.resendButton,vals.email]
    }))
    setTimeout(() => {
      this.setState({resendButton:[]})
    }, 5000);

    return new Promise((r,e)=>{
      let popup={
        opacity:1,
        text:"Invitation email has been sent to your email.",
        buttons:["Close"],
        resolve:e=>this.resolvePopup(e,r),
      }
    this.setState({popup:popup})
    })
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "resendInvitation":
        this.resendInvitation(vals)
        break
      case "sort":
//         cl(type,vals)
        this.reSort(vals.column)
        break
      case "deleteUser":
        this.deleteUser(vals.userId)
        break
      case "sendTestNote":
        this.sendTestNote(vals.userId)
      default:
        break
    }
  }
  
  showUserHead=()=>{
// sort mode: name, email, role, access, status, 
    let sort=this.state.sortMode// 1-5 for the columns
    let icons=[]
    for(let i=0;i<5;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }
//     cl(icons)
//         <th><button type="button" aria-label="sort"
//           onClick={()=>this.onChange("sort",{column:3})}>
//           Role <span className="material-icons-outlined">{icons[2]}</span></button></th>
    return(
      <tr>
        <th colSpan="2"><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{column:1})}
        >Name <span className="material-icons-outlined">{icons[0]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{column:2})}
        >Email <span className="material-icons-outlined">{icons[1]}</span></button></th>
          
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{column:4})}
        >Access <span className="material-icons-outlined">{icons[3]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{column:5})}
        >Status <span className="material-icons-outlined">{icons[4]}</span></button></th>
        <th>Invitation</th>
        {this.state.showSuper && 
         <th>Test Note</th>
        }
        <th>Edit</th><th>Delete</th>
      </tr>
      
    )
    
  }
  
  showUserLine=(u,i)=>{
//     cl(u)
//     cl(u.status)
    let av=u.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
//     cl(path)
    let access=u.access
    if(access.length>32){access=access.substr(0,24)+"..."}
//     let roles={admin:"Admin",pending:"Pending",mortal:"Mortal",superadmin:"Super Admin",superman:"Superman"}
//     let role=roles[u.role]||"None"
//     if(u.role=="admin"){role="Admin"}
    let invitation=""
//     let status="xx"
    let [status,statusClass]=(u.active)?["ACTIVE","active"]:["INACTIVE","inactive"]
//     cl(globs.accountInfo.info)
    let isOwner=false
//     cl(globs.userData.session)
//     cl(u.accountId,globs.userData.session.accountId,u.accountId==globs.userData.session.accountId,)
    if(u.p3){
      status="THIRD PARTY"
      statusClass="thirdParty"
    }
    if(u.userId==globs.accountInfo.info.owner){status="OWNER"; isOwner=true;
      statusClass="owner"}
//     let status=(u.active)?"ACTIVE":"INACTIVE"
//     let statusClass="active"
    if(u.status=="pending"){
      status=""
      invitation=(
        <button className="invitation" type="button" disabled={this.state.resendButton.includes(u.email)?true:false}
          onClick={e=>{this.onChange("resendInvitation",{email:u.email,token:u.token})}}
        >Resend Invitation</button>
      )
      status="PENDING"
      statusClass="pending"
    }
//       <a href="" className="settings-button material-icons-outlined flip" aria-label="edit user">build</a>
    let isMe=(u.userId==globs.userData.session.userId)
    let isOtto=globs.accountInfo.info.name=="Automated Testing"

// <td>{role}</td>    
    return(
      <tr key={i}>
        <td>
          <img src={path} alt={u.name}/>
        </td>
        <td>
          {u.name}
        </td>
      <td>{u.email}</td><td>{access}</td><td><span className={statusClass}>{status}</span></td><td>{invitation}</td>
      {this.state.showSuper&&
        <td>
            <button type="button" className="material-icons send" aria-label="send user"
            onClick={()=>this.onChange("sendTestNote",{userId:u.userId})}>send</button>
        </td>
      }
      <td>
      <C18Anchor00 to={`/usa/c18/admin/editUser/${u.userId}`} className="settings-button material-icons-outlined flip">edit</C18Anchor00>
      </td><td>
        {!((isMe||isOwner)&&(!isOtto))&&
          <button type="button" className="material-icons trash" aria-label="delete user"
          onClick={()=>this.onChange("deleteUser",{userId:u.userId})}>delete_outline</button>
        }
      </td>
      </tr>
      
    )
  }
  
  showUserLines=()=>{
    let users=(this.state.users||[]).map((u,i)=>{
//       cl(u)
      return this.showUserLine(u,i)
    })
    return users
  }
  
  render(){
    if(this.state.loaded){
//       let email=globs.usersInfo.info[getUserIndex(this.state.userSel)]?.email
//           <button className="icon floatright add"
//           onClick={}
//           ><span className="material-icons">add</span>Add New User</button>
//           <C18Anchor00 to={`/usa/c18/admin/editUser`} className="material-icons-outlined add" aria-label="add user">add</C18Anchor00>
      return(
        <>
          <C18Confirm00 parms={this.state.popup}/>
          <div className="section-controls floatright">
            <C18Anchor00 to="/usa/c18/admin/editUser" className="material-icons-outlined add" aria-label="add report">
              add
            </C18Anchor00>
          </div>

          <div className="clearfloat"></div>
          <br />

          <div className="user-table-container">

              <table className="user-table"><tbody>
              {this.showUserHead()}
              {this.showUserLines()}

            </tbody></table>
          </div>  

          {false&&
            <div className="report-paging paging">
              <button type="button" className="back-to-beginning" aria-label="first page">&#171;</button>
              <button type="button" className="back" aria-label="previous page">&#8249;</button>
              <span className="page-range">1-99 of 200</span> 
              <button type="button" className="forward" aria-label="next page">&#8250;</button>
              <button type="button" className="forward-to-end" aria-label="last page">&#187;</button>
            </div>
          }
          <div className="clearfloat"></div>

        </>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ManageUsers3;
