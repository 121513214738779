import React from 'react';
import C18Button00 from './C18Button00'
import C18InputError00 from './C18InputError00'
import config from '../../components/utils/config'
import {wsTrans} from '../utils/utils'
import {loadAccountInfo, addToAdminLog, updateRecurlyAccount,acctFeature} 
  from './C18utils'
import {cl, globs, getTime,allStates,allTimezones} from '../../components/utils/utils';
import history from "../../history"

class C18ManageAccount extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      name:"",
      adminEmail:"zz",
      additionalEmails:"",
      timezone:"",
      country:"",
      thoroughfare:"",
      premise:"",
      locality:"",
      administrativeArea:"",
      postalCode:"",
      primaryPhone:"",
      mobilePhone:"",
      thirdParty:"",
      nameError:"",
      emailError:"",
      currentName:"",
      currentEmail:"",
    }
    this.adminFields=["name","adminEmail","additionalEmails","timezone","country","thoroughfare","premise","locality","administrativeArea",
      "postalCode","primaryPhone","mobilePhone"]
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveAccount)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
/*from cloud 1.5:
+-------------------------+--------------+------+-----+---------+-------+
| user_id                 | int(11)      | NO   | PRI | NULL    |       |
| account_id              | int(11)      | YES  |     | NULL    |       |
| flags                   | int(11)      | YES  |     | NULL    |       |
| picture                 | varchar(255) | YES  |     | NULL    |       |
| country                 | varchar(255) | YES  |     | NULL    |       | country
| administrative_area     | varchar(255) | YES  |     | NULL    |       | state
| sub_administrative_area | varchar(255) | YES  |     | NULL    |       |
| locality                | varchar(255) | YES  |     | NULL    |       | city
| dependent_locality      | varchar(255) | YES  |     | NULL    |       |
| postal_code             | varchar(255) | YES  |     | NULL    |       | zip code
| thoroughfare            | varchar(255) | YES  |     | NULL    |       | street1
| premise                 | varchar(255) | YES  |     | NULL    |       | street2
| sub_premise             | varchar(255) | YES  |     | NULL    |       |
| primary_phone           | varchar(255) | YES  |     | NULL    |       | primary phone
| home_phone              | varchar(255) | YES  |     | NULL    |       |
| work_phone              | varchar(255) | YES  |     | NULL    |       |
| mobile_phone            | varchar(255) | YES  |     | NULL    |       | cell phone
+-------------------------+--------------+------+-----+---------+-------+
comes from this post: https://stackoverflow.com/questions/310540/best-practices-for-storing-postal-addresses-in-a-database-rdbms
*/
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Account", url:`/usa/c18/admin/manageAccount2`},
            ]},
        },
      )
    }
  }

  saveToAdminLog=(vals)=>{
    return Object.keys(vals).map(v=>{
      return{
        userId:globs.userData.session.userId,
        time:Math.floor(getTime()),
        action:v,
        oldVal:globs.accountInfo.info[v],
        newVal:this.state[v],
      }
    })
  }

  validate=async(vals)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/unique", method: "retrieve", 
      body: {companyName: vals.name, email: vals.adminEmail, password: ""}})

    // matches and not equal to this account's id/email
    let errors = {}
    if(!res.uc && (this.state.currentName !== vals.name)) {
      errors.nameError = "\nName is in use."
    }
//     cl("check")
    if(!res.ue && (this.state.currentEmail !== vals.adminEmail)) {
      errors.emailError = "\nEmail is in use."
    }
    if (Object.keys(errors).length) {
      this.mySetState(errors)
      return false
    } else {
      return true
    }
  }
  
//   saveToAdminLog=(adds,o,n)=>{
//     let addObj={
//       userId:globs.userData.session.userId,
//       time:Math.floor(getTime())
//     }
//     if(o.name!=n.name){
//       adds.push(
//         Object.assign({},addObj,
//         {
//         action:"name",
//         oldVal:o.name,
//         newVal:n.name,
//       }))
//     }
//   }
  
  saveAccountName=(name)=>{
    wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "update", 
      sessionId: globs.userData.session.sessionId, body: {
        name: name,
      }})
  }
  
  saveAccount=async(cmd)=>{
    if(cmd=="save"){
      let vals=this.copyToFromAccountInfo("comp")
      if(Object.keys(vals).length){
        // validate email and account name
        let valid = await this.validate({adminEmail: this.state.adminEmail, name: this.state.name})
        if (valid) {
          let adds=this.saveToAdminLog(vals)
          addToAdminLog(adds)
          this.copyToFromAccountInfo("to")
          await wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "update", 
          sessionId: globs.userData.session.sessionId, body: globs.accountInfo.info})
          // update recurly account
          await updateRecurlyAccount()
          // update current email
          this.mySetState({currentName: this.state.name, currentEmail: this.state.adminEmail})
          globs.events.publish("saveOK",true)
        } else {
          globs.events.publish("saveOK",false)
        }
      }
      // globs.events.publish("saveOK",true)
    }
  }
  
  deleteAccount=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Account? This is not something to be done lightly. Common side effects include mild headache (not a migraine), pain or chest tightness, pressure or heavy feeling in any part of your body, weakness, feeling hot or cold, dizziness, spinning sensation, drowsiness, nausea, vomiting, drooling, unusual taste in your mouth after using nasal spray, burning/numbness/pain/irritation in your nose or throat after using nasal spray, or flushing (warmth, redness, or tingling under the skin). Is this really pain that you want to bring on yourself?", buttons:["Cancel","Ruin My Life"]})
    if(res=="Yes"){
      cl("delete account")
    }
    
  }
  
  copyToFromAccountInfo=(dir)=>{
    let vals={}
    this.adminFields.map(f=>{
      switch(dir){
        case "from":
          vals[f]=globs.accountInfo.info[f]||""
          break
        case "to":
          globs.accountInfo.info[f]=this.state[f]
          break
        case "comp":
          if((globs.accountInfo.info[f]||"")!=this.state[f]){
            vals[f]=1// mark as changed
          }
        default:
          break
      }
    })
    return vals
  }
  
  loadInfo=async()=>{
    await loadAccountInfo()
    let vals=this.copyToFromAccountInfo("from")
    if(!vals.timezone){
      let tz=0-(new Date(2021,0,1)).getTimezoneOffset()/60// hours of offset from UTC, on 1-1-2021, standard time
      vals.timezone=allTimezones[(tz+12)%12]
    }
    vals.currentName = vals.name
    vals.currentEmail = vals.adminEmail
    vals.loaded=true
    cl(vals)
    this.mySetState(vals)
  }
  
  mySetState=(vals)=>{
    this.setState(vals)
  }
  
//   getUserAccount=async(userId)=>{
//     let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId, 
//       body: {userId:userId}})
//     cl(res.data[0].accountId)
//     return res.data[0].accountId
//   }
  
  addThirdParty=async()=>{
    cl(this.state)
//     let p3Account=await this.getUserAccount(this.state.thirdParty)
    wsTrans("usa", {cmd: "cRest", uri: "/s/thirdParty", method: "update", 
      sessionId: globs.userData.session.sessionId, 
      body: {userId:this.state.thirdParty}})
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "input":
        globs.events.publish("savePageEnable",true)
        if (vals.name) {
          vals.nameError = ""
        }
        if (vals.adminEmail) {
          vals.emailError = ""
        }
        this.mySetState(vals)
        break
      case "upd":
        globs.events.publish("savePageEnable",true)
        this.mySetState(vals)
        break
      case "thirdPartyId":
        this.mySetState(vals)
        break
      case "deleteAccount":
        this.deleteAccount()
        break
      case "addThirdParty":
        this.addThirdParty()
        break
//       case "name":
//         globs.events.publish("savePageEnable",true)
//         this.mySetState(vals)
//         break
      default:
        break
    }
  }
  
  showBasicSettings=()=>{
//     cl(allTimezones)
//     cl(this.state.adminEmail)
    return(
      <>
        <h2>Basic Settings</h2>

        <label htmlFor="account-name">Account Name<span>*</span></label>
        {/* <input id="account-name" type="text" maxLength="50"
          value={this.state.name}
          onChange={e=>this.onChange("upd",{name:e.currentTarget.value})}
        /> */}

        <C18InputError00 parms={{
            type:'text',
            title:"",
            id:"if-accountName",
            required: true,
            value: this.state.name,
            valueId: "name",
            onChange: this.onChange,
            message:this.state.nameError,
            messageType: "error"
          }}/>
        

        <label htmlFor="admin-email">Administrative Email<span>*</span></label>
        {/* <input id="admin-email" type="email" 
          value={this.state.adminEmail}
          onChange={e=>this.onChange("upd",{adminEmail:e.currentTarget.value})}
        /> */}

        <C18InputError00 parms={{
            type:'email',
            title:"",
            id:"if-adminEmail",
            required: true,
            value: this.state.adminEmail,
            valueId: "adminEmail",
            onChange: this.onChange,
            message:this.state.emailError,
            messageType: "error"
          }}/>

        <label htmlFor="additional-emails">Additional Emails <span>(separate with commas)</span></label><input id="additional-emails" type="text"
          value={this.state.additionalEmails}
          onChange={e=>this.onChange("upd",{additionalEmails:e.currentTarget.value})}
        />

        <label htmlFor="account-zone">Timezone</label>
        <span className="custom-select">
          <select id="account-zone"
            value={this.state.timezone}
            onChange={e=>this.onChange("upd",{timezone:e.currentTarget.value})}
          >
          {allTimezones.map((tz,i)=>{
            return(
              <option key={i} value={tz}>{tz}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>
      </>
    )
  }
  
  showAddress=()=>{
    return(
      <>
        <h2>Address</h2>

        <label htmlFor="account-street1">Street 1</label><input id="account-street1" type="text" maxLength="50"
          value={this.state.thoroughfare}
          onChange={e=>this.onChange("upd",{thoroughfare:e.currentTarget.value})}
        />
        <label htmlFor="account-street2">Street 2</label><input id="account-street2" type="text" maxLength="50"
          value={this.state.premise}
          onChange={e=>this.onChange("upd",{premise:e.currentTarget.value})}
        />

        <label htmlFor="account-city">City</label><input id="account-city" type="text" maxLength="50"
          value={this.state.locality}
          onChange={e=>this.onChange("upd",{locality:e.currentTarget.value})}
        />

        <label htmlFor="account-state">State</label>
        <span className="custom-select">
          <select id="account-state"
            value={this.state.administrativeArea}
            onChange={e=>this.onChange("upd",{administrativeArea:e.currentTarget.value})}
          >
            {allStates.map((st,i)=>{
              return(
                <option key={i} value={st}>{st}</option>
              )
            })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>
        <label htmlFor="account-zip">Zip<span>*</span></label><input id="account-zip" type="text" maxLength="10"
          value={this.state.postalCode}
          onChange={e=>this.onChange("upd",{postalCode:e.currentTarget.value})}
        />
      </>
    )
  }
  
  showTelephone=()=>{
    return(
      <>
        <h2>Telephone</h2>

        <label htmlFor="account-phone">Primary Phone<span>*</span></label><input id="account-phone" type="tel" maxLength="20"
          value={this.state.primaryPhone}
          onChange={e=>this.onChange("upd",{primaryPhone:e.currentTarget.value})}
        />
        <label htmlFor="account-cell">Cell Phone</label><input id="account-cell" type="tel" maxLength="20"
          value={this.state.mobilePhone}
          onChange={e=>this.onChange("upd",{mobilePhone:e.currentTarget.value})}
        />

      </>
    )
  }
  
  showThirdParty=()=>{
    let addDisable=false
    return(
      <>
      <div id="third-party-id" style={{height:150,borderStyle:"solid",borderWidth:1,borderRadius:10, width:500, padding:"0px 0px 0px 20px"}}>
        <h2>Third Party ID</h2>

        <input type="text" maxLength="20"
          style={{display:"inline-block"}}

          value={this.state.thirdParty}
          onChange={e=>this.onChange("thirdPartyId",{thirdParty:e.currentTarget.value})}
        />
        <C18Button00 type="button" className="filled" disabled={addDisable}
        style={{display:"inline-block",marginLeft:20}}
        onClick={()=>this.onChange("addThirdParty",{thirdId:this.state.thirdParty})}>Add</C18Button00>
      </div>
      </>
    )
  }
  
  showManageAccount=()=>{
//         {(["dev","stage","ryan"].includes(config.server))&&this.showThirdParty()}
    return(
      <div>
        <div className="manage-account-wrapper">
          <div className="manage-account">

            {this.showBasicSettings()}
            {this.showAddress()}
            {this.showTelephone()}
          </div>
        </div>
        <br/><br/>

      <br /><br /><br /><br /><br /><br />

      <div className="float-right-box">
        <button id="delete-account" type="button" className="danger"
        onClick={e=>this.onChange("deleteAccount")}
        >Delete Account</button>
      </div>
      <div className="clearfloat"></div>
      </div>
      
    )
  }
  
  render(){
    if(this.state.loaded){
      return this.showManageAccount()
//       return(
//         <div>
//           <br /><hr />
//           <p>Account ID: {globs.userData.session.accountId}</p>
//           <br />
// 
//           <label htmlFor="">Account Name</label>
//           <input type="text" id="" 
//             value={this.state.name}
//             onChange={e=>this.onChange("name",{name:e.currentTarget.value})}
//           />
//         </div>
//       )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ManageAccount;
