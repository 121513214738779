import React from 'react';
import CravoInput from './CravoInput'
import UsaDuration00 from '../../usa/components/UsaDuration00'
import {p, pi, pInd} from '../../components/utils/paramIds';
import {cl,globs,getTime} from '../../components/utils/utils';
import {sendArray, checkSendArray, dbVals,getParam,putParam} from '../../components/utils/http';
import {showSelect, showInput, showFUISelect} from '../../components/utils/reactUtils';

const INPUTIDX_INTEMP=0
const INPUTIDX_OUTTEMP=1
const INPUTIDX_HUM=2
const INPUTIDX_CO2=3
const INPUTIDX_LITE=4
const INPUTIDX_WINSPD=5
const INPUTIDX_WINDIR=12
const INPUTIDX_BHEAT1=6
const INPUTIDX_BHEAT2=7
const INPUTIDX_BHEAT3=8
const INPUTIDX_BHEAT4=9
const INPUTIDX_BHEAT5=10
const INPUTIDX_RAIN=18

class PMAdvancedSettings extends React.Component{
  constructor(props) {
    super(props);
    let [z,u,c,i]=props.zuci.split("-")
    let ids=this.getDBIds()
    this.state={
      supplyPump: getParam(z,c,ids.supplyPump),
      it_p:getParam(z,c,ids.it_p),
      it_i:getParam(z,c,ids.it_i),
      it_d:getParam(z,c,ids.it_d),
      disable_inside_temp_pid:getParam(z,c,ids.disable_inside_temp_pid),
      wt_p:getParam(z,c,ids.wt_p),
      wt_i:getParam(z,c,ids.wt_i),
      wt_d:getParam(z,c,ids.wt_d),
      shock_protect_enabled:getParam(z,c,ids.shock_protect_enabled),
      shock_protect_water_sensor:getParam(z,c,ids.shock_protect_water_sensor),
      shock_protect_threshold:getParam(z,c,ids.shock_protect_threshold),
      shock_protect_open_valve_perc: getParam(z,c,ids.shock_protect_open_valve_perc)
    }
    cl(this.state)
    cl(ids)
    cl(dbVals)
    this.props.getValue(props.ind, {type: "array", count: 11})
    this.props.saves.push(this.onSave)
    cl(this.props.value)
  }

//   onChange=(type, valId, ind, e)=>{
//     var vals={}
//     let arr=this.props.value.slice(0)
//     switch(type){
//       case "inp":
//         arr[ind]=e.currentTarget.value
//         break
//       case "check":
//         arr[ind]=(e.currentTarget.checked)?1:0
//         break
//     }
//     this.setState({arr: arr})
//     this.props.onChange(this.props.ind, {value: arr})
//   }
  
  getDBIds=()=>{
    let b=pInd[1800].config_channels_configuration[0]
    let ids={}
    ids.supplyPump=b+pi[1800].config_channels_configuration.supply_pump
    ids.it_p=b+pi[1800].config_channels_configuration.inside_temp_p
    ids.it_i=b+pi[1800].config_channels_configuration.inside_temp_i
    ids.it_d=b+pi[1800].config_channels_configuration.inside_temp_d
    ids.wt_p=b+pi[1800].config_channels_configuration.water_temp_p
    ids.wt_i=b+pi[1800].config_channels_configuration.water_temp_i
    ids.wt_d=b+pi[1800].config_channels_configuration.water_temp_d
    ids.disable_inside_temp_pid=b+pi[1800].config_channels_configuration.disable_inside_temp_pid
    ids.shock_protect_enabled=b+pi[1800].config_channels_configuration.shock_protect_enabled
    ids.shock_protect_water_sensor=b+pi[1800].config_channels_configuration.shock_protect_water_sensor
    ids.shock_protect_threshold=b+pi[1800].config_channels_configuration.shock_protect_threshold
    ids.shock_protect_open_valve_perc=b+pi[1800].config_channels_configuration.shock_protect_open_valve_perc
    return ids
  }

  onSave=()=>{
    let [z,u,c,i]=this.props.zuci.split("-")
    let t=Math.floor(getTime())
    let ids=this.getDBIds()
    let parms=[]
    putParam(z,c,ids.supplyPump,t,parms,this.state.supplyPump)
    putParam(z,c,ids.it_p,t,parms,this.state.it_p)
    putParam(z,c,ids.it_i,t,parms,this.state.it_i)
    putParam(z,c,ids.it_d,t,parms,this.state.it_d)
    putParam(z,c,ids.disable_inside_temp_pid,t,parms,this.state.disable_inside_temp_pid)
    putParam(z,c,ids.wt_p,t,parms,this.state.wt_p)
    putParam(z,c,ids.wt_i,t,parms,this.state.wt_i)
    putParam(z,c,ids.wt_d,t,parms,this.state.wt_d)
    putParam(z,c,ids.shock_protect_enabled,t,parms,this.state.shock_protect_enabled)
    putParam(z,c,ids.shock_protect_water_sensor,t,parms,this.state.shock_protect_water_sensor)
    putParam(z,c,ids.shock_protect_threshold,t,parms,this.state.shock_protect_threshold)
    putParam(z,c,ids.shock_protect_open_valve_perc,t,parms,this.state.shock_protect_open_valve_perc)
    cl(this.state)
    cl(parms)
    checkSendArray(parms,this.props.current.virtual).then(r=>{globs.events.publish("saveOK",true)})
    
    cl("saving")
  }
  
  showInput=(val, valId, ind, onChange)=>{
    return(
      <input
        value={val}
        style={{width: 50,}}
        type="number"
        onChange={e=>onChange("inp", valId, ind, e)}
      />
    )
  }
  
  onChange=(type,valId,ind,e)=>{//type, valId,ind,e
    var vals
    globs.events.publish("savePageEnable",true)
    switch(type){
      case "check":
        vals={}
        vals[valId]=e.currentTarget.checked
        break
      default:
        cl([type,valId,ind,e])
        vals={}
        vals[valId]=e.currentTarget.value
        break
    }
    this.setState(vals)
//     cl([a,b,c,d])
    
  }
  
  showTab1=()=>{
    let spOpts={0:{t:"None"}}
    for(let i=0;i<12;i++){spOpts[i+1]={t:`Pump ${1+i}`}}
    return(
      <table><tbody>
      <tr><td>
      {showSelect("supply", this.state.supplyPump, "supplyPump", -1, spOpts, this.onChange)}
      </td></tr>
      <tr><td>The supply pump will activate<br/> 
      when mixvalve opens past the<br/> 
      threshold set on the selected<br/> 
      pump's programming screen.
      </td></tr>
      </tbody></table>
    )
  }
  
  showTab2=()=>{
    return(
      <table><tbody>
      <tr><td>Water Temperature</td></tr>
      <tr><td>
        <table><tbody>
        <tr><td>P</td><td>{showInput("fields",this.state.it_p,"it_p",-1,this.onChange)}</td></tr>
        <tr><td>I</td><td>{showInput("fields",this.state.it_i,"it_i",-1,this.onChange)}</td></tr>
        <tr><td>D</td><td>{showInput("fields",this.state.it_d,"it_d",-1,this.onChange)}</td></tr>
        <tr><td>
          <input
            type="checkbox"
            checked={this.state.disable_inside_temp_pid}
            onChange={e=>this.onChange("check", "disable_inside_temp_pid", 0, e)}
          />
        </td><td>Disable Water Temperature PID</td></tr>
        
        </tbody></table>
      </td></tr>
      </tbody></table>
    )
  }
  
  showTab3=()=>{
    return(
      <table><tbody>
      <tr><td>Target Temperature</td></tr>
      <tr><td>
        <table><tbody>
        <tr><td>P</td><td>{showInput("fields",this.state.wt_p,"wt_p",-1,this.onChange)}</td></tr>
        <tr><td>I</td><td>{showInput("fields",this.state.wt_i,"wt_i",-1,this.onChange)}</td></tr>
        <tr><td>D</td><td>{showInput("fields",this.state.wt_d,"wt_d",-1,this.onChange)}</td></tr>
        
        </tbody></table>
      </td></tr>
      </tbody></table>
    )
  }
  
//   showTab3=()=>{
//     let so={}
//     so[-1]={t:"None"}
//     so[INPUTIDX_INTEMP]={t:"Inside Temperature"}
//     so[INPUTIDX_OUTTEMP]={t:"Outside Temperature"}
//     so[INPUTIDX_HUM]={t:"Relative Humidity"}
//     so[INPUTIDX_CO2]={t:"CO2"}
//     so[INPUTIDX_LITE]={t:"Outside Light"}
//     so[INPUTIDX_WINSPD]={t:"Wind Speed"}
//     so[INPUTIDX_WINDIR]={t:"Wind Direction"}
//     so[INPUTIDX_BHEAT1]={t:"Analog Temperature 1"}
//     so[INPUTIDX_BHEAT2]={t:"Analog Temperature 2"}
//     so[INPUTIDX_BHEAT3]={t:"Analog Temperature 3"}
//     so[INPUTIDX_BHEAT4]={t:"Analog Temperature 4"}
//     so[INPUTIDX_BHEAT5]={t:"Analog Temperature 5"}
//     so[INPUTIDX_RAIN]={t:"Rain"}
//     let od={
//       0:{t:"Greater Than"},
//       1:{t:"Less Than"},
//     }
//     let oat={
//       0:{t:"at most"},
//       1:{t:"at least"},
//     }
//     return(
//       <table><tbody>
//       <tr><td>if the sensor 
//         {showSelect("fields", this.state.sensor, "sensor", -1, so, this.onChange)}
//       is</td></tr>
//       <tr><td>
//         {showSelect("fields", this.state.override_dir, "override_dir", -1, od, this.onChange)}
//       (input)</td></tr>
//       <tr><td>then limit 
//         {showSelect("fields", this.state.override_action_target, "override_action_target", -1, oat, this.onChange)}
//       to 
//         {showInput("fields",this.state.override_trigger,"override_trigger",-1,this.onChange)}
//       </td></tr>
//       </tbody></table>
//     )
//   }

    getValue=(index,valueDescription)=>{
    }
  
  showTab4=()=>{
    let ws={
      0:{t:"Analog Temperature 1"},
      1:{t:"Analog Temperature 2"},
      2:{t:"Analog Temperature 3"},
      3:{t:"Analog Temperature 4"},
      4:{t:"Analog Temperature 5"},
    }
    return(
      <table><tbody>
      <tr><td>
        <br></br>
        <div className="checkbox-field">
          <span>
          <input
            type="checkbox"
            checked={this.state.shock_protect_enabled}
            onChange={e=>this.onChange("check", "shock_protect_enabled", 0, e)}
          />
          <label>Shock Protect</label>
          </span>
        </div>
      <br></br>
      </td></tr>
      <tr><td>Return Temperature Sensor</td></tr>
      
      <tr><td>
        {showFUISelect("fields", this.state.shock_protect_water_sensor, "shock_protect_water_sensor", -1, ws, this.onChange, this.getValue)}
 
      <br></br>
      </td></tr>
      <tr><td>
        Shock Minimum Temperature
        <br></br>
        <CravoInput
          label={"\u00B0F"}
          color={"#FFFFFF"}
          value={this.state.shock_protect_threshold}
          valueId={"shock_protect_threshold"}
          ind={-1}
          onChange={(valueId, ind, e)=>{this.onChange("cravo", valueId, ind, e)}}
        />
      <br></br>
      </td></tr>
      <tr><td>
       Shock Maximum Opening
       <br></br>
        <CravoInput
          label={"%"}
          color={"#FFFFFF"}
          value={this.state.shock_protect_open_valve_perc}
          valueId={"shock_protect_open_valve_perc"}
          ind={-1}
          onChange={(valueId, ind, e)=>{this.onChange("cravo", valueId, ind, e)}}
        />
      </td></tr>
      
      
      
      </tbody></table>
    )
  }
  
  showAdvanced=()=>{
    return(
     <table><tbody>
      <tr>
      <td>{this.showTab4()}</td>
      </tr>
     </tbody>
     </table>

     
    )
  }

  showAdvanced2=()=>{
    return(
     <table><tbody>
      <tr>
      <td></td>
      <td></td>
      </tr>
      <tr>
        <td valign="top" style={{width: "39%"}}>{this.showTab2()}</td>
        <td valign="top">{this.showTab3()}</td>
        </tr>
      </tbody></table>
    )
  }
  
  
  render(){
    let section =(this.props.custom.unit)
    return(
      <div>
      {section==="first" &&this.showAdvanced()}
      {section==="second" &&this.showAdvanced2()}
      </div>
    )
  }

}

export default PMAdvancedSettings ;
