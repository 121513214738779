var cl=console.log;

var lc={};
// this file contains all the controller constants, and the test vector
var init = ()=>{
  lc={
    //   LT_config: -100,
    setup_t_CoolStages:	3,
    setup_t_HeatStages:	4,
    setup_t_StageWidth:	5,
    setup_t_TimePeriods:	6,
    setup_t_Zones:	7,
    setup_t_RainHoldTime:	8,
    setup_t_CmdDelayMin:	9,
    setup_t_CmdDelaySec:	10,
    setup_t_CoolDeadband:	11,
    setup_t_HeatDeadband:	12,
    setup_t_HumDeadband:	13,
    setup_t_InputMap:	14,
    setup_t_Accumulator_t_id:	15,
    setup_t_Accumulator_t_sensor:	16,
    setup_t_Accumulator_t_period:	17,
    setup_t_DaylightSavings:	18,
    setup_t_TempUnits:	19,
    setup_t_WindUnits:	20,
    setup_t_LiteUnits:	21,
    setup_t_Latitude:	22,
    setup_t_Longitude:	23,
    setup_t_TimeZone:	24,
    setup_t_fb_enable:	25,
    setup_t_fb_stage:	26,
    setup_t_Password:	27,
    setup_t_fb_enable_hi:	28,
    setup_t_CalInTemp:	29,
    setup_t_CalOutTemp:	30,
    setup_t_CalInHum:	31,
    setup_t_CalCo2:	32,
    setup_t_CalLite:	33,
    setup_t_CalWind:	34,
    setup_t_CalWindDir:	35,
    setup_t_CalBHeat:	36,
    setup_t_CalSoil:	37,
    setup_t_CalFallBack:	38,
    setup_t_CalVOpen:	39,
    setup_t_CalVClose:	40,
    setup_t_CalVPos:	41,
    setup_t_CalDiffP:	42,
    setup_t_CalInLight:	43,
    setup_t_IrrCntl:	44,
    setup_t_IrrDelayMin:	45,
    setup_t_IrrDelaySec:	46,
    setup_t_hist_hh:	47,
    setup_t_hist_mm:	48,
    setup_t_comm_mode:	49,
    setup_t_zoneID:	50,
    setup_t_BHeatDeadband:	51,
    setup_t_EnableAdvanced:	52,
    setup_t_remoteSetp:	53,
    setup_t_eq_cycle_sec:	54,
    setup_t_EnergyLightHyst:	55,
    setup_t_EnergyTempHyst:	56,
    setup_t_CO2_LSB:	57,
    setup_t_chnl_addr:	58,
    setup_t_smart_cool_enable:	59,
    setup_t_smart_cool_number:	60,
    setup_t_sc_t_KU:	61,
    setup_t_sc_t_Gmax:	62,
    setup_t_sc_t_Kd:	63,
    setup_t_sc_t_Gmin:	64,
    setup_t_exbFlg:	65,
    setup_t_htdDT:	66,
    setup_t_htdMaxLight:	67,
    setup_t_htdMaxWind:	68,
    setup_t_htdLightFactor:	69,
    setup_t_htdWindFactor:	70,
    setup_t_htdShow:	71,
    setup_t_bump:	72,
    setup_t_cldDT:	73,
    setup_t_cldMaxLight:	74,
    setup_t_cldLightFactor:	75,
    setup_t_cldActiveSet:	76,
    setup_t_cldPassiveSet:	77,
    setup_t_cldEnableActive:	78,
    setup_t_cldPassiveLockStg:	79,
    setup_t_cldShow:	80,
    setup_t_AutoDetectMode:	81,
    setup_t_htdOffset:	82,
    setup_t_cldOffset:	83,
    setup_t_sns_wait_cnt:	84,
    setup_t_HaltOnVentCurt:	85,
    setup_t_tempSpikeThresh:	86,
    setup_t_tempSpikeMin:	87,
    setup_t_tempSpikeSec:	88,
    setup_t_lightMultipler:	89,
    setup_t_genericMult:	90,
    setup_t_genericCal:	91,
    setup_t_genericUnits:	92,
    setup_t_genericRange:	93,
    setup_t_pidMultiplier:	94,
    setup_t_filter_cfg_t_max_samples:	95,
    setup_t_CalOutHum:	96,
    setup_t_d2avg_enable:	97,
    setup_t_d2avg_target:	98,
    setup_t_d2avg_maxFailDays:	99,
    setup_t_d2avg_successTemp:	100,
    setup_t_d2avg_maxTempDiff:	101,
    setup_t_tmInput:	102,
    setup_t_hidCntl:	103,
    setup_t_hidInitPauseMin:	104,
    setup_t_hidActiveMin:	105,
    setup_t_hidFinalPauseMin:	106,
    setup_t_hidMinOnMin:	107,
    setup_t_InfluenceFactor_id:	108,
    setup_t_InfluenceFactor_enable:	109,
    setup_t_InfluenceFactor_TempSPOffset:	110,
    setup_t_InfluenceFactor_AC:	111,
    setup_t_InfluenceFactor_AC_type:	112,
    setup_t_ZoneDelaySec:	113,
    setup_t_ZoneDelayMin:	114,
    setup_t_SetupFlags:	115,
    setup_t_bOutExpEnable:	116,
    setup_t_bOutExpAutoDetect:	117,
    setup_t_Unused:	118,
    setup_t_nutrientsUnits:	119,
    setup_t_tdsConversionFactor:	120,
    setup_t_peristalticCntl:	121,
    setup_t_peristalticDelayMin:	122,
    setup_t_peristalticDelaySec:	123,
    setup_t_volumeUnits:	124,
    setup_t_inLightMultiplier:	125,
    setup_t_advTrigger:	126,
    setup_t_CalRain:	127,
    setup_t_CalSnow:	128,
    setup_t_CalOutTemp2:	129,
    setup_t_CalPressure:	130,
    setup_t_press_LSB:	131,
    setup_t_initVentClosed:	132,
    setpoint_t_id:	133,
    setpoint_t_enable:	134,
    setpoint_t_startmode:	135,
    setpoint_t_starttime:	136,
    setpoint_t_ramp:	137,
    setpoint_t_cool:	138,
    setpoint_t_heat:	139,
    setpoint_t_dehum:	140,
    setpoint_t_hum:	141,
    chnl_t_chnl:	142,
    chnl_t_eq_type:	143,
    chnl_t_name:	144,
    chnl_t_OnBitMask:	145,
    chnl_t_VentClosing:	146,
    chnl_t_stage:	147,
    chnl_t_vent_t_openmin:	148,
    chnl_t_vent_t_opensec:	149,
    chnl_t_vent_t_facing:	150,
    chnl_t_vent_t_wwmin:	151,
    chnl_t_vent_t_wwmax:	152,
    chnl_t_vent_t_lwmin:	153,
    chnl_t_vent_t_lwmax:	154,
    chnl_t_vent_t_holdtime:	155,
    chnl_t_vent_t_rainopen:	156,
    chnl_t_vent_t_LoTempOutT:	157,
    chnl_t_vent_t_LoTempMaxOpen:	158,
    chnl_t_vent_t_HiTempOutT:	159,
    chnl_t_vent_t_HiTempMinOpen:	160,
    chnl_t_vent_t_vpsSensorID:	161,
    chnl_t_vent_t_vpsMaxErr:	162,
    chnl_t_vent_t_vpsTimeout:	163,
    chnl_t_vent_t_vpsIgnore:	164,
    chnl_t_vent_t_vpsSCMode:	165,
    chnl_t_vent_t_vpsAllowErr:	166,
    chnl_t_vent_t_vpsDelay:	167,
    chnl_t_vent_t_bVPosAutoCal:	168,
    chnl_t_vent_t_ActiveCoolStageOvr:	169,
    chnl_t_vent_t_ventType:	170,
    chnl_t_vent_t_setpointList_wbPcnt:	171,
    chnl_t_vent_t_setpointList_ofPcnt:	172,
    chnl_t_vent_t_setpointList_shPcnt:	173,
    chnl_t_vent_t_setpointList_hiPcnt:	174,
    chnl_t_vent_t_setpointList_hiHum:	175,
    chnl_t_vent_t_setpointList_wbTemp:	176,
    chnl_t_vent_t_setpointList_ofTemp:	177,
    chnl_t_vent_t_setpointList_shTemp:	178,
    chnl_t_ovr_t_enable:	445,
    chnl_t_ovr_t_force:	446,
    chnl_t_ovr_t_start:	447,
    chnl_t_ovr_t_end:	448,
    chnl_t_ovr_t_sched:	449,
    chnl_t_ch_hdh_t_dh1:	450,
    chnl_t_ch_hdh_t_dh2:	451,
    chnl_t_ch_hdh_t_dhlt:	452,
    chnl_t_ch_hdh_t_h:	453,
    chnl_t_P:	454,
    chnl_t_I:	455,
    chnl_t_D:	456,
    chnl_t_curt_t_openmin:	179,
    chnl_t_curt_t_opensec:	180,
    chnl_t_curt_t_ShadeCloseMode:	181,
    chnl_t_curt_t_ShadeOpenMode:	182,
    chnl_t_curt_t_ShadeCloseTime:	183,
    chnl_t_curt_t_ShadeOpenTime:	184,
    chnl_t_curt_t_ShadeMaxClose:	185,
    chnl_t_curt_t_ShadeCloseAbove:	186,
    chnl_t_curt_t_ShadeOutT:	187,
    chnl_t_curt_t_ShadeOpenBelow:	188,
    chnl_t_curt_t_ShadeOpenDelay:	189,
    chnl_t_curt_t_EnergyCloseMode:	190,
    chnl_t_curt_t_EnergyOpenMode:	191,
    chnl_t_curt_t_EnergyCloseTime:	192,
    chnl_t_curt_t_EnergyOpenTime:	193,
    chnl_t_curt_t_EnergyLiteThresh:	194,
    chnl_t_curt_t_EnergyTempThresh:	195,
    chnl_t_curt_t_MaxStagePcnt:	196,
    chnl_t_curt_t_MaxAtStage:	197,
    chnl_t_curt_t_ShockProtect:	198,
    chnl_t_irr_t_mode:	199,
    chnl_t_irr_t_on_hh:	200,
    chnl_t_irr_t_on_mm:	201,
    chnl_t_irr_t_on_ss:	202,
    chnl_t_irr_t_scheduledTime:	203,
    chnl_t_irr_t_scheddays:	204,
    chnl_t_irr_t_startastro:	205,
    chnl_t_irr_t_startWindowTime:	206,
    chnl_t_irr_t_endastro:	207,
    chnl_t_irr_t_endWindowTime:	208,
    chnl_t_irr_t_accl_litemin:	209,
    chnl_t_irr_t_accl_tempmin:	210,
    chnl_t_irr_t_accl_litemax:	211,
    chnl_t_irr_t_accl_tempmax:	212,
    chnl_t_irr_t_maxoffhh:	213,
    chnl_t_irr_t_mohpad:	214,
    chnl_t_irr_t_maxoffmm:	215,
    chnl_t_irr_t_soilInput:	216,
    chnl_t_irr_t_soilThreshold:	217,
    chnl_t_irr_t_vpdacc_thresh:	218,
    chnl_t_irr_t_tankSensor:	219,
    chnl_t_co2_t_startastro:	220,
    chnl_t_co2_t_starttime:	221,
    chnl_t_co2_t_endastro:	222,
    chnl_t_co2_t_endtime:	223,
    chnl_t_co2_t_co2_min:	224,
    chnl_t_co2_t_co2_max:	225,
    chnl_t_co2_t_litemin:	226,
    chnl_t_co2_t_lockstage:	227,
    chnl_t_hid_t_startastro:	228,
    chnl_t_hid_t_starttime:	229,
    chnl_t_hid_t_endastro:	230,
    chnl_t_hid_t_endtime:	231,
    chnl_t_hid_t_lite_on:	232,
    chnl_t_hid_t_lite_off:	233,
    chnl_t_hid_t_offdelay:	234,
    chnl_t_hid_t_use_sensor:	235,
    chnl_t_hid_t_mode:	236,
    chnl_t_hid_t_scheduledTime:	237,
    chnl_t_hid_t_scheduleddays:	238,
    chnl_t_hid_t_DLIsensor:	239,
    chnl_t_hid_t_DLIthreshold:	240,
    chnl_t_hid_t_DLIstartime:	241,
    chnl_t_hid_t_DLIontime:	242,
    chnl_t_hid_t_DLIendtime:	243,
    chnl_t_hid_t_DLIoutput:	244,
    chnl_t_mzone_t_day_temp:	245,
    chnl_t_mzone_t_day_enable:	246,
    chnl_t_mzone_t_day_start:	247,
    chnl_t_mzone_t_day_astro:	248,
    chnl_t_mzone_t_day_lock:	249,
    chnl_t_mzone_t_nite_temp:	250,
    chnl_t_mzone_t_nite_enable:	251,
    chnl_t_mzone_t_nite_start:	252,
    chnl_t_mzone_t_nite_astro:	253,
    chnl_t_mzone_t_nite_lock:	254,
    chnl_t_mzone_t_sensor:	255,
    chnl_t_mzone_t_mode:	256,
    chnl_t_mzone_t_pump:	257,
    chnl_t_mv_pz_t_minT:	258,
    chnl_t_mv_pz_t_maxT:	259,
    chnl_t_mv_pz_t_P:	260,
    chnl_t_mv_pz_t_I:	261,
    chnl_t_mv_pz_t_D:	262,
    chnl_t_mv_pz_t_sensor:	263,
    chnl_t_mv_pz_t_openmin:	264,
    chnl_t_mv_pz_t_opensec:	265,
    chnl_t_mv_pz_t_hh:	266,
    chnl_t_mv_pz_t_mm:	267,
    chnl_t_mv_pz_t_ss:	268,
    chnl_t_mv_pz_t_InTempDisable:	269,
    chnl_t_mv_pz_t_pump:	270,
    chnl_t_mv_pz_t_retenable:	271,
    chnl_t_mv_pz_t_retsensor:	272,
    chnl_t_mv_pz_t_retthresh:	273,
    chnl_t_mv_pz_t_retopen:	274,
    chnl_t_mv_pz_t_rettime:	275,
    chnl_t_mv_pz_t_airsensor:	276,
    chnl_t_mv_pz_t_heat:	277,
    chnl_t_mv_pz_t_cool:	278,
    chnl_t_mv_pz_t_bCoolDevice:	279,
    chnl_t_mv_pz_t_fineTempRange:	280,
    chnl_t_mv_pz_t_aval_1:	281,
    chnl_t_mv_pz_t_aval_2:	282,
    chnl_t_mv_pz_t_bPassiveCool:	283,
    chnl_t_pump_t_type:	284,
    chnl_t_pump_t_Thresh:	285,
    chnl_t_pump_t_id:	286,
    chnl_t_pump_t_ecph_probeType:	287,
    chnl_t_pump_t_ecph_probeID:	288,
    chnl_t_pump_t_ecph_increase:	289,
    chnl_t_pump_t_ecph_setpoint:	290,
    chnl_t_pump_t_ecph_deadband:	291,
    chnl_t_pump_t_ecph_lite:	292,
    chnl_t_pump_t_ecph_lite_drive_to:	293,
    chnl_t_pump_t_ecph_tempsensor:	294,
    chnl_t_pump_t_ecph_temp:	295,
    chnl_t_pump_t_ecph_temp_drive_to:	296,
    chnl_t_pump_t_ecph_ontime:	297,
    chnl_t_pump_t_ecph_offtime:	298,
    chnl_t_pump_t_m_off_delay:	299,
    chnl_t_pump_t_ss_off_delay:	300,
    chnl_t_pump_t_feedingMode:	301,
    chnl_t_pump_t_followChannelIdx:	302,
    chnl_t_pump_t_scalingFactor:	303,
    chnl_t_pump_t_tankSizeLiter:	304,
    chnl_t_pump_t_flowRateMLiterPermInute:	305,
    chnl_t_pump_t_mixingTime:	306,
    chnl_t_pump_t_injectingVolume:	307,
    chnl_t_pump_t_limitInjectedVolumePerHour:	308,
    chnl_t_pump_t_injectingTime:	309,
    chnl_t_pump_t_limitInjectedTimePerHour:	310,
    chnl_t_pump_t_limitTime:	311,
    chnl_t_pump_t_resetInjection:	312,
    chnl_t_pump_t_pumpMeasurementMode:	313,
    chnl_t_pump_t_enableSmart:	314,
    chnl_t_pump_t_smartLevels_minDifferencex10:	315,
    chnl_t_pump_t_smartLevels_ratiox100:	316,
    chnl_t_pump_t_sensorHoldTime:	317,
    chnl_t_pump_t_injectBatchConcentration:	318,
    chnl_t_pump_t_injectBatchTime:	319,
    chnl_t_pump_t_trigStartType:	320,
    chnl_t_pump_t_trigStartIDX:	321,
    chnl_t_pump_t_batchVolume:	322,
    chnl_t_pump_t_activeTrigger:	323,
    chnl_t_pump_t_delayStartSS:	324,
    chnl_t_pump_t_delayStartMM:	325,
    chnl_t_pump_t_delayStartHH:	326,
    chnl_t_fval_t_trigStartType:	327,
    chnl_t_fval_t_trigStartIDX:	328,
    chnl_t_fval_t_trigStopType:	329,
    chnl_t_fval_t_trigStopIDX:	330,
    chnl_t_fval_t_timeHH:	331,
    chnl_t_fval_t_timeMM:	332,
    chnl_t_fval_t_timeSS:	333,
    chnl_t_fval_t_volume:	334,
    chnl_t_fval_t_activeTrigger:	335,
    chnl_t_fval_t_tankeSensor:	336,
    chnl_t_mv_pz_t_minT:	337,
    chnl_t_mv_pz_t_maxT:	338,
    chnl_t_mv_pz_t_P:	339,
    chnl_t_mv_pz_t_I:	340,
    chnl_t_mv_pz_t_D:	341,
    chnl_t_mv_pz_t_sensor:	342,
    chnl_t_mv_pz_t_openmin:	343,
    chnl_t_mv_pz_t_opensec:	344,
    chnl_t_mv_pz_t_hh:	345,
    chnl_t_mv_pz_t_mm:	346,
    chnl_t_mv_pz_t_ss:	347,
    chnl_t_mv_pz_t_InTempDisable:	348,
    chnl_t_mv_pz_t_pump:	349,
    chnl_t_mv_pz_t_retenable:	350,
    chnl_t_mv_pz_t_retsensor:	351,
    chnl_t_mv_pz_t_retthresh:	352,
    chnl_t_mv_pz_t_retopen:	353,
    chnl_t_mv_pz_t_rettime:	354,
    chnl_t_mv_pz_t_airsensor:	355,
    chnl_t_mv_pz_t_heat:	356,
    chnl_t_mv_pz_t_cool:	357,
    chnl_t_mv_pz_t_bCoolDevice:	358,
    chnl_t_mv_pz_t_fineTempRange:	359,
    chnl_t_mv_pz_t_aval_1:	360,
    chnl_t_mv_pz_t_aval_2:	361,
    chnl_t_mv_pz_t_bPassiveCool:	362,
    chnl_t_hdemand_t_DT:	363,
    chnl_t_hdemand_t_maxwtemp:	364,
    chnl_t_hdemand_t_minwtemp:	365,
    chnl_t_hdemand_t_maxlight:	366,
    chnl_t_hdemand_t_maxwind:	367,
    chnl_t_hdemand_t_lightfactor:	368,
    chnl_t_hdemand_t_windfactor:	369,
    chnl_t_cdemand_t_DT:	370,
    chnl_t_cdemand_t_maxwtemp:	371,
    chnl_t_cdemand_t_minwtemp:	372,
    chnl_t_cdemand_t_maxlight:	373,
    chnl_t_cdemand_t_lightfactor:	374,
    chnl_t_onoff_t_pump:	375,
    chnl_t_onoff_t_bCycle:	376,
    chnl_t_onoff_t_cycleOn:	377,
    chnl_t_onoff_t_cycleOff:	378,
    chnl_t_onoff_t_bActiveCoolOverrideEnable:	379,
    chnl_t_onoff_t_activeCoolStageOvr:	380,
    chnl_t_varout_t_vc:	381,
    chnl_t_varout_t_func:	382,
    chnl_t_varout_t_maxf:	383,
    chnl_t_varout_t_minf:	384,
    chnl_t_varout_t_maxi:	385,
    chnl_t_varout_t_mini:	386,
    chnl_t_varout_t_maxout:	387,
    chnl_t_varout_t_minout:	388,
    chnl_t_varout_t_offset:	389,
    chnl_t_varout_t_gain:	390,
    chnl_t_generic_t_type:	391,
    chnl_t_generic_t_min:	392,
    chnl_t_generic_t_sec:	393,
    chnl_t_generic_t_analog00P:	394,
    chnl_t_generic_t_analog99P:	395,
    chnl_t_generic_t_sensor:	396,
    chnl_t_generic_t_setpoint:	397,
    chnl_t_generic_t_orSensor:	398,
    chnl_t_generic_t_orCompOp:	399,
    chnl_t_generic_t_orThreshold:	400,
    chnl_t_generic_t_orValM:	401,
    chnl_t_generic_t_orValPercent:	402,
    chnl_t_generic_t_pump:	403,
    chnl_t_generic_t_hh:	404,
    chnl_t_generic_t_mm:	405,
    chnl_t_generic_t_ss:	406,
    chnl_t_cravoVent_t_wbUpDelay:	407,
    chnl_t_cravoVent_t_wbDoDelay:	408,
    chnl_t_cravoVent_t_ofUpDelay:	409,
    chnl_t_cravoVent_t_ofDoDelay:	410,
    chnl_t_cravoVent_t_shUpDelay:	411,
    chnl_t_cravoVent_t_shDoDelay:	412,
    chnl_t_cravoVent_t_hiUpDelay:	413,
    chnl_t_cravoVent_t_hiDoDelay:	414,
    chnl_t_cravoVent_t_wbDeadband:	415,
    chnl_t_cravoVent_t_ofDeadband:	416,
    chnl_t_cravoVent_t_shDeadband:	417,
    chnl_t_cravoVent_t_hiDeadband:	418,
    chnl_t_cravoVent_t_cpWind:	419,
    chnl_t_cravoVent_t_cpPos:	420,
    chnl_t_cravoVent_t_cpDelay:	421,
    chnl_t_cravoVent_t_cp2Wind:	422,
    chnl_t_cravoVent_t_cp2Pos:	423,
    chnl_t_cravoVent_t_cp2Delay:	424,
    chnl_t_cravoVent_t_cp3Wind:	425,
    chnl_t_cravoVent_t_cp3Pos:	426,
    chnl_t_cravoVent_t_cp3Delay:	427,
    chnl_t_cravoVent_t_spWind:	428,
    chnl_t_cravoVent_t_spPos:	429,
    chnl_t_cravoVent_t_spDelay:	430,
    chnl_t_cravoVent_t_hailThresh:	431,
    chnl_t_cravoVent_t_hailPos:	432,
    chnl_t_cravoVent_t_hailDelay:	433,
    chnl_t_cravoVent_t_setpointList_wbPcnt:	434,
    chnl_t_cravoVent_t_setpointList_ofPcnt:	435,
    chnl_t_cravoVent_t_setpointList_shPcnt:	436,
    chnl_t_cravoVent_t_setpointList_hiPcnt:	437,
    chnl_t_cravoVent_t_setpointList_hiHum:	438,
    chnl_t_cravoVent_t_setpointList_wbTemp:	439,
    chnl_t_cravoVent_t_setpointList_ofTemp:	440,
    chnl_t_cravoVent_t_setpointList_shTemp:	441,
    chnl_t_cravoVent_t_fireIn:	442,
    chnl_t_cravoVent_t_firePos:	443,
    chnl_t_cravoVent_t_fireDleay:	444,
    configPartial_t_ovr_t_enable:	457,
    configPartial_t_ovr_t_force:	458,
    configPartial_t_ovr_t_start:	459,
    configPartial_t_ovr_t_end:	460,
    configPartial_t_ovr_t_sched:	461,
    configPartial_t_alarm_t_hitempoffset:	462,
    configPartial_t_alarm_t_hitemptime:	463,
    configPartial_t_alarm_t_lotempoffset:	464,
    configPartial_t_alarm_t_lotemptime:	465,
    configPartial_t_humdehum_t_dh_maxon_min:	466,
    configPartial_t_humdehum_t_dh_minoff_min:	467,
    configPartial_t_humdehum_t_heat_boost:	468,
    configPartial_t_humdehum_t_dhlt_thresh:	469,
    configPartial_t_humdehum_t_dh_maxon_sec:	470,
    configPartial_t_humdehum_t_dh_minoff_sec:	471,
    xboard_t_type:	472,
    xboard_t_addr:	473,
    xboard_t_numOutputs:	474,
    xboard_t_startOutput:	475,
    xboard_t_ModbusPort:	476,
    xboard_t_numInputs:	556,
    xboard_t_startInput:	557,
    xboard_t_uniqueId:	544,
    
    ecphConfig_t_ecphSensor_t_ecMapping:	477,
    ecphConfig_t_ecphSensor_t_pHMapping:	478,
    ecphConfig_t_ecphSensor_t_tempMapping:	479,
    ecphConfig_t_ecphSensor_t_tempCompensationMode:	480,
    ecphConfig_t_ecphSensor_t_tempCompensationValx10:	481,
    ecphConfig_t_ecphSensor_t_ecServiceIntervalDays:	482,
    ecphConfig_t_ecphSensor_t_ecCalibrationIntervalDays:	483,
    ecphConfig_t_ecphSensor_t_ecServiceTime:	484,
    ecphConfig_t_ecphSensor_t_ecCalibrationTime:	485,
    ecphConfig_t_ecphSensor_t_phServiceIntervalDays:	486,
    ecphConfig_t_ecphSensor_t_phCalibrationIntervalDays:	487,
    ecphConfig_t_ecphSensor_t_phServiceTime:	488,
    ecphConfig_t_ecphSensor_t_phCalibrationTime:	489,
    ecphConfig_t_ecphSensor_t_calECRef1Val:	490,
    ecphConfig_t_ecphSensor_t_calECRef1Readingx10:	491,
    ecphConfig_t_ecphSensor_t_calECRef2Val:	492,
    ecphConfig_t_ecphSensor_t_calECRef2Readingx10:	493,
    ecphConfig_t_ecphSensor_t_calPHRef1Valx10:	494,
    ecphConfig_t_ecphSensor_t_calPHRef1Readingx100:	495,
    ecphConfig_t_ecphSensor_t_calPHRef2Valx10:	496,
    ecphConfig_t_ecphSensor_t_calPHRef2Readingx100:	497,
    ecphConfig_t_ecphSensor_t_calTempx10:	498,
    ecphConfig_t_ecType:	499,
    ecphConfig_t_alarmHoldTimeMinutes:	500,
    ecphConfig_t_lowECThreshold:	501,
    ecphConfig_t_highECThreshold:	502,
    ecphConfig_t_lowPHThresholdx10:	503,
    ecphConfig_t_highPHThresholdx10:	504,
    ecphConfig_t_maxECDevThreshold:	505,
    ecphConfig_t_maxPHDevThresholdx10:	506,
    ecphConfig_t_tempECErrorCoefficient:	507,
    settings_t_ethernet_dhcp_on: 508,
    settings_t_ethernet_ip_ddr: 509,
    settings_t_ethernet_ip_net_mask: 510,
    settings_t_ethernet_ip_gateway: 511,
    settings_t_wifi_jap_name: 512,
    settings_t_wifi_jap_pass: 513,
    settings_t_wifi_jap_net_setting_dhcp_on: 514,
    settings_t_wifi_jap_net_setting_ip_ddr: 515,
    settings_t_wifi_jap_net_setting_ip_net_mask: 516,
    settings_t_wifi_jap_net_setting_ip_gateway: 517,
    settings_t_wifi_sap_name: 518,
    settings_t_wifi_sap_pass: 519,
    settings_t_wifi_sap_net_setting_dhcp_on: 520,
    settings_t_wifi_sap_net_setting_ip_ddr: 521,
    settings_t_wifi_sap_net_setting_ip_net_mask: 522,
    settings_t_wifi_sap_net_setting_ip_gateway: 523,
    settings_t_bluetooth_settings_name: 524,
    settings_t_bluetooth_settings_pass: 525,
    settings_t_rs485_BaudRate: 526,
    settings_t_rs485_WordLength: 527,
    settings_t_rs485_parity: 528,
    settings_t_rs485_stop_bits: 529,
    settings_t_display_screensaver_on: 530,
    settings_t_display_screensaver_timeout_s: 531,
    settings_t_time_settings_use_ntp: 532,
    settings_t_time_settings_current_time: 533,
    settings_t_inputConfig_inputMode: 534,
    settings_t_inputConfig_cal_m: 535,
    settings_t_inputConfig_cal_b: 536,
    settings_t_CloudSettings_BrokerURL: 537,
    settings_t_CloudSettings_Username: 538,
    settings_t_CloudSettings_Password: 539,
    settings_t_CloudSettings_SessionID: 540,
    settings_t_CloudSettings_UniqueID: 541,
    settings_t_CloudSettings_Flags: 542,
    settings_t_ethernet_MAC_Address: 543,// added on 20230528
//     xboard_t_uniqueId: 544,
    settings_t_auto_add_MinAddress: 545,// these are not used, yet
    settings_t_auto_add_MaxAddress: 546,

    settings_t_inputConfig_Type: 547,
    settings_t_inputConfig_inputMode: 534,
    settings_t_inputConfig_PullUpEnabled: 548,
    settings_t_inputConfig_cal_m: 535,
    settings_t_inputConfig_cal_b: 536,
    settings_t_inputConfig_FrequencyAvgTime_Seconds: 549,

    settings_t_ZoneSettings_Field_Flags: 550,
    settings_t_ZoneSettings_RS485_Port: 551,
    settings_t_ZoneSettings_RS485_Address: 552,
    settings_t_ZoneSettings_Name: 553,
    settings_t_rs485_Address: 554,
    settings_t_time_settings_TimeZoneShift_mins: 555,
    xboard_t_numInputs: 556,
    xboard_t_startInput: 557,

    sensorCfg_t_channel: 558,
    sensorCfg_t_board: 559,

    
    //   LT_snapshot: -100,	,
    snapshot_t_currentTime:	2000,
//     snapshot_t_sensors_t_InTemp_Raw:	2015,
    snapshot_t_sensors_t_InTemp:	2037,
    snapshot_t_sensors_t_OutTemp:	2038,
    snapshot_t_sensors_t_InHum:	2039,
    snapshot_t_sensors_t_co2:	2040,
    snapshot_t_sensors_t_OutHum:	2041,
    snapshot_t_sensors_t_Light:	2042,
    snapshot_t_sensors_t_WindSpd:	2043,
    snapshot_t_sensors_t_WindDir:	2044,
    snapshot_t_sensors_t_Rain:	2045,
    snapshot_t_sensors_t_FallbackInTemp:	2046,
    snapshot_t_sensors_t_BHeat:	2047,
    snapshot_t_sensors_t_Soil:	2048,
    snapshot_t_sensors_t_VentPos:	2049,
    snapshot_t_sensors_t_ECpH_old:	2050,
    snapshot_t_sensors_t_Generic:	2051,
    snapshot_t_sensors_t_localTemp:	2052,
    snapshot_t_sensors_t_localHum:	2053,
    snapshot_t_sensors_t_diffp:	2054,
    snapshot_t_sensors_t_InLight:	2055,
    snapshot_t_sensors_t_Snow:	2056,
    snapshot_t_sensors_t_OutTemp2:	2057,
    snapshot_t_sensors_t_Pressure:	2058,
    snapshot_t_ecph_sensors_t_phx100:	2062,
    snapshot_t_ecph_sensors_t_ecx10:	2063,
    snapshot_t_ecph_sensors_t_tempx10:	2064,
    snapshot_t_setp_stat_t_cool:	2001,
    snapshot_t_setp_stat_t_heat:	2002,
    snapshot_t_setp_stat_t_hum:	2003,
    snapshot_t_setp_stat_t_dehum:	2004,
    snapshot_t_setp_stat_t_ramp:	2005,
    snapshot_t_stage:	2007,
    snapshot_t_hdhstage:	2008,
    snapshot_t_hdhtimer_t_ontime_remaining:	2009,
    snapshot_t_hdhtimer_t_offtime_remaining:	2010,
    snapshot_t_hdhtimer_t_lsec:	2011,
    snapshot_t_hdhtimer_t_state:	2012,
    snapshot_t_IrrWeek:	2066,
    snapshot_t_alarms:	2000,
    snapshot_t_SiteChange:	2000,
    snapshot_t_htd:	2100,
    snapshot_t_cld:	2101,
    snapshot_t_d2avg_t_avg:	2103,
    snapshot_t_d2avg_t_cnt:	2104,
    snapshot_t_d2avg_t_hist_id:	2105,
    snapshot_t_d2avg_t_full_avg:	2106,
    snapshot_t_d2avg_t_full_cnt:	2107,
    snapshot_t_d2avg_t_night_cool:	2108,
    snapshot_t_d2avg_t_night_heat:	2109,
    snapshot_t_d2avg_t_failDays:	2110,
    snapshot_t_accum:	2111,
    snapshot_t_xcomm_status:	2115,
    snapshot_t_relayCurr:	2000,
    snapshot_t_accvpd:	2000,
    snapshot_t_vpd:	2000,// added 20210706, not sure why!
    snapshot_t_ecphAlarms:	2116,
    snapshot_t_tempAlarms:	2117,
    eqrtd_t_pos:	2118,
    eqrtd_t_eq_type:	2119,
    eqrtd_t_ovr:	2120,
    eqrtd_t_CVState:	2121,
    eqrtd_t_eqrtd_curt_t_ShadeOpenDelay:	2122,
    eqrtd_t_eqrtd_curt_t_ShockOpenDelay:	2123,
    eqrtd_t_eqrtd_vent_t_CurrWindFacing:	2124,
    eqrtd_t_eqrtd_vent_t_CurrWindOvrState:	2125,
    eqrtd_t_eqrtd_vent_t_CurrWidOvrPos:	2126,
    eqrtd_t_eqrtd_vent_t_CurrWindOvrHold:	2127,
    eqrtd_t_eqrtd_vent_t_vpsTimeout:	2128,
    eqrtd_t_eqrtd_vent_t_vpsAlarm:	2129,
    eqrtd_t_eqrtd_vent_t_VPosAutoCal_State:	2130,
    eqrtd_t_eqrtd_vent_t_lastTime:	2131,
    eqrtd_t_eqrtd_vent_t_startTime:	2132,
    eqrtd_t_eqrtd_irr_t_IrrStartTime:	2133,
    eqrtd_t_eqrtd_irr_t_lastIterationTime:	2134,
    eqrtd_t_eqrtd_irr_t_accumOffCycleTime_secs:	2135,
    eqrtd_t_eqrtd_irr_t_irrSched:	2136,
    eqrtd_t_eqrtd_irr_t_inWindow:	2137,
    eqrtd_t_eqrtd_irr_t_AactiveCyclesCount:	2138,
    eqrtd_t_eqrtd_irr_t_inqueue:	2139,
    eqrtd_t_eqrtd_irr_t_accl:	2140,
    eqrtd_t_eqrtd_irr_t_accvpd:	2141,
    eqrtd_t_eqrtd_irr_t_lasttimevpd:	2142,
    eqrtd_t_eqrtd_mixv_t_IrrStartTime:	2143,
    eqrtd_t_eqrtd_mixv_t_lasttime:	2144,
    eqrtd_t_eqrtd_mixv_t_offtime:	2145,
    eqrtd_t_eqrtd_mixv_t_protect_percent:	2146,
    eqrtd_t_eqrtd_mixv_t_WtrTempErrSum:	2147,
    eqrtd_t_eqrtd_mixv_t_AirTempErroSum:	2148,
    eqrtd_t_eqrtd_mixv_t_AdjWtrTempSetpoint:	2149,
    eqrtd_t_eqrtd_hid_t_lasttime:	2150,
    eqrtd_t_eqrtd_hid_t_DLIaccum:	2151,
    eqrtd_t_eqrtd_hid_t_DLIminOnTime:	2152,
    eqrtd_t_eqrtd_varout_t_raw:	2153,
    eqrtd_t_eqrtd_varout_t_cal:	2154,
    eqrtd_t_eqrtd_varout_t_pcnt:	2155,
    eqrtd_t_eqrtd_pump_t_currTime:	2156,
    eqrtd_t_eqrtd_pump_t_pulseTime:	2157,
    eqrtd_t_eqrtd_pump_t_totalInjectedTime:	2158,
    eqrtd_t_eqrtd_pump_t_totalInjectedVolume:	2159,
    eqrtd_t_eqrtd_pump_t_hourlyInjectedTime:	2160,
    eqrtd_t_eqrtd_pump_t_hourlyInjectedVolume:	2161,
    eqrtd_t_eqrtd_pump_t_currSetpoint:	2162,
    eqrtd_t_eqrtd_pump_t_currHoldTime:	2163,
    eqrtd_t_eqrtd_pump_t_applyDeadband:	2164,
    eqrtd_t_eqrtd_pump_t_activeTrigger:	2165,
    eqrtd_t_eqrtd_pump_t_batchVolume:	2166,
    eqrtd_t_eqrtd_pump_t_peristalticState:	2167,
    eqrtd_t_eqrtd_pump_t_pulse:	2168,
    eqrtd_t_eqrtd_fval_t_fillTime:	2169,
    eqrtd_t_eqrtd_fval_t_activeTrigger:	2170,
    eqrtd_t_eqrtd_fval_t_triggerState:	2171,
    eqrtd_t_eqrtd_mixva_t_IrrStartTime:	2172,
    eqrtd_t_eqrtd_mixva_t_lasttime:	2173,
    eqrtd_t_eqrtd_mixva_t_offtime:	2174,
    eqrtd_t_eqrtd_mixva_t_protect_percent:	2175,
    eqrtd_t_eqrtd_mixva_t_curr_va:	2176,
    eqrtd_t_eqrtd_mixva_t_last_pos:	2177,
    eqrtd_t_eqrtd_gen_pid_t_StartTime:	2178,
    eqrtd_t_eqrtd_gen_pid_t_DelayTime:	2179,
    eqrtd_t_eqrtd_gen_pid_t_lasttime:	2180,
    eqrtd_t_eqrtd_gen_pid_t_curr_va:	2181,
    eqrtd_t_eqrtd_gen_pid_t_period:	2182,
    eqrtd_t_eqrtd_gen_pid_t_pulseOn:	2183,
    eqrtd_t_eqrtd_onoff_t_ontime_remaining:	2184,
    eqrtd_t_eqrtd_onoff_t_offtime_remaining:	2185,
    eqrtd_t_eqrtd_onoff_t_lsec:	2186,
    eqrtd_t_eqrtd_onoff_t_state:	2187,
    cloudStatus_t_ProcessorID: 2188,
    cloudStatus_t_ConrollerID: 2189,
    cloudStatus_t_CurrentTime: 2190,
    
    eqrtd_t_PositionReason:	2191,

    snapshot_t_master_pump_t_commandEnable: 2192,
    snapshot_t_master_pump_t_command: 2193,
    snapshot_t_master_pump_t_currentTime: 2194,
    snapshot_t_changedStatusConfigFlags: 2195,
    snapshot_t_Voltage: 2197,
    snapshot_t_TemperatureExt: 2198,
    xboard_status_swpos: 2199,
    xboard_status_relayState: 2200,
    xboard_status_scan_or_add: 2201,
    xboard_status_dli_ana_lastTime: 2202,
    xboard_status_dli_ana_accum: 2203,
    xboard_status_dli_ana_minOnTime: 2204,
    xboard_status_current_op_prog: 2205,
    xboard_status_last_wd_resset_task: 2206,
    xboard_status_xboard_digital_inputs: 2207,
    xboard_status_bootloader_version: 2208,

    //   LT_auxctl: -100,	,
    auxcntl_t_operand1:	4001,
    auxcntl_t_operand2:	4002,
    auxcntl_t_operand3:	4003,
    auxcntl_t_target:	4004,
    auxcntl_t_functionParameter:	4005,
    auxcntl_t_operandTypes:	4006,
    auxcntl_t_conditionTime:	4007,
    auxcntl_t_operators:	4008,
    auxcntl_t_function:	4009,
    auxcntl_t_holdTime:	4010,
    
    auxsnapshot_t_Vars:	6001,
    auxsnapshot_t_PersistentVars:	6002,
    auxsnapshot_t_Alarms:	6003,
    auxcntl_rtd_t_timestamp:	6004,
    auxcntl_rtd_t_activeTimestamp:	6005,
    auxcntl_rtd_t_lastTrueCondition:	6006,
    auxrtd_t_setpointSet:	6007,
    auxrtd_t_outputSetPosition:	6008,
    auxrtd_t_equipmentPosition:	6009,
    auxrtd_t_alarmSetPosition:	6010,
    auxrtd_t_outputStatus:	6011,
    auxrtd_t_equipmentStatus:	6012,
    auxrtd_t_alarmStatus:	6013,
    auxrtd_t_setpointStatus:	6014,
    auxrtd_t_specialStates:	6015,
    auxrtd_t_specialStatesValue:	6016,
    auxrtd_t_temperatureStageSetPosition:	6017,
    auxrtd_t_temperatureStageStatus:	6018,
    landruToCloudEnd: -1,
    //   LT_inputidx: -100,	,
    setup_t_InputMap_INPUTIDX_INTEMP:	0,
    setup_t_InputMap_INPUTIDX_OUTTEMP:	1,
    setup_t_InputMap_INPUTIDX_HUM:	2,
    setup_t_InputMap_INPUTIDX_CO2:	3,
    setup_t_InputMap_INPUTIDX_LITE:	4,
    setup_t_InputMap_INPUTIDX_WINSPD:	5,
    setup_t_InputMap_INPUTIDX_BHEAT1:	6,
    setup_t_InputMap_INPUTIDX_BHEAT2:	7,
    setup_t_InputMap_INPUTIDX_BHEAT3:	8,
    setup_t_InputMap_INPUTIDX_BHEAT4:	9,
    setup_t_InputMap_INPUTIDX_BHEAT5:	10,
    setup_t_InputMap_INPUTIDX_FBINTEMP:	11,
    setup_t_InputMap_INPUTIDX_WINDIR:	12,
    setup_t_InputMap_INPUTIDX_SOIL1:	13,
    setup_t_InputMap_INPUTIDX_SOIL2:	14,
    setup_t_InputMap_INPUTIDX_SOIL3:	15,
    setup_t_InputMap_INPUTIDX_SOIL4:	16,
    setup_t_InputMap_INPUTIDX_SOIL5:	17,
    setup_t_InputMap_INPUTIDX_RAIN:	18,
    setup_t_InputMap_INPUTIDX_IRR_TRIG:	19,
    setup_t_InputMap_INPUTIDX_DIFFP:	20,
    setup_t_InputMap_INPUTIDX_INLIGHT:	21,
    setup_t_InputMap_INPUTIDX_VENTPOS1:	32,
    setup_t_InputMap_INPUTIDX_VENTPOS2:	33,
    setup_t_InputMap_INPUTIDX_VENTPOS3:	34,
    setup_t_InputMap_INPUTIDX_VENTPOS4:	35,
    setup_t_InputMap_INPUTIDX_VENTPOS5:	36,
    setup_t_InputMap_INPUTIDX_ECPH1:	37,
    setup_t_InputMap_INPUTIDX_ECPH2:	38,
    setup_t_InputMap_INPUTIDX_ECPH3:	39,
    setup_t_InputMap_INPUTIDX_ECPH4:	40,
    setup_t_InputMap_INPUTIDX_ECPH5:	41,
    setup_t_InputMap_INPUTIDX_GENERIC1:	42,
    setup_t_InputMap_INPUTIDX_GENERIC2:	43,
    setup_t_InputMap_INPUTIDX_OUTHUM:	44,
    setup_t_InputMap_INPUTIDX_LOCAL_INTEMP:	45,
    setup_t_InputMap_INPUTIDX_LOCAL_INHUM:	46,
    setup_t_InputMap_INPUTIDX_SNOW:	47,
    setup_t_InputMap_INPUTIDX_OUTTEMP2:	48,
    setup_t_InputMap_INPUTIDX_BARO:	49,
    setup_t_InputMap_INPUTIDX_VOLTAGE1:50,
    setup_t_InputMap_INPUTIDX_VOLTAGE2:51,
    setup_t_InputMap_INPUTIDX_VOLTAGE3:52,
    setup_t_InputMap_INPUTIDX_VOLTAGE4:53,
    setup_t_InputMap_INPUTIDX_VOLTAGE5:54,
    setup_t_InputMap_INPUTIDX_VOLTAGE6:55,
    setup_t_InputMap_INPUTIDX_VOLTAGE7:56,
    setup_t_InputMap_INPUTIDX_VOLTAGE8:57,
    setup_t_InputMap_INPUTIDX_TEMP1:58,
    setup_t_InputMap_INPUTIDX_TEMP2:59,
    setup_t_InputMap_INPUTIDX_TEMP3:60,
    setup_t_InputMap_INPUTIDX_TEMP4:61,
    setup_t_InputMap_INPUTIDX_TEMP5:62,
    setup_t_InputMap_INPUTIDX_TEMP6:63,
    setup_t_InputMap_INPUTIDX_TEMP7:64,
    setup_t_InputMap_INPUTIDX_TEMP8:65,
    setup_t_InputMap_INPUTIDX_TEMP9:66,
    setup_t_InputMap_INPUTIDX_TEMP10:67,
    setup_t_InputMap_INPUTIDX_TEMP11:68,
    setup_t_InputMap_INPUTIDX_TEMP12:69,
    setup_t_InputMap_INPUTIDX_TEMP13:70,
    setup_t_InputMap_INPUTIDX_TEMP14:71,
    setup_t_InputMap_INPUTIDX_TEMP15:72,
    setup_t_InputMap_INPUTIDX_TEMP16:73,
    setup_t_InputMap_INPUTIDX_TEMP17:74,
    setup_t_InputMap_INPUTIDX_TEMP18:75,
    setup_t_InputMap_INPUTIDX_TEMP19:76,
    setup_t_InputMap_INPUTIDX_GSNS1:77,
    setup_t_InputMap_INPUTIDX_GSNS2:78,
    setup_t_InputMap_INPUTIDX_GSNS3:79,
    setup_t_InputMap_INPUTIDX_GSNS4:80,
    setup_t_InputMap_INPUTIDX_GSNS5:81,
    setup_t_InputMap_INPUTIDX_GSNS6:82,
    setup_t_InputMap_INPUTIDX_GSNS7:83,
    setup_t_InputMap_INPUTIDX_GSNS8:84,
    setup_t_InputMap_INPUTIDX_GSNS9:85,
    setup_t_InputMap_INPUTIDX_GSNS10:86,
    setup_t_InputMap_INPUTIDX_GSNS11:87,
    setup_t_InputMap_INPUTIDX_GSNS12:88,
    setup_t_InputMap_INPUTIDX_GSNS13:89,
    setup_t_InputMap_INPUTIDX_GSNS14:90,
    setup_t_InputMap_INPUTIDX_GSNS15:91,
    setup_t_InputMap_INPUTIDX_GSNS16:92,
    setup_t_InputMap_INPUTIDX_GSNS17:93,
    setup_t_InputMap_INPUTIDX_GSNS18:94,
    setup_t_InputMap_INPUTIDX_GSNS19:95,
    setup_t_InputMap_INPUTIDX_GSNS20:96,
    setup_t_InputMap_INPUTIDX_GSNS21:97,
    setup_t_InputMap_INPUTIDX_GSNS22:98,
    setup_t_InputMap_INPUTIDX_GSNS23:99,
    setup_t_InputMap_INPUTIDX_GSNS24:100,
    setup_t_InputMap_INPUTIDX_GSNS25:101,
    setup_t_InputMap_INPUTIDX_GSNS26:102,
    setup_t_InputMap_INPUTIDX_GSNS27:103,
    setup_t_InputMap_INPUTIDX_GSNS28:104,
    setup_t_InputMap_INPUTIDX_GSNS29:105,
    setup_t_InputMap_INPUTIDX_GSNS30:106,
    setup_t_InputMap_INPUTIDX_GSNS31:107,
    setup_t_InputMap_INPUTIDX_GSNS32:108,
    setup_t_InputMap_INPUTIDX_GSNS33:109,
    setup_t_InputMap_INPUTIDX_GSNS34:110,
    setup_t_InputMap_INPUTIDX_GSNS35:111,
    setup_t_InputMap_INPUTIDX_GSNS36:112,
    setup_t_InputMap_INPUTIDX_GSNS37:113,
    setup_t_InputMap_INPUTIDX_GSNS38:114,
    setup_t_InputMap_INPUTIDX_GSNS39:115,
    setup_t_InputMap_INPUTIDX_GSNS40:116,
    setup_t_InputMap_INPUTIDX_GSNS41:117,
    setup_t_InputMap_INPUTIDX_GSNS42:118,
    setup_t_InputMap_INPUTIDX_GSNS43:119,
    setup_t_InputMap_INPUTIDX_GSNS44:120,
    setup_t_InputMap_INPUTIDX_GSNS45:121,
    setup_t_InputMap_INPUTIDX_GSNS46:122,
    setup_t_InputMap_INPUTIDX_GSNS47:123,
    setup_t_InputMap_INPUTIDX_GSNS48:124,
    setup_t_InputMap_INPUTIDX_GSNS49:125,
    setup_t_InputMap_INPUTIDX_GSNS50:126,
    setup_t_InputMap_INPUTIDX_GSNS51:127,
    setup_t_InputMap_INPUTIDX_GSNS52:128,
    setup_t_InputMap_INPUTIDX_GSNS53:129,
    setup_t_InputMap_INPUTIDX_GSNS54:130,
    setup_t_InputMap_INPUTIDX_GSNS55:131,
    setup_t_InputMap_INPUTIDX_GSNS56:132,
    setup_t_InputMap_INPUTIDX_GSNS57:133,
    setup_t_InputMap_INPUTIDX_GSNS58:134,
    setup_t_InputMap_INPUTIDX_GSNS59:135,
    setup_t_InputMap_INPUTIDX_GSNS60:136,
    setup_t_InputMap_INPUTIDX_GSNS61:137,
    setup_t_InputMap_INPUTIDX_GSNS62:138,
    setup_t_InputMap_INPUTIDX_GSNS63:139,
    setup_t_InputMap_INPUTIDX_GSNS64:140,
    setup_t_InputMap_INPUTIDX_GSNS65:141,
    setup_t_InputMap_INPUTIDX_GSNS66:142,
    setup_t_InputMap_INPUTIDX_GSNS67:143,
    setup_t_InputMap_INPUTIDX_GSNS68:144,
    setup_t_InputMap_INPUTIDX_GSNS69:145,
    setup_t_InputMap_INPUTIDX_GSNS70:146,
    setup_t_InputMap_INPUTIDX_GSNS71:147,
    setup_t_InputMap_INPUTIDX_GSNS72:148,
    setup_t_InputMap_INPUTIDX_GSNS73:149,
    setup_t_InputMap_INPUTIDX_GSNS74:150,
    setup_t_InputMap_INPUTIDX_GSNS75:151,
    setup_t_InputMap_INPUTIDX_GSNS76:152,
    setup_t_InputMap_INPUTIDX_GSNS77:153,
    setup_t_InputMap_INPUTIDX_GSNS78:154,
    setup_t_InputMap_INPUTIDX_GSNS79:155,
    setup_t_InputMap_INPUTIDX_GSNS80:156,
    setup_t_InputMap_INPUTIDX_GSNS81:157,
    setup_t_InputMap_INPUTIDX_GSNS82:158,
    setup_t_InputMap_INPUTIDX_GSNS83:159,
    setup_t_InputMap_INPUTIDX_GSNS84:160,
    setup_t_InputMap_INPUTIDX_GSNS85:161,
    setup_t_InputMap_INPUTIDX_GSNS86:162,
    setup_t_InputMap_INPUTIDX_GSNS87:163,
    setup_t_InputMap_INPUTIDX_GSNS88:164,
    setup_t_InputMap_INPUTIDX_GSNS89:165,
    setup_t_InputMap_INPUTIDX_GSNS90:166,
    setup_t_InputMap_INPUTIDX_GSNS91:167,
    setup_t_InputMap_INPUTIDX_GSNS92:168,
    setup_t_InputMap_INPUTIDX_GSNS93:169,
    setup_t_InputMap_INPUTIDX_GSNS94:170,
    setup_t_InputMap_INPUTIDX_GSNS95:171,
    setup_t_InputMap_INPUTIDX_GSNS96:172,
    setup_t_InputMap_INPUTIDX_GSNS97:173,
    setup_t_InputMap_INPUTIDX_GSNS98:174,
    setup_t_InputMap_INPUTIDX_GSNS99:175,
    setup_t_InputMap_INPUTIDX_GSNS100:176,
    setup_t_InputMap_INPUTIDX_GSNS101:177,
    setup_t_InputMap_INPUTIDX_GSNS102:178,
    setup_t_InputMap_INPUTIDX_GSNS103:179,
    setup_t_InputMap_INPUTIDX_GSNS104:180,
    setup_t_InputMap_INPUTIDX_GSNS105:181,
    setup_t_InputMap_INPUTIDX_GSNS106:182,
    setup_t_InputMap_INPUTIDX_GSNS107:183,
    setup_t_InputMap_INPUTIDX_GSNS108:184,
    setup_t_InputMap_INPUTIDX_GSNS109:185,
    setup_t_InputMap_INPUTIDX_GSNS110:186,
    setup_t_InputMap_INPUTIDX_GSNS111:187,
    setup_t_InputMap_INPUTIDX_GSNS112:188,
    setup_t_InputMap_INPUTIDX_GSNS113:189,
    setup_t_InputMap_INPUTIDX_GSNS114:190,
    setup_t_InputMap_INPUTIDX_GSNS115:191,
    setup_t_InputMap_INPUTIDX_GSNS116:192,
    setup_t_InputMap_INPUTIDX_GSNS117:193,
    setup_t_InputMap_INPUTIDX_GSNS118:194,
    setup_t_InputMap_INPUTIDX_GSNS119:195,
    setup_t_InputMap_INPUTIDX_GSNS120:196,
    setup_t_InputMap_INPUTIDX_GSNS121:197,
    setup_t_InputMap_INPUTIDX_GSNS122:198,
    setup_t_InputMap_INPUTIDX_GSNS123:199,
    setup_t_InputMap_INPUTIDX_GSNS124:200,
    setup_t_InputMap_INPUTIDX_GSNS125:201,
    setup_t_InputMap_INPUTIDX_GSNS126:202,
    setup_t_InputMap_INPUTIDX_GSNS127:203,
    setup_t_InputMap_INPUTIDX_GSNS128:204,

    setup_t_InputMap_INPUTNAME_NONE:0,
    setup_t_InputMap_INPUTNAME_ANALOG_SS1:1,
    setup_t_InputMap_INPUTNAME_ANALOG_SS2:2,
    setup_t_InputMap_INPUTNAME_ANALOG_1:3,
    setup_t_InputMap_INPUTNAME_ANALOG_2:4,
    setup_t_InputMap_INPUTNAME_ANALOG_3:5,
    setup_t_InputMap_INPUTNAME_ANALOG_4:6,
    setup_t_InputMap_INPUTNAME_ANALOG_5:7,
    setup_t_InputMap_INPUTNAME_ANALOG_6:8,
    setup_t_InputMap_INPUTNAME_ANALOG_7:9,
    setup_t_InputMap_INPUTNAME_ANALOG_8:10,
    setup_t_InputMap_INPUTNAME_FDIN_1:11,
    setup_t_InputMap_INPUTNAME_FDIN_2:12,
    setup_t_InputMap_INPUTNAME_FDIN_3:13,
    setup_t_InputMap_INPUTNAME_DIGITAL_1:14,
    setup_t_InputMap_INPUTNAME_DIGITAL_2:15,
    setup_t_InputMap_INPUTNAME_DIGITAL_3:16,
    setup_t_InputMap_INPUTNAME_DIGITAL_4:17,
    setup_t_InputMap_INPUTNAME_DIGITAL_5:18,
    setup_t_InputMap_INPUTNAME_DIGITAL_6:19,
    setup_t_InputMap_INPUTNAME_DIGITAL_7:20,
    setup_t_InputMap_INPUTNAME_DIGITAL_8:21,
    setup_t_InputMap_INPUTNAME_SOFTWARE:22,
    setup_t_InputMap_INPUTNAME_REMOTE:23,
    setup_t_InputMap_INPUTNAME_EXB_ANALOG_1:24,
    setup_t_InputMap_INPUTNAME_EXB_ANALOG_2:25,
    setup_t_InputMap_INPUTNAME_SENSOR_MODULE:26,
    setup_t_InputMap_INPUTNAME_SENSOR_DTH:27,
    setup_t_InputMap_INPUTNAME_SENSMOD_BKUP:28,
    setup_t_InputMap_INPUTNAME_MBUS_INPUT :29,
//   LT_tables: -100,	,
    ZC_Use_Fallback_Sensor_if_In_Temp_Fails:	3,
    ZC_Fallback_Sensor_Failed_Temperature_Stage:	7,
    ZC_Force_To_No_Sensor_Stage_setting_3_if_High_Alarm_occurs:	9,
    ZC_Rain_Hold_Time:	11,
    ZC_Command_Delay_Time:	13,
    ZC_Daylight_Savings_Time:	15,
    ZC_Log_History:	17,
    ZC_Latitude:	19,
    ZC_Longitude:	21,
    ZC_Temperature_Units:	23,
    ZC_Windspeed_Units:	25,
    ZC_Light_Units:	27,
    ZC_Irrigation_Mode:	29,
    ZC_Irrigation_Delay:	31,
    ZC_High_Alarm_Temperature_Above_Cool_Setpoint_Threshold:	35,
    ZC_High_Alarm_Temperature_Hold_Time:	37,
    ZC_Low_Alarm_Temperature_Below_Heat_Setpoint_Threshold:	39,
    ZC_Low_Alarm_Temperature_Hold_Time:	41,
    ZC_Cool_Deadband:	43,
    ZC_Heat_Deadband:	45,
    ZC_Humidity_Deadband:	47,
    ZC_Analog_Temperature_Deadband:	49,
    ZC_Enable_SmartCool:	51,
    ZC_SmartCool_Setting:	53,
    ZC_Show_Heat_Demand_on_iGrow:	65,
    ZC_Heat_Demand_Max_Light:	67,
    ZC_Heat_Demand_Max_Wind:	69,
    ZC_Greenhouse_heating_design_delta_T:	71,
    ZC_Light_s_influence_on_Heat_Demand:	73,
    ZC_Wind_s_influence_on_Heat_Demand:	75,
    ZC_Show_Cool_Demand_on_iGrow:	77,
    ZC_Cool_Demand_Max_Light:	79,
    ZC_Greenhouse_cooling_design_delta_T:	81,
    ZC_Cool_Demand_Light_Factor:	83,
    ZC_Enable_Active_Cooling:	85,
    ZC_Passive_Lock_Stage:	87,
    ZC_Enter_Active_Cooling_Cool_Demand_Threshold:	89,
    ZC_Exit_Active_Cooling_Cool_Demand_Threshold:	91,
    ZC_Cool_stages:	93,
    ZC_Heat_stages:	95,
    ZC_Stage_Width:	97,
    ZC_Stage_Override_1_Enabled:	99,
    ZC_Stage_Override_1_Temperature_Stage:	101,
    ZC_Stage_Override_1_Start_Time:	103,
    ZC_Stage_Override_1_End_Time:	105,
    ZC_Stage_Override_1_Interval:	107,
    ZC_Stage_Override_2_Enabled:	109,
    ZC_Stage_Override_2_Temperature_Stage:	111,
    ZC_Stage_Override_2_Start_Time:	113,
    ZC_Stage_Override_2_End_Time:	115,
    ZC_Stage_Override_2_Interval:	117,
    ZC_Stage_Override_3_Enabled:	119,
    ZC_Stage_Override_3_Temperature_Stage:	121,
    ZC_Stage_Override_3_Start_Time:	123,
    ZC_Stage_Override_3_End_Time:	125,
    ZC_Stage_Override_3_Interval:	127,
    ZC_Stage_Override_4_Enabled:	129,
    ZC_Stage_Override_4_Temperature_Stage:	131,
    ZC_Stage_Override_4_Start_Time:	133,
    ZC_Stage_Override_4_End_Time:	135,
    ZC_Stage_Override_4_Interval:	137,
    ZC_Vapor_Pressure_Deficit_Media_Sensor:	139,
    ZC_Lighting_Cyclic_Mode:	141,
    ZC_Lighting_Start_Delay:	143,
    ZC_Lighting_Finish_Delay:	145,
    ZC_Lighting_Active_Time:	147,
    ZC_Lighting_Minimum_On_Time:	149,
    ZC_Drive_to_Average_Enable:	151,
    ZC_Drive_to_Average_Target_Temperature:	153,
    ZC_Drive_to_Average_Maximum_Failed_Days:	155,
    ZC_Drive_to_Average_Deviated_Temperature_Threshold:	157,
    ZC_Drive_to_Average_Setpoint_Correction:	159,
    ZC_Cool_Setpoint_Influence_Factor_Enable:	161,
    ZC_Cool_Setpoint_Influence_Factor_Sensor:	163,
    ZC_Cool_Setpoint_Influence_Factor_Upper_Threshold:	165,
    ZC_Cool_Setpoint_Influence_Factor_Upper_Offset:	167,
    ZC_Cool_Setpoint_Influence_Factor_Lower_Threshold:	169,
    ZC_Cool_Setpoint_Influence_Factor_Lower_Offset:	171,
    ZC_Heat_Setpoint_Influence_Factor_Enable:	173,
    ZC_Heat_Setpoint_Influence_Factor_Sensor:	175,
    ZC_Heat_Setpoint_Influence_Factor_Upper_Threshold:	177,
    ZC_Heat_Setpoint_Influence_Factor_Upper_Offset:	179,
    ZC_Heat_Setpoint_Influence_Factor_Lower_Threshold:	181,
    ZC_Heat_Setpoint_Influence_Factor_Lower_Offset:	183,
    ZC_Start_Up_Delay:	185,
    ZC_Curtain_Energy_Mode_Light_Deadband:	187,
    ZC_Curtain_Energy_Mode_Temperature_Deadband:	189,
    ZC_Humidity_Override_On_Time:	191,
    ZC_Humidity_Override_Off_Time:	193,
    ZC_Dehumidify_Low_Outside_Temperature_Threshold:	195,
    ZC_Dehumidify_Heat_Boost:	197,
    ZC_Enable_Demands_Based_on_Active_Cool:	199,
    ZC_Enable_Active_Cool_Stage_Override:	201,
    ZC_SmartCool_0_Ku:	203,
    ZC_SmartCool_0_Gmax:	205,
    ZC_SmartCool_0_Kd:	207,
    ZC_SmartCool_0_Gmin:	209,
    ZC_SmartCool_1_Ku:	211,
    ZC_SmartCool_1_Gmax:	213,
    ZC_SmartCool_1_Kd:	215,
    ZC_SmartCool_1_Gmin:	217,
    ZC_SmartCool_2_Ku:	219,
    ZC_SmartCool_2_Gmax:	221,
    ZC_SmartCool_2_Kd:	223,
    ZC_SmartCool_2_Gmin:	225,
    ZC_SmartCool_3_Ku:	227,
    ZC_SmartCool_3_Gmax:	229,
    ZC_SmartCool_3_Kd:	231,
    ZC_SmartCool_3_Gmin:	233,
    ZC_SmartCool_4_Ku:	235,
    ZC_SmartCool_4_Gmax:	237,
    ZC_SmartCool_4_Kd:	239,
    ZC_SmartCool_4_Gmin:	241,
    ZC_SmartCool_5_Ku:	243,
    ZC_SmartCool_5_Gmax:	245,
    ZC_SmartCool_5_Kd:	247,
    ZC_SmartCool_5_Gmin:	249,
    ZC_SmartCool_6_Ku:	251,
    ZC_SmartCool_6_Gmax:	253,
    ZC_SmartCool_6_Kd:	255,
    ZC_SmartCool_6_Gmin:	257,
    ZC_SmartCool_7_Ku:	259,
    ZC_SmartCool_7_Gmax:	261,
    ZC_SmartCool_7_Kd:	263,
    ZC_SmartCool_7_Gmin:	265,
    ZC_SmartCool_8_Ku:	267,
    ZC_SmartCool_8_Gmax:	269,
    ZC_SmartCool_8_Kd:	271,
    ZC_SmartCool_8_Gmin:	273,
    ZC_SmartCool_9_Ku:	275,
    ZC_SmartCool_9_Gmax:	277,
    ZC_SmartCool_9_Kd:	279,
    ZC_SmartCool_9_Gmin:	281,
    ZC_Peristaltic_Output_Mode:	283,
    ZC_Peristaltic_Output_Delay:	285,
    ZC_Volume_Measurement_Units:	287,
    ZC_Nutrient_Units:	289,
    ZC_Nutrient_Units_TDS_Conversion_Factor:	291,
    ZC_Equipment_Delay:	293,
    ZC_Sensor_Display_1:	295,
    ZC_Sensor_Display_2:	297,
    ZC_Sensor_Display_3:	299,
    ZC_Sensor_Display_4:	301,
    ZC_Schedule_Pump_Transition_Time:	303,
    ZC_Peristaltic_Pump_Advanced_Mode:	305,
    
    CC_Inside_Temperature_Mapping:	3,
    CC_Relative_Humidity_Mapping:	5,
    CC_Local_Temperature_Mapping:	7,
    CC_Local_Humidity_Mapping:	9,
    CC_Outside_Temperature_Mapping:	11,
    CC_Outside_Humidity_Mapping:	13,
    CC_CO2_Mapping:	15,
    CC_Outside_Light_Mapping:	17,
    CC_Wind_Speed_Mapping:	19,
    CC_Wind_Direction_Mapping:	21,
    CC_Rain_Mapping:	23,
    CC_Analog_Temperature_1_Mapping:	25,
    CC_Analog_Temperature_2_Mapping:	27,
    CC_Analog_Temperature_3_Mapping:	29,
    CC_Analog_Temperature_4_Mapping:	31,
    CC_Analog_Temperature_5_Mapping:	33,
    CC_Soil_Moisture_1_Mapping:	35,
    CC_Soil_Moisture_2_Mapping:	37,
    CC_Soil_Moisture_3_Mapping:	39,
    CC_Soil_Moisture_4_Mapping:	41,
    CC_Soil_Moisture_5_Mapping:	43,
    CC_Vent_Position_Sensor_1_Mapping:	45,
    CC_Vent_Position_Sensor_2_Mapping:	47,
    CC_Vent_Position_Sensor_3_Mapping:	49,
    CC_Vent_Position_Sensor_4_Mapping:	51,
    CC_Vent_Position_Sensor_5_Mapping:	53,
    CC_EC_pH_1_Mapping:	55,
    CC_EC_pH_2_Mapping:	57,
    CC_EC_pH_3_Mapping:	59,
    CC_EC_pH_4_Mapping:	61,
    CC_EC_pH_5_Mapping:	63,
    CC_EC_pH_1_Probe_Type:	65,
    CC_EC_pH_2_Probe_Type:	67,
    CC_EC_pH_3_Probe_Type:	69,
    CC_EC_pH_4_Probe_Type:	71,
    CC_EC_pH_5_Probe_Type:	73,
    CC_Generic_1_Mapping:	75,
    CC_Generic_2_Mapping:	77,
    CC_Inside_Temperature_Calibration:	79,
    CC_Relative_Humidity_Calibration:	81,
    CC_Outside_Temperature_Calibration:	83,
    CC_Outside_Humidity_Calibration:	85,
    CC_CO2_Calibration:	87,
    CC_Outside_Light_Calibration:	89,
    CC_Wind_Speed_Calibration:	91,
    CC_Fallback_Temperature_Calibration:	93,
    CC_Analog_Temperature_1_Calibration:	95,
    CC_Analog_Temperature_2_Calibration:	97,
    CC_Analog_Temperature_3_Calibration:	99,
    CC_Analog_Temperature_4_Calibration:	101,
    CC_Analog_Temperature_5_Calibration:	103,
    CC_Soil_Mositure_1_Calibration:	105,
    CC_Soil_Mositure_2_Calibration:	107,
    CC_Soil_Mositure_3_Calibration:	109,
    CC_Soil_Mositure_4_Calibration:	111,
    CC_Soil_Mositure_5_Calibration:	113,
    CC_Vent_Position_Sensor_1_Calibration_Adjust:	115,
    CC_Vent_Position_Sensor_2_Calibration_Adjust:	117,
    CC_Vent_Position_Sensor_3_Calibration_Adjust:	119,
    CC_Vent_Position_Sensor_4_Calibration_Adjust:	121,
    CC_Vent_Position_Sensor_5_Calibration_Adjust:	123,
    CC_EC_pH_1_Calibration_Zero:	125,
    CC_EC_pH_2_Calibration_Zero:	127,
    CC_EC_pH_3_Calibration_Zero:	129,
    CC_EC_pH_4_Calibration_Zero:	131,
    CC_EC_pH_5_Calibration_Zero:	133,
    CC_EC_pH_1_Calibration_Gain:	135,
    CC_EC_pH_2_Calibration_Gain:	137,
    CC_EC_pH_3_Calibration_Gain:	139,
    CC_EC_pH_4_Calibration_Gain:	141,
    CC_EC_pH_5_Calibration_Gain:	143,
    CC_EC_pH_1_Calibration_Given_Gain:	145,
    CC_EC_pH_2_Calibration_Given_Gain:	147,
    CC_EC_pH_3_Calibration_Given_Gain:	149,
    CC_EC_pH_4_Calibration_Given_Gain:	151,
    CC_EC_pH_5_Calibration_Given_Gain:	153,
    CC_Generic_1_Calibration:	155,
    CC_Generic_2_Calibration:	157,
    CC_CO2_Least_Significant_Bit:	159,
    CC_Light_Multiplier:	161,
    CC_Generic_1_Multiplier:	163,
    CC_Generic_2_Multiplier:	165,
    CC_Local_Remote_Setpoints:	167,
    CC_Enable_Bump_Vents:	169,
    CC_Bump_Intervals_Minutes:	171,
    CC_Spike_Temperature_Delta:	173,
    CC_Spike_Temperature_Hold_Time_Exception:	175,
    CC_Outside_Temperature_2_Mapping:	177,
    CC_Outside_Temperature_2_Calibration:	179,
    CC_Barometric_Pressure_Mapping:	181,
    CC_Barometric_Pressure_Calibration:	183,
    CC_Enable_expansion_board:	185,
    CC_Autodetect_Mode:	187,
    CC_Heat_Demand_Offset:	189,
    CC_Cool_Demand_Offset:	191,
    CC_Generic_Sensor_1_Range:	193,
    CC_Generic_Sensor_1_Units:	195,
    CC_Generic_Sensor_1_Filter_Max_Samples:	197,
    CC_Generic_Sensor_2_Range:	199,
    CC_Generic_Sensor_2_Units:	201,
    CC_Generic_PID_Multipliers_P:	203,
    CC_Generic_PID_Multipliers_I:	205,
    CC_Generic_PID_Multipliers_D:	207,
    CC_Expansion_Output_1_Type:	219,
    CC_Expansion_Output_2_Type:	221,
    CC_Expansion_Input_1_Type:	223,
    CC_Expansion_Input_2_Type:	225,
    CC_Sensor_Delay:	227,
    CC_Vent_Position_1_Calibration_Open:	229,
    CC_Vent_Position_2_Calibration_Open:	231,
    CC_Vent_Position_3_Calibration_Open:	233,
    CC_Vent_Position_4_Calibration_Open:	235,
    CC_Vent_Position_5_Calibration_Open:	237,
    CC_Vent_Position_1_Calibration_Close:	239,
    CC_Vent_Position_2_Calibration_Close:	241,
    CC_Vent_Position_3_Calibration_Close:	243,
    CC_Vent_Position_4_Calibration_Close:	245,
    CC_Vent_Position_5_Calibration_Close:	247,
    CC_Inside_Light_Mapping:	249,
    CC_Differential_Pressure_Mapping:	251,
    CC_Snow_Mapping:	253,
    CC_Inside_Light_Calibration:	255,
    CC_Differential_Pressure_Calibration:	257,
    CC_Accumulator_1_Sensor:	259,
    CC_Accumulator_1_Operating_Period:	261,
    CC_Accumulator_2_Sensor:	263,
    CC_Accumulator_2_Operating_Period:	265,
    CC_Generic_Sensor_2_Filter_Max_Samples:	267,
    CC_Enable_Loud_Vent:	269,
    CC_Irrigation_Trigger_Input_Mapping:	271,
    CC_Fallback_Sensor_Input_Mapping:	273,
    CC_Enable_Expansion_Board:	275,
    CC_Enable_Loud_Vent:	277,
    CC_Wind_Direction_Calibration:	279,
    CC_Rain_Calibration:	281,
    CC_Snow_Calibration:	283,
    CC_Inside_Light_Multiplier:	285,
    CC_Canopy_Sensor_Mapping:	287,
    CC_Canopy_Sensor_Calibration:	289,
    CC_Max_Temperature_Adjustment:	291,
    CC_Temperature_Average_Enable:	293,
    CC_Temperature_Average_Include_Temp1:	295,
    CC_Temperature_Average_Include_Temp2:	297,
    CC_Temperature_Average_Include_Temp3:	299,
    CC_Temperature_Average_Include_Temp4:	301,
    CC_Temperature_Average_Include_Temp5:	303,
    CC_VPD_Notify:	305,
    CC_VPD_Min:	307,
    CC_VPD_Max:	309,
    CC_Cloud_1800_Enable:	311,
    CC_Save_Inside_Temperature_Calibration:	313,
    CC_Save_Temperature_Average_Enable:	315,
    
    SP_setpointIndex: 0,
    SP_unix_timestamp_modified_: 1,
    SP_enabled: 2,
    SP_startTimeOfDay: 3,
    SP_astroAdjust: 4,
    SP_rampMinutes: 5,
    SP_heatSetpoint: 6,
    SP_coolSetpoint: 7,
    SP_humidifySetpoint: 8,
    SP_dehumidifySetpoint: 9,
    SN_id: 1,
    SN_zoneIndex: 2,
    SN_unitIndex: 3,
    SN_unix_timestamp_created: 4,
    SN_unix_timestamp_unitTime: 5,
    SN_temperatureStage: 6,
    SN_humidityStage: 7,
    SN_heatSetpoint: 8,
    SN_coolSetpoint: 9,
    SN_humidifySetpoint: 10,
    SN_dehumidifySetpoint: 11,
    SN_accumulator0: 12,
    SN_accumulator1: 13,
    SN_d2avgTotalAverage: 14,
    SN_d2avgDayAverage: 15,
    SN_d2avgNightAverage: 16,
    SN_d2avgFailDays: 17,
    SN_lowAlarm: 18,
    SN_highAlarm: 19,
    SN_lowInTemperatureAlarm: 20,
    SN_highInTemperatureAlarm: 21,
    SN_inTemperatureSensorAlarm: 22,
    SN_inTemperature: 23,
    SN_outTemperature: 24,
    SN_localTemperature: 25,
    SN_backupTemperature: 26,
    SN_inHumidity: 27,
    SN_outHumidity: 28,
    SN_localHumidity: 29,
    SN_differentialPressure: 30,
    SN_co2: 31,
    SN_inLight: 32,
    SN_outLight: 33,
    SN_windSpeed: 34,
    SN_windDirection: 35,
    SN_rain: 36,
    SN_snow: 37,
    SN_analogTemperature1: 38,
    SN_analogTemperature2: 39,
    SN_analogTemperature3: 40,
    SN_analogTemperature4: 41,
    SN_analogTemperature5: 42,
    SN_ventPosition1: 43,
    SN_ventPosition2: 44,
    SN_ventPosition3: 45,
    SN_ventPosition4: 46,
    SN_ventPosition5: 47,
    SN_soilMoisture1: 48,
    SN_soilMoisture2: 49,
    SN_soilMoisture3: 50,
    SN_soilMoisture4: 51,
    SN_soilMoisture5: 52,
    SN_ecph1: 53,
    SN_ecph2: 54,
    SN_ecph3: 55,
    SN_ecph4: 56,
    SN_ecph5: 57,
    SN_generic1: 58,
    SN_generic2: 59,
    SN_heatDemand: 60,
    SN_coolDemand: 61,
    SN_coolDemandPassive: 62,
    SN_auxHeatSetpointStatus: 63,
    SN_auxHeatSetpointValue: 64,
    SN_auxCoolSetpointStatus: 65,
    SN_auxCoolSetpointValue: 66,
    SN_auxHumidifySetpointStatus: 67,
    SN_auxHumidifySetpointValue: 68,
    SN_auxDehumidifySetpointStatus: 69,
    SN_auxDehumidifySetpointValue: 70,
    SN_auxLowAlarmStatus: 71,
    SN_auxLowAlarmValue: 72,
    SN_auxHighAlarmStatus: 73,
    SN_auxHighAlarmValue: 74,
    SN_auxActiveCoolStatus: 75,
    SN_auxActiveCoolValue: 76,
    SN_auxPassiveCoolStatus: 77,
    SN_auxPassiveCoolValue: 78,
    SN_accvpd: 79,
    SN_outTemperatureSecondary: 80,
    SN_barometricPressure: 81,
    SN_vpd: 82,
    SN_cloudStatus_t_ProcessorID: 83,
    SN_cloudStatus_t_ConrollerID: 84,
    SN_cloudStatus_t_CurrentTime: 85,

    ECPH_ALARM_EC_DEVIATION: 0x0001,// these are the flags used for ecph alarms
    ECPH_ALARM_PH_DEVIATION: 0x0002,
    ECPH_ALARM_EC_LOW: 0x0004,
    ECPH_ALARM_EC_HIGH: 0x0008,
    ECPH_ALARM_PH_LOW: 0x0010,
    ECPH_ALARM_PH_HIGH: 0x0020,
    ECPH_ALARM_EC_SERVICE: 0x0040,
    ECPH_ALARM_EC_CALIB: 0x0080,
    ECPH_ALARM_PH_SERVICE: 0x0100,
    ECPH_ALARM_PH_CALIB: 0x0200,
    ECPH_ALARM_EC_ERROR: 0x0400,
    ECPH_ALARM_PH_ERROR: 0x0800,
    SE_snapshotId: 1,
    SE_ecphIndex: 2,
    SE_ec1: 3,
    SE_ec2: 4,
    SE_ec3: 5,
    SE_ec1SensorAlarm: 6,// this now holds *all* the alarms for ecph - both ec and ph. there are 12 bit flags
    //   SE_ec1ServiceAlarm: 7,
    //   SE_ec1CalibrationAlarm: 8,
    SE_ec2SensorAlarm: 9,
    //   SE_ec2ServiceAlarm: 10,
    //   SE_ec2CalibrationAlarm: 11,
    SE_ec3SensorAlarm: 12,
    //   SE_ec3ServiceAlarm: 13,
    //   SE_ec3CalibrationAlarm: 14,
    //   SE_ecLowAlarm: 15,
    //   SE_ecHighAlarm: 16,
    //   SE_ecDeviationAlarm: 17,
    SE_ph1: 18,
    SE_ph2: 19,
    SE_ph3: 20,
    //   SE_ph1SensorAlarm: 21,// similarly, this is all the ph alarms
    //   SE_ph1ServiceAlarm: 22,
    //   SE_ph1CalibrationAlarm: 23,
    //   SE_ph2SensorAlarm: 24,
    //   SE_ph2ServiceAlarm: 25,
    //   SE_ph2CalibrationAlarm: 26,
    //   SE_ph3SensorAlarm: 27,
    //   SE_ph3ServiceAlarm: 28,
    //   SE_ph3CalibrationAlarm: 29,
    //   SE_phLowAlarm: 30,
    //   SE_phHighAlarm: 31,
    //   SE_phDeviationAlarm: 32,
    SE_temperature1: 33,
    SE_temperature2: 34,
    SE_temperature3: 35,
    SX_boardStatus: 303,
    CD_activeCool_cool1: 0,
    CD_activeCool_cool2: 1,
    CD_activeCool_cool3: 2,
    CD_activeCool_cool4: 3,
    CD_activeCool_cool5: 4,
    CD_activeCool_cool6: 5,
    
    CD_activeCool_cool1_onoff: 356,
    CD_activeCool_cool2_onoff: 357,
    CD_activeCool_cool3_onoff: 358,
    CD_activeCool_cool4_onoff: 359,
    CD_activeCool_cool5_onoff: 360,
    CD_activeCool_cool6_onoff: 361,
    CD_activeCool_normal_onoff: 362,
    
    CD_heat6_vent: 363,// these need to be ordered as an array for the FUI page
    CD_heat5_vent: 364,// note that you have to add to pi[1800].config_channels_configuration["activeTrigger"] = 13, also!
    CD_heat4_vent: 365,
    CD_heat3_vent: 366,
    CD_heat2_vent: 367,
    CD_heat1_vent: 368,
    CD_normal_vent: 369,
    CD_cool1_vent: 370,
    CD_cool2_vent: 371,
    CD_cool3_vent: 372,
    CD_cool4_vent: 373,
    CD_cool5_vent: 374,
    CD_cool6_vent: 375,

    CD_trigStartType_fval: 376,
    CD_trigStartIDX_fval: 379,
    CD_trigStopType_fval: 382,
    CD_trigStopIDX_fval: 385,
    CD_time_sec_fval: 388,
    CD_volume_fval: 391,
    CD_trigStartType_pump: 394,
    CD_trigStartIDX_pump: 397,
    CD_batchVolume_pump: 400,
    CD_SL_minDifferencex10_pump: 403,
    CD_SL_ratiox100_pump: 408,

    
    CD_activeCool_heat1: 6,
    CD_activeCool_heat2: 7,
    CD_activeCool_heat3: 8,
    CD_activeCool_heat4: 9,
    CD_activeCool_heat5: 10,
    CD_activeCool_heat6: 11,
    CD_activeCool_normal: 12,
    CD_activeTrigger_pump: 13,
    CD_activeTrigger_fval: 355,
    CD_advanced_close_perc: 14,
    CD_advanced_shock_threshold: 15,
    CD_advanced_stage: 16,
    CD_advanced_time: 17,
    CD_air_cool_setpoint: 18,
    CD_air_heat_setpoint: 19,
    CD_air_temp_sensor: 20,
    CD_analog_max_mv_pz: 21,
    CD_analog_max_varout: 352,
    CD_analog_max_generic: 353,
    
    CD_analog_max: 22,
    CD_analog_min_mv_pz: 23,
    CD_analog_min_varout: 350,
    CD_analog_min_generic: 351,
    
//     CD_cool1: 24,
//     CD_cool2: 25,
//     CD_cool3: 26,
//     CD_cool4: 27,
//     CD_cool5: 28,
//     CD_cool6: 29,
    CD_cooling_device: 30,
    CD_cycleOffPeriod: 31,
    CD_cycleOnPeriod: 32,
    CD_D: 33,
    CD_daytime_astroadjust: 34,
    CD_daytime_enabled: 35,
    CD_daytime_setpoint: 36,
    CD_daytime_stage: 37,
    CD_daytime_start: 38,
    CD_deadband: 39,
    CD_dir: 40,
    CD_disable_inside_temp_pid: 41,
    CD_dliEndTime: 42,
    CD_dliEnergyOutput: 43,
    CD_dliOnTime: 44,
    CD_dliSensor: 45,
    CD_dliStartTime: 46,
    CD_dliThreshold: 47,
    CD_enableActiveCoolStageOverride: 48,
    CD_enableSmartInject: 49,
    CD_end_irr: 50,
    CD_end_hid: 291,
    CD_end_astroadjust_irr: 51,
    CD_end_astroadjust_co2: 288,
    CD_end_astroadjust_hid: 289,
    CD_end_time: 52,
    CD_energy_end: 53,
    CD_energy_end_astroadjust: 54,
    CD_energy_start: 55,
    CD_energy_start_astroadjust: 56,
    CD_feedingMode: 57,
    CD_fine_adjust_range: 58,
    CD_followChannelIndex: 59,
    CD_followRatio: 60,
    CD_function: 61,
    CD_function_float_max: 62,
    CD_function_float_min: 63,
    CD_function_int_max: 64,
    CD_function_int_min: 65,
    CD_gain: 66,
    CD_heat_on_delay: 67,
//     CD_heat1: 68,// these had to get unified with cool1
//     CD_heat2: 69,
//     CD_heat3: 70,
//     CD_heat4: 71,
//     CD_heat5: 72,
//     CD_heat6: 73,
    CD_highlight_cover_perc: 74,
    CD_highlight_cover_trigger_light: 75,
    CD_highlight_cover_trigger_temp: 76,
    CD_hold_delay: 77,
    CD_I: 78,
    CD_increased_co2_level: 79,
    CD_injectingBatchTimeSeconds: 80,
    CD_injectingBatchVolumeMilliLiters: 81,
    CD_injectingLimitHours: 82,
    CD_injectingLimitMilliLiters: 83,
    CD_injectingLimitSeconds: 84,
    CD_injectingTimeSeconds: 85,
    CD_injectingVolumeMilliLiters: 86,
    CD_injectionRate: 87,
    CD_inside_temp_d: 88,
    CD_inside_temp_i: 89,
    CD_inside_temp_p: 90,
    CD_irrigation_mode: 91,
    CD_isCycling: 92,
    CD_keep_open_threshold_light: 93,
    CD_keep_open_threshold_temp: 94,
    CD_light_drive_to: 95,
    CD_light_level: 96,
    CD_light_mode: 97,
    CD_light_sensor_enabled: 98,
    CD_light_threshold: 99,
    CD_low_light_threshold_duration: 100,
    CD_low_light_threshold_light: 101,
    CD_mixingTimeSeconds: 102,
    CD_mode_mzone: 103,
    CD_mode_varout: 347,
    CD_nighttime_astroadjust: 104,
    CD_nighttime_enabled: 105,
    CD_nighttime_setpoint: 106,
    CD_nighttime_stage: 107,
    CD_nighttime_start: 108,
//     CD_normal: 109,
    CD_normal_co2_level: 110,
    CD_off_delay: 111,
    CD_off_duration: 112,
    CD_off_threshold: 113,
    CD_offDelay: 114,
    CD_offset: 115,
    CD_on_duration: 116,
    CD_on_threshold: 117,
    CD_output_type: 118,
    CD_override_action_target: 119,
    CD_override_dir: 120,
    CD_override_limit: 121,
    CD_override_sensor: 122,
    CD_override_trigger: 123,
    CD_P: 124,
    CD_passive_cooling: 125,
    CD_probe_id: 126,
    CD_probeSensorIndex: 127,
    CD_probeType: 128,
    CD_pulse_ff_time: 129,
    CD_pulse_on_time: 130,
    CD_pump_mzone: 131,
    CD_pump_onoff: 348,
    CD_pump_id: 132,
    CD_pump_type: 133,
    CD_pumpMeasurementUnits: 134,
    CD_resetInjectionTracker: 135,
    CD_sensor_mzone: 136,
    CD_sensor_generic: 346,
    CD_sensorHoldTime: 137,
    CD_setpoint_pump: 138,
    CD_setpoint_generic: 354,
    
    CD_setting_1_threshold_light: 139,
    CD_setting_1_threshold_temp: 140,
    CD_setting_2_threshold_light: 141,
    CD_setting_2_threshold_temp: 142,
    CD_shade_end: 143,
    CD_shade_end_astroadjust: 144,
    CD_shade_start: 145,
    CD_shade_start_astroadjust: 146,
    CD_shock_protect_enabled: 147,
    CD_shock_protect_open_valve_perc: 148,
    CD_shock_protect_threshold: 149,
    CD_shock_protect_time_closed: 150,
    CD_shock_protect_water_sensor: 151,
    CD_soil_moisture_input: 152,
    CD_soil_moisture_threshold: 153,
    CD_stage: 154,
    CD_start_irr: 155,
    CD_start_hid: 290,
    CD_start_astroadjust_irr: 156,
    CD_start_astroadjust_co2: 286,
    CD_start_astroadjust_hid: 287,
    CD_start_time: 157,
    CD_start_time_1_irr: 158,
    CD_start_time_2_irr: 159,
    CD_start_time_3_irr: 160,
    CD_start_time_4_irr: 161,
    CD_start_time_5_irr: 162,
    CD_start_time_6_irr: 163,
    
    CD_start_time_1_hid: 326,
    CD_start_time_2_hid: 327,
    CD_start_time_3_hid: 328,
    CD_start_time_4_hid: 329,
    CD_start_time_5_hid: 330,
    CD_start_time_6_hid: 331,
    CD_supply_pump_mv_pz: 164,
    CD_supply_pump_generic: 349,
    CD_tankSensor_irr: 165,
    CD_tankSensor_fval: 292,
    CD_tankSize: 166,
    CD_temp_drive_to: 167,
    CD_temp_sensor: 168,
    CD_temp_threshold: 169,
    CD_threshold: 170,
    CD_time_vent: 171,
    CD_time_generic: 293,
    CD_triggerDelay: 172,
    CD_valve_open_time: 173,
    CD_vpdacc_threshold: 174,
    CD_water_temp_d: 175,
    CD_water_temp_i: 176,
    CD_water_temp_max: 177,
    CD_water_temp_min: 178,
    CD_water_temp_p: 179,
    CD_water_temp_sensor: 180,
    CD_week_a_fri_irr: 181,
    CD_week_a_mon_irr: 182,
    CD_week_a_sat_irr: 183,
    CD_week_a_sun_irr: 184,
    CD_week_a_thu_irr: 185,
    CD_week_a_tue_irr: 186,
    CD_week_a_wed_irr: 187,
    CD_week_b_fri_irr: 188,
    CD_week_b_mon_irr: 189,
    CD_week_b_sat_irr: 190,
    CD_week_b_sun_irr: 191,
    CD_week_b_thu_irr: 192,
    CD_week_b_tue_irr: 193,
    CD_week_b_wed_irr: 194,
    
    CD_week_a_fri_hid: 332,
    CD_week_a_mon_hid: 333,
    CD_week_a_sat_hid: 334,
    CD_week_a_sun_hid: 335,
    CD_week_a_thu_hid: 336,
    CD_week_a_tue_hid: 337,
    CD_week_a_wed_hid: 338,
    CD_week_b_fri_hid: 339,
    CD_week_b_mon_hid: 340,
    CD_week_b_sat_hid: 341,
    CD_week_b_sun_hid: 342,
    CD_week_b_thu_hid: 343,
    CD_week_b_tue_hid: 344,
    CD_week_b_wed_hid: 345,
    
    CD_triggers: 195,
    CD_smartInject: 196,
//     CD_vent_mode: 197,
    CD_P: 198,
    CD_I: 199,
    CD_D: 200,
    CD_acDeadband: 201,
    CD_acDoDelay: 202,
    CD_acPcnt_1_cvent: 294,
    CD_acPcnt_1_vent: 203,
    CD_acPcnt_2_cvent: 295,
    CD_acPcnt_2_vent: 204,
    CD_acPcnt_3_cvent: 296,
    CD_acPcnt_3_vent: 205,
    CD_acPcnt_4_cvent: 297,
    CD_acPcnt_4_vent: 206,
    CD_acTemp_1_cvent: 298,
    CD_acTemp_1_vent: 207,
    CD_acTemp_2_cvent: 299,
    CD_acTemp_2_vent: 208,
    CD_acTemp_3_cvent: 300,
    CD_acTemp_3_vent: 209,
    CD_acTemp_4_cvent: 301,
    CD_acTemp_4_vent: 210,
    CD_acUpDelay: 211,
    CD_autoCalibration: 212,
    CD_begin_closing_leeward_trigger_end: 213,
    CD_begin_closing_leeward_trigger_start: 214,
    CD_begin_closing_windward_trigger_end: 215,
    CD_begin_closing_windward_trigger_start: 216,
    CD_closing_delay: 217,
    //   CD_cool_1: 218,// these values are unused
    //   CD_cool_2: 219,
    //   CD_cool_3: 220,
    //   CD_cool_4: 221,
    //   CD_cool_5: 222,
    //   CD_cool_6: 223,
    CD_cp2Delay: 224,
    CD_cp2Pos: 225,
    CD_cp2Wind: 226,
    CD_cp3Delay: 227,
    CD_cp3Pos: 228,
    CD_cp3Wind: 229,
    CD_cpDelay: 230,
    CD_cpPos: 231,
    CD_cpWind: 232,
    CD_direction: 233,
    CD_fireDelay: 234,
    CD_fireIn: 235,
    CD_firePos: 236,
    CD_hailDelay: 237,
    CD_hailPos: 238,
    CD_hailThresh: 239,
    CD_hgDeadband: 240,
    CD_hgDoDelay: 241,
    CD_hgPcnt_1_cvent: 302,
    CD_hgPcnt_1_vent: 242,
    CD_hgPcnt_2_cvent: 303,
    CD_hgPcnt_2_vent: 243,
    CD_hgPcnt_3_cvent: 304,
    CD_hgPcnt_3_vent: 244,
    CD_hgPcnt_4_cvent: 305,
    CD_hgPcnt_4_vent: 245,
    CD_hgTemp_1_cvent: 306,
    CD_hgTemp_1_vent: 246,
    CD_hgTemp_2_cvent: 307,
    CD_hgTemp_2_vent: 247,
    CD_hgTemp_3_cvent: 308,
    CD_hgTemp_3_vent: 248,
    CD_hgTemp_4_cvent: 309,
    CD_hgTemp_4_vent: 249,
    CD_hgUpDelay: 250,
    CD_max_open_rain: 252,
    CD_max_open: 251,
    CD_open_var: 253,
    CD_rhgDeadband: 254,
    CD_rhgDoDelay: 255,
    CD_rhgTemp_1_cvent: 310,
    CD_rhgTemp_1_vent: 256,
    CD_rhgTemp_2_cvent: 311,
    CD_rhgTemp_2_vent: 257,
    CD_rhgTemp_3_cvent: 312,
    CD_rhgTemp_3_vent: 258,
    CD_rhgTemp_4_cvent: 313,
    CD_rhgTemp_4_vent: 259,
    CD_rhgUpDelay: 260,
    CD_rtDeadband: 261,
    CD_rtDoDelay: 262,
    CD_rtHum_1_cvent: 314,
    CD_rtHum_1_vent: 263,
    CD_rtHum_2_cvent: 315,
    CD_rtHum_2_vent: 264,
    CD_rtHum_3_cvent: 316,
    CD_rtHum_3_vent: 265,
    CD_rtHum_4_cvent: 317,
    CD_rtHum_4_vent: 266,
    CD_rtPcnt_1_cvent: 318,
    CD_rtPcnt_1_vent: 267,
    CD_rtPcnt_2_cvent: 319,
    CD_rtPcnt_2_vent: 268,
    CD_rtPcnt_3_cvent: 320,
    CD_rtPcnt_3_vent: 269,
    CD_rtPcnt_4_cvent: 321,
    CD_rtPcnt_4_vent: 270,
    CD_rtUpDelay: 271,
    CD_spDelay: 272,
    CD_spPos: 273,
    CD_spWind: 274,
    CD_temp_below_trigger: 275,
    CD_temp_var: 276,
    CD_thgPcnt_1_cvent: 322,
    CD_thgPcnt_1_vent: 277,
    CD_thgPcnt_2_cvent: 323,
    CD_thgPcnt_2_vent: 278,
    CD_thgPcnt_3_cvent: 324,
    CD_thgPcnt_3_vent: 279,
    CD_thgPcnt_4_cvent: 325,
    CD_thgPcnt_4_vent: 280,
    CD_vent_type: 281,
    CD_vps_alarm_duration: 282,
    CD_vps_alarm_limit: 283,
    CD_vps_error_exception_limit: 284,
    CD_vps_sensor: 285,
    
    CHC_zoneIndex: 1,
    CHC_unitIndex: 2,
    CHC_channelIndex: 3,
    CHC_unix_timestamp_modified: 4,
    CHC_used: 5,
    CHC_isAnalog: 6,
    CHC_channelName: 7,
    CHC_channelType: 8,
    CHC_channelData: 9,
    CHC_timedEnabled: 10,
    CHC_timedInterval: 11,
    CHC_timedStartTime: 12,
    CHC_timedEndTime: 13,
    CHC_timedOutput: 14,
    CHC_stageDehumidfy1: 15,
    CHC_stageDehumidfy2: 16,
    CHC_stageColdDehumidfy: 17,
    CHC_stageHumidfy: 18,
    CHC_expansionFailSafeOutput: 19,
    CHC_userComment: 20,
    
    // snapshot_channels
    SNC_snapshotId: 1,
    SNC_channelIndex: 2,
    SNC_channelData: 3,
    SNC_position: 4,
    SNC_relay: 5,
    SNC_channelOverride: 6,
    SNC_analogOutput_varout: 7,
    SNC_analogOutput_mixv: 21,
    SNC_analogOutput_generic: 22,
    
    SNC_co2Setpoint: 8,
    SNC_microzoneSetpoint: 9,
    SNC_proportionalZoneLowSetpoint: 10,
    SNC_proportionalZoneHighSetpoint: 11,
    SNC_pumpPeristalticSetpoint: 12,
    SNC_pumpPeristalticTankLevel: 13,
    SNC_pumpPeristalticInjectedMilliliters: 14,
    SNC_pumpPeristalticInjectedSeconds: 15,
    SNC_lightDailyLightIntegral: 16,
    SNC_auxOutputStatus: 17,
    SNC_auxOutputValue: 18,
    SNC_auxEquipmentStatus: 19,
    SNC_auxEquipmentValue: 20,
    
    // snapshot_chan_Snapshot
    SNCD_accumulatedLight: 1,
    SNCD_accumulator: 2,
    SNCD_accvpd: 3,
    SNCD_activatedTimes: 4,
    SNCD_activatedTimes_1: 49,
    SNCD_activatedTimes_2: 50,
    SNCD_activatedTimes_3: 51,
    SNCD_activatedTimes_4: 52,
    SNCD_activatedTimes_5: 53,
    SNCD_activatedTimes_6: 54,
    
    SNCD_activeCount: 5,
    SNCD_auxControl: 6,
    SNCD_coolDemand: 7,
    SNCD_coolDemandPassive: 8,
    SNCD_currentSetpoint: 9,
    SNCD_cycleOffTimeRemains: 10,
    SNCD_cycleOnTimeRemains: 11,
    SNCD_cycleState: 12,
    SNCD_dailyLightIntegral: 13,
    SNCD_driveToAverage: 14,
    SNCD_ecphs: 15,
    SNCD_equipment: 16,
    SNCD_expansionBoardStatus: 17,
    SNCD_heatDemand: 18,
    SNCD_highAlarm: 19,
    SNCD_highInTemperatureAlarm: 20,
    SNCD_humidityStage: 21,
    SNCD_injectedVolumeMLiters: 22,
    SNCD_injectedVolumeSeconds: 23,
    SNCD_inQueue: 24,
    SNCD_inTemperatureSensorAlarm: 25,
    SNCD_inWindow: 26,
    SNCD_irrigationWeek: 27,
    SNCD_isCycling: 28,
    SNCD_isPulseOn: 29,
    SNCD_lowAlarm: 30,
    SNCD_lowInTemperatureAlarm: 31,
    SNCD_offTime_mixv: 32,
    //   SNCD_offTime_mixva: 55,
    
    SNCD_originallyOn: 33,
    SNCD_output: 34,
    SNCD_pdTimer: 35,
    SNCD_peristalticState: 36,
    SNCD_sensors: 37,
    SNCD_setpoint: 38,
    SNCD_setpointHigh: 39,
    SNCD_setpointLow: 40,
    SNCD_siteChanged: 41,
    SNCD_tankLevelLiters: 42,
    SNCD_tankSizeLiters: 43,
    SNCD_temperatureStage: 44,
    SNCD_timestamp: 45,
    SNCD_unitTime: 46,
    SNCD_pdTimerState: 47,
    SNCD_week: 48,
    
    
    // TODO -- snapshot_aux assign to zero
    // pi[1800].snapshot_aux_alarms["alarmValue: 0;;// 1024
    // pi[1800].snapshot_aux_persistent_variables["variableValue: 0;;// 1056
    // pi[1800].snapshot_aux_variables["variableValue: 0;;// 1056
    // pi[1800].snapshot_expansion_boards["boardStatus: 0;;// 1056
    
    // config_aux_alarm
    CAA_alarmIndex: 0,
    CAA_alarmName: 1,
    CAA_allowGraphing: 2,
    CAA_userComment: 3,
    
    // config_aux_controls
    // CAC_auxIndex: 0,
    // CAC_unix_timestamp_modified: 1,
    CAC_operand1Type: 2,
    CAC_operand1Value: 3,
    CAC_operand2Type: 4,
    CAC_operand2Value: 5,
    CAC_operand3Type: 6,
    CAC_operand3Value: 7,
    CAC_operator1: 8,// has both 4 bit values from operators field
    //   CAC_operator2: 9,
    CAC_conditionSeconds: 10,
    CAC_action: 11,
    CAC_targetType: 12,
    CAC_targetValue: 13,
    CAC_actionParameter: 14,
    CAC_actionHoldTime: 15,
    CAC_userComment: 16,
    
    // config_aux_persistent_variables
    CAPV_variableIndex: 0,
    CAPV_variableName: 1,
    CAPV_allowGraphing: 2,
    CAPV_userComment: 3,
    
    // config_aux_variables
    CAV_variableIndex: 0,
    CAV_variableName: 1,
    CAV_allowGraphing: 2,
    CAV_userComment: 3,
    
    // config_expansion_boards
    CEB_boardIndex: 0, // 64
    CEB_unix_timestamp_modified: 1,
    CEB_boardType: 2,
    CEB_address: 3,
    CEB_startChannelIndex: 4,
    CEB_numOutputs: 5,
    
    // config_zones
    CZ_name: 0,
    CZ_description: 1,
    
    // config_communication_status
    CCS_statusID: 0,
    CCS_unix_timestamp_statusTime: 1,
    CCS_statusLog: 2,
    
    CES_ecMapping:	1,
    CES_ecServiceIntervalDays:	2,
    CES_ecServiceTime:	3,
    CES_ecCalibrationIntervalDays:	4,
    CES_ecCalibrationTime:	5,
    
    CES_ecCalibration1Value:	6,
    CES_ecCalibration1Raw:	7,
    CES_ecCalibration2Value:	8,
    CES_ecCalibration2Raw:	9,
    
    CES_phMapping:	10,
    CES_phServiceIntervalDays:	11,
    CES_phServiceTime:	12,
    CES_phCalibrationIntervalDays:	13,
    CES_phCalibrationTime:	14,
    
    CES_phCalibration1Value:	15,
    CES_phCalibration1Raw:	16,
    CES_phCalibration2Value:	17,
    CES_phCalibration2Raw:	18,
    CES_temperatureMapping:	19,
    CES_temperatureCalibration:	20,
    CES_temperatureCompensationMode:	21,
    CES_temperatureCompensationValue:	22,
    
    CE_name:	2,
    CE_ecType:	3,
    CE_alarmHoldTime:	4,
    CE_lowECThreshold:	5,
    CE_highECThreshold:	6,
    CE_highECDeviationThreshold:	7,
    CE_lowPHThreshold:	8,
    CE_highPHThreshold:	9,
    CE_highPHDeviationThreshold:	10,

    MSG_CMD_NACK: -1,
    MSG_CMD_ACK: 0,
    MSG_CMD_WRITE_SPECIFIC_DEVICE_CONFIG: 1,
    MSG_CMD_READ_SPECIFIC_DEVICE_CONFIG: 2,
    MSG_CMD_REPORT_CHANGED_DEVICE_CONFIG: 3,
    MSG_CMD_READ_SPECIFIC_DEVICE_STATUS: 4,
    MSG_CMD_REPORT_CHANGED_DEVICE_STATUS: 5,
    MSG_CMD_WRITE_SPECIFIC_AUX_CONFIG: 6,
    MSG_CMD_READ_SPECIFIC_AUX_CONFIG: 7,
    MSG_CMD_REPORT_CHANGED_AUX_CONFIG: 8,
    MSG_CMD_READ_SPECIFIC_AUX_STATUS: 9,
    MSG_CMD_REPORT_CHANGED_AUX_STATUS: 10,

    GATEWAY_FLAG_NEW_SITE: 0x0001,
    GATEWAY_FLAG_CONFIRM_UID: 0x0002,
    GATEWAY_FLAG_BAD_UID: 0x0004,
    
  };
// begin of "test vector" that goes through all the controller values  
/*these have a number of types:
a value by itself means just use the value
type 1: followed by an int, use all ix values up to int
type 2: use ch and ix values up to ints 1, and 2 that follow
type 3: use following int as count for ix values, each indexed by multiplying from l2c table
type 4: ecph index ch, ix by following 2 ints
type 5: index ch and ix by following 2 ints - ecph
type 6: index ch by following int
type 7: index ch and ix by following two ints
 */
  var tc = [
  // setup_t
  lc.setup_t_CoolStages,
  lc.setup_t_HeatStages,
  lc.setup_t_StageWidth,
  lc.setup_t_RainHoldTime,
  lc.setup_t_CmdDelaySec,
  lc.setup_t_CoolDeadband,
  lc.setup_t_HeatDeadband,
  lc.setup_t_HumDeadband,
  [lc.setup_t_InputMap, 1, 64],
  [lc.setup_t_Accumulator_t_id, 2, 2, 2],
  lc.setup_t_DaylightSavings,
  lc.setup_t_TempUnits,
  lc.setup_t_WindUnits,
  lc.setup_t_LiteUnits,
  lc.setup_t_Latitude,
  lc.setup_t_Longitude,
  //   lc.setup_t_TimeZone,
  lc.setup_t_fb_enable,
  lc.setup_t_fb_stage,
  //   lc.setup_t_Password,
  lc.setup_t_fb_enable_hi,
  lc.setup_t_CalInTemp,
  lc.setup_t_CalOutTemp,
  lc.setup_t_CalInHum,
  lc.setup_t_CalCo2,
  lc.setup_t_CalLite,
  lc.setup_t_CalWind,
  lc.setup_t_CalWindDir,
  // TODO not mapping to a table like type 1 should?
  [lc.setup_t_CalBHeat, 1, 5],
  [lc.setup_t_CalSoil, 1, 5],
  lc.setup_t_CalFallBack,
  [lc.setup_t_CalVOpen, 1, 5],
  [lc.setup_t_CalVClose, 1, 5],
  [lc.setup_t_CalVPos, 1, 5],
  lc.setup_t_CalDiffP,
  lc.setup_t_CalInLight,
  lc.setup_t_IrrCntl,
  lc.setup_t_IrrDelaySec,
  lc.setup_t_hist_mm,
  lc.setup_t_BHeatDeadband,
  lc.setup_t_remoteSetp,
  lc.setup_t_eq_cycle_sec,
  lc.setup_t_EnergyLightHyst,
  lc.setup_t_EnergyTempHyst,
  lc.setup_t_CO2_LSB,
  lc.setup_t_smart_cool_enable,
  lc.setup_t_smart_cool_number,
  [lc.setup_t_sc_t_KU, 1, 10],
  [lc.setup_t_sc_t_Gmax, 1, 10],
  [lc.setup_t_sc_t_Kd, 1, 10],
  [lc.setup_t_sc_t_Gmin, 1, 10],
  lc.setup_t_exbFlg,
  lc.setup_t_htdDT,
  lc.setup_t_htdMaxLight,
  lc.setup_t_htdMaxWind,
  lc.setup_t_htdLightFactor,
  lc.setup_t_htdWindFactor,
  lc.setup_t_htdShow,
  lc.setup_t_cldDT,
  lc.setup_t_cldMaxLight,
  lc.setup_t_cldLightFactor,
  lc.setup_t_cldActiveSet,
  lc.setup_t_cldPassiveSet,
  lc.setup_t_cldEnableActive,
  lc.setup_t_cldPassiveLockStg,
  lc.setup_t_cldShow,
  lc.setup_t_bump,
  lc.setup_t_AutoDetectMode,
  lc.setup_t_htdOffset,
  lc.setup_t_cldOffset,
  lc.setup_t_sns_wait_cnt,
  lc.setup_t_HaltOnVentCurt,
  lc.setup_t_tempSpikeThresh,
  lc.setup_t_tempSpikeSec,
  lc.setup_t_lightMultipler,
  [lc.setup_t_genericMult, 1, 2],
  [lc.setup_t_genericCal, 1,  2],
  [lc.setup_t_genericUnits, 1, 2],
  [lc.setup_t_genericRange, 1, 2],
  [lc.setup_t_pidMultiplier, 1, 3],
  [lc.setup_t_filter_cfg_t_max_samples, 1, 2],
  lc.setup_t_CalOutHum,
  lc.setup_t_d2avg_enable,
  lc.setup_t_d2avg_target,
  lc.setup_t_d2avg_maxFailDays,
  lc.setup_t_d2avg_successTemp,
  lc.setup_t_d2avg_maxTempDiff,
  lc.setup_t_tmInput,
  lc.setup_t_hidCntl,
  lc.setup_t_hidInitPauseMin,
  lc.setup_t_hidActiveMin,
  lc.setup_t_hidFinalPauseMin,
  lc.setup_t_hidMinOnMin,
  
  // TODO these type 2s get redefined in l2c2l
  [lc.setup_t_InfluenceFactor_AC, 2, 2, 2],// 2, 2, 2 means type 2, 2 ch (0, 1), and 2 ix (0, 1)
  
  // lc.setup_t_InfluenceFactor_AC]=[p.PID_BASE_CONFIG_ZONE_SETTINGS, 2, [[], []]];
  // lc.setup_t_InfluenceFactor_AC][2][0][0]=lc.ZC_Heat_Setpoint_Influence_Factor_Upper_Threshold;
  // lc.setup_t_InfluenceFactor_AC][2][0][1]=lc.ZC_Heat_Setpoint_Influence_Factor_Lower_Threshold;
  // lc.setup_t_InfluenceFactor_AC]=[p.PID_BASE_CONFIG_ZONE_SETTINGS, 2, [[], []]];
  // lc.setup_t_InfluenceFactor_AC][2][1][0]=lc.ZC_Cool_Setpoint_Influence_Factor_Upper_Threshold;
  // lc.setup_t_InfluenceFactor_AC][2][1][1]=lc.ZC_Cool_Setpoint_Influence_Factor_Lower_Threshold;
  
  [lc.setup_t_InfluenceFactor_AC_type, 2, 2, 1],
  // lc.setup_t_InfluenceFactor_AC_type]=[p.PID_BASE_CONFIG_ZONE_SETTINGS, 2, [[]]];
  // lc.setup_t_InfluenceFactor_AC_type][2][0][0]=lc.ZC_Heat_Setpoint_Influence_Factor_Sensor;
  // lc.setup_t_InfluenceFactor_AC_type]=[p.PID_BASE_CONFIG_ZONE_SETTINGS, 2, [[]]];
  // lc.setup_t_InfluenceFactor_AC_type][2][0]=lc.ZC_Heat_Setpoint_Influence_Factor_Sensor;
  // lc.setup_t_InfluenceFactor_AC_type][2][1]=lc.ZC_Cool_Setpoint_Influence_Factor_Sensor;
  
  [lc.setup_t_InfluenceFactor_enable, 2, 2 ,1],
  // lc.setup_t_InfluenceFactor_enable]=[p.PID_BASE_CONFIG_ZONE_SETTINGS, 2, [[]]];
  // lc.setup_t_InfluenceFactor_enable][2][0][0]=lc.ZC_Heat_Setpoint_Influence_Factor_Enable;
  // lc.setup_t_InfluenceFactor_enable]=[p.PID_BASE_CONFIG_ZONE_SETTINGS, 2, [[], []]];
  // lc.setup_t_InfluenceFactor_enable][2][1][0]=lc.ZC_Cool_Setpoint_Influence_Factor_Enable;
  
  [lc.setup_t_InfluenceFactor_TempSPOffset, 2, 2, 2],
  // lc.setup_t_InfluenceFactor_TempSPOffset]=[p.PID_BASE_CONFIG_ZONE_SETTINGS, 2, [[], []]];
  // lc.setup_t_InfluenceFactor_TempSPOffset][2][0][0]=lc.ZC_Heat_Setpoint_Influence_Factor_Upper_Offset;
  // lc.setup_t_InfluenceFactor_TempSPOffset][2][0][1]=lc.ZC_Heat_Setpoint_Influence_Factor_Lower_Offset;
  // lc.setup_t_InfluenceFactor_TempSPOffset]=[p.PID_BASE_CONFIG_ZONE_SETTINGS, 2, [[], []]];
  // lc.setup_t_InfluenceFactor_TempSPOffset][2][1][0]=lc.ZC_Cool_Setpoint_Influence_Factor_Upper_Offset;
  // lc.setup_t_InfluenceFactor_TempSPOffset][2][1][1]=lc.ZC_Cool_Setpoint_Influence_Factor_Lower_Offset;
  
  lc.setup_t_ZoneDelaySec,
  lc.setup_t_SetupFlags,
  lc.setup_t_bOutExpEnable,
  lc.setup_t_nutrientsUnits,
  lc.setup_t_tdsConversionFactor,
  lc.setup_t_peristalticCntl,
  lc.setup_t_peristalticDelaySec,
  lc.setup_t_volumeUnits,
  lc.setup_t_inLightMultiplier,
  lc.setup_t_advTrigger,
  lc.setup_t_CalRain,
  lc.setup_t_CalSnow,
  lc.setup_t_CalOutTemp2,
  lc.setup_t_CalPressure,
  
  // configPartial_t_ovr_t
  [lc.configPartial_t_ovr_t_enable, 1, 4],
  [lc.configPartial_t_ovr_t_force, 1, 4],
  [lc.configPartial_t_ovr_t_start, 1, 4],
  [lc.configPartial_t_ovr_t_end, 1, 4],
  [lc.configPartial_t_ovr_t_sched, 1, 4],
  
  // alarm_t
  lc.configPartial_t_alarm_t_hitempoffset,
  lc.configPartial_t_alarm_t_hitemptime,
  lc.configPartial_t_alarm_t_lotempoffset,
  lc.configPartial_t_alarm_t_lotemptime,
  
  // humdehum_t
  lc.configPartial_t_humdehum_t_heat_boost,
  lc.configPartial_t_humdehum_t_dhlt_thresh,
  lc.configPartial_t_humdehum_t_dh_maxon_sec,
  lc.configPartial_t_humdehum_t_dh_minoff_sec,
  
  // setpoint_t
  // TODO type 3 ?
  [lc.setpoint_t_enable, 3, 8],
  [lc.setpoint_t_startmode, 3, 8],
  [lc.setpoint_t_starttime, 3, 8],
  [lc.setpoint_t_ramp, 3, 8],
  [lc.setpoint_t_cool, 3, 8],
  [lc.setpoint_t_heat, 3, 8],
  [lc.setpoint_t_dehum, 3, 8],
  [lc.setpoint_t_hum, 3, 8],
  
  [lc.xboard_t_numOutputs, 3, 64],
  [lc.xboard_t_type, 3, 64],
  [lc.xboard_t_addr, 3, 64],
  [lc.xboard_t_startOutput, 3, 64],
  
  [lc.ecphConfig_t_ecphSensor_t_ecMapping, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_pHMapping, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_tempMapping, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_tempCompensationMode, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_tempCompensationValx10, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_ecServiceIntervalDays, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_ecCalibrationIntervalDays, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_ecServiceTime, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_ecCalibrationTime, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_phServiceIntervalDays, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_phCalibrationIntervalDays, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_phServiceTime, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_phCalibrationTime, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_calECRef1Val, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_calECRef1Readingx10, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_calECRef2Val, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_calECRef2Readingx10, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_calPHRef1Valx10, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_calPHRef1Readingx100, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_calPHRef2Valx10, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_calPHRef2Readingx100, 5, 8, 3],// ecph +192, and ix 0-2
  [lc.ecphConfig_t_ecphSensor_t_calTempx10, 5, 8, 3],// ecph +192, and ix 0-2
  
  lc.ecphConfig_t_ecType,
  lc.ecphConfig_t_alarmHoldTimeMinutes,
  lc.ecphConfig_t_lowECThreshold,
  lc.ecphConfig_t_highECThreshold,
  lc.ecphConfig_t_lowPHThresholdx10,
  lc.ecphConfig_t_highPHThresholdx10,
  lc.ecphConfig_t_maxECDevThreshold,
  lc.ecphConfig_t_maxPHDevThresholdx10,
  
  // irr_t
  // TODO type 6 ?
  lc.chnl_t_irr_t_mode,
  lc.chnl_t_irr_t_on_ss,
  [lc.chnl_t_irr_t_scheduledTime, 1, 6],
  [lc.chnl_t_irr_t_scheddays, 1, 14],
  
  lc.chnl_t_irr_t_startastro,
  lc.chnl_t_irr_t_startWindowTime,
  lc.chnl_t_irr_t_endastro,
  lc.chnl_t_irr_t_endWindowTime,
  lc.chnl_t_irr_t_accl_litemin,
  lc.chnl_t_irr_t_accl_tempmin,
  lc.chnl_t_irr_t_accl_litemax,
  lc.chnl_t_irr_t_accl_tempmax,
  lc.chnl_t_irr_t_maxoffmm,
  lc.chnl_t_irr_t_soilInput,
  lc.chnl_t_irr_t_soilThreshold,
  lc.chnl_t_irr_t_vpdacc_thresh,
  lc.chnl_t_irr_t_tankSensor,
  
  // vent_t
  lc.chnl_t_vent_t_opensec,
  lc.chnl_t_vent_t_facing,
  lc.chnl_t_vent_t_wwmin,
  lc.chnl_t_vent_t_wwmax,
  lc.chnl_t_vent_t_lwmin,
  lc.chnl_t_vent_t_lwmax,
  lc.chnl_t_vent_t_holdtime,
  lc.chnl_t_vent_t_rainopen,
  lc.chnl_t_vent_t_LoTempOutT,
  lc.chnl_t_vent_t_LoTempMaxOpen,
  lc.chnl_t_vent_t_HiTempOutT,
  lc.chnl_t_vent_t_HiTempMinOpen,
  lc.chnl_t_vent_t_vpsSensorID,
  lc.chnl_t_vent_t_vpsMaxErr,
  lc.chnl_t_vent_t_vpsTimeout,
  lc.chnl_t_vent_t_vpsIgnore,
  lc.chnl_t_vent_t_bVPosAutoCal,
  
  // TODO ActiveCoolStageOvr overwritten ?
  // cl2c[lc.chnl_t_vent_t_ActiveCoolStageOvr]=[p.PID_BASE_CONF_CHAN_DATA;
  // cl2c[lc.chnl_t_vent_t_ActiveCoolStageOvr]=[p.PID_BASE_CONF_CHAN_DATA, 7, []];
  [lc.chnl_t_vent_t_ActiveCoolStageOvr, 1, 6],
  
  lc.chnl_t_vent_t_ventType,
  [lc.chnl_t_vent_t_setpointList_wbPcnt, 1, 4],
  [lc.chnl_t_vent_t_setpointList_ofPcnt, 1, 4],
  [lc.chnl_t_vent_t_setpointList_shPcnt, 1, 4],
  [lc.chnl_t_vent_t_setpointList_hiPcnt, 1, 4],
  [lc.chnl_t_vent_t_setpointList_hiHum, 1, 4],
  [lc.chnl_t_vent_t_setpointList_wbTemp, 1, 4],
  [lc.chnl_t_vent_t_setpointList_ofTemp, 1, 4],
  [lc.chnl_t_vent_t_setpointList_shTemp, 1, 4],
  
  // chnl_t
  lc.chnl_t_chnl,
  lc.chnl_t_eq_type,
  lc.chnl_t_name,
  [lc.chnl_t_stage, 1, 13],
  
  lc.chnl_t_ovr_t_enable,
  lc.chnl_t_ovr_t_force,
  lc.chnl_t_ovr_t_start,
  lc.chnl_t_ovr_t_end,
  lc.chnl_t_ovr_t_sched,
  lc.chnl_t_ch_hdh_t_dh1,
  lc.chnl_t_ch_hdh_t_dh2,
  lc.chnl_t_ch_hdh_t_dhlt,
  lc.chnl_t_ch_hdh_t_h,
  lc.chnl_t_P,
  lc.chnl_t_I,
  lc.chnl_t_D,
  
  // curt_t
  lc.chnl_t_curt_t_opensec,
  //   lc.chnl_t_curt_ShadeCloseMode,
  lc.chnl_t_curt_t_ShadeCloseMode,
  
  lc.chnl_t_curt_t_ShadeOpenMode,
  lc.chnl_t_curt_t_ShadeCloseTime,
  lc.chnl_t_curt_t_ShadeOpenTime,
  lc.chnl_t_curt_t_ShadeMaxClose,
  lc.chnl_t_curt_t_ShadeCloseAbove,
  lc.chnl_t_curt_t_ShadeOutT,
  lc.chnl_t_curt_t_ShadeOpenBelow,
  lc.chnl_t_curt_t_ShadeOpenDelay,
  lc.chnl_t_curt_t_EnergyCloseMode,
  lc.chnl_t_curt_t_EnergyOpenMode,
  lc.chnl_t_curt_t_EnergyCloseTime,
  lc.chnl_t_curt_t_EnergyOpenTime,
  lc.chnl_t_curt_t_EnergyLiteThresh,
  lc.chnl_t_curt_t_EnergyTempThresh,
  lc.chnl_t_curt_t_MaxStagePcnt,
  lc.chnl_t_curt_t_MaxAtStage,
  lc.chnl_t_curt_t_ShockProtect,
  
  // co2_t
  lc.chnl_t_co2_t_startastro,
  lc.chnl_t_co2_t_starttime,
  lc.chnl_t_co2_t_endastro,
  lc.chnl_t_co2_t_endtime,
  lc.chnl_t_co2_t_co2_min,
  lc.chnl_t_co2_t_co2_max,
  lc.chnl_t_co2_t_litemin,
  lc.chnl_t_co2_t_lockstage,
  
  // hid_t
  lc.chnl_t_hid_t_startastro,
  lc.chnl_t_hid_t_starttime,
  lc.chnl_t_hid_t_endastro,
  lc.chnl_t_hid_t_endtime,
  lc.chnl_t_hid_t_lite_on,
  lc.chnl_t_hid_t_lite_off,
  lc.chnl_t_hid_t_offdelay,
  lc.chnl_t_hid_t_use_sensor,
  lc.chnl_t_hid_t_mode,
  [lc.chnl_t_hid_t_scheduledTime, 1, 6],
  [lc.chnl_t_hid_t_scheduleddays, 1, 14],
  lc.chnl_t_hid_t_DLIsensor,
  lc.chnl_t_hid_t_DLIthreshold,
  lc.chnl_t_hid_t_DLIstartime,
  lc.chnl_t_hid_t_DLIontime,
  lc.chnl_t_hid_t_DLIendtime,
  lc.chnl_t_hid_t_DLIoutput,
  
  
  // mzone_t
  lc.chnl_t_mzone_t_day_temp,
  lc.chnl_t_mzone_t_day_enable,
  lc.chnl_t_mzone_t_day_start,
  lc.chnl_t_mzone_t_day_astro,
  lc.chnl_t_mzone_t_day_lock,
  lc.chnl_t_mzone_t_nite_temp,
  lc.chnl_t_mzone_t_nite_enable,
  lc.chnl_t_mzone_t_nite_start,
  lc.chnl_t_mzone_t_nite_astro,
  lc.chnl_t_mzone_t_nite_lock,
  lc.chnl_t_mzone_t_sensor,
  lc.chnl_t_mzone_t_mode,
  lc.chnl_t_mzone_t_pump,
  
  //mv_pz_t
  lc.chnl_t_mv_pz_t_minT,
  lc.chnl_t_mv_pz_t_maxT,
  lc.chnl_t_mv_pz_t_P,
  lc.chnl_t_mv_pz_t_I,
  lc.chnl_t_mv_pz_t_D,
  lc.chnl_t_mv_pz_t_sensor,
  lc.chnl_t_mv_pz_t_opensec,
  lc.chnl_t_mv_pz_t_ss,
  lc.chnl_t_mv_pz_t_InTempDisable,
  lc.chnl_t_mv_pz_t_pump,
  lc.chnl_t_mv_pz_t_retenable,
  lc.chnl_t_mv_pz_t_retsensor,
  lc.chnl_t_mv_pz_t_retthresh,
  lc.chnl_t_mv_pz_t_retopen,
  lc.chnl_t_mv_pz_t_rettime,
  lc.chnl_t_mv_pz_t_airsensor,
  lc.chnl_t_mv_pz_t_heat,
  lc.chnl_t_mv_pz_t_cool,
  lc.chnl_t_mv_pz_t_bCoolDevice,
  lc.chnl_t_mv_pz_t_fineTempRange,
  lc.chnl_t_mv_pz_t_aval_1,
  lc.chnl_t_mv_pz_t_aval_2,
  lc.chnl_t_mv_pz_t_bPassiveCool,
  
  // pump_t
  lc.chnl_t_pump_t_type,
  lc.chnl_t_pump_t_Thresh,
  lc.chnl_t_pump_t_id,
  lc.chnl_t_pump_t_ecph_probeType,
  lc.chnl_t_pump_t_ecph_probeID,
  lc.chnl_t_pump_t_ecph_increase,
  lc.chnl_t_pump_t_ecph_setpoint,
  lc.chnl_t_pump_t_ecph_deadband,
  lc.chnl_t_pump_t_ecph_lite,
  lc.chnl_t_pump_t_ecph_lite_drive_to,
  lc.chnl_t_pump_t_ecph_tempsensor,
  lc.chnl_t_pump_t_ecph_temp,
  lc.chnl_t_pump_t_ecph_temp_drive_to,
  lc.chnl_t_pump_t_ecph_ontime,
  lc.chnl_t_pump_t_ecph_offtime,
  lc.chnl_t_pump_t_ss_off_delay,
  lc.chnl_t_pump_t_feedingMode,
  lc.chnl_t_pump_t_followChannelIdx,
  lc.chnl_t_pump_t_scalingFactor,
  lc.chnl_t_pump_t_tankSizeLiter,
  lc.chnl_t_pump_t_flowRateMLiterPermInute,
  lc.chnl_t_pump_t_mixingTime,
  lc.chnl_t_pump_t_injectingVolume,
  lc.chnl_t_pump_t_limitInjectedVolumePerHour,
  lc.chnl_t_pump_t_injectingTime,
  lc.chnl_t_pump_t_limitInjectedTimePerHour,
  lc.chnl_t_pump_t_limitTime,
  lc.chnl_t_pump_t_resetInjection,
  lc.chnl_t_pump_t_pumpMeasurementMode,
  lc.chnl_t_pump_t_enableSmart,
  [lc.chnl_t_pump_t_smartLevels_minDifferencex10, 3, 5],
  [lc.chnl_t_pump_t_smartLevels_ratiox100, 3, 5],
  [lc.chnl_t_pump_t_trigStartType, 3, 3],
  [lc.chnl_t_pump_t_trigStartIDX, 3, 3],
  [lc.chnl_t_pump_t_batchVolume, 3, 3],
  [lc.chnl_t_fval_t_trigStartType, 3, 3],
  [lc.chnl_t_fval_t_trigStartIDX, 3, 3],
  [lc.chnl_t_fval_t_trigStopType, 3, 3],
  [lc.chnl_t_fval_t_trigStopIDX, 3, 3],
  [lc.chnl_t_fval_t_timeSS, 3, 3],
  [lc.chnl_t_fval_t_volume, 3, 3],
  
  lc.chnl_t_pump_t_sensorHoldTime,
  lc.chnl_t_pump_t_injectBatchConcentration,
  lc.chnl_t_pump_t_injectBatchTime,
  lc.chnl_t_pump_t_activeTrigger,
  lc.chnl_t_pump_t_delayStartSS,
  
  // fval
  // TODO triggers
  lc.chnl_t_fval_t_activeTrigger,
  lc.chnl_t_fval_t_tankeSensor,
  
  // mz2
  
  // htdem
  
  // cldem
  
  // onoff_t
  lc.chnl_t_onoff_t_pump,
  lc.chnl_t_onoff_t_bCycle,
  lc.chnl_t_onoff_t_cycleOn,
  lc.chnl_t_onoff_t_cycleOff,
  lc.chnl_t_onoff_t_bActiveCoolOverrideEnable,
  [lc.chnl_t_onoff_t_activeCoolStageOvr, 1, 13],
  
  // varout_t
  lc.chnl_t_varout_t_vc,
  lc.chnl_t_varout_t_func,
  lc.chnl_t_varout_t_maxf,
  lc.chnl_t_varout_t_minf,
  lc.chnl_t_varout_t_maxi,
  lc.chnl_t_varout_t_mini,
  lc.chnl_t_varout_t_maxout,
  lc.chnl_t_varout_t_minout,
  lc.chnl_t_varout_t_offset,
  lc.chnl_t_varout_t_gain,
  
  // generic_t
  lc.chnl_t_generic_t_type,
  lc.chnl_t_generic_t_sec,
  lc.chnl_t_generic_t_analog00P,
  lc.chnl_t_generic_t_analog99P,
  lc.chnl_t_generic_t_sensor,
  lc.chnl_t_generic_t_setpoint,
  lc.chnl_t_generic_t_orSensor,
  lc.chnl_t_generic_t_orCompOp,
  lc.chnl_t_generic_t_orThreshold,
  lc.chnl_t_generic_t_orValM,
  lc.chnl_t_generic_t_orValPercent,
  lc.chnl_t_generic_t_pump,
  lc.chnl_t_generic_t_ss,
  
  // snapshot values
  lc.snapshot_t_sensors_t_InTemp,
  lc.snapshot_t_sensors_t_OutTemp,
  lc.snapshot_t_sensors_t_InHum,
  lc.snapshot_t_sensors_t_co2,
  lc.snapshot_t_sensors_t_OutHum,
  lc.snapshot_t_sensors_t_Light,
  lc.snapshot_t_sensors_t_WindSpd,
  lc.snapshot_t_sensors_t_WindDir,
  lc.snapshot_t_sensors_t_Rain,
  lc.snapshot_t_sensors_t_FallbackInTemp,
  [lc.snapshot_t_sensors_t_BHeat, 1, 5],
  [lc.snapshot_t_sensors_t_Soil, 1, 5],
  [lc.snapshot_t_sensors_t_VentPos, 1, 5],
  [lc.snapshot_t_sensors_t_Generic, 1, 2],
  lc.snapshot_t_sensors_t_localTemp,
  lc.snapshot_t_sensors_t_localHum,
  lc.snapshot_t_sensors_t_diffp,
  lc.snapshot_t_sensors_t_InLight,
  lc.snapshot_t_sensors_t_Snow,
  lc.snapshot_t_sensors_t_OutTemp2,
  lc.snapshot_t_sensors_t_Pressure,
  
  // TODO type 4
  [lc.snapshot_t_ecph_sensors_t_phx100, 4, 8, 3],// ch selects tank, ix sensor
  [lc.snapshot_t_ecph_sensors_t_ecx10, 4, 8, 3],
  [lc.snapshot_t_ecph_sensors_t_tempx10, 4, 8, 3],
  
  lc.snapshot_t_setp_stat_t_cool,
  lc.snapshot_t_setp_stat_t_heat,
  lc.snapshot_t_setp_stat_t_hum,
  lc.snapshot_t_setp_stat_t_dehum,
  lc.snapshot_t_stage,
  lc.snapshot_t_hdhstage,
  lc.snapshot_t_htd,
  lc.snapshot_t_cld,
  [lc.snapshot_t_d2avg_t_avg, 1, 2],
  lc.snapshot_t_d2avg_t_full_avg,
  lc.snapshot_t_d2avg_t_failDays,
  [lc.snapshot_t_accum, 1, 2],
  
  // TODO type 3
  [lc.snapshot_t_xcomm_status, 3, 64],
  [lc.snapshot_t_ecphAlarms, 5, 8, 3],// there are 8 channels to go through, and 3 sensors on each (ch, ix)
  //   lc.snapshot_t_tempAlarms
  lc.eqrtd_t_pos,
  lc.eqrtd_t_ovr,
  [lc.eqrtd_t_eqrtd_irr_t_irrSched, 1, 5],
  lc.eqrtd_t_eqrtd_irr_t_inWindow,
  lc.eqrtd_t_eqrtd_irr_t_inqueue,
  lc.eqrtd_t_eqrtd_irr_t_accl,
  lc.eqrtd_t_eqrtd_irr_t_accvpd,
  lc.eqrtd_t_eqrtd_mixv_t_offtime,
  lc.eqrtd_t_eqrtd_hid_t_DLIaccum,
  lc.eqrtd_t_eqrtd_varout_t_cal,
  
  lc.eqrtd_t_eqrtd_pump_t_totalInjectedTime,
  lc.eqrtd_t_eqrtd_pump_t_totalInjectedVolume,
  lc.eqrtd_t_eqrtd_pump_t_currSetpoint,
  lc.eqrtd_t_eqrtd_pump_t_peristalticState,
  lc.eqrtd_t_eqrtd_mixva_t_offtime,
  lc.eqrtd_t_eqrtd_mixva_t_curr_va,
  
  lc.eqrtd_t_eqrtd_gen_pid_t_curr_va,
  lc.eqrtd_t_eqrtd_gen_pid_t_pulseOn,
  lc.eqrtd_t_eqrtd_onoff_t_ontime_remaining,
  lc.eqrtd_t_eqrtd_onoff_t_offtime_remaining,
  lc.eqrtd_t_eqrtd_onoff_t_state,
  [lc.auxcntl_t_operand1, 6, 128],
  [lc.auxcntl_t_operand2, 6, 128],
  [lc.auxcntl_t_operand3, 6, 128],
  [lc.auxcntl_t_target, 6, 128],
  [lc.auxcntl_t_functionParameter, 6, 128],
  [lc.auxcntl_t_operandTypes, 7, 128, 4],
  [lc.auxcntl_t_conditionTime, 6, 128],
  [lc.auxcntl_t_operators, 6, 128],
  [lc.auxcntl_t_function, 6, 128],
  [lc.auxcntl_t_holdTime, 6, 128],
  
  [lc.auxsnapshot_t_Vars, 3, 64],
  [lc.auxsnapshot_t_PersistentVars, 3, 30],
  [lc.auxsnapshot_t_Alarms, 3, 32],
  
  [lc.auxrtd_t_setpointSet, 1, 4],
  [lc.auxrtd_t_setpointStatus, 1, 4],
  
  lc.auxrtd_t_outputSetPosition,
  lc.auxrtd_t_equipmentPosition,
  lc.auxrtd_t_outputStatus,
  lc.auxrtd_t_equipmentStatus,
  
  [lc.auxrtd_t_alarmSetPosition, 1, 2],
  [lc.auxrtd_t_alarmStatus, 1, 2],
  [lc.auxrtd_t_specialStates, 1, 2],
  [lc.auxrtd_t_specialStatesValue, 1, 2],
  
  // TODO eqrtd_t
  // lc.eqrtd_t_pos,
  // lc.eqrtd_t_eq_type,
  // lc.eqrtd_t_ovr,
  // lc.eqrtd_t_CVState,
  // lc.eqrtd_t_eqrtd_curt_t_ShadeOpenDelay,
  // lc.eqrtd_t_eqrtd_curt_t_ShockOpenDelay,
  // lc.eqrtd_t_eqrtd_vent_t_CurrWindFacing,
  // lc.eqrtd_t_eqrtd_vent_t_CurrWindOvrState,
  // lc.eqrtd_t_eqrtd_vent_t_CurrWidOvrPos,
  // lc.eqrtd_t_eqrtd_vent_t_CurrWindOvrHold,
  // lc.eqrtd_t_eqrtd_vent_t_vpsTimeout,
  // lc.eqrtd_t_eqrtd_vent_t_vpsAlarm,
  // lc.eqrtd_t_eqrtd_vent_t_VPosAutoCal_State,
  // lc.eqrtd_t_eqrtd_vent_t_lastTime,
  // lc.eqrtd_t_eqrtd_vent_t_startTime,
  // lc.eqrtd_t_eqrtd_irr_t_IrrStartTime,
  // lc.eqrtd_t_eqrtd_irr_t_lastIterationTime,
  // lc.eqrtd_t_eqrtd_irr_t_accumOffCycleTime_secs,
  // lc.eqrtd_t_eqrtd_irr_t_irrSched,
  // lc.eqrtd_t_eqrtd_irr_t_inWindow,
  // lc.eqrtd_t_eqrtd_irr_t_AactiveCyclesCount,
  // lc.eqrtd_t_eqrtd_irr_t_inqueue,
  // lc.eqrtd_t_eqrtd_irr_t_accl,
  // lc.eqrtd_t_eqrtd_irr_t_accvpd,
  // lc.eqrtd_t_eqrtd_irr_t_lasttimevpd,
  // lc.eqrtd_t_eqrtd_mixv_t_IrrStartTime,
  // lc.eqrtd_t_eqrtd_mixv_t_lasttime,
  // lc.eqrtd_t_eqrtd_mixv_t_offtime,
  // lc.eqrtd_t_eqrtd_mixv_t_protect_percent,
  // lc.eqrtd_t_eqrtd_mixv_t_WtrTempErrSum,
  // lc.eqrtd_t_eqrtd_mixv_t_AirTempErroSum,
  // lc.eqrtd_t_eqrtd_mixv_t_AdjWtrTempSetpoint,
  // lc.eqrtd_t_eqrtd_hid_t_lasttime,
  // lc.eqrtd_t_eqrtd_hid_t_DLIaccum,
  // lc.eqrtd_t_eqrtd_hid_t_DLIminOnTime,
  // lc.eqrtd_t_eqrtd_varout_t_raw,
  // lc.eqrtd_t_eqrtd_varout_t_cal,
  // lc.eqrtd_t_eqrtd_varout_t_pcnt,
  // lc.eqrtd_t_eqrtd_pump_t_currTime,
  // lc.eqrtd_t_eqrtd_pump_t_pulseTime,
  // lc.eqrtd_t_eqrtd_pump_t_totalInjectedTime,
  // lc.eqrtd_t_eqrtd_pump_t_totalInjectedVolume,
  // lc.eqrtd_t_eqrtd_pump_t_hourlyInjectedTime,
  // lc.eqrtd_t_eqrtd_pump_t_hourlyInjectedVolume,
  // lc.eqrtd_t_eqrtd_pump_t_currSetpoint,
  // lc.eqrtd_t_eqrtd_pump_t_currHoldTime,
  // lc.eqrtd_t_eqrtd_pump_t_applyDeadband,
  // lc.eqrtd_t_eqrtd_pump_t_activeTrigger,
  // lc.eqrtd_t_eqrtd_pump_t_batchVolume,
  // lc.eqrtd_t_eqrtd_pump_t_peristalticState,
  // lc.eqrtd_t_eqrtd_pump_t_pulse,
  // lc.eqrtd_t_eqrtd_fval_t_fillTime,
  // lc.eqrtd_t_eqrtd_fval_t_activeTrigger,
  // lc.eqrtd_t_eqrtd_fval_t_triggerState,
  // lc.eqrtd_t_eqrtd_mixva_t_IrrStartTime,
  // lc.eqrtd_t_eqrtd_mixva_t_lasttime,
  // lc.eqrtd_t_eqrtd_mixva_t_offtime,
  // lc.eqrtd_t_eqrtd_mixva_t_protect_percent,
  // lc.eqrtd_t_eqrtd_mixva_t_curr_va,
  // lc.eqrtd_t_eqrtd_mixva_t_last_pos,
  // lc.eqrtd_t_eqrtd_gen_pid_t_StartTime,
  // lc.eqrtd_t_eqrtd_gen_pid_t_DelayTime,
  // lc.eqrtd_t_eqrtd_gen_pid_t_lasttime,
  // lc.eqrtd_t_eqrtd_gen_pid_t_curr_va,
  // lc.eqrtd_t_eqrtd_gen_pid_t_period,
  // lc.eqrtd_t_eqrtd_gen_pid_t_pulseOn,
  // lc.eqrtd_t_eqrtd_onoff_t_ontime_remaining,
  // lc.eqrtd_t_eqrtd_onoff_t_offtime_remaining,
  // lc.eqrtd_t_eqrtd_onoff_t_lsec,
  // lc.eqrtd_t_eqrtd_onoff_t_state,
  
  // TODO auxcntl_t
  // lc.auxcntl_t_operand1,
  // lc.auxcntl_t_operand2,
  // lc.auxcntl_t_operand3,
  // lc.auxcntl_t_target,
  // lc.auxcntl_t_functionParameter,
  // lc.auxcntl_t_operandTypes,
  // lc.auxcntl_t_conditionTime,
  // lc.auxcntl_t_operators,
  // lc.auxcntl_t_function,
  // lc.auxcntl_t_holdTime,
  
  // lc.auxsnapshot_t_Vars,
  // lc.auxsnapshot_t_PersistentVars,
  // lc.auxsnapshot_t_Alarms,
  
  // lc.auxcntl_rtd_t_timestamp,
  // lc.auxcntl_rtd_t_activeTimestamp,
  // lc.auxcntl_rtd_t_lastTrueCondition,
  
  // lc.auxrtd_t_setpointSet,
  // lc.auxrtd_t_outputSetPosition,
  // lc.auxrtd_t_equipmentPosition,
  // lc.auxrtd_t_alarmSetPosition,
  // lc.auxrtd_t_outputStatus,
  // lc.auxrtd_t_equipmentStatus,
  // lc.auxrtd_t_alarmStatus,
  // lc.auxrtd_t_setpointStatus,
  // lc.auxrtd_t_specialStates,
  // lc.auxrtd_t_specialStatesValue,
  // lc.auxrtd_t_temperatureStageSetPosition,
  // lc.auxrtd_t_temperatureStageStatus,
  
  // TODO setup_t
  // lc.setup_t_InputMap_INPUTIDX_INTEMP,
  // lc.setup_t_InputMap_INPUTIDX_OUTTEMP,
  // lc.setup_t_InputMap_INPUTIDX_HUM,
  // lc.setup_t_InputMap_INPUTIDX_CO2,
  // lc.setup_t_InputMap_INPUTIDX_LITE,
  // lc.setup_t_InputMap_INPUTIDX_WINSPD,
  // lc.setup_t_InputMap_INPUTIDX_BHEAT1,
  // lc.setup_t_InputMap_INPUTIDX_BHEAT2,
  // lc.setup_t_InputMap_INPUTIDX_BHEAT3,
  // lc.setup_t_InputMap_INPUTIDX_BHEAT4,
  // lc.setup_t_InputMap_INPUTIDX_BHEAT5,
  // lc.setup_t_InputMap_INPUTIDX_FBINTEMP,
  // lc.setup_t_InputMap_INPUTIDX_WINDIR,
  // lc.setup_t_InputMap_INPUTIDX_SOIL1,
  // lc.setup_t_InputMap_INPUTIDX_SOIL2,
  // lc.setup_t_InputMap_INPUTIDX_SOIL3,
  // lc.setup_t_InputMap_INPUTIDX_SOIL4,
  // lc.setup_t_InputMap_INPUTIDX_SOIL5,
  // lc.setup_t_InputMap_INPUTIDX_RAIN,
  // lc.setup_t_InputMap_INPUTIDX_IRR_TRIG,
  // lc.setup_t_InputMap_INPUTIDX_DIFFP,
  // lc.setup_t_InputMap_INPUTIDX_INLIGHT,
  // lc.setup_t_InputMap_INPUTIDX_VENTPOS1,
  // lc.setup_t_InputMap_INPUTIDX_VENTPOS2,
  // lc.setup_t_InputMap_INPUTIDX_VENTPOS3,
  // lc.setup_t_InputMap_INPUTIDX_VENTPOS4,
  // lc.setup_t_InputMap_INPUTIDX_VENTPOS5,
  // lc.setup_t_InputMap_INPUTIDX_ECPH1,
  // lc.setup_t_InputMap_INPUTIDX_ECPH2,
  // lc.setup_t_InputMap_INPUTIDX_ECPH3,
  // lc.setup_t_InputMap_INPUTIDX_ECPH4,
  // lc.setup_t_InputMap_INPUTIDX_ECPH5,
  // lc.setup_t_InputMap_INPUTIDX_GENERIC1,
  // lc.setup_t_InputMap_INPUTIDX_GENERIC2,
  // lc.setup_t_InputMap_INPUTIDX_OUTHUM,
  // lc.setup_t_InputMap_INPUTIDX_LOCAL_INTEMP,
  // lc.setup_t_InputMap_INPUTIDX_LOCAL_INHUM,
  // lc.setup_t_InputMap_INPUTIDX_SNOW,
  // lc.setup_t_InputMap_INPUTIDX_OUTTEMP2,
  // lc.setup_t_InputMap_INPUTIDX_BARO,
  
  ]
  lc.tc = tc;
}



init();

  var pearlSensorIds={// new IDs are based on Pearl
    inTemp:{i:lc.setup_t_InputMap_INPUTIDX_INTEMP,t:"Inside Temperature"},
    outTemp:{i:lc.setup_t_InputMap_INPUTIDX_OUTTEMP,t:"Outside Temperature"},
    inHum:{i:lc.setup_t_InputMap_INPUTIDX_HUM,t:"Inside Humidity"},
    inCo2:{i:lc.setup_t_InputMap_INPUTIDX_CO2,t:"Inside CO2"},
    outLight:{i:lc.setup_t_InputMap_INPUTIDX_LITE,t:"Outside Light"},
    inLight:{i:lc.setup_t_InputMap_INPUTIDX_INLIGHT,t:"Inside Light"},
    wndSpd:{i:lc.setup_t_InputMap_INPUTIDX_WINSPD,t:"Wind Speed"},
    wndDir:{i:lc.setup_t_InputMap_INPUTIDX_WINDIR,t:"Wind Direction"},
    rain:{i:lc.setup_t_InputMap_INPUTIDX_RAIN,t:"Rain"},
    snow:{i:lc.setup_t_InputMap_INPUTIDX_SNOW,t:"Snow"},
    fbInTemp:{i:lc.setup_t_InputMap_INPUTIDX_FBINTEMP,t:"Fallback Inside Temperature"},
    diffP:{i:lc.setup_t_InputMap_INPUTIDX_DIFFP,t:"Differential Pressure"},
    irrTrig:{i:lc.setup_t_InputMap_INPUTIDX_IRR_TRIG,t:"Irrigation Trigger"},
    outHum:{i:lc.setup_t_InputMap_INPUTIDX_OUTHUM,t:"Outside Humidity"},
    anTemp1:{i:lc.setup_t_InputMap_INPUTIDX_BHEAT1,t:"Analog Temperature 1"},
    anTemp2:{i:lc.setup_t_InputMap_INPUTIDX_BHEAT2,t:"Analog Temperature 2"},
    anTemp3:{i:lc.setup_t_InputMap_INPUTIDX_BHEAT3,t:"Analog Temperature 3"},
    anTemp4:{i:lc.setup_t_InputMap_INPUTIDX_BHEAT4,t:"Analog Temperature 4"},
    anTemp5:{i:lc.setup_t_InputMap_INPUTIDX_BHEAT5,t:"Analog Temperature 5"},
    vent1:{i:lc.setup_t_InputMap_INPUTIDX_VENTPOS1,t:"Vent Position 1"},
    vent2:{i:lc.setup_t_InputMap_INPUTIDX_VENTPOS2,t:"Vent Position 2"},
    vent3:{i:lc.setup_t_InputMap_INPUTIDX_VENTPOS3,t:"Vent Position 3"},
    vent4:{i:lc.setup_t_InputMap_INPUTIDX_VENTPOS4,t:"Vent Position 4"},
    vent5:{i:lc.setup_t_InputMap_INPUTIDX_VENTPOS5,t:"Vent Position 5"},
    soil1:{i:lc.setup_t_InputMap_INPUTIDX_SOIL1,t:"Soil Moisture 1"},
    soil2:{i:lc.setup_t_InputMap_INPUTIDX_SOIL2,t:"Soil Moisture 2"},
    soil3:{i:lc.setup_t_InputMap_INPUTIDX_SOIL3,t:"Soil Moisture 3"},
    soil4:{i:lc.setup_t_InputMap_INPUTIDX_SOIL4,t:"Soil Moisture 4"},
    soil5:{i:lc.setup_t_InputMap_INPUTIDX_SOIL5,t:"Soil Moisture 5"},
//     gen1:{i:lc.setup_t_InputMap_INPUTIDX_GENERIC1,t:"Generic 1"},
//     gen2:{i:lc.setup_t_InputMap_INPUTIDX_GENERIC2,t:"Generic 2"},
    locInTemp:{i:lc.setup_t_InputMap_INPUTIDX_LOCAL_INTEMP,t:"Local Inside Temperature"},
    locInHum:{i:lc.setup_t_InputMap_INPUTIDX_LOCAL_INHUM,t:"Local inside Humidity"},
    bpTemp:{i:lc.setup_t_InputMap_INPUTIDX_OUTTEMP2,t:"Black Plate Temperature"},
    baro:{i:lc.setup_t_InputMap_INPUTIDX_BARO,t:"Barometer"},
    volt1:{i:lc.setup_t_InputMap_INPUTIDX_VOLTAGE1,t:"Voltage 1"},
    volt2:{i:lc.setup_t_InputMap_INPUTIDX_VOLTAGE2,t:"Voltage 2"},
    volt3:{i:lc.setup_t_InputMap_INPUTIDX_VOLTAGE3,t:"Voltage 3"},
    volt4:{i:lc.setup_t_InputMap_INPUTIDX_VOLTAGE4,t:"Voltage 4"},
    volt5:{i:lc.setup_t_InputMap_INPUTIDX_VOLTAGE5,t:"Voltage 5"},
    volt6:{i:lc.setup_t_InputMap_INPUTIDX_VOLTAGE6,t:"Voltage 6"},
    volt7:{i:lc.setup_t_InputMap_INPUTIDX_VOLTAGE7,t:"Voltage 7"},
    volt8:{i:lc.setup_t_InputMap_INPUTIDX_VOLTAGE8,t:"Voltage 8"},
    exTemp1:{i:lc.setup_t_InputMap_INPUTIDX_TEMP1,t:"Exp Temp 1"},
    exTemp2:{i:lc.setup_t_InputMap_INPUTIDX_TEMP2,t:"Exp Temp 2"},
    exTemp3:{i:lc.setup_t_InputMap_INPUTIDX_TEMP3,t:"Exp Temp 3"},
    exTemp4:{i:lc.setup_t_InputMap_INPUTIDX_TEMP4,t:"Exp Temp 4"},
    exTemp5:{i:lc.setup_t_InputMap_INPUTIDX_TEMP5,t:"Exp Temp 5"},
    exTemp6:{i:lc.setup_t_InputMap_INPUTIDX_TEMP6,t:"Exp Temp 6"},
    exTemp7:{i:lc.setup_t_InputMap_INPUTIDX_TEMP7,t:"Exp Temp 7"},
    exTemp8:{i:lc.setup_t_InputMap_INPUTIDX_TEMP8,t:"Exp Temp 8"},
    exTemp9:{i:lc.setup_t_InputMap_INPUTIDX_TEMP9,t:"Exp Temp 9"},
    exTemp10:{i:lc.setup_t_InputMap_INPUTIDX_TEMP10,t:"Exp Temp 10"},
    exTemp11:{i:lc.setup_t_InputMap_INPUTIDX_TEMP11,t:"Exp Temp 11"},
    exTemp12:{i:lc.setup_t_InputMap_INPUTIDX_TEMP12,t:"Exp Temp 12"},
    exTemp13:{i:lc.setup_t_InputMap_INPUTIDX_TEMP13,t:"Exp Temp 13"},
    exTemp14:{i:lc.setup_t_InputMap_INPUTIDX_TEMP14,t:"Exp Temp 14"},
    exTemp15:{i:lc.setup_t_InputMap_INPUTIDX_TEMP15,t:"Exp Temp 15"},
    exTemp16:{i:lc.setup_t_InputMap_INPUTIDX_TEMP16,t:"Exp Temp 16"},
    exTemp17:{i:lc.setup_t_InputMap_INPUTIDX_TEMP17,t:"Exp Temp 17"},
    exTemp18:{i:lc.setup_t_InputMap_INPUTIDX_TEMP18,t:"Exp Temp 18"},
    exTemp19:{i:lc.setup_t_InputMap_INPUTIDX_TEMP19,t:"Exp Temp 19"},
    genSens1:{i:lc.setup_t_InputMap_INPUTIDX_GSNS1,t:"Generic Sensor 1"},
    genSens2:{i:lc.setup_t_InputMap_INPUTIDX_GSNS2,t:"Generic Sensor 2"},
    genSens3:{i:lc.setup_t_InputMap_INPUTIDX_GSNS3,t:"Generic Sensor 3"},
    genSens4:{i:lc.setup_t_InputMap_INPUTIDX_GSNS4,t:"Generic Sensor 4"},
    genSens5:{i:lc.setup_t_InputMap_INPUTIDX_GSNS5,t:"Generic Sensor 5"},
    genSens6:{i:lc.setup_t_InputMap_INPUTIDX_GSNS6,t:"Generic Sensor 6"},
    genSens7:{i:lc.setup_t_InputMap_INPUTIDX_GSNS7,t:"Generic Sensor 7"},
    genSens8:{i:lc.setup_t_InputMap_INPUTIDX_GSNS8,t:"Generic Sensor 8"},
    genSens9:{i:lc.setup_t_InputMap_INPUTIDX_GSNS9,t:"Generic Sensor 9"},
    genSens10:{i:lc.setup_t_InputMap_INPUTIDX_GSNS10,t:"Generic Sensor 10"},
    genSens11:{i:lc.setup_t_InputMap_INPUTIDX_GSNS11,t:"Generic Sensor 11"},
    genSens12:{i:lc.setup_t_InputMap_INPUTIDX_GSNS12,t:"Generic Sensor 12"},
    genSens13:{i:lc.setup_t_InputMap_INPUTIDX_GSNS13,t:"Generic Sensor 13"},
    genSens14:{i:lc.setup_t_InputMap_INPUTIDX_GSNS14,t:"Generic Sensor 14"},
    genSens15:{i:lc.setup_t_InputMap_INPUTIDX_GSNS15,t:"Generic Sensor 15"},
    genSens16:{i:lc.setup_t_InputMap_INPUTIDX_GSNS16,t:"Generic Sensor 16"},
    genSens17:{i:lc.setup_t_InputMap_INPUTIDX_GSNS17,t:"Generic Sensor 17"},
    genSens18:{i:lc.setup_t_InputMap_INPUTIDX_GSNS18,t:"Generic Sensor 18"},
    genSens19:{i:lc.setup_t_InputMap_INPUTIDX_GSNS19,t:"Generic Sensor 19"},
    genSens20:{i:lc.setup_t_InputMap_INPUTIDX_GSNS20,t:"Generic Sensor 20"},
    genSens21:{i:lc.setup_t_InputMap_INPUTIDX_GSNS21,t:"Generic Sensor 21"},
    genSens22:{i:lc.setup_t_InputMap_INPUTIDX_GSNS22,t:"Generic Sensor 22"},
    genSens23:{i:lc.setup_t_InputMap_INPUTIDX_GSNS23,t:"Generic Sensor 23"},
    genSens24:{i:lc.setup_t_InputMap_INPUTIDX_GSNS24,t:"Generic Sensor 24"},
    genSens25:{i:lc.setup_t_InputMap_INPUTIDX_GSNS25,t:"Generic Sensor 25"},
    genSens26:{i:lc.setup_t_InputMap_INPUTIDX_GSNS26,t:"Generic Sensor 26"},
    genSens27:{i:lc.setup_t_InputMap_INPUTIDX_GSNS27,t:"Generic Sensor 27"},
    genSens28:{i:lc.setup_t_InputMap_INPUTIDX_GSNS28,t:"Generic Sensor 28"},
    genSens29:{i:lc.setup_t_InputMap_INPUTIDX_GSNS29,t:"Generic Sensor 29"},
    genSens30:{i:lc.setup_t_InputMap_INPUTIDX_GSNS30,t:"Generic Sensor 30"},
    genSens31:{i:lc.setup_t_InputMap_INPUTIDX_GSNS31,t:"Generic Sensor 31"},
    genSens32:{i:lc.setup_t_InputMap_INPUTIDX_GSNS32,t:"Generic Sensor 32"},
    genSens33:{i:lc.setup_t_InputMap_INPUTIDX_GSNS33,t:"Generic Sensor 33"},
    genSens34:{i:lc.setup_t_InputMap_INPUTIDX_GSNS34,t:"Generic Sensor 34"},
    genSens35:{i:lc.setup_t_InputMap_INPUTIDX_GSNS35,t:"Generic Sensor 35"},
    genSens36:{i:lc.setup_t_InputMap_INPUTIDX_GSNS36,t:"Generic Sensor 36"},
    genSens37:{i:lc.setup_t_InputMap_INPUTIDX_GSNS37,t:"Generic Sensor 37"},
    genSens38:{i:lc.setup_t_InputMap_INPUTIDX_GSNS38,t:"Generic Sensor 38"},
    genSens39:{i:lc.setup_t_InputMap_INPUTIDX_GSNS39,t:"Generic Sensor 39"},
    genSens40:{i:lc.setup_t_InputMap_INPUTIDX_GSNS40,t:"Generic Sensor 40"},
    genSens41:{i:lc.setup_t_InputMap_INPUTIDX_GSNS41,t:"Generic Sensor 41"},
    genSens42:{i:lc.setup_t_InputMap_INPUTIDX_GSNS42,t:"Generic Sensor 42"},
    genSens43:{i:lc.setup_t_InputMap_INPUTIDX_GSNS43,t:"Generic Sensor 43"},
    genSens44:{i:lc.setup_t_InputMap_INPUTIDX_GSNS44,t:"Generic Sensor 44"},
    genSens45:{i:lc.setup_t_InputMap_INPUTIDX_GSNS45,t:"Generic Sensor 45"},
    genSens46:{i:lc.setup_t_InputMap_INPUTIDX_GSNS46,t:"Generic Sensor 46"},
    genSens47:{i:lc.setup_t_InputMap_INPUTIDX_GSNS47,t:"Generic Sensor 47"},
    genSens48:{i:lc.setup_t_InputMap_INPUTIDX_GSNS48,t:"Generic Sensor 48"},
    genSens49:{i:lc.setup_t_InputMap_INPUTIDX_GSNS49,t:"Generic Sensor 49"},
    genSens50:{i:lc.setup_t_InputMap_INPUTIDX_GSNS50,t:"Generic Sensor 50"},
    genSens51:{i:lc.setup_t_InputMap_INPUTIDX_GSNS51,t:"Generic Sensor 51"},
    genSens52:{i:lc.setup_t_InputMap_INPUTIDX_GSNS52,t:"Generic Sensor 52"},
    genSens53:{i:lc.setup_t_InputMap_INPUTIDX_GSNS53,t:"Generic Sensor 53"},
    genSens54:{i:lc.setup_t_InputMap_INPUTIDX_GSNS54,t:"Generic Sensor 54"},
    genSens55:{i:lc.setup_t_InputMap_INPUTIDX_GSNS55,t:"Generic Sensor 55"},
    genSens56:{i:lc.setup_t_InputMap_INPUTIDX_GSNS56,t:"Generic Sensor 56"},
    genSens57:{i:lc.setup_t_InputMap_INPUTIDX_GSNS57,t:"Generic Sensor 57"},
    genSens58:{i:lc.setup_t_InputMap_INPUTIDX_GSNS58,t:"Generic Sensor 58"},
    genSens59:{i:lc.setup_t_InputMap_INPUTIDX_GSNS59,t:"Generic Sensor 59"},
    genSens60:{i:lc.setup_t_InputMap_INPUTIDX_GSNS60,t:"Generic Sensor 60"},
    genSens61:{i:lc.setup_t_InputMap_INPUTIDX_GSNS61,t:"Generic Sensor 61"},
    genSens62:{i:lc.setup_t_InputMap_INPUTIDX_GSNS62,t:"Generic Sensor 62"},
    genSens63:{i:lc.setup_t_InputMap_INPUTIDX_GSNS63,t:"Generic Sensor 63"},
    genSens64:{i:lc.setup_t_InputMap_INPUTIDX_GSNS64,t:"Generic Sensor 64"},
    genSens65:{i:lc.setup_t_InputMap_INPUTIDX_GSNS65,t:"Generic Sensor 65"},
    genSens66:{i:lc.setup_t_InputMap_INPUTIDX_GSNS66,t:"Generic Sensor 66"},
    genSens67:{i:lc.setup_t_InputMap_INPUTIDX_GSNS67,t:"Generic Sensor 67"},
    genSens68:{i:lc.setup_t_InputMap_INPUTIDX_GSNS68,t:"Generic Sensor 68"},
    genSens69:{i:lc.setup_t_InputMap_INPUTIDX_GSNS69,t:"Generic Sensor 69"},
    genSens70:{i:lc.setup_t_InputMap_INPUTIDX_GSNS70,t:"Generic Sensor 70"},
    genSens71:{i:lc.setup_t_InputMap_INPUTIDX_GSNS71,t:"Generic Sensor 71"},
    genSens72:{i:lc.setup_t_InputMap_INPUTIDX_GSNS72,t:"Generic Sensor 72"},
    genSens73:{i:lc.setup_t_InputMap_INPUTIDX_GSNS73,t:"Generic Sensor 73"},
    genSens74:{i:lc.setup_t_InputMap_INPUTIDX_GSNS74,t:"Generic Sensor 74"},
    genSens75:{i:lc.setup_t_InputMap_INPUTIDX_GSNS75,t:"Generic Sensor 75"},
    genSens76:{i:lc.setup_t_InputMap_INPUTIDX_GSNS76,t:"Generic Sensor 76"},
    genSens77:{i:lc.setup_t_InputMap_INPUTIDX_GSNS77,t:"Generic Sensor 77"},
    genSens78:{i:lc.setup_t_InputMap_INPUTIDX_GSNS78,t:"Generic Sensor 78"},
    genSens79:{i:lc.setup_t_InputMap_INPUTIDX_GSNS79,t:"Generic Sensor 79"},
    genSens80:{i:lc.setup_t_InputMap_INPUTIDX_GSNS80,t:"Generic Sensor 80"},
    genSens81:{i:lc.setup_t_InputMap_INPUTIDX_GSNS81,t:"Generic Sensor 81"},
    genSens82:{i:lc.setup_t_InputMap_INPUTIDX_GSNS82,t:"Generic Sensor 82"},
    genSens83:{i:lc.setup_t_InputMap_INPUTIDX_GSNS83,t:"Generic Sensor 83"},
    genSens84:{i:lc.setup_t_InputMap_INPUTIDX_GSNS84,t:"Generic Sensor 84"},
    genSens85:{i:lc.setup_t_InputMap_INPUTIDX_GSNS85,t:"Generic Sensor 85"},
    genSens86:{i:lc.setup_t_InputMap_INPUTIDX_GSNS86,t:"Generic Sensor 86"},
    genSens87:{i:lc.setup_t_InputMap_INPUTIDX_GSNS87,t:"Generic Sensor 87"},
    genSens88:{i:lc.setup_t_InputMap_INPUTIDX_GSNS88,t:"Generic Sensor 88"},
    genSens89:{i:lc.setup_t_InputMap_INPUTIDX_GSNS89,t:"Generic Sensor 89"},
    genSens90:{i:lc.setup_t_InputMap_INPUTIDX_GSNS90,t:"Generic Sensor 90"},
    genSens91:{i:lc.setup_t_InputMap_INPUTIDX_GSNS91,t:"Generic Sensor 91"},
    genSens92:{i:lc.setup_t_InputMap_INPUTIDX_GSNS92,t:"Generic Sensor 92"},
    genSens93:{i:lc.setup_t_InputMap_INPUTIDX_GSNS93,t:"Generic Sensor 93"},
    genSens94:{i:lc.setup_t_InputMap_INPUTIDX_GSNS94,t:"Generic Sensor 94"},
    genSens95:{i:lc.setup_t_InputMap_INPUTIDX_GSNS95,t:"Generic Sensor 95"},
    genSens96:{i:lc.setup_t_InputMap_INPUTIDX_GSNS96,t:"Generic Sensor 96"},
    genSens97:{i:lc.setup_t_InputMap_INPUTIDX_GSNS97,t:"Generic Sensor 97"},
    genSens98:{i:lc.setup_t_InputMap_INPUTIDX_GSNS98,t:"Generic Sensor 98"},
    genSens99:{i:lc.setup_t_InputMap_INPUTIDX_GSNS99,t:"Generic Sensor 99"},
    genSens100:{i:lc.setup_t_InputMap_INPUTIDX_GSNS100,t:"Generic Sensor 100"},
    genSens101:{i:lc.setup_t_InputMap_INPUTIDX_GSNS101,t:"Generic Sensor 101"},
    genSens102:{i:lc.setup_t_InputMap_INPUTIDX_GSNS102,t:"Generic Sensor 102"},
    genSens103:{i:lc.setup_t_InputMap_INPUTIDX_GSNS103,t:"Generic Sensor 103"},
    genSens104:{i:lc.setup_t_InputMap_INPUTIDX_GSNS104,t:"Generic Sensor 104"},
    genSens105:{i:lc.setup_t_InputMap_INPUTIDX_GSNS105,t:"Generic Sensor 105"},
    genSens106:{i:lc.setup_t_InputMap_INPUTIDX_GSNS106,t:"Generic Sensor 106"},
    genSens107:{i:lc.setup_t_InputMap_INPUTIDX_GSNS107,t:"Generic Sensor 107"},
    genSens108:{i:lc.setup_t_InputMap_INPUTIDX_GSNS108,t:"Generic Sensor 108"},
    genSens109:{i:lc.setup_t_InputMap_INPUTIDX_GSNS109,t:"Generic Sensor 109"},
    genSens110:{i:lc.setup_t_InputMap_INPUTIDX_GSNS110,t:"Generic Sensor 110"},
    genSens111:{i:lc.setup_t_InputMap_INPUTIDX_GSNS111,t:"Generic Sensor 111"},
    genSens112:{i:lc.setup_t_InputMap_INPUTIDX_GSNS112,t:"Generic Sensor 112"},
    genSens113:{i:lc.setup_t_InputMap_INPUTIDX_GSNS113,t:"Generic Sensor 113"},
    genSens114:{i:lc.setup_t_InputMap_INPUTIDX_GSNS114,t:"Generic Sensor 114"},
    genSens115:{i:lc.setup_t_InputMap_INPUTIDX_GSNS115,t:"Generic Sensor 115"},
    genSens116:{i:lc.setup_t_InputMap_INPUTIDX_GSNS116,t:"Generic Sensor 116"},
    genSens117:{i:lc.setup_t_InputMap_INPUTIDX_GSNS117,t:"Generic Sensor 117"},
    genSens118:{i:lc.setup_t_InputMap_INPUTIDX_GSNS118,t:"Generic Sensor 118"},
    genSens119:{i:lc.setup_t_InputMap_INPUTIDX_GSNS119,t:"Generic Sensor 119"},
    genSens120:{i:lc.setup_t_InputMap_INPUTIDX_GSNS120,t:"Generic Sensor 120"},
    genSens121:{i:lc.setup_t_InputMap_INPUTIDX_GSNS121,t:"Generic Sensor 121"},
    genSens122:{i:lc.setup_t_InputMap_INPUTIDX_GSNS122,t:"Generic Sensor 122"},
    genSens123:{i:lc.setup_t_InputMap_INPUTIDX_GSNS123,t:"Generic Sensor 123"},
    genSens124:{i:lc.setup_t_InputMap_INPUTIDX_GSNS124,t:"Generic Sensor 124"},
    genSens125:{i:lc.setup_t_InputMap_INPUTIDX_GSNS125,t:"Generic Sensor 125"},
    genSens126:{i:lc.setup_t_InputMap_INPUTIDX_GSNS126,t:"Generic Sensor 126"},
    genSens127:{i:lc.setup_t_InputMap_INPUTIDX_GSNS127,t:"Generic Sensor 127"},
    genSens128:{i:lc.setup_t_InputMap_INPUTIDX_GSNS128,t:"Generic Sensor 128"},
}

// module.exports=lc;

export {lc,pearlSensorIds}

