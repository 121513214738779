import React from 'react';
import C18Select00 from './C18Select00'
import C18Anchor00 from './C18Anchor00'
import C18SiteZoneView00 from './C18SiteZoneView00'
import {wsTrans} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadPresetsInfo,loadUsersInfo,getSiteName,getZoneName,getUserIndex,getPresetIndex,loadPrivsInfo,privs,getZoneInfo} from './C18utils'
import {cl,globs,constant,dateToDisplayDate,addNumbering} from '../../components/utils/utils';

class C18GraphSelect00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded: false,
      site:"allSites",
      zone:"allZones",
      sortMode:5,
    }
    this.setBreadCrumbs()
    this.loadInfo()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Graphs", url:`/usa/c18/reports/graphList`},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    await loadUsersInfo()
    await loadPresetsInfo()
    await loadPrivsInfo()
    this.sitesInd={}
    globs.sitesInfo.info.forEach(s=>{this.sitesInd[s.siteId]=s})
    this.zonesInd={}
    globs.zonesInfo.info.forEach(z=>{this.zonesInd[z.zoneId]=z})
//     cl(this.zonesInd)
    this.usersInd={}
    globs.usersInfo.info.forEach(u=>{this.usersInd[u.userId]=u})
    let writePriv=(privs("account",0,constant.AREA_PRIVS_WRITE)!=0)
//     cl(globs.presetsInfo.info)
    this.setState({loaded:true,writePriv:writePriv})
  }
  
  sortGraphs=(views,sortMode)=>{
    let field=["","name","site","zone","userId","ts"][Math.abs(sortMode)]
    // cl(views)
    // cl(this.state)
//     let field=["","title","userId","createdOn"][Math.abs(sortMode)]
    let dir=(sortMode>0)?1:-1
    views.sort((a,b)=>{
      let af=a[field]
      let bf=b[field]
      switch(field){
        case "name":
          af=af?.toLowerCase()
          bf=bf?.toLowerCase()
          break
        case "site":
          af=this.sitesInd[af]?.name
          bf=this.sitesInd[bf]?.name
          break
        case "zone":
          af=this.zonesInd[af]?.zoneName||"None"
          bf=this.zonesInd[bf]?.zoneName||"None"
          break
        case "userId":
          af=this.usersInd[af]?.name||"Unknown"
          bf=this.usersInd[bf]?.name||"Unknown"
          break
        case "ts":
          bf=af||0
          af=b[field]||0
          break
      }
//       cl([af,bf])
//       if(field=="title"){
//         af=(af||"Untitled Report").toLowerCase()
//         bf=(bf||"Untitled Report").toLowerCase()
//       }
//       if(field=="userId"){
//         af=(this.usersSearch[af]||{})?.name||"Former User"
//         bf=(this.usersSearch[bf]||{})?.name||"Former User"
//       }
      if(af>bf){return dir}
      if(af<bf){return 0-dir}
      return 0
    })
    return views
  }
  
  onChange=(type,vals)=>{
    // cl(type,vals)
    let st=this.state
    switch(type){
      case "szv":
        let updVals
        switch(vals.type){
          case "sites":
            updVals={site:vals.site,zone:"allZones"}
            break
          case "zones":
            updVals={site:vals.site,zone:vals.zone}
            break
          default:
            break
        }
        this.setState(updVals)
        break
      case "deleteGraph":
        this.deletePreset(vals.presetId)
        // cl(vals)
        break
      case "createGraph":
        vals.e.preventDefault()
        cl("create")
        break
      case 'sort':
//         cl(st)
        if(Math.abs(vals.sortMode)==st.sortMode){
          vals.sortMode=0-st.sortMode
        }
//         let graphs=this.sortGraphs(st.graphs.slice(0),vals.sortMode)
//         Object.assign(vals,{graphs:graphs})
        this.setState(vals)
        break
        
      default:
        break
    }
  }
  
  deletePreset=async(presetId)=>{
    cl(presetId)
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this View?", buttons:["Cancel","Yes"]})
    let ind=getPresetIndex(presetId)
    globs.presetsInfo.info.splice(ind,1)
    if(res=="Yes"){
      wsTrans("usa", {cmd: "cRest", uri: "/s/graphingPresets", method: "delete", 
        sessionId: globs.userData.session.sessionId, body: {presetId:presetId}})
      this.setState({update:(this.state.update||0)+1})
    }
  }
  
  getPresetLevel=()=>{
    var level="account"
    if(this.props.parms.site){level="site"}
    if(this.props.parms.zone){level="zone"}
    return level
  }
  
  makeGraphList=()=>{
//     cl(this.state)
    // use the sensors in views to determine site and zone (sensor knows zone, zone knows site)
    var views=[]
    var zi
    var valid
    let pr=globs.presetsInfo.info
    if(this.state.site=="allSites"){
      pr.forEach(p=>{
        valid = false
        if(p.sensors?.length === 0) {
          views.push(p)
        } else if (p.sensors && getZoneInfo(p.zone)) {
          for (let s of p.sensors) {
            zi = getZoneInfo(s.z)
            // sensor referencing non-existent zone
            if (!zi) {
              valid = false
              break
            } else {
              valid = true
            }
          }
          if (valid) views.push(p)
        }
      })
    }else if(this.state.zone=="allZones"){
        pr.forEach(p=>{
          valid = false
          if(p.sensors?.length === 0) {
            views.push(p)
          } else if (p.sensors && getZoneInfo(p.zone)) {
            for (let s of p.sensors) {
              zi = getZoneInfo(s.z)
              // sensor referencing non-existent zone
              if (!zi) {
                valid = false
                break
              } else if (this.state.site == zi.siteId) {
                valid = true
              }
            }
            if (valid) views.push(p)
          }
          // if(p.site==this.state.site){views.push(p)}
        })
      }else{
        pr.forEach(p=>{
          valid = false
          if(p.sensors?.length === 0) {
            views.push(p)
          } else if (p.sensors && getZoneInfo(p.zone)) {
            for (let s of p.sensors) {
              zi = getZoneInfo(s.z)
              // sensor referencing non-existent zone
              if (!zi) {
                valid = false
                break
              } else if (this.state.zone == zi.zoneId) {
                valid = true
              }
            }
            if (valid) views.push(p)
          }
          // if(p.zone==this.state.zone){views.push(p)}
        })
    }
    let views2=views.filter(v=>{return v.name!="Current"})
    // add numbering to graphs
    let views3 = addNumbering(views2, "name")
    // cl(views3)
    // let views3=[]
    // views2.forEach(v=>{
    //   if(getZoneName(v.zone)) {
    //     let count = 0;
    //     v.sensors.forEach(v2=> {
    //       if(getZoneName(v2.z)) {
    //         count++
    //       }
    //     })
    //     if(count === v.sensors.length) {
    //        views3.push(v)
    //     } 
    //   }
    // })

    return this.sortGraphs(views3,this.state.sortMode)
//     return views
  }
  
  showGraphHead=()=>{
    let sort=this.state.sortMode// 1-5 for the columns
    let icons=[]
    for(let i=0;i<6;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }
//         <th>ID</th>
    return(
      <tr>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:1})}
        >Name <span className="material-icons-outlined">{icons[0]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:2})}
        >Site <span className="material-icons-outlined">{icons[1]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:3})}
        >Zone <span className="material-icons-outlined">{icons[2]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:4})}
        >Created By <span className="material-icons-outlined">{icons[3]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:5})}
        >Modified On <span className="material-icons-outlined">{icons[4]}</span></button></th>
        {this.state.writePriv&&
          <>
            <th>Edit</th><th>Delete</th>
          </>
        }
      </tr>
    )
  }

  getSiteName=(sensors,expand)=>{
    let names = []
    for (let s of sensors) {
      let zi = getZoneInfo(s.z)
      names.push(getSiteName(zi.siteId))
    }
    let name = names.filter((n, i, a) => a.indexOf(n) == i).join(", ")
    if (expand){
      return (name.length > 25) ? name : ""
    }
    return (name.length > 25) ? `${name.substring(0, 25)}...` : name
  }

  getZoneName=(sensors,expand)=>{
    let names = []
    for (let s of sensors) {
      names.push(getZoneName(s.z))
    }
    let name = names.filter((n, i, a) => a.indexOf(n) == i).join(", ")
    if (expand){
      return (name.length > 25) ? name : ""
    }
    return (name.length > 25) ? `${name.substring(0, 25)}...` : name
  }
  
  showGraphList=()=>{
//     cl(globs.presetsInfo.info)
    let views=this.makeGraphList()
//     cl(views)
//     let i2=1
    let banding=globs.usersInfo?.uiInfo?.tableBanding||true
    let viewRows=views.filter(item => item.presetId != null || item.presetId != undefined)
    .map((v,i)=>{
      let tableBand=(((i+1)%2)&&banding)?"tableBand":null
//       cl(v.userId)
//       cl(getUserIndex(v.userId))
      let name=(globs.usersInfo.info[getUserIndex(v.userId)]||{}).name||"Unknown"
      var da2
      if(v.ts){
        let da=new Date(v.ts*1000)
        da2=dateToDisplayDate(da,"mm/dd/yyyy")
      }else{
        da2="Unknown"
      }
//       cl(da)
//       let create=v.createTime||"Unknown"
//           <td><button type="button" className="settings-button material-icons-outlined flip" aria-label="settings">build</button></td>
      if(v.name!="Current"){
//             <td>{i2++}</td>
      return(
          <tr key={i} className={tableBand}>
            <td>
            <C18Anchor00 to={`/usa/c18/reports/viewGraph/${v.presetId}`} className="name">{v.name}</C18Anchor00>
            </td>
            <td title={this.getSiteName(v.sensors,true)}>{this.getSiteName(v.sensors)||"Unknown"}</td><td title={this.getZoneName(v.sensors,true)}>{this.getZoneName(v.sensors)||"No Zone"}</td><td>{name}</td>
            <td>{da2}</td>
            {this.state.writePriv&&
              <>
                <td>
                  <C18Anchor00 to={`/usa/c18/reports/editGraph/${v.presetId}`} className="settings-button material-icons-outlined flip" aria-label="settings">
                    build
                  </C18Anchor00>
                </td>
                
                <td><button type="button" className="material-icons trash" aria-label="delete graph"
                  onClick={e=>this.onChange("deleteGraph",{presetId:v.presetId})}
                >
                  delete_outline
                  </button></td>
              </>
            }
            
          </tr>
        )
      }
    })
    // cl("viewRows", viewRows)
    return(
      <div className="table-container">
        <table className="list graphs"><tbody>
          {this.showGraphHead()}
          {viewRows}
        </tbody></table>
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div>
          {this.state.writePriv&&
            <div className="section-controls floatright">
              <C18Anchor00 to={`/usa/c18/reports/editGraph/`} className="material-icons-outlined add" aria-label="add report">
                add
              </C18Anchor00>
            </div>
          }
          <div className="clearfloat"/>
          <C18SiteZoneView00 parms={{
            level:this.getPresetLevel(),
            site:this.props.parms.site,
            zone:this.props.parms.zone,
            mode:"viewList",
            view:null,
            getPopup:this.props.parms.getPopup,
            onChange:e=>{this.onChange("szv",e)}
          }}/>
          <div className="clearfloat"></div>
          {this.showGraphList()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18GraphSelect00;
