import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Camera from '@material-ui/icons/Camera';
import GrowJournalWidgetEntry00 from '../../visualization/components/GrowJournalWidgetEntry00'
import UsaIcon from '../../usa/components/UsaIcon'
import UsaTextArea00 from '../../usa/components/UsaTextArea00'
import {cl, globs, constant, getTime, getRandomString} from '../../components/utils/utils';
import {wsTrans, doGetPostBasic} from '../../usa/utils/utils'

class GrowJournalReply00 extends React.Component{
  
  constructor(props) {
    super(props);
    cl(props)
    this.state={
      gjBody:"",
      images:[],
    }
  }
  
  saveGJReply=async(obj)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: obj})
    
  }
  
  saveReply=async()=>{
    await this.sendImages()
    let e=this.props.parms.entry
    let growJournalId=getRandomString(16)
    let obj={threadId: e.threadId, growJournalId: growJournalId, body: this.state.gjBody,
      modTime: Math.floor(getTime())
    }
    await this.saveGJReply(obj)
    return this.props.onChange({gjReply:false})
  }
  
  closeReply=()=>{
//     let vals={gjReply:false}
    return this.props.onChange({gjReply:false})
  }
  
  result=(type)=>{
    cl(type)
    switch (type){
      case "reply-OK":
        this.saveReply()
        break
      case "reply-Cancel":
        this.closeReply()
        break
      default:
        break
    }
  }
  
  onChange=(type, vals)=>{
//     cl(type)
//     cl(vals)
    this.setState(vals)
    return this.props.onChange(vals)
  }
  
  showReply=(height, width)=>{
    let e=this.props.parms.entry
//     cl(this.state)
    return(
      <div>
        <div style={{height: height, width: width, overflowY: "auto"}}>
          <GrowJournalWidgetEntry00 
            parms={{entry: e, reply: true, avatars: this.props.parms.avatars}}
            onChange={vals=>this.onChange("gjEntry", vals)}
            />
        </div>
        <div style={{height: 20}}/>
        <UsaTextArea00 parms={{
          height: 130, 
          width: width-20,
          value: this.state.gjBody,
          valueId: "gjBody",
          onChange: v=>{this.onChange("reply", v)}
        }}/>
      <UsaIcon icon={"reply-OK"} result={this.result}/>
      <UsaIcon icon={"reply-Cancel"} result={this.result}/>
      <IconButton style={{padding: 0}}
        variant="contained"
        component="label"
        title="Insert Image"
      >
        <Camera/>
        <input hidden multiple type="file" onChange={this.markImage}/>
      </IconButton>
      
      </div>
    )
  }
  
/****************** Added to accommodate images **************************************/  
  
  markImage=(e)=>{
    let expImage={id: getRandomString(16), image: e.target.files[0]}// expanded image, w/ our name
    let images=this.state.images.slice(0)
    images.push(expImage)
    cl(images)
    cl(expImage.id)
    this.setState({gjBody: this.state.gjBody+`{File:${expImage.id}}`, images: images})
  }
  
// not used:
//   gotImagesO=(e)=>{
// //   function doGetPost(url, method, body=null){
// 
//     cl(e.target.files)
//     let files=e.target.files[0]
//     var formData = new FormData();
//     formData.append("a", "b")
// //     console.log(formData)
//     let data = new FormData()
//     data.append('file', files)
//     let url=`${constant.expressUrl}/usa/images`
//     let method="POST"
//     let type="multipart/form-data"
//     doGetPostBasic(url, method, data, type)
//   }
  
  sendImages=()=>{
    let data = new FormData()
    this.state.images.forEach((img,i)=>{
      data.append(`id${i}`, img.id)
      data.append(`file${i}`, img.image)
    })
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl(url)
    return doGetPostBasic(url, method, data, type)// promise
  }
  
/****************** end Added to accommodate images **************************************/  
  render(){
//     cl(this.props)
    let p=this.props.parms.pos
    let color=this.props.parms.color
    return(
      <div style={{position: "absolute", left: p.x, top: p.y, width: p.w, height: p.h+200, textAlign: "center", borderRadius: 10, backgroundColor: color,
        overflowY: "auto", zIndex: 1,
      }} 
        >
        {this.showReply(p.h, p.w)}
      </div>
    )
  }
}

export default GrowJournalReply00;
