import React from 'react';
import { Router, Route, Switch, useParams } from "react-router-dom";// , Link
import {Link} from 'react-router-dom'
import ReactHtmlParser from 'html-react-parser'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {cl, globs, getTime,saveLocalStorage,getLocalStorage, 
  userSpecificHome,dateToDisplayDate,constant,checkOnChangeKey,
  getRandomString} from '../../components/utils/utils';
import {wsTrans, saveTokens, logout, getHomeDashboard,doGetPostBasic,saveBrowserAccess } from '../utils/utils';
import {sendSocket} from '../../components/utils/ws'
import InputAdornment from '@material-ui/core/InputAdornment';
// import Input from "@material-ui/core/Input";
import Input from '@material-ui/core/Input';

import InputLabel from '@material-ui/core/InputLabel';
import C18Select01 from './C18Select01'
import Register from './Register';
import Login from './Login';
import Status from './Status';
import MainBar from '../../components/MainBar';
import UserProfile from './UserProfile';
import ManageAccount from './ManageAccount';
import IDoser from './IDoser/IDoser';
import PostRegister from './PostRegister';
import ResetPassword from './ResetPassword';
import ManageAccess from './ManageAccess';
import ManageGateways from './ManageGateways';
import ManageAlarms from './ManageAlarms';
import ManageZones from './ManageZones';
import ManageControllers from './ManageControllers';
import ManageUsers from './ManageUsers';
import ManageSites from './ManageSites';
import ManageSiteAccess from './ManageSiteAccess';
import InviteUser from './InviteUser';
import SetSite from './SetSite';
import EditWidget from './EditWidget';
import DashDisplay00 from './DashDisplay00'
import GrowJournal00 from './GrowJournal00';
import GrowJournalView00 from './GrowJournalView00';
import CreateMessage00 from './CreateMessage00';
import DataVisualization00 from './DataVisualization00';
import Demo1 from '../../visualization/components/Demo1';
import C18Host00 from './C18Host00';
import {getUserIndex,loadZonesInfo,loadPrivsInfo,loadUsersInfo,getSuperUserFlags,
  acctFeature,gdRest} from './C18utils'
import DashboardDisplay from '../../visualization/components/DashboardDisplay';
import C18Button00 from './C18Button00'
import C18Input00 from './C18Input00'
import C18TestConfigs00 from './C18TestConfigs00'
import notify1 from './notify1.mp3'
import {colorIsDark} from '../../visualization/utils/utils'
import './Usa.css'
import history from "../../history"
// import prog0 from "/progs/prog0.png"
var utilsx=require('../../components/utils/utils')

 
 class Usa extends React.Component{
   constructor(props) {
     super(props);
//      cl(useParams())
//      cl("usa construct")
//      cl(props)
//      cl("usa")
//      cl(process.env.PUBLIC_URL)
     let ephemeral=this.loadEphemeral()
     this.state={
       mainBarTitle:"Main Bar",
       contextOpen:false,
       menuAnchor:null,
       infoOpen:false,
       infoEdit:false,
//        infoScroll:window.scrollY,
       infoTop:ephemeral.infoTop,
       infoRight:ephemeral.infoRight,
       infoWidth:ephemeral.infoWidth,
       infoHeight:ephemeral.infoHeight,
       infoText:"here we are",
       chatOpen:false,
       inChatText:"",
       chatId:"ueEIdIa5zedw4I_0",
       curChat:[],
       chats:[],
       images:[],
       waiting:false,
//        testConfigs:[
//         {id:"cfg1",name:"Config One",zones:{}},
//         {id:"cfg2",name:"Config Two",zones:{}},
//        ],
       showTestControl:false,
       cursorProgress:-1,
       helpMode: false,
//        scrollTop:50,
       
//        popup: "editWidget",
    }
//      cl(props);
     this.initExtra0Menu();
     this.initExtra1Menu();
     this.initExtra2Menu();
     this.initExtra3Menu();
     this.subscribe_SetTitle=globs.events.subscribe("setMainBarTitle", this.setMainBarTitle)
     this.subscribe_Waiting=globs.events.subscribe("waiting", this.setWaiting)
     this.subscribe_Progress=globs.events.subscribe("progress", this.setProgress)
//      history.listen((loc, act)=>{
//        this.mode=(this.props.match.params.p1) ? this.props.match.params.p1 : "register";
//     })
     //      this.mode=(props.match.params.action)? props.match.params.action : "register";
//      cl(props);
     
//      cl("usa")
     this.rest={}// comm to and from child
     document.onkeydown=e=>this.doKeyUpDown("keyDown",e/*.key*/)
     document.onkeyup=e=>this.doKeyUpDown("keyUp",e/*.key*/)
     document.onmousemove=this.doMouseMove//("mouseMove",e.key)
     document.onmouseup=this.doMouseUp//("mouseMove",e.key)
     document.onscroll=this.doScroll//("mouseMove",e.key)
     document.addEventListener("contextmenu",e=>{this.contextMenu(e)})
     window.addEventListener("beforeunload", this.onBeforeUnload);  
     window.addEventListener('resize', this.reSize);

    this.subscribe_chatText=globs.events.subscribe("chatText",
      this.receiveChatText)
    this.subscribe_showInfoPage=globs.events.subscribe("showInfoPage",
      this.showInfoPage)
    this.subscribe_chatNotification=globs.events.subscribe("chatNotification",
      this.chatNotification)
    this.loadInfo()
    this.infoHistory=[]
//     cl(contact)
//     cl(window.contact)
//     cl(window.parent.contact)
//     cl(window.window.contact)
//     cl(window.contact)
    window.contact=this.contact
    window.gdRest=gdRest
//     cl("construct done")
//     setTimeout(this.testCallGodot,10000)
//     {
//       callCloud:this.callCloud
//     }
//     window.window.contact=this.contact
//     window.parent.contact=this.contact
//     document.contact=this.contact
//     document.parent.contact=this.contact
    
//     window.contact("testing")
//     eval("window.contact('this is just')")
   }
   
//    testFunc=()=>{
//      cl("testfunc")
//   }
//   
//   callCloud=(uri,method,data)=>{
//     cl(uri)
//     return "return from call Cloud"
//   }
//   
//   returnCloud=(val)=>{
//     cl(val)
//   }
//   
//   testCallGodot=()=>{
// //     cl("test call godot")
//     this.gdRest.gdFunc("try one", 2, 3)
// //     cl(this.gdRest.gdFunc)
//   }
//   
//   setGodot=(func)=>{
// //     cl("set godot")
// //     func(["now", 2, 3])
// //     cl(func)
//     this.gdRest.gdFunc=func
//   }
//   
//   callGodot=(uri,method,data)=>{
//     this.gdRest.gdFunc(uri,method,data)
//     
//   }
//   
//   gdRest={
//     callCloud:this.callCloud,
//     returnCloud:this.returnCloud,
//     setGodot:e=>this.setGodot(e),
//     callGodot:(uri,method,data)=>this.callGodot(uri,method,data)
//   }
//   
//    
//   contact=(o,func,obj2)=>{
//     cl(o)
//     cl(func)
//     cl(obj2)
//     let obj={funcx: this.testFunc}
//     obj.funcx()
//     let ret=func(obj)
//     cl(obj)
//     return "returning"
//   }
//   
// //   contact={func:"here"}
//    
  componentWillUnmount=()=>{
    cl("will unmount")
    this.subscribe_SetTitle.remove()
    this.subscribe_Waiting.remove()
    this.subscribe_chatText.remove()
    this.subscribe_chatNotification.remove()
    this.subscribe_showInfoPage.remove()
    this.subscribe_Progress.remove()
     document.removeEventListener("contextmenu",e=>{this.contextMenu(e)})
     window.removeEventListener("beforeunload", this.onBeforeUnload);  
     window.removeEventListener('resize', this.reSize);
  }
  
  componentDidUpdate=()=>{
// componentDidUpdate()    
//     cl("didupdate")
    let st=this.state
    if(this.refs.chatScroll){
      this.refs.chatScroll.scrollTop=this.refs.chatScroll.scrollHeight-(st.infoHeight-80)
//       cl(this.refs.chatScroll.scrollHeight)
//       cl(st.infoHeight-80)
    }
//     this.refs.chatScroll.scrollTop=this.state.scrollTop
//     cl(this.refs)
  }
  
   componentDidMount=()=>{
    if(globs.userData.session) {
      this.loadAllInfoPages()
    }
//     this.refs.chatScroll.scrollTop=this.state.scrollTop
//      cl("componentDidMount")
  }
   
//    static getDerivedStateFromProps=()=>{
//      cl("getDerivedStateFromProps")
//      return null
//    }
   
   componentWillUnmount=()=>{
//      cl("componentWillUnmount")
   }
   
   shouldComponentUpdate=()=>{
//      cl("shouldComponentUpdate")
     return true
   }
   
   getSnapshotBeforeUpdate=()=>{
//      cl("getSnapshotBeforeUpdate")
     return null
   }
   
  loadInfo=async()=>{
    saveBrowserAccess("/browser/usa/loadinfo/line237")
    if(globs.userData.session){
      await loadZonesInfo()
      await loadPrivsInfo()
    }
//     cl(globs.privsInfo)
  }
    infoDragStart=(e)=>{
      if(e.button!=0){return}
//       cl(e.button)
//       cl(e)
      let st=this.state
      this.infoDragInfo={ofsX:st.infoRight+e.clientX, ofsY:st.infoTop-e.clientY}
    }
    
    infoReSizeStart=(e)=>{
      let st=this.state
      this.infoReSizeInfo={
        ofsRsX:st.infoWidth-e.clientX, 
        ofsRsY:st.infoHeight-e.clientY,
        ofsRsRight:st.infoRight+e.clientX,
      }
    }
    
    saveEphemeral=()=>{
      let st=this.state
      let ephemeral={
        infoTop:st.infoTop,
        infoRight:st.infoRight,
        infoWidth:st.infoWidth,
        infoHeight:st.infoHeight
      }
      saveLocalStorage("ephemeral",JSON.stringify(ephemeral))
    }
    
    loadEphemeral=()=>{
      let eph=getLocalStorage("ephemeral")
//       cl(eph)
      if(eph)return JSON.parse(eph)
      return {
       infoTop:50,
       infoRight:50,
       infoWidth:700,
       infoHeight:700,
      }
    }
    
    reSize=(e)=>{
//       cl(e)
      
    }
    
    onBeforeUnload=()=>{
      this.saveEphemeral()
      cl("unload")
    }
    
    doScroll=(e)=>{
//       cl(e)
      globs.events.publish("scroll",window.scrollY)
//       this.setState({infoScroll:window.scrollY})
    }
    
    doMouseUp=(e)=>{
      this.infoReSizeEnd()
      if(e.button==0){this.clearHelpMenu()}// left click
    }

    doMouseMove=(e)=>{
//       cl(e)
      let id=this.infoDragInfo
      if(id){
        this.setState({infoTop:id.ofsY+e.clientY,infoRight:id.ofsX-e.clientX})
      }
      let ir=this.infoReSizeInfo
      var infoWidth,infoHeight,infoRight
      if(ir){
        if(ir.ofsRsX+e.clientX>=200){
          infoWidth=ir.ofsRsX+e.clientX
          infoHeight=ir.ofsRsY+e.clientY
          infoRight=ir.ofsRsRight-e.clientX
        }else{
          infoWidth=200//ir.ofsRsX+e.clientX
          infoHeight=ir.ofsRsY+e.clientY
          infoRight=ir.ofsRsRight-e.clientX
        }
        if(infoHeight<200){infoHeight=200}
        this.setState({
          infoWidth:infoWidth,//ir.ofsRsX+e.clientX,
          infoHeight:infoHeight,//ir.ofsRsY+e.clientY,
          infoRight:infoRight,//ir.ofsRsRight-e.clientX
        })
      }
    }

    infoDragEnd=(e)=>{
      this.infoDragInfo=null
    }
    
    infoReSizeEnd=(e)=>{
      this.infoReSizeInfo=null
    }
    
    infoButMouseDown=(e)=>{
      e.stopPropagation()
    }
    
    showCurChat=()=>{
//       cl("show cur")
      let st=this.state
//       cl(st)
      var rows
      if(st.chatArr){
        rows=this.state.chatArr.map((ch,i)=>{
          let da=new Date(1000*ch.ts)
          let daDisp=dateToDisplayDate(da,"hh:mm",480)
//           cl(ch)
          var name=ch.name
          if(ch.url&&(getSuperUserFlags()&constant.SUPER_PRIVS_SUPPORT)){
            name=(
              <Link style={{color:"blue"}} to={ch.url}>{ch.name}</Link>
            )
          }
          return(
            <p key={i}>
            <strong>{name}</strong> @ {daDisp}<br/>
            {ch.chatText}
            </p>
          )
          cl(ch)
        })
      }
      return rows
    }
    
    checkUnread=(chatId)=>{
// goes through all the entries for this chat, looking for the max write time
      let chats=this.state.chats
      let userId=globs.userData.session.userId
//       cl(chats,chatId)
      let maxWrite=0
      let readTime=0
      chats.forEach(ch=>{
        if(ch.chatId==chatId){
          if(maxWrite<ch.writeTime){maxWrite=ch.writeTime}
          if(ch.userId==userId){readTime=ch.readTime}
        }
      })
//       cl(readTime,maxWrite)
      return readTime<maxWrite
    }
    
/****************** Image Handling *******************************/
  sendImages=async(images)=>{
    let data = new FormData()
//     let base=this.state.gotImageCount
    for(let i=0;i<images.length;i++){
      let img=images[i]
//       cl(["send",img])
      data.append(`id${i}`, img.id)
      data.append(`file${i}`, img.image)
      data.append("type", "info")
    }
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
//     cl([url, method, data, type])
    return await doGetPostBasic(url, method, data, type)// pro  mise
  }
  
  markImage=(e)=>{
    let images=this.state.images.slice(0)
    let len0=images.length
    let imagesStr=""
    for(let i=0;i<e.target.files.length;i++){
      let name=e.target.files[i].name
      let ext=name.substring(name.lastIndexOf("."))
      images.push({id: getRandomString(16)+ext, image: e.target.files[i]/*e.target.files[0]*/})
      imagesStr+=`[File${len0+i+1}]`
    }
    return images
//     this.setState({note: this.state.note+imagesStr, images: images})//`{Img:${expImage.id}}`
  }
  
  makeImagePath=(id)=>{
    return`${constant.expressUrl}/usa/images/uploads/${id.charAt(0)}/${id.charAt(1)}/${id.charAt(2)}/${id.substring(3)}`
  }
  
  markAndSendImages=async(e)=>{
    let images=this.markImage(e)
    // cl(images)
    let res=await this.sendImages(images)
    let imageInfo=await res.json()// this is just the added image
//     cl(imageInfo)
    let images2=images.map((im,i)=>{
      return{id:im.id,name:im.image.name,width:imageInfo[i].width,height:imageInfo[i].height}
    })
    let str=images2.map((im,i)=>{
      let intRef=`|{"type":"image","url":"${im.id}","width":${im.width},"height":${im.height}}|`
      let imgPath=this.makeImagePath(im.id)
      let extRef=`<img src="${imgPath}" width=${im.width} height=${im.height}/>`
      return (
        <span key={i}>
          These images have been uploaded:<br/><br/>
          {images2.map((im,i)=>{
            return(
              <span key={i}>
{`Name: ${im.name}`}<br/><br/>
Internal Ref:<br/>
{intRef}<br/><br/>
External Ref:<br/>
{extRef}<br/><br/>
              </span>
            )
          })}
        </span>
      )
//       return(
//         "<span>Images have been uploaded</span>"
//       )
    })
    res=await this.rest.child({uri:"getPopup",method:"create",body:{type:"admin",
      vals:{text:str,buttons:["OK"]}}})
// http://ngsg.link4cloud.com:3105/usa/images/uploads/Z/u/c/vzCw5gozk47ZY.png
  }
  
/****************** End Image Handling *******************************/
  
    showChat=()=>{
      let st=this.state
      let superUser=getSuperUserFlags()&constant.SUPER_PRIVS_SUPPORT
      let topOfs=(superUser)?0:20
      cl(superUser)
//       cl(st.chats)
      let userId=globs.userData.session.userId
      return (
        <div style={{position:"fixed",zIndex:11,width:200,height:st.infoHeight,top:st.infoTop,
          right:st.infoRight+st.infoWidth,backgroundColor:"blue"
        }}>
        {(superUser!=0)&&
          <div style={{width:"100%",height:20,backgroundColor:"white"}}>
            <select style={{width:200}}
            value={this.state.chatId}
            onChange={e=>this.onChange("setChat",{chatId:e.currentTarget.value})}
            >
            {st.chats.map((ch,i)=>{
//               cl(ch)
              if(ch.userId==userId){
                let unread=this.checkUnread(ch.chatId)?" *":""
                return(
                  <option key={i} value={ch.chatId}>{ch.chatName}{unread}</option>
                )
              }
            })}
            </select>
          </div>
        }
          <div style={{
            width:"100%",
            height:st.infoHeight-80+topOfs,
            padding:5,
            backgroundColor:"white",
            overflowY:"auto",
            scrollTop:50,
            border:"1px solid black",
          }}
          ref="chatScroll"
          >
          {this.showCurChat()}
          </div>
          <div style={{width:"100%",height:60}}>
            <textarea style={{width:"100%",height:60,borderRadius:0,resize:"none"}}
            value={st.inChatText}
            onChange={e=>this.onChange("setState",{inChatText:e.currentTarget.value})}
            onKeyPress={e=>this.onChange("chatText",{e:e})}/>
          </div>
        </div>
      )
    }
    
  showImageIcon=()=>{
    return(
      <>
        <C18Button00 type="button" className="floatleft material-icons selected" aria-label="upload" style={{
          width:30,
          height:30,
          textAlign:"center",
          cursor:"pointer",
          color:"white",
        }}>image
        </C18Button00>
        <form className="file-upload-form">
          <C18Input00 id="/sidebar/growJournal/header/attach" type="file" multiple onChange={this.markAndSendImages} style={{
            position:"absolute", 
            width:30, 
            height:30, 
            marginTop:0, 
            marginLeft:-31,
            zIndex:10,                   
            opacity:0,
            cursor: "pointer",
          }}/>
        </form>
      </>
    )
  }
  
   showInfo=()=>{
//      cl(this.state)
//      let width=window.innerWidth
//      let height=window.innerHeight
     let st=this.state
     let backgroundColor="#55836e"
     if(!st.infoOpen){return null}
//      cl(st)
     if(st.chatOpen){}
//      cl(this.getSuperUserFlags())
     let superUserEdit=((getSuperUserFlags()&constant.SUPER_PRIVS_EDIT)!=0)
//      cl(superUserEdit)
     return(
       <div>
       {st.chatOpen&&this.showChat()}
       <div id="infoWindow" className="opaque-background" style={{
         position:"fixed",
         zIndex:11,
         right:st.infoRight,
         top:st.infoTop,
         width:st.infoWidth,
         height:st.infoHeight,
//          backgroundColor:(colorIsDark())?"black":"white",
         borderRadius:10,
         border:1,
         borderStyle:"solid",
         display:"flex",
         flexDirection:"column",
         justifyContent:"flex-start",
      }}
      >
        <div style={{
          position:"relative",
          width:st.infoWidth,
          height:50,
          backgroundColor:backgroundColor,
          borderRadius:10,
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
        }}
        onMouseDown={this.infoDragStart}
        onMouseUp={this.infoDragEnd}
        draggable="false"
        >
          <div style={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"flex-start",
            marginLeft:10,
            marginRight:10,
            color:"white",
            fontSize:24,
            fontWeight:700,
            cursor:"default",
          }}
          >Info
            <div style={{
              display:"flex",
              width:"100%",
              flexDirection:"row",
              justifyContent:"flex-end",
              alignContent:"space-between",
            }}>
              <button type="button" className="usa-info-header-button material-icons selected" style={{
                width:30,
                height:30,
                textAlign:"center",
                cursor:"pointer",
                color:"white",
                backgroundColor:backgroundColor,
              }}
              onClick={e=>this.onChange("infoHome")}
              onMouseDown={e=>e.stopPropagation()}
              title="Home"
              >home</button>
              <button type="button" className="usa-info-header-button material-icons selected" style={{
                width:30,
                height:30,
                textAlign:"center",
                cursor:"pointer",
                color:"white",
                backgroundColor:backgroundColor,
              }}
              onClick={e=>this.onChange("infoBack")}
              onMouseDown={e=>e.stopPropagation()}
              title="Back"
              >arrow_back</button>
              <button type="button" className="usa-info-header-button material-icons selected" style={{
                width:30,
                height:30,
                textAlign:"center",
                cursor:"pointer",
                color:"white",
                backgroundColor:backgroundColor,
              }}
              onClick={e=>this.onChange("infoChat")}
              onMouseDown={e=>e.stopPropagation()}
              title="Chat"
              >chat</button>
              {superUserEdit&&!st.infoEdit&&
                <button type="button" className="usa-info-header-button material-icons selected" style={{
                  width:30,
                  height:30,
                  textAlign:"center",
                  cursor:"pointer",
                  color:"white",
                  backgroundColor:backgroundColor,
                }}
                onClick={e=>this.onChange("infoEdit")}
                onMouseDown={e=>e.stopPropagation()}
                title="Edit"
                >edit</button>
              }
              {st.infoEdit&&
                <>
                  <button type="button" className="usa-info-header-button material-icons selected" style={{
                    width:30,
                    height:30,
                    textAlign:"center",
                    cursor:"pointer",
                    color:"white",
                    backgroundColor:backgroundColor,
                  }}
                  onClick={e=>this.onChange("infoSave")}
                  onMouseDown={e=>e.stopPropagation()}
                  title="OK"
                  >check</button>
                  {this.showImageIcon()}
                </>
              }

              <button type="button" className="usa-info-header-button material-icons selected"
              style={{
                width:30,
                height:30,
                textAlign:"center",
                cursor:"pointer",
                color:"white",
                backgroundColor:backgroundColor,
              }}
              onClick={e=> this.onInfoClose()}
              onMouseDown={e=>e.stopPropagation()}
              title="Close"
              >close</button>
            </div>
          </div>

            {!st.infoEdit&&// the html version
            <div style={{
              position:"absolute",
              left:0,
              top:50,
              width:st.infoWidth-2,
              height:st.infoHeight-60,
              overflowY:"auto",
              overflowX:"hidden",
            }}>
              <div style={{margin:10}}>
                {this.processInfoText(st.infoText)}
              </div>
            </div>
            }
            {st.infoEdit&&// the editing version
            <div style={{
              position:"absolute",
              left:0,
              top:50,
              width:st.infoWidth,
              height:st.infoHeight-60,
            }}>
            <div style={{height:20,marginLeft:10}}>
            {`Id: ${st.infoId}`}
            </div>

              <textarea style={{
                width:st.infoWidth-2,
                height:st.infoHeight-50-2-20,
                border:1,
                resize:"none",
              }}
              onMouseDown={e=>e.stopPropagation()}
              value={st.infoText}
              onChange={e=>this.onChange("infoText",{infoText:e.currentTarget.value})}/>
            </div>
            }

        </div>
        <div style={{
          position:"absolute",
          top:st.infoHeight-20,
          right:0,
          width:20,
          height:20,
          cursor:"nwse-resize",
        }}
        onMouseDown={this.infoReSizeStart}
        >
        {this.image}
        </div>
      </div>
       </div>
    )
  }
  
  linkClick=async(e,url)=>{// internal link
    e.preventDefault()
    this.infoHistory.push(url)
//     cl(url)
    let infoText=await this.loadInfoPage(url)
    this.setState({infoText:infoText,infoId:url})
//     cl(res)
//     cl(parm)
  }
  
  cLinkClick=(e,url)=>{
//     e.preventDefault()
    cl(url)
    history.push(`/usa/c18${url}`)
  }
  
  infoKey=0
  
  makeLink=(obj,useH2)=>{
    if(useH2){
      return (
        <Link key={this.infoKey++} to="" style={{color:"blue",fontSize:20}}
        onClick={e=>this.linkClick(e,obj.url)}>{obj.text}</Link>
      )
    }else{
      return (
        <Link key={this.infoKey++} to="" style={{color:"blue"}}
        onClick={e=>this.linkClick(e,obj.url)}>{obj.text}</Link>
      )
    }
  }
  
  makeCLink=(obj)=>{
      
//       <Link key={this.infoKey++} to="" style={{color:"blue"}}
//       onClick={e=>this.linkClick(e,obj.url)}>{obj.text}</Link>
    return (
              <button type="button" className="usa-info-header-button material-icons selected" style={{
                width:20,
                height:20,
                fontSize:14,
                textAlign:"center",
                cursor:"pointer",
                color:"white",
                borderRadius:5,
              }}
              title="Go There"
              onClick={e=>{this.cLinkClick(e,obj.url)}}
              onMouseDown={e=>e.stopPropagation()}
              >arrow_forward</button>
    )
  }
  
  makeXLink=(obj)=>{
    return (
      <a key={this.infoKey++} href={obj.url} target="_blank" style={{color:"blue"}}
      >{obj.text}</a>
    )
  }
  
  makeImage=(obj)=>{
    let width=obj.width
    let height=obj.height
    return (
      <img key={this.infoKey++} src={this.makeImagePath(obj.url)} 
      width={width} height={height}
      />
    )
  }
  
  makeXImage=(obj)=>{
    let width=obj.width||320
    let height=obj.height||240
    return (
      <img key={this.infoKey++} src={obj.url} width={width} height={height}/>
    )
  }
  
  makeXVideo=(obj)=>{
    let width=obj.width||320
    let height=obj.height||240
    return (
      <img key={this.infoKey++} src={obj.url} width={width} height={height} controls/>
    )
  }
  
  makeYTVideo=(obj)=>{
    let width=obj.width||320
    let height=obj.height||240
    return(
      <iframe key={this.infoKey++} width={width} height={height} src={obj.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>      
    )
  }
  
  makeVMVideo=(obj)=>{
    let width=obj.width||320
    let height=obj.height||240
    return(
      <iframe key={this.infoKey++} src={obj.url} width={obj.width} height={obj.height} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title={obj.title}></iframe>
      
    )
  }
  
//   https://cdn.cnn.com/cnnnext/dam/assets/220119144231-01-biden-lead-up-file-restricted-large-tease.jpg
  
  processInternal=(obj)=>{
//     cl(obj)
    switch(obj.type){
      case "link":
        return this.makeLink(obj,false)
      case "linkh2":
        return this.makeLink(obj,true)
      case "cLink":
        return this.makeCLink(obj)
      case "xLink":
        return this.makeXLink(obj)
      case "image":
        return this.makeImage(obj)
      case "xImage":
        return this.makeXImage(obj)
      case "xVideo":
        return this.makeXVideo(obj)
      case "YTVideo":
        return this.makeYTVideo(obj)
      case "VMVideo":
        return this.makeVMVideo(obj)
    }
    return null
  }
  
  processInfoText=(infoText)=>{
    let parts=infoText.split("|")
    let children=[]
    parts.forEach(pa=>{
      var ch
      try{
        let obj=JSON.parse(pa)
        ch=this.processInternal(obj)
      }catch{
        ch=ReactHtmlParser(pa)
      }
      children.push(ch)
    })
    return React.createElement("div", {}, children)
  }
  
  processOurUrl=(url)=>{
    let parts=url.split("/")
    if(parts[3]=="sites"){
      if(parts[7]=="settings"){
        return parts[8]
      }else{
        if(parts[5]=="zones"){
          return "/zone"
        }else{
          if(parts[4]){
            return "/site"
          }else{
            return "/sites"
          }
        }
      }
    }else{}
//     cl(parts)
//     cl(url)
    if(url.indexOf("/usa/c18")==0){url=url.substring(8)}
    if(url.indexOf("/fui/")==0){url=url.split("/")[2]}
//     cl(url)
    return url
  }
  
  fixInfoId=async(infoId)=>{
    var infoText
//     cl(infoId)
    if(infoId){
      this.infoHistory.push(infoId)
      infoText=await this.loadInfoPage(infoId)
//       this.setState({infoOpen:true,infoText:infoText,contextOpen:false})
    }else{
      let url=this.props.match.url
//       cl(url)
      infoId=this.processOurUrl(url)
      this.infoHistory.push(infoId)
//       cl(infoId)
      infoText=await this.loadInfoPage(infoId)
//       this.setState({infoOpen:true,infoText:infoText,infoId:infoId,contextOpen:false})
    }
    return [infoId,infoText]
  }
  
  renameSensor=()=>{
//     cl(this.state.contextCmd)
    globs.events.publish("renameSensor",this.state.contextCmd)
  }

  createGrowJournal=()=>{
    // cl("create grow journal from menu")
    // cl(this.state.contextCmd)
    globs.events.publish("createGrowJournal", {cmd: this.state.contextCmd, pos: {left:this.state.contextX-10,top:this.state.contextY-10}})
  }
  resetZoneOrder=()=>{
    // cl("resetZoneOrder")
    // cl(this.state.contextCmd)
    globs.events.publish("resetZoneOrder", {cmd: this.state.contextCmd, pos: {left:this.state.contextX-10,top:this.state.contextY-10}})
  }

  confirmWatch=async()=>{
    let res=await this.rest.child({uri:"getPopup",method:"create",body:{type:"admin",vals:{text:"Add to Watch List?",buttons:["Yes","No","Manage"]}}})
    if(res=="Yes"){
      let cmd=this.state.contextCmd
      cl("yes")
      cl(this.state)
      let watch={
        watchId:getRandomString(16),
        siteId:globs.userData.session.siteId,
        zuci:cmd.zuci,
        type:cmd.type,
        pid:cmd.id}
      await wsTrans("usa", {cmd: "cRest", uri: "/s/watch", method: "update", 
        sessionId: globs.userData.session.sessionId,
        body: watch})
    }
    if(res=="Manage"){
      history.push(`/usa/c18/admin/manageWatch`)
    }
  }
  
   closeHelpMenu=async(e)=>{
    console.log(e)
    var id
    if((e.nativeEvent instanceof KeyboardEvent)&&(e.nativeEvent.code=="Escape")){
       id="cancel"
    }else{
      id=e.target.id
    }
    // cl("faiza id", id)
    let st=this.state
    var data,data2,data3
    console.log('closehlpmenh id',id)
    if(id.indexOf("_")>0){
      let parts=id.split("_")
      id=parts[0]
      data=parts[1]
      data2=parts[2]
      data3=parts[3]
    }
//     cl(data,data2)
     
    //  cl(id)
     switch(id){
       case "info":
         let [infoId,infoText]=await this.fixInfoId(st.infoId)
         cl("show context on mobile: ", infoId)
          this.setState({infoOpen:true,infoText:infoText,infoId:infoId,contextOpen:false})
//          if(st.infoId){
//            this.infoHistory.push(this.state.infoId)
//           let infoText=await this.loadInfoPage(this.state.infoId)
//           this.setState({infoOpen:true,infoText:infoText,contextOpen:false})
//         }else{
//           let url=this.props.match.url
//           let infoId=this.processOurUrl(url)
//            this.infoHistory.push(url)
// //           cl(infoId)
//           let infoText=await this.loadInfoPage(infoId)
//           this.setState({infoOpen:true,infoText:infoText,infoId:infoId,contextOpen:false})
//         }
         break
       case "cancel":
         this.setState({infoOpen:false,contextOpen:false})
         break
       case "sensor":
         this.setState({contextOpen:false})
         this.renameSensor()
         break
       case "fuiImage":
       case "editTags":
       case "allIrrStartDisable":
         this.setState({contextOpen:false})
          globs.events.publish(id,this.state.contextCmd)
         break
       case "watch":
         this.setState({contextOpen:false})
         this.confirmWatch()
         cl(this.state)
         cl("watch")
         break
       case "cropPhase":
//          cl(data)
         if(data2=="new"){
           history.push(`/usa/c18/cropRecipes/${data}/days/${data2}`)
          }else{
            if(data2=="edit"){
              history.push(`/usa/c18/cropRecipes/${data}/days/${data3}`)
            }else{
              globs.events.publish("cropPhase",data2)// data2 is phase
            }
         }
         break
       case "graph":
        //  cl("graph")
         this.createGrowJournal()
         this.setState({contextOpen:false})
         break
       case "zoneTile":
        //  cl("resetZoneOrder")
         this.resetZoneOrder()
         this.setState({contextOpen:false})
         break
       default:
         break
    }
  }
  
  showInfoPage=async(infoId)=>{
    let info=(typeof infoId=="object")?infoId:{infoId:infoId}//infoId.infoId:infoId
    let infoText=await this.loadInfoPage(info.infoId)
    this.infoHistory.push(info.infoId)
    let newSt=Object.assign({},{infoOpen:true,infoText:infoText},info)
    this.setState(newSt)
  }
  
  showCropPhases=(cmd)=>{
//     cl(cmd)
    let newPhase=(
      <MenuItem key={"newP"} id={`cropPhase_${cmd.cropRecipe}_new`}>New Crop Phase</MenuItem>      
    )
    let editPhase=(
      <MenuItem key={"editP"} id={`cropPhase_${cmd.cropRecipe}_edit_${cmd.cropPhase}`}>
      {`Edit ${cmd.phaseName}`}</MenuItem>      
    )
    let phases=cmd.phases.map((ph,i)=>{
      return(
        <MenuItem key={3+i} id={`cropPhase_${cmd.cropRecipe}_${ph.cropPhaseId}`}>{ph.name}</MenuItem>
      )
    })
    return phases.concat([editPhase,newPhase])
//             <MenuItem key="3" id="watch">Watch</MenuItem>
  }
  
  showCmdMenu=()=>{
//     let st=this.state
    let cmd=this.state.contextCmd
    if(!cmd){return}
    // cl(cmd)
    switch(cmd.cmd){
      case "sensor":
        return(
          <MenuItem key="3" id="sensor">Rename Sensor</MenuItem>
        )
      case "fuiImage":
        return(
          <MenuItem key="3" id="fuiImage">Upload Image</MenuItem>
        )
      case "tags":
        return(
          <MenuItem key="3" id="editTags">Edit Tags</MenuItem>
        )
      case "watch":
        if(acctFeature("watchParams")){
          return(
            <MenuItem key="3" id="watch">Watch</MenuItem>
          )
        }else{return null}
      case "graph":
        return(
          <MenuItem key="3" id="graph">Create Grow Journal</MenuItem>
        )
      case "zoneTile":
        return(
          <MenuItem key="3" id="zoneTile">Reset Zone Order</MenuItem>
        )
  
      case "cropPhase":
        if(cmd.selecting){
          return this.showCropPhases(cmd)
        }else{return null}
      case "allIrrStartDisable":
        return(
          <MenuItem key="3" id="allIrrStartDisable">Disable</MenuItem>
        )
    }
//     cl(cmd)
  }
  
  clearHelpMenu=()=>{
    if(this.state.contextOpen){this.setState({contextOpen:false})}
  }
   
   showHelpMenu=()=>{
//         anchorEl={this.state.menuAnchor}
    return(
      <Menu 
        open={this.state.contextOpen}
        onClose={this.closeHelpMenu}
        onClick={this.closeHelpMenu}
        anchorReference="anchorPosition"
        anchorPosition={{left:this.state.contextX-10,top:this.state.contextY-10}}
        onKeyPress={e=>cl(e)}
      >
        {this.showCmdMenu()}
        <MenuItem key="1" id="info"
        onKeyPress={e=>cl(e)}
        >Info</MenuItem>
        <MenuItem key="2" id="cancel">Cancel</MenuItem>
      </Menu>
    )
  }
  
//   isInfoWindow=(el)=>{
//     do{
//       cl(el.id)
//       if(el.id=="infoWindow"){return true}
//     } while(el=el.parentNode)
//     return false
//     
//   }

    getFirstId=(node)=>{
      let svg=false
      var anchor
      do{
//         cl(node.tagName)
        if(svg&&!anchor){anchor=node}
        let lc=node.tagName.toLowerCase()
        if(lc=="html"){return {id:"",svg:false,anchor:node} }
        svg|=(lc=="svg")
        if(node.id&&(node.id!="content-area")){break}
      }while(node=node.parentNode)
      return {id:node.id,svg:svg,anchor:anchor}
    }
   
   contextMenu=async(e)=>{
//      cl(e)
//      cl([e.pageX,e.pageY])
     if(e.shiftKey){return}
     e.preventDefault()
     let firstId=this.getFirstId(e.target)
    //  cl(firstId)
//      cl(firstId.id)
     var obj
     try{
//        cl(firstId)
       obj=JSON.parse(firstId.id)
//        cl(obj)
    }catch (e) {
//       cl(e)
      obj=firstId
      obj.cmd = firstId.id

    }
//     cl(obj)
//      cl(firstId)
//      let anchor=(firstId.svg)?firstId.anchor:e.target
     let id=e.target.id||firstId.id
     if(id.indexOf("/usa/c18")==0){id=id.substring(8)}
     if(id.indexOf("/fui/")==0){id=id.split("/")[2]}
     if(["main"].includes(id)){id=""}
     if(this.state.infoOpen){
       let [infoId,infoText]=await this.fixInfoId(id)
//        cl(infoId)
      if(infoId!="infoWindow"){
        this.setState({infoText:infoText,infoId:infoId})
      }
       return
    }
    // cl("ffff context", obj, id)
     this.setState({/*menuAnchor:anchor,*/infoId:id,contextOpen:true,
       contextX:e.pageX-window.scrollX,contextY:e.pageY-window.scrollY,contextCmd:obj
    })
  }
   
  readController=()=>{// new version, for c1800
  cl("send packs")
  let sendPack = {
    cmd: "readSite",
    s: globs.userData.session.siteId,
    sessionId: globs.userData.session.sessionId,
  }
  cl(sendPack)
  sendSocket(sendPack).then(r=>{
    cl(r);
  });
  }
   
   readControllerO=()=>{
    cl("do read");
//     cl(this.props.token);
    let body={sessionId: globs.userData.session.sessionId};
    let readCont={cmd: "cRest", uri: "/o/readGateway", method: "retrieve", body: body};
    wsTrans("usa", readCont).then(r=>{
//       saveTokens(r.data.accessToken, r.data.refreshToken, r.session)
//       history.push('/usa/userProfile');
    });
    
  }
  
//   getHomeDashboard=async()=>{
// //     cl(this.state)
// //     cl(this.props)
// //     cl(globs.userData.session)
//     let r = await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", sessionId: globs.userData.session.sessionId,
//       body: {siteId: globs.userData.session.siteId}})
// //     cl(r)
//     if(r.data[0]&&r.data[0].homeDashboard){
//       return r.data[0].homeDashboard
//     }else{
//       r = await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "retrieve2", sessionId: globs.userData.session.sessionId,
//         body: {a: globs.userData.session.accountId}})
//       cl(r)
//       if(!r.data.length){
//         r = await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "retrieve2", sessionId: globs.userData.session.sessionId,
//           body: {u: globs.userData.session.userId}})
//         cl(r)
//       }
//     }
//     if(r.data[0]){return r.data[0].i}
//     
//   }
  
  sendAlarm=async()=>{
//     let body={sessionId: globs.userData.session.sessionId};
//     let readCont={cmd: "cRest", uri: "/o/readGateway", method: "retrieve", body: body};
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/notifications2", method: "create", sessionId: globs.userData.session.sessionId,
      body: {}})
    cl(r)
    cl("send alarm")
  }
  
   funcExtra0=(id)=>{
     switch(id){
       case "register":
//          cl("reg");
//          setTimeout(f=>{history.push("/usa/register")}, 5000)
         history.push("/usa/register");
         break;
       case "login":
//          cl("reg");
//          history.push("/usa/login");
         break;
       case "userProfile":
         cl("reg");
         history.push("/usa/userProfile");
         break;
       case "manageAccount":
         history.push("/usa/manageAccount");
         break;
       case "iDoser":
         history.push("/usa/iDoser");
         break;
       case "manageUsers":
         history.push("/usa/manageUsers");
         break;
       case "manageSites":
         history.push("/usa/manageSites");
         break;
       case "manageSiteAccess":
         history.push("/usa/manageSiteAccess");
         break;
       case "manageControllers":
         history.push("/usa/manageControllers");
         break;
       case "manageGateways":
         history.push("/usa/manageGateways");
         break;
       case "manageAlarms":
         history.push("/usa/manageAlarms");
         break;
       case "manageZones":
         history.push("/usa/manageZones");
         break;
       case "dataVisualize":
         history.push("/vis/demo1");
         break;
       case "dashboardDisplay":
         history.push("/usa/dash/3CtZxfzKKZ-EE51x");
         break;
       case "setSite":
         cl("setSite");
         this.setState({popup: "setSite"});
         break;
//        case "editWidget":
//          cl("editWidget");
//          this.setState({popup: "editWidget"});
//          break;
       case "readController":
         this.readController()
         break;
       case "sendAlarm":
//          history.push("/usa/dash/vz4sci_m-iHC9nQZ");
         this.sendAlarm()
         break;
       case "growJournal":
         history.push("/usa/growJournal");
         break;
       case "growJournalView":
         history.push("/usa/growJournalView");
         break;
       case "dataVisualization":
         history.push("/usa/dataVisualization");
         break;
       case "status":
         history.push("/usa/status");
         break;
         
       default:
         break;
    }
//      cl(id);
   }
   
   funcExtra1=(id)=>{
     cl(id);
     history.push(`/fui/live/${id}/0-0-0-0`);
     
//      switch(id){
//        case "register":
//          cl("reg");
//          history.push("/usa/register");
//          break;
//        default:
//          break;
//     }
//      cl(id);
   }
   
   initExtra0Menu=()=>{
     this.extra0Menu={
       func: this.funcExtra0,
       menuEntries: [
       {text: "Register", val: "register"},
        {text: "Log In", val: "login"},
        {text: "User Profile", val: "userProfile"},
        {text: "Manage Account", val: "manageAccount"},
        {text: "iDoser", val: "iDoser"},
        {text: "Manage Users", val: "manageUsers"},
        {text: "Manage Sites", val: "manageSites"},
        {text: "Manage Site Access", val: "manageSiteAccess"},
        {text: "Manage Controllers", val: "manageControllers"},
        {text: "Manage Gateways", val: "manageGateways"},
        {text: "Manage Alarms", val: "manageAlarms"},
        {text: "Manage Zones", val: "manageZones"},
        {text: "Visualize", val: "dataVisualize"},
        {text: "Dashboard", val: "dashboardDisplay"},
        {text: "Set Site", val: "setSite"},
//         {text: "Edit Widget", val: "editWidget"},
        {text: "Send Alarm", val: "sendAlarm"},
        {text: "Grow Journal", val: "growJournal"},
        {text: "Grow Journal View", val: "growJournalView"},
        {text: "Data Visualization", val: "dataVisualization"},
        {text: "Read Controller", val: "readController"},
        {text: "menu 4", val: "menu4"},
       ],
    }
  }
   
   initExtra1Menu=()=>{
     this.extra1Menu={
       func: this.funcExtra1,
       menuEntries: [
       {text: "channel_None", val: "channel_None"},
       {text: "channel_On_Off", val: "channel_On_Off"},
       {text: "channel_Irrigation_Scheduled", val: "channel_Irrigation_Scheduled"},
       {text: "channel_Irrigation_Accumulated_Light", val: "channel_Irrigation_Accumulated_Light"},
       {text: "channel_Irrigation_Cycle", val: "channel_Irrigation_Cycle"},
       {text: "channel_Irrigation_Trigger", val: "channel_Irrigation_Trigger"},
       {text: "channel_Irrigation_Soil_Trigger", val: "channel_Irrigation_Soil_Trigger"},
       {text: "channel_Irrigation_VPD", val: "channel_Irrigation_VPD"},
       {text: "channel_CO2", val: "channel_CO2"},
       {text: "channel_Light_Supplemental", val: "channel_Light_Supplemental"},
       {text: "channel_Light_Scheduled", val: "channel_Light_Scheduled"},
       {text: "channel_Light_Cyclic", val: "channel_Light_Cyclic"},
       {text: "channel_Light_DLI", val: "channel_Light_DLI"},
       {text: "channel_Microzone", val: "channel_Microzone"},
//        {text: "channel_Supply_Pump", val: "channel_Supply_Pump"},
//        {text: "channel_Peristaltic_Recirculating_Pump", val: "channel_Peristaltic_Recirculating_Pump"},
       {text: "channel_Peristaltic_Batch_Pump", val: "channel_Peristaltic_Batch_Pump"},
       {text: "channel_Peristaltic_Balance_Pump", val: "channel_Peristaltic_Balance_Pump"},
       {text: "channel_Fill_Valve", val: "channel_Fill_Valve"},
//        {text: "channel_Vent_Roof", val: "channel_Vent_Roof"},
//        {text: "channel_Vent_Retractable_Roof", val: "channel_Vent_Retractable_Roof"},
//        {text: "channel_Vent_Side_Wall", val: "channel_Vent_Side_Wall"},
       {text: "channel_Curtain", val: "channel_Curtain"},
       {text: "channel_Mix_Valve", val: "channel_Mix_Valve"},
//        {text: "channel_Proportional_Microzone", val: "channel_Proportional_Microzone"},
//        {text: "channel_PID", val: "channel_PID"},
//        {text: "channel_Variable_Out", val: "channel_Variable_Out"},
       ],
     }
   }
   
   initExtra2Menu=()=>{
     this.extra2Menu={
       func: this.funcExtra1,
       menuEntries: [
       {text: "zone_Stages", val: "zone_Stages"},
       {text: "zone_Fallback", val: "zone_Fallback"},
       {text: "zone_Output", val: "zone_Output"},
       {text: "zone_History", val: "zone_History"},
       {text: "zone_Units", val: "zone_Units"},
       {text: "zone_Irrigation", val: "zone_Irrigation"},
       {text: "zone_Lighting", val: "zone_Lighting"},
       {text: "zone_Alarms", val: "zone_Alarms"},
//        {text: "zone_Smartcool", val: "zone_Smartcool"},
       {text: "zone_H-C_Demand", val: "zone_H-C_Demand"},
       {text: "zone_Setpoints", val: "zone_Setpoints"},
       {text: "zone_SP_Drive_to_Avg", val: "zone_SP_Drive_to_Avg"},
       {text: "zone_SP_Influence_Factors", val: "zone_SP_Influence_Factors"},
//        {text: "zone_SP_Retractable_Greenhouse", val: "zone_SP_Retractable_Greenhouse"},
       {text: "zone_Hum_DeHum", val: "zone_Hum_DeHum"},
       {text: "zone_Aux_Controls", val: "zone_Aux_Controls"},
//        {text: "zone_Pump_Schedule", val: "zone_Pump_Schedule"},
//        {text: "zone_Sensors", val: "zone_Sensors"},
       {text: "zone_Deadband", val: "zone_Deadband"},
       {text: "zone_Peristaltic", val: "zone_Peristaltic"},
       ],
     }
   }
   
   initExtra3Menu=()=>{
     this.extra3Menu={
       func: this.funcExtra1,
       menuEntries: [
       {text: "unit_Input_Mapping", val: "unit_Input_Mapping"},
       {text: "unit_Analog_Temp_Mapping", val: "unit_Analog_Temp_Mapping"},
       {text: "unit_Irrigation_Sensor_Mapping", val: "unit_Irrigation_Sensor_Mapping"},
       {text: "unit_Vent_Position_Mapping", val: "unit_Vent_Position_Mapping"},
       {text: "unit_Mixing_Tanks", val: "unit_Mixing_Tanks"},
       {text: "unit_Generic_Mapping", val: "unit_Generic_Mapping"},
       {text: "unit_Network_Sensors", val: "unit_Network_Sensors"},
       {text: "unit_Accumulator", val: "unit_Accumulator"},
       {text: "unit_Input_Calibration", val: "unit_Input_Calibration"},
       {text: "unit_Analog_Temp_Calibration", val: "unit_Analog_Temp_Calibration"},
       {text: "unit_Soil_Moisture_Calibration", val: "unit_Soil_Moisture_Calibration"},
       {text: "unit_Vent_Position_Calibration", val: "unit_Vent_Position_Calibration"},
       {text: "unit_Mixing_Tank_Calibration", val: "unit_Mixing_Tank_Calibration"},
       {text: "unit_Generic_Calibration", val: "unit_Generic_Calibration"},
       {text: "unit_Input_Multipliers", val: "unit_Input_Multipliers"},
       {text: "unit_Miscellaneous", val: "unit_Miscellaneous"},
       ],
     }
   }
   
   checkDismiss=()=>{
     if(this.keyDown=="Escape"){
       if(this.state.contextOpen){this.setState({contextOpen:false})}
       if(this.state.infoOpen){this.setState({infoOpen:false,infoEdit:false})}
    }
  }

  checkOCT=(e)=>{
    let key=e.key
    let st=this.state
    let res=checkOnChangeKey(key)
    switch(res?.cmd){
//       case "showHideTestControl":
//         let val=(res.val==undefined)?!(st.showTestControl||false):res.val
//         this.setState({showTestControl:val})
//         break
      default:
        break
    }

  }
   
   doKeyUpDown=(type,e)=>{
//      cl(type)
//      cl(e)
     let key=e.key
     switch(type){
       case "keyDown":
         if(this.keyDown!=key){// to prevent repeat?
//            cl(key)
           globs.events.publish("keyDown",e)
           this.keyDown=key
           globs.keyDowns[key]=true
           this.checkOCT(e)
         }
         break
       case "keyUp":
//         cl(e)
         this.checkDismiss()
         this.keyDown=null
           globs.events.publish("keyUp",e)
           globs.keyDowns[key]=false
         break
       default:
         break
    }
  }
  
  saveInfo=async()=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/info", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {infoId:this.state.infoId,infoText:this.state.infoText,
        ts:Math.floor(getTime())}})
  }
  
  loadInfoPage=async(infoId)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/info", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {infoId:infoId}})
//     cl(res)
//     cl(infoId)
    return (res.data||[])[0]?.infoText||"Empty Page"
  }
  loadAllInfoPages=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/info", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    // cl(res)
    this.setState({infoPages:res.data})
    // return res.data
  }
  
  checkIfMobile = ()=>window.innerWidth <= 768;

   loadValidIdsInDOM=async()=>{
    // cl(["load valid ids in dom", this.state.helpMode])
    // load ids with infopages and setAttribute
    // const elementsWithId = document.querySelectorAll('[data-context]');
    // cl(["load valid ids in dom", this.state.helpMode])
    // load valid ids in dom
    const isMobile = this.checkIfMobile();
    let elementsWithContext
    if (this.state.helpMode) {
      elementsWithContext = document.querySelectorAll('[data-context]');
      elementsWithContext.forEach(element => {
        const { id } = element;
        // Check if the id has an info page
        const hasInfoPage = this.state.infoPages.some(page => page.infoId === id);
        if (hasInfoPage && isMobile) {
          // Highlight CSS on these DOM elements
          element.classList.remove('highlighted-element');
          element.removeAttribute("data-context");
          // Add click event listener for info page popup
          element.removeEventListener('click',this.showContextOnMobile);
        }
      });
    } else {
      const elementsWithId = document.querySelectorAll('[id]');
      let validElements = Array.from(elementsWithId).filter(element => element.id.trim() !== '');
      const excludedIds = ["mainUsaDiv"];
      validElements = validElements.filter(el => !excludedIds.includes(el.id))
      // cl(validElements.map(el=>el.id))

      validElements.forEach(element => {
        const { id } = element; 
        // Check if the id has an info page
        const hasInfoPage = this.state.infoPages.some(page => page.infoId === id);
        if (hasInfoPage && isMobile) {
          element.setAttribute("data-context", id);
        }
      });
      // only data context el
      elementsWithContext = document.querySelectorAll('[data-context]');
      elementsWithContext.forEach(element => {
        const { id } = element;
        element.addEventListener('click', this.showContextOnMobile);
        element.classList.add('highlighted-element');
      })
    }

    this.setState({ 
      helpMode: !this.state.helpMode 
    });

    // elementsWithId.forEach(element => {
    //   const { id } = element; 
    //   // Check if the id has an info page
    //   const hasInfoPage = this.state.infoPages.some(page => page.infoId === id);
    //   if (hasInfoPage && isMobile) {
    //     // Highlight CSS on these DOM elements
    //     element.classList.add('highlighted-element');
    //     // Add click event listener for info page popup
    //     element.addEventListener('click', this.showContextOnMobile);
    //   }
    // });
  }

  showContextOnMobile =async (e)=> {
    e.preventDefault(); // Prevent default action if it's a link or button
    e.stopPropagation()
    // this.contextMenu(e)
    let firstId=this.getFirstId(e.target)
    // cl(firstId)
    // cl(firstId.id)
    var obj
    try{
      obj=JSON.parse(firstId.id)
    }catch (e) {
      obj=firstId
      obj.cmd = firstId.id
    }
    let id=e.target.id||firstId.id
    if(id.indexOf("/usa/c18")==0){id=id.substring(8)}
    if(id.indexOf("/fui/")==0){id=id.split("/")[2]}
    if(["main"].includes(id)){id=""}
    if(!this.state.infoOpen){
      let [infoId, infoText] = await this.fixInfoId(id);
      // cl("🚀 ~ Usa ~ showContextOnMobile= ~ infoId:", "infoId"+infoId, "e.id"+e.target.id)
      this.setState({
        infoOpen: true,
        infoText: infoText,
        infoId: infoId,
      });
    }
  }
  loadChat=async(chatId)=>{
    let now=Math.floor(getTime())
//     cl(chatId)
//     let query={chatId:chatId,userId:globs.userData.session.userId,ts:{$gte:now-3600,$lt:now}}
    let query={chatId:chatId}
//       cl(query)
//       cl(chatId)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/infoChat", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: query})
//     cl(res.data)
    return res.data
//     this.setState({chatId:chatId,curChat:res.data})
  }
  
  loadChats=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/chatMembers", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {userId:globs.userData.session.userId}})
    let chats=res.data
//     cl(chats)
    return chats
//     this.setState({chatId:chats[0].chatId,chats:chats})
  }
   
  sendChat=async()=>{
    cl(this.props)
    cl(this.state)
    await loadUsersInfo()
    let name=globs.usersInfo.info[getUserIndex(globs.userData.session.userId)].name
//     cl(name)
//     cl(this.state.chatId)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/infoChat", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: {chatId:this.state.chatId,chatText:this.state.inChatText,
        userId:globs.userData.session.userId,name:name,
        url:this.props.location.pathname,
        ts:Math.floor(getTime())
      }})
      this.setState({inChatText:""})
  }
  
  notifySound=()=>{
    this.audio=new Audio(notify1)
    this.audio.autoplay=true
  }
  
  getUser=async(userId)=>{
    return await wsTrans("usa", {cmd: "cRest", uri: "/su/allUsers", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {userId:userId}})
  }
  
  receiveChatText=async(msg)=>{
//     cl(this.state.chatArr)
//     cl(msg)
    if(this.state.chatArr&&(msg.chatId==this.state.chatId)){
      let chatArr=this.state.chatArr.slice(0)
      chatArr.push({
        chatId:msg.chatId,
        chatText:msg.body,
        name:msg.name,
        ts:msg.ts,
        userId:msg.userId,
      })
      await wsTrans("usa", {cmd: "cRest", uri: "/s/chatMembers", method: "update", 
        sessionId: globs.userData.session.sessionId,
        body: {chatId:this.state.chatId,userId:globs.userData.session.userId,
          readTime:Math.floor(getTime()),
        }})
      this.setState({chatArr:chatArr})
    }
  }
  
  chatNotification=async(msg)=>{
    this.notifySound()
//     var res
//     res=await this.getUser(msg.userId)
//     if(res.data){
//       cl(res.data)
//       let user=res.data[0]
//       let chatName=msg.chatName
      let res=await this.rest.child({uri:"getPopup",method:"create",body:{type:"admin",vals:{text:`Do you want to join the ${msg.chatName} Chat?`,buttons:["Yes","No"]}}})
      if(res=="Yes"){
//         let userId=globs.userData.session.userId
//         await wsTrans("usa", {cmd: "cRest", uri: "/s/chatMembers", method: "create", 
//           sessionId: globs.userData.session.sessionId, body: {
//             chatId:msg.userId,userId:userId,
//           }})
        cl(msg.chatId)
        let chats=await this.loadChats()
        let chatArr=await this.loadChat(msg.chatId)
        cl(chatArr)
        this.setState({chatId:msg.chatId,infoOpen:true,chats:chats,chatOpen:true,chatArr:chatArr})
      }
//       cl(res)
//     }
  }
  
  makeChatId=(users)=>{
    users.sort()
    return users.join("&")
  }
  
//   getSuperUserFlags=()=>{
// //     cl(globs.privsInfo)
//     let su=globs.privsInfo.info.filter(pr=>{return pr.level=="super"})
// //     cl(su)
//     return (su[0]||{}).flags
//   }
  
  notifySupport=async()=>{
    await loadPrivsInfo()
    await loadUsersInfo()
    let superUser= getSuperUserFlags()&constant.SUPER_PRIVS_SUPPORT
//     let superUser=globs.privsInfo.info.filter(pr=>{return pr.level=="super"})
//     cl(superUser)
    let userId=globs.userData.session.userId
    if(!superUser){// *not* superUser
      
      let users=["1S1tSCDsWatsjHeD"]
      let user=globs.usersInfo.info[getUserIndex(userId)]
      let chatName=`${user.name}/Support`
      let usersAll=users.slice(0)
      usersAll.push(userId)
      let chatId=this.makeChatId(usersAll)// user and support IDs
      
      users.forEach(async u=>{
        let msg={
          userId:u,
          msg:{cmd:"chatNotification",chatId:chatId,chatName:chatName,body:""}}
        await wsTrans("usa", {cmd: "cRest", uri: "/s/sendUserMessage", method: "create", 
          sessionId: globs.userData.session.sessionId, body: msg})
      })
      await wsTrans("usa", {cmd: "cRest", uri: "/s/chatMembers", method: "create", 
        sessionId: globs.userData.session.sessionId, body: {
          chatId:chatId,chatName:chatName,
        }})
      return chatId
    }
  }
  
  onInfoClose = () => {
    const elementsWithId = document.querySelectorAll('[data-context]');
    if(this.state.helpMode){
      elementsWithId.forEach(element => {
        const { id } = element;
        // Check if the id has an info page
        const hasInfoPage = this.state.infoPages.some(page => page.infoId === id);
        if (hasInfoPage) {
          // Highlight CSS on these DOM elements
          element.classList.remove('highlighted-element');
          // Add click event listener for info page popup
          element.removeEventListener('click', this.showContextOnMobile);
        }
      });
    }
    this.onChange("infoClose")
  }

   onChange=async(type,vals)=>{
//      cl(type,vals)
     let st=this.state
     var infoId,infoText
     switch(type){
       case "infoClose":
         if(st.infoEdit){
          this.setState({infoEdit:false,chatOpen:false,helpMode:false})
        }else{
          this.setState({infoOpen:false,chatOpen:false,helpMode:false})
         }
         break
       case "infoSave":
         this.saveInfo()
          this.setState({infoEdit:false})
         break
       case "infoEdit":
         this.setState({infoEdit:true})
         break
       case "infoChat":
//          let st=this.state
         if(!st.chatOpen){// if opening
          await loadPrivsInfo()
          let superUser=getSuperUserFlags()&constant.SUPER_PRIVS_SUPPORT
//           globs.privsInfo.info.filter(pr=>{return pr.level=="super"})
          var chatId
//           let chatId=(superUser)?st.chatId:globs.userData.session.userId
//           cl(chatId)
          let chats=[]
          if(!superUser){
            chatId=await this.notifySupport()// new chatId
          }else{
            chats=await this.loadChats()
            chatId=chats[0]?.chatId
          }
          let chatArr=await this.loadChat(chatId)
//           cl(chatId)
//           cl(chatArr)
          this.setState({chatOpen:true,chatId:chatId,chats:chats,chatArr:chatArr})
        }else{
          this.setState({chatOpen:false})
        }
         break
       case "infoBack":
         cl(this.infoHistory)
         if(this.infoHistory.length<2){return}
         this.infoHistory.pop()
         infoId=this.infoHistory.at(-1)
          infoText=await this.loadInfoPage(infoId)
          this.setState({infoText:infoText,infoId:infoId})
         break
       case "infoHome":
         infoId="/sites"
         infoText=await this.loadInfoPage(infoId)
         this.setState({infoText:infoText,infoId:infoId})
         break
       case "infoText":
       case "setState":
//          cl(vals)
         this.setState(vals)
         break
       case "keyDown":
       case "keyUp":
         this.doKeyUpDown(type,vals.e.key)
         break
       case "chatText":
//          cl(vals.e)
//          cl(vals.e.charCode)
         if(vals.e.charCode==13){
           vals.e.preventDefault()
           this.sendChat()
        }
         break
       case "setChat":
         let chatArr=await this.loadChat(vals.chatId)
         this.setState({chatId:vals.chatId,chatArr:chatArr})
         break
       case "host00":
//          cl(type,vals)
         if(this.state.infoOpen){
          let infoId=this.processOurUrl(vals.url)
          infoText=await this.loadInfoPage(infoId)
          this.setState({infoText:infoText,infoId:infoId})
//           cl(infoId)
        }
         break
    }
  }
   
   inputField=()=>{
      return(
        <div>
        <InputLabel htmlFor="unknown">{"The Title"}</InputLabel>
        <Input
        onChange={this.onChange}
        value={this.props.value}
        />
        </div>
      );
/*
 *        endAdornment={<InputAdornment position="start">
 *          {"unit"}
 *          </InputAdornment>}
 * //           inputProps={{
 * //             step: this.props.custom.step,
 * //              min: this.props.custom.min,
 * //              max: this.props.custom.max,
 * //              type: "number", // this.state.type
 * //           }}
 */
    }
    
    logInOut=()=>{
      cl("login");
    }
   
    barClick=async(e)=>{
      let cmd=e.currentTarget.id;
      switch(cmd){
        case "home":
          let dash=await getHomeDashboard()
//           userSpecificHome()
          if(dash){history.push(`/usa/dash/${dash}`)}
          break
        case "back":
          history.goBack()
          break
        default:
          break
      }
      // cl(cmd)
//       let cmds={loginout: this.logInOut()};
//       if(cmds[cmd]) cmds[cmd]();
//       cl(e.currentTarget.id);
//       cl(this.props);
//       history.push("/usa/dash/vz4sci_m-iHC9nQZ")
       
    }
    
    popupDone=(result)=>{
//       cl(result);
      this.setState({popup: null});
    }

// /****************** Test Control Routines *******************************/
//
//     selectTestConfig=()=>{
//       let st=this.state
//       let configs=st.testConfigs.map(tc=>{return{v:tc.id,t:tc.name}})
//       return(
//         <div>
//           <C18Select01 parms={{
//             label:"Test Configurations",
//             priority:this.state.selTestConfig,
//             valueName:"selTestConfig",
//             opts:configs,
//             onChange:(e,v)=>{this.onChange("selTestConfig",v)}//this.onChange,
//           }}/>
//         <div className="clearfloat"></div>
//
//         <C18Button00 type="button" className="filled"
//         onClick={()=>this.onChange("saveTestConfig")}>Save</C18Button00>
//         <C18Button00 type="button" className="danger"
//           style={{marginLeft:20}}
//           onClick={e=>{this.onChange("deleteTestConfig")}}
//         >Delete</C18Button00>
//
//         </div>
//       )
//     }
//
//     showTCEdit=()=>{
//       let st=this.state
//       return(
//         <div>
//           <label htmlFor="config-name">Config Name</label>
//           <C18Input00 type="text" id="config-name"
//             value={st.configName}
//             onChange={e=>this.onChange("configName",{configName:e.currentTarget.value})}
//           />
//           <C18Button00 type="button" className="filled"
//           onClick={()=>this.onChange("restoreTestConfig")}>Restore</C18Button00>
//         </div>
//       )
//     }
//
    showTestControl=()=>{
      let st=this.state
      return(
        <C18TestConfigs00
          parms={{
            showTestControl:st.showTestControl,
          }}
        />
      )
//       if(!st.showTestControl){return}
//       let wid=window.innerWidth
//       let hgt=window.innerHeight
// //       cl(window.innerHeight)
// //       cl(hgt)
//       let dWid=400
//       let dHgt=200
//       let x=(wid-dWid)/2
//       let y=(hgt-dHgt)/2
// //       cl(y)
//       return(
//         <div style={{position:"absolute",left:x,top:y,zIndex:20,width:dWid,
//           backgroundColor:"white",borderStyle:"solid",borderWidth:1,borderRadius:10,
//           padding:20,
//
//         }}>
//           <h3 style={{textAlign:"center"}}>Test Control</h3>
//           {this.selectTestConfig()}
//           <div className="clearfloat"></div><br/>
//           {this.showTCEdit()}
//         </div>
//       )
    }

// /****************** End Test Control Routines *******************************/

    showWaitScreen=()=>{
      let st=this.state
      if(st.waiting){
        let progLevel=Math.floor(8*st.cursorProgress)
        if(progLevel>7){progLevel=7}
//         let url=`http://stggrn.link4cloud.com:3105/usa/images/cursors/prog${progLevel}.png`
        let url=`/progs/prog${progLevel}.png`
//         cl(url)
        let cursor=(st.cursorProgress>=0)?
          `url("${url}"),auto`:
          "wait"
//         cl(cursor)
//         cursor="wait"
        return(
          <div style={{position:"absolute",left:0,top:0,width:"100%",height:"100%",opacity:0.1,backgroundColor:"gray",cursor:cursor,zIndex:6}}>
          </div>
        )
      }else{return null}
    }

    setWaiting=(val)=>{// wait should be supplied as a binary flag
//       cl(val)
      this.setState({waiting:val.wait})

    }

    setProgress=(val)=>{
//       cl(val)
      this.setState({cursorProgress:val.prog,waiting:(val.prog>=0)})

    }

    onMsg=(msg)=>{
//       cl(msg)
      switch(msg.id){
        case "editWidget":
          this.setState({popup: "editWidget", dashboardId: msg.dashboardId, dashboardAcct: msg.dashboardAcct, widgetId: msg.widgetId})
          break
        default:
          break
      }
    }

    notify=(note)=>{// used here to notify widgets of a change in drag position
      if(note.id in this.notifies){}else{
        this.notifies[note.id]=[]
      }
      this.notifies[note.id].push(note.func)
    }
    
    setMainBarTitle=(title)=>{
//       cl(parms)
      document.title=title;
      this.setState({mainBarTitle: title})
    }
    
   
    render(){
      let st=this.state
//       let {id}=useParams()
//       cl(id)
//       cl("usa render")
//       cl(globs.userData)
/* When there is a change in url this will be called again, and the props.match fields will be updated*/
//       cl(`render Usa ${this.mode}`)
//       cl(this.props)
//       cl(this.props)
//      cl(this.mode);
//      cl("render");
//      cl(this.props.match.params)
//      cl(this.props);
//      cl(this.props.match.url)
//     cl(this.props)
     let parts=this.props.match.url.split("/")
//      cl(parts)
//      cl(this.state)
//       cl(this.mode)
     if(parts[2]=="c18"){
       this.mode="c18"
    }else{
      this.mode=(this.props.match.params.p1) ? this.props.match.params.p1 : "c18";
     }
//      cl(this.props.match.params)
     if(this.mode=="c18"){
//        cl(parts,this.mode)
       this.c18Mode=parts[3]||"login"
    }

    if (((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)) {
      this.image = <img draggable="false" width="20" src="/resize-edited.png"/>
    }

    else {
      this.image = <img draggable="false" width="20" src="/resize.png"/>
    }
//     return null
//      if(this.props.match.params.p1=="c18"){return null}// assuming this was for testing
//      cl(this.mode);

//      cl(`usa render ${this.mode}, ${this.props.match.url}`)
//       cl(this.mode)
// onKeyDown={e=>this.onChange("keyDown",{e:e})} onKeyUp={e=>this.onChange("keyUp",{e:e})}
//     cl(this.c18Mode)
//     cl(this.mode)
     return(
       <div id="mainUsaDiv">
      {this.mode!="c18"&&
        <MainBar home back extra0={this.extra0Menu} extra1={this.extra1Menu} extra2={this.extra2Menu} extra3={this.extra3Menu} settings
            menu click={this.barClick} title={this.state.mainBarTitle} />
      }
      {this.mode == "activate" && 
        <Register mode={this.mode} token={parts[4]}/>}
      {this.mode == "register" && 
        <Register mode={this.mode}/>}
      {this.mode == "resetPasswordxx" && 
        <ResetPassword mode={this.mode} token={this.props.match.params.p2}/>}
      {this.mode == "postRegister" && 
        <PostRegister mode={this.mode}/>}
      {this.mode == "manageAccess" && 
        <ManageAccess mode={this.mode}/>}
      {this.mode == "manageGateways" && 
        <ManageGateways mode={this.mode}/>}
      {this.mode == "manageAlarms" && 
        <ManageAlarms mode={this.mode}/>}
      {this.mode == "manageZones" && 
        <ManageZones mode={this.mode}/>}
      {this.mode == "manageControllers" && 
        <ManageControllers mode={this.mode}/>}
      {this.mode == "manageUsers" && 
        <ManageUsers mode={this.mode}/>}
      {this.mode == "manageSites" && 
        <ManageSites mode={this.mode}/>}
      {this.mode == "manageSiteAccess" && 
        <ManageSiteAccess mode={this.mode}/>}
      {this.mode == "inviteUser" && 
        <InviteUser mode={this.mode} token={this.props.match.params.p2}/>}
      {this.mode == "vis" && 
        <Demo1 mode={this.mode}/>}
      {this.mode == "dash" && 
        <DashDisplay00 page={this.props.match.params.p2} zuci={this.props.match.params.p3}
          onMsg={this.onMsg}/>}
      {this.mode == "c18" && 
        <C18Host00 page={this.c18Mode} url={this.props.match.url}
        loadDOMIds={this.loadValidIdsInDOM}
        rest={this.rest}
        onChange={e=>{this.onChange("host00",e)}}
        parms={{setPopup:this.setPopup,search:this.props.location.search}}/>}
      {this.mode == "growJournal" && 
        <GrowJournal00 mode={this.mode} growJournalId={this.props.match.params.p2}/>}
      {this.mode == "growJournalView" && 
        <GrowJournalView00 mode={this.mode}/>}
      {this.mode == "dataVisualization" && 
        <DataVisualization00 mode={this.mode} screenSel={this.props.match.params.p2}/>}
      {this.mode == "login" && 
        <Login/>}
      {this.mode == "status" && 
        <Status/>}
      {this.mode == "userProfile" && 
        <UserProfile/>}
      {this.mode == "manageAccount" && 
        <ManageAccount/>}
      {this.mode == "iDoser" && 
        <IDoser/>}
      {this.state.popup == "setSite" && 
        <SetSite done={this.popupDone}/>}
      {this.state.popup == "editWidget" && 
        <EditWidget done={this.popupDone} parms={{dashboardId: this.state.dashboardId, dashboardAcct: this.state.dashboardAcct, 
          widgetId: this.state.widgetId}}/>}
        {this.showHelpMenu()}
        {this.showInfo()}
        {this.showTestControl()}
        {this.showWaitScreen()}
       </div>
     );
   }
 }

 export default Usa ;
