import React from 'react';
import C18Button00 from './C18Button00'
import C18Tags01 from './C18Tags01'
import { DateRangePicker } from 'react-date-range';
import CreatableSelect from 'react-select/creatable'
import {loadUsersInfo,loadSitesInfo,loadZonesInfo} from './C18utils'
import {cl,globs,constant,dateToDisplayDate,getTime} from '../../components/utils/utils';
import {wsTrans} from '../utils/utils'

class C18Filter00 extends React.Component{
  constructor(props) {
    super(props);
    let now=getTime()
    this.state={
      loaded:false,
      tags:[],
      filterTags:[],
      fromTime:now-86400,
      toTime:now,
      filterTags:false,
      filterDate:false,
      filterUsers:false,
      filterSites:false,
      filterZones:false,
    }
    this.allUsersShow=false
    this.allSitesShow=false
    this.allZonesShow=false
    this.notifies=[]
    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
    this.loadInfo()
  }
  
  loadInfo=async()=>{
    await loadUsersInfo()
    await loadSitesInfo()
    await loadZonesInfo()
    let users=[]
    globs.usersInfo.info.forEach(u=>{
      users.push({show:false,name:u.name,email:u.email,avatar:u.avatar,userId:u.userId})
    })
    users.sort((a,b)=>{
      let af=(a.name||"").toLowerCase()
      let bf=(b.name||"").toLowerCase()
      if(af>bf){return 1}
      if(af<bf){return -1}
      return 0
    })
    let sites=[]
    let siteLU={}
    globs.sitesInfo.info.forEach(s=>{
      sites.push({show:false,siteId:s.siteId,name:s.name})
      siteLU[s.siteId]=s.name
    })
//     cl(sites)
    

    let zones=[]
    globs.zonesInfo.info.forEach(z=>{
      let name=`${siteLU[z.siteId]} - ${z.zoneName}`
      zones.push({show:false,zoneId:z.zoneId,name:name})
    })
//     cl(zones)

    this.setState({loaded:true,users:users,sites:sites,zones:zones})
    
 /*   this.state.users=users
    this.state.sites=sites
 */ }
 
  makeFilter=()=>{
    let st=this.state
    let fields={date:"filterDate",sites:"filterSites",tags:"filterTags",users:"filterUsers",
      zones:"filterZones"}
//     let useFields=Object.keys(fields).filter(k=>{return st[fields[k]]}).map(k=>{return k})
//     cl(useFields)
//     let ret={fields:useFields}
    let ret={}
    Object.keys(fields).filter(k=>{return st[fields[k]]}).forEach(f=>{
//       cl(f)
      switch(f){
        case "tags":
          ret.tags=st.tags.slice(0)
          break
        case "date":
          ret.from=st.fromTime
          ret.to=st.toTime+86400// include the last shown date
          break
        case "sites":
          ret.sites=st.sites.filter(s=>{return s.show}).map(s=>{return s.siteId})
          break
        case "zones":
          ret.zones=st.zones.filter(z=>{return z.show}).map(z=>{return z.zoneId})
//           ret.zones=st.zones.slice(0)
          break
        case "users":
          ret.users=st.users.filter(u=>{return u.show}).map(u=>{return u.userId})
//           ret.users=st.users.slice(0)
          break
      }
    })
//     useFields.forEach(f=>{
//     })
    return ret
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    let pa=this.props.parms
    switch(type){
      case "filter":
        vals.filter=this.makeFilter()
//         cl("filter")
//         cl(st)
        this.doNotify("useTags")// there may be new tags that need to be saved
      case "cancel":
        vals.cmd=type
        pa.onChange(vals)
        break
      case "timePeriod":
        let fromTime=Math.floor(vals.selection.startDate.getTime()/1000)
        let toTime=Math.floor(vals.selection.endDate.getTime()/1000)
        this.setState({fromTime:fromTime,toTime:toTime})
        break
      case "check":// checkUsers
        let users=this.state.users.slice(0)
        if(vals.userId==-1){
          this.allUsersShow=vals.checked
          users.forEach(u=>{u.show=vals.checked})
        }else{
          let user=users.filter(u=>{return u.userId==vals.userId})[0]
          user.show=vals.checked
        }
        this.setState({users:users})
        break
      case "checkSite":
        cl(vals)
        let sites=this.state.sites.slice(0)
        if(vals.siteId==-1){
          this.allSitesShow=vals.checked
          sites.forEach(s=>{s.show=vals.checked})
        }else{
          let site=sites.filter(s=>{return s.siteId==vals.siteId})[0]
          site.show=vals.checked
        }
        this.setState({sites:sites})
        break
      case "checkZone":
        cl(vals)
        let zones=this.state.zones.slice(0)
        if(vals.zoneId==-1){
          this.allZonesShow=vals.checked
          zones.forEach(z=>{z.show=vals.checked})
        }else{
          let zone=zones.filter(z=>{return z.zoneId==vals.zoneId})[0]
          zone.show=vals.checked
        }
        this.setState({zones:zones})
        break
      case "upd":
        this.setState(vals)
        break
      case "tags":
        this.setState({tags:vals.tags})
        break
    }
  }
  
  notify=(note)=>{
    if(note.unMount){// called when widget is unmounted
      this.notifies[note.id].forEach((n,i)=>{
        if(n==note.func){
          this.notifies[note.id].splice(i,1)
        }
      })
    }else{
      if(note.id in this.notifies){}else{
        this.notifies[note.id]=[]
      }
      this.notifies[note.id].push(note.func)
    }
  }
  
  doNotify=(event,cmd,data)=>{
    if(event in this.notifies){
      this.notifies[event].forEach(f=>{
        f({cmd:cmd, data:data})
      })
    }
  }
  
  showDateRange=()=>{
//     let now=getTime()
//     let cp={fromTime:now-86400, toTime:now}
    let st=this.state
    let tp={
      startDate: new Date(1000*st.fromTime),
      endDate: new Date(1000*st.toTime),
      key:'selection',
    }
    return(
      <div style={{display:(st.filterDate)?"block":"none"}} className="time-period-container">
        <DateRangePicker
          ranges={[tp]}
          onChange={o=>{this.onChange("timePeriod",o)}}
          inputRanges={[]}
        />
      </div>
    )
  }
  
  showUsers=()=>{
//     cl(globs.usersInfo.info)
    let st=this.state
    let users=[
      <tr key={"all"}>
      <td width="25" height="35"><input type="checkbox"
        checked={this.allUsersShow}
        onChange={e=>this.onChange("check",{userId:-1,checked:e.currentTarget.checked})}
      /></td>
      <td width="50"></td>
      <td>All Users</td>
      </tr>
    ]
    this.state.users.forEach((u,i)=>{
      let av=u.avatar
      var path
      if(av){
        path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
      }else{
        path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
      }
      users.push(
        <tr key={i}
        >
        <td width="25"><input type="checkbox"
          checked={u.show}
          onChange={e=>this.onChange("check",{userId:u.userId,checked:e.currentTarget.checked})}
        /></td>
        <td width="50"><img width="35" height="35" src={path} alt={u.name}/></td>
        <td>{u.name}</td>
        </tr>
      )
    })
    return(
      <div
        style={{height:300,padding:20,borderStyle:"solid",borderWidth:1,borderRadius:10,
          overflowY:"auto",display:(st.filterUsers)?"block":"none"
        }}
      >
        <table><tbody>
        {users}
        </tbody></table>
      </div>
    )
  }
  
  showSites=()=>{
//     cl(globs.usersInfo.info)
    let st=this.state
//     cl(st)
    let sites=[
      <tr key={"all"}>
      <td width="25" height="25"><input type="checkbox"
        checked={this.allSitesShow}
        onChange={e=>this.onChange("checkSite",{siteId:-1,checked:e.currentTarget.checked})}
      /></td>
      <td>All Sites</td>
      </tr>
    ]
    this.state.sites.forEach((s,i)=>{
      sites.push(
        <tr key={i}
        >
        <td width="25" height="25"><input type="checkbox"
          checked={s.show}
          onChange={e=>this.onChange("checkSite",{siteId:s.siteId,checked:e.currentTarget.checked})}
        /></td>
        <td>{s.name}</td>
        </tr>
      )
    })
    return(
      <div
        style={{height:300,padding:20,borderStyle:"solid",borderWidth:1,borderRadius:10,
          overflowY:"auto",display:(st.filterSites)?"block":"none"
        }}
      >
        <table><tbody>
        {sites}
        </tbody></table>
      </div>
    )
    
  }

  showZones=()=>{
//     cl(globs.usersInfo.info)
    let st=this.state
//     cl(st)
    let zones=[
      <tr key={"all"}>
      <td width="25" height="25"><input type="checkbox"
        checked={this.allZonesShow}
        onChange={e=>this.onChange("checkZone",{zoneId:-1,checked:e.currentTarget.checked})}
      /></td>
      <td>All Zones</td>
      </tr>
    ]
    this.state.zones.forEach((z,i)=>{
      zones.push(
        <tr key={i}
        >
        <td width="25" height="25"><input type="checkbox"
          checked={z.show}
          onChange={e=>this.onChange("checkZone",
            {zoneId:z.zoneId,checked:e.currentTarget.checked})}
        /></td>
        <td>{z.name}</td>
        </tr>
      )
    })
    return(
      <div
        style={{height:300,padding:20,borderStyle:"solid",borderWidth:1,borderRadius:10,
          overflowY:"auto",display:(st.filterZones)?"block":"none"
        }}
      >
        <table><tbody>
        {zones}
        </tbody></table>
      </div>
    )
    
  }

  render(){
  //       className="image-popup"
    let st=this.state
    let pa=this.props.parms
//     cl(pa.fields)
//     cl(st)
    if(st.loaded){
      return(
        <div  className="image-popup"
          style={{
            display:(pa.display)?"block":"none",
            boxShadow:'5px 10px 10px #888888',
            overflowY:"auto",
            
            borderRadius:10,
            width:700,
            height:500,
            backgroundColor:(this.dark)?"#000000":"#FFFFFF",
            padding:20,
          }}
        >
        <section className="link4">
          <h3>Filter List</h3>
          {pa.fields.includes("tags")&&
            <>
              <input id="filterTags" type="checkbox"
              checked={st.filterTags}
              onChange={e=>this.onChange("upd",{filterTags:e.currentTarget.checked})}
              />
              <label htmlFor="filterTags" style={{display:"inline-block", marginLeft:10}}>Tags</label>
              <div style={{display:(st.filterTags)?"block":"none"}}>
                <C18Tags01 
                  notify={this.notify}
                  parms={{
                    onChange:e=>this.onChange("tags",{tags:e}),
                  }}
                />
              </div>
              <div className="clearfloat"></div><br/>
            </>
          }
          {pa.fields.includes("date")&&
            <>
              <input id="filterDate" type="checkbox"
              checked={st.filterDate}
              onChange={e=>this.onChange("upd",{filterDate:e.currentTarget.checked})}
              />
              <label htmlFor="filterDate" style={{display:"inline-block", marginLeft:10}}>
              Date Range</label>
              {this.showDateRange()}
              <div className="clearfloat"></div><br/>
              
            </>
          }
          {pa.fields.includes("users")&&
            <>
              <input id="filterUsers" type="checkbox"
              checked={st.filterUsers}
              onChange={e=>this.onChange("upd",{filterUsers:e.currentTarget.checked})}
              />
              <label htmlFor="filterUsers" style={{display:"inline-block", marginLeft:10}}>
              Users</label>
              {this.showUsers()}
              <div className="clearfloat"></div><br/>
            </>
          }
          
          
          {pa.fields.includes("sites")&&
            <>
              <input id="filterSites" type="checkbox"
              checked={st.filterSites}
              onChange={e=>this.onChange("upd",{filterSites:e.currentTarget.checked})}
              />
              <label htmlFor="filterSites" style={{display:"inline-block", marginLeft:10}}>
              Sites</label>
              {this.showSites()}
              <div className="clearfloat"></div><br/>
            </>
          }
          
          {pa.fields.includes("zones")&&
            <>
              <input id="filterZones" type="checkbox"
              checked={st.filterZones}
              onChange={e=>this.onChange("upd",{filterZones:e.currentTarget.checked})}
              />
              <label htmlFor="filterZones" style={{display:"inline-block", marginLeft:10}}>
              Zones</label>
              {this.showZones()}
              <div className="clearfloat"></div><br/>
            </>
          }
          
          <div className="clearfloat"></div><br/>
          <C18Button00 type="button" className="outlined"
            onClick={e=>this.onChange("filter",{})}>Filter</C18Button00>
          <C18Button00 type="button" className="outlined"
            style={{marginLeft:20}}
            onClick={e=>this.onChange("cancel",{})}>Cancel</C18Button00>
        </section>
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18Filter00;
