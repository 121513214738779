import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Select01 from './C18Select01'
import C18SiteZoneSub00 from './C18SiteZoneSub00'
import C18Button00 from './C18Button00'
import {wsTrans} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo, loadSubscriptionInfo, getZoneIndex,loadSiteData,getZoneInfo, onCheckout, 
  addToAdminLog, updateSubscription, getRecurlyAccount, getZonesCount, getZoneDiscount, getTotal, getLockedZones, getZoneCount,acctFeature,loadGatewaysInfo,getGatewayInfo} from './C18utils'
import {sendArray, dbVals, putZValue} from '../../components/utils/http';
import {cl, globs, getTime, allZones, allAddons, capitalize, round, zoneTypes, recurlySubdomain,getRandomString,getTimeI} from '../../components/utils/utils';
import history from "../../history"

class C18ManageZones extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      zoneAddName:"",
      zoneType:"link4",
      loaded:false,
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveZones)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Zones", url:`/usa/c18/admin/manageZones2`},
            ]},
        },
      )
    }
  }

  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  loadInfo=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
//     cl(globs.zonesInfo)
    await loadGatewaysInfo()
    let sub = globs.subscriptionInfo.info
    // this.saveZoneNames(false)// save the names to compare later
    this.saveZoneInfo(false)// save the names to compare later
    let siteSel=globs.sitesInfo.info[0].siteId
    let zoneSel=this.getZoneSel(siteSel)
    this.setState({loaded:true, siteSel:siteSel,zoneSel:zoneSel, oldPlan: sub,
      currPlan: JSON.parse(JSON.stringify(sub)), displayCheckout: false, subChange: false, unlockAllZones: false, newConfig: []})
  }

  onCancel = () => {
    // TODO un-disable outside controls
    globs.events.publish("savePageEnable",true)
    this.mySetState({displayCheckout:false})
//     this.mySetState({displayCheckout: false, plan: null, preview: null, currPlan: JSON.parse(JSON.stringify(this.state.oldPlan)),
//       unlockedAllZones: [], unlockAllZones: false, newConfig: []})
  }
  
  showSitesOpts=()=>{
    return globs.sitesInfo.info.map((s,i)=>{
      return(
        <option key={i} value={s.siteId}>{s.name}</option>
      )
    })
  }

  showZonesOpts=()=>{
    let gzi=globs.zonesInfo.info
//     cl(gzi)
    return gzi.map((z,i)=>{
      if(z.siteId==this.state.siteSel){
//         cl(z)
        return(
          <option key={i} value={z.zoneId}>{z.zoneName||"(No Name)"}</option>
        )
      }
    })
  }

  saveToAdminLog=(adds,o,n)=>{
//     cl(o)
//     cl(n)
    let addObj={
      userId:globs.userData.session.userId,
      siteId:o.siteId,
      zoneId:o.zoneId,
      time:Math.floor(getTime())
    }
    if(o.zoneName!=n.zoneName){
      adds.push(
        Object.assign({},addObj,
        {
        action:"zoneName",
        oldVal:o.zoneName,
        newVal:n.zoneName,
      }))
    }
    if(o.hideEquipment!=n.hideEquipment){
      adds.push(
        Object.assign({},addObj,
        {
        action:"hideEquipment",
        oldVal:o.hideEquipment,
        newVal:n.hideEquipment,
      }))
    }
  }

  saveZoneInfoToDb=async(data)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
      sessionId: globs.userData.session.sessionId, body: data})
    globs.events.publish("saveOK",true)
  }

  saveZoneName800=(zone,zoneName)=>{
    let body1={controllerId:zone.controllerId,controllerName:zoneName}
    wsTrans("usa", {cmd: "cRest", uri: "/s/iGrow800Controller", method: "update",
      sessionId: globs.userData.session.sessionId, body: body1})
  }
  
  saveZoneNameToDb=async(zone,zoneId, zoneName)=>{
    if(zone.gatewayType==800){this.saveZoneName800(zone,zoneName)}
    await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
      sessionId: globs.userData.session.sessionId, body: {
        zoneId: zoneId,
        zoneName: zoneName,
      }})
    globs.events.publish("saveOK",true)
  }
  
  saveZoneNameToControllerDb=async(zone,zoneName)=>{// z=gatewayZoneInd, c=255, i=5022 is zone name
// need to load site data!  
    await loadSiteData(zone.siteId)
    // cl(zone, zoneName)
    let pid=5022 // zone name
    let z=zone.siteZoneIndex
    let c=255
    let parms=[]
    if(putZValue(z,c,pid,zoneName)){
      parms.push({
        c:c,
        d:zoneName,
        f:1,
        i:pid,//iOfs+ofs,
        t:Math.floor(getTime()),
        z:z,
      })
    }
    // cl(parms)
    sendArray(parms).then(r=>{globs.events.publish("saveOK",true)})
  }

  saveZoneInfo=async(doSave)=>{// do Save, means save to db, not means copy current settings , newPlan means new plan will be created on recurly
    if(!doSave){
      this.zoneNames=[]
      this.hideEquipment=[]
    }
//     cl(this.zoneNames)
    let gzi=globs.zonesInfo.info
    let adminAdds=[]
    for(let i=0;i<gzi.length;i++){
      if(doSave){
        let zoneNew = {zoneId: gzi[i].zoneId}
        let zoneOld = {}
        let zoneChange = false
        if(this.zoneNames[i]!=gzi[i].zoneName) {
//           cl(`update ${gzi[i].zoneName}`)
// this is a little deceiving. zoneNames is what the names *used* to be
          zoneNew.zoneName = gzi[i].zoneName // was this.zoneNames[i]
          zoneOld.zoneName = this.zoneNames[i]
          this.saveZoneNameToControllerDb(gzi[i],gzi[i].zoneName)
          zoneChange = true
        }
        if (this.hideEquipment[i]!=(gzi[i].hideEquipment||false)){
          zoneNew.hideEquipment = gzi[i].hideEquipment
          zoneOld.hideEquipment = this.hideEquipment[i]
          zoneChange = true
        }
        if (zoneChange) {
          cl(zoneNew)
          this.saveZoneInfoToDb(zoneNew)
          this.saveToAdminLog(adminAdds, zoneOld, gzi[i])
        }
      }else{
        this.zoneNames.push(gzi[i].zoneName)
        this.hideEquipment.push(gzi[i].hideEquipment||false)
      }
//       if(gzi[i].siteId==siteId){
//         return gzi[i].zoneId
//       }
    }
//     cl(adminAdds)
    if(doSave){addToAdminLog(adminAdds)}
//     cl(this.zoneNames)
  }
  
  saveZoneNames=(doSave)=>{// do Save, means save to db, not means copy current settings
    if(!doSave){this.zoneNames=[]}
    let gzi=globs.zonesInfo.info
    let adminAdds=[]
    for(let i=0;i<gzi.length;i++){
      if(doSave){
        if(this.zoneNames[i]!=gzi[i].zoneName){
          // cl(`update ${gzi[i].zoneName}`)
          this.saveToAdminLog(adminAdds, {zoneName: this.zoneNames[i]}, gzi[i])
          // cl(gzi[i])
          this.saveZoneNameToDb(gzi[i],gzi[i].zoneId,gzi[i].zoneName)
          this.saveZoneNameToControllerDb(gzi[i],gzi[i].zoneName)
        }
      }else{
        this.zoneNames.push(gzi[i].zoneName)
      }
//       if(gzi[i].siteId==siteId){
//         return gzi[i].zoneId
//       }
    }
//     cl(adminAdds)
    if(doSave){addToAdminLog(adminAdds)}
//     cl(this.zoneNames)
  }
  
  getZoneSel=(siteId)=>{
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      if(gzi[i].siteId==siteId){
        return gzi[i].zoneId
      }
    }
  }

  saveZones=async(cmd)=>{
    if(cmd=="save"){
      globs.events.publish("saveOK",true)
      this.saveZoneInfo(true)// save to db
      // this.saveZoneNames(true)// save to db
    }
  }
  
  setZoneName=(zoneId,zoneName)=>{// should not be changing zonesInfo!
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      if(gzi[i].zoneId==zoneId){
        gzi[i].zoneName=zoneName
        return
      }
    }
  }

  setHideZoneEquipment=(zoneId,hideEquipment)=>{// should not be changing zonesInfo!
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      if(gzi[i].zoneId==zoneId){
        gzi[i].hideEquipment=hideEquipment
        return
      }
    }
  }

  deleteZone=async(zoneId)=>{// should not be changing zonesInfo! why not?
    let zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
//     cl(zone)
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Zone?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "delete", 
        sessionId: globs.userData.session.sessionId,
        body: {zoneId: zoneId,virtual:zone.virtual, siteId:zone.siteId,
          siteZoneIndex:zone.siteZoneIndex}})
      let ind=getZoneIndex(zoneId)// delete from zonesInfo
      globs.zonesInfo.info.splice(ind,1)
      this.mySetState({update:(this.state.updated||0)+1,
        zoneSel:this.getZoneSel(this.state.siteSel)
      })
    }
  }

  addZone=async()=>{
//     cl("add zone")
//     cl(this.state)
    let st=this.state
    let siteZones=globs.zonesInfo.info.filter(zo=>{return zo.siteId==st.siteSel})
    let inds={}
    siteZones.forEach(z=>{inds[z.siteZoneIndex]=1})
    var siteZoneIndex
    for(let i=0;i<40;i++){// find unused siteZoneIndex
      if(!inds[i]){siteZoneIndex=i; break}
    }
    let zone={
      siteId:st.siteSel,
      zoneId:getRandomString(16),
      zoneName:this.state.zoneAddName,
      gatewayType:1800,
      siteZoneIndex:siteZoneIndex,
      inNet:false,
      virtual:true,
      virtualType:st.zoneType,
      zoneTier:zoneTypes["locked"],
    }
//     cl(zone)
    globs.zonesInfo.info.push(zone)
//     cl(globs.zonesInfo)
    wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "create",
      sessionId: globs.userData.session.sessionId, body: zone})
    let configId=""
    var resp
    let vTemp=`VirtualZoneTemplate_${st.zoneType}`
    cl(vTemp)
    resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: {vTemp:vTemp}})
//     cl(resp)
    let zTemp=resp.data[0]
    cl(zTemp)
//       cl("restore")
      let query={
        configId:zTemp.configId,
        siteId:globs.userData.session.siteId,
        zoneInd:siteZoneIndex,
        virtual:true,
        ranges:[[0,100000]]}
//       cl(query)
      resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "update",
        sessionId: globs.userData.session.sessionId, body: query})
//     }
//     cl(st.update)
    this.setState({update:(st.update||0)+1})
//     cl(st)
  }
  
  mySetState=(vals)=>{
    this.setState(vals)
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "upd":
//         cl(type)
        this.setState(vals)
        break
      case "siteSel":
        Object.assign(vals,{zoneSel:this.getZoneSel(vals.siteSel)})
      case "zoneAddName":
      case "zoneSel":
        this.mySetState(vals)
        break
      case "siteZone":
//         cl(vals)
        this.mySetState({zoneSel:vals.zoneId})
        break
      case "zoneName":
        globs.events.publish("savePageEnable",true)
        this.setZoneName(this.state.zoneSel,vals.zoneName)
        this.mySetState(vals)
        break
      case "hideZoneEquipments":
        globs.events.publish("savePageEnable",true)
        this.setHideZoneEquipment(this.state.zoneSel,vals.hideEquipment)
        this.mySetState(vals)
        break
      case "deleteZone":
//         cl("del")
        this.deleteZone(this.state.zoneSel)
        break
      case "addZone":
        this.addZone();
        break
      default:
        break
    }
  }
  
//   showSelectSite=()=>{
// //     cl(this.state.siteSel)
//     return (
//       <div className="custom-select">
//         <label htmlFor="">Select Site</label>
//         <C18Select00 id=""
//           value={this.state.siteSel}
//           onChange={e=>this.onChange("siteSel",{siteSel: e.currentTarget.value})}
//         >
//           {this.showSitesOpts()}
//         </C18Select00>
//         <span className="material-icons down-arrow">
//           keyboard_arrow_down
//         </span>
//       </div> 
//     )
//   }
  
//   showSelectZone=()=>{
//     return(
//       <div className="custom-select">
//         <label htmlFor="">Select Zone</label>
//         <C18Select00
//           value={this.state.zoneSel}
//           onChange={e=>this.onChange("zoneSel",{zoneSel: e.currentTarget.value})}
//         id="">
//         {this.showZonesOpts()}
//         </C18Select00>
//         <span className="material-icons down-arrow">
//           keyboard_arrow_down
//         </span>
//       </div>
//     )
//   }
//
  showSelectZone=()=>{
    return(
      <div className="custom-select">
        <label htmlFor="">Select Zone</label>
        
        <C18Select00 id=""
          parms={{list:true}}
          value={this.state.zoneSel}
          onChange={e=>this.onChange("zoneSel",{zoneSel: e.currentTarget.value})}
        >
          {this.showZonesOpts()}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div> 
    )
  }
  
  showSelectSite=()=>{
    return(
      <div className="custom-select">
        <label htmlFor="">Select Site</label>
        
        <C18Select00 id=""
          parms={{list:true}}
          value={this.state.siteSel}
          onChange={e=>this.onChange("siteSel",{siteSel: e.currentTarget.value})}
        >
          {this.showSitesOpts()}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div> 
    )
  }
  
  showSelectZoneType=()=>{
    let types=[
    {v:"link4",t:"1800-Link 4"},
    {v:"iDoser",t:"1800-iDoser"},
    {v:"modbus",t:"1800-Modbus"},
    {v:"expLink4",t:"1800-Expanded Link 4"},
    {v:"pearl",t:"Pearl"},
    ]
    return(
      <C18Select01 parms={{
        label:"Zone Types",
        zoneType:this.state.zoneType,
        valueName:"zoneType",
        opts:types,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showAddZone=()=>{
    return(
      <div className="custom-select" style={{borderStyle:"solid",borderWidth:1,
        borderRadius:10,padding:"0px 20px"}}>
        <p>Add Virtual Zone</p>
        {this.showSelectZoneType()}
        <label htmlFor="zone-name">Zone Name</label>
        <C18Input00 type="text" id="zone-add-name" className="with-right-button" 
          value={this.state.zoneAddName}
          onChange={e=>this.onChange("zoneAddName",{zoneAddName:e.currentTarget.value})}
        />
        <C18Button00 type="button" className="outlined"
        disabled={!this.state.zoneAddName}
          onClick={e=>this.onChange("addZone")}>Add Zone</C18Button00>
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
//       cl(this.state.zoneSel)
    let zoneInfo=getZoneInfo(this.state.zoneSel)
//     let gw=globs.gatewaysInfo.info.filter(g=>{return g.gatewayId==zoneInfo.gatewayId})
//     zoneInfo.clientId=gw.clientId
    let gw=globs.gatewaysInfo.info.filter(gw=>{
//       cl(gw)
      return gw.gatewayId==zoneInfo?.gatewayId})[0]
    if(!gw){gw={}}
//     cl(gw)
    var lastContact,lcTime
    if(gw?.updateTime){
      lcTime=getTimeI()-gw.updateTime
      if(gw.connected){
        lastContact=<div>{`Last Contact: ${lcTime} seconds ago`}</div>
      }else{
        lastContact=(
          <div style={{color:"red"}}>Not Connected</div>
        )
      }
    }
    let current=getTimeI()-zoneInfo?.updateTime<86400
//     cl(current)
    let gatewayInfo=getGatewayInfo(zoneInfo?.gatewayId)
//     let inNet=zoneInfo?.inNet||0
    let virtual=zoneInfo?.virtual||false
//     let inNet=globs.zonesInfo.info[getZoneIndex(this.state?.zoneSel)]?.inNet
//             {this.showSelectZoneTier(zoneInfo)}
    return(
      <div>
        {this.showSelectSite()}
        <div className="clearfloat"></div>
        {this.showSelectZone()}
        {zoneInfo&&
          <>
            <div>Gateway Name: {gatewayInfo ? gatewayInfo.name :"" }</div>
            <div>Gateway ID: {zoneInfo.gatewayId}</div>
            <div>Client ID: {gw.clientId}</div>
            {lastContact}
            <div>Firmware Version: {gw?.fwVersion}</div><br/>
            <label htmlFor="zone-name">Zone Name</label>
            <C18Input00 type="text" id="zone-name" 
              value={zoneInfo.zoneName}
              onChange={e=>this.onChange("zoneName",{zoneName:e.currentTarget.value})}
            />
            <div className="checkbox-field float-left-box" style={{float:'none'}}>
              <div className="top-margin">
                <input type="checkbox" id="schedule-status" checked={zoneInfo?.hideEquipment||false}
                  onChange={()=>this.onChange("hideZoneEquipments", {hideEquipment:!zoneInfo?.hideEquipment})} />
                  <label className="left-margin" htmlFor="schedule-status">
                      Hide Channels with No Equipment Configured
                  </label>
                </div>
            </div>
            <br />
            {((!current)||virtual)&&
              <>
                <button type="button" className="outlined clearfloat danger"
                onClick={()=>this.onChange("deleteZone")}>Delete Zone</button>
                <div className="clearfloat"></div><br/>
              </>
            }

          </>
        }
        <div className="clearfloat"></div>
        {acctFeature("virtualZones")&&this.showAddZone()}
      </div>
    )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ManageZones;
