import React from 'react';
// import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import C18MuiPickersUtilsProvider from '../../usa/components/C18MuiPickersUtilsProvider'
import C18MuiTimePicker from '../../usa/components/C18MuiTimePicker'
import C18MuiInput from '../../usa/components/C18MuiInput'
// import { TimePicker } from "@material-ui/pickers";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {cl, globs,az,dateToDisplayDate,interpolateColor} from '../../components/utils/utils';

class TimeOfDay extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.updateFui()
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
    this.state={
      enabled: (props.value<1440),
    }
  }

  updateFui=()=>{
//     cl(this.changed)
    if(!this.changed){
      this.props.getValue(this.props.ind, {type: "scalar"})
    }
  }
  
  savePageEvent=()=>{
    this.changed=false
  }

  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
  }

  onChange=(e)=>{
    let pr=this.props
//     cl(pr)
//     cl(e.currentTarget.value)
    this.changed=true
    var t
    if(pr.custom.date){
      t=Math.floor(Date.parse(e.currentTarget.value)/1000)
//       cl(da)
    }else{
      let parts=e.currentTarget.value.split(":")
      t=parts[0]*60+(+parts[1])
    }
    this.props.onChange(this.props.ind,{value: t})
  }
  
  enableChange=(e)=>{
//     let en=e.currentTarget.checked
//     this.setState({enabled: en})
    this.changed=true
    if(e.currentTarget.checked){
      this.props.onChange(this.props.ind,{value: 0})
    }else{
      this.props.onChange(this.props.ind,{value: 1440})
    }
  }

  minsToTime=(mins)=>{
    if(mins>=1440){mins=0}
    let hr=Math.floor(mins/60)
    let mn=mins%60
    return `${az(hr,2)}:${az(mn,2)}`
  }
  
//   minsToTime=(mins)=>{
//     let hr=Math.floor(mins/60)
//     let mn=mins%60
//     return `${az(hr,2)}:${az(mn,2)}`
//     return "09:00"
//   }

  render(){
//     cl(this.state)
    let pr=this.props
//     cl(pr)
    let val=pr.value
    val=(val)?val:0
    // rbg values for day/night
    let col = ""
    if (pr.value<1440) {
      let dayCol = [245, 192, 0, .7]
      let nightCol = [192, 130, 209, .7]
      col = pr.value<720 ? interpolateColor(nightCol, dayCol, val/720) : interpolateColor(dayCol, nightCol, (val-720)/720)
    }
    let type=(pr.custom.date)?"date":"time"
    let val2=this.minsToTime(val)
    if(type=="date"){
      let da=new Date(val*1000)
      val2=dateToDisplayDate(da,"yyyy-mm-dd",0)
      this.unit=pr.custom.unit
//       cl(da)
//       cl(da2)
    }
//     if(pr.conflict){col="#FFCCCC"}
//     cl(this.minsToDate(val))
//     cl(pr?.custom)
//     cl(this.minsToTime(val))
//     let pr=pr
    return(
      <div id={pr?.custom?.infoId}
        className={`time-enable${(pr?.custom?.float=="left")?" floatleft":""}`}>
        <C18MuiPickersUtilsProvider utils={DateFnsUtils}>
          <C18MuiTimePicker style={{display: "inline"}}
            id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
            title={(pr?.rest?.parent||(e=>{}))({uri:"pid",pr:pr})}
            ampm={true}
            openTo="hours"
            views={["hours", "minutes"]}
            format="HH:mm"
            label={pr.title}
            disabled={pr.value>=1440}
            value={val2}
            onChange={this.onChange}
            saveOK={pr.saveOK}
            bgColor={col}
            type={type}
            unit={this.unit}
            inputProps={{
              style: {textAlign: 'center', width: 100,}
            }}
          />
        </C18MuiPickersUtilsProvider>
        {pr?.custom?.showEnable&&
          <>
          <C18MuiInput 
            type="checkbox" 
            style={{display: "inline", paddingTop: 20, marginTop: 26}}
            onChange={this.enableChange}
            checked={pr.value<1440}
            noBreak={true}
            saveOK={pr.saveOK}
          />
          <label>Enable</label>
          </>
        }
      </div>
    );
  }
}

  export default TimeOfDay ;
