import React from 'react';
import { TimePicker } from "@material-ui/pickers";
import {cl,globs} from '../../components/utils/utils';

class C18MuiTimePicker extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
  }
  
  render(){
//<div><label for="">1.</label> <input type="time" id="" /> <input type="checkbox" id="" /><label for="">Enable</label></div>
    
// <div><label for="">1.</label> <input type="time" id="" /> <input type="checkbox" id="" /><label for="">Enable</label></div>
    let pr=this.props
//     cl(pr)
    let type=(pr.type)?pr.type:"time"
    let unit=pr.unit
    if(globs.noMui){
      if(type === "time") {
        return(
          <>
            <input type={type}
              id={pr.id}
              title={pr.title}
              value={pr.value}
              onChange={pr.onChange}
              disabled={!pr.saveOK}
              style={{backgroundColor:pr.bgColor}}
            >
              {pr.children}
              
            </input>
            <label>{pr.label}</label>
          </>
        )
      } 
      else {
        return(
          <>
            <label style={{marginLeft:"-5px"}}>{pr.label}</label>
            <input type={type}
              id={pr.id}
              title={pr.title}
              value={pr.value}
              onChange={pr.onChange}
              disabled={!pr.saveOK}
              style={{backgroundColor:pr.bgColor}}
            >
              {pr.children}
              
            </input>
            {unit&&
            <span className="units">{unit}</span>
            }
          </>
        )
      }    
    }else{
      return React.createElement(TimePicker,pr)
    }
  }
}
      
export default C18MuiTimePicker;
 
