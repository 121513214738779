import React from 'react';
import C18MenuBar00 from './C18MenuBar00'
// import C18Breadcrumb00 from './C18Breadcrumb00'
// import history from "../../history"
import { loadSitesInfo, loadSiteData, loadZonesInfo, getZoneInfo, getSiteInfo } from './C18utils'
import { sensorIds, getParamId, tempUnit, lightUnit, windUnit, initSensorIds } from '../utils/utils'
import { dbVals } from '../../components/utils/http';
import { cl, globs } from '../../components/utils/utils';
import { wsTrans } from '../utils/utils'
import DayClearIcon from '../../visualization/icons/sun-icon.svg';
import NightClearIcon from '../../visualization/icons/moon-icon.svg';
import DayCloudIcon from '../../visualization/icons/day-cloudy-icon.svg';
import NightCloudIcon from '../../visualization/icons/night-cloudy-icon.svg';
import OvercastIcon from '../../visualization/icons/cloud-icon.svg';
import DaySnowIcon from '../../visualization/icons/day-cloud-snow-icon.svg';
import NightSnowIcon from '../../visualization/icons/night-cloud-snow-icon.svg';
import DayRainIcon from '../../visualization/icons/day-cloud-rain-icon.svg';
import NightRainIcon from '../../visualization/icons/night-cloud-rain-icon.svg';
import DayThunderstormIcon from '../../visualization/icons/day-cloud-rain-lightning-icon.svg';
import NightThunderstormIcon from '../../visualization/icons/night-cloud-rain-lightning-icon.svg';
import FunnelIcon from '../../visualization/icons/hurricane-icon.svg';

class C18WeatherStation00 extends React.Component {
  constructor(props) {
    super(props);
    //     cl(props)
    this.state = {
      winDir: "-",
      winSpeed: "",
      winUnits: "mph",
      outTemp: 57.7,
      outTempUnits: "F",
      outHum: 82.3,
      outLight: 324,
      blackPlate: 92.5,
      blackPlateMapping: 0,
      // outLightUnits: "W/m" + "2".sub(), // subscript doesn't work on react
      outLightUnits: "W/m2",
      rain: 0,
      snow: 0,
      rs_status: "",
      mode: "sites",
      weather: true,
      showAllTemp: false,
    }
    this.loadData()
    this.windDirs = [
      "N",
      "NNE",
      "NE",
      "ENE",
      "E",
      "ESE",
      "SE",
      "SSE",
      "S",
      "SSW",
      "SW",
      "WSW",
      "W",
      "WNW",
      "NW",
      "NNW",
    ]
    if (this.gotWs) { this.checkWSInt = setInterval(this.checkWS, 5 * 1000) }

  }

  componentWillUnmount = () => {
    //     cl("clear WS")
    clearInterval(this.checkWSInt)
  }

  checkWS = () => {
    //     cl("check WS")
    this.updateData()
  }

  loadData = async () => {
    let pa = this.props.parms
    await loadSitesInfo()
    let site = globs.sitesInfo.info.filter(s => { return s.siteId == pa.site })[0]
    if (!site.weatherStation) { return }
    if (site.weatherStation) { this.gotWs = true }
    await loadSiteData(pa.site)
    await loadZonesInfo()
    let zInd = getZoneInfo(site.weatherStation || {})?.siteZoneIndex || 0
    if (!(dbVals.z[zInd] || {})[240]) { return }
    initSensorIds(zInd)
    this.ids = {
      winDirId: sensorIds.e0oWd.id,//getParamId("snapshots", "windDirection")
      winSpeedId: sensorIds.e0oWs.id,//getParamId("snapshots", "windSpeed")
      outTempId: sensorIds.e0ouT.id,//getParamId("snapshots", "outTemperature")
      outHumId: sensorIds.e0ouH.id,//getParamId("snapshots", "outHumidity")
      outLightId: sensorIds.e0ouL.id,//getParamId("snapshots", "outLight")
      blackPlateId: sensorIds.e0bpT.id,//getParamId("snapshots", "outLight")
      blackPlateMapping: 4216 + 177,//getParamId("configuration_unit_settings","Outside Temperature 2 Mapping"),
      rainId: sensorIds.e0ran.id,//getParamId("snapshots", "rain")
      snowId: sensorIds.e0sno.id,//getParamId("snapshots", "snow")
    }
    //     cl(this.ids)
    //     cl(this.ids)
    // cl(this.state.weather)
    if (this.state.weather) {
      let address
      let body = { endpoint: "forecast" }
      // cl(pa.site)
      if (pa.site) {
        let site = getSiteInfo(pa.site)
        if(site.postalCode){
          Object.assign(body, { siteId: pa.site, postalCode: site.postalCode  })
        } else if (site.thoroughfare && site.locality && site.administrativeArea) {
          Object.assign(body, { siteId: pa.site })
          if (site.coordinates) {
            Object.assign(body, { coordinates: site.coordinates })
          } else {
            address = `${site.thoroughfare} ${site.locality} ${site.administrativeArea}`
            address = address.replaceAll(" ", "%20")
            Object.assign(body, { address: address })
          }
        } else {
          Object.assign(body, { accountId: globs.userData.session.accountId })
          if (globs.accountInfo.info.thoroughfare && globs.accountInfo.info.locality && globs.accountInfo.info.administrativeArea) {
            if (globs.accountInfo.info.coordinates) {
              Object.assign(body, { coordinates: globs.accountInfo.info.coordinates })
            } else {
              address = `${globs.accountInfo.info.thoroughfare} ${globs.accountInfo.info.locality} ${globs.accountInfo.info.administrativeArea}`
              address = address.replaceAll(" ", "%20")
              Object.assign(body, { address: address })
            }
          }
        }
        // cl("got site address")
      } else {
          Object.assign(body, { accountId: globs.userData.session.accountId })
          if(globs.accountInfo.info.postalCode){
            Object.assign(body, { postalCode: globs.accountInfo.info.postalCode })
          }
          if (globs.accountInfo.info.thoroughfare && globs.accountInfo.info.locality && globs.accountInfo.info.administrativeArea) {
            if (globs.accountInfo.info.coordinates) {
              Object.assign(body, { coordinates: globs.accountInfo.info.coordinates })
            } else {
              address = `${globs.accountInfo.info.thoroughfare} ${globs.accountInfo.info.locality} ${globs.accountInfo.info.administrativeArea}`
              address = address.replaceAll(" ", "%20")
              cl(address)
              Object.assign(body, { address: address })
            }
          }
          // cl("got account address")
      }
      if (body.coordinates || body.address || body.postalCode) {
        let forecast = await wsTrans("usa", {
          cmd: "cRest", uri: "/s/weather", method: "retrieve",
          sessionId: globs.userData.session.sessionId, body: body
        })
        // cl(forecast.data.properties)
        this.setState({ forecast: forecast.data?.properties })
      }
    }

    this.updateData()

    //     cl([winUnitsId, outTempUnitsId, outLightUnitsId, ])
  }

  updateData = () => {
    let si = getSiteInfo(this.props.parms.site)
    //     cl(si)
    let weatherStation = si?.weatherStation
    // let weatherStation = si?.weatherStation || this.props.parms.zone
    //     cl(weatherStation)
    let zi = getZoneInfo(weatherStation)
    //     cl(zi)
    let wsz = (zi || {}).siteZoneIndex || 0
    if (!(dbVals.z[wsz] || {})[240]) { return }
    //     cl((dbVals.z[wsz]||{})[240])
    let winUnits = windUnit(wsz).t//getParamId("configuration_zone_settings", "Windspeed Units")
    let outTempUnits = tempUnit(wsz).t//getParamId("configuration_zone_settings", "Temperature Units")
    let outLightUnits = lightUnit(wsz).t//getParamId("configuration_zone_settings", "Light Units")
    //     cl(outLightUnits)
    if (!dbVals.z[wsz]) { return }

    let winDir = (dbVals.z[wsz] || {})[240][this.ids.winDirId];
    //     cl(dbVals)
    //     cl(dbVals.z)
    //     cl(wsz)
    //     cl(dbVals.z[wsz])
    //     cl(dbVals.z[wsz][240])
    //     cl(winDirId)
    let winSpeed = dbVals.z[wsz][240][this.ids.winSpeedId];
    let outTemp = dbVals.z[wsz][240][this.ids.outTempId];
    let outHum = dbVals.z[wsz][240][this.ids.outHumId];
    let outLight = dbVals.z[wsz][240][this.ids.outLightId];
    let blackPlate = dbVals.z[wsz][240][this.ids.blackPlateId];
    let blackPlateMapping = dbVals.z[wsz][240][this.ids.blackPlateMapping];
    //     cl(blackPlateMapping)
    let rain = +dbVals.z[wsz][240][this.ids.rainId];
    let snow = +dbVals.z[wsz][240][this.ids.snowId];
    let rs_status = `${(rain) ? "rain" : ""} ${(snow) ? "snow" : ""}`
    //     cl([winDir,winSpeed,outTemp,outHum,outLight,rain,snow])

    //     let rs_status = ""
    //     if (rain == 1) rs_status += "rain "
    //     if (snow == 1) rs_status += "snow "


    this.setState({
      winDir: this.windDirs[winDir],
      winSpeed: winSpeed,
      winUnits: winUnits,
      outTemp: outTemp,
      outHum: outHum,
      outTempUnits: outTempUnits,
      outLight: outLight,
      outLightUnits: outLightUnits,
      blackPlate: blackPlate,
      blackPlateMapping: blackPlateMapping,
      rain: rain,
      snow: snow,
      rs_status: rs_status,
    })
  }

  getZoneIndex = (zoneId) => {
    for (let i = 0; i < globs.zonesInfo.info.length; i++) {
      let z = globs.zonesInfo.info[i]
      if (z.zoneId == zoneId) { return z.siteZoneIndex }
    }
  }

  showValues = () => {
    this.setState({ showAllTemp: !this.state.showAllTemp })
  }

  findMatchingWord(sentence) {

    const wordList = [
      'sunny',
      'fair',
      'clear',
      'windy',
      'breezy',
      'clouds',
      'cloudy',
      'overcast',
      'snow',
      'ice',
      'rain',
      'drizzle',
      'showers',
      'thunderstorm',
      'pellets',
      'funnel',
      'tornado',
      'hurricane',
      'storm',];

    const lowercaseSentence = sentence.toLowerCase();
    for (let i = 0; i < wordList.length; i++) {
      if (lowercaseSentence.includes(wordList[i])) {
        return wordList[i];
      }
    }
    return wordList[0];
  }

  makeWeatherIconsEnum = (forecastedSentence, isDaytime) => {

    const sentence = forecastedSentence;
    const matchingWord = this.findMatchingWord(sentence);
    let forecastedIcon = [];

    if (isDaytime) {
      let icons = {
        "sunny": DayClearIcon,
        'fair': DayClearIcon,
        'clear': DayClearIcon,
        'windy': DayClearIcon,
        'breezy': DayClearIcon,
        'clouds': DayCloudIcon,
        'cloudy': DayCloudIcon,
        'overcast': OvercastIcon,
        'snow': DaySnowIcon,
        'ice': DaySnowIcon,
        'rain': DayRainIcon,
        'drizzle': DayRainIcon,
        'showers': DayRainIcon,
        'thunderstorm': DayThunderstormIcon,
        'pellets': DayThunderstormIcon,
        'funnel': FunnelIcon,
        'tornado': FunnelIcon,
        'hurricane': FunnelIcon,
        'storm': FunnelIcon,
      }
      forecastedIcon = icons[matchingWord]
    }

    else {
      let icons = {
        "sunny": NightClearIcon,
        'fair': NightClearIcon,
        'clear': NightClearIcon,
        'windy': NightClearIcon,
        'breezy': NightClearIcon,
        'clouds': NightCloudIcon,
        'cloudy': NightCloudIcon,
        'overcast': OvercastIcon,
        'snow': NightSnowIcon,
        'ice': NightSnowIcon,
        'rain': NightRainIcon,
        'drizzle': NightRainIcon,
        'showers': NightRainIcon,
        'thunderstorm': NightThunderstormIcon,
        'pellets': NightThunderstormIcon,
        'funnel': FunnelIcon,
        'tornado': FunnelIcon,
        'hurricane': FunnelIcon,
        'storm': FunnelIcon,
      }

      forecastedIcon = icons[matchingWord]
    }

    return forecastedIcon;
  }


  showForecastBody = (readings) => {
    let data = []
    // for (let i = 0; i < readings.length; i++) {
      // if (this.props.parms.forecast) {
      // TODO make enum work
      // let icon = this.makeWeatherIconsEnum(readings.shortFc, this.props.parms.forecast.periods[0].isDaytime)
    let icon = this.makeWeatherIconsEnum(readings.shortFc || "", true)
    if (Object.keys(readings).includes("temp")) {
      if (this.state.outTempUnits === '°C') {
        readings.temp = (readings.temp - 32) * 5 / 9
      }
      data.push(
        <span key={0}>
          <span>
            {`${readings.temp.toFixed()}${this.state.outTempUnits}`}
          </span>
          <br />
          <img src={icon} width="20px" />
          <span className='ws-title'> Temperature</span>
        </span>
      )
    }
    if (Object.keys(readings).includes("windDir")) {
      // convert winSpeed based on unit
      if (this.state.winUnits === 'kph') {
        readings.windSpd = (readings.windSpd * 1.609344)
      }
      data.push(
        <span key={1}>
          <span>
            {`${readings.windDir} | ${(readings.windSpd).toFixed()}${this.state.winUnits}`}
          </span>
          <br />
          <span className="material-icons-outlined ws-small-icon">
            air
          </span>
          <span className='ws-title'>Wind</span>
        </span>
      )
    }
    if (Object.keys(readings).includes("relHum")) {
      data.push(
        <span key={2}>
          <span>
            {`${readings.relHum}%`}
          </span>
          <br />
          <span className="material-icons-outlined ws-small-icon">
            water_drop
          </span>
          <span className='ws-title'>Humidity</span>
        </span>
      )
    }
    if (Object.keys(readings).includes("rainPb")) {
      // convert winSpeed based on unit
      data.push(
        <span key={3}>
          <span>
            {`${readings.rainPb}%`}
          </span>
          <br />
          <span id="ws-icons" className={readings.rainPb > 0 ? "rain" : ""}>
            <img id="rain-icon" src="/rain.png" width="20px"/>
          </span>
          <span className='ws-title'> Rain Probability</span>
        </span>
      )
    }
    // }
    return data
  }

  // makeUIOld = (temperature, day, words, unit, unitsArray) => {

  //   let lengthValue = this.state.showAllTemp ? temperature.length : 3
  //   let lastValue = <span style={{ cursor: "Pointer" }} onClick={this.showValues}>{this.state.showAllTemp ? "Show Less" : "Show More"}</span>

  //   let spans = []
  //   for (let i = 0; i < lengthValue; i++) {
  //     let icon = this.makeWeatherIconsEnum(words[i])
  //     let finaltemperature;
  //     let finalUnit;
  //     if (unit === "°" + unitsArray[i]) {
  //       finaltemperature = temperature[i]
  //       finalUnit = unitsArray[i]
  //     }
  //     else {
  //       if (unit === '°F') {
  //         let temp = (temperature[i] * 9 / 5) + 32;
  //         finaltemperature = temp.toFixed(1)
  //         finalUnit = unit
  //       }
  //       else {
  //         let temp = (temperature[i] - 32) * 5 / 9;
  //         finaltemperature = temp.toFixed(1)
  //         finalUnit = unit

  //       }
  //     }
  //     spans.push(<span key={i} >{finaltemperature}<span style={{ marginLeft: "5px" }}>{finalUnit}</span><br /><img src={icon} width="20px" /><span className='ws-title'>{day[i]}</span></span>);
  //   }

  //   spans.push(lastValue)
  //   let value = spans

  //   return value;
  // }

  showForecast = () => {
    // for (let i = 0; i < 1; i++) {

      // let maxTemp = this.state.forecast.maxTemperature.values[i].value
      // let minTemp = this.state.forecast.minTemperature.values[i].value
      // let relHum = this.state.forecast.relativeHumidity.values[i].value
      // let windDir = this.state.forecast.windDirection.values[i].value
      // let windSpd = this.state.forecast.windSpeed.values[i].value
      // let rainPb = this.state.forecast.probabilityOfPrecipitation.values[i].value
      // let shortFc = this.state.forecast.weather.values[i + 1].value[0].weather
      ///
      // TODO get other weather info
      // let myDay = this.state.forecast.periods[i]?.name
    let curr = this.state.forecast.periods[0]

    let name = curr.name
    let temp = curr.temperature
    let short_forecast = curr.shortForecast
    let wind_dir = curr.windDirection
    let wind_spd = curr.windSpeed?.split(" ")[0]
    let pop = curr.probabilityOfPrecipitation?.value || 0
    let rh = curr.relativeHumidity?.value || 0

    let icon = this.makeWeatherIconsEnum(curr.shortForecast || "", curr.isDaytime)
    let readings = {
      // "maxTemp": maxTemp,
      // "minTemp": minTemp,
      "temp": temp,
      "relHum" : rh,
      "windDir": wind_dir,
      "windSpd": Number(wind_spd),
      "rainPb" : pop,
      "shortFc" : short_forecast,
    }
    // }
    return this.showForecastBody(readings)
  }


  render() {
    //     cl(this.state)
    //     cl(this.state.blackPlateMapping)
    //     cl(((+this.state.blackPlateMapping)!=0))
    let st = this.state
    if (!this.gotWs) { return null }
    return (
      <div id="weather-station">
          <div className="inner">
            <span className="title">
              Weather<br />Station
            </span>
            <span id="ws-icons" className={st.rs_status}>
              <img id="rain-icon" src="/rain.png" />
              <img id="snow-icon" src="/snow.png" />
            </span>
            <span>
              <span id="ws-wind-direction">{st.winDir}</span> | <span id="wind-speed">{st.winSpeed}</span><span className="ws-title"> {st.winUnits}<br />
                <span className="material-icons-outlined ws-small-icon">
                  air
                </span>
                <span className="ws-title">Wind</span>
              </span></span>
            <span><span id="ws-temp">{st.outTemp}</span> <span id="ws-temp-units">{st.outTempUnits}</span><br />
              <span className="material-icons-outlined ws-small-icon">
                thermostat
              </span>
              <span className="ws-title">Air Temperature</span>
            </span>
            <span><span id="ws-humidity">{st.outHum}</span> %<br />
              <span className="material-icons-outlined ws-small-icon">
                water_drop
              </span>
              <span className="ws-title">Humidity</span>
            </span>
            <span><span id="ws-light">{st.outLight}</span> {st.outLightUnits}<br />
              <span className="material-icons-outlined ws-small-icon">
                light_mode
              </span>
              <span className="ws-title">Light</span>
            </span>
            {((st.blackPlateMapping || 0) != 0) &&
              <span><span id="ws-blackPlate">{st.blackPlate}</span> {st.outTempUnits}<br />
                <span className="material-icons-outlined ws-small-icon">
                  thermostat
                </span>
                <span className="ws-title">Black Plate Temp</span>
              </span>

            }
          </div>
          {this.state.forecast &&
            <div className="inner hide-mobile">
              <span className="title">
                Weather<br />Forecast
              </span>
              {this.showForecast()}
            </div>
          }
      </div>
    )
  }
}

export default C18WeatherStation00;
