import React from 'react';
import * as d3 from 'd3';
import {blendColor} from '../utils/utils';
import {cl,globs} from '../../components/utils/utils';
//  import { Router, Route, Switch } from "react-router-dom";// , Link
// import {cl, inputField} from '../utils/utils';
// import {globs} from '../../components/utils/utils';
// import IconButton from '@material-ui/core/IconButton';
// import Check from '@material-ui/icons/Check';
// import Clear from '@material-ui/icons/Clear';
// //  import InputAdornment from '@material-ui/core/InputAdornment';
// //  import Input from "@material-ui/core/Input";
// //  import InputLabel from '@material-ui/core/InputLabel';
// //  import Register from './Register';
// //  import Login from './Login';
// import MainBar from '../../components/MainBar';

/* props for Gauge: 
need to define the range from min to max 
the colors to be used, with the start point for each 
the ticks to use, and their location 
size: total height, total width, ring max, ring min 
current value 
in a single config object:
{width: 180,
height: 180,
ringInset: 20,
ringWidth: 30,
min: 0,
minAngle: -120,
max: 10,
maxAngle: 120
ticks: [
[0, 0],// pos, tick
[2, 2],
[4, 4],
[6, 6],
[8, 8],
[10, 10],
],
colors: [
[0, "#FF0000"],
[2, "#FFFF00"],
[4, "#00FF00"],
[6, "#00FFFF"],
[8, "#0000FF"],
[10, "#FF0000"],
],
}
*/
class Gauge01 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props);
    this.svgDiv=React.createRef();
    this.mounted=false
    this.state={
      value: props.value,
    }
//     cl("setting update")
    props.config.update = this.update
  }
  
  componentDidMount() {
//     cl("did mount")
//     cl(this.props)
//     cl(this.props.config.value)
//     return true
    this.gauge=this.Gauge2(this.svgDiv.current);
    this.gauge.render(this.props.config.value);
//     this.gauge.render(this.props.config.value);
    this.mounted=true
//     cl(this.gauge)
//     cl(this.gauge)
  }
  
  componentWillUnmount=()=>{
//     cl("unmount")
  }
  
  update=(isRender)=>{
//     cl(`gauge update: ${isRender}`)
//     cl(`gauge update ${this.props.config.value}`)
    if(isRender){// probably needs to initialize more stuff
      this.gauge.render(this.props.config.value);
    }else{
      this.gauge.update(this.props.config.value)
    }
//     cl("done")
  }
  
//   shouldComponentUpdate(){
//     cl("shouldComponentUpdate");
//   }
//   
// //   static getDerivedStateFromProps(){
// //     return null
// //   }
//   
//   getSnapshotBeforeUpdate(){
//     cl("shouldComponentUpdate");
//   }
//   
//   componentDidUpdate(){
//     
//   }
  
  Gauge2=(container, configuration)=>{
//     cl("gauge");
//     cl(container);
    //   var container;
    //   var configuration;
    let co = this.props.config;
    var that = {};
//     {
//       colors: co.colors,
//       ticks: co.ticks,
//       size						: co.width,
//       clipWidth					: co.width + 2,
//       clipHeight					: co.height + 2,
//       ringInset					: co.ringInset,
//       ringWidth					: co.ringWidth,
//       
//       pointerWidth				: 0.05*co.width,//10,
//       pointerTailLength			: 0.025*co.width,
//       pointerHeadLengthPercent	: 0.9,
//       
//       minValue					: co.min,
//       maxValue					: co.max,
//       
//       minAngle					: co.minAngle,
//       maxAngle					: co.maxAngle,
//       
//       transitionMs				: 4000,
//       
//       majorTicks					: co.ticks.length - 1,
//       labelFormat					: d3.format('d'),
//       labelInset					: 0.08*co.width,//10,
//       
//       arcColorFn					: d3.interpolateHsl(d3.rgb('#28e2ca'), d3.rgb('#3e6c0a'))
//     };
    
    var makeConfig=()=>{
      let co = this.props.config;
//       cl(co)
      let dark=globs.device?.deviceTheme=="originalDark"
//       cl(co)
//       cl(co.value)
      return {
        colors: co.colors,
        ticks: co.ticks,
        size						: co.width,
        clipWidth					: 1.2*co.width + 2,
        clipHeight					: 1.3*co.height + 2,
        ringInset					: co.ringInset,
        ringWidth					: co.ringWidth,
        
        pointerWidth				: 0.05*co.width,//10,
        pointerTailLength			: 0.025*co.width,
        pointerHeadLengthPercent	: 0.9,
        
        nPointerWidth: 10,
        nPointerDepth: 10,
        nPointerRadius: co.width/2-co.ringInset-5,//55,
        nPointerColor: co.pointerColor,
        nCenterColor: this.props.config.bgColor,//co.centerColor,
        nCenterTextColor: (co.alarm)?"#EA5455":(dark)?"white":"black",
        nLabelColor: (co.alarm)?(dark)?"#BCBCBC":"#EA5455":(dark)?"white":"black",
        nValue: +co.value,
        nCenterValue: +co.centerValue,
        nUnit: co.unit,
        nUnitColor: co.unitColor,
        
        minValue					: co.min,
        maxValue					: co.max,
        
        minAngle					: co.minAngle,
        maxAngle					: co.maxAngle,
        
        transitionMs				: 4000,
        
        majorTicks					: co.ticks.length - 1,
        labelFormat					: d3.format('d'),//(co.tod)?d3.timeFormat('%H:%M')(
//         d3.timeFormat('%H:%M')(
//           d=>{
//           cl(d)
//           (new Date()).setMinutes(d)
//         })
//         :d3.format('d'),
        labelInset					: 0.02*co.width,//0.08*co.width,//10,
        
        arcColorFn					: d3.interpolateHsl(d3.rgb('#28e2ca'), d3.rgb('#3e6c0a'))
      };
    }
    
    var config = makeConfig()
    var range = undefined;
//     var globProps = this.props;
    var r = undefined;
    var pointerHeadLength = undefined;
    var value = 0;
    
    var svg = undefined;
    var arc = undefined;
    var scale = undefined;
//     var ticks = undefined;
    var testTicks = undefined;
    var tickData = undefined;
    var pointer = undefined;
    
    var donut = d3.pie();
    
    var rlc=(node)=>{
      node.removeChild(node.lastChild);
    }
    
    var rc=(node)=>{
      if(!node.firstChild) return;
      while (node.firstChild) {
        rlc(node);
      }  
    }
    
    function deg2rad(deg) {
      return deg * Math.PI / 180;
    }
    
    function newAngle(d) {
      var ratio = scale(d);
      var newAngle = config.minAngle + (ratio * range);
      return newAngle;
    }
    
    var arcSize=(arr)=>{
      let ret=[];
//       cl(config);
      let range = config.maxValue - config.minValue;
//       cl(range);
      for (let i = 1 ; i < arr.length ; i++){
//         ret.push((arr[i][0] - arr[i-1][0])/range);
        ret.push([(arr[i-1][0] - config.minValue)/range, (arr[i][0]-config.minValue)/range]);
        //         cl(size);
//         let a=arr[i];
//         cl(a);
      };
//       cl(ret);
//       ret=[0.25, 0.15, 0.25, 0.2, 0.2];
//       ret=[0.11, 0.11, 0.11, 0.11, 0.12];// this is multiplied by the 0-10 value
//       ret=[
//         [0, 0.2],
//         [0.2, 0.4],
//         [0.4, 0.7],
//         [0.7, 0.8],
//         [0.8, 1],
//       ];
      return ret;
    }
    
    function configure(configuration) {
      var prop = undefined;
//       for ( prop in configuration ) {
//         config[prop] = configuration[prop];
//       }
      
      range = config.maxAngle - config.minAngle;
      r = config.size / 2;
      pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);
      
      // a linear scale that maps domain values to a percent from 0..1
      scale = d3.scaleLinear()
      .range([0,1])
      .domain([config.minValue, config.maxValue]);
      
      //     ticks = scale.ticks(config.majorTicks);
//       cl(testTicks);
//       testTicks=[
//       {tick: 0, size: 0.2, color: "#FF0000"},
//       {tick: 1, size: 0.2, color: "#FFFF00"},
//       {tick: 2, size: 0.2, color: "#00FF00"},
//       {tick: 3, size: 0.2, color: "#00FFFF"},
//       {tick: 4, size: 0.2, color: "#0000FF"},
//       {tick: 5, size: 0.2, color: "#FF0000"},
//       ];
      
      //     tickData = d3.range(config.majorTicks).map(function() {return 1/config.majorTicks;});
      
      arc = d3.arc()
      .innerRadius(r - config.ringWidth - config.ringInset)
      .outerRadius(r - config.ringInset)
      .startAngle(function(d, i) {
        var ratio = d[0];// * i;
        return deg2rad(config.minAngle + (ratio * range));
      })
      .endAngle(function(d, i) {
        var ratio = d[1];// * (i+1);
        return deg2rad(config.minAngle + (ratio * range));
      });
    }
    that.configure = configure;
    
    function formatNumber(v){
	  v=(+v)||0
      if(v<10){
        if(Math.abs(v)<1){
          if((v!=0)&&Math.abs(v)<.001){
            v=(1000*v).toPrecision(1)+"m"
          }else{
            v=v.toPrecision(2)
          }
        }else{
          v=v.toPrecision(3)
        }
        if(Math.floor(v)==v){v=Math.floor(v)}
      }else{
        v=Math.round(v.toPrecision(4))
      }
      if((v>=10000)&&(v<1e6)){
        return `${v/1000}k`
      }
      return v
    }
    
    function centerTranslation() {
//       return 'translate('+r +','+ r +')';
      return `translate(${1.1*r+5},${r+5})`
    }
    
    function isRendered() {
      return (svg !== undefined);
    }
    that.isRendered = isRendered;
    
    function render(newValue) {
//       cl("render gauge")
//       cl(this.props)
//       cl(this.state)
//       console.trace()
      
      rc(container)
      config = makeConfig()
//       cl(config)
      svg = d3.select(container)
      .append('svg:svg')
      .attr('class', 'gauge')
      .attr('width', config.clipWidth)
      .attr('height', config.clipHeight);
      
      var centerTx = centerTranslation();
//       cl(centerTx)
//       cl(config.colors)
      var arcs = svg.append('g')
      .attr('class', 'arc')
      .attr('transform', centerTx)
//       .attr('fill','black')
//       cl("render gauge")
      arcs.selectAll('path')
      .data(arcSize(config.colors))
      .enter().append('path')
      .attr('stroke', 'white')
      .attr('stroke-width',2)
      .attr('fill', function(d, i) {
        return config.colors[i][1];
        //       return config.arcColorFn(d * i);
      })
      .attr('d', arc);
      
//       let rad=50
//       cl([config.size/2, config.ringWidth, config.ringInset])
      let rad=config.size/2-config.ringInset-config.ringWidth-.035*config.size
      
//   var blendColor=(color,color2,k)=>{
      var edgeColor=blendColor(config.nCenterColor,"#000000",0.96)

      var circ=svg.append('g')
      .append('circle')
      .attr('cx',0)
      .attr('cy',0)
      .attr('transform', centerTx)
      .attr('r',rad)
      .attr('fill',config.nCenterColor)
      .attr('stroke', edgeColor)
      .attr('stroke-width',2)
      
      
//       cl("append value")
//       cl(r)
//       cl("render gauge")
      var value=svg.append('g')
      .append('text')
//       'translate('+r +','+ r +')'
      .attr('transform', `translate(${1.1*r+5},${1.08*r+5})`)
      .attr('style', `text-anchor: middle; alignment-baseline: middle; font-size: ${0.8*rad}px; fill:${config.nCenterTextColor};`)
      .text(formatNumber(config.nCenterValue))
//       cl(config.nUnitColor)
//       cl("render gauge")
      var value=svg.append('g')
      .append('text')
//       'translate('+r +','+ r +')'
      .attr('transform', `translate(${1.1*r+5},${.70*r+5})`)
      .attr('style', `color: #FFFF00; opacity: 0.5; text-anchor: middle; alignment-baseline: middle; font-size: ${0.3*rad}px; 
        fill:${config.nCenterTextColor};`)
      .text(config.nUnit)

      //       cl(co)
//       var tod
//       cl(config.ticks)
//       cl("render gauge")
      var lg = svg.append('g')// label group
      .attr('class', 'label')
      .attr('style', `text-anchor: center; font-size: ${((co.tod)?0.7:1)*co.fontSize}px;`)
      .attr('transform', centerTx)
      .attr('fill',`${config.nLabelColor}`);//

//       cl("render gauge")
//       cl(config.ticks)
      lg.selectAll('text')
      .data(config.ticks)
      .enter().append('text')
      .attr('style', 'text-anchor: middle; alignment-baseline: middle; opacity: 1.0')
      .attr('transform', function(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + (ratio * range);
        let ro=`rotate(${+newAngle})`
        let ro2=`rotate(${0-newAngle})`
        let tr=`translate(0,${config.labelInset - r})`
        let tr2=`${ro} ${tr} ${ro2}`
//         cl(tr2)
        return tr2;
//         return 'rotate(' +newAngle +') translate(0,' +(config.labelInset - r) +')';
      })
      .text(function(d){
//         cl(d)
//         cl(co.tod)
        var tod, v=""
        if(co.tod){
          tod=new Date()
          tod.setMinutes(d%60)
          tod.setHours(Math.floor(d/60))
        }else{
          v=formatNumber(d)
        }
//         cl(v)
        return (co.tod)?
        d3.timeFormat('%H:%M')(tod):
        ((v)||0).toString()
      });
//         labelFormat					: (co.tod)?d3.timeFormat('%H:%M')(
//         (co.tod)?
//           d3.timeFormat('%H:%M')(tod):
//           config.labelFormat
//       );
      
//       var lineData = [ [config.pointerWidth / 2, 0], 
//       [0, -pointerHeadLength],
//       [-(config.pointerWidth / 2), 0],
//       [0, config.pointerTailLength],
//       [config.pointerWidth / 2, 0] ];
//       cl("render gauge")
      var lineData = [ [config.nPointerWidth/2,0-config.nPointerRadius-config.nPointerDepth], 
      [0,0-config.nPointerRadius-config.nPointerDepth],
      [0-config.nPointerWidth/2,0-config.nPointerRadius-config.nPointerDepth],
      [0, 0-config.nPointerRadius],
      [config.nPointerWidth/2,0-config.nPointerRadius-config.nPointerDepth] ];
      
      var pointerLine = d3.line().curve(d3.curveLinear)
      var pg = svg.append('g').data([lineData])
      .attr('class', 'pointer')
      .attr('stroke', 'white')
      .attr('stroke-width',2)
      .attr('fill', config.nPointerColor)
      .attr('transform', centerTx);
//       cl(pg)
      
      pointer = pg.append('path')
      .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/ )
      .attr('transform', 'rotate(' +config.minAngle +')');
      
//       cl("render gauge")
      update(newValue === undefined ? 0 : newValue);
    }
    that.render = render;
    
    function update(newValue, newConfiguration) {
//       console.trace()
//       if(!pointer){return}
//       if ( newConfiguration  !== undefined) {
//         configure(newConfiguration);
//       }
//       cl("upd")
      var ratio = scale(newValue);
      that.curValue=newValue
//       cl(pointer)
      var newAngle = config.minAngle + (ratio * range);pointer.attr('transform', 'rotate(' +newAngle +')');
//       pointer.transition()
//       .duration(config.transitionMs)
//       .ease(d3.easeElastic)
//       .attr('transform', 'rotate(' +newAngle +')');
    }
    that.update = update;
    
    configure(configuration);
    
    return that;
  }
  
  render(){
//     cl("render");
//     cl(this.props)
//     this.gauge.curValue=77
    if(this.gauge && (this.gauge.curValue !== this.props.config.value)){
      this.gauge.update(this.props.config.value)
    }
//     if(this.gauge){
//       cl(this.gauge.curValue)
//       cl(this.props.config.value)
//     }
    if(this.mounted){
//       this.gauge.render(this.props.config.value);
      this.componentDidMount()
    }
//     cl(this.gauge)
    return(
      <div ref={this.svgDiv} 
      style={{
        width: 1.2*this.props.config.width, 
        height: this.props.config.height, 
        margin: "auto",
//         color: "white",
        backgroundColor: this.props.config.bgColor}} />
    );
  }
}

export default Gauge01 ;
