import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MainBar from '../components/MainBar';
import history from "../history"
import {saveFuiPage} from '../components/utils/httpauth'; // deleteFuiPage
import {cl} from '../components/utils/utils';
import {globs} from '../components/utils/utils';
import {makeParmTabs, paramIdToTableColumn, controls} from './utilsFui';
import {tableIds} from '../components/utils/paramIds';
import {dbVals, init} from '../components/utils/http';
import {checkLogin} from '../components/utils/httpauth';
import {getHomeDashboard} from '../usa/utils/utils'
import {loadSiteData} from '../usa/components/C18utils'

class EditControlFui extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.initZuci();
    this.state = {
      loaded: false,
      loadMsg: "loading",
      name: "",
      title: "",
      type: "",
      pid: 0,
      k: 0,
      selType: -1,
      selTable: -1,
      selColumn: -1,
      fuiZone: globs.fuiZone,
      fuiChan: globs.fuiChan,
      fuiUnit: globs.fuiUnit,
      fuiInd: globs.fuiInd,
      // fuiParm: 0,
      // parmValue: 0,

    }
    this.columnList = [];
    this.parmValue = 0;
//     this.tableList = makeParmTabs(this.state.gatewayType||1800);// now set in loadFuiControl
    this.checkLoggedIn();
    // this.loadFuiControl(props);
    // paramIdToTableColumn(204);
  }
  mounted = false;

  componentDidMount=()=>{
     this.mounted=true
  }

  initZuci=()=>{
    if (globs.fuiZone === undefined){
      globs.fuiZone = 0;
      globs.fuiChan = 0;
      globs.fuiUnit = 0;
      globs.fuiInd = 0;
      // cl("init it")
    }
  }

  checkLoggedIn=()=>{
    // cl("check")
    if ((globs.token === "") || (!dbVals.gotSite)){
      checkLogin().then(async r=>{
        await loadSiteData(globs.userData.session.siteId)
        init().then(r=>{
//           cl("got it")
//           cl(dbVals)
          if ((dbVals.siteAuthorized)/* &&
            (r[0].command === "gotcursite00")*/){
            // cl("set state")
            this.loadFuiControl(this.props);
            this.setState({loaded: true});
          } else {
            this.setState({loadMsg: "Not Authorized"})
          }
        });
      });
    } else {// already loaded data
      // cl("loaded")
      this.loadFuiControl(this.props);
      if(this.mounted){
        this.setState({loaded: true});
      }else{
        this.state.loaded=true
      }
      this.initZuci();
      // cl("set")
    }
  }

  controlTypes = Object.keys(controls);

  // controlTypes = [
  //   "channelType",
  //   "inputMapping",
  //   "selMixTank",
  //   "repeat2Week",
  //   "textTimeOfDay",
  //   "textGeneral",
  // ]

  isValidJson=(str)=>{
    try{
      JSON.parse(str);
      return true;
    }catch{
      return false;
    }

  }

  loadFuiControl=(props)=>{
    cl(globs.fuiPages)
//     cl(props)
    // cl(this.controlTypes)
    let pageType = props.match.params.pageType;
    var controlId;
    if (props.match.params.controlId === undefined){
      controlId = -1;// means create new control
      cl("new")
    }else{
      controlId = props.match.params.controlId*1;
    }
    // getFuiPages().then(r=>{
      var controlList = null;
      // cl("here")
      // console.log(globs)
      // cl(globs)
      var r;
      var gatewayType
      // controlList = r[pageType];
      controlList = globs.fuiPages[pageType];
      cl(controlList);
      // cl(controlId)
        gatewayType=controlList.gatewayType||"1800"
        cl(gatewayType)
        this.tableList = makeParmTabs(gatewayType||1800);
        cl(this.tableList)
        if (controlList.controls.length === 0){// needs to be taken out!
          controlList.controls = this.initControls();
        }
        var control, tabCol;
        if(controlId >= 0){
          control = controlList.controls[controlId];
          this.tableList = makeParmTabs(gatewayType||1800);
//           cl(control)
//           cl(gatewayType)
          tabCol = paramIdToTableColumn(control.pid,gatewayType);
// cl(tabCol)
// cl(this.tableList)
          if (this.tableList[tabCol[0]] !== undefined){
            this.columnList = this.tableList[tabCol[0]].cols;
            // cl("get fui")
            this.pidInfo = this.getPidInfo(tabCol[0], tabCol[1]);
            // cl(this.pidInfo)
            // cl(tabCol)
          }
        }else{
          control = -1 ;
          tabCol = [-1, -1];
        }
        // cl(control);
        if (this.mounted){
          this.setState({
            control: control,
            customProps: JSON.stringify(control.customProps),
            validJSON: true,
            name: control.name,
            title: control.title,
            selType: control.type,
            gatewayType: gatewayType,
            pid: control.pid,
            k: control.k,
            selTable: tabCol[0],
            selColumn: tabCol[1],
          })
        }else{
          // cl(tabCol)
          this.state = {
            // loaded: true,
            control: control,
            customProps: JSON.stringify(control.customProps),
            validJSON: true,
            name: control.name,
            title: control.title,
            selType: control.type,
            gatewayType: gatewayType,
            pid: control.pid,
            k: control.k,
            // selTable: "snapEcph",
            selTable: tabCol[0],
            selColumn: tabCol[1],
            fuiZone: globs.fuiZone,
            fuiChan: globs.fuiChan,
            fuiUnit: globs.fuiUnit,
            fuiInd: globs.fuiInd,
          };
        }
    // });
  }

  initControls=()=>{
/* this creates the first controls, so that we can edit them*/
    return [];
    //   {title: "first", type: "text", pid: 4300, k: 10},
    //   {title: "second", type: "text", pid: 4300, k: 10},
    //   {title: "third", type: "text", pid: 4300, k: 10},
    // ]
  }

  onTextChange=(e)=>{
    // cl(e.target.value)
    let val = e.target.value;
    switch (e.target.id){
      case "title":
        this.setState({title: val});
        break;
      case "name":
        this.setState({name: val})
        break;
      default:
        break;
    }

  }

  showOneText=(lab, id, place, val, multi)=>{
    return(
      <div style={{margin: 10}}>
        <TextField
          label={lab}
          id={id}
          value={val}
          multiline={multi}
          onChange={this.onTextChange}
          placeholder={place}
        />
      </div>
    );
  }

  onTypeSelectChange=(e)=>{
    // cl(e.target.value)
    this.setState({selType: e.target.value})

  }

  initTypeSelect=()=>{
    return this.controlTypes.map((t, i)=>{
        return(
            <MenuItem key={i} value={t}>{t}</MenuItem>
        );
    })
  }

  showTypeSelect=()=>{
    return(
      <div style={{margin: 10}}>
        <FormControl className={"formControl"}>
          <InputLabel>Type</InputLabel>
          <Select
            value={this.state.selType}
            onChange={this.onTypeSelectChange}
            >
            {(!this.controlTypes.includes(this.state.selType)) &&
              <MenuItem value={this.state.selType}>Select a Type</MenuItem>}
            {this.initTypeSelect()}
          </Select>
        </FormControl>
      </div>
    );
  }

  makeColumns=(table)=>{
    cl(table)
    cl(this.tableList)
    this.columnList = this.tableList[table].cols;
    cl(this.tableList[table].cols)

  }

  onTableSelectChange=(e)=>{
    cl(e.target.value)
    this.setState({selTable: e.target.value})
    // cl(this.tableList[e.target.value])
    this.makeColumns(e.target.value);
  }

  initTableSelect=()=>{
    return Object.keys(this.tableList).map((t, i)=>{
        return(
            <MenuItem key={i} value={t}>{t}</MenuItem>
        );
    })
  }

  showTableSelect=()=>{
    if(!this.tableList){this.tableList=[]}
//     cl(this.state)
//     cl(this.tableList)
//     cl(this.state.selTable)
    return(
      <div style={{margin: 10}}>
        <FormControl className={"formControl"}>
          <InputLabel>Table</InputLabel>
          <Select
            value={this.state.selTable}
            onChange={this.onTableSelectChange}
            >
            {(!Object.keys(this.tableList).includes(this.state.selTable)) &&
              <MenuItem value={this.state.selTable}>Select a Table</MenuItem>}
            {this.initTableSelect()}
          </Select>
        </FormControl>
      </div>
    );
  }

  onColumnSelectChange=(e)=>{
    // cl(e.target.value)
    this.setState({selColumn: e.target.value})
    this.pidInfo = this.getPidInfo(this.state.selTable, e.target.value);
    cl(this.pidInfo)
    // this.calcTestPidValue();
  }

  readPidValue=(z, c, i)=>{
    var zone, chan, parm;
    try{
      zone = dbVals.z[z];
      // cl(zone)
      try{
        chan = zone[c];
        // cl(chan);
        try{
          parm = chan[i];
        }catch{}
      }catch{}
    }catch{}
    if (parm === undefined) parm = "---";
    // cl(parm)
    // cl([z, c, i, parm])
    return parm;
    // cl(dbVals)

  }

  // types of organization:
  // 0: zone, unit
  // 1: zone, unit, channel
  // 2: zone, unit, channel, tank
  // 3: zone, unit, channel, tank, ecph sensor
  // 4: zone
  // 5: (site wide)
  // 7: controller config settings

  calcTestPidValue=()=>{
// need to calculate z, c, i to look up in dbVals.z
// this is called from render, so can't use setState
    let z0 = this.state.fuiZone;
    let u0 = this.state.fuiUnit;
    let c0 = this.state.fuiChan;
    let in0 = this.state.fuiInd;
    // cl(this.pidInfo)
    var k, pid;
//     cl("calc")
    try{
      k = this.pidInfo.k;
      pid = this.pidInfo.pid;
    }catch{return 0}
//     cl("calc")
    
    var z, c, i;
//     cl([z0, u0, pid, in0, this.pidInfo.tableType])
    switch(this.pidInfo.tableType){
      case 0:// unit info
        z = z0;
        c = 240 + u0;
        i = pid + k * in0;
        break ;
      case 1:
        z = z0;
        c = 40 * u0 + c0;
        i = pid + k * in0;
        break ;
      case 2:
        z = z0;
        c = 192 + 8 * u0 + c0;// channel is 192-
        i = pid + k * in0;
        break ;
      case 3:
        z = z0;
        c = 192 + 8 * u0 + c0;// channel is 192-
        i = pid + k * in0;// index of 0-2 sensor
        break ;
      case 4:
        z = z0;
        c = 255;
        i = pid + k * in0;
        break ;
      case 5:// site wide
        z = 255;
        c = 255;
        i = pid + k * in0;
        break ;
      case 6:// zone config settings
        z = z0;
        c = 255;
        i = pid + k * in0;
        break ;
      case 7:// controller config settings
        z = z0;
        c = 240 + u0;
        i = pid + 2 * in0;
        break ;
      default:
        break;

    }
    // cl("calc test")
//     cl([z, c, i])
    
    this.parmValue = this.readPidValue(z, c, i);
    // this.setState({parmValue: 0})
  }

  initColumnSelect=()=>{
    // cl(this.columnList)
    return Object.keys(this.columnList).map((t, i)=>{
        return(
            <MenuItem key={i} value={t}>{t}</MenuItem>
        );
    })
  }

  showColumnSelect=()=>{
    cl(tableIds)
    cl(this.state.gatewayType)
    cl(this.tableList[this.state.selTable])
    cl(this.columnList)
    cl(this.state.selColumn)
    let gotTable = tableIds[this.state.gatewayType].includes(this.state.selTable);
    // cl(gotTable)
    // cl(this)
    // cl(this.state)
    // cl(this.state.selTable)
    // cl(this.columnList)
    // cl(this.tableList)
    // cl(this.tableList[this.state.selTable] === undefined)
    return(
      <div style={{margin: 10}}>
        <FormControl className={"formControl"}>
          <InputLabel>Column</InputLabel>
          <Select
            value={this.state.selColumn}
            onChange={this.onColumnSelectChange}
            >
            {(this.tableList[this.state.selTable] === undefined) &&
              <MenuItem value={this.state.selTable}>Select a Table First</MenuItem>}
            {(this.columnList[this.state.selColumn] === undefined) &&
              <MenuItem value={this.state.selColumn}>Select a Column</MenuItem>}
            {(gotTable) && this.initColumnSelect()}
          </Select>
        </FormControl>
      </div>
    );
  }

  saveParam=()=>{
    cl("save param")
  }

  testChange=(e)=>{
    let val = e.target.value*1;
    switch(e.target.id){
      case "tz":
        this.setState({fuiZone: val})
        globs.fuiZone = val;
        break;
      case "tu":
        this.setState({fuiUnit: val})
        globs.fuiUnit = val;
        break;
      case "tc":
        this.setState({fuiChan: val})
        globs.fuiChan = val;
        break;
      case "ti":
        this.setState({fuiInd: val})
        globs.fuiInd = val;
        break;
      default:
        break ;
    }
    // this.getPidInfo(this.state.)
    // vals = this.getPidInfo(this.state.selTable, this.state.selColumn);

    // this.calcTestPidValue();
    // cl(e.target.id)
    // cl("ch")
  }

  setParmValue=(e)=>{
    cl("set")
    this.setState({parmValue: e.target.value})

  }

  showTestSettings=()=>{
    return(
      <table><tbody>
      <tr>
      <td>Z:<input type="text" size="1" id="tz" value={this.state.fuiZone}
        onChange={this.testChange}/></td>
      <td>U:<input type="text" size="1" id="tu" value={this.state.fuiUnit}
        onChange={this.testChange}/></td>
      <td>C:<input type="text" size="1" id="tc" value={this.state.fuiChan}
        onChange={this.testChange}/></td>
      <td>I:<input type="text" size="1" id="ti" value={this.state.fuiInd}
        onChange={this.testChange}/></td>
      </tr>
      <tr>
      <td align="right">Current</td>
      <td align="right" colSpan="2"><input type="text" size="12"
        value={this.parmValue} onChange={this.setParmValue}
      /></td>
      <td align="right"><button type="button" onClick={this.saveParam}>Save</button></td>
      </tr>
      </tbody></table>
    );
  }

  getPidInfo=(tabid, colid)=>{
    // cl([tabid, colid])
    let tab = this.tableList[tabid];
    let colOffset = this.columnList[colid];
//     cl(tab)
//     cl(colOffset)
//     cl(this.tableList)
    // cl(tab.base);
    // cl(colOffset);
    switch(tab.type){
      case 0:
      case 1:
      case 2:
//         cl(`ecph ${colid}`)
      case 3:
      case 4:
      case 5:
      return {
        pid: tab.base + colOffset,
        k: tab.indSize,
        tableType: tab.type,
      }
      case 6:// zone configuration settings
        return {
          pid: tab.base + tab.indSize * colOffset + 1,
          k: 0, // tab.indSize,
          tableType: tab.type,
        }
      case 7:// controller configuration
        return {
          pid: tab.base + tab.indSize * colOffset + 1,
          k: 0, // tab.indSize,
          tableType: tab.type,
        }
      default:
      return {
        pid: tab.base + colOffset,
        k: tab.indSize,
        tableType: tab.type,
      }
    }
  }

  saveControl=()=>{
    // var k, pid, tableType;
    let controlId = this.props.match.params.controlId;
    let pageType =  this.props.match.params.pageType;
    // cl(this.state.selTable)
    cl(this.tableList)
    let gotTab = this.tableList[this.state.selTable] !== undefined;
    let gotCol = this.columnList[this.state.selColumn] !== undefined;
    cl(gotTab, gotCol)
    var vals;
    if (gotTab && gotCol){
      vals = this.getPidInfo(this.state.selTable, this.state.selColumn);
      cl(vals)
      // let tab = this.tableList[this.state.selTable];
      // let colOffset = this.columnList[this.state.selColumn];
      // pid = tab.base + colOffset;
      // k = tab.indSize;
      // tableType = tab.type;
    } else {
      vals = {k: 0, pid: -1, tableType: 0}
      // k = 0;
      // pid = -1;
      // tableType = 0;
    }
    let cp = this.isValidJson(this.state.customProps)
      ? JSON.parse(this.state.customProps) : {}
    let control = {
      customProps: cp,
      k: vals.k, // k,
      pid: vals.pid, // pid,
      tableType: vals.tableType, // tableType,
      name: this.state.name,
      title: this.state.title,
      type: this.state.selType,
    };
    // cl(controlId)
//     cl(control)
    if(controlId === undefined){
      globs.fuiPages[pageType].controls.push(control);// new
    }else{
      globs.fuiPages[pageType].controls[controlId*1] = control;// update
    }
    // cl(globs)
    saveFuiPage(pageType, globs.fuiPages[pageType])
    // cl(globs)
  }

  onButtonClick=(name)=>{
    cl(name)
    switch(name){
      case "Save":
        this.saveControl();
        cl(this.state)
        history.goBack();
        break;
      case "Cancel":
        history.goBack();
        break;
      default:
        break;
    }
    // cl(name)
  }

  showButton=(name)=>{
    return(
      <Button onClick={
        ()=>this.onButtonClick(name)}
      style={{margin: 5}}
      variant="contained" color="primary" className={"button"}>
        {name}
      </Button>
    );
  }

  onCustomPropsChange=(e)=>{
  // cl(e.target.value)
  let validJSON = false;
  let customProps = {};
  try{
    let customProps = JSON.parse(e.target.value);
    validJSON = true;
  }catch{}
  this.setState({
    customProps: e.target.value,
    validJSON: validJSON})
}

showCustomProps=()=>{
  // cl("custom")
  return(
    <div style={{overflowX: "auto", height: 200, width: 300}}>
    <TextField
      multiline={true}
      fullWidth={true}
      label="Custom Props"
      id="customProps"
      value={this.state.customProps}
      onChange={this.onCustomPropsChange}
    />
    </div>
  );
}

  barClick = async (e)=>{
    cl(e.currentTarget.id);
    switch (e.currentTarget.id){
      case "home":
//         userSpecificHome()
        let dash=await getHomeDashboard()
        if(dash){history.push(`/usa/dash/${dash}`)}
//         history.push("/usa/dashboard")
//         history.push("/sa")
        break;
      default:
        break;
    }
  }

  render(){
//     cl("render")
//     this.mounted = true;
    // cl(this.state)
    // cl(this.columnList)
    // cl(this.paramValue)
    if (this.state.loaded){
//       cl("render2")
      this.calcTestPidValue();
      return(
        <div>
          <MainBar home settings
          menu click={this.barClick} title="FUI Control Editor" />
          {this.showOneText("Name", "name", "", this.state.name, false)}
          {this.showOneText("Title", "title", "", this.state.title, false)}
          {this.showTypeSelect()}
          <h3>Controlled Parameter</h3>
          {this.showTableSelect()}
          {this.showColumnSelect()}
          {this.showCustomProps()}
          {!this.state.validJSON &&
            <div style={{color: "red"}}>Invalid JSON</div>
          }
          <div>For Testing:</div>
          {this.showTestSettings()}
          <table><tbody>
          <tr>
            <td>{this.showButton("Save")}</td>
            <td>{this.showButton("Cancel")}</td>
          </tr>
          </tbody></table>
        </div>
      );
    }else{
//       cl("pt 2")
      return(
        <div>
          <MainBar home settings
          menu click={this.barClick} title="FUI Control Editor3" />
          {this.state.loadMsg}
        </div>
      );
    }
  }

}

export default EditControlFui ;
