import React from 'react';
import C18Duration00 from '../../usa/components/C18Duration00'
import {getEquipmentStatus} from '../../usa/components/C18EquipmentStatus00'
import {getParamId,getPearlUsed} from '../../usa/utils/utils';
import {sendArray, checkSendArray,dbVals} from '../../components/utils/http';
import {cl, globs, constant, secsToHms, minsToHm, hmToMins,getTimeI} from '../../components/utils/utils';

class AllIrrigation extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      allZones:true,
    }
    this.props.getValue(props.ind, {type: "scalar"})
    this.loadInfo()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
    this.subscribe_startDisableEvent=globs.events.subscribe(
      "allIrrStartDisable",this.startDisable)
//     this.subscribe_keyUpEvent=globs.events.subscribe("keyUp",this.keyUp)

  }

  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
    this.subscribe_startDisableEvent.remove()
//     this.subscribe_keyUpEvent.remove()
  }

  saveIrrData=()=>{
    var pParm=(ch,pid,val)=>{
      parms.push({
        z:ch.zone.siteZoneIndex,
        c:ch.chan,
        t:getTimeI(),
        f:1,
        i:pid,
        d:val,
      })
    }
    let parms=[]
    let st=this.state
    st.chans.forEach(ch=>{
      cl(ch)
      if(((ch.type==constant.EQ_IRR)&&(ch.mode==constant.IRR_SCHEDULED))&&
        (st.allZones||(ch.zone.zoneId==this.props.current.zoneId))){
          pParm(ch,this.nameId,ch.name)
          pParm(ch,this.durId,ch.dur)
          ch.starts.forEach((st,i)=>{
            pParm(ch,this.startIds[i],st)
          })
        }
    })
//     cl(parms)
    checkSendArray(parms,this.props.current.virtual).then(r=>{
      globs.events.publish("saveOK",true)})
/* parms:
{
    c,d,f,i,t,z - s will be filled in
  }*/
  }

  savePageEvent=(cmd)=>{
    if(cmd=="save"){
//       cl("save")
      this.saveIrrData()
    }
  }

//   keyUp=(e)=>{
//     cl(e)
//     if((e.key=="d")&&(e.ctrlKey)){
//       cl("got ctrlD")
//     }
//   }

  startDisable=(cmd)=>{
    this.onChange("start",{ind:cmd.ind,st:cmd.st,start:"24:00"})
  }

  getIrrIds=()=>{
    let cc="configuration_channels"
    let ccd="configuration_channel_data"
    this.durId=getParamId(ccd,"on_duration")
    this.modeId=getParamId(ccd,"irrigation_mode")
    this.typeId=getParamId(cc,"channelType")
    this.nameId=getParamId(cc,"channelName")
    this.startIds=[...Array(6).keys()].map(i=>{
      return getParamId(ccd,`start_time_${i+1}`)})
  }

  getUsedChans=(chans,zone)=>{
    var chs=[]
    if(zone.gatewayType==1900){
      chs=getPearlUsed(zone.siteZoneIndex).map(c=>{return c.chInd})
    }else{
      this.props.current.channelInfo.forEach((c,i)=>{if(c.used){chs.push(i)}})
    }
    chs.forEach(c=>{
      let ch=((dbVals.z[zone.siteZoneIndex]||{})[c])||{}
      let ci={
        zone:zone,// refers to data in globs.zonesInfo.info
        chan:c,
        type:+ch[this.typeId],
        mode:+ch[this.modeId],
        name:ch[this.nameId],
        dur:ch[this.durId],
        starts:this.startIds.map(id=>{return ch[id]}),
      }
      chans.push(ci)
    })
  }

  getChannels=()=>{
    let siteId=this.props.current.siteId
    let zones=globs.zonesInfo.info.filter(z=>{return z.siteId==siteId})
    let chans=[]
    zones.forEach(z=>{
      this.getUsedChans(chans,z)
    })
    return chans
  }

  loadInfo=()=>{
    this.getIrrIds()
//     this.setState({chans:this.getChannels(),loaded:true})
    this.state.chans=this.getChannels()
    this.state.loaded=true
//     cl("li done")

  }

  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "dur":
      case "name":
      case "start":
        globs.events.publish("savePageEnable",true)
        let chans=this.state.chans.slice(0)
        let ch=chans[vals.ind]
        if(type=="dur"){
          ch.dur=vals.dur
        }
        if(type=="name"){ch.name=vals.name}
        if(type=="start"){
          ch.starts[vals.st]=hmToMins(vals.start)
        }
        this.setState({chans:chans})
        break
      case "allZones":
        this.setState(vals)
        break
      default:
        break
    }
  }

  fillDown=(type,vals)=>{
        globs.events.publish("savePageEnable",true)
    let st=this.state
    let chans=st.chans.slice(0)
    let filling=false
    let fillVal=1440
    chans.forEach((ch,i)=>{
      if(((ch.type==constant.EQ_IRR)&&(ch.mode==constant.IRR_SCHEDULED))&&
        (st.allZones||(ch.zone.zoneId==this.props.current.zoneId))){
        if(type=="start"){
          if(filling){ch.starts[vals.st]=fillVal}
          if(vals.ind==i){
            filling=true
            fillVal=ch.starts[vals.st]
          }
        }
        if(type=="run"){
          cl(ch)
          if(filling){ch.dur=fillVal}
          if(vals.ind==i){
            filling=true
            fillVal=ch.dur
          }
        }
        if(type=="name"){
//           cl(ch)
          if(filling){ch.name=fillVal}
          if(vals.ind==i){
            filling=true
            fillVal=ch.name
          }
        }
      }
    })
    this.setState({chans:chans})
  }

  onKeyPress=(type,vals)=>{
    switch(type){
      case "start":
//         cl(vals.e)
//         if((vals.e.key=="d")&&(vals.e.ctrlKey)){
//           vals.e.preventDefault()
//           this.fillDown(type,vals)
//         }
        if(["Backspace","Delete"].includes(vals.e.key)){
          vals.start="24:00"
          this.onChange(type,vals)

        }
//         break
      case "run":
      case "name":
//         cl(type,vals)
        if((vals.e.key=="d")&&(vals.e.ctrlKey)){
          vals.e.preventDefault()
          this.fillDown(type,vals)
        }
        break
      default:
        break
    }
  }

  showStarts=(i)=>{
    let ch=this.state.chans[i]
//     cl(ch)
    let starts=ch.starts.map((st,j)=>{
//       cl(st)
      let id=`{"cmd":"allIrrStartDisable","ind":${i},"st":${j},"type":"${i}-${j}"}`
      let dispT=minsToHm(st)
      if(dispT=="24:00"){dispT=""}
      return(
        <td key={j}>
          <input
            type="time"
            id={id}
            value={dispT}
            onChange={e=>this.onChange("start",{ind:i,st:j,start:e.currentTarget.value})}
            onKeyDown={e=>this.onKeyPress("start",{ind:i,st:j,e:e})}
//             onKeyUp={e=>this.onKeyPress("startUp",{ind:i,st:j,e:e})}
          />
        </td>
      )
    })
    return starts
  }

  showRunTime=(i)=>{
    let ch=this.state.chans[i]
    let ti=ch.dur
//     let dispT=secsToHms(ti)
    return(
      <C18Duration00 parms={{
        width:80,
        style:{marginRight:10},
        duration:ti,
        onChange:e=>this.onChange("dur",{ind:i,dur:e.duration}),
        saveOK:true,//this.props.parms.saveOK
        onKeyDown:e=>this.onKeyPress("run",{ind:i,e:e}),
      }}/>
    )
  }

  showName=(i)=>{
    let ch=this.state.chans[i]
    let name=ch.name
//     let dispT=secsToHms(ti)
    return(
      <input
        style={{marginRight:10}}
        type="text"
        value={name}
        size="5"
        onChange={e=>this.onChange("name",{ind:i,name:e.currentTarget.value})}
        onKeyDown={e=>this.onKeyPress("name",{ind:i,e:e})}
      />
    )
  }

  showStatus=(chInd)=>{
    let st=this.state
    let ch=st.chans[chInd]
    let gwType=this.props.current.gwType
    let zInd=ch.zone.siteZoneIndex
    let i=ch.chan
    let chFull=dbVals.z[zInd][i]
    let status=getEquipmentStatus(gwType,zInd,i,chFull)
    return(
      <div style={{width:150}}>{status}</div>
    )
  }

  showChanTable=()=>{
    let st=this.state
    var showChanHead=()=>{
      var hStyle={fontSize:16,fontWeight:700}
      return(
        <tr>
          <th style={hStyle}>Zone</th>
          <th style={hStyle}>Chan</th>
          <th style={hStyle}>Status</th>
          <th style={hStyle}>Run</th>
          <th style={hStyle}>Start 1</th>
          <th style={hStyle}>Start 2</th>
          <th style={hStyle}>Start 3</th>
          <th style={hStyle}>Start 4</th>
          <th style={hStyle}>Start 5</th>
          <th style={hStyle}>Start 6</th>
        </tr>
      )
    }
    var trimName=(name,length)=>{
      if(name.length>length){
        name=name.substring(0,length)+"..."
      }
      return name.replace(/ /g,`\u00A0`)

    }
    let rows=[]
    st.chans.forEach((ch,i)=>{
//       cl(ch)
      let zName=trimName(ch.zone?.zoneName||"",9)
      let cName=trimName(ch.name||"",9)
      if(((ch.type==constant.EQ_IRR)&&(ch.mode==constant.IRR_SCHEDULED))&&
        (st.allZones||(ch.zone.zoneId==this.props.current.zoneId))){
        rows.push(
          <tr key={i}>
            <td title={ch.zone?.zoneName}>{zName}</td>
            <td title={this.state?.chans[i]?.name}>{this.showName(i)}</td>
            <td>{this.showStatus(i)}</td>
            <td>{this.showRunTime(i)}</td>
            {this.showStarts(i)}
          </tr>
        )
      }
    })
    return(
      <table><tbody>
      {showChanHead()}
      {rows}
      </tbody></table>
    )
  }

  render(){
    let st=this.state
    if(st.loaded){
      return(
        <div style={{overflowX:"auto"}}>
          <input
            type="checkbox"
            id="allZones"
            checked={st.allZones}
            onChange={e=>this.onChange("allZones",{allZones:e.currentTarget.checked})}
          />
          <label htmlFor="allZones" style={{display:"inline-block"}}>&nbsp;Show All Zones
          </label>
          {this.showChanTable()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}


  export default AllIrrigation ;
