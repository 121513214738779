import React from 'react';
import ReactHtmlParser from 'react-html-parser'
import {cl,globs,constant} from '../../components/utils/utils';

class C18Confirm00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      mounted:false,
      opacity:0,
      fading:false,
      showPopup:true,
    }
  }
  
  componentDidMount=()=>{
    this.mounted=true
  }
  
  componentWillUnmount=()=>{
    this.mounted=false
  }
  
  mySetState=(state)=>{
    if(this.mounted){this.setState(state)}
  }
  
  onChange=(type,vals)=>{
    switch(type){
      case "popup":
        let r=this.props.parms.resolve
        if(r)(r(vals.cmd))
        break
    }
  }
  
  doFading=()=>{
    let step=0.01
    let diff=this.props.parms.opacity-this.state.opacity
    if (Math.abs(diff)<step){
      this.mySetState({fading:false, opacity:this.props.parms.opacity})
    }else{
      let op=this.state.opacity//+(diff>0)?step:(0-step)
      if(diff>0){
        op+=step
      }else{
        op-=step
      }
//       cl(this.props.parms.opacity,this.state.opacity,op)
      this.mySetState({opacity:op})
      setTimeout(this.doFading,80)
    }
  }
  
  render(){
//     cl("render confirm")
//     cl(this.props.parms)
    let diff=this.props.parms.opacity-this.state.opacity
    if(diff){setTimeout(this.doFading,1)}
    var ch
    if(Array.isArray(this.props.parms.text)){
      ch=<p>{this.props.parms.text}</p>
    }else{
      ch=<div>{this.props.parms.text.split('\n').map( (it, i) => <div key={'x'+i}>{it}</div> )}</div>
    }
//       ch=this.props.parms.text
//     cl(ch)
    return(
      <>
        <div id="popup" className="popup" style={{
          opacity: this.state.opacity,
          display:(this.state.opacity!=0)?"block":"none"}}>
          {ch}
          <br />
          <div className="alignright">
          {this.props.parms.buttons.map((b,i)=>{
            let filled=(["Yes","OK","Save"].includes(b))?"filled ":"outlined"
            return(
            <button id="popupButton" key={i} type="button" className={`${filled} left-margin`}
              onClick={e=>this.onChange("popup",{cmd:b})}
            >{b}</button> 
            )
          })}
          </div>
        </div>
        <div className="popup-scrim"></div>
      </>
    )
  }
}
      
export default C18Confirm00;
