import React from 'react';
import {wsTrans} from '../../usa/utils/utils'
import {loadGJ} from './C18utils'
import C18Button00 from '../../usa/components/C18Button00'
import {cl,globs,constant,getTime} from '../../components/utils/utils';

class C18SidebarImages00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
    }
//     this.refs=Array(20).fill()
//     for(let i=0;i<20;i++){this.refs[i]=React.createRef()}
//     cl(this.refs[0])
    this.getGrowJournalImages(this.props.parms)
    this.subscribe_keyDown = globs.events.subscribe("keyDown", this.keyDown)
    this.subscribe_newContextEvent=globs.events.subscribe("newContext",this.newContext)
    this.subscribe_refreshEvent=globs.events.subscribe("RefreshImagesEntries",this.newContext)
  }
  
  componentDidMount=()=>{
    this.refs=Array(20).fill()
    for(let i=0;i<20;i++){this.refs[i]=React.createRef()}
//     cl(this.refs[0])
    this.mounted=true
  }
  componentWillUnmount=()=>{
    this.subscribe_keyDown.remove()
    this.subscribe_newContextEvent.remove()
    this.subscribe_refreshEvent.remove();
    this.mounted=false
  }
  
  newContext=async(vals)=>{// page has changed
//     cl(vals)
    var p
    if(vals.level=="config"){
      p={level:vals.level,siteId:vals.siteId,zoneId:vals.zoneId,mode:"fui",pageType:vals.pageType,zuci:vals.zuci}
    }else{
      p={level:vals.level,siteId:vals.siteId,zoneId:vals.zoneId}
    }
    this.getGrowJournalImages(p)
//     let entries=await this.loadGJ(this.state.gjSkip,this.state.gjLimit,p)
//     cl(p.level)
//     if(this.mounted){this.setState({loaded:true,entries:entries,sgjLevel:p.level,sgjMode:p.mode,sgjSite:p.siteId,
//       sgjZone:p.zoneId,pageType:vals.pageType,zuci:vals.zuci})}

  }
  
  keyDown=(e)=>{
//     cl(key)
    var key=e.key
    var imageIndex=0
//     cl(this.state.imageIndex)
    if(typeof(this.state.imageIndex)!="undefined"){
      imageIndex=this.state.imageIndex
      let len=this.state.images.length
//       cl(len)
      switch(key){
        case "ArrowRight":
          imageIndex=(imageIndex+1)%len
          this.refs[imageIndex].current.focus()
          break
        case "ArrowLeft":
          imageIndex=(imageIndex+len-1)%len
          this.refs[imageIndex].current.focus()
          break
      }
//       cl(imageIndex)
    }
    if(this.state.images[imageIndex]){
      this.props.parms.onChange({type:"image",path:this.makeImagePath(this.state.images[imageIndex].imageId)})
      this.setState({imageIndex:imageIndex})
//       cl(this.imageButs)
//       cl(imageIndex)
//       cl(this.imageButs[imageIndex])
//       this.imageButs[imageIndex].focus()
    }
//     cl(imageIndex)
  }
  
  getTimePeriod=()=>{
    let end=Math.floor(getTime())+3*3600
    let begin=end-14*86400
    return [begin,end]
  }
  
  makeImagePath=(id, ext)=>{
    return `${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`// .${ext}
  }
  
  getGrowJournalEntries=async(start,count,p)=>{
    let entries=await loadGJ(start,count,{},p)
    let threads=[]
    entries.forEach(e=>{threads.push(e.threadId)})
    let query={threadId:{$in:threads}}
//     cl(query)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId,
      body: query})
//     cl(r.data)
    return r.data
//     return entries
  }
  
  getGrowJournalImages=async(p)=>{
//     cl(p)
//     if(p.level=="account"){
//       if(this.mounted){
//         this.setState({images:[],loaded:true})
//       }else{
//         this.state.images=[]
//         this.state.loaded=true;
//         return
//       }
//     }
//     let [begin,end]=this.getTimePeriod()
// //     cl(p)
//     let query={zoneId: p.zoneId, 
//         siteId: p.siteId,
//         modTime: {$gt: begin, $lte: end}}
//     if(p.level=="config"){
//       query.tags=`${p.pageType}-${p.zuci}`//p.pageType
//     }
//     cl(query)
//     let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
//       sessionId: globs.userData.session.sessionId,
//       body: query})
//     let entries=r.data
//     let entries=await loadGJ(0,12,p)
    let entries=await this.getGrowJournalEntries(0,12,p)
    let images=[]
    
    entries.forEach(e=>{
//       cl(e)
      e.images.forEach(im=>{
        // cl(im)
        let path=this.makeImagePath(im.id,"jpg")
        images.push({
          id:e.growJournalId,imageId:im.id,
          url:`${constant.expressUrl}/usa/images/uploads/${path}`,
          format:im.format,
          dispTime:e.dispTime,
          name: im.name,
          size: im.size
        })
      })
//       let parts=e.body.split("{Img:")
//       if(parts.length>1){
//         for(let i=1;i<parts.length;i++){
//           let p=parts[i]
//           let pos=p.indexOf("}")
//           let imageId=p.substr(0,pos)
//           let path=this.makeImagePath(imageId,"jpg")
//           images.push({
//             id:e.growJournalId,imageId:imageId,
//             url:`${constant.expressUrl}/usa/images/uploads/${path}`,
//             dispTime:e.dispTime})
//         }
//       }
    })
    images.sort((a,b)=>{
      if(a.dispTime<b.dispTime){return 1}
      if(a.dispTime>b.dispTime){return -1}
      return 0
    })
//     cl(images)
    this.setState({images:images,loaded:true})
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
//     cl(this.state)
    switch(type){
      case "image":
      case "imageKey":
        this.props.parms.onChange({type:"image",path:this.makeImagePath(vals.imageId)})
        this.setState(vals)
//         cl(vals)
        break
      default:
        break
    }
  }
  
//   makeImagePath=(id, ext)=>{
//     return `${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`// .${ext}
//   }
  
  showImage=(path,s)=>{
    this.props.onChange({type: "image", path: path, section: s})//showImage
  }
  
  showImageVideo=(img,i)=>{
    // cl(img)
    let path=this.makeImagePath(img.imageId, "jpg")
//     cl(path)
//     cl(img.url)
    const lastDot = img.imageId.lastIndexOf(".");
    const imgExt = img.imageId.slice(lastDot + 1);
    if((img.format||"").indexOf("video")>=0){
      return(
        <video key={i} width={320} height={240} controls={true}
        src={`${constant.expressUrl}/usa/images/uploads/${path}#t=0.001`}
        type={img.format}/>
      )
    } else if (["apng", "avif", "gif", "jpg", "jpeg", "png", "svg", "webp"].includes(imgExt)) {
      return(
        <button ref={this.refs[i]} key={i} type="button"
        onKeyPress={e=>{this.onChange("imageKey",{imageId:img.imageId,imageIndex:i})}}
        ><img src={img.url} alt="" 
        onClick={e=>this.onChange("image",{imageId:img.imageId,imageIndex:i})}/></button>
      )
    } else {
      // show download
      // cl("show download")
      let url = `${constant.expressUrl}/usa/images/uploads/${path}`
      let name = img.name || path
      return(
        <div key={`media-${i}`}>
          <C18Button00 id="downloadFile" className="download-file" 
          onClick={async(e)=>this.downloadFile(url, name)}>
            {(name.length <= 20 ) ? name : `${name.substring(0, 20)}...`} 
            <span className="material-icons">
            file_download
            </span>
          </C18Button00>
        </div>
        )
    }
  }

  downloadFile=(url, name)=>{
    // cl("downloading file to")
    // cl([url, name])
     let method="GET"
    let query = `cmd=download&name=${name}`
    // let query = `cmd=download&name=${name.replaceAll(\s, "_")}`
    var a = document.createElement("a");
    // cl(`${url}?${query}`)
    a.href = `${url}?${query}`
    a.target = "_blank"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
  
  showImageList=()=>{
//       cl("image")
//     cl(this.refs[5])//ref={this.refs[i]}
    // cl(this.state.images)
    if (this.state.images.length === 0) {
      return (
        <div style={{textAlign:"center"}}>No GJ Media</div>
      )
    }
    this.imageButs=this.state.images.map((img,i)=>{
      return this.showImageVideo(img,i)
//       cl(img)
//       return <button ref={this.refs[i]} key={i} type="button"
//       onKeyPress={e=>{this.onChange("imageKey",{imageId:img.imageId,imageIndex:i})}}
//       ><img src={img.url} alt="" 
//       onClick={e=>this.onChange("image",{imageId:img.imageId,imageIndex:i})}/></button>
    })
    return this.imageButs
  }
  
  render(){
//     cl(this.refs[0])
    if(this.state.loaded){
    return(
      <div>
        <div className="info-section">
          <div className="title">LATEST GROW JOURNAL MEDIA</div>
        </div>

        <div className="photo-grid">
          {this.showImageList()}
        </div>
      </div>
    )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18SidebarImages00;
