import React from 'react';
import C18TabsHeader00 from './C18TabsHeader00'
import {wsTrans,getParamId} from '../utils/utils'
import {loadUsersInfo,loadSitesInfo,loadZonesInfo,getSiteIndex,getZoneIndex,
  addToAdminLog,alarmNames,sensors,addedSensorTypeNames, getSiteName,getZoneName} from './C18utils'
import {cl,globs,constant,getTime,az} from '../../components/utils/utils';


class C18ManageAlarms extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      userAlarm:"user",
      mode:"account"
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveAlarms)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
//     this.loadInfo()
  }
  
  updateAlarmLevels=(adminAdds)=>{

//     cl(this.alarmIds)
//     cl(adminAdds)
/*adminAdds:
action
alarm
level
levelName
name
newVal
oldVal
time
userId
*/
//     cl(this.mixTankIds)
//     cl(this.alarmSects)
//     cl(this.sensorTabs)
//     let gai=globs.accountInfo.info
    let gsi=globs.sitesInfo.info
//     cl(gai)
    gsi.forEach((site,i)=>{
      let levels=this.levels[site.siteId]// levels for one site
      Object.keys(levels).forEach(k=>{
        if(levels[k]!=(site.alarmLevels||{})[k]){// each alarm
          let admin={
            action:"manageAlarmLevels",
            alarm:k,
            level:"site",
            levelName:site.name,
            newVal:this.levels[k],
            oldVal:(site.alarmLevels||{})[k],
            time:Math.floor(getTime()),
            userId:globs.userData.session.userId,
          }
          adminAdds.push(admin)
        }
      })
      site.alarmLevels=levels
      wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "update", 
        sessionId: globs.userData.session.sessionId, body: {
          siteId:site.siteId,
          alarmLevels:levels,
      }})
//       cl(levels)
    })
        }
  
  setAlarmLevel=(vals)=>{
//     cl(vals)
    let level=+vals.selAlarmLevel
    let st=this.state
    var alarmId
    if(vals.selAlarm==-1){
      alarmId=this[st.selAlarmSect][0]
      this[st.selAlarmSect].forEach(al=>{
        this.levels[this.state.selSite][al]=level
      })
    }else{
      this.levels[this.state.selSite][vals.selAlarm]=level
    }
//     return this.levels[alarmId]
  }
  
  updateAlarms=(site,zone,tank,sets)=>{
    if(tank!=-1){
      let pos=tank.lastIndexOf("-")
      zone=tank.substr(0,pos)
      tank=tank.substr(pos+1)
      cl(zone,tank)
    }
//     cl(site,zone,tank,sets)
    wsTrans("usa", {cmd: "cRest", uri: "/s/alarmNotifications", method: "update", 
      sessionId: globs.userData.session.sessionId, body: {
        siteId:site,
        zoneId:zone,
        tankIndex:tank,
        sets:sets,
      }})
// { "_id" : ObjectId("60e78957fc8a9a0bc731525c"), "alarm" : "ITHi", "userId" : "ueEIdIa5zedw4I_0", "accoun
// tId" : "a036uzDCxohZ7ovD", "siteId" : "0sna8jVYIh0xw6oF", "zoneIndex" : -1, "tankIndex" : -1 }
    
  }
  
  scanAlarmChanges=(adminAdds,alarms,site,zone,tank)=>{
/* this scans one set of alarms and users
if there's any change, then it returns the list of alarms to *set**/
//     cl(alarms,site,zone,tank)
//     setAlarms={site:site,zone:zone,tank:tank,sets=[]}
    let sets=[]
    let modified=false
      Object.keys(alarms).forEach(alarmId=>{
        let users=alarms[alarmId]
        Object.keys(users).forEach(userId=>{
          let u=users[userId]
          if(u.cur){
            sets.push({alarmId:alarmId,userId:userId,flags:u.cur})
          }
          if(u.cur!=u.orig){
            modified=true
            this.saveToAdminLog(adminAdds, {flags:u.orig}, {name:u.name,flags:u.cur}, 
              site||-1,zone||-1,tank||-1, alarmId)
          }
        })
      })
    if(modified){
      this.updateAlarms(site||-1,zone||-1,tank||-1,sets)
    }
  }

  saveToAdminLog(adds, o, n, site, zone, tank, alarm) {
    let level = "account"
    if (site != -1) {
      level = "site"
    }
    if (zone != -1) {
      level = "zone"
    }
    if(tank!=-1){
      let pos=tank.lastIndexOf("-")
      zone=tank.substr(0,pos)
      tank=tank.substr(pos+1)
      cl(zone,tank)
      level = "tank"
    }
    let addObj={
      userId:globs.userData.session.userId,
      name: n.name,
      alarm: alarm,
      time:Math.floor(getTime())
    }
    if(o.flags!=n.flags){
      let levelName
      switch (level) {
        case "account":
          levelName = globs.accountInfo.info.name
          break
        case "site":
          levelName = globs.sitesInfo.info[getSiteIndex(site)].name
          break
        case "zone":
          levelName = globs.zonesInfo.info[getZoneIndex(zone)].zoneName
          break
        case "tank":
          levelName = `Tank ${tank}`
          break
      }
      adds.push(
        Object.assign({},addObj,
        {
        action:"manageAlarms",
        oldVal:o.flags,
        newVal:n.flags,
        level: level,
        levelName: levelName,
      }))
    }
  }
  
  saveAlarms=(cmd)=>{
//     cl("save alarms")
//     cl(this.alarms.tanks)
  if(this.state.loaded){
    let adminAdds = []
    this.scanAlarmChanges(adminAdds,this.alarms.account)
    Object.keys(this.alarms.sites).forEach(siteId=>{
      this.scanAlarmChanges(adminAdds, this.alarms.sites[siteId],siteId)
    })
    Object.keys(this.alarms.zones).forEach(zoneId=>{
      this.scanAlarmChanges(adminAdds,this.alarms.zones[zoneId],-1,zoneId)
    })
    Object.keys(this.alarms.tanks).forEach(tankId=>{
//       cl(tankId)
      this.scanAlarmChanges(adminAdds,this.alarms.tanks[tankId],-1,-1,tankId)
    })
//     cl(adminAdds)
    this.updateAlarmLevels(adminAdds)
    addToAdminLog(adminAdds)
    globs.events.publish("saveOK",true)
  }
  }
  
  setBreadCrumbs=()=>{
    // cl(this.props.parms)
    if(this.props.parms && !this.props.parms.tab){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Alarms", url:`/usa/c18/admin/manageAlarms2`},
            ]},
        },
      )
    }
    else {
      let siteName=getSiteName(this.props.parms.site)
      let zoneName=getZoneName(this.props.parms.zone)
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
              {t:"Sensor", url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/sensor/${this.props.parms.sensor}`},
            ]},
        },
      )
    }
  }

  // componentDidMount=()=>{
  //   this.query = window.location.href
  //   if(this.query) {
  //     this.queryParseValue = this.query.split("/")
  //     if(this.queryParseValue[10]) {
  //     this.label = this.queryParseValue[10]
  //     this.setState({mode:this.label, selSite:this.queryParseValue[6], selZone2:this.queryParseValue[8]})
  //     }
      
  //   }
  // }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
// { "_id" : ObjectId("60e78957fc8a9a0bc731525c"), "alarm" : "ITHi", "userId" : "ueEIdIa5zedw4I_0", "acc
// ountId" : "a036uzDCxohZ7ovD", "siteId" : "0sna8jVYIh0xw6oF", "zoneIndex" : -1, "tankIndex" : -1 }

//   setTanksOverrides=(zo,i,vals,ovr)=>{
// //     cl([zo.zoneId,i])
//   }

  setZonesOverrides=(zo,vals,ovr)=>{// zo is the zoneId
    for(let i=0;i<8;i++){
      let users=this.alarms.tanks[`${zo}-${i}`]
//       cl(`${zo}-${i}`)
//       cl(users)
      
//       cl(users)
      vals.user.forEach(us=>{
//         cl(us)
      if(users[us.aId]){users[us.aId][us.uId].ovr=ovr}
        
//         cl(users[us.aId][us.uId])
      })
//       this.setTanksOverrides(zo,i,vals,ovr)
      
    }
  }
  
  setSitesOverrides=(si,vals,ovr)=>{// si is the siteId
//     cl(users)
    globs.zonesInfo.info.filter(zo=>{return zo.siteId==si}).forEach(zo=>{
      let users=this.alarms.zones[zo.zoneId]
      vals.user.forEach(us=>{
        users[us.aId][us.uId].ovr=ovr
//         cl(users[us.aId][us.uId])
      })
        this.setZonesOverrides(zo.zoneId,vals,ovr)})
  }
  
  setAccountOverrides=(vals,ovr)=>{
    globs.sitesInfo.info.forEach(si=>{
      let users=this.alarms.sites[si.siteId]
      vals.user.forEach(us=>{
        users[us.aId][us.uId].ovr=ovr
//         cl(users[us.aId][us.uId])
      })
        this.setSitesOverrides(si.siteId,vals,ovr)})
  }
  
  setOverrides=(vals)=>{
    let ovr=(vals.v)?1:0
    switch(vals.d.scope){
      case "account":
        this.setAccountOverrides(vals,ovr)
        break
      case "site":
        this.setSitesOverrides(vals.d.site,vals,ovr)
        break
      case "zone":
//         cl(vals)
//         cl(vals.d.zone)
        this.setZonesOverrides(vals.d.zone,vals,ovr)
        break
//       case "tank":
//         this.setZonesOverrides(vals.d.tank,vals,ovr)
//         break
    }
//     cl(vals)
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    let st={}
    Object.assign(st,this.state,vals)
    switch(type){
      case "site":
      case "zone":
        this.setInitSelects(type,st)
      case "tank":
      case "alarm":
//         cl(vals)
        if(vals.selAlarmSect){vals.selAlarm=-1}
        this.setState(vals)
        break
      case "userAlarm":
      case "user":
//         cl(vals)
        this.setState(vals)
        break
      case "alarmLevel":
//         cl(vals)
        this.setAlarmLevel(vals)
        globs.events.publish("savePageEnable",true)
        this.setState(vals)
        break
      case "notify":
//         cl(type,vals)
        globs.events.publish("savePageEnable",true)
        let u0=vals.user[0]
        vals.user.forEach(u=>{
//           let u=vals.user
          if(vals.v){
            u.cur|=constant.ALARM_NOTIFY
          }else{
            u.cur&=~constant.ALARM_NOTIFY
          }
  //         cl(u)
        })
        this.setOverrides(vals)
        this.setState({user:u0,cur:u0.cur})// really just to trigger a re-render
        break
      case "tabs":
        cl(this.addedSensors)
        cl(this.levels)
        st={mode:vals.tab}
//         var zoneId
//         if(vals.tab=="levels"){
//           zoneId=-1
//         }else{
//           zoneId=globs.zonesInfo.info.filter(z=>{return z.siteId==this.state.siteSel})[0].zoneId
//         }
//         Object.assign(st,{selZone:zoneId})
//         cl(st)
        this.setState(st)
        break
      default:
        break
    }
  }
  
//   makeJustUserList=()=>{
//     let users={}
//     globs.usersInfo.info.forEach(u=>{
//       users[u.userId]={
//         name:u.name,
//         email:u.email,
//         orig:0,
//         cur:0,
//       }
//     })
//     return users
//   }
  
  makeUserList=(level,zone,tank)=>{
// this needs to have a user list for *each* alarm!
    let alarmIds=this.alarmIds.slice(0)
    if(level=="zone"){
//       cl(zone)
//       cl(this.alarmIds)
//       let ob=((this.addedSensors[zone.siteId]||{})[zone.siteZoneIndex]||[])
//       cl(this.addedSensors)
//       cl(zone)
      let ob=this.addedSensors[zone.zoneId]||[]
//       cl(ob)
//       cl(ob)
      ob.forEach(c=>{
//         cl(c)
        alarmIds.push(c.id)
      })
//       cl(zone)
    }
    let alarms={}
//     cl(this.alarmIds)
    alarmIds.forEach(a=>{
      let users={}
      globs.usersInfo.info.forEach(u=>{
        users[u.userId]={
          name:u.name,
          email:u.email,
          uId:u.userId,
          aId:a,
          ovr:0,
          orig:0,
          cur:0,
        }
      })
      alarms[a]=users
    })
//     cl(alarms)
    return alarms
  }
  
  makeBlankAlarms=()=>{
/* for account, all sites, all zones, all tanks, scope: account, site, zone, tank*/
    let alarms={sites:{},zones:{},tanks:{}}
    alarms.account=this.makeUserList("account")
    globs.sitesInfo.info.forEach( s=>{ alarms.sites[s.siteId]=this.makeUserList("site") })
    this.gotZones={}
    globs.zonesInfo.info.forEach(
      z=>{ alarms.zones[z.zoneId]=this.makeUserList("zone",z)
        this.gotZones[z.zoneId]=1
        for(let i=0;i<8;i++){
          let tankId=`${z.zoneId}-${i}`
          alarms.tanks[tankId]=this.makeUserList("tank",z,tankId)
        }
      })
//     cl(this.gotZones)
//     cl(alarms)
    this.alarms=alarms
  }
  
  loadAlarmNotificationInfo=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/alarmNotifications", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})// for entire account
    let notes=res.data
//     cl(notes)
    var ua
    notes.forEach(n=>{
      cl(n)
//       n.zoneId=n.zoneId||n.zoneIndex
//       n.alarmId=n.alarmId||n.alarm
//       n.flags=n.flags||constant.ALARM_NOTIFY
//       cl(n)
      if(this.gotZones[n.zoneId]||(n.zoneId==-1)){
//         cl(n.siteId,n.zoneId,n.tankIndex)
        if((n.siteId==-1)&&(n.zoneId==-1)){// account
//           cl("set account")
          ua=((this.alarms.account[n.alarmId]||{})[n.userId])||{}
          if (ua) {
            ua.orig=n.flags
            ua.cur=n.flags
          }
        }else if(n.zoneId==-1){// site
  //         cl(this.alarms.sites)
  //         cl(n.siteId)
  //         cl(n.alarmId)
  //         cl(n.userId)
  //         cl(this.alarms.sites[n.siteId][n.alarmId])
          ua=((this.alarms.sites[n.siteId]||{})[n.alarmId]||{})[n.userId]
          if(ua){
            ua.orig=n.flags
            ua.cur=n.flags
          }
          
        }else if(n.tankIndex==-1){// zone// need to check if zone exists
          // cl(n)
//           cl(this.alarms.zones)
//           cl(n.zoneId)
//           cl(this.alarms.zones[n.zoneId])
          ua=((this.alarms.zones[n.zoneId]||{})[n.alarmId]||{})[n.userId]
          if(ua){
            ua.orig=n.flags
            ua.cur=n.flags
          }
        }else{// tank
          ua=(this.alarms.tanks[`${n.zoneId}-${n.tankIndex}`]||{})[n.alarmId][n.userId]
          if (ua) {
            ua.orig=n.flags
            ua.cur=n.flags
          }
          
        }
      }else{// tank
        cl(this.alarms.tanks)
        ua=((this.alarms.tanks[`${n.zoneId}-${n.tankIndex}`]||{})[n.alarmId]||{})[n.userId]
        if (ua) {
          ua.orig=n.flags
          ua.cur=n.flags
        }
//         cl(n.siteId,n.zoneId,n.tankIndex)
//         cl(ua)
        
      }
    })
  }
  
  initAlarmLevels=()=>{
    var levels
    this.levels={}
    let addSect=(arr,level)=>{
      arr.forEach(a=>{
        levels[a]=level
      })
    }
    globs.sitesInfo.info.forEach(site=>{
      if(site.alarmLevels){
        this.levels[site.siteId]=Object.assign({},site.alarmLevels)
      }else{
        levels={}
        addSect(this.mixTanks,5)
        addSect(this.relays,1)
        addSect(this.inTemp,1)
        addSect(this.timeout,1)
        addSect(this.aux,4)
        addSect(this.sensors,2)
        this.levels[site.siteId]=Object.assign({},levels)
      }
    })
//       cl(this.levels)
  }
  
  makeTabs=()=>{
//     cl(sensors)
    this.alarmSects=[
      {v:"relays",t:"1800 Relays"},
      {v:"inTemp",t:"1800 Inside Temperature"},
      {v:"timeout",t:"Timeout"},
      {v:"aux",t:"1800 Aux"},
      {v:"sensors",t:"Sensors"},
//       {v:"addedSensors",t:"Added Sensors"},
      {v:"mixTanks",t:"Mixing Tanks"}
    ]
    
    this.mixTanks=["E1Sn", "E1Sr", "E1Ca", "E2Sn", "E2Sr", "E2Ca", "E3Sn", "E3Sr", "E3Ca", "ECLo", "ECHi", "ECDe", "P1Sn", "P1Sr", "P1Ca", "P2Sn", "P2Sr", "P2Ca", "P3Sn", "P3Sr", "P3Ca", "PhLo", "PhHi", "PhDe", ]
    this.relays=["reLo", "reHi", ]
    this.inTemp=["intLo", "intHi", "intSn", ]
    this.aux=["auxAl", ]
    this.timeout=["TmOt"]
    this.sensorTabs={}
    this.sensorTabs.sensors=[{v:-1,t:"All Sensors"}]
//     this.addedSensors=loadAddedSensors() 
    this.sensors=[]
//     this.sectionNames={
//       relays:"Relays",
//       inTemp:"Inside Temperature",
//       aux:"Aux Alarm",
//       timeout:"Timeout",
//       sensors:"Sensors",
//       mixTanks:"M",
//     }
    
    Object.keys(sensors).forEach(id=>{
//       this.alarmIds.push(`${id}Sa`)
      this.sensors.push(`${id}Sa`)
      this.sensorTabs.sensors.push({
        v:`${id}Sa`,t:sensors[id].title
      })
    })
//     this.sensorTabs.mixTanks=[{v:-1,t:"All Mix Tanks"}]
//     this.mixTankIds=[]
//     for(let i=0;i<8;i++){
//       this.mixTankIds.push(`mixT${i}`)
//       this.sensorTabs.mixTanks.push({
//         v:`mixT${i}`,t:`Mix Tank ${i+1}`
//       })
//     }
    this.sectNames=this.alarmSects.map(as=>{return as.v}).concat(this.mixTankIds)
//     cl(this.sectNames)
    this.alarmIds=this.mixTanks.concat(this.relays,this.inTemp,this.timeout,
      this.aux,this.sensors,this.mixTankIds
    )
//     cl(this.sensorTabs)
//     cl(this.alarmIds)
    this.tabs=[
      {v:"account",t:"Account"},
      {v:"site",t:"Site"},
      {v:"zone",t:"Zone"},
      {v:"tank",t:"Tank"},
      {v:"levels",t:"Levels"},
    ]
    this.tankInds=[0,1,2,3,4,5,6,7]
//     cl(globs.sitesInfo.info)
//     globs.sitesInfo.info.forEach(site=>{this.initAlarmLevels(site)})
    this.initAlarmLevels()
  }
  
  loadAddedSensors=async()=>{
    let typeId=getParamId("config_added_sensors","type")
//     cl(globs.zonesInfo)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/accountSite", 
      method: "retrieve", sessionId: globs.userData.session.sessionId, 
      body: {
        i:typeId,
    }})
//     cl(res)
    let aSensors={}
    res.data.forEach(d=>{
      if(d.c!==null){
        let zo=(globs.zonesInfo.sz2z[d.s]||{})[d.z]||{}
        if(!aSensors[zo.zoneId]){aSensors[zo?.zoneId]=[]}
        aSensors[zo.zoneId].push({c:d.c,id:`as${d.c}Sa`,type:d.d})
      }
//       if(d.c!==null){
//         if(!aSensors[d.s]){aSensors[d.s]={}}
//         if(!aSensors[d.s][d.z]){aSensors[d.s][d.z]=[]}
//         aSensors[d.s][d.z].push({id:`as${d.c}`,type:d.d})
//       }
    })
    cl(aSensors)
    this.addedSensors=aSensors
  }
  
  loadInfo=async()=>{
    this.makeTabs()
    await loadUsersInfo()
    await loadSitesInfo()
    await loadZonesInfo()
    await this.loadAddedSensors()
    this.makeBlankAlarms()
    await this.loadAlarmNotificationInfo()
    
//     cl(this.alarmIds)
//     cl(this.alarms)
    cl(this.props.parms)
    if (this.props.parms.tab) {
      this.setInitSelects(this.props.parms.tab,this.state) 
    }
    else {
      this.setInitSelects("account",this.state)
    }
  }
  
  setInitSelects=(type,st)=>{
//     cl(type)
    let si=globs.sitesInfo.info
    let zi=globs.zonesInfo.info
//     cl(zi)
    let siteId=this.props.parms.site||st.selSite
    let zoneId=this.props.parms.zone||st.selZone
    if(type=="account"){ 
      siteId=this.props.parms.site||si[0].siteId
    }
    if((type=="site")||(type=="account")){
      for(let i=0;i<zi.length;i++){
        let z=zi[i]
//         cl(z)
//         cl(siteId)
//         cl(z.siteId)
        if(z.siteId==siteId){ 
//           cl("going")
          zoneId=this.props.parms.site||z.zoneId; break; }
      }
//       zi.forEach(z=>{
//         if(z.siteId==siteId){ zoneId=z.zoneId }
//       })
    }
//     if(this.state.mode=="levels"){zoneId=-1}
//     cl(this.state.mode)
    let alarmSect=this.alarmSects[0].v
    let tankId=`${zoneId}-0`
    let alarmId=-1;//this.alarmIds[0]
//     cl(this.alarmIds)
//     cl(alarmId)
    this.setState({selSite:siteId,selZone:zoneId,selZone2:zoneId,
      selTank:tankId,selAlarm:alarmId,
      selAlarmSect:alarmSect,selUser:globs.usersInfo.info[0].userId,mode:type,loaded:true})
//     cl([siteId,zoneId,tankId,alarmId,alarmSect])
  }
  
  makeUserAlarmArray=(scope,id,alarm)=>{
/* now, with groups, like "relays" and "sensors", each entry should be
an array of user flags, not just 1
alarm may be a single alarm, or a group
*/
    let st=this.state
//     cl(alarm)
//     let sect=st.selAlarmSect
    var scopeList
    if(id){
      scopeList=this.alarms[scope][id]
    }else{
      scopeList=this.alarms[scope]
    }
    let blankO
    let retAlarms={}
    if(this.sectNames.includes(alarm)){// a section
//       let alarmIds=(alarm.substring(0,4)=="mixT")?this.mixTanks:this[alarm]
      let alarmIds=this[alarm]
//       cl(alarmIds)
//       cl(scopeList)
      alarmIds.forEach(al=>{// for each alarm in the list
        Object.keys(scopeList[al]).forEach(userId=>{// for each user
          if(!retAlarms[userId]){retAlarms[userId]=[]}
          retAlarms[userId].push(scopeList[al][userId])
        })
      })
      return retAlarms
    }else{// an actual alarm
//       cl(alarm)
//       cl(scopeList)
//       cl(scopeList[alarm])
      Object.keys(scopeList[alarm]).forEach(userId=>{
//         if(!retAlarms[userId]){retAlarms[userId]=[]}
        retAlarms[userId]=[scopeList[alarm][userId]]// array of 1
      })
      
    }
    return retAlarms// scopeList["E1Sn"]
  }
  
  showUserList=(scope,site,zone,tank)=>{
    let desc={scope:scope,site:site,zone:zone,tank:tank}
    let st=this.state
    let alarm=(st.selAlarm==-1)?st.selAlarmSect:st.selAlarm
//     cl(alarm)
//     let alarm=this.state.selAlarmSect
//     cl(this.state.selAlarm)
//     cl(this.alarms.sites)
//     cl(site,zone,tank,alarm)
//     cl(this.alarms)
//     cl(this.alarms.account[alarm])
    var users
    if(scope=="account"){
      users=this.makeUserAlarmArray("account",null,alarm)
//       users=this.alarms.account[alarm]
    }else if(scope=="site"){
      cl(this.alarms.sites[site])
//       cl(this.alarms.sites[site][alarm])
      users=this.makeUserAlarmArray("sites",site,alarm)
//       users=this.alarms.sites[site][alarm]
    }else if(scope=="zone"){
      if(zone){
        cl(zone)
        cl(this.alarms.zones[zone])
        cl(this.alarms.zones[zone][alarm])
        users=this.makeUserAlarmArray("zones",zone,alarm)
      }else{
        users=[]
      }
//       users=this.alarms.zones[zone][alarm]
    }else{
//       cl(`${zone}-${tank}`)
//       cl(this.alarms.tanks)
//       cl(this.alarms.tanks[`${tank}`])//${zone}-
      if(zone){
        users=this.makeUserAlarmArray("tanks",tank,alarm)
      }else{
        users=[]
      }
//       users=this.alarms.tanks[tank][alarm]//${zone}-
    }
//       cl(users)
    return(
      <div key={5} className="checkbox-list">
        <div key={6} className="title">Select Recipients</div>
        {
          Object.keys(users).map((k,i)=>{
            let u=users[k]
//             cl(u)
            let partial=0
            u.forEach(al=>{partial|=(al.cur)?2:1})
            let style=(partial==3)?{opacity:0.4}:{}
            return(
              <div key={i}>
                <input key={i} type="checkbox" id="site-alarm-user-1" checked={u[0].cur&constant.ALARM_NOTIFY} 
                  onChange={e=>this.onChange("notify",{v:e.currentTarget.checked,user:u,d:desc})} 
                  style={style}/>
                <label htmlFor="site-alarm-user-1">{`${u[0].name} (${u[0].email})`}</label>
                <br />
              </div>
            )
          })
        }
        <br />
      </div>
    )
  }
  
  showCustomSelect=(parms)=>{
    return(
      <div className="custom-select">
        <label htmlFor="">{parms.title}</label>
        <select id="" 
          value={parms.value}
          onChange={parms.onChange}>
        {
          parms.options.map((o,i)=>{
          return(
              <option key={i} value={o.v}>{o.t}</option>
          )
          })
        }
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
  
  showSelectSite=()=>{
    return this.showCustomSelect({
      title:"Select Site",
      value:this.state.selSite,
      onChange:e=>{this.onChange("site",{selSite:e.currentTarget.value})},
      options:globs.sitesInfo.info.map((s,i)=>{return {v:s.siteId,t:s.name}})
    })
  }
  
  showSelectZone2=()=>{
    let options=[{v:-1,t:"All Zones"}]
    options=options.concat(
      globs.zonesInfo.info.filter((z,i)=>{return z.siteId==this.state.selSite})
        .map((z,i)=>{return {v:z.zoneId,t:z.zoneName}})
    )
    return this.showCustomSelect({
      title:"Select Zone",
      value:this.state.selZone2,
      onChange:e=>{this.onChange("zone",{selZone2:e.currentTarget.value})},
      options:options,
    })
  }
    
  showSelectZone=()=>{
    let options=[]
    options=options.concat(
      globs.zonesInfo.info.filter((z,i)=>{return z.siteId==this.state.selSite})
        .map((z,i)=>{return {v:z.zoneId,t:z.zoneName}})
    )
    return this.showCustomSelect({
      title:"Select Zone",
      value:this.state.selZone,
      onChange:e=>{this.onChange("zone",{selZone:e.currentTarget.value})},
      options:options,
    })
  }
  
  showSelectTank=()=>{
    return(
      <div className="custom-select">
        <label htmlFor="">Select Tank</label>
        <select id="" 
          value={this.state.selTank}
          onChange={e=>{this.onChange("tank",{selTank:e.currentTarget.value})}}>
        {
          this.tankInds.map((t,i)=>{
            return(
              <option key={i} value={`${this.state.selZone}-${t}`}>{`Tank ${i+1}`}</option>
            )
          })
        }
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
  
  showSubSelectAlarm=(type)=>{
    let opts=this.sensorTabs[this.state.selAlarmSect]
    return(
      <div className="custom-select">
        <label htmlFor="">Select Alarm Type</label>
        <select id=""
          value={this.state.selAlarm}
          onChange={e=>{this.onChange("alarm",{selAlarm:e.currentTarget.value})}}>
        >
        {opts.map((a,i)=>{
          return(
            <option key={i} value={a.v}>{a.t}</option>
          )
        })}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
  
  showSelectAlarm=(type)=>{
//         cl(this.alarmIds)
    
    let showSubs=(["sensors"].includes(this.state.selAlarmSect))
    return(
      <>
        <div className="custom-select">
          <label htmlFor="">Select Alarm</label>
          <select id=""
            value={this.state.selAlarmSect}
            onChange={e=>{this.onChange("alarm",{selAlarmSect:e.currentTarget.value})}}>
          >
          {this.alarmSects.map((a,i)=>{
            return(
              <option key={i} value={a.v}>{a.t}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </div>
        <div className="clearfloat"></div>
        {false&&showSubs&&this.showSubSelectAlarm(type)
        }
      </>
    )
  }
  
  showSelectAlarmsOrUsers=()=>{
    let st=this.state
    let labelStyle={display:"inline-block", paddingRight:20}
    return(
      <div>
        <div className="title">Select By:</div>
        <input type="radio" id="users" name="userAlarm" value="user"
          checked={st.userAlarm=="user"}
          onChange={e=>this.onChange("userAlarm",{userAlarm:e.currentTarget.value})}
        />
        <label htmlFor="users" style={labelStyle}>Users</label>
        <input type="radio" id="alarms" name="userAlarm" value="alarm"
          checked={st.userAlarm=="alarm"}
          onChange={e=>this.onChange("userAlarm",{userAlarm:e.currentTarget.value})}
        />
        <label htmlFor="alarms" style={labelStyle}>Alarms</label>
      </div>
    )
  }
  
  showSelectByAlarms=(scope,site,zone,tank)=>{
    return(
      <>
        {this.showSelectAlarm()}
        <br />
        {this.showUserList(scope,site,zone,tank)}
      </>
    )
  }
  
  makeAlarmUserArray=(scope,id,userId)=>{
//     cl(scope,id,userId)
    let st=this.state
    var scopeList
    if(id){
      scopeList=this.alarms[scope][id]
    }else{
      scopeList=this.alarms[scope]
    }
//     cl(scopeList)
//     cl(scopeList)// for each alarm, we have a list of users
    let retAlarms=[]
//     cl(this.addedSensors)
    this.alarmSects.forEach(as=>{
      let sectAlarms=[]
      var sensorArray
      sensorArray=this[as.v].slice(0)
      if((scope=="zones")&&(as.v=="sensors")){
//         cl(this.addedSensors[id])
        let as2=this.addedSensors[id]||[]
        as2.forEach(as=>{
          sensorArray.push(as.id)
        })
//         cl(sensorArray)
        
      }
//       cl(as.v)
//       cl(scope)
//       if(as.v=="addedSensors"){
//         sensorArray=[]
//       }else{
//         sensorArray=this[as.v]
//       }
      sensorArray.forEach(al=>{
        sectAlarms.push(scopeList[al][userId])
      })
//       cl(sectAlarms)
      
      retAlarms.push({
        type:"sect",
        name:`All ${as.t}`,
        al:as.v,
        vals:sectAlarms
      })
      sensorArray.forEach(al=>{
//         cl(al)
//         cl(alarmNames)
//         cl(sensors)
        var name;
        if(al.substring(0,2)=="as"){
//           cl(this.addedSensors[id])
          let asObj=this.addedSensors[id].filter(s=>{return s.id==al})||[]
          cl(asObj)
          let asType=asObj[0].type
//           cl(asType)
//           cl("........................................................")
//           let nameTab={temp:"Temperature", hum:"Humiditiy"}
          name=`AS ${asObj[0].c} ${addedSensorTypeNames[asType]} Sensor`
        }else{
          name=(al.slice(-2)=="Sa")?sensors[al.substring(0,3)].title:alarmNames[al].name
        }
//         cl(name)
        retAlarms.push({
          type:"alarm",
          name:name,
          al:al,
          vals:[scopeList[al][userId]]
        })
      })
      
    })
//     cl(retAlarms)
    return retAlarms
  }
  
  showAllAlarms=(scope,site,zone,tank)=>{
//     cl(scope,site,zone,tank)
    let desc={scope:scope,site:site,zone:zone,tank:tank}
    var alarms
    let userId=this.state.selUser
    if(scope=="account"){
      alarms=this.makeAlarmUserArray("account",null,userId)
    }else if(scope=="site"){
      alarms=this.makeAlarmUserArray("sites",site,userId)
    }else if(scope=="zone"){
      if(zone){
        alarms=this.makeAlarmUserArray("zones",zone,userId)
//         cl(alarms)
      }else{
        alarms=[]
      }
    }else{
      if(zone){
        alarms=this.makeAlarmUserArray("tanks",tank,userId)
      }else{
        alarms=[]
      }
    }
    return(
      <div key={5} className="checkbox-list">
        <div key={6} className="title">Select Alarms</div>
        {
          Object.keys(alarms).map((k,i)=>{
//             cl(k)
//             cl(alarms[k])
            let margin=(alarms[k].type=="sect")?0:20
            let alarm=alarms[k]
//             cl(alarm,k)
            var partial,style={},disable=false,title=""
            if(alarm.vals[0]?.ovr){
              partial=2
              disable=true
              title="Alarm enabled at a higher level"
            }else{
              partial=0
              alarm.vals.forEach(al=>{partial|=(al.cur&constant.ALARM_NOTIFY)?2:1})//
              style=(partial==3)?{opacity:0.4}:{}
            }
            return(
              <div key={i} style={{marginLeft:margin}}>
                <input key={i} type="checkbox" id="site-alarm-user-1" checked={partial&2} 
                  onChange={e=>this.onChange("notify",{v:e.currentTarget.checked,user:alarm.vals,d:desc})} 
                  style={style}
                  disabled={disable}
                  title={title}/>
                <label htmlFor="site-alarm-user-1">{`${alarm.name}`}</label>
                <br />
              </div>
            )
          })
        }
        <br />
      </div>
    )
    return(
      <div>alarms</div>
    )
  }
  
  showSelectUsers=()=>{
    return this.showCustomSelect({
      title:"Select User",
      value:this.state.selUser,
      onChange:e=>{this.onChange("user",{selUser:e.currentTarget.value})},
      options:globs.usersInfo.info.map((u,i)=>{return {v:u.userId,
        t:`${u.name} (${u.email})`
      }})
    })
  }
  
  showSelectByUsers=(scope,site,zone,tank)=>{
    return(
      <>
      {this.showSelectUsers()}
      {this.showAllAlarms(scope,site,zone,tank)}
      </>
    )
  }
  
  showAlarmsOrUsers=(scope,site,zone,tank)=>{
    let st=this.state
    return(
      <>
        {this.showSelectAlarmsOrUsers()}
        <br />
        {(st.userAlarm=="alarm")&&this.showSelectByAlarms(scope,site,zone,tank)}
        {(st.userAlarm=="user")&&this.showSelectByUsers(scope,site,zone,tank)}
      </>
    )
  }
  
  showAccountAlarms=(site,zone,tank)=>{
//     cl(this.state)
//     if(this.state.alarmSel!=-1)
    return (
      <div className="tab-panels">
        <div className="tab-panel selected" role="tabpanel">
          <div className="left-tab-column">
            {this.showAlarmsOrUsers('account',site,zone,tank)}
          </div>
          <div className="clearfloat"></div>
        </div>
      </div>
    )
  }
  
  showSiteAlarms=(site,zone,tank)=>{
    return (
      <div className="tab-panels">
        <div className="tab-panel selected" role="tabpanel">
          <div className="left-tab-column">
          {this.showSelectSite()}
            <br />
            {this.showAlarmsOrUsers('site',site,zone,tank)}
          </div>
          <div className="clearfloat"></div>
        </div>
      </div>
    )
  }
  
  showZoneAlarms=(site,zone,tank)=>{
//     cl([site,zone,tank])
    return (
      <div className="tab-panels">
        <div className="tab-panel selected" role="tabpanel">
          <div className="left-tab-column">
          {this.showSelectSite()}
            <br />
            {this.showSelectZone()}
            <br />
            {this.showAlarmsOrUsers('zone',site,zone,tank)}
          </div>
          <div className="clearfloat"></div>
        </div>
      </div>
    )
  }
  
  showTankAlarms=(site,zone,tank)=>{
    return (
      <div className="tab-panels">
        <div className="tab-panel selected" role="tabpanel">
          <div className="left-tab-column">
          {this.showSelectSite()}
            <br />
            {this.showSelectZone()}
            <br />
            {this.showSelectTank()}
            <br />
            {this.showAlarmsOrUsers('tank',site,zone,tank)}
          </div>
          <div className="clearfloat"></div>
        </div>
      </div>
    )
  }
  
  getAlarmLevel=(alarmId)=>{
    let st=this.state
//     cl(alarmId)
//     var alarmId
//     if(st.selAlarm==-1){
//       alarmId=this[st.selAlarmSect][0]
//     }else{
//       alarmId=st.selAlarm
//     }
    return this.levels[st.selSite][alarmId]
  }
  
  addAddedSensors=()=>{
    
  }
  
  getAlarmName=(sensors,sect,alarmId)=>{
//     cl(sensors)
//     cl(alarmId)
    if(sect=="sensors"){
      if(alarmId.substring(3,5)=="as"){// added sensor
        return sensors[alarmId].title
      }else{
        return sensors[alarmId.substring(0,3)].title
      }
    }else{
      return alarmNames[alarmId].name
    }
  }
  
//   getAlarmLevel=(alarmId)=>{
//     return 2
//   }
  
  showSelect=(alarmId,alarmName,alarmLevel,levels)=>{
//     cl([alarmId,alarmName,alarmLevel,levels])
    let levels2=levels.slice(0)
    if(alarmId==-1){levels2.unshift("")}
    return(
      <div className="custom-select">
        <label htmlFor="">{alarmName} Alarm Level</label>
        <select id=""
          value={alarmLevel}
          onChange={e=>{this.onChange("alarmLevel",{selAlarm: alarmId,
            selAlarmLevel:e.currentTarget.value})}}>
        >
        {levels2.map((l,i)=>{
          return(
            <option key={i} value={l}>{l}</option>
          )
        })}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
  
  addCustomSensorNames=(sensors2)=>{
    cl(sensors2)
    
  }
  
  showLevelSelect2=()=>{
    let sect=this.state.selAlarmSect
    let sectName=this.alarmSects.filter(al=>{return al.v==sect})[0].t
//     cl(sectName)
//     cl(sect)
//     cl(this[sect])
    cl(sensors)
    let alarmIds=this[sect].slice(0)
    let sensors2=Object.assign({},sensors)// copy array to add addedSensors
//     cl(sect)
    if(sect=="sensors"){
//       cl(this.state.selZone2)
      let adS=this.addedSensors[this.state.selZone2]
//       let zo=globs.zonesInfo.info.filter(z=>{return z.zoneId==this.state.selZone2})
      if(adS){
        let zInd=globs.zonesInfo.info[getZoneIndex(this.state.selZone2)].siteZoneIndex
//         cl(zInd)
//         cl(adS)
        adS.forEach(s=>{
          let aId=`z${az(zInd,2)}${s.id}`
          alarmIds.push(aId)
          sensors2[aId]={title:`AS ${s.c} ${addedSensorTypeNames[s.type]}`}
        })
//         cl(alarmIds)
      }
      this.addCustomSensorNames(sensors2)
    }
    let levels=[1,2,3,4,5,6,7]
//     cl(this.sensorTabs)
//     let opts=this.sensorTabs[sect]
//     cl(opts)
//     let levels=[1,2]
    return(
      <div>
        {/* {(alarmIds.length>1)&&this.showSelect(-1,`Set All ${sectName}`,"",levels)} ANAS Commented against C20C22-1342 */}
        <div className="clearfloat"></div>
        {alarmIds.map((al,i)=>{
          let alarmName=this.getAlarmName(sensors2,sect,al)
          let alarmLevel=this.getAlarmLevel(al)
          
          return(
            <div key={i}>
            {this.showSelect(al,alarmName,alarmLevel,levels)}
            <div className="clearfloat"></div>
            </div>
          )
        })}
      </div>
    )
  }
  
  showLevelSelect=()=>{
    let type=""
    let showSubs=(["sensors"].includes(this.state.selAlarmSect))
    let levels=[1,2,3,4,5,6,7]
    let levelDesc=[
      "notification sent, red at top, alarm log page, red site, red zone, sidebar (important, notified, and texted)",
      "notification sent, red at top, alarm log page, red site, red zone, sidebar  (important, notified, and texted)",
      "notification NOT sent, red at top, alarm log page, red site, red zone, sidebar (important, notified, but not enough to be texted)",
      "notification not sent, alarm log page, sidebar (important, but not enough to be texted or notified)",
      "notification not sent, alarm log page, sidebar (important, but not enough to be texted or notified)",
      "notification not sent, sidebar (not important, but can be seen)",
      "notification not sent, not in sidebar (not important, not visible)",
    ]
    let alarmLevel=this.getAlarmLevel()
    return(
      <>
        <div className="custom-select">
          <label htmlFor="">Alarm Level</label>
          <select id=""
            value={alarmLevel}
            onChange={e=>{this.onChange("alarmLevel",{selAlarmLevel:e.currentTarget.value})}}>
          >
          {levels.map((l,i)=>{
            return(
              <option key={i} value={l}>{l}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </div>
        <p>{levelDesc[alarmLevel-1]}</p>
        <div className="clearfloat"></div>
      </>
    )
  }
  
  showLevels=(site,zone,tank)=>{
    return(
      <div className="tab-panels">
        <div className="tab-panel selected" role="tabpanel">
          <div className="left-tab-column">
            {this.showSelectSite()}
            <br />
            {this.showSelectZone2()}
            <br />
            {this.showSelectAlarm("zone")}
            <br />
            {this.showLevelSelect2()}
          </div>
          <div className="clearfloat"></div>
        </div>
      </div>
    )
    
  }
  
  showMode=()=>{
    let st=this.state
//     cl(st)
    let [site,zone,tank]=[st.selSite,st.selZone,st.selTank]
    switch(this.state.mode){
      case "account":
        return this.showAccountAlarms(site,zone,tank)
      case "site":
        return this.showSiteAlarms(site,zone,tank)
      case "zone":
        return this.showZoneAlarms(site,zone,tank)
      case "tank":
        return this.showTankAlarms(site,zone,tank)
      case "levels":
        return this.showLevels(site,zone,tank)
    }
  }
  
  render(){
//     cl(this.state)
    if(this.state.loaded){
    return(
      <div className="tabs">
        <C18TabsHeader00
          tabId={this.state.mode}
          tabs={this.tabs}
          onChange={o=>this.onChange("tabs",o)}
        />
        {this.showMode()}
      </div>
    )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ManageAlarms;
