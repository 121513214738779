// eslint-disable-line eqeqeq
import React from 'react';
import * as d3 from 'd3';
import {Link} from 'react-router-dom'
// import C18Anchor00 from './C18Anchor00'
import C18Select00 from './C18Select00'
import C18Select01 from './C18Select01'
import C18Button00 from './C18Button00'
import Select from 'react-select'

// import ReactHtmlParser from 'react-html-parser'
import C18SubMenuHeader00 from './C18SubMenuHeader00'
import {wsTrans,sensorIds,getUId,doGetPostBasic,makeOpts,getParamId2,logIt,
  xBoardShorts,allSense
} from '../utils/utils'
import {tableIds,dpNames,pi,pb,pInd} from '../../components/utils/paramIds'
import {cl,globs,constant,dateToDisplayDate,getPWString,getTimeI,
  saveLocalStorage,getLocalStorage,getTime,makeNumNum,show,
  copyToClipboard,az,getRandomString} from '../../components/utils/utils';
import {dbVals,} from '../../components/utils/http';
import {sendSocket,} from '../../components/utils/ws';
import {loadSiteData,} from './C18utils'
import {lc} from '../../components/utils/landruConstants'
var config = require('../../components/utils/config');
class C18TechPortal00 extends React.Component{
  constructor(props) {
    super(props);
//     let dtFormat=dateToDisplayDate(new Date(),"yyyy-mm-ddThh:mm")
    let ts=getTime()
//     cl(ts)
    this.state={
      accountSearch:"",
      userSearch:"",
      siteSearch:"",
      gatewaySearch:"",
      zoneSearch:"",
      searchType:"",
      oneSearch:"",
      searchRes:{},
      searchSel:-1,
      relUsers:[],
      relSites:[],
      relAccounts:[],
      relGateways:[],
      relZones:[],
      userSel:"",
      accountFeatures:{},
      resetPW:"",
      editUserAcctId:false,
      editGatewaySiteId:false,
      editType:"",
      editField:"",
      showSystemReport:false,
      disableCache:false,
      uploadFwResult:"Upload Firmware Image",
      debugText:"",
      debugResp:{},
      pageType:"techPortal",
      mqttLogTime:ts,//dtFormat,
//       mqttClient:getLocal,
      msgClick:0,
      contSel:"",
      endTime:dateToDisplayDate((new Date()),"yyyy-mm-ddThh:mm"),
      dbgLogClientId:getLocalStorage("dbgLogClientId")||"",
      dbgLogEntries:[],
      tags:{one:true,two:true},
      useTags:[],
      waitFlag:false,
      sortMode:1,
      liveChecked:true,
      gwDel:{
        auth_gw:true,
      },
      valColor:"#FFFFFF",
      resetTime:0,
      resetMsg:"",
      contReset:"cnreset",
      copyBtnClicked: false,
      slowTimeRange:"day",
      graphSpan:1440,
      graphTick:240,
      testText:"",
      testSel:"",
      tests:{},
      testClientId:getLocalStorage("testClientId")||"",


//       pageType:"users",
// //       selUser:"",
//       editObj:{},
//       editId:"",
//       editKey:"",
    }
//     cl(this.state.dbgLogClientId)
    this.servers={
      mongo:"mongo.c2.link4cloud.com:3105",
      wayne:"wayne.link4cloud.com:3105",
      dev:"stage.link4cloud.com:3105",
      stage:"stage.link4cloud.com:3115",
      alpha:"stage.link4cloud.com:3125",
      prodx:"http0test.link4cloud.com:3125",
      prod:"http0test.link4cloud.com:3105",
      prods:"http0test.link4cloud.com:3115",
      nomq:"nomq00.link4cloud.com:3105",
    }
//     cl(this.state.disableCache)
//     this.pageTitles={accounts:"Accounts",sites:"Sites",gateways:"Gateways",
//       zones:"Zones",users:"Users",}
//     this.pageParms={
//       users:["userSearch","users","name","email","userId","selUser"],
//       accounts:["accountSearch","accounts","name","adminEmail","accountId","selAccount"],
//       sites:["siteSearch","sites","name","","siteId","selSite"],
//       gateways:["gatewaySearch","gateways","name","","gatewayId","selGateway"],
//       zones:["zoneSearch","zones","zoneName","","zoneId","selZone"],
//     }
//     this.itemIds={accounts:"accountId",sites:"siteId",gateways:"gatewayId",zones:"zoneId",users:"userId"}
//     cl(this.state.not.here)
    this.loadInfo()
    this.openLog2Ws()
//     this.sendTestLog()
    this.setBreadCrumbs()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveData)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})

    this.subscribe_keyDownEvent=globs.events.subscribe("keyDown",
      e=>{this.onChange("searchKey",{key:e.key})})

    this.subscribe_newData=globs.events.subscribe("data",this.newData)
    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
    this.bgColor1=(this.dark)?"#202020":"#FFFFFF"
    this.bgColor2=(this.dark)?"#203030":"#FFFFFF"
    this.selColor1=(this.dark)?"#606060":"#DDDDDD"
    this.mqttInterval = setInterval(e=>{
      this.loadMqttContacts().then(cont=>{
        this.setState({mqttContacts:cont})
      })
    },5000)
    this.dbgInterval=setInterval(this.updateDbgLog,2000)
    this.controllerStateInterval=setInterval(this.getControllerState,2000)
    this.mqttLogThisTime=0
    this.mqttLogNextTime=0
//     this.mqttLogBusy=false
    this.mqttLogBusyTimer=null
    this.dbgLogRef=React.createRef()
    this.cmdTypes={
      "-1":{t1:"NAK",t2:"",t3:""},
      "0":{t1:"ACK",t2:"",t3:"",b:0},
      "1":{t1:"Write",t2:"Device",t3:"Config",b:0},
      "2":{t1:"Read",t2:"Device",t3:"Config",b:0},
      "3":{t1:"Report",t2:"Device",t3:"Config",b:0},
      "4":{t1:"Read",t2:"Device",t3:"Status",b:2000},
      "5":{t1:"Report",t2:"Device",t3:"Status",b:2000},
      "6":{t1:"Write",t2:"Aux",t3:"Config",b:4000},
      "7":{t1:"Read",t2:"Aux",t3:"Config",b:4000},
      "8":{t1:"Report",t2:"Aux",t3:"Config",b:4000},
      "9":{t1:"Read",t2:"Aux",t3:"Status",b:6000},
      "10":{t1:"Report",t2:"Aux",t3:"Status",b:6000},
      "11":{t1:"Debugging",t2:"Command",t3:"",b:6000},
    }
    this.csRef=React.createRef()
    this.mongoInfo={totals:{},slows:{}}
    this.svgDiv=React.createRef();
    this.selCellRef=React.createRef()


//     cl(this.state.mqttLogTime)
  }

//   sendTestLog=()=>{
// //     let url=`${constant.expressUrl}/usa/log`
// //     let method="POST"
// //     let type="text/plain"//"application/json"
// //     let data={thisx:"is it"}
//
//     logIt({msg:"this is here"})
// //     let data={s:}
// //     cl(url)
// //     return doGetPostBasic(url, method, JSON.stringify(data), type)// promise
//
//   }

  componentDidMount(){
    window.addEventListener('beforeunload', this.saveSearch);
//     this.graph = this.makeGraph(this.svgDiv.current)
  }

  componentWillUnmount=()=>{
    cl("component will unmount")
    this.subscribe_keyDownEvent.remove()
    this.subscribe_savePageEvent.remove()
    this.subscribe_newData.remove()
    // save tech portal search
    window.removeEventListener('beforeunload', this.saveSearch);
    // stop mqtt check
    if (this.mqttInterval){ clearInterval(this.mqttInterval)}
    this.closeLog2Ws()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this?.graph?.svgCurrent!=this.svgDiv.current){
      if(this.svgDiv.current){
//         cl(this.svgDiv.current)
        this.graph=this.makeGraph(this.svgDiv.current)
//         this.drawGraph(this.graph,0)
      }else{
        this.graph=null
      }
    }
  }

  saveSearch = () => {
    saveLocalStorage("techPortalState", JSON.stringify({"oneSearch": this.state.oneSearch}))
  }

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Tech Portal", url:`/usa/c18/admin/techPortal2`},
            ]},
        },
      )
    }
  }

  loadWsTrans=async(parms)=>{
    return await wsTrans("usa", {cmd: "cRest", uri: parms.uri,
      method: parms.method||"retrieve", sessionId: globs.userData.session.sessionId,
      body: parms.body})

  }

  makeItemLookups=(arr,idName)=>{
    let lu={}
    arr.forEach(ar=>{ lu[ar[idName]]=ar })
    return lu
  }

  loadMqttContacts=async()=>{
//     cl("mqttcontacts")
    let st=this.state
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/shared",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {type:"mqttContacts"}})
    let mqttContacts={}
    res.data.forEach(r=>{
      mqttContacts[r.clientId]=r
    })
//     cl(st.udpId)
    if((st.selectedType=="gateway")&&((st.udpId||0)<0x100)){//
      let gwId=st.selectedId
      let gw=st.gwIndex[gwId]
//       cl(gw.clientId)
      res=await wsTrans("usa", {cmd: "cRest", uri: "/s/lastSync",
        method: "retrieve", sessionId: globs.userData.session.sessionId,
        body: {clientId:gw.clientId}})
//       cl(res)
      this.lastSync=res.data.lastSync
//       cl(this.lastSync)
      let udpId=this.lastSync?.server?.substring(3,5)||0
//       cl(`current udpId: ${udpId}`)
      this.setState({udpId:+udpId})
//       cl(+this.lastSync?.server?.substring(3,5)||0)

//       cl(this.lastSync)
    }
//     cl(this.state)
//     cl(mqttContacts)
    return mqttContacts
  }

  loadLandruConstants=()=>{
    let badPrefs=["ZC","CC","SP","SN","ECPH","SE","SX","CD",
    "CHC","SNC","SNCD","CAA","CAC","CAPV","CAV","CEB","CZ","CCS",
    "CES","CE","MSG","GATEWAY"]
    let lc2={}
    Object.keys(lc).forEach(k=>{
      let pref=k.split("_")[0]
      if(!badPrefs.includes(pref)){
        let val=lc[k]
        lc2[val]=k
      }
    })
    this.lc2=lc2
  }

  loadMqttLive2=async(conts,server,reqType)=>{// load the info from prodX
    return
//     cl(config)
//     cl(server)
    if(true||config.server!="prod0"){
//       cl("getting data")
      let url=`http://${this.servers[server]}/usa/publicRest?uri=/o/${reqType}&method=retrieve`
      let method="GET"
      var data
      let type="text/plain"
//       cl(url)
      let res=await doGetPostBasic(url, method, data, type)
//       cl(res)
      let obj=await res.json()
//       cl(obj)
      conts.push({
        server:server,
        type:reqType,
        val:obj,
      })
    }
  }


  processMqttHttpConts=(accounts,contsIn,gwIndex)=>{
//     cl(gwIndex)
    let st=this.state
    let lines=[]
    let conts=[]
//     cl(contsIn)
    contsIn.forEach(c=>{
//       cl(c)
      switch(c.type){
        case "mqtt":
//           cl(c.val.data.clientIdToGatewayId)
          let gwIds=c.val.data.clientIdToGatewayId
//           cl(gwIds)
          Object.keys(gwIds).forEach(k=>{
//             cl(gwIds[k])
            let gw=gwIds[k]
            let ac=accounts.filter(a=>{return a.accountId==gw.accountId})[0]
//             cl(ac)
            let acctName=ac?.name||"No Account"
//             cl(gw)
//             cl(gw.gw)
//             cl(st.gwIndex[gw.gw])
            conts.push({
              account:acctName,
              server:c.server,
              type:c.type,
              gwId:gw.gw,
              clientId:gw.clientId,
              clientIdSort:gw.clientId.slice(-6),
              name:gwIndex[gw.gw]?.name||`${gw.gw}`,
              val:gw})
          })
          break
        case "http":
//           cl(c.val.data.sessions)
          let sessions=c.val.data.sessions
          Object.keys(sessions).forEach(k=>{
//             cl(sessions[k])
            let session=sessions[k]
            let ac=accounts.filter(a=>{return a.accountId==session.accountId})[0]
//             cl(ac)
//             cl(gwIndex)
//             cl(gwIndex[session.gatewayId])
            conts.push({
              account:ac?.name,
              server:c.server,
              type:c.type,
              gwId:session.gatewayId,
              clientId:"--",
              name:gwIndex[session.gatewayId]?.name,
              val:session,
            })
          })
          break
      }
//       cl(c)
    })
//     cl(conts)
    return conts

  }

//   loadMqttLive=async()=>{
//     let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/mqtt", method: "retrieve",
//       sessionId: globs.userData.session.sessionId,// actually, not necessary
//       body: {cmd:"getContacts"}})
//     cl(res)
//   }
//
//   loadHttpLive=async()=>{
//     let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/http", method: "retrieve",
//       sessionId: globs.userData.session.sessionId,// actually, not necessary
//       body: {cmd:"getContacts"}})
//     cl(res)
//
//   }

  doLoadDbgLog=async(clientId,period,endTime)=>{
    let query={e:Math.floor(endTime),p:period,s:clientId,l:7000}
//     cl(query)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/log",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: query})
//     cl(res.data.length)
//     cl(res.data[0])
//     cl("done")
    return res.data
  }

  loadDbgLog=async(st)=>{
    let now=getTime()
    let ts=Math.floor(Date.parse(st?.endTime||now)/1000)
    let query={e:ts,p:7200,l:1000}
//     cl(query, st.dbgLogClientId)
    var retVal=[]
    if(st.dbgLogClientId){
      retVal=await this.doLoadDbgLog(st.dbgLogClientId,7200,ts)
    }
//     cl(retVal[0])
    return retVal
//     if(st.dbgLogClientId){query.s=st.dbgLogClientId}
//     let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/log",
//       method: "retrieve", sessionId: globs.userData.session.sessionId,
//       body: query})
//     cl(res.data)
//     return res.data
  }

  updateDbgLog=async()=>{
    let st=this.state
    let now=getTimeI()
    let end=Date.parse(st.endTime)/1000
    if((st.pageType!="dbgLog")||(now>end)||
      (this.dbgLogRef?.current?.scrollTop)){return}
    let lastTime=st.dbgLogEntries[0]?.t||now
    let period=now-lastTime
    let addOn=await this.doLoadDbgLog(st.dbgLogClientId,period,now)
    let curDbgLog=addOn.concat(st.dbgLogEntries)
    let tags=this.findDbgTags(curDbgLog)
    this.setState({dbgLogEntries:curDbgLog,tags:tags})
  }

  findDbgTags=(log)=>{
//     cl("find debug tags")
    let tags={}
    log.forEach(l=>{
      let parts=l.m.split("\t")
//       cl(parts)
      for(let i=1;i<parts.length-1;i++){
        tags[parts[i]]=true}
    })
//     cl(tags)
    return tags
  }

  sendLog2Command=(cmd,clientId,parms)=>{
//     cl(this.log2WS)
    let obj={
      cmd:cmd,
      val:clientId,
      parms:parms,
//       address:this.lastSync?.address,
//       port:this.lastSync?.port,
    }
    if(this.log2WS){
//       cl(`send udp`)
      cl(obj)
      this.log2WS.send(JSON.stringify(obj))}
  }

  closeLog2Ws=()=>{
    if(this.log2WS){
      this.log2WS.close()
      this.log2WS=null
    }
  }

  openLog2Ws=()=>{// the log2 ws is on mqtt!!!

    var onOpen=(r,e)=>{
      cl("log2WS Open")
      this.setState({log2Online:true})
    }

    var onClose=(r,e)=>{
      cl("log2WS Close")
      this.log2WS=null
      this.setState({log2Online:false})
    }

    var onError=(r,e)=>{
      cl("log2WS Error")
    }

    var recvSocket=(msg)=>{
//       cl("log2WS Recv")
      this.rtdHandleData(msg)
    }

    cl("open Web Socket")
//     cl(config.host)
//     cl(constant.wsLog2Url)
    let uri=`${constant.wsLog2Url}`// must be set in utils!
    if(this.log2WS){return}
    let ws=new WebSocket(uri);
    ws.onopen = e=>onOpen(e);
    ws.onclose = e=>onClose(e);
    ws.onmessage = e=>recvSocket(e);
    ws.onerror = e=>onError(e);
    this.log2WS=ws
//     cl(this.log2WS)
  }

  loadInfo=async()=>{
// users, sites, accounts, zones, gateways,
    this.types=["account","user","site","gateway","zone"]
    let users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
//     cl(users)
    let accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
    let sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
    let gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
//     let zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
    // if(!gateways/*.gw*/){// for old style response from server
    //   let newGW=gateways
    //   gateways={gw:newGW,agw:[]}
    // }
//     cl(gateways)
    let gwIndex={}
//     cl(gateways)
    gateways/*.gw*/.forEach(gw=>{gwIndex[gw.gatewayId]=gw})
//     cl(gateways)
//     cl(gwIndex)
//     gateways/*.agw*/.forEach(gw=>{gwIndex[gw.gatewayId]=gw})
    let zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
    let searchRes={}
    this.makeCustomSaveInfo()
    let mqttContacts=await this.loadMqttContacts()
    let conts=[]
//     await this.loadMqttLive2(conts,"prod","mqtt")
    await this.loadMqttLive2(conts,"prod","http")
    await this.loadMqttLive2(conts,"prods","mqtt")
    await this.loadMqttLive2(conts,"prods","http")
//     await this.loadMqttLive2(conts,"prodx","mqtt")
//     await this.loadMqttLive2(conts,"prodx","http")
// cl("loadi")
//     await this.loadMqttLive2(conts,"stage","mqtt")
// cl("loadi")
//     await this.loadMqttLive2(conts,"stage","http")
    await this.loadMqttLive2(conts,"nomq","mqtt")
    await this.loadMqttLive2(conts,"nomq","http")
//     cl(conts)
//     await this.loadMqttLive2(conts,"stage","http")
//     await this.loadMqttLive2(conts,"stage","mqtt")
    let allConts=this.processMqttHttpConts(accounts,conts,gwIndex)
//     cl(allConts)
    var techPortalState
    this.loadLandruConstants()
//     cl(this.mqttContacts)
    techPortalState = getLocalStorage("techPortalState")
    // cl(techPortalState)
    let myState = {
      loaded:true,
      users:users,
      accounts:accounts,
      sites:sites,
      gateways:gateways,
      gwIndex:gwIndex,
      controllers:conts,
      allConts:allConts,
      zones:zones,
      mqttContacts:mqttContacts,
      lookUps:{
        userId:this.makeItemLookups(users,"userId"),
        accountId:this.makeItemLookups(accounts,"accountId"),
        siteId:this.makeItemLookups(sites,"siteId"),
        gatewayId:this.makeItemLookups(gateways,"gatewayId"),
        zoneId:this.makeItemLookups(zones,"zoneId"),
      }
    }
    if (techPortalState) {
      let techObj=JSON.parse(techPortalState)
      // cl(techObj)
// here, delete the saved items we *don't* want
      // delete techObj.mqttLogTime
      myState = Object.assign(techObj, myState)
    }
    let dbgLog=await this.loadDbgLog(this.state)
//     cl(dbgLog)
    let tags=this.findDbgTags(dbgLog)
//     cl(tags)
    myState = Object.assign(myState, {
      searchRes:searchRes,
      disableCache:globs.disableCache,// !(getLocalStorage("disableCache")=="false")||false,
      selDbTab:Object.keys(dpNames)[0],
      dbgLogEntries:dbgLog,
      tags:tags,
    })
//     cl(myState)
    await this.setState(myState)
    await this.loadAllSysStatData(getTime())// 2 minute ago
//     cl(conts)
  }

  saveData=async(cmd)=>{
    let st=this.state
    cl(st)
    if(cmd=="save"){
      var upd
      if(st.editType){
        switch(st.editType){
          case "zone":
            let zone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
            cl(zone)
            upd={
              zoneId:st.selectedId,
            }
            upd[st.editField]=makeNumNum(zone[st.editField])
            cl(upd)
            cl(st.editField)
            cl(zone[st.editField])
            cl("done")
            globs.events.publish("saveOK",true)
            return await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones",
              method: "update", sessionId: globs.userData.session.sessionId,
              body: upd})
            break
          case "gateway":
            let gateway=st.gateways.filter(g=>{return g.gatewayId==st.selectedId})[0]
            cl(gateway)
            upd={
              gatewayId:st.selectedId,
            }
            upd[st.editField]=gateway[st.editField]
            cl(upd)
            cl(st.editField)
            cl(gateway[st.editField])
            cl("done")
            globs.events.publish("saveOK",true)
            return await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways",
              method: "update", sessionId: globs.userData.session.sessionId,
              body: upd})
            break
        }
      }
//       if(st.editUserAcctId){
//         this.setState({editUserAcctId:false})
//         globs.events.publish("saveOK",true)
//         let selUser=st.users.filter(u=>{return u.userId==st.selectedId})[0]
//         cl(selUser)
//         let upd={userId:selUser.userId,accountId:st.userAccountId}
//         return await wsTrans("usa", {cmd: "cRest", uri: "/su/allUsers",
//           method: "update", sessionId: globs.userData.session.sessionId,
//           body: upd})
//       }
      this.checkSaveFields(this.saveFields[0])

//       if(st.editGatewaySiteId){
//         this.setState({editGatewaySiteId:false})
//         globs.events.publish("saveOK",true)
//         let selGateway=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.selectedId})[0]
//         cl(selGateway)
//         let upd={gatewayId:selGateway.gatewayId,siteId:st.gatewaySiteId}
//         return await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways",
//           method: "update", sessionId: globs.userData.session.sessionId,
//           body: upd})
//       }
    }
  }

/******************** Routines to handle the custom field saves **********************************/

  unSaveFields=()=>{
    globs.events.publish("savePageEnable",false)
    let vals={editType:null,editField:null}// old style custom field
    this.saveFields.forEach(sfi=>{
      vals[sfi.showEditFlag]=false
    })
//     cl(vals)
    this.setState(vals)

  }

  makeCustomSaveInfo=()=>{
// table for individual fields that get saved:
    this.saveFields=[
    {showEditFlag: "editGatewaySiteId",table:"gateways",id:"gatewayId",
      field:"gatewaySiteId", uri:"/su/allGateways",
      updField:"siteId",fieldName:"Site ID:"},
    {showEditFlag: "editUserAccountId",table:"users",id:"userId",
      field:"userAccountId", uri:"/su/allUsers",
      updField:"accountId",fieldName:"Acct ID:"}
    ]
  }

  checkSaveFields=async(sfi)=>{// sfi is saveFieldInfo
// this also needs to save to local storage!
    let st=this.state
    this.saveFields.forEach(async sfi=>{
      if(st[sfi.showEditFlag]/*.editGatewaySiteId*/){
//         cl("saving "+sfi.showEditFlag)
        globs.events.publish("saveOK",true)
        let vals={}
        vals[sfi.showEditFlag]=false// end edit mode
        this.setState(vals)//this.setState({editGatewaySiteId:false})
        let selVals=st[sfi.table].filter(v=>{return v[sfi.id]==st.selectedId})[0]
//         cl(selVals)// the item that's being saved'
        let upd={}
        upd[sfi.id]=selVals[sfi.id]
        upd[sfi.updField]=st[sfi.field]
        Object.assign(selVals,upd)
//         cl(upd)
//         cl(sfi.uri)
        return await wsTrans("usa", {cmd: "cRest", uri: sfi.uri,
          method: "update", sessionId: globs.userData.session.sessionId,
          body: upd})
  //         return await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways",
  //           method: "update", sessionId: globs.userData.session.sessionId,
  //           body: upd})
      }
    })
  }

  hideShowCustomSaveField=(vals)=>{
/* this needs to set the editGatewaySiteId flag, *and*
save the current value to st.gatewaySiteId
when it enters edit mode
*/
//     cl("show custom")
//     cl(vals)
    this.setState(vals)
  }

  editCustomSaveField=(vals)=>{
//     cl("edit custom")
//     cl(vals)
    globs.events.publish("savePageEnable",true)
    this.setState(vals)

  }

  showCustomSaveField=(sfi)=>{
    let st=this.state
//     cl(st)
//     cl(sfi)
//     let arr=(sfi.table=="gateways")?st[sfi.table].gw:st[sfi.table]
//     let selItem=st[sfi.table].filter(v=>{return v[sfi.id]==st.selectedId})[0]||{}
    let selItem=st[sfi.table]/*arr*/.filter(v=>{return v[sfi.id]==st.selectedId})[0]||{}
    if(st[sfi.showEditFlag]/*.editGatewaySiteId*/){
//             <td
//               style={{cursor:"pointer"}}
//               onClick={e=>{
//                 let vals={type:sfi.field}
//                 vals[sfi.field]=e.currentTarget.value
//                 this.onChange("hideShowCustomSaveField",
//                 {gatewaySiteId:selItem[sfi.updField]})}
//               }
//             >
      return(
            <tr><td>{sfi.fieldName}</td>
            <td>
            <input type="text"
              style={{width:150,padding:0,borderRadius:0}}
              value={st[sfi.field]}

            onChange={
              e=>{
//                 let vals={type:sfi.field}
                let vals={}
                vals[sfi.field]=e.currentTarget.value
                this.onChange("editCustomSaveField",vals)
              }
            }
            ></input>
            </td></tr>
      )
    }else{
      return <tr><td>{sfi.fieldName}</td><td
        style={{cursor:"pointer"}}
        onClick={e=>{
          let vals={}
          vals[sfi.showEditFlag]=true
          cl(st)
          cl(st[sfi.table])
          cl(sfi.id)
          cl(st.searchSel)
//           let arr=(sfi.table=="gateways")?st[sfi.table].gw:st[sfi.table]
          let item=st[sfi.table]/*arr*/.filter(it=>{return it[sfi.id]==st.selectedId})[0]// or selectedId
          cl(item)
          cl(sfi)
          vals[sfi.field]=item[sfi.updField]
          this.onChange("hideShowCustomSaveField",vals)}
        }
      >
        <span>{selItem[sfi.updField]||"No ID"}</span>
      </td>
      </tr>
    }

  }

/******************** End Routines to handle the custom field saves **********************************/

  searchType=(vals,type)=>{
//     cl(vals)
    let parms={
      account:{l:"accounts",n:"name",s:"accountSearch",i:"accountId"},
      user:{l:"users",n:"name",s:"userSearch",i:"userId"},
      site:{l:"sites",n:"name",s:"siteSearch",i:"siteId"},
      gateway:{l:"gateways",n:"name",s:"gatewaySearch",i:"gatewayId"},
      zone:{l:"zones",n:"zoneName",s:"zoneSearch",i:"zoneId"},
    }[type]
    let search=vals["oneSearch"].toLowerCase()
//     let arr=(parms.l=="gateways")?this.state[parms.l].gw:this.state[parms.l]
//     let res=this.state[parms.l].filter(v=>{
    let res=this.state[parms.l]/*arr*/.filter(v=>{
      if(Array.isArray(v.zoneId)){v.zoneId=""}
      return ((v[parms.n]+""||"").toLowerCase().indexOf(search)>=0)||
      ((v.accountId||"").toLowerCase().indexOf(search)>=0)||
      ((v.userId||"").toLowerCase().indexOf(search)>=0)||
      ((v.siteId||"").toLowerCase().indexOf(search)>=0)||
      ((v.gatewayId||"").toLowerCase().indexOf(search)>=0)||
      ((v.clientId||"").toLowerCase().indexOf(search)>=0)||
      ((v.zoneId||"").toLowerCase().indexOf(search)>=0)||
      ((v.email||"").toLowerCase().indexOf(search)>=0)
    }).map(v=>{return {v:v[parms.i],t:v[parms.n],id:v["_id"]}})
    vals.searchRes[type]=res
  }

//   searchAccount=(vals)=>{this.searchType(vals,"account")}
//   searchUser=(vals)=>{this.searchType(vals,"user")}
//   searchSite=(vals)=>{this.searchType(vals,"site")}
//   searchGateway=(vals)=>{this.searchType(vals,"gateway")}
//   searchZone=(vals)=>{this.searchType(vals,"zone")}

  getSearchPos=()=>{
    let st=this.state
    if(st.searchSel==-1){return -1}
    for(let i=0;i<st.searchRes.length;i++){
      if(st.searchRes[i].v==st.searchSel){return i}
    }
    return -1
  }

  searchKey=(key)=>{
    let st=this.state
    let pos=this.getSearchPos()
    var searchSel=st.searchSel
    if(key=="ArrowDown"){
      if(pos<st.searchRes.length-1){
        searchSel=st.searchRes[pos+1].v
      }
    }else{
      if(pos>0){
        searchSel=st.searchRes[pos-1].v
      }
    }
    this.setState({searchSel:searchSel})
  }

  getFeatures=(accountId)=>{
    let st=this.state
    let selAccount=st.accounts.filter(a=>{return a.accountId==accountId})[0]
//     cl(selAccount)
    let features={};
    (selAccount.features||[]).forEach(f=>{
      features[f]=true
    })
    return features
  }

  ts2str=(ts)=>{
    return dateToDisplayDate(new Date(1000*ts),"mm/dd/yyyy h:mm:ss ap",420)
  }

  getZoneLastData=async(zoneId)=>{
//     cl(zoneId)
    let zone=this.state.zones.filter(z=>{return z.zoneId==zoneId})[0]
//     cl(zone)
    let query={siteId:zone.siteId,zoneInd:+zone.siteZoneIndex}
//     cl(query)

    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/lastData",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: query})
//     cl(res.data)
    let ts=(res.data[0]||{}).t||0
    let daStr=this.ts2str(ts)
//     let da=new Date(1000*ts)
//     let daStr=dateToDisplayDate(da,"mm/dd/yyyy h:mm:ss ap",420)
//     cl(res)
    return daStr

  }

  resetPassword=async()=>{
    let st=this.state
    let pw=getPWString(8)
    let userId=st.selectedId

    let upd={userId:userId,password:pw}
    cl(upd)
    cl(st)
    this.setState({resetPW:pw})

    return await wsTrans("usa", {cmd: "cRest", uri: "/s/users",
      method: "update", sessionId: globs.userData.session.sessionId,
      body: upd})
  }

  activateUser=async()=>{
// if a user has been invited to an account, then they already have the accountId filled in
// if a user has not been activated, then they have a "token" field
    let st=this.state
    let user=st.users.filter(u=>{return u.userId==st.selectedId})[0]
    cl(user)
    cl("activate")
    if(user.accountId){//user invited to an account
      let pw=getPWString(8)
      let body={userId:user.userId, name: user.name, password: pw, token: user.token};
      wsTrans("usa", {cmd: "cRest", uri: "/o/invites", method: "create", body: body})
      this.setState({resetPW:pw})
    }else{// new user and account
      let query={token:user.token}
      cl(query)
      return await wsTrans("usa", {cmd: "cRest", uri: "/o/users/activate",
        method: "retrieve", sessionId: globs.userData.session.sessionId,
        body: query})
    }
}

  deleteUser=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this User?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
//       let user=st.users.filter(u=>{return u.userId==st.selectedId})[0]
      let user=st.users.filter(u=>{return u._id==st.id})[0]
      let users=st.users.filter(u=>{return u.userId!=st.selectedId})
      let relUsers=st.relUsers.filter(u=>u.v!=st.selectedId)
      cl(user)
      this.setState({users:users,relUsers:relUsers,selectedType:"",selectedId:""})//
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allUsers",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {_id:user._id}})
    }
  }

  deleteSite=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Site?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      cl(st)
      let site=st.sites.filter(s=>{return s._id==st.id})[0]// *if* selected from the site list
      let sites=st.sites.filter(s=>{return s.siteId!=st.selectedId})
      let relSites=st.relSites.filter(s=>s.v!=st.selectedId)

      if(!site){
        site=st.sites.filter(s=>{return s.siteId==st.selectedId})[0]
      }
      cl(site)
      this.setState({sites:sites,relSites:relSites,selectedType:"",selectedId:""})//
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allSites",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {_id:site._id}})
    }
  }

  deleteGateway=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Gateway? This will delete *all* gateway entries that have the given clientId. Also, note that the gateway MUST BE DISCONNECTED from the network when this delete is performed. If it is plugged in, you can DISCONNECT IT NOW.",
      buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      cl(st)
      let gateway=st.gateways/*.gw*/.filter(g=>{return g._id==st.id})[0]// *if* selected from the site list
      let gateways=st.gateways/*.gw*/.filter(g=>{return g.gatewayId!=st.selectedId})
      let relGateways=st.relGateways.filter(g=>g.v!=st.selectedId)

      if(!gateway){
        gateway=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.selectedId})[0]
      }
      cl(gateway)
      this.setState({gateways:gateways,relGateways:relGateways,selectedType:"",selectedId:""})//
// This should *completely* erase a zone, in the db,
// and in the tables that the server keeps
      cl("cont")
      await wsTrans("usa", {cmd: "cRest", uri: "/su/allConts",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {cmd:"deleteGateway",gatewayId:st.selectedId,clientId:gateway.clientId}})
//       cl("cont2")
//       await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones",
//         method: "delete", sessionId: globs.userData.session.sessionId,
//         body: {gatewayId:st.selectedId}})
    }
//       await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways",
//         method: "delete", sessionId: globs.userData.session.sessionId,
//         body: {_id:gateway._id}})
//       cl("cont3")
  }

  deleteAccount  =async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Account?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      let account=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
      let accounts=st.accounts.filter(a=>{return a.accountId!=st.selectedId})
      let relAccounts=st.relAccounts.filter(a=>a.v!=st.selectedId)
      cl(account)
      this.setState({accounts:accounts,relAccounts:relAccounts,selectedType:"",selectedId:""})//
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/suAccounts",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {accountId:account.accountId}})
    }
  }

  toggleDeleteZone=()=>{
//     cl("toggle delete")
    let st=this.state
    let zones=st.zones.slice(0)
    let zone=zones.filter(z=>{return z.zoneId==st.selectedId})[0]
//     cl(zone)
    zone.deleted=(zone.deleted)?false:true
    this.setState({zones:zones,editType:"zone",editField:"deleted"})//
  }

  deleteZone=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Zone?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      cl(st)
      let zone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
      let zones=st.zones.filter(z=>{return z.zoneId!=st.selectedId})
      let relZones=st.relZones.filter(z=>z.v!=st.selectedId)
      cl(zone)
      this.setState({zones:zones,relZones:relZones,selectedType:"",selectedId:""})//
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones",
        method: "delete", sessionId: globs.userData.session.sessionId,
        body: {zoneId:zone.zoneId}})
    }
  }

  doRefresh=async(section)=>{
//     let users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
// //     cl(users)
//     let accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
//     let sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
//     let gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
//     let zones=(await this.loadWsTrans({uri:"/su/allZones"})).data

    let vals={}
    let tp=(section=="techPortal")
    let st=this.state
    if((tp)||(section=="accounts")){
      vals.accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
    }
    if((tp)||(section=="users")){
      vals.users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
    }
    if((tp)||(section=="sites")){
      vals.sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
    }
    if((tp)||(section=="gateways")){
      vals.gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
    }
    if((tp)||(section=="zones")){
//       cl(this.state)
      vals.zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
      if(st.selectedType=="zone"){
        vals.zoneLastData=await this.getZoneLastData(st.selectedId)
      }
    }
    this.setState(vals)
//     cl(section)
  }

  addIGrowAcct=async(acct)=>{
    cl(acct)
    let st=this.state
    cl(st.iGrowAcct)
    cl(st)
    let res=await wsTrans("usa", {cmd: "cRest", uri: '/s/iGrow800Acct',
      method: 'retrieve', sessionId: globs.userData.session.sessionId,
      body: {igAccountId:st.iGrowAcct,siteId:st.selectedId,
        controllerType:1}})
    cl(res)
    let controllers=(res.data||[])
      .filter(c=>{return c.controllerType==1})
      .map(c=>{return c.serialNo})
    cl(controllers)
  }

  debugClick=async(text)=>{
//     cl(text)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/su/debugCmd",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {cmd:this.state.debugText}})
    cl(res.data)
    this.setState({debugResp:res.data})
  }

  sortMqttLive=(vals)=>{
    let st=this.state
    let conts=st.allConts.slice(0)
    cl(conts)
    let col=["","name","type","server","clientIdSort","account"][Math.abs(vals.sortMode)]
    let dir=(vals.sortMode>0)?1:-1
    cl(col)
    cl(dir)
    conts.sort((a,b)=>{
//       cl(a[col])
      if(a[col]>b[col]){return dir}
      if(a[col]<b[col]){return 0-dir}
      return 0
    })
    return conts
  }

  downloadToday=()=>{
    let sessionId=globs.userData.session.sessionId
    let st=this.state
    let ts=Math.floor (Date.parse(st.endTime)/1000)
    let da=dateToDisplayDate(new Date(1000*ts),"yyyy-mm-ddThh:mm")
    let url=`${constant.expressUrl}/usa/csv/dbgLog_${st.dbgLogClientId.slice(-6)}_${da}.csv?type=dbgLog&session=${sessionId}&clientId=${st.dbgLogClientId}&ts=${ts}`
    window.open(url,'_blank')
//     window.location.href=url
  }

  openTestPage=()=>{
    cl(config)
    let st=this.state
    let selGateway=st.gateways.filter(g=>{return g.gatewayId==st.selectedId})[0]||{}
    let cid=selGateway.clientId
    cl(cid)
    let urls={
      prod0:"http://nomq00.link4cloud.com:3103/bobIndex.html?clientId="+cid,
      http1:"http://http1.link4cloud.com:3103/bobIndex.html?clientId="+cid,
      prod0Test:"http://http0test.link4cloud.com:3113/bobIndex.html?clientId="+cid,
      prodS:"http://http0test.link4cloud.com:3113/bobIndex.html?clientId="+cid,
      prodX:"http://http0test.link4cloud.com:3123/bobIndex.html?clientId="+cid,
      nomq00:"http://nomq00.link4cloud.com:3103/bobIndex.html?clientId="+cid,
      stage:"http://stage.link4cloud.com:3113/bobIndex.html?clientId="+cid,
      devMongo:"xx",
      sg_ryan:"http://ryanelk.link4cloud.com:3133/bobIndex.html?clientId="+cid,
      wayne:"http://wayne.link4cloud.com:3103/bobIndex.html?clientId="+cid,
      sg_stage:"http://stage.link4cloud.com:3113/bobIndex.html?clientId="+cid,
      sg_alpha:"http://alpha.link4cloud.com:3123/bobIndex.html?clientId="+cid,
    }
    cl(urls[config.server])
// http://stage.link4cloud.com:3113/bobIndex.html
    window.open(urls[config.server], "_blank");

    cl("Open Test page")
  }

  updResetTime=()=>{
//     cl("upd")
    let st=this.state
    let now=getTime()
    let relTime=st.resetTime-now
    let resetMsg=`Reset in ${relTime} secs`
    if(relTime<0){
      clearInterval(this.resetTimer)
      this.resetTimer=null
      this.setState({resetTime:0})
    }else{
      this.setState({resetMsg:resetMsg})
    }

  }

  resetGateway=async()=>{
    let st=this.state
    if(st.selectedType!="gateway"){return}
    cl("reset gateway")
    cl(st)
    cl(st.selectedId)
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
      sessionId: globs.userData.session.sessionId, body:
      {gatewayId:st.selectedId,
        cmd:"resetGateway",
        contReset:st.contReset,
        }})
    cl(resp)
    this.setState({resetTime:(resp.data?.queryTime||0)+60})
  }

  setUdp=async()=>{
    cl("set udp")
    let st=this.state
    cl(st.udpId)
    if((st.selectedType=="gateway")&&(st.udpId!=null)){
      let gwId=st.selectedId
      let gw=st.gwIndex[gwId]
      let clientId=gw.clientId
      let udpId=(+st.udpId)&0xFF
      cl(`set udp to: ${udpId}`)
//       let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/udp", method: "update",
//         sessionId: globs.userData.session.sessionId,
//         body:{
//           clientId:st.clientId,
//           cmd:"sendUdp",
//         }})
//       cl(resp)

//       cl(gw)
      this.sendLog2Command("setUdpHost",clientId,[udpId])// now convert to number
      this.setState({udpId:null})// force a refresh
    }

  }

  onChange=async(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    let searches={updAccount:this.searchAccount,updUser:this.searchUser,
      updSite:this.searchSite,updGateway:this.searchGateway,updZone:this.searchZone}
//     cl(type,vals)
    var gateway,sss
    switch(type){
      case "updAccount":
      case "updUser":
      case "updSite":
      case "updGateway":
      case "updZone":
        vals.searchType=type
        searches[type](vals)
        vals.searchSel=-1
        this.setState(vals)
        break
      case "updSearch":
//         cl(st.searchRes)
        vals.searchRes={}
        this.types.forEach(k=>{
          this.searchType(vals,k)
        })
        Object.assign(vals,
          {relAccounts:[],relUsers:[],relSites:[],relGateways:[],relZones:[],selectedType:""}
        )
//         Object.keys(this.state.searchRes).forEach(k=>{
//           this.searchType(vals,k)
//         })
        this.setState(vals)
        break
      case "sel":
        this.unSaveFields()
        this.selectSearch(vals.type,vals.searchSel,vals.id)
        vals.selectedType=vals.type
//         cl(vals)
        if(vals.type=="account"){vals.accountFeatures=this.getFeatures(vals.searchSel)}
        if(vals.type=="zone"){
          this.zone=(st.zones.filter(z=>{return z.zoneId==vals.searchSel})||[])[0]
          this.gwType=this.zone?.gatewayType||1800
          vals.zoneLastData=await this.getZoneLastData(vals.searchSel)}
          if(this.zone){await loadSiteData(this.zone.siteId)}

        vals.searchRes={}
//         cl(vals)
        this.setState(vals)
        break
      case "searchKey":
//         cl(vals)
//         cl(st.searchType)
        if(st.searchType!=""){
          if(["ArrowDown","ArrowUp"].includes(vals.key)){this.searchKey(vals.key)}
          if(vals.key=="Enter"){this.selectSearch(st.searchSel)}
        }
        break
      case "accountSel":// these are selections from the related list
        this.unSaveFields()
        this.getRelatedItems(vals.accountSel)
//         cl(vals)
        let accountFeatures=this.getFeatures(vals.accountSel)
        this.setState({searchType:"",selectedType:"account",selectedId:vals.accountSel,
          accountSel:vals.accountSel,accountFeatures:accountFeatures,
        },
        )
        break
      case "userSel":
        this.unSaveFields()
        this.setState({searchType:"",selectedType:"user",selectedId:vals.userSel,
          userSel:vals.userSel,
        })
        break
      case "siteSel":
//         cl(vals)
//         cl(st)
        this.unSaveFields()
        let site=st.sites.filter(s=>{
          return s.siteId==vals.siteSel})[0]
        this.getRelatedItems(site.accountId,vals.siteSel,null,null,"site")
        this.setState({searchType:"",selectedType:"site",selectedId:vals.siteSel,
          siteSel:vals.siteSel,
        })
        break
      case "gatewaySel":
//         cl(vals)
        this.unSaveFields()
        gateway=st.gateways/*.gw*/.filter(g=>{
          return g.gatewayId==vals.gatewaySel})[0]
//         this.gwType=gateway.gatewayType||1800
//         cl(gateway)
//         cl(st.gateways)
        this.getRelatedItems(gateway.accountId,gateway.siteId,vals.gatewaySel,null,"gateway")
        this.setState({searchType:"",selectedType:"gateway",selectedId:vals.gatewaySel,
          gatewaySel:vals.gatewaySel,
        })
        break
      case "zoneSel":
//         cl("zonesel")
//         cl(vals)
        this.unSaveFields()
        let zone=st.zones.filter(z=>{return z.zoneId==vals.zoneSel})[0]
//         cl(zone)
        gateway=st.gateways/*.gw*/.filter(g=>{
          return g.gatewayId==zone.gatewayId})[0]
//         cl(gateway)
        this.gwType=gateway?.gatewayType||1800
        this.zone=zone
//         this.zInd=zone.siteZoneIndex

        let zoneLastData=await this.getZoneLastData(vals.zoneSel)
//         cl("load site data")
        await loadSiteData(this.zone.siteId)
//         cl("load site data done")
//         cl(zoneLastData)
        this.getRelatedItems(zone.accountId,zone.siteId,zone.gatewayId)
        this.setState({searchType:"",selectedType:"zone",selectedId:vals.zoneSel,
          gatewaySel:zone.gatewayId,zoneSel:vals.zoneSel,zoneLastData:zoneLastData,
        })
        break
      case "resetPassword":
        this.resetPassword()
        break
      case "activateUser":
        this.activateUser()
        break
      case "deleteUser":
        this.deleteUser()
        break
      case "hideShowCustomSaveField":
        this.hideShowCustomSaveField(vals)
        break
      case "editCustomSaveField":
        this.editCustomSaveField(vals)
        break
      case "showUserAccountId":
        vals.editUserAcctId=true
        this.setState(vals)
        break
//       case "showGatewaySiteId":
//         vals.editGatewaySiteId=true
//         this.setState(vals)
//         break
      case "userAccountId":
        globs.events.publish("savePageEnable",true)
        this.setState(vals)
        break
      case "deleteAccount":
        this.deleteAccount()
        break
      case "deleteZone":
        this.deleteZone()
        break
      case "toggleDeleteZone":
        globs.events.publish("savePageEnable",true)
        this.toggleDeleteZone()
        break
      case "deleteSite":
        this.deleteSite()
        break
      case "deleteGateway":
        this.deleteGateway()
        break
      case "feature":
        let features=Object.assign(st.accountFeatures)
        features[vals.feature]=vals.checked
        cl(vals)
        this.setState({accountFeatures:features})
        return
      case "saveFeatures":
//         cl(type)
        this.saveFeatures()
        break
      case "viewEdit":
//         cl(vals)
        this.setState({editType:vals.type,editField:vals.field})
//         cl(vals)
        break
      case "valEdit":
        globs.events.publish("savePageEnable",true)
        this.valEdit(vals)
        break
      case "link":
        vals.e.preventDefault()
        this.doLink(vals)
//         cl(vals)
        break
      case "upd":
        this.setState(vals)
        break
      case "refresh":
        this.doRefresh(vals.element)
//         cl(vals)
        break
      case "sendFwFile":
        cl(type)
        break
      case "igrow":
        this.addIGrowAcct(st.iGrowAcct)
        break
      case "disableCache":
        cl(vals.disableCache)
        saveLocalStorage("disableCache",vals.disableCache)
        this.setState(vals)
        cl(vals)
        break
      case "dbTab":
      case "dbCol":
      case "dbChan":
      case "dbInd":
      case "dbVal":
        if(type=="dbTab"){
          let tabId=dpNames[vals.selDbTab]
          cl(tabId)
          cl(this.gwType)
          let tab=pi[this.gwType][tabId]
          let selCol=Object.keys(tab||{})[0]
          vals.selDbCol=selCol
          cl(tab)

        }
        if(type!="dbVal"){
          vals.dbVal=this.getDBVal(vals)
        }
//         cl(vals)

        this.setState(vals)
        break
      case "dbSave":
        this.setDBVal()
        break
      case "dbRead":
        this.readPearlVal()
        break
      case "debugText":
      case "contReset":
        this.setState(vals)
        break
      case "debugClick":
        this.debugClick(st.debugText)
        break
      case "logClick":
        let url=`/usa/c18/serverLog/${globs.userData.session.sessionId}`
        window.open(url,"_blank")
        break
      case "selCell":
//         cl("selCell")
        await this.setState({selCell:vals})
        this.selCellRef.current.focus()
        break
      case "selCellText":
        this.setCellText(vals.r,vals.c,vals.text)
        break
      case "paramSearchText":
//         cl(vals)
        this.setState(vals)
        break
      case "selParam":
        if(st.selCell){
          let line=this.paramShows[vals.selParam]
          cl(line)
          let parts=line.split("-")
          let sc=st.selCell
          this.setCellText(sc.r,sc.c,parts[1])
        }
        this.setState(vals)
        break
      case "pageMenu":
        if(vals.pageType=="mongo"){
          this.getPerformance(true)
        }
        if(st.selectedType=="gateway"){
          let gw=st.gateways.filter(gw=>{return gw.gatewayId==st.selectedId})[0]
          saveLocalStorage("dbgLogClientId",gw.clientId)
          await this.setState({dbgLogClientId:gw.clientId})
        }
        if(vals.pageType=="mqttLog"){
          vals.waitFlag=true
          this.loadMqttLog()
//           vals.mqttLog=await this.loadMqttLog()
        }
        if(vals.pageType=="dbgLog"){
          vals.dbgLogEntries=await this.loadDbgLog(this.state)
        }
        if(vals.pageType=="test"){
          await this.loadTests(vals)
          await this.loadTestLog();
          st=this.state// why is this necessary?!
          vals.testLogSel=st.testLogs[0]?.i||""
          this.testSetInterval=setInterval(this.loadTestLog,5000)
        }else{
          clearInterval(this.testSetInterval)
          this.paramTable=null
          this.paramShows=null
        }
        vals.mqttLog=[]
        this.setState(vals)
        break
      case "mqttLogTime":
//         let val=dateToDisplayDate(new Date(1000*st.mqttLogTime),"yyyy-mm-ddThh:mm")
        vals.mqttLogTime=(+Date.parse(vals.mqttLogTime))/1000
//         cl(vals.mqttLogTime)
        this.loadMqttLog(vals.mqttLogTime)
        this.setState(vals)

        break
      case "mqttMsgClick":
//         cl(vals)
        this.setState(vals)
        break
      case "dTime":
//         cl(vals)
        this.loadMqttLog(st.mqttLogTime+vals.dif)
        this.setState({mqttLogTime:st.mqttLogTime+vals.dif})
        break
      case "liveContClick":
        cl(vals)
        let cont=st.gateways.filter(g=>{return g.gatewayId==vals.contSel})[0]
        cl(cont)
        if(cont){vals.dbgLogClientId=cont.clientId}
        saveLocalStorage("dbgLogClientId",vals.dbgLogClientId)
        this.setState(vals)
        break
      case "mqttClient":
//         cl(vals)
        clearTimeout(this.mqttLogTimer)
        this.mqttLogTimer=setTimeout(this.loadMqttLog,1000)
//         this.mqttLogTimer=setTimeout(
//           e=>{
//             cl("timed")
//             this.loadMqttLog()
//           },1000)
//           this.loadMqttLog,1000)
        saveLocalStorage("dbgLogClientId",vals.dbgLogClientId)
        this.setState(vals)
        break
      case "dbgLogClientId":
        saveLocalStorage("dbgLogClientId",vals.dbgLogClientId)
      case "endTime":
        await this.setState(vals)
        this.setState({dbgLogEntries:await this.loadDbgLog(this.state)})
        break
      case "tagsEdit":
        this.setState(vals)
//         cl(vals)
        break
      case "sortMqttLive":
        let sm=vals.column
        if(sm==Math.abs(st.sortMode)){sm=0-st.sortMode}
        vals.sortMode=sm
        vals.allConts=this.sortMqttLive(vals)
        this.setState(vals)
        break
      case "downloadDbg":
        return this.downloadToday()
      case "gwDel":
        let gwd=Object.assign({},st.gwDel)
        gwd[vals.v]=vals.val
        this.setState({gwDel:gwd})
        break
      case "testPage":
        return this.openTestPage()
      case "liveCheck":
        cl(vals)
        this.setState({liveChecked:vals.val})
        break
      case "resetGateway":
        this.resetGateway()
        break
      case 'copyValue':
        this.setState({copyBtnClicked: true})
        let success = await copyToClipboard(vals);
        setTimeout(() => {
          this.setState({ copyBtnClicked: false });
        }, 2000);
  
        break;
      case "sysStatSel":
//         cl(vals)
        vals[vals.chl]=vals.id
        if(vals.chl=="zones"){// load the graph data for the zone
          vals.xBoards=await this.loadXBoards(vals.id)
          vals.graphSpan=1440
          vals.graphTick=240
          let now=Math.floor(getTime()/60)
          let min=now-vals.graphSpan// minutes
          let clientId=(st.zones.filter(z=>{return z.zoneId==vals.id})||[])[0].clientId
//           cl(min,now)
          let res=await this.loadSysStat({
            c:clientId,
            m:{$gte:min,$lt:now}
          })
//           cl(res)
          vals.data=res
        }
        sss=Object.assign({},st.sysStatSel,vals)
//         if(["accts","sites"].includes(vals.chl)){sss.zones=null}
        if(vals.chl=="accts"){sss.sites=null}
        if(vals.chl=="sites"){
          sss.zones=null
          this.xBoardsLoaded=null
        }
        if(vals.chl=="zones"){sss.id=-1}
        await this.setState({sysStatSel:sss})
        if(vals.chl=="mods"){
          let id=vals.id
          let grVals=st.sysStatSel.data.map(v=>{return{
            t:v.m*60*1000,
            v:(v.p[id]-1)/2.54
          }})
          this.drawGraph(this.graph,grVals)
        }
        break
      case "setGraphTime":
        cl(st)
        let now=Math.floor(getTime()/60)
        let min=now-vals.graphSpan// minutes
        sss=st.sysStatSel
        let id=sss.id
        let clientId=(st.zones.filter(z=>{return z.zoneId==sss.zones})||[])[0].clientId
        let res=await this.loadSysStat({
          c:clientId,
          m:{$gte:min,$lt:now}
        })
        vals.data=res
        let grVals=vals.data.map(v=>{return{
          t:v.m*60*1000,
          v:(v.p[id]-1)/2.54
        }})
//         sss=Object.assign(st.sysStatSel)
//         Object.assign(sss,vals)
        sss=Object.assign({},st.sysStatSel,vals,)
        cl(sss.graphSpan)
        await this.setState({sysStatSel:sss})
        this.drawGraph(this.graph,grVals)
//         this.setState({sysStatSel:vals})// should be made a function
        break
      case "setUdp":
        this.setUdp()
        break
      case "testText":
//         cl(vals)
        this.parseTest(vals)
        this.setState(vals)
        break
      case "runTest":
      case "deleteTest":
        this.doTestCmd(type)
        break
      case "saveTest":
        this.saveTests()
        break
      case "selSlow":
        this.getSlowInfo(vals.slowIndex)
      case "copyTest":
        this.copyTest()
        break
      case "testClientId":
        this.setState(vals)
        cl(vals)
        saveLocalStorage("testClientId",vals.testClientId)
        break
      case "testLogSel":
        await this.setState(vals)
//         cl(st.testLogSel)
        return await this.loadTestLog()
      case "deleteTestLog":
        this.deleteTestLog()
        break

      case "testName":// to allow renaming of a test
//         cl(st)
        let tnTests=Object.assign({},st.tests)
        if(tnTests[st.testSel]){
          tnTests[vals.testName]=tnTests[st.testSel]
          tnTests[vals.testName].testName=vals.testName
          delete tnTests[st.testSel]
        }
        vals.testSel=vals.testName
        vals.tests=tnTests
//         let tnTest=tnTests[st.testSel]
//         cl(tnTest)
        this.setState(vals)
        break
      case "testSel":
        vals.testName=vals.testSel// drop through
        if(!st.tests[vals.testSel]){// new test
          let tsTests=Object.assign({},st.tests)
          tsTests[vals.testName]={test:[]}
          vals.tests=tsTests
        }
      case "fuiSel":
      case "newUdp":
      case "slowTimeRange":
        this.setState(vals)
        break
    }
  }

  valEdit=(vals)=>{
    let st=this.state
    switch(st.selectedType){
      case "zone":
        let zones=st.zones.slice(0)
        let zone=zones.filter(z=>{return z.zoneId==st.selectedId})[0]
        zone[st.editField]=vals.val
        this.setState({zones:zones})
        break
      case "gateway":
        let gateways=st.gateways.slice(0)
        let gateway=gateways.filter(g=>{return g.gatewayId==st.selectedId})[0]
        gateway[st.editField]=vals.val
        this.setState({gateways:gateways})
        break
    }
  }

  saveFeatures=async()=>{
    let st=this.state
//     cl("save features")
    let features=[]
    Object.keys(st.accountFeatures).forEach(f=>{
//       cl(f)
      if(st.accountFeatures[f]){features.push(f)}
    })
    let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
    selAccount.features=features
    cl(st)
    cl(features)

    return await wsTrans("usa", {cmd: "cRest", uri: '/su/suAccounts',
      method: 'update', sessionId: globs.userData.session.sessionId,
      body: {accountId:st.selectedId,features:features}})
  }

  showSearchBox=(type)=>{
    let st=this.state
//     cl(st.searchRes[type])
    return(
      <div style={{width:"100%",height:200,backgroundColor:this.bgColor2,marginRight:30,
        padding:10,overflowY:"auto",
      }}>
      <table><tbody>
      {st.searchRes[type].map((r,i)=>{
        let txt=(r.t)?r.t:"No Name"
//         cl(r)
        let bg=(r.v==st.searchSel)?this.selColor1:null
        return(
          <tr key={i} style={{backgroundColor:bg,cursor:"pointer"}}
            onClick={e=>this.onChange("sel",{type:type,searchSel:r.v,id:r.id})}
          ><td>{txt}</td></tr>
        )})}
      </tbody></table>
      </div>
    )
  }

  getRelatedItems=(accountId,siteId,gatewayId,zoneId,selType)=>{

//     cl(accountId,siteId,gatewayId,zoneId,selType)
    var filterRes=(type,val)=>{
//       cl(type,val)
//       if(type=="gateway"){cl(val.gatewayId)}

      if(siteId){
        if(gatewayId&&(type!="site")){
//           if(val.siteId=="wYlujiZmhVlPN0*n"){cl(type);cl(val)}
          if(zoneId){
            return (val.zoneId==zoneId)&&(val.siteId==siteId)
            &&(val.gatewayId==gatewayId)&&(val.accountId==accountId)
          }else{
            if(selType=="gateway"){
//               cl(val)
              return (val.gatewayId==gatewayId)&&(val.accountId==accountId)
            }else{
              let tVal=(val.siteId==siteId)&&(val.accountId==accountId)
                &&(val.gatewayId==gatewayId)
//               if(val.gatewayId=="ZAHN0AUF9MGY4Y35"){cl(tVal);cl(val)}
              return (val.siteId==siteId)&&(val.accountId==accountId)
                &&(val.gatewayId==gatewayId)
            }
          }
        }else{
          return (val.siteId==siteId)&&(val.accountId==accountId)
        }
      }else{
        return val.accountId==accountId
      }
    }

    let field={updAccount:"accountId"}
    let st=this.state
    let relAccounts=st.accounts.filter(a=>{return a.accountId==accountId}).map(a=>
      {return {v:a.accountId,t:a.name}})
    let relUsers=st.users.filter(u=>{return u.accountId==accountId}).map(u=>
      {return {v:u.userId,t:u.name}})

    let relSites=st.sites.filter(s=>{return filterRes("site",s)}).map(s=>
      {return {v:s.siteId,t:s.name}})

//     cl(st.gateways.filter(g=>{return g.siteId=="wYlujiZmhVlPN0*n"}))

    let relGateways=st.gateways/*.gw*/.filter(g=>{return filterRes("gateway",g)}).map(g=>
      {return {v:g.gatewayId,t:g.name||"No GW Name"}})
//     cl(relGateways)

    let relZones=st.zones.filter(z=>{return filterRes("zone",z)}).map(z=>
      {return {v:z.zoneId,t:z.zoneName}})
//     cl(relUsers)
    this.setState({relAccounts:relAccounts,relUsers:relUsers,relSites:relSites,
      relGateways:relGateways,relZones:relZones})
  }

  selectSearch=(type,id,id2)=>{// id2 is the mongo _id
//     cl(type,id,id2)
    let st=this.state
//     cl(st.searchType)
    let parms={
      account:{v:"accounts",id:"accountId"},
      user:{v:"users",id:"userId"},
      site:{v:"sites",id:"siteId"},
      gateway:{v:"gateways",id:"gatewayId"},
      zone:{v:"zones",id:"zoneId"},
    }[type]
    var val
//     let arr=(parms.v=="gateways")?st[parms.v].gw:st[parms.v]
    if(id2){
      val=st[parms.v]/*arr*/.filter(s=>{return s["_id"]==id2})[0]
    }else{
      val=st[parms.v]/*arr*/.filter(s=>{return s[parms.id]==id})[0]
    }
//     cl(val)
//     let accountId=val.accountId


//     cl("select "+id)
//     let accountId=id
//     cl(val.accountId,val.siteId,val.gatewayId,null,type)
    this.getRelatedItems(val.accountId,val.siteId,val.gatewayId,null,type)//,val.zoneId
    this.setState({searchType:"",selectedId:id})
  }

//   makeOpts=(vals)=>{
//     return vals.map((v,i)=>{return(
//       <option key={i} value={v.v}>{v.t}</option>
//     )})
//   }

//   showSelectUser=(relUsers)=>{
//     cl(relUsers)
//     return(
//       <div className="custom-select">
//         <div className="clearfloat"></div><br/>
//         <label htmlFor="">Related Users</label>
//
//         <C18Select00 id=""
//           parms={{list:true,height:200}}
//           value={this.state.userSel}
//           onChange={e=>this.onChange("userSel",{userSel: e.currentTarget.value})}
//         >
//           {this.makeOpts(relUsers)}
//         </C18Select00>
//         {false&&
//           <span className="material-icons down-arrow">
//             keyboard_arrow_down
//           </span>
//         }
//       </div>
//     )
//   }

  showSelectType=(relTypes,type)=>{
//     cl(type)
//     cl(relTypes)
    let parms={
      account:{t:"Accounts",v:"accountSel"},
      user:{t:"Users",v:"userSel"},
      site:{t:"Sites",v:"siteSel"},
      gateway:{t:"Gateways",v:"gatewaySel"},
      zone:{t:"Zones",v:"zoneSel"},
    }[type]
    return(
      <div className="custom-select">
        <div className="clearfloat"></div><br/>
        <label htmlFor="">Related {parms.t}</label>
        <C18Select00 id=""
          parms={{list:true,height:200}}
          value={this.state[parms.v]}
          onChange={e=>{
            let vals={}
            vals[parms.v]=e.currentTarget.value
            this.onChange(parms.v,vals)}
          }
        >
          {makeOpts(relTypes)}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div>
    )
  }

  showFeatureFlags=(selAccount)=>{
    let features=[
    {v:"tasks",t:"Tasks"},
    {v:"messaging",t:"Messaging"},
    {v:"editFui",t:"Edit Fui"},
    {v:"cameras",t:"Cameras"},
    {v:"showGjId",t:"Show GJ ID"},
    {v:"techPortal",t:"Tech Portal"},
    {v:"salesPortal",t:"Sales Portal"},
    {v:"editInfo",t:"Edit Info Pages"},
    {v:"gjPing",t:"GJ Ping"},
    {v:"3rdParty",t:"Third Party"},
    {v:"fullSiteAccess",t:"Full Site Access"},
    {v:"showUserEmail",t:"Show Email"},
    {v:"zoneGroups",t:"Zone Groups"},
    {v:"saveDefaults",t:"Save Defaults"},
    {v:"equipmentImage",t:"Equipment Image"},
    {v:"configSave",t:"Save Config"},
//     {v:"videoTest",t:"Video Test"},
    {v:"testing",t:"Testing"},
    {v:"systemStatus",t:"System Status"},
    {v:"cropRecipes",t:"Crop Recipes"},
    {v:"svgEditor",t:"SVG Editor"},
    {v:"virtualZones",t:"Virtual Zones"},
    {v:"filterList",t:"Filter Lists"},
    {v:"watchParams",t:"Watch Parameters"},
    {v:"testAcct",t:"Test Account"},
    {v:"physView",t:"Physical View"},
    {v:"syncStatus",t:"Sync Status"},
    {v:"accesses",t:"View Page Accesses"},
    {v:"advancedDataViz",t:"Advanced Data Visualization"},    
    {v:"advancedGraphingInterp",t:"Advanced Graphing Interpolation"},    
    {v:"debug",t:"Debug Functions"},
    {v:"errorBounds",t:"Error Bounds"},
    {v:"fuiShow",t:"Show FUI PIDs"},
    {v:"combinedYAxis",t:"Combined Y-Axis"},
    {v:"repeatingTasks",t:"Repeating Tasks"},
    {v:"autoSetup",t:"Auto Setup"},
    {v:"qrcodeListen",t:"QR Codes"},
    {v:"compareConfig",t:"Compare Config"},
    {v:"linkbot",t:"AI Chatbot"},
//     {v:"zoneGroups",t:"QR Codes"},
//     {v:"fwUpload",t:"Firmware Upload"},
// tasks, messaging, editFui, cameras, showGjId, techPortal, salesPortal, gjPing, 3rdParty,fullSiteAccess,
// showUserEmail, zoneGroups, configSave,videoTest
    ]
    let st=this.state
//     cl(st.accountFeatures)
    return(
      <div style={{height:100,backgroundColor:this.bgColor2, overflowY:"auto",padding:10,
        borderStyle:"solid",borderWidth:1,

      }}>
      <table><tbody>
      {features.map((f,i)=>{
        return(
        <tr key={i}><td>
        <input type="checkbox" style={{margin:5}}
          checked={st.accountFeatures[f.v]||false}
          onChange={e=>{this.onChange("feature",{feature:f.v,checked:e.currentTarget.checked})}}
        />
        </td>
        <td>{f.t}</td>
        </tr>
      )})}
      </tbody></table>
      </div>
    )
  }

  showUserAccountId=(user)=>{
//     cl(user)
    let st=this.state
    if(st.editUserAcctId){
      return(
        <div>
          <input type="text"
          style={{width:"100%",padding:0,borderRadius:0}}
          value={st.userAccountId}
          onChange={e=>this.onChange("userAccountId",
            {userAccountId:e.currentTarget.value})}
          ></input>
        </div>
      )
    }else{
      return <span>here{user.accountId}</span>
    }
  }

//   showGatewaySiteId=(gw)=>{
//     let st=this.state
//     let selGateway=null
//     if(st.editGatewaySiteId){
//       return(
//         <div>
//             <tr><td>Site ID:</td><td
//               style={{cursor:"pointer"}}
//               onClick={e=>this.onChange("showGatewaySiteId",{gatewaySiteId:selGateway.siteId})}
//             >
//             {this.showGatewaySiteId(selGateway)}
//             <input type="text"
//             style={{width:"100%",padding:0,borderRadius:0}}
//             value={st.gatewaySiteId}
//             onChange={e=>this.onChange("gatewaySiteId",
//               {gatewaySiteId:e.currentTarget.value})}
//             ></input>
//             </td></tr>
//         </div>
//       )
//     }else{
//
//       return <span>{gw.siteId||"No ID"}</span>
//     }
//   }

  showIGrow=()=>{
    let st=this.state
    let zones=st.zones.filter(z=>{
      return z.siteId==st.selectedId})
//     cl(zones[0]?.siteId,st.siteSel)
//     cl(zones)
    if(zones.length){
      let sty={borderRadius:0,borderWidth:1,borderStyle:"solid",padding:0,width:60,
        display:"inline-block",marginLeft:5}
      let sty2=Object.assign({},sty,{width:40})
      return(
        <div>
          <h3>Add iGrow Account to Site</h3>
          <label style={{display:"inline-block"}}>AcctID:</label>
          <input type="number"
            value={st.iGrowAcct||0}
            onChange={e=>{this.onChange("upd",{iGrowAcct:e.target.value})}}
            style={sty}/>
          <button style={sty2}
          onClick={e=>{this.onChange("igrow")}}
          >Add</button>
        </div>
      )
    }
  }

  showDbTableSelect=()=>{
    let gwType=1800
    let st=this.state

    let opts=Object.keys(dpNames).map((ta,i)=>{return(//[gwType]
      <option key={i} value={ta}>{dpNames[ta]}</option>
    )})
    return(
      <div>
      <label htmlFor="tabSel" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>tab:</label>
      <select id="tabSel"
        value={st.selDbTab||""}
        onChange={e=>{this.onChange("dbTab",{selDbTab:e.currentTarget.value})} }
      >
        {opts}
      </select>
      </div>
    )
  }

  showDbColumnSelect=()=>{
    let gwType=1800
    let st=this.state
//     cl(st.selDbTab)
    let tabId=dpNames[st.selDbTab]
//     cl(pi[gwType][tabId])
    let tab=pi[gwType][tabId]
    let opts=Object.keys(tab).map((k,i)=>{
      let op=tab[k]
      return <option key={i} value={k}>{k}</option>
    })
    return(
      <div>
      <label htmlFor="colSel" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>col:</label>
      <select id="colSel"
        value={st.selDbCol||""}
        onChange={e=>{this.onChange("dbCol",{selDbCol:e.currentTarget.value})} }
      >
        {opts}
      </select>
      </div>
    )
  }

  showDbChanSelect=()=>{
//     let gwType=1800
    let st=this.state
//     cl(st.selDbTab)
//     cl(pi[gwType][st.selDbTab])
//     let tab=pi[gwType][st.selDbTab]

    let opts=[...Array(64)].map((k,i)=>{
      return <option key={i} value={i}>{i}</option>
    })
    return(
      <div>
      <label htmlFor="chSel" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>chn:</label>
      <select id="chSel"
        value={st.selDbChan||""}
        onChange={e=>{this.onChange("dbChan",{selDbChan:e.currentTarget.value})} }
      >
        {opts}
      </select>
      </div>
    )
  }

  showDbIndexSelect=()=>{
//     let gwType=1800
    let st=this.state
//     cl(st.selDbTab)
//     cl(pi[gwType][st.selDbTab])
//     let tab=pi[gwType][st.selDbTab]

    let opts=[...Array(64)].map((k,i)=>{
      return <option key={i} value={i}>{i}</option>
    })
    return(
      <div>
      <label htmlFor="indSel" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>ind:</label>
      <select id="indSel"
        value={st.selDbInd||""}
        onChange={e=>{this.onChange("dbInd",{selDbInd:e.currentTarget.value})} }
      >
        {opts}
      </select>
      </div>
    )
  }

  getParamInfo=(vals)=>{
    let st=this.state
    let gw=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.gatewaySel})
    vals=Object.assign({},st,vals)
    let tabId=dpNames[vals.selDbTab]
    let tab=(pInd[this.gwType]||{})[tabId]
    if(!tab){return}
//     cl(tab)
    vals.selDbChan=+(vals.selDbChan||0)
    switch(tab[1]){
      case 0:
//         cl("do 0")
        vals.selDbChan=240
        break
      case 2:
        vals.selDbChan+=192
        break
      default:
        break
    }
    vals.pid=getParamId2(this.gwType,vals.selDbTab,vals.selDbCol)
    vals.pid+=(+(vals.dbInd||0))*tab[2]
//     cl(`zone: ${this.zone.siteZoneIndex}, chan: ${vals.selDbChan||0}, pid: ${vals.pid}`)
//     cl(vals)
    return vals
  }

  makeDbParams=(doSave)=>{
    let vals=this.getParamInfo();
//     cl(vals)
    if(doSave){
      ((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[vals.pid]=vals.dbVal
    }
    let params=[{
      c:vals.selDbChan,
      d:this.state.dbVal,
      f:2,
      i:vals.pid,
      t:getTimeI(),
      z:this.zone.siteZoneIndex,
    }]
    return params
  }

  newData=(zData)=>{
    if(this.state.selectedType!="zone"){return}
    let params=zData.params
//     cl(params)
    if(!params){return}
    let p=params[0]
    let cp=(this.makeDbParams()||{})[0]
//     cl(cp)
    for(let i=0;i<params.length;i++){
      let p=params[i]
//       cl(p)
      if((p.z==cp.z)&&(p.c==cp.c)&&(p.i==cp.i)){
        this.setState({valColor:"#FFFF88",dbVal:`*${p.d}*`})
        setTimeout(()=>{
          this.setState({valColor:"#FFFFFF"})
        },500)
        break
      }
    }
  }

  readPearlVal=()=>{
//     cl("force pearl read")
//     cl(this.state)
    let params=this.makeDbParams()
//     cl(params)
    let p=params[0]
    delete p.d
    delete p.f
    delete p.t
    let par={
      cmd:"data02",
      s:this.zone.siteId,
      gwType:this.zone.gatewayType||1800,
      sessionId:globs.userData.session.sessionId,
//       user:globs.userData.session.userId,
//       virtual:this.zone.virtual||false,
      params:params,
    }
//     cl(par)
    sendSocket(par)
  }

  setDBVal=()=>{
    let st=this.state
    let params=this.makeDbParams(true)
    let par={
      cmd:"data01",
      s:this.zone.siteId,
      gwType:this.zone.gatewayType||1800,
      sessionId:globs.userData.session.sessionId,
      user:globs.userData.session.userId,
      virtual:this.zone.virtual||false,
      params:params,
    }
    cl(par)
    sendSocket(par)
  }

  getDBVal=(vals)=>{
    vals=this.getParamInfo(vals)
//     cl(vals)
//     cl(dbVals.z[0])
//     cl(dbVals.z[0][0])
//     cl(dbVals.z[0][0][507])
//     cl((dbVals.z[this.zone.siteZoneIndex]||{}))
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{}))
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[507])
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[+pid])
    let val=((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})
      [vals.pid]||"nodata"
//     cl(val)
    return val

//     let st=this.state
//     let gw=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.gatewaySel})
//     vals=Object.assign({},st,vals)
//     let tabId=dpNames[vals.selDbTab]
//     let tab=pInd[this.gwType][tabId]
//     cl(tab)
//     vals.selDbChan=vals.selDbChan||0
//     switch(tab[1]){
//       case 0:
//         vals.selDbChan=240
//         break
//       case 2:
//         vals.selDbChan+=192
//         break
//       default:
//         break
//     }
//     let pid=getParamId2(this.gwType,vals.selDbTab,vals.selDbCol)
//     pid+=(+(vals.dbInd||0))*tab[2]
//     cl(`zone: ${this.zone.siteZoneIndex}, chan: ${vals.selDbChan||0}, pid: ${pid}`)
//     cl(vals.dbInd)
//     cl(tab[2])
//     cl((+vals.dbInd)*tab[2])
//     cl(dbVals.z[0])
//     cl(dbVals.z[0][0])
//     cl(dbVals.z[0][0][507])
//     cl((dbVals.z[this.zone.siteZoneIndex]||{}))
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{}))
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[507])
//     cl(((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[+pid])

//     let val=((dbVals.z[this.zone.siteZoneIndex]||{})[vals.selDbChan]||{})[pid]||"nodata"
//     return val
  }

  showBrowseDB=()=>{
    let st=this.state
//         <label htmlFor="chanText" style={{display:"inline-block"}}
//         >c:</label>
//         <input type="text" id="chanText"
//           style={{marginTop:10,marginLeft:10,padding:0,minWidth:0,width:50,borderRadius:0,
//             display:"inline-block"
//           }}
//         />
//         <label htmlFor="pidText" style={{display:"inline-block",marginLeft:15,}}>i:</label>
//         <input type="text" id="pidText"
//           style={{marginTop:10,marginLeft:10,padding:0,minWidth:0,width:50,borderRadius:0,
//             display:"inline-block"
//           }}
//         />
//         <label htmlFor="pidText" style={{display:"inline-block",marginLeft:15,}}>d:</label>
//         <input type="text" id="pidText"
//           style={{marginTop:10,marginLeft:10,padding:0,minWidth:0,width:50,borderRadius:0,
//             display:"inline-block"
//           }}
//         />
//     let valColor="rgb(0,0,255)"

    return(
      <div>
        <div className="clearfloat"></div><br/>
        {this.showDbTableSelect()}
        {this.showDbColumnSelect()}
        {this.showDbChanSelect()}
        {this.showDbIndexSelect()}
        <label htmlFor="dbVal" style={{display:"inline-block",marginRight:5,}}>val:</label>
        <input type="text" id="dbVal"
          value={st.dbVal||""}
          onChange={e=>this.onChange("dbVal",{dbVal:e.currentTarget.value})}
          style={{padding:0,minWidth:0,width:100,borderRadius:0,
            display:"inline-block",backgroundColor:st.valColor,
          }}
        />
        <button type="button" style={{borderStyle:"solid",borderWidth:1,padding:"0px 5px",
          borderRadius:0,marginLeft:10}}
          onClick={e=>{this.onChange("dbSave")}}
          >Save</button>
        <button type="button" style={{borderStyle:"solid",borderWidth:1,padding:"0px 5px",
          borderRadius:0,marginLeft:10}}
          onClick={e=>{this.onChange("dbRead")}}
          >Read</button>
      </div>

    )
  }

  getControllerState=async()=>{
    let st=this.state
    if(st.selectedType!="gateway"){return}
//     cl("get cont")
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/controllerEvents",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {gatewayId:st.selectedId,ts:{$gt:getTime()-86400}}})
//     cl(res.data)
    this.setState({controllerState:res.data})
//     cl(this.csRef)
//     this.csRef.current.scrollIntoView()
  }

  controllerState=()=>{
//     cl(this.state)
    let rows=this.state?.controllerState?.map((cs,i)=>{
//       cl(cs)
      let da=new Date(1000*cs.ts)
//       cl(da)
      let dispTime=dateToDisplayDate(da,"hh:mm",480)
      var msg=""
      if(cs.subType?.includes("Init")){msg="Init"}
      if(cs.subType?.includes("inTmpErr")){msg="inTemp Error"}
      if(cs.subType?.includes("inTmpOk")){msg="inTemp OK"}
      if(cs.subType?.includes("Timeout")){msg="Timeout"}
//       let msg=cs.body
//       if(msg?.length>40){msg=msg.substring(0,37)+"..."}
      return(
        <tr key={i}>
        <td width="50">{dispTime}</td>
        <td>{msg}</td>
        </tr>
      )
    })
    return(
      <div style={{marginTop:10,width:280,height:150,borderStyle:"solid",
        borderWidth:1,padding:"0px 5px",borderRadius:5,overflowY:"auto"}}
      >
      <table><tbody>
        {rows}
      </tbody></table>
      <div ref={this.csRef}/>
      </div>
    )
  }

  showSelectUdp=()=>{
    let st=this.state
    var opts
    if(st.udpId!=null){
      opts=[...Array(4)].map((k,i)=>{return {v:i|0x100,t:`udp${az(i,2)}`}})
    }else{
      opts=[{v:-1,t:"reading"}]
    }
//     cl(opts)
//     cl(this.lastSync)
//     let curUdp=(st.udpId)?st.udpId:+this?.lastSync?.server?.substring(3,5)||0
//     cl(curUdp)
//     let
    return(
      <div>
        <C18Select01 parms={{
          label:"Select Udp",
          valueName:"udpId",
          udpId:(st.udpId||0)|0x100,
          opts:opts,
          onChange:(e,v)=>{this.onChange("newUdp",v)}//this.onChange,
        }}/>
        <C18Button00 type="button" className="filled"
          onClick={e=>{this.onChange("setUdp")}}
        >Set</C18Button00><br/>
        <br/>
      </div>

    )

  }

  showSelectedInfo=(type)=>{
// this is for the one item currently selected
    let tiers={locked:"Locked",unlocked:"UnLocked"}
    let st=this.state
    var created
    switch(type){
      case "account":
        let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
        let owner=st.users.filter(u=>{return u.userId==selAccount.owner})[0]||{}
        created=(selAccount.created)?this.ts2str(selAccount.created):"(unknown)"
        return(
          <div>
            <h4>{selAccount.name}</h4>
            <table><tbody>
            <tr><td>ID:</td><td>{selAccount.accountId||"No ID"}</td>
              <td>
                <button
                type="button"
                className="material-icons-outlined copy-button"
                title="Copy"
                onClick={() => this.onChange('copyValue', selAccount.accountId)}
                >
                content_copy
              </button>
              </td>
            </tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr><td>Email:</td><td>{selAccount.adminEmail}</td></tr>
            <tr><td>Owner:</td><td>{owner.name}</td></tr>
            </tbody></table>
            <div style={{borderStyle:"solid",borderWidth:1,borderRadius:10,
              padding:10
            }}>
              <h4>Feature Flags</h4>
              {this.showFeatureFlags(selAccount)}
              <div className="clearfloat"></div><br/>
              <C18Button00 type="button" className="filled"
              onClick={e=>this.onChange("saveFeatures")}
              >
              Save</C18Button00>
            </div>
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteAccount")}}
            >Delete</C18Button00>
          </div>
        )
      case "user":
        let selUser=st.users.filter(u=>{return u._id==st.id})[0]
//         cl(selUser)
        if(!selUser){
          selUser=st.users.filter(u=>{return u.userId==st.selectedId})[0]// needed for selections from system check
        }
        let activated=(!selUser.token)
        let invited=!!selUser.accountId
        let pw=st.resetPW
        created=(selUser.created)?this.ts2str(selUser.created):"(unknown)"

        let uAccount=st.accounts.filter(a=>{return a.accountId==selUser.accountId})[0]
        // cl(uAccount)
        // cl(selUser)
        let isOwner=selUser.userId==uAccount?.owner
        // cl(isOwner)
//             <tr><td>Acct ID:</td><td
//               style={{cursor:"pointer"}}
//               onClick={e=>this.onChange("showUserAccountId",{userAccountId:selUser.accountId})}
//             >
//             {this.showUserAccountId(selUser)}
//             </td></tr>
        return(
          <div>
            <h4>{selUser.name}</h4>
            <table><tbody>
            <tr><td>ID:</td><td>{selUser.userId}</td>
            <td>
                <button
                type="button"
                className="material-icons-outlined copy-button"
                title="Copy"
                onClick={() => this.onChange('copyValue', selUser.userId)}
                >
                content_copy
              </button>
              </td>
            </tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            {this.showCustomSaveField(this.saveFields[1])}
            <tr><td>Email:</td><td>{selUser.email}</td></tr>
            <tr><td>Active:</td><td>{(selUser.active)?"Yes":"No"}</td></tr>
            <tr><td>Activated:</td><td>{(activated)?"Yes":"No"}</td></tr>
            {!activated&&
              <tr><td>Invited:</td><td>{(invited)?"Yes":"No"}</td></tr>
            }
            <tr><td>Owner:</td><td>{(isOwner ? "Yes":"No")}</td></tr>
            {(pw!="")&&
              <tr><td>Password:</td><td>{pw}</td></tr>
            }
            </tbody></table>
            {activated?
              <>
                <div className="clearfloat"/><br/>
                <C18Button00 type="button" className="filled"
                  onClick={e=>{this.onChange("resetPassword")}}
                >Reset Password</C18Button00>
              </>:
              <>
                <div className="clearfloat"/><br/>
                <C18Button00 type="button" className="filled"
                  onClick={e=>{this.onChange("activateUser")}}
                >Activate</C18Button00>
              </>
            }
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteUser")}}
            >Delete</C18Button00>
          </div>
        )
      case "site":
        let selSite=st.sites.filter(s=>{return s.siteId==st.selectedId})[0]
        created=(selSite.created)?this.ts2str(selSite.created):"(unknown)"
        let wsZone=st.zones.filter(z=>{return z.zoneId==selSite.weatherStation})[0]
//         cl(selSite)
//         cl(st)
        return(
          <div>
            <h4>{selSite.name}</h4>
            <table><tbody>
            <tr><td>ID:</td><td>{selSite.siteId}</td>
            <td>
                <button
                type="button"
                className="material-icons-outlined copy-button"
                title="Copy"
                onClick={() => this.onChange('copyValue', selSite.siteId)}
                >
                content_copy
              </button>
              </td>
            </tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr><td>Account ID:</td><td>{selSite.accountId}</td></tr>
            <tr><td>Weather St:</td><td>{wsZone?.zoneName||"None"}</td></tr>
            </tbody></table>
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteSite")}}
            >Delete</C18Button00>
            {this.showIGrow()}
          </div>
        )
      case "gateway":
        let selGateway=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.selectedId})[0]||{}
        let zones=st.zones.filter(z=>{return z.gatewayId==st.selectedId})
        zones.sort((a,b)=>{
          if(a.gatewayZoneId>b.gatewayZoneId){return 1}
          if(a.gatewayZoneId<b.gatewayZoneId){return -1}
          return 0
        })
        created=(selGateway.created)?this.ts2str(selGateway.created):"(unknown)"
        var mqttContact
        let mcInfo=this.state.mqttContacts[selGateway.clientId]
        if(mcInfo){
          let now=getTimeI()
          let lastComm=`${now-mcInfo.ts} seconds ago`
          mcInfo=(
            <>
              <tr><td>Last MQTT:</td><td>{lastComm}</td></tr>
              <tr><td>Server:</td><td>{mcInfo.server}</td></tr>
              <tr><td>Database:</td><td>{mcInfo.database}</td></tr>
              <tr><td>Clients:</td><td>{mcInfo.clients||"none"}</td></tr>
            </>

          )
//           cl(mcInfo)
        }
        if(st.resetTime){
          var resetMsg
          let now=getTime()
          let relTime=st.resetTime-now
          if(relTime>-10){
            resetMsg=`Reset in ${Math.floor(relTime)} secs`
            if(!this.resetTimer){
              this.resetTimer=setInterval(this.updResetTime,1000)
            }
          }else{
            resetMsg="Controller Offline"
            setTimeout(e=>{this.setState({resetTime:0})},5000)
          }
//           this.setState({resetMsg:resetMsg})

        }
        return(
          <div>
            <h4>{selGateway.name}</h4>
            <table><tbody>
            <tr><td>GW ID:</td><td>{selGateway.gatewayId}</td>
            <td>
                <button
                type="button"
                className="material-icons-outlined copy-button"
                title="Copy"
                onClick={() => this.onChange('copyValue', selGateway.gatewayId)}
                >
                content_copy
              </button>
            </td>
            </tr>
            <tr><td>Proc ID:</td><td
            style={{fontSize:11}}
            >{selGateway.clientId}</td>
            <td>
                <button
                type="button"
                className="material-icons-outlined copy-button"
                title="Copy"
                onClick={() => this.onChange('copyValue', selGateway.clientId)}
                >
                content_copy
              </button>
            </td>
            </tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr><td>Last Comm:</td><td>{this.ts2str(selGateway.updateTime)}</td></tr>

            <tr><td>Account ID:</td>
              <td>{this.showViewEdit(selGateway,type,"accountId")}</td>
            </tr>
            <tr><td>Site ID:</td>
              <td>{this.showViewEdit(selGateway,type,"siteId")}</td>
            </tr>

            <tr><td>Last IP:</td><td>{selGateway.ip||"None"}</td></tr>
            <tr><td>FW Version:</td><td>{selGateway.fwVersion||"None"}</td></tr>
            {zones.map((z,i)=>{
              return(
                <tr key={"z"+i}><td>{`Zone ${z.gatewayZoneIndex}:`}</td>
                <td>{`to Site: ${z.siteZoneIndex}`}</td></tr>
              )
            })}
            {mcInfo}
            </tbody></table>
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteGateway")}}
            >Delete</C18Button00>
            &nbsp;
            <C18Button00 type="button" className="filled"
              onClick={e=>{this.onChange("testPage")}}
            >Test Page</C18Button00><br/>
            <div className="clearfloat"></div><br/>
            {this.showSelectUdp()}

            <C18Select01 parms={{
              label:"Select Reset",
              valueName:"contReset",
              contReset:st.contReset,
              opts:[
              {v:"cnreset",t:"Controller"},
              {v:"fdreset",t:"Factory Def"},
              {v:"clreset0",t:"MQTT-Lwip"},
              {v:"clreset1",t:"MQTT-Cont"},
              {v:"clreset2",t:"UDP-Lwip"},
              {v:"clreset3",t:"UDP-Cont"},
              {v:"clreset4",t:"Net Restart"},
              {v:"mbreset0",t:"MB Reset"},
              ],
              onChange:(e,v)=>{this.onChange("contReset",v)}//this.onChange,
            }}/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("resetGateway")}}
            >Reset</C18Button00><br/>

            <div style={{display:(st.resetTime)?"block":"none",
              border:"1px solid",borderRadius:10,marginTop:10,padding:5}}>
            {resetMsg}
            </div>
            {this.controllerState()}
          </div>
        )
      case "zone":
        let selZone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
//         cl(selZone)
//         let tier=selZone.zoneTier
//             <td>{selZone.siteZoneIndex}</td>
//             <td>{selZone.zoneId}</td>
        created=(selZone?.created)?this.ts2str(selZone?.created):"(unknown)"
//         cl(selZone)
        return(
          <div>
            <h4>{selZone?.zoneName}</h4>
            <table><tbody>
            <tr><td>Namex:</td>
            <td>{this.showViewEdit(selZone,type,"zoneName")}</td>
            </tr>

            <tr><td>ID:</td><td>{selZone?.zoneId}</td></tr>

            <tr><td>Account ID:</td>
              <td>{this.showViewEdit(selZone,type,"accountId")}</td>
            </tr>

            <tr><td>Site ID:</td>
              <td>{this.showViewEdit(selZone,type,"siteId")}</td>
            </tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr style={{cursor:"pointer"}}
              onClick={e=>this.onChange("toggleDeleteZone")}
            ><td
            >Deleted:</td><td>{selZone?.deleted?"True":"False"}</td></tr>
            <tr><td>GW Index:</td><td>{selZone?.gatewayZoneIndex}</td></tr>

            <tr><td>Site Index:</td>
            <td>{this.showViewEdit(selZone,type,"siteZoneIndex")}</td>
            </tr>

            <tr><td>Gateway ID:</td><td>{selZone?.gatewayId}</td></tr>
            <tr><td>Remote IP:</td><td>{selZone?.remoteIp}</td></tr>
            <tr><td>Last ZData:</td><td>{st.zoneLastData}</td></tr>
            <tr><td>Connected:</td><td>{(selZone?.connected)?"Yes":"No"}</td></tr>
            <tr><td>Sub Tier:</td><td>{tiers[selZone?.zoneTier]||"None"}</td></tr>
            <tr><td>Virtual:</td><td>{(selZone?.virtual)?"Yes":"No"}</td></tr>
            </tbody></table>
            <div className="clearfloat"/><br/>
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteZone")}}
            >Delete</C18Button00>
            {this.showBrowseDB()}
          </div>
        )
    }
  }

  showViewEdit=(val,type,field)=>{
    let st=this.state
    if((st.editType==type)&&(st.editField==field)){
      let sty={borderRadius:0,padding:0,width:150}
      return(
        <input type="text" style={sty}
          value={val[field]||""}
          onChange={e=>this.onChange("valEdit",{val:e.currentTarget.value})}
        />
      )
    }else{
      let vf
      if(val) {
        vf=val[field]
      }
//       cl(val,field,vf)
//       cl(vf!="", vf, vf==0)
//       let txt=((vf!="")&&(vf||(vf==0)))?vf:"(none)"
      let txt=(vf||(vf===0))?vf:"(none)"
//       let txt=val[field]||"(none)"
      return(
        <span onClick={e=>this.onChange("viewEdit",{val:val,type:type,field:field})}>
          {txt}
        </span>
      )
    }
//
  }

  showAccounts=()=>{
    let st=this.state
//     cl(st)
//     let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
    let type="account"
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"accounts"})}}
      >Accounts</h3>
      {(st.searchRes?.account?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relAccounts.length>0)&&
            this.showSelectType(st.relAccounts,type)
        )
      }
      </div>
    )
  }

//   showRelated=(rel)=>{
//     return(
//       <div style={{width:"100%",height:200,backgroundColor:"#CCCCFF",
//         overflowY:"auto"
//       }}>
//       <table><tbody>
//       {rel.map((r,i)=>{
//         return(
//           <tr key={i}><td>{r.t}</td></tr>
//         )
//       })}
//       </tbody></table>
//       </div>
//     )
//   }

  showUsers=()=>{
    let st=this.state
//     cl(st)
    let selUser=st.users.filter(u=>{return u.userId==st.selectedId})[0]
    let type="user"
//       {(st.searchRes?.user?.length)?
//         this.showSearchBox("user"):
//       (st.selectedType=="user")?
//         <>
//           <h4>{selUser.name}</h4>
//         </>:
//         (st.relUsers.length>0)&&
//           this.showSelectType(st.relUsers,"user")
//       }
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"users"})}}
      >Users</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relUsers.length>0)&&
            this.showSelectType(st.relUsers,type)
        )
      }
      </div>
    )
  }

  showSites=()=>{
    let st=this.state
//     cl(st)
    let selSite=st.sites.filter(s=>{return s.siteId==st.selectedId})[0]
    let type="site"
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"sites"})}}
      >Sites</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relSites.length>0)&&
            this.showSelectType(st.relSites,type)
        )
      }
      </div>
    )
  }

  showGateways=()=>{
    let st=this.state
    let selGateway=st.gateways/*.gw*/.filter(g=>{return g.gatewayId==st.selectedId})[0]
    let type="gateway"
    return(
      <div style={{width:300,minHeight:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"gateways"})}}
      >Gateways</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relGateways.length>0)&&
            this.showSelectType(st.relGateways,type)
        )
      }
      </div>
    )
  }

  showZones=()=>{
    let st=this.state
//     cl(st)
    let selZone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
    let type="zone"
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"zones"})}}
      >Zones</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relZones.length>0)&&
            this.showSelectType(st.relZones,type)
        )
      }
      </div>
    )
  }

  showDebug=()=>{
    let st=this.state
//     cl(st)
    let selZone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
    let type="zone"
    return(
      <div style={{width:300,height:600,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:"0px 10px 20px 10px",
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"zones"})}}
      >Debug</h3>
      <input type="text"
        value={st.debugText}
        onChange={e=>this.onChange("debugText",{debugText:e.currentTarget.value})}
      />
      <button type="button"
      style={{borderStyle:"solid",borderWidth:1,borderRadius:10,padding:10,marginTop:10}}
      onClick={e=>this.onChange("debugClick")}
      >Send</button>
      <button type="button"
      style={{borderStyle:"solid",borderWidth:1,borderRadius:10,padding:10,
        marginTop:10,marginLeft:10}}
      onClick={e=>this.onChange("logClick")}
      >Log</button>
      <div
      style={{borderStyle:"solid",borderWidth:1,borderRadius:10,height:300,marginTop:10,
        overflowY:"auto",overflowX:"hidden"
      }}
      >{(JSON.stringify(st.debugResp)||"")
        .replace(/,/g,/, /)
        .replace(/:/g,/: /)
      }
      </div>
      </div>
    )
  }

  markImage=async(e)=>{

    cl(e.target.files)
    let files=e.target.files[0]
    let data = new FormData()
    data.append("type", "fwImage")
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', files)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl("post image")
    cl(url,method,data,type)
//     var ret
    let ret=await doGetPostBasic(url, method, data, type)
    cl(ret)
    this.setState({uploadFwResult:"Upload OK!"})
    setTimeout(e=>this.setState({uploadFwResult:"Upload Firmware Image"}),5000)
//     let ret2 = await ret.json()
//     cl(ret2)
//     this.onChange("avatar",{avatar: ret2.avatar})
//     this.setState({avatar: ret2.avatar})
  }



  showFwUpload=()=>{
    let st=this.state
//     cl("show")
//       <C18Input00 type="file"  onChange={this.markImage} style={{
//         position:"absolute",
//         width:103,
//         height:103,
//         marginTop:0,
//         marginLeft:0,
//         zIndex:10,
//         opacity:0,
//         cursor: "pointer",
//       }}/>
//         <br/>
//         <button
//           type="button"
//           onClick={e=>this.onChange("sendFwFile")}
//           style={{padding:"2px 5px",borderStyle:"solid", borderWidth:1, borderRadius:0,backgroundColor:"#EEEEEE",marginTop:5}}
//         >Send File</button>
    let color=(st.uploadFwResult=="Upload Firmware Image")?"#404040":"green"
    return(
      <div style={{height:100}}>
      <br/>
      <div>
        <form style={{float:"auto"}}
        className="file-upload-form">
        <label style={{color:color}}>{st.uploadFwResult}</label>
        <input style={{width:"auto",height:"auto",marginTop:0,zIndex:"auto",opacity:1,
          position:"static",cursor:"auto"}}
          type="file"
          onChange={this.markImage}
          />
          </form>
        </div>
      </div>
    )
  }

/****************************** Sanity Checks *****************************************/

  doLink=(vals)=>{
// vals sends the 'type' (gateway), and the gatewayId to select it
    cl(vals)
    switch(vals.type){
      case "account":
        this.onChange("accountSel",{accountSel:vals.accountId})
        break
      case "site":
        this.onChange("siteSel",{siteSel:vals.siteId})
        break
      case "gateway":
        this.onChange("gatewaySel",{gatewaySel:vals.gatewayId})
        break
      case "zone":
        this.onChange("zoneSel",{zoneSel:vals.zoneId})
        break
      case "user":
        this.onChange("userSel",{userSel:vals.userId})
        break
    }
  }

  checkUnique=(arr,idName,typeName,typeChange,lines)=>{
//     cl(arr)
    let ids={}
    arr.forEach(ac=>{
      let id=ac[idName]
      if(ids[id]){
        ids[id]+=1
      }else{
        ids[id]=1
      }
    })
    let dups=Object.keys(ids).filter(id=>{
      return (ids[id]>1)/* || (id=="JY8N0-CyDE4gUdhy")*/
    })
//     cl(dups)
    dups.forEach(du=>{
      let vals={type:typeChange}
      vals[idName]=(du=="undefined")?undefined:du
      let errLine=(
        <span>{typeName} <Link style={{color:"blue"}} to=""
          onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
        >{du}</Link> has {ids[du]} entries</span>
      )
      lines.push(
        <div key={lines.length}>
          {errLine}<br/>
        </div>
      )
    })
  }

  checkAllUnique=(st,lines)=>{
//     cl(st)
    this.checkUnique(st.accounts,"accountId","Account","account",lines)
    this.checkUnique(st.sites,"siteId","Site","site",lines)
    this.checkUnique(st.gateways/*.gw*/,"gatewayId","Gateway","gateway",lines)
    this.checkUnique(st.zones,"zoneId","Zone","zone",lines)
    this.checkUnique(st.users,"userId","User","user",lines)
  }

  checkUndefined=(arr,idName,fields,typeName,typeChange,lines)=>{
    arr.forEach(ar=>{
      let id=ar[idName]||"undefined"
      let ok=true
      fields.forEach(f=>{
        ok=ok&&(ar[f])
      })
      if(!ok){
        let vals={type:typeChange}
        vals[idName]=(id=="undefined")?undefined:id
        let errLine=(
          <span>{typeName} <Link style={{color:"blue"}} to=""
            onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
          >{id}</Link> has undefined fields</span>
        )
        lines.push(
          <div key={lines.length}>
            {errLine}<br/>
          </div>
        )

      }
    })

  }

  checkAllUndefined=(st,lines)=>{
// checks the fields in the array to see if they're "undefined"
    this.checkUndefined(st.accounts,"accountId",["accountId","name"],"Account","account",lines)//,"adminEmail","owner"
    this.checkUndefined(st.sites,"siteId",["accountId","siteId","name"],"Site","site",lines)//,"adminEmail","owner"
    this.checkUndefined(st.gateways/*.gw*/,"gatewayId",["gatewayId","siteId","accountId","name","uId"],"Gateway","gateway",lines)
    this.checkUndefined(st.zones,"zoneId",["zoneId","gatewayId","siteId","accountId","zoneName"],"Zone","zone",lines)
    this.checkUndefined(st.users,"userId",["accountId","email","name"],"User","user",lines)
  }

  checkReferences=(arr,idName,fields,typeName,typeChange,lines)=>{
// check the field in the array of fields to see if they're real sites, accounts, etc.'
/* for 'siteId, we're checking the sites table to see that all the sites
 refer to accountIds that actually exist,
 so, ar is an item from the sites table
 ar[f]is the accountId of that entry*/
//     cl(this.state)
    let lu=this.state.lookUps
//     cl(lu)
    arr.forEach(ar=>{
      fields.forEach(f=>{
        let id=ar[f]// the accountId of the entry in sites
        let tab=lu[f]// the accountId lookup table
        if(!tab[id]){// missing - make the entry to bring up this site
          let vals={type:typeChange}
          let id2=ar[idName]
//           cl(ar)
          vals[idName]=(id2=="undefined")?undefined:id2
          let errLine=(
            <span>{typeName} <Link style={{color:"blue"}} to=""
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id2}</Link> has nonexistent {f}</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        }
//         cl(key)
//         cl(tab)
      })
    })
  }

  checkDuplicateZoneSiteIndexes=(st,lines)=>{
    let keys={}
    st.zones.forEach(z=>{
      let key=`${z.siteId}_${z.siteZoneIndex}`
      if(!keys[key])
        {keys[key]=[z]}else
        {keys[key].push(z)}// so keys is an array of the zones that have that siteZoneIndex
    })
    Object.keys(keys).forEach(k=>{
      if(keys[k].length>1){
        keys[k].forEach(z2=>{
          let vals={type:"zone"}
          let id=z2.zoneId
          vals["zoneId"]=(id=="undefined")?undefined:id
          let errLine=(
            <span>Zone <Link style={{color:"blue"}} to=""
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id}</Link> has duplicate SiteIndex {k}</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        })

      }
    })
  }

  checkZoneAccountReferences=(st,lines)=>{
    let gwLu=st.lookUps.gatewayId
    let siLu=st.lookUps.siteId
    st.zones.forEach(z=>{
      if(z.gatewayId && z.siteId){// has valid gateway and site IDs
        let gw=gwLu[z.gatewayId]
        let si=siLu[z.siteId]
//         cl(z)
//         cl(gw)
//         cl(si)
        if((gw&&si)&&((z.accountId!=gw.accountId)||(z.accountId!=si.accountId))){
          let vals={type:"zone"}
          let id=z.zoneId
          vals["zoneId"]=(id=="undefined")?undefined:id
          let errLine=(
            <span>Zone <Link style={{color:"blue"}} to=""
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id}</Link> has site or gw account error</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        }
      }

    })
  }

  checkGatewayAccountReferences=(st,lines)=>{
//     let gwLu=st.lookUps.gatewayId
    let siLu=st.lookUps.siteId
    st.gateways/*.gw*/.forEach(gw=>{
      if(gw.siteId){// has valid gateway and site IDs
        let si=siLu[gw.siteId]
//         cl(gw)
//         cl(si)
        if((gw&&si)&&((gw.accountId!=si.accountId))){
          let vals={type:"gateway"}
          let id=gw.gatewayId
          vals["gatewayId"]=(id=="undefined")?undefined:id
          let errLine=(
            <span>Gateway <Link style={{color:"blue"}} to=""
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id}</Link> has site account error</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        }
      }

    })
  }

  checkZoneSiteIndexes=(st,lines)=>{
    st.zones.forEach(z=>{
      let sziOk=((z.siteZoneIndex==0)||(z.siteZoneIndex>0))&&(z.siteZoneIndex<100)
      if(!sziOk){
        let vals={type:"zone"}
        let id=z.zoneId
        vals["zoneId"]=(id=="undefined")?undefined:id
        let errLine=(
          <span>Zone <Link style={{color:"blue"}} to=""
            onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
          >{id}</Link> has siteZoneIndex error</span>
        )
        lines.push(
          <div key={lines.length}>
            {errLine}<br/>
          </div>
        )
      }
    })
  }

  checkAllReferencesExist=(st,lines)=>{
//     this.checkReferences(st.accounts,"accountId",["accountId","name"],"Account","account",lines)//,"adminEmail","owner"
    this.checkReferences(st.sites,"siteId",["accountId"],"Site","site",lines)
    this.checkReferences(st.gateways/*.gw*/,"gatewayId",["accountId","siteId"],"Gateway","gateway",lines)
    this.checkReferences(st.zones,"zoneId",["accountId","siteId","gatewayId"],"Zone","zone",lines)
    this.checkReferences(st.users,"userId",["accountId"],"User","user",lines)
  }

  showSystemReport=()=>{
    let st=this.state
    let lines=[]// this is where the report will be put
    this.checkAllUnique(st,lines)
    this.checkAllUndefined(st,lines)
    this.checkAllReferencesExist(st,lines)
    this.checkDuplicateZoneSiteIndexes(st,lines)
    this.checkZoneAccountReferences(st,lines)
    this.checkGatewayAccountReferences(st,lines)
    this.checkZoneSiteIndexes(st,lines)
    return(
      <>
      <input type="checkbox"
        checked={st.showSystemReport}
        onChange={e=>this.onChange("upd",{showSystemReport:e.target.checked})}
      />
      <h3 style={{marginLeft:10,display:"inline-block"}}>Show System Report</h3>
      <input type="checkbox"
        style={{marginLeft:20}}
        checked={st.disableCache}

        onChange={e=>this.onChange("disableCache",{disableCache:e.target.checked})}
      />
      <h3 style={{marginLeft:10,display:"inline-block"}}>Disable Caching</h3>
      {st.showSystemReport&&
        <div style={{width: 1000, borderStyle:"solid", borderWidth:1,borderRadius:10,padding:10}}>
        {lines.map(li=>{return li})}
        </div>
      }
      </>
    )
  }

/****************************** End Sanity Checks *****************************************/

  showGatewayDelete=()=>{
    let st=this.state
    let sects=[
      {t:"Mongo auth_gw",v:"auth_gw"},
      {t:"Mongo usa_gw",v:"usa_gw"},
    ]
    let style={backgroundColor:"#EEEEFF",display:"inline-block"}
    return(
      <div className="popup" style={style}>
      {sects.map((s,i)=>{
        return(
          <div key={i}>
            <input type="checkbox"
              checked={st.gwDel[s.v]||false}
              onChange={e=>{this.onChange("gwDel",{v:s.v,val:e.currentTarget.checked})}}
            />
            <label className="left-margin" style={{display:"inline-block"}}>
            {s.t}</label><br/>
          </div>
        )
      })}
      <button type="button" className="outlined">OK</button>
      <button type="button" className="filled left-margin">Cancel</button>
      </div>
    )
  }

  showTechPortal=()=>{
    let st=this.state
    return(
        <div
        >
        <h2 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"techPortal"})}}
        >Tech Portal</h2>
        <div className="clearfloat"></div><br/>
        {this.showSystemReport()}
        <br/>
        <label>Search</label>
        <input type="text"
          value={st.oneSearch}
          onChange={e=>this.onChange("updSearch",{oneSearch:e.currentTarget.value})}
        />
        {this.showAccounts()}
        {this.showUsers()}
        {this.showSites()}
        {this.showGateways()}
        {this.showZones()}
        {this.showDebug()}
        {this.showFwUpload()}
        {/*this.showGatewayDelete()*/}
        </div>
    )
  }

  scanMqttClients=(log)=>{
//     cl(log)
    let clients={}
    log.forEach(l=>{
      clients[l.top[2]]=1
    })
    return Object.keys(clients)
  }

  showClientSelectO=()=>{
    let st=this.state
    let opts=st.mqttClients.map((c,i)=>{return({v:c,t:c})})
    return(
      <C18Select01 parms={{
        label:"Select Client",
        valueName:"mqttClient",
        mqttClient:st.mqttClient,
        opts:opts,
        onChange:(e,v)=>{this.onChange("mqttClient",v)}//this.onChange,
      }}/>
    )
  }

  showClientSelect=()=>{
    let st=this.state
//     cl(st)
    return(
      <div>
      <label>Client ID</label>
      <input type="text"
        value={st.dbgLogClientId}
        onChange={e=>{this.onChange("mqttClient",{dbgLogClientId:e.currentTarget.value})}}
      /><br/>
      </div>
    )

  }

  showDateTimeSelect=()=>{
    let st=this.state
    let bStyle={borderStyle:"solid",borderWidth:1,borderRadius:0,padding:2,marginLeft:5}
    let val=dateToDisplayDate(new Date(1000*st.mqttLogTime),"yyyy-mm-ddThh:mm")
//         &nbsp;<input id="liveCheck" type="checkbox" style={{
//           height:22,width:22,transform:"none",verticalAlign:"middle"}}
//           checked={st.liveChecked}
//           onChange={e=>this.onChange("liveCheck",{val:e.currentTarget.checked})}/>
//         &nbsp;<label htmlFor="liveCheck" style={{display:"inline-block"}}>Live</label>
    return(
      <div>
        <label>Select Time</label>
        <input type="datetime-local"
          value={val}
          onChange={e=>this.onChange("mqttLogTime",{mqttLogTime:e.currentTarget.value})}
        />
        <button type="button" style={bStyle}
          onClick={e=>this.onChange("dTime",{dif:-3600})}>&lt;&lt;</button>
        <button type="button" style={bStyle}
          onClick={e=>this.onChange("dTime",{dif:-60})}>&lt;</button>
        <button type="button" style={bStyle}
          onClick={e=>this.onChange("dTime",{dif:60})}>&gt;</button>
        <button type="button" style={bStyle}
         onClick={e=>this.onChange("dTime",{dif:3600})}>&gt;&gt;</button>
      </div>
    )
  }

  dfProgress=(progressEvent)=>{
    globs.events.publish("progress",{prog:progressEvent.loaded/progressEvent.total})
  }

  downloadFileWithProgress=(url, method, data, type)=>{
    cl(url,method,type)
//     cl(data)
    return new Promise((r,e)=>{
      let xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onprogress=this.dfProgress
//       xhr.upload.addEventListener("progress", this.dfProgress)
//       xhr.responseType="json"
      xhr.onload=()=>{
        this.setState({progressLoaded:0,progressTotal:0})
        globs.events.publish("progress",{prog:-1})
        r(xhr.response)
      }
      xhr.send(data);
    })

  }

  loadMqttLog=async(ts=this.state.mqttLogTime)=>{
//     ts=Math.floor(ts)
//     cl(ts)
//     this.mqttLogNextTime=ts
//     if(this.mqttLogBusyTimer){return}// if busy, save time for next
//     this.mqttLogBusyTimer=setTimeout(x=>{// set timer, and get log if new time
// //       cl("timeout")
//       this.mqttLogBusyTimer=null
// //       cl(this.mqttLogThisTime, this.mqttLogNextTime)
// //       cl(this.mqttLogThisTime!=this.mqttLogNextTime)
//       if(this.mqttLogThisTime!=this.mqttLogNextTime){// if we have updated time
// //         cl("get another")
//         this.loadMqttLog(this.mqttLogNextTime)
//       }
//     },5000)// 5 seconds
//     cl("getting mqtt log")
    let st=this.state
    let now=getTime()
    let liveMode=st.liveMode
    if(Math.abs(now-ts)>60){liveMode=ts>now}
//     cl(liveMode)
    var fromTime,sortLog
    if(liveMode){
      if(ts-this.mqttLogThisTime<60){
        fromTime=this.mqttLogThisTime-(this.mqttLogThisTime%60)// round down to minute
      }else{
        fromTime=ts-2*3600
      }
      setTimeout(e=>this.loadMqttLog(now+10),5000)

    }
    this.mqttLogThisTime=ts// this is the time that will be current
//     cl(this.mqttLogThisTime)
//     cl(st)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/mqttLog",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {clientId:st.dbgLogClientId,from:fromTime,to:ts}})
    if(liveMode){
      sortLog=st.mqttLog.filter(en=>{return en.t<fromTime}).concat(res.data)
      .slice(0-st.mqttLog.length)
    }else{
      sortLog=res.data
    }
//     cl(res.data)
//     let sortLog=res.data
//     let sortLog=res.data.sort((a,b)=>{
//       if(a.t>b.t){return 1}
//       if(a.t<b.t){return -1}
//       return 0
//     })
    this.setState({mqttLog:sortLog,waitFlag:false,liveMode:liveMode})
    return
//     cl(res)
//
//     let name=`mqttLog${Math.floor(ts/1e5)}.txt`
//     if(this?.curMqttLogLoaded==name){return}
//     this.curMqttLogLoaded=name
//
//     let url=`${constant.expressUrl}/usa/${name}`
//     cl(url)
// //     let url=`http://http0.link4cloud.com:3115/usa/${name}`
//     let method="GET"
//     var data
//     let type="text/plain"
// //     let res=await doGetPostBasic(url, method, data, type)
// //     let txt=await res.text()
// //     let txt=await this.downloadFileWithProgress(url, method, data, type)
//     let txt=""
// //     cl(txt.substring(0,100))
// //     cl(txt)
//     let lines=txt.split("\n")
//     cl(lines.length)
//     let mqttLog=[]
//     lines.forEach(l=>{
// //       cl(l)
//       let f=l.split("\t")
//       if(f.length==3){
//         try{
// //           cl("push")
//           mqttLog.push({
//               t:(+Date.parse(f[0]+"Z"))/1000,
//               top:f[1].split("/"),
//               msg:JSON.parse(f[2]),
//           })
//         }catch{}
//       }
//     })
//     let mqttClients=this.scanMqttClients(mqttLog)
//     let vals={
//       mqttLog:mqttLog,
//       mqttClients:mqttClients,
//       mqttClient:mqttClients[0],
//     }
//     cl(vals)
//     this.setState(vals)
  }

  showOneMqttEntry=()=>{
    let st=this.state
    let ent=st.mqttLog[st.msgClick]
    if(!ent||typeof ent.msg=="string"){return null}
    if(!ent){return}
//     cl(ent)
    let dtFormat=dateToDisplayDate(new Date(1000*ent.t),"yyyy-mm-ddThh:mm")

//     cl(st)
//     cl(ent.msg)
    let cmd=this.cmdTypes[ent.msg.cmd]
//     cl(cmd)
    let cmdLine=`${cmd.t1} ${cmd.t2} ${cmd.t3}`
    let lines=[]
    let base=cmd.b;
    let gotData=["Report","Write"].includes(cmd.t1)
    var parm="",val="";
    (ent.msg?.p||[]).forEach((p,i)=>{
      parm=this.lc2[base+p.id]||""
//       cl(p,parm)
      val=(gotData)?`: ${p.d}`:""
      let str=`${parm}${val}`
//       cl(str)
      lines.push(<span key={i}>{str}<br/></span>)
    })
//       {`${cmdLine}, ${parm}${val}`}<br/>
    return(
      <div>
      {`At ${dtFormat}: ${cmdLine}`}<br/>
      {lines}
      </div>
    )
  }

  showMqttEntries=()=>{
//     cl("show")
//     cl("mqttLog: "+show("main"))//cl(`time: ${getTime()}`)
    let st=this.state
//     cl(st.mqttLog)
    let lines=[]
//     let ti=(+Date.parse(st.mqttLogTime))/1000
//     cl(st.mqttLogTime)
//     cl(ti)
    let cnt=0
    let oLine=st.mqttLog[0]
//     cl(oLine.top[2],st.mqttClient)
//     cl(st.mqttLog.length)
//     let start=(st.mqttLog.length<1000)?0:st.mqttLog.length-1000
    let start=0
    let len=st.mqttLog.length
    if(len>1000){len=1000}
    for(let i=start;i<len/*st.mqttLog.length*/;i++){
      let l=st.mqttLog[i]
      let da=new Date(l.t*1000)
      let ti=dateToDisplayDate(da,"hh:mm:ss")
      let ud=l.topic[0]
        let msg=JSON.stringify(l.msg)
        let bgColor=(i==st.msgClick)?"#DDEEEE":""
        lines.push(
          <tr key={i}><td>{`${ti} ${ud}`}</td>
          <td>
            <span style={{cursor:"pointer",backgroundColor:bgColor}}
              onClick={e=>this.onChange("mqttMsgClick",{msgClick:i})}
            >{msg}<br/></span>
          </td></tr>
        )
    }
//     st.mqttLog.forEach((l,i)=>{
//     })
//     cl("mqttLog: "+show("main"))//cl(`time: ${getTime()}`)
    return(

      <div style={{height:500,width:"100%",overflowX:"auto",//textOverflow:"ellipsis",
        whiteSpace:"nowrap"}}>
      <table><tbody>
        {lines}
      </tbody></table>
      </div>
    )
  }

  showMqttLog=()=>{
    return(
      <div>
      <h2>MQTT Log</h2>
      {this.showClientSelect()}
      {this.showDateTimeSelect()}<br/>
      {this.showMqttEntries()}<br/>
      {this.showOneMqttEntry()}
      </div>
    )
  }

  showLiveContDetails=()=>{
    let st=this.state
    let cont=st.allConts.filter(c=>{return c.gwId==st.contSel})[0]
    if(!cont){return}
    let accountId=cont.val.accountId
    let siteId=cont.val.siteId
//     cl(siteId)
    let ac=st.accounts.filter(a=>{return a.accountId==accountId})[0]
    let si=st.sites.filter(s=>{return s.siteId==siteId})[0]
    let contact=st.mqttContacts[cont.val.clientId]
//     cl(contact)
//     cl(si)
//     cl(ac)
//     cl(st)
//     cl(cont)
    let now=getTimeI()
    return(
      <div>
      <table><tbody>
      <tr><td width="100">Server</td><td>{cont.server}</td></tr>
      <tr><td>Account</td><td>{ac?.name||"No Account"}</td></tr>
      <tr><td>Site</td><td>{si?.name||"--"}</td></tr>
      <tr><td>GW Name</td><td>{cont.name}</td></tr>
      <tr><td>Client Id</td><td>{cont?.val?.clientId}</td></tr>
      <tr><td>Gateway Id</td><td>{cont.gwId}</td></tr>
      <tr><td>FW Version</td><td>{cont?.val?.fwVersion}</td></tr>
      <tr><td>Clients</td><td>{cont?.val?.clients?.length||0}</td></tr>
      <tr><td>Contact</td><td>{now-contact?.ts||"--"} seconds ago</td></tr>
      <tr><td>Zones</td><td>{Object.keys(cont?.val?.zoneMap).length}</td></tr>
      <tr><td>State</td><td>{cont?.val?.state}</td></tr>
      </tbody></table>
      </div>
    )
  }

  showLiveContSelect=()=>{
    var showHeader=()=>{
      let sort=this.state.sortMode// 1-5 for the columns
      let icons=[]
      for(let i=0;i<5;i++){
        let icon=""
        if(Math.abs(sort)==i+1){
          icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
        } else{
          icons.push("")
        }
      }
      return(
        <tr>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sortMqttLive",{column:1})}
          >Name <span className="material-icons-outlined">{icons[0]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sortMqttLive",{column:2})}
          >Type <span className="material-icons-outlined">{icons[1]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sortMqttLive",{column:3})}
          >Server <span className="material-icons-outlined">{icons[2]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sortMqttLive",{column:4})}
          >ClientId <span className="material-icons-outlined">{icons[3]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sortMqttLive",{column:5})}
          >Account <span className="material-icons-outlined">{icons[4]}</span></button></th>
        </tr>
      )
    }
    let st=this.state
//     cl(st)
    let lines=st.allConts.map((c,i)=>{
      let ac=st.accounts.filter(a=>{return a.accountId==c.val.accountId})[0]
      var bgColor
      if(c.gwId==st.contSel){bgColor="#C0E0FF"}
      return(
        <tr key={i} style={{cursor:"pointer",backgroundColor:bgColor}}
          onClick={e=>this.onChange("liveContClick",{contSel:c.gwId})}
        >
          <td>{c.name}</td>
          <td>{c.type}</td>
          <td>{c.server}</td>
          <td>{c.clientId?.slice(-6)}</td>
          <td>{c.account||""}</td>
        </tr>
      )
    })

    return(
      <div style={{height:200,borderStyle:"solid",borderWidth:1,
        borderRadius:10,padding:10,overflowY:"auto"}}
      >
      <table><tbody>
      {showHeader()}
      {lines}
      </tbody></table>
      </div>
    )
    return null
  }

  showMqttLive=()=>{
    return(
      <div>
      {this.showLiveContSelect()}<br/>
      {this.showLiveContDetails()}
      </div>
    )
  }

  showDbgContSelect=()=>{
    let st=this.state
    let opts=[]
    return(
      <C18Select01 parms={{
        label:"Select End Time",
        valueName:"endTime",
        endTime:st.endTime,
        opts:opts,
        onChange:(e,v)=>{this.onChange("mqttClient",v)}//this.onChange,
      }}/>
    )
  }

  showEndTime=()=>{
// 2023-06-07T05:36
    let st=this.state
//     cl(this.state)
    return(
      <div>
        <label>Ending Time</label>
        <input type="datetime-local"
        style={{padding:10,border:"1px solid #aaa", borderRadius:10}}
        value={st.endTime}
        onChange={e=>this.onChange("endTime",{endTime:e.currentTarget.value})}
        />
      </div>
    )
  }

  showClientId=()=>{
    let st=this.state
    return(
      <div>
        <label>Client ID</label>
        <input type="text"
        value={st.dbgLogClientId}
        onChange={e=>this.onChange("dbgLogClientId",{dbgLogClientId:e.currentTarget.value})}
        /><br/>
      </div>
    )
  }

  showDbgLogEntries=()=>{

    var gotTags=(msg)=>{
      if (!msg){return false}
      if(!tagList.length){return true}
      let tags=msg.split("\t")
      let res=tags.filter(t=>{return tagList.includes(t)})
      return res.length>0
//       cl(msg)
//       cl(res.length)
//       return true
    }

    let rows=<tr><td>row</td></tr>
    let st=this.state
//     cl(st)
//     cl(st.dbgLogEntries)
    let tagList=st.useTags.map(t=>{return t.value})
//     cl(tagList)
    rows=st.dbgLogEntries
    .filter(l=>{return gotTags(l.m)})
    .map((l,i)=>{
//       cl(l)
      let da=(new Date(1000*l.t))
//       cl(da)
      let ti=dateToDisplayDate(da,"hh:mm:ss.ddd")
      let msg=l.m||"(No Message)"
      return(
      <tr key={i}><td>{`${ti}: ${msg}`}</td></tr>
    )})

    return(
      <div>
        <label>Log Entries</label>
        <div ref={this.dbgLogRef}
          style={{height:400,overflowY:"auto",padding:20,borderRadius:10,border:"1px solid #aaa"}}
        >
        <table><tbody>
        {rows}
        </tbody></table>
        </div>
      </div>
            )
  }

  showTagSelect=()=>{
    let st=this.state
    let tags=Object.keys(st.tags).map(t=>{return {value:t,label:t}})
    tags=tags.sort((a,b)=>{
      if(a.value.toLowerCase()>b.value.toLowerCase()){return 1}
      if(a.value.toLowerCase()<b.value.toLowerCase()){return -1}
      return 0
    })
    //({t=>{return{value:t,label:t}}})
//     cl(st.useTags)
    return(
      <div>
        <label>Tags</label>
        <Select
          value={st.useTags}
          onChange={o=>{this.onChange("tagsEdit",{useTags:o})}}
          options={tags}
          isMulti={true}
          isClearable={false}
        />
      </div>
    )
  }

  showPerformance=()=>{
//     let mi=this.state.mongoInfo
    let pe=this.state.performance
//     cl(pe)
//     cl(mi)
    let rows=[]
    if(pe){
      rows=pe.map(p=>{
        return(
          <tr key={p.coll}><td>{p.coll}</td><td>{p.diffTime}</td></tr>
        )
      })
    }
    return(
      <div>
      <h3>Performance</h3>
      <div style={{width:400,height:300, border:"1px solid black", borderRadius: 10,
        padding:10,overflowY:"auto"
      }}>
      <table><tbody>
      {rows}
      </tbody></table>
      </div>

      </div>
    )
  }

  getSlowInfo=async(slowIndex)=>{
    let slow=this.state.slow[slowIndex]
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/mongoInfo",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body:{db:slow.db,slowTime:slow.tso}
    })
    this.setState({slowQuery:resp.data.slow})
//     cl(resp.data.slow.command["$truncated"])
//     let obj=JSON.parse(resp.data.slow.command["$truncated"])
//     cl(obj)
    cl(resp)
  }

  getPerformance=async(doRun)=>{
    cl("get performance")
    if(!doRun&&(this.state.pageType!="mongo")){return}
    let curTotals=this.mongoInfo.totals
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/mongoInfo",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body:{timeRange:this.state.slowTimeRange}
    })
//     cl(resp)
    let mongoInfo=resp?.data?.mongoInfo
    let totals=resp?.data?.top.totals
    let slow=resp?.data?.slow
//     cl(slow)
//     cl(totals)
    let arrTotal=[]
    Object.keys(totals).forEach(k=>{
      let total=totals[k].total
//       totals[k]=totals[k]=totals[k].total
      if(total?.time){
        let curTotal=curTotals[k]
        if(curTotals[k]){
          curTotals[k]={
            time:total.time,
            count:total.count,
            diffTime:total.time-curTotal.time,
            diffCount:total.count-curTotal.count,
          }
          arrTotal.push({
            coll:k,
            diffTime:curTotal.diffTime,
            diffCount:curTotal.diffCount,
          })
        }else{
          curTotals[k]={
            time:total.time,
            count:total.count,
            diffTime:0,
            diffCount:0,
          }
        }
      }
    })
    arrTotal=arrTotal.filter(t=>{return t.diffTime})
      .sort((a,b)=>{
        if(a.diffTime>b.diffTime){return -1}
        if(a.diffTime<b.diffTime){return 1}
        return 0
      })
      if(arrTotal.length==0){
        arrTotal=[{coll:"Collecting. . ."}]
      }
//     cl(arrTotal)
    this.setState({performance:arrTotal,slow:slow})
//     let tmp2=tmp
//     cl(tmp2)
//
//     cl(curTotals)
//     cl(mongoInfo.totals)
//     if(mongoInfo){
//       mongoInfo.performance=Object.keys(mongoInfo.totals)
//       .filter(k=>{return mongoInfo.totals[k].diff.time})
//       .sort((a,b)=>{
//         let total0=mongoInfo.totals[a]
//         let total1=mongoInfo.totals[b]
//         if(total0.diff.time>total1.diff.time){return -1}
//         if(total0.diff.time<total1.diff.time){return 1}
//         return 0
//       })
// //       cl(mongoInfo.performance)
//       this.setState({mongoInfo:mongoInfo})
//     }
      setTimeout(this.getPerformance,5000)
  }

  showSlowQueries=()=>{
    let st=this.state
    let slow=st.slow
    let labelStyle={display:"inline-block", paddingRight:20}
    let rows=[]
    if(slow){
      rows=slow.map((s,i)=>{
        let bgColor=(i==st.slowIndex)?"#88FFFF":"#FFFFFF"
        return(
          <tr key={i} onClick={e=>{this.onChange("selSlow",{slowIndex:i})}}
          style={{backgroundColor:bgColor}}>
          <td>{s.db}</td>
          <td>{s.coll}</td>
          <td>{s.millis}</td>
          <td>{s.tso}</td></tr>
        )
      })
    }
    var cmdRes
//     cl(st.slowQuery?.command)
    if(st.slowQuery?.op=="remove"){
      cmdRes=JSON.stringify((st.slowQuery?.command||{}).q)
    }else{
      cmdRes=(st.slowQuery?.command||{})["$truncated"]
    }
//     cl(cmdRes)

    return(
      <div>
          <h3>Slow Queries</h3>
          <div id="timeRange">
            <input type="radio" id="hour" name="timeRange" value="hour"
              checked={st.slowTimeRange=="hour"}
              onChange={e=>this.onChange("slowTimeRange",
                {slowTimeRange:e.currentTarget.value})}
            />
            <label htmlFor="hour" style={labelStyle}>Hour</label>
            <input type="radio" id="day" name="timeRange" value="day"
              checked={st.slowTimeRange=="day"}
              onChange={e=>this.onChange("slowTimeRange",
                {slowTimeRange:e.currentTarget.value})}
            />
            <label htmlFor="day" style={labelStyle}>Day</label>
            <input type="radio" id="week" name="timeRange" value="week"
              checked={st.slowTimeRange=="week"}
              onChange={e=>this.onChange("slowTimeRange",
                {slowTimeRange:e.currentTarget.value})}
            />
            <label htmlFor="week" style={labelStyle}>Week</label>
          </div>

        <table><tbody>
        <tr><td>
          <div style={{width:600,height:300, border:"1px solid black", borderRadius: 10,
            padding:10,overflowY:"auto",cursor:"pointer"
          }}>
          <table><tbody>
          {rows}
          </tbody></table>
          </div>
          </td><td>
          <div style={{width:400,height:300, border:"1px solid black", borderRadius: 10,
            padding:10,overflowY:"auto",cursor:"pointer"
          }}>
          op:{st.slowQuery?.op}<br/>
          {cmdRes}
          </div>
          </td></tr>
        </tbody></table>

      </div>
    )
  }

  showMongo=()=>{
    return(
      <div>
      {this.showPerformance()}
      {this.showSlowQueries()}
      </div>
    )
  }

  showDbgLog=()=>{
    return(
      <div>
      {this.showClientId()}
      {this.showEndTime()}<br/>
      {this.showTagSelect()}<br/>
      <button style={{padding:10,border:"1px solid #aaa",borderRadius:10}}
      type="button" onClick={e=>this.onChange("downloadDbg")}>
      Download a Day
      </button>
      <div className="clearfloat"></div><br/>
      {this.showDbgLogEntries()}
      </div>
    )
  }

  loadXBoards=async(zoneId)=>{
    let st=this.state
    if(this.xBoardsLoaded==zoneId){return}
    this.xBoardsLoaded=zoneId
    let zone=(st.zones.filter(z=>{return z.zoneId==zoneId})||[])[0]
    if(!zone){return}
//     cl(zone)
//     return
    let siteId=zone.siteId
    let zInd=zone.siteZoneIndex
//     cl(siteId,zInd)
    var getDb00c=async(query)=>{
//       cl(query)
      return await wsTrans("usa", {cmd: "cRest", uri: '/su/suDb00c',
        method: 'retrieve', sessionId: globs.userData.session.sessionId,
        body: {query:query}})
    }
    let mult=pInd[1900].config_expansion_boards[2]
    let ceb="configuration_expansion_boards"
    let cebBase=pb[1900].config_expansion_boards
    let xca="pearl_xboard_cfg_anx"
    let xcaBase=pb[1900][xca]
    this.xIDs={
      addIndex:getParamId2(1900,ceb,"address"),
      typeId:getParamId2(1900,ceb,"boardType"),
      uniqueId1:getParamId2(1900,xca,"uniqueId1"),
    }
    let addDif=this.xIDs.addIndex-this.xIDs.typeId
    let uniDif=this.xIDs.uniqueId1-this.xIDs.typeId
    let reqs=[...Array(40).keys()].map((k,i)=>{
      return {i:this.xIDs.typeId+mult*i}
    })
    let types0=(await getDb00c({s:siteId,z:zInd,c:240,$or:reqs})).data
      .filter(r=>{return +r.d})
//     cl(types0)
    let reqs2=[]
    types0.forEach(t=>{
      reqs2.push({i:t.i+addDif})
      reqs2.push({i:t.i+uniDif})
    })
    let addrUni=(reqs2.length)?
      (await getDb00c({s:siteId,z:zInd,c:240,$or:reqs2})).data:
      []
//     cl(addrUni)
    let xBoards={}
    types0.forEach(t=>{
      xBoards[Math.floor((t.i-cebBase)/8)]={type:+t.d}
    })
    addrUni.forEach(au=>{
      if(au.i>xcaBase){
        xBoards[Math.floor((au.i-xcaBase)/8)].uid=+au.d
      }else{
        xBoards[Math.floor((au.i-cebBase)/8)].addr=+au.d
      }
    })
    return xBoards
  }

  getClientIdToZone=()=>{
    let st=this.state
    let clientIds={}
    st.gateways
    .filter(g=>{return g?.clientId?.length==24})
    .forEach(g=>{
      clientIds[g.gatewayId]=g.clientId
    })
    let clientIdsToZones={}
    st.zones.forEach(z=>{
      z.clientId=clientIds[z.gatewayId]
      if(z.clientId){clientIdsToZones[z.clientId]=z}
    })

    return clientIdsToZones
  }

  getCums0=(pcnts,cum,b,e)=>{
    for(let i=b;i<e;i++){
      if(pcnts[i]){
        cum.c++
        cum.t+=pcnts[i]-1// 2.54 to get pcnt
      }
    }
  }


  getCums=(pcnts,name,id)=>{
    let comm={c:0,t:0},
      xBoard={c:0,t:0},
      sensor={c:0,t:0}
    this.getCums0(pcnts,comm,constant.SYS_STAT_COMMS,constant.SYS_STAT_XBOARDS)
    this.getCums0(pcnts,xBoard,constant.SYS_STAT_XBOARDS,constant.SYS_STAT_SENSORS)
    this.getCums0(pcnts,sensor,constant.SYS_STAT_SENSORS,constant.SYS_STAT_END)// 204 sensors
    return {name:name,id:id,comm:comm,xBoard:xBoard,sensor:sensor,pcnts:pcnts}
  }

  addACum=(name,a,b)=>{
    a[name].c+=b[name].c
    a[name].t+=b[name].t
  }

  addCum=(a,b)=>{
    this.addACum("comm",a,b)
    this.addACum("xBoard",a,b)
    this.addACum("sensor",a,b)
  }

  cleanSysStat=(sysStat)=>{
    sysStat.sort((a,b)=>{
      if(a.m>b.m){return 1}
      if(a.m<b.m){return -1}
      return 0
    })
    let o={}
    sysStat.forEach(s=>{o[s.c]=s})
    return Object.keys(o).map(k=>{return o[k]})
  }

  loadAllSysStatData=async(time)=>{// load data to show accounts, sites, zones
    let st=this.state
//     cl(st)
    let m=Math.floor(time/60)
    let query={m:{$gte:m-5,$lte:m}}// all entries for 5 minutes
    let sysStat=await this.loadSysStat(query)// db in minutes
//     cl(sysStat)
    sysStat=this.cleanSysStat(sysStat)// get most recent for each
//     cl(sysStat)
    let clientIdsToZones=this.getClientIdToZone()
    let acctNames={}
    st.accounts.forEach(a=>{acctNames[a.accountId]={name:a.name}})
    let siteNames={}
    st.sites.forEach(s=>{siteNames[s.siteId]={name:s.name}})
    let zoneNames={}
    st.zones.forEach(z=>{zoneNames[z.zoneId]={name:z.zoneName}})
    let accounts={}
    let system={accts:accounts,comm:{c:0,t:0},xBoard:{c:0,t:0},sensor:{c:0,t:0}}
    let name="";
//     cl(sysStat);
    (sysStat||[]).forEach(ss=>{
      let z=clientIdsToZones[ss.c]
//       cl(ss)
//       cl(z)
//       cl(acctNames[z.accountId])
      name=acctNames[z?.accountId]?.name
      if(name){
        let acctId=z.accountId
        let siteId=z.siteId
        let zoneId=z.zoneId
        let zoneInd=z.siteZoneIndex
        if(!accounts[z.accountId]){accounts[z.accountId]={
          name:name,id:acctId,sites:{},comm:{c:0,t:0},xBoard:{c:0,t:0},sensor:{c:0,t:0}}
        }
        let acct=accounts[z.accountId]
        name=siteNames[z.siteId].name
        if(!acct.sites[z.siteId]){acct.sites[z.siteId]={
          name:name,id:siteId,zones:{},comm:{c:0,t:0},xBoard:{c:0,t:0},sensor:{c:0,t:0}}
        }
        let site=acct.sites[z.siteId]
        name=zoneNames[z.zoneId].name
        site.zones[z.zoneId]=this.getCums(ss.p,name,zoneId)
        let zone=site.zones[z.zoneId]
        this.addCum(site,zone)
        this.addCum(acct,zone)
        this.addCum(system,zone)
      }
    })
//     cl(accounts)
    this.setState({sysStat:system})
//     this.getXBoardInfo("d1fIue45CWvP@Nik",0)
  }

  loadSysStat=async(parms)=>{
    return (await wsTrans("usa", {cmd: "cRest", uri: "/su/sysStat",
      method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: parms})).data.sysStat
  }

  showTwoDecimal=(x)=>{
    return Math.round(100*x)/100
  }

  calcNetPct=(pcts)=>{
    if(!pcts.c){return 0}
    return this.showTwoDecimal(pcts.t/(pcts.c*2.54))
  }

  makeData=()=>{
    let data=[]
    for(let i=0;i<10;i++){
      data.push({date:i,value:i%3})
    }
    return data
  }

  testGraph=()=>{
    let data=this.makeData()
//     cl(data)
    let g=this.graph
//     let svg=this.graph.svg
    let width=100
    let height=100
    g.svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", d3.line()
        .x(function(d) { return g.x(d.date) })
        .y(function(d) { return g.y(d.value) })
        )

  }

  makeTicks=(min,max,tick)=>{
    let tick0=(Math.floor(min/tick)+1)*tick
    let ticks=[]
    while(tick0<max){
      ticks.push(tick0*60*1000)// back to seconds
      tick0+=tick
    }
    return ticks
  }

  drawGraph=(o,vals)=>{
//     cl(o)
    let st=this.state
    let min=vals[0].t/(60*1000)
    let max=vals[vals.length-1].t/(60*1000)
//     let tick=st.graphTick// in minutes
    let ticks=this.makeTicks(min,max,st.sysStatSel.graphTick)
//     cl(st.sysStatSel)
//     cl(ticks)
    o.x=d3.scaleLinear()
      .domain([min*60*1000,max*60*1000])
      .range(o.xDomRng[1]);
    let tickFormat=(st.sysStatSel.graphSpan<7*1440)?"%H:%M":"%b %e"
//     let tickFormat={"60":"%H:%M","1440":"%H:%M","10080":}
    o.xAxis=d3.axisBottom(o.x)
        .tickValues(ticks)
        .tickFormat(d3.timeFormat(tickFormat))
    o.gx.call(o.xAxis)
    if(o.graph){o.graph.remove()}

    o.graph=o.g.append("path")
      .datum(vals)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", d3.line()
        .x(function(d) { return o.x(d.t) })
        .y(function(d) { return o.y(d.v) })
        )
  }

  makeGraph=(divRef)=>{
    var o={
      init:(divRef)=>{
        o.width=400
        o.height=200
        o.hMarg=[25,20]
        o.vMarg=[10,35]
        o.xDomRng=[[0,100],[0,o.width-o.hMarg[0]-o.hMarg[1]]]
        o.yDomRng=[[0,100],[o.height-o.vMarg[0]-o.vMarg[1],0]]
        o.svgCurrent=divRef
        o.svg=d3.select(divRef)
          .append("svg")
            .attr("width",o.width)
            .attr("height",o.height)
        o.g=o.svg
          .append("g")
            .attr("transform",
                  "translate(" + o.hMarg[0] + "," + o.vMarg[0] + ")")
        o.x = d3.scaleLinear()
          .domain(o.xDomRng[0])
          .range(o.xDomRng[1]);
        o.gx=o.g.append("g")
        o.gx
          .attr("class", "x axis")
          .attr("transform", `translate(0,${o.height-o.vMarg[0]-o.vMarg[1]})`)
        o.gx.call(d3.axisBottom(o.x))
        o.y = d3.scaleLinear()
          .domain(o.yDomRng[0])
          .range(o.yDomRng[1]);
        o.g.append("g")
          .attr("transform",`translate(0,0)`)
          .call(d3.axisLeft(o.y));
      }
    }
    o.init(divRef)
    return o
  }

  showTimeButs=()=>{
    let butStyle={display:"inline-block",marginLeft:20,padding:10,
      border:"1px solid",borderRadius:10
    }
    return(
      <div>
        <C18Button00 type="button" style={butStyle}
          onClick={e=>{this.onChange("setGraphTime",
            {graphSpan:60,graphTick:10})}}
        >Hour</C18Button00>
        <C18Button00 type="button" style={butStyle}
          onClick={e=>{this.onChange("setGraphTime",
            {graphSpan:1440,graphTick:240})}}
        >Day</C18Button00>
        <C18Button00 type="button" style={butStyle}
          onClick={e=>{this.onChange("setGraphTime",
            {graphSpan:7*1440,graphTick:1440})}}
        >Week</C18Button00>
        <C18Button00 type="button" style={butStyle}
          onClick={e=>{this.onChange("setGraphTime",
            {graphSpan:31*1440,graphTick:5*1440})}}
        >Month</C18Button00>
      </div>
    )

  }

  showGraph=()=>{
    if(this.state.sysStatSel.chl!="mods"){return}
//     cl(this.state)
    return(
      <div style={{width:400,height:200,border:"1px solid",borderRadius:10}}
      ref={this.svgDiv}>
      </div>
    )
  }

  showXBoardTable=()=>{
    var addRow=(rows,ssSel,i,name,addr,uid)=>{
      let pcnt=this.showTwoDecimal((zone.pcnts[i]-1)/2.54)
      let bgC="white"
      if(ssSel){
        bgC=(ssSel["mods"]==i)?"#CCEEEE":"white"
      }
      rows.push(
        <tr key={i} style={{backgroundColor:bgC}}
        onClick={()=>{this.onChange("sysStatSel",{id:i,chl:"mods"})}}>
        <td>{name}</td>
        <td>{addr}</td>
        <td>{uid}</td>
        <td>{pcnt}</td>
        </tr>
      )
    }

    let st=this.state
    if(!st?.sysStatSel?.zones){return}
    let acct=st.sysStat.accts[st.sysStatSel.accts]
    let site=acct.sites[st.sysStatSel.sites]
    let zone=site.zones[st.sysStatSel.zones]
    let ssSel=st.sysStatSel
    var bl=k=>{return <tr key={`s${k}`} style={{height:20}}><td colSpan="4"></td></tr>}
    let rows=[]
    addRow(rows,ssSel,0,"MQTT")
    addRow(rows,ssSel,1,"UDP")
    rows.push(bl(0))
//     cl(st.sysStatSel.xBoards)
    for(let i=constant.SYS_STAT_XBOARDS;i<constant.SYS_STAT_SENSORS;i++){
      if((zone.pcnts[i])&&(st.sysStatSel.xBoards)){
        let xb=st.sysStatSel.xBoards[i-constant.SYS_STAT_XBOARDS]
        if(xb){
          let typeName=xBoardShorts[xb.type]
          let uidName=("00000000"+xb.uid.toString(16)).slice(-8)
          addRow(rows,ssSel,i,typeName,xb.addr,uidName)
        }else{
          cl(`xBoard Missing: ${i-constant.SYS_STAT_XBOARDS}`)
        }
      }
    }
    rows.push(bl(1))
    for(let i=constant.SYS_STAT_SENSORS;i<constant.SYS_STAT_END;i++){
      if(zone.pcnts[i]){
        let name=allSense[i-constant.SYS_STAT_SENSORS]
        addRow(rows,ssSel,i,name,"","")
      }
    }
    return(
      <div>
        <table style={{width:"initial"}}><tbody>
          <tr><td><h3>Modules and Sensors on {zone.name}</h3></td><td></td>
          <td>{(st.sysStatSel.chl=="mods")&&this.showTimeButs()}</td></tr>
        <tr><td>
        <div>
          <div style={{width:300,maxHeight:200,border:"1px solid",
            borderRadius:10,padding:10,overflowY:"auto",
          }}>
            <table style={{cursor:"pointer"}}><tbody>
              {rows}
            </tbody></table>
          </div>
        </div>
        </td><td style={{width:20}}></td><td>{this.showGraph()}
      </td></tr></tbody></table>
      <div style={{height:20}}/>
      </div>
    )
    cl(zone)
  }


  showTable=(tab0,chl)=>{
    let tab=tab0[chl]
    let st=this.state
    let keys=Object.keys(tab)
    let lines=keys.map((k,i)=>{
      let c=tab[k]
//       cl(c)
      let comm=this.calcNetPct(c.comm)
      let xBoard=this.calcNetPct(c.xBoard)
      let sensor=this.calcNetPct(c.sensor)
      let avg=this.showTwoDecimal((comm+xBoard+sensor)/3)
      let ssSel=st.sysStatSel
//       cl(ssSel)
      let bgC="white"
      if(ssSel){
        bgC=(ssSel[chl]==c.id)?"#CCEEEE":"white"
      }
      return(
        <tr key={i} style={{backgroundColor:bgC}}
        onClick={()=>{this.onChange("sysStatSel",{id:c.id,chl:chl})}}
        ><td>{c.name}</td><td>{avg}</td></tr>
      )
    })
    let comm=this.calcNetPct(tab0.comm)
    let xBoard=this.calcNetPct(tab0.xBoard)
    let sensor=this.calcNetPct(tab0.sensor)
    let avg=this.showTwoDecimal((comm+xBoard+sensor)/3)
    return(
      <div>
        <h3>Total: {`${avg}`}</h3>
        <h3>Comm: {comm}</h3>
        <h3>XBoard: {xBoard}</h3>
        <h3>Sensor: {sensor}</h3>
        <div style={{width:300,maxHeight:200,border:"1px solid",
          borderRadius:10,padding:10,overflowY:"auto",
        }}>
          <table style={{width:"initial",cursor:"pointer"}}><tbody>
            {lines}
          </tbody></table>
        </div>
        <div style={{height:20}}/>
      </div>
    )
  }

  showSysStat=()=>{
//     cl("show sysStat")
    let st=this.state
    let acct="a036uzDCxohZ7ovD"
    let level=["accts","sites","zones"].indexOf(st?.sysStatSel?.chl)
//     cl(st.sysStat)
    return(
      <div>
      <h2>System Status</h2>
      {this.showTable(st.sysStat,"accts")}
      {(st?.sysStatSel?.accts)&&
        this.showTable(st.sysStat.accts[st.sysStatSel.accts],"sites")}
      {(st?.sysStatSel?.sites)&&
        this.showTable(st.sysStat.accts[st.sysStatSel.accts]
          .sites[st.sysStatSel.sites],"zones")}
      {this.showXBoardTable()}
      </div>
    )
  }

  loadTests=async(vals)=>{
    let st=this.state
    if(Object.keys(st.tests).length){return}
    let userId=globs.userData.session.userId
//     await getFuiPages()
    let res=await wsTrans("usa", {cmd: "cRest", uri: '/s/testCsv',
      method: 'retrieve', sessionId: globs.userData.session.sessionId,
      body: {userId:userId}})
    let tests={}
    res.data.forEach(r=>{
      tests[r.testName]=r
    })
    vals.tests=tests
    vals.testSel=Object.keys(tests)[0]
    vals.testName=vals.testSel
  }

  doTestCmd=async(cmd)=>{
    let st=this.state
    let userId=globs.userData.session.userId
    let tzOffset=(new Date()).getTimezoneOffset()
    let testId=getRandomString(8)
//     cl(tzOffset)
    let query={
        cmd:cmd,
        userId:userId,
        testName:st.testSel,
        clientId:st.testClientId,
        tzOffset:tzOffset,
    }
    if(cmd=="runTest"){
      query.testId=testId

    }
//     cl(query)
    let res=await wsTrans("usa", {cmd: "cRest", uri: '/s/testCsv',
      method: 'update', sessionId: globs.userData.session.sessionId,
      body: query})
    switch(cmd){
      case "deleteTest":
        let tests=Object.assign({},st.tests)
//         cl(tests)
        delete tests[st.testSel]
        let testSel=Object.keys(tests)[0]
        this.setState({tests:tests,testSel:testSel,testName:testSel})
        break
      case "runTest":
//         let testLogInt=setInterval(loadTestLog,5000)
        this.setState({testLogSel:testId})
        await this.loadTestLog()
    }
  }

  copyTest=()=>{
    let st=this.state
//     cl(st)
    let test=st.tests[st.testSel].test
//     cl(test)
    let lines=test.map(t=>{
      return t.join("\t")
    })
    let text=lines.join("\n")
//     cl(text)
    window.navigator.clipboard.writeText(text)
  }

  saveTests=async()=>{
    let st=this.state
//     if(!st.testName){return}
    let tests=Object.keys(st.tests).map(k=>{
      return st.tests[k]
    })
//     cl(tests)
    await wsTrans("usa", {cmd: "cRest", uri: '/s/testCsv',
      method: 'update', sessionId: globs.userData.session.sessionId,
      body: {tests:tests}})
  }

  trimTest=(test)=>{
//     cl(test.test)
    let test2=[]
    let maxLen=0
    test.test.forEach(t=>{
      var i
      for(i=t.length-1;i>=0;i--){
        if(t[i]!=""){break}
      }
      if(i>=0){
        test2.push(t)
        if(maxLen<i){maxLen=i}
      }
    })
    test.test=[]
    test2.forEach(t=>{
      test.test.push(t.slice(0,maxLen+1))
    })
//     cl(test.test)
  }

  findTests=(arr,userId,testName0)=>{
    let tests={}
    tests[testName0]={userId:userId,testName:testName0,test:[]}
    let testNames=[testName0]
    var testName
    arr.forEach(a=>{
      switch(a[1].trim()){
        case "start":
          testName=a[2]
//           cl(`Start ${testName}`)
          testNames.unshift(testName)
          tests[testName]={userId:userId,testName:testName,test:[]}
          break
        case "end":
          testNames.shift()
          break
        default:
//           cl(testNames)
          tests[testNames[0]].test.push(a)
          break
      }
    })
//     cl(tests)
    Object.keys(tests).forEach(t=>{this.trimTest(tests[t])})
    return tests
  }

  parseTest=async(vals)=>{
    let st=this.state
    let testName=st.testName
    if(!(st.testName||"").length){
      let suf=Math.floor(getTime())%1000
      testName=`New Test ${suf}`
      await this.setState({testName:testName})
    }
    let userId=globs.userData.session.userId
    let lines=vals.testText.split("\n")
    let arr=lines
      .filter(l=>{return l.length})
      .map(l=>{
        return l.split("\t")
    })
    var test=[]
    if(!testName){return}
    for(let i=0;i<arr.length;i++){// just 1 test, for now
      let arr2=arr[i]
      switch(arr2[1]){
        case "vSend":
        default:
          test.push(arr2)
          break
      }
    }
//     cl(test)
    let tests=this.findTests(test,userId,testName)
    let curTests=Object.assign({},st.tests,tests)
    Object.keys(curTests).forEach(k=>{
      let test=curTests[k]
      if(test.test.length==0){delete curTests[k]}
    })
//     cl(curTests)
    this.setState({tests:curTests,testSel:st.testName})
  }

  loadTestLog=async()=>{
    let st=this.state
    let starts=(await wsTrans("usa", {cmd: "cRest", uri: '/s/testLog',
      method: 'retrieve', sessionId: globs.userData.session.sessionId,
      body: {y:"start"}})).data
    let entries=[]
    let testLogSel=st.testLogSel||starts[0]?.i||""
    if(testLogSel){
      entries =(await wsTrans("usa", {cmd: "cRest", uri: '/s/testLog',
        method: 'retrieve', sessionId: globs.userData.session.sessionId,
        body: {i:testLogSel}})).data
    }
    await this.setState({testLogs:starts,testLogEntries:entries})
  }

  deleteTestLog=async()=>{
    let st=this.state
//     cl("delete test log")
    await wsTrans("usa", {cmd: "cRest", uri: '/s/testLog',
      method: 'delete', sessionId: globs.userData.session.sessionId,
      body: {i:st.testLogSel}})
    let logs=st.testLogs.filter(l=>{return l.i!=st.testLogSel})
//     cl(logs[0])

    this.setState({testLogs:logs,testLogSel:logs[0]?.i||""})
  }

  showTestLogSelect=()=>{
    let st=this.state
    let opts=(st.testLogs||[]).map(l=>{
      let da=new Date(l.t*1000)
//       cl(da)
      let desc=`${l.n} ${dateToDisplayDate(da,"mm/dd/yyyy h:mm ap",0)}`// localTime
      return{v:l.i,t:desc}
    })
//     cl(opts)
    return(
      <div>
        <C18Select01 parms={{
          label:"Select Test Log",
          valueName:"testLogSel",
          testLogSel:st.testLogSel||"",
          opts:opts,
          onChange:(e,v)=>{this.onChange("testLogSel",v)}//this.onChange,
        }}/>
        <C18Button00 type="button" className="filled"
          onClick={e=>{this.onChange("deleteTestLog")}}
        >Delete</C18Button00>
      </div>
    )
  }

  showTestLogEntries=()=>{
    let st=this.state
    let rows=st.testLogEntries.map((e,i)=>{
//       cl(e.t)
      let da=new Date(e.t*1000)
//       cl(da)
//       cl(da.getTimezoneOffset())
      let dispDa=dateToDisplayDate(da,"hh:mm:ss.ddd",0)// localTime
      return(
        <tr key={i}><td>
        {dispDa}
        </td><td>
        {e.m}
        </td></tr>
      )
    })
    return(
      <div style={{width:600,height:661,overflowY:"auto",
        padding:10,border:"1px solid",borderRadius:10}}>
      <table style={{width:"initial"}}><tbody>
      {rows}
      </tbody></table>
      </div>
    )
  }

  showTestButs=()=>{
    let butStyle={marginLeft:20}
    return(
      <div style={{padding:"20px,0px"}}>
        <C18Button00 type="button" className="filled"
          onClick={e=>{this.onChange("runTest")}}
        >Run</C18Button00>
        <C18Button00 type="button" className="filled" style={butStyle}
          onClick={e=>{this.onChange("deleteTest")}}
        >Delete</C18Button00>
        <C18Button00 type="button" className="filled" style={butStyle}
          onClick={e=>{this.onChange("saveTest")}}
        >Save</C18Button00>
      </div>
    )
  }

  showTestClientId=()=>{
    let st=this.state
    return(
      <div>
        <label htmlFor="testClientId" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>Client ID</label>
        <input type="text"
          style={{width:250, border:"1px solid black", borderRadius:10, padding:10}}
          value={st.testClientId}
          onChange={e=>this.onChange("testClientId",{testClientId:e.target.value})}
        />
      <div style={{height:20}}/>

      </div>
    )
  }

  showTestName=()=>{
    let st=this.state
    return(
      <div>
        <label htmlFor="testName" style={{marginBottom:0,marginRight:5,display:"inline-block"}}>Test Name</label>
        <input type="text" id="testName"
          style={{width:250, border:"1px solid black", borderRadius:10, padding:10}}
          value={st.testName||""}
          onChange={e=>this.onChange("testName",{testName:e.target.value})}
        />
      <div style={{height:20}}/>

      </div>
    )
  }

  showCell=(r,c,text)=>{
    let st=this.state
    let sc=st.selCell||{}
    let sel=(sc.r==r)&&(sc.c==c)
//     cl(sc)
    let bgColor=(sel)?"#88CCFF":"white"
    let wid=30+text.length*6.8
    if(sel&&sc.edit){
      return (
        <div>
          <input
            type="text"
            ref={this.selCellRef}
            style={{border:"0px",padding:1,borderRadius:0,width:wid,
            }}
            value={text}
            onBlur={()=>{this.setState({selCell:{r:r,c:c,text:text,edit:false}})}}
            onChange={e=>{this.onChange("selCellText",{r:r,c:c,text:e.target.value})}}
          />
        </div>
      )

    }else{
      return(
        <div
          onClick={()=>this.onChange("selCell",{r:r,c:c,text:text,edit:true})}
          style={{backgroundColor:bgColor,cursor:"pointer"}}
        >
          {text}
        </div>
      )
    }
//     cl(bgColor)
  }

  showEnteredTests=()=>{
    let st=this.state
//     cl(st.tests)
    let testArr=st.tests[st.testSel]?.test||[]
//     cl(testArr)
    if(!testArr?.length){return}
    let tStyle={border:"1px solid black"}
    let rows=testArr.map((r,i)=>{
      let row=r.map((c,j)=>{
        return(<td key={j} style={tStyle}>{this.showCell(i,j,c)}</td>)
      })
      return(<tr key={i}>{row}</tr>)
    })
    return(
      <div>
      <h3>Selected Test</h3>
        <div style={{width:400,maxHeight:400,overflowY:"auto",border:"1px solid black",
          borderRadius:10,padding:20
        }}>
          <table style={{width:"initial"}}><tbody>
          {rows}
          </tbody></table>
        </div>
        <div style={{height:10}}/>
        <C18Button00 type="button" className="filled"
          onClick={e=>{this.onChange("copyTest")}}
        >Copy</C18Button00>
      </div>
    )
  }

  getParams=()=>{
    if(this.paramTable){return}
    this.paramTable=[]
    Object.keys(pi[1900]).forEach(kt=>{
      let tab=pi[1900][kt]
      Object.keys(tab).forEach(kc=>{
        this.paramTable.push(`${kt}-${kc}`)
      })
    })
//     cl(this.paramTable)
  }

//   showSelectFuiPage=()=>{
//     return(
//       <div>
//         <C18Select01 parms={{
//           label:"Select Fui Page",
//           valueName:"fuiSel",
//           fuiSel:st.fuiSel||"",
//           opts:opts,
//           onChange:(e,v)=>{this.onChange("fuiSel",v)}//this.onChange,
//         }}/>
//       </div>
//
//     )
//   }

  setCellText=(r,c,text)=>{
    let st=this.state
    let tests=Object.assign(st.tests)
    let test=tests[st.testSel].test
    test[r][c]=text
    this.setState({tests:tests})
  }

  showParamSearch=()=>{
    let st=this.state
    let rows=[]
    if(this.paramTable){
      this.paramShows=this.paramTable
        .filter(p=>{
          return p.toLowerCase().indexOf((st.paramSearchText||"").toLowerCase())>=0
        })
      rows=this.paramShows.map((p,i)=>{
          return <div
            style={{
              backgroundColor:(i==st.selParam)?"#88CCFF":"white"
            }}
            key={i}
            onClick={()=>this.onChange("selParam",{selParam:i})}
          >{p}</div>})
    }
    rows=rows.slice(0,50)
    return(
      <div>
        <div style={{height:10}}/>
        Parameter Search:&nbsp;
        <input
          value={st.paramSearchText||""}
//           onChange={e=>{this.onChange("dbCol",{selDbCol:e.currentTarget.value})} }
          onChange={e=>{this.onChange("paramSearchText",
            {paramSearchText:e.currentTarget.value})}}
          onFocus={()=>this.getParams()}
        />
        <div style={{height:10}}/>
        <div
          style={{width:400,height:200,border:"1px solid",borderRadius:10,
            overflowY:"auto",cursor:"pointer",padding:10
          }}
        >
        {rows}
        </div>
      </div>
    )
  }

  showTestLeft=()=>{
    let st=this.state
    let opts=Object.keys(st.tests).map(k=>{
      return({t:k,v:k})
    })
    opts.unshift({v:"New Test",t:"New Test"})
    if(!opts.length){opts=[{v:-1,t:"No Tests"}]}
    return(
      <div>
        <div>
        <table style={{width:"initial"}}><tbody>
        <tr>
        <td valign="top">
          <C18Select01 parms={{
            label:"Select Test",
            valueName:"testSel",
            testSel:st.testSel||"",
            opts:opts,
            onChange:(e,v)=>{this.onChange("testSel",v)}//this.onChange,
          }}/>
        </td>
        <td><h3>Paste<br/>CSV<br/>Here</h3></td><td>
          <textarea style={{width:50,height:50,resize:"none",
            overflowY:"clip",overflowClipMargin:1000,}}
            value={st.testsText}
            onChange={e=>{this.onChange("testText",{testText:e.currentTarget.value})}}
          />
        </td></tr>
        </tbody></table>
        {this.showTestClientId()}
        {this.showTestName()}
        {this.showTestButs()}
        {this.showEnteredTests()}
        {this.showParamSearch()}

        </div>
      </div>
    )
  }

  showTestLog=()=>{
    let st=this.state
    return(
      <div>
        {this.showTestLogSelect()}
        {this.showTestLogEntries()}
      </div>
    )
  }

  showTest=()=>{
    return(
      <div>
      <table style={{width:"initial"}}><tbody>
      <tr><td>
        {this.showTestLeft()}
      </td><td width="20">
      </td><td valign="top">
        {this.showTestLog()}
      </td></tr>
      </tbody></table>
      </div>
    )
  }

  showPageType=()=>{
    switch(this.state.pageType){
      case "mqttLive":
        return this.showMqttLive()
      case "techPortal":
        return this.showTechPortal()
      case "mqttLog":
        return this.showMqttLog()
      case "dbgLog":
        return this.showDbgLog()
      case "mongo":
        return this.showMongo()
      case "sysStat":
        return this.showSysStat()
      case "test":
        return this.showTest()
    }
  }

  render(){
//     cl("render")
    let st=this.state
//     let [searchId,type,field1,field2,itemId,selId]=this.pageParms[st.pageType]
    if(st.loaded){
      let cursor=(st.waitFlag)?"wait":""
      return(
        <div style={{cursor:cursor}}>
        <C18SubMenuHeader00 parms={{
          items:[
            {v:"techPortal",t:"Tech Portal"},
            {v:"mqttLive",t:"MQTT Live"},
            {v:"mqttLog",t:"MQTT Log"},
            {v:"dbgLog",t:"Dbg Log"},
            {v:"mongo",t:"Mongo"},
            {v:"sysStat",t:"SysStat"},
            {v:"test",t:"Test"},
          ],
          pageType: this.state.pageType,
          onChange: o=>this.onChange("pageMenu",o),
        }}/>
        <div className="clearfloat"></div>
        {this.showPageType()}
        </div>

      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18TechPortal00;
