import React from 'react';
import {cl, globs, constant, dateToHrMinString, dateToYrMoDa, tsToDate, 
  dateToShortDay, makeShortDayDateStr} from '../../components/utils/utils';
import Icon from '@material-ui/core/Icon';
import Eco from '@material-ui/icons/Eco';
import UsaIcon from '../../usa/components/UsaIcon'
import GrowJournalReply00 from '../../usa/components/GrowJournalReply00'
import parse from 'html-react-parser'
import {wsTrans} from '../../usa/utils/utils'

class GrowJournalWidgetEntry00 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
    this.state={
      closed: true,
      loaded: false,
    }
    this.loremIpsum="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel mauris laoreet, fermentum urna eget, bibendum tortor. Sed dui nulla, fermentum id consectetur nec, malesuada ullamcorper turpis. Praesent at luctus diam. Cras hendrerit lacus nisi, quis feugiat urna interdum in. Maecenas iaculis viverra eros sed faucibus. Pellentesque justo ipsum, consectetur et ex ac, luctus venenatis magna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam in convallis turpis. Nulla sed feugiat arcu, a laoreet massa. Pellentesque nec leo ipsum. Phasellus facilisis, lorem sed eleifend finibus, enim ex feugiat turpis, ac pellentesque tortor ante in metus. Vivamus tristique urna vitae luctus tempor. Praesent aliquam porta nibh ut euismod. Suspendisse imperdiet dui risus, a condimentum neque condimentum sollicitudin.<br/><br/>" +
    "Suspendisse suscipit vehicula commodo. Suspendisse potenti. Nam varius vestibulum nunc, ut tincidunt lorem. Aliquam ut nunc eget justo auctor mollis non vitae ante. Nunc et eleifend lectus, nec vulputate diam. Etiam porttitor nunc luctus lorem pellentesque, non mattis quam dignissim. Quisque fermentum accumsan quam in facilisis. Vestibulum non mollis felis. Nam eu libero varius, placerat nunc nec, blandit neque. Interdum et malesuada fames ac ante ipsum primis in faucibus."
//     cl(props)
//     props.notify({id: "drag", func: this.onDrag})
//     this.loadGJ()
    if(props.parms.reply){
      this.getThread()
    }else{
      this.state.loaded=true
    }
//     cl("continuing")
  }
  
//   componentDidMount=()=>{
//     cl("componentDidMount")
//   }
//   
//   componentDidUpdate=()=>{
//     cl("componentDidUpdate")
//   }
//   
//   componentWillUnmount=()=>{
//     cl("componentDidUpdate")
//   }
//   
//   getThreadAvatars=()=>{
//     cl(this.props.parms.avatars)
//   }
  
  getThread=async()=>{
//     cl(this.props)
    let e=this.props.parms.entry
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId,
      body: {threadId: e.threadId}})
    this.thread=[]
    r.data.forEach(e=>{
      if(e.growJournalId!=this.props.parms.entry.growJournalId){
        this.thread.push(e)
        if(!(e.userId in this.props.parms.avatars)){
          cl("missing")// need to add avatar
        }
        
      }
    })
    this.setState({loaded: true})
//     cl(this.thread)
    this.thread.sort((a,b)=>{
      if(a.modTime>b.modTime){return 1}
      if(a.modTime<b.modTime){return -1}
      return 0
    })
//     cl(this.thread)// still needs to be sorted by time
//     cl(r)
  }
  
// body: "It’s okay. He woke up."
// dispTime: 1611197804
// growJournalId: "U3_Vrk-0tgpz_192"
// subject: "Did you hear about the kidnapping at school?"
// threadId: "zwGisQhdJ1qN_192"
// userId: "3hRZJgBx2_rNEIhd"


  result=async(type)=>{
//     cl(type)
    switch(type){
      case "entry-More":
        await this.getThread()
        this.setState({closed: false})
        break
      case "entry-Close":
        this.setState({closed: !this.state.closed})
        break
      case "entry-Comment":
//         cl(this.props)
        this.props.onChange({gjReply: true, entry: this.props.parms.entry})
//         this.setState({reply: true})
        break
      default:
        break
    }
  }
  
  makeImagePath=(id, ext)=>{
    return `${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`// .${ext}
  }
  
  showImage=(path,s)=>{
//     cl(path)
//     cl(s)
    this.props.onChange({type: "showImage", path: path, section: s})
  }
//                 onClick={e=>{this.showImage(path, s)}}/>

  
  showGJBody=(body)=>{
    let parts=body.split("{File:")
    if (parts.length == 1) parts = body.split("{Img:")
    let sects=[]
    parts.forEach((p,i)=>{
      if(i>0){
        let p2=p.split("}")
//         let imageInfo=this.state.images[p2[0]]
        let imageInfo={w:100, h:50}
        sects.push({i:p2[0], t:p2[1], w: imageInfo.w, h: imageInfo.h, x: 100+50*i, y: 100+50*i})// x, y is where it will appear large
      }else{
        sects.push({i: false, t:p})
      }
    })
//     cl(sects)
    return sects.map((s,i)=>{
//       cl(s)
      var path
      if(s.i){
//         path=this.makeImagePath(s.i, this.state.images[s.i].ext)
        path=this.makeImagePath(s.i, "jpg")
      }
      return(
          <div key={i}>
          {s.i &&
            <img width="100" src={`${constant.expressUrl}/usa/images/uploads/${path}`}
              onClick={e=>{this.showImage(path, s)}}/>
          }
          <div>{s.t}</div>
          </div>
      )
    })
    return
  }
  
  
  showClosedEntry=()=>{
//     cl(this.props)
    let entry=this.props.parms.entry
    let av=entry.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    let dateStr=makeShortDayDateStr(entry.dispTime)
//     let da=tsToDate(entry.dispTime)
//     let dateStr=`${dateToShortDay(da)} ${dateToYrMoDa(da)} ${dateToHrMinString(da)}`
    let subject=`<strong>${entry.subject}</strong>`
//     cl(path)
    
    return(
      <table style={{textAlign: "left"}}><tbody>
      <tr><td><img src={path} height="40"/></td>
      <td>
      <table><tbody>
      <tr><td>
        <Icon component={Eco} style={{padding: 0, fontSize: 18, 
          verticalAlign: "middle"}}/>
        <span style={{verticalAlign: "middle"}}>{dateStr}</span>          
      </td></tr>
      <tr><td>{parse(subject)}
      <UsaIcon icon={"entry-More"} result={this.result}/>
      </td></tr>
      </tbody></table>
      </td>
      </tr>
      </tbody></table>
    )
    cl(entry)
  }
  
  showThread=()=>{
    return this.thread.map((th,i)=>{
//       cl(th)
//       cl(this.props.parms.avatars)
      var av
      if(th.userId in this.props.parms.avatars){av=this.props.parms.avatars[th.userId]}
      var path
      if(av){
        path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
      }else{
        path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
      }
      let body=this.showGJBody(th.body)
//       cl(path)
      
      return(
        <tr key={i}>
          <td style={{verticalAlign: "top"}}><img src={path} height="40"/></td>
          <td>
          <table><tbody>
          <tr><td>
            <Icon component={Eco} style={{padding: 0, fontSize: 18, 
              verticalAlign: "middle"}}/>
            <span style={{verticalAlign: "middle"}}>{this.makeDateStr(th.modTime)}</span>          
          </td></tr>
          <tr><td>{body}</td></tr>
          </tbody></table>
          </td>
        </tr>
      )
    })
      
  }
  
  makeDateStr=(ts)=>{
    let da=tsToDate(ts)
    return `${dateToShortDay(da)} ${dateToYrMoDa(da)} ${dateToHrMinString(da)}`
  }
  
  showOpenEntry=()=>{
    let entry=this.props.parms.entry
//     cl(entry.body)
    let av=entry.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    let dateStr=makeShortDayDateStr(entry.dispTime)
//     let da=tsToDate(entry.dispTime)
//     let dateStr=this.makeDateStr(entry.dispTime)//`${dateToShortDay(da)} ${dateToYrMoDa(da)} $*/{dateToHrMinString(da)}`
    let subject=entry.subject
//     cl(path)
//     let text="" entry.subject + ": " + entry.body + "<br/>" + this.loremIpsum
    let body=this.showGJBody(entry.body)
    let text=`<strong>${entry.subject}:</strong><br/>${entry.body}<p>${this.loremIpsum}</p>`
    
    
    return(
      <table style={{textAlign: "left"}}><tbody>
      <tr><td style={{verticalAlign: "top"}}><img src={path} height="40"/></td>
      <td>
      <table><tbody>
      <tr><td>
      
          <Icon component={Eco} style={{padding: 0, fontSize: 18, 
            verticalAlign: "middle"}}/>
          <span style={{verticalAlign: "middle"}}>{dateStr}</span>          
      {parse("&nbsp;&nbsp;")}
      {!this.props.parms.reply &&
        <UsaIcon icon={"entry-Close"} result={this.result}/>
      }
      {parse("&nbsp;&nbsp;")}
      <UsaIcon icon={"entry-Like"} result={this.result}/>
      {parse("&nbsp;&nbsp;")}
      <UsaIcon icon={"entry-Comment"} result={this.result}/>
      {parse("&nbsp;&nbsp;")}
      <UsaIcon icon={"entry-Share"} result={this.result}/>
      </td></tr>
      <tr><td>{body}
      </td></tr>
      </tbody></table>
      </td>
      </tr>
      {this.showThread()}
      <tr><td>
      <UsaIcon icon={"entry-Comment"} result={this.result}/>
      </td></tr>
      </tbody></table>
    )
    cl(entry)
  }
  
//   showReply=()=>{
//     return(
//       <div>reply></div>
//     )
//   }
  
  render() {
//     cl(this.props)
//     cl(this.state)
//     if(this.state.reply){
//       return this.showReply()
//     }else{
    if(this.state.loaded){
      if(this.state.closed && !this.props.parms.reply){
        return(
          <div>
          {this.showClosedEntry()}
          </div>
        )
      }else{
        
        return(
          <div>
          {this.showOpenEntry()}
          </div>
        )
      }
    }else{
      return <div>loading...</div>
    }
//     }
  }
}

export default GrowJournalWidgetEntry00;
