import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from 'react-router-dom'
import C18Input00 from './C18Input00'
import C18InputError00 from './C18InputError00'
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import history from "../../history"
import {wsTrans, saveTokens} from '../utils/utils'
import {cl, globs,constant,getTZName} from '../../components/utils/utils';

class C18Register00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      companyName: "",
      companyNameError:"",
      name: "",
      nameError:"",
      email1: "",
      email1Error:"",
      email2: "",
      email2Error:"",
      password1: "",
      password1Visible:false,
      password1Error:"",
      password2: "",
      password2Visible:false,
      password2Type:"",
      password2Error:"",
      eulaChecked:false,
      eulaError:"",
      navClass: " ",
      recaptchaResponse:null,
    }
     this.alerts=[
      "Just fill in the fields, and press Register!",//0
      "Company Name Cannot Be Blank",//1
      "Company Name is In Use ",//2
      "Your Name Cannot Be Blank",//3
      "Email Cannot Be Blank",//4
      "Email is In Use ",//5
      "Please Enter a Valid Email",//6
      "Emails Don't Match ",//7
      "Password Cannot Be Blank",//8
      "Password is Too Short",//9
      "Passwords Don't Match",//10
      "What a Super-Duper Password!",//11
      "Checking . . .",//12
      "Password is Too Common ",//13
      "Please accept the EULA terms",//14
      "Just Press Register!",//15
     ];
     if(props.mode=="activate") this.activateUser(props.token);
  }
  
  mySetState=(vals)=>{
    var state={}
    Object.assign(state,this.state,vals)
    this.validate(state)
    this.setState(vals)
  }
  
  onChange=(type,vals)=>{
//     cl([type,vals])
    switch(type){
      case "input":
        this.mySetState(vals)
//         this.validate()
        break
      case "visibility":
        let type=(vals.id=="if-password1")?"password1Visible":"password2Visible"
        let upd={[type]:!this.state[type]}
        cl(upd)
        this.setState(upd)
        break
      case "check":
        this.mySetState(vals)
        break
      default:
        break
    }
  }
  
  onReCaptcha=(e)=>{
//     cl(e)
    this.setState({recaptchaResponse: e});
  }
  
   emailsDiffer=()=>{
     return  !((this.state.email1 === "") || (this.state.email2 === "") || (this.state.email1 === this.state.email2));
   }
   
   emailsEmpty=()=>{
     return (this.state.email1 === "");
  }
  
  setAlert=(n)=>{
    let state=this.state
    var companyNameError,nameError,email1Error,email2Error,password1Error,password2Error,eulaError
    switch(n){
      case 1:
      case 2:
        companyNameError=this.alerts[n]
        break
      case 3:
        nameError=this.alerts[n]
        break
      case 4:
      case 5:
      case 6:
        email1Error=this.alerts[n]
        break
      case 7:
        email2Error=this.alerts[n]
        break
      case 8:
      case 9:
      case 13:
        password1Error=this.alerts[n]
        break
      case 10:
      case 11:
        password2Error=this.alerts[n]
        break
      case 14:
        eulaError=this.alerts[n]
        break
    }
//     cl("alert: " + n);
    this.setState({
      companyNameError: companyNameError,
      nameError: nameError,
      email1Error: email1Error,
      email2Error: email2Error,
      password1Error: password1Error,
      password2Error: password2Error,
      eulaError: eulaError,
      alertId: n});
  }
  
  validEmail=(email)=>{
    let atPos = email.indexOf("@");
    let perPos = email.lastIndexOf(".");
    return ((atPos > 0) && (perPos > atPos));
  }
  
  validate=(state)=>{
/*
alert lines:
That Company Name is Already in Use
Please Fill in Your Name
Please fill in the Email Fields
Email Fields don't Match
That Email is Already in Use
Please Fill in the Password Fields
Password Fields don't Match
That Password is Commonly Used
What a Super Duper Password!
*/
//     let msg = "Please fill in the emails";
//     cl("validate");
//     cl(state.companyName)
    if(state.companyName==="") return this.setAlert(1);
//     if(!state.uc) return this.setAlert(2)
    if(state.name==="") return this.setAlert(3);
    if(state.email1 + state.email2 === "") return this.setAlert(4);
    if(!this.validEmail(state.email1)) return this.setAlert(6);
    
    if(state.email1 !== state.email2) return this.setAlert(7);
    if(state.password1 + state.password2 === "") return this.setAlert(8);
    if(state.password1.length < 6) return this.setAlert(9);
    if(state.password1 !== state.password2) return this.setAlert(10);
    if(!state.eulaChecked) return this.setAlert(14)
    
//      if(this.emailsDiffer()) this.setState({alertMessage: "The emails are different"});
//      if (this.state.companyName + this.state.name + this.state.email1 + this.state.email2 + this.state.password1 + this.state.password2 === "") return this.setAlert(0);
    this.checkFields(state)
//     if(state.password1==="password") return this.setAlert(11);
    return this.setAlert(12);
     
   }

  checkFields=async(state)=>{
    this.timeoutIF = null;
//     cl("checking");
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/unique", method: "retrieve", 
      body: {companyName: state.companyName, email: state.email1, password: state.password1}})
//     cl(res)
//     cl(res.uc)
//     cl("check")
    if(!res.uc && (this.state.alertId > 2)) return this.setAlert(2);// company name used
//     cl("check")
    if(!res.ue && (this.state.alertId > 5)) return this.setAlert(5);// email used
//     cl("check")
    if(!res.up && (this.state.alertId > 11)) return this.setAlert(13);// password fails
//     cl("check")
    if(this.state.alertId === 12) this.setAlert(15);
  }
   
   activateUser=async(token)=>{
//      cl(this.props)
     let r=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/activate", method: "retrieve", 
       body: {token: token}})
//      cl(r)
     saveTokens(r.data.accessToken, r.data.refreshToken, r.session);// should set logged in true
     history.push('/usa/c18/admin/userProfile');
   }
   
  postRegister=(token)=>{
    globs.npi={name: this.state.name, email: this.state.email1, token: token};
    history.push('/usa/c18/postRegister');
  }

  butClick=async(e)=>{
    cl(this.props)
//     cl(this.state)
    let da=new Date()
    da.setMonth(0)// to standard time for this year
    let tzo=da.getTimezoneOffset()
    let tz=getTZName(tzo)
//     cl(tzo)
//     cl(tz)
//     return
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/register", method: "create", 
      body: {
      companyName: this.state.companyName,
      name: this.state.name,
      email: this.state.email1,
      password: this.state.password1,
      recaptchaResponse: this.state.recaptchaResponse,
      developer: (this.props.mode=="devRegister"),
      timezone: tz,
    }})
//     cl(res.data)
    if(res.result==="ok"){ this.postRegister(res.data.token) }else{
//       cl(res)
      this.setState({password2Error:"reCaptcha Error"})
    }
  }
  
//   showInputErrorField=(parms)=>{
//     let messageClass={
//       error:"input-error-message",
//     }[parms.messageType]
//     let divClass=`text-input-wrapper${(parms.message)?" input-error":""}`
//     if(parms.type=="password"){
//       let type=(parms.visible)?"text":"password"
//       
//       return(
//         <div className={divClass}>
//           <label htmlFor={parms.id}>{parms.title}</label>
//           <div id="password-container">
//             <C18Input00 id={parms.id} type={type} required={parms.required}
//               value={parms.value}
//               onChange={e=>this.onChange("input", {[parms.valueId]: e.currentTarget.value})}
//             />
//             <C18Button00 id="visible-button" type="button" aria-label="toggle password visibility">
//               <span className="material-icons"
//                 onClick={e=>parms.onChange("visibility", {id: parms.id})}
//               >
//                 {(parms.visible)?"visibility_off":"visibility"}
//                 </span>
//             </C18Button00>
//             <div className={messageClass}>{parms.message}</div>
//           </div>
//         </div>
//       )
//     }else{
//       return(
//         <div className={divClass}>
//           <label htmlFor={parms.id}>{parms.title}</label>
//           <C18Input00 id={parms.id} type={parms.type} required={parms.required}
//             value={parms.value}
//             onChange={e=>this.onChange("input", {[parms.valueId]: e.currentTarget.value})}
//           />
//           <div className={messageClass}>{parms.message}</div>
//         </div>
//       )
//     }
//   }

  showEulaLink=()=>{
// <Link to={"/eula/eula.html"}>End User License Agreement</Link>    
    return(
      <a target="_blank" href={"/eula/eula.html"}>End User License Agreement</a>
    )
  }

  isOtto=()=>{
    return this.state.companyName=="Automated Testing"
  }
  
  render(){
//               <div>{this.alerts[this.state.alertId]}</div>
//     cl(this.state.alertId)
    let st=this.state
    cl(st)
    let al=this.state.alertId
    let okRegister=((al==11)||(al==15))&&(this.state.recaptchaResponse||(this.isOtto()))
    return (
      <div>
        <div className="login-register-new">

          <div className="login-left register">
          </div>

          <div id="/register" className="login-right">

            <header>
              <div className="floatleft">
                <a href="https://link4controls.com/" className="main-logo"><img src="/img/link4_logo_blue_trans.png" alt="Link4 Controls logo"/></a>
              </div>
              <div className="floatright">
                 Have an account? <C18Anchor00 id="back-to-login" to="/usa/c18/login">Log In</C18Anchor00>
              </div>
            </header>

            <form className="register">
              <h1>Welcome to Link4!</h1>

              <C18InputError00 parms={{
                type:'text',
                title:"Company Name",
                id:"if-companyName",
                required: true,
                value: this.state.companyName,
                valueId: "companyName",
                onChange: this.onChange,
                message:this.state.companyNameError,
                messageType: "error"
              }}/>
              <C18InputError00 parms={{
                type:'text',
                title:"Your Name",
                id:"if-name",
                required: true,
                value: this.state.name,
                valueId: "name",
                onChange: this.onChange,
                message:this.state.nameError,
                messageType: "error"
              }}/>
              <C18InputError00 parms={{
                type:'email',
                title:"Your Email",
                id:"if-email1",
                required: true,
                value: this.state.email1,
                valueId: "email1",
                onChange: this.onChange,
                message:this.state.email1Error,
                messageType: "error"
              }}/>
              <C18InputError00 parms={{
                type:'email',
                title:"Confirm Email",
                id:"if-email2",
                required: true,
                value: this.state.email2,
                valueId: "email2",
                onChange: this.onChange,
                message:this.state.email2Error,
                messageType: "error"
              }}/>
              <C18InputError00 parms={{
                type:'password',
                visible:this.state.password1Visible,
                title:"Your Password",
                id:"if-password1",
                required: true,
                value: this.state.password1,
                valueId: "password1",
                onChange: this.onChange,
                message:this.state.password1Error,
                messageType: "error"
              }}/>
              <C18InputError00 parms={{
                type:'password',
                visible:this.state.password2Visible,
                title:"Confirm Password",
                id:"if-password2",
                required: true,
                value: this.state.password2,
                valueId: "password2",
                onChange: this.onChange,
                message:this.state.password2Error,
                messageType: "error"
              }}/>

              <div className="remember-container">
                <div id="remember-me-container">
                  <C18Input00 id="eula-box" type="checkbox" 
                  style={{padding:"10.5px 12px"}}
                  checked={this.state.eulaChecked}
                  onChange={e=>this.onChange("check",{eulaChecked:e.currentTarget.checked})}
                  /><label htmlFor="eula-box">I accept the {this.showEulaLink()}</label>
                </div>
                <div className="text-input-wrapper input-error">
                  <div className="input-error-message"
                  style={{marginTop:12}}>{this.state.eulaError}</div>
                </div>
              </div>

              {!this.isOtto()&&
                <div className="recaptcha-wrapper">
                  <div className="recaptcha-container">
                    <ReCAPTCHA
                    sitekey="6LergcwZAAAAAEZu1hJC5b7s8ar2JSHvgyfgbYpx"
                    onChange={this.onReCaptcha}
                    />
                  </div>
                </div>
              }

              <div className="button-container">
                <C18Button00 className="filled" disabled={!okRegister}
                tabIndex="0" type="button" id="register-button" onClick={this.butClick}>Register
                </C18Button00>
              </div>


              
              

            </form>

            <div id="footer" className="register">
              <p>Cloud-Based Environmental Controls</p>
              <p>Copyright &copy;2021 Link4 Corporation. All Rights Reserved.</p>
            </div>
          
          </div>
        
        </div>
      </div>
    )
  }
}

export default C18Register00;
