import React from 'react';
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
// import theme from '../themes/theme00';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import Check1 from '@material-ui/icons/CheckRounded';
// import Close1 from '@material-ui/icons/CloseRounded';
import ArrowBack from '@material-ui/icons/ArrowBackRounded';
import ArrowForward from '@material-ui/icons/ArrowForwardRounded';
import ArrowUpward from '@material-ui/icons/ArrowUpwardRounded';
import Home from '@material-ui/icons/HomeRounded';
import Edit from '@material-ui/icons/EditRounded';
import Settings from '@material-ui/icons/SettingsRounded';
import MenuIcon from '@material-ui/icons/MenuRounded';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AcUnit from '@material-ui/icons/AcUnit';
import Accessibility from '@material-ui/icons/Accessibility';
import Adb from '@material-ui/icons/Adb';

import PlayArrow from '@material-ui/icons/PlayArrow';
import Stop from '@material-ui/icons/Stop';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import history from "../history"
import {globs} from './utils/utils';
import {cl} from './utils/utils';
import {loginoutUsa, checkLoggedIn} from '../usa/utils/utils';

class MainBar extends React.Component{
  constructor(props){
    super(props);
//     cl("mainbar");
    globs.menuBar = this;
    this.open=false;
    this.open2=false;
    this.openExtra0=false;
    this.openExtra1=false;
    this.openExtra2=false;
    this.openExtra3=false;
//     cl("mainbar check logged in");
    checkLoggedIn();
    let at = (globs.username === "none") ? "" : " User: " + globs.username +
      (globs.siteName === "none") ? "" : " Site: " + globs.siteName;
    this.state={
      loggedIn: false,
      mainMenu: true,
      anchorEl: null,
      anchorEl2: null,
      addTitle: at,
      addOnMenus:[],
    }
//     this.state.addOnMenus=[
//       {
//         title: "Add on Menu",
//         func: this.testAddOn,// this is the callback, with the menu val
//         icon: ArrowUpward,
//         menuEntries:[
//           {text: "Item One", val: "itemOne"},
//           {text: "Item Two", val: "itemTwo"},
//         ],
//         
//         onClick: this.addOnMenuClick,
//         closeFunc: this.addOnMenuCloseFunc,
//         openVar: false,
//         anchor: null,
//       }
//     ]
  }
  
  
  
  // menu =
  //       [
  //         <MenuItem key="1" id="ParamEditor" onClick={this.handleSelect}>Parameters</MenuItem>,
  //         <MenuItem key="2" id="TableEditor" onClick={this.handleSelect}>Tables</MenuItem>,
  //         <MenuItem key="3"  id="Login" onClick={this.handleSelect}>Log in</MenuItem>
  //       ];

  /*buttons for the main app bar:
  Home, forward, back, up, settings, menu,
  */
  leftButtons(){
    return (
      <div>
      {this.props.home &&
            <IconButton id="home" color="inherit" onClick={this.props.click}>
              <Home/>
            </IconButton>}
      {this.props.upward &&
            <IconButton id="upward" color="inherit" onClick={this.props.click}>
              <ArrowUpward/>
            </IconButton>}
      {this.props.back &&
            <IconButton id="back" color="inherit" onClick={this.props.click}>
              <ArrowBack/>
            </IconButton>}
      {this.props.forward &&
            <IconButton id="forward" color="inherit" onClick={this.props.click}>
              <ArrowForward/>
            </IconButton>}
      </div>
    )
  }
  
  doRemoveMenu=(id)=>{
    let addOnMenus=this.state.addOnMenus.slice(0)
    for(let i=0 ; i < addOnMenus.length; i++){
      if(addOnMenus[i].id==id){addOnMenus.splice(i,1)}
    }
    return addOnMenus
  }
  
  removeOneMenu=(id)=>{
    this.setState({addOnMenus: this.doRemoveMenu(id)})
  }
  
  addOneMenu=(menu)=>{
    let addOnMenus=this.doRemoveMenu(menu.id)
//     let addOnMenus=this.state.addOnMenus.slice(0)
//     cl(menu)
//     cl(menu.menuEntries.length)
    addOnMenus.push(
      Object.assign({
        onClick: this.addOnMenuClick,
        closeFunc: this.addOnMenuCloseFunc,
        openVar: false,
        anchor: null,
      }, menu)
    )
//     cl(addOnMenus)
    this.setState({addOnMenus: addOnMenus})
  }
  
  addOnMenuSetOpen=(i, open, target)=>{
//     cl("set open")
    let addOnMenus=this.state.addOnMenus.slice(0)
    let m=addOnMenus[i]
    m.openVar=open
    m.anchor=target
    this.setState({addOnMenus: addOnMenus})
  }
  
  addOnMenuClick=(i,e)=>{// when icon in bar is clicked
    this.addOnMenuSetOpen(i, true, e.currentTarget)
  }
  
  addOnMenuCloseFunc=(i)=>{
    this.addOnMenuSetOpen(i, false, null)
  }
  
  addOnMenus2=()=>{
//     cl(this.state.addOnMenus)
    return this.state.addOnMenus.map((m,i)=>{
      return(
        <Menu key={i}
        anchorEl={m.anchor}
        anchorOrigin={{vertical: 'top',horizontal: 'right'}}
        transformOrigin={{vertical: 'top',horizontal: 'right',}}
        open={m.openVar}
        onClose={x=>m.closeFunc(i)}
        >
        {m.menuEntries.map((r, j)=>{
          return(
            <MenuItem key={j} id={r.val} onClick={e=>{
              m.func(r.val);
              m.closeFunc(i);
            }}>{r.text}</MenuItem>
          );
        })}
        </Menu>
      )
    })
  }
  
  addOnMenus=()=>{
/*this.state.addOnMenus is an array of menus. each has a func, an icon, and menuEntries array,
where each entry has text, and val*/
    return this.state.addOnMenus.map((m,i)=>{
      return(
        <IconButton key={i} 
          color="inherit"
          onClick={e=>m.onClick(i,e)}
        >
        <Tooltip title={m.title}>
        <m.icon/>
        </Tooltip>
        </IconButton>
      )
    })
  }

  getButtons(){
//     cl(this.props);
    return (
      <div>
      {this.props.edit &&
            <IconButton id="edit" color={this.props.editColor} onClick={this.props.click}>
              <Edit/>
            </IconButton>}
      {this.addOnMenus()}
      {this.props.extra0 &&
            <IconButton id="extra0" color="inherit" onClick={this.props.click}
              color="inherit"
              aria-owns={this.openExtra0 ? 'menu-zs-appbar-extra0' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenuExtra0}
            >
            <Tooltip title="USA Functions">
            <AccountCircle style={{color: "red"}}/>
            </Tooltip>
            </IconButton>}
      {this.props.extra1 &&
            <IconButton id="extra1" color="inherit" onClick={this.props.click}
              color="inherit"
              aria-owns={this.openExtra1 ? 'menu-zs-appbar-extra1' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenuExtra1}
            >
            <Tooltip title="Channel Functions">
            <AcUnit/>
            </Tooltip>
            </IconButton>}
      {this.props.extra2 &&
            <IconButton id="extra2" color="inherit" onClick={this.props.click}
              color="inherit"
              aria-owns={this.openExtra2 ? 'menu-zs-appbar-extra2' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenuExtra2}
            >
            <Tooltip title="Zone Functions">
            <Accessibility/>
            </Tooltip>
            </IconButton>}
      {this.props.extra3 &&
            <IconButton id="extra3" color="inherit" onClick={this.props.click}
              color="inherit"
              aria-owns={this.openExtra3 ? 'menu-zs-appbar-extra3' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenuExtra3}
            >
            <Tooltip title="Unit Functions">
            <Adb/>
            </Tooltip>
            </IconButton>}
      {this.props.settings &&
            <IconButton id="settings" color="inherit" onClick={this.props.click}>
              <Settings/>
            </IconButton>}
      {(this.props.zoneSettings || this.props.unitSettings) &&
        <IconButton
          color="inherit"
          aria-owns={this.open ? 'menu-zs-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenu2}
          >
          <Settings/>
        </IconButton>}
        </div>
    )
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
    cl(this.open);
    cl(this.state.anchorEl);
    this.open=true;
  };

  handleClose = (e) => {
    this.open=false;
    this.setState({ anchorEl: null });
  };

  handleMenu2 = event => {
    cl("handle2");
    this.open2=true;
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleClose2 = (e) => {
    this.open2=false;
    this.setState({ anchorEl2: null });
  };

  handleMenuExtra0 = event => {
//     cl("handleExtra0");
    this.openExtra0=true;
    this.setState({ anchorElExtra0: event.currentTarget });
  };
  
  handleCloseExtra0 = (e) => {
//     cl("close2");
    this.openExtra0=false;
    this.setState({ anchorElExtra0: null });
  };
  
  menuExtra0=()=>{
//     cl("menu extra0");
    if(!this.props.extra0) return;
//     cl(this.props.extra0);
    return (
      <Menu
      id="menu-zs-appbar-extra0"
      anchorEl={this.state.anchorElExtra0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={this.openExtra0}
      onClose={this.handleCloseExtra0}
      >
      {this.props.extra0.menuEntries.map((r, i)=>{
        return(
          <MenuItem key={i} id={r.val} onClick={e=>{
            this.props.extra0.func(r.val);
            this.handleCloseExtra0();
          }}>{r.text}</MenuItem>
        );
      })}
      </Menu>
    );
  }
  
  handleMenuExtra1 = event => {
    //     cl("handleExtra0");
    this.openExtra1=true;
    this.setState({ anchorElExtra1: event.currentTarget });
  };
  
  handleCloseExtra1 = (e) => {
    //     cl("close2");
    this.openExtra1=false;
    this.setState({ anchorElExtra1: null });
  };
  
  menuExtra1=()=>{
    //     cl("menu extra0");
    if(!this.props.extra1) return;
    //     cl(this.props.extra0);
    return (
      <Menu
      id="menu-zs-appbar-extra1"
      anchorEl={this.state.anchorElExtra1}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={this.openExtra1}
      onClose={this.handleCloseExtra1}
      >
      {this.props.extra1.menuEntries.map((r, i)=>{
        return(
          <MenuItem key={i} id={r.val} onClick={e=>{
            this.props.extra1.func(r.val);
            this.handleCloseExtra1();
          }}>{r.text}</MenuItem>
        );
      })}
      </Menu>
    );
  }
  
  handleMenuExtra2 = event => {
    //     cl("handleExtra0");
    this.openExtra2=true;
    this.setState({ anchorElExtra2: event.currentTarget });
  };
  
  handleCloseExtra2 = (e) => {
    //     cl("close2");
    this.openExtra2=false;
    this.setState({ anchorElExtra2: null });
  };
  
  menuExtra2=()=>{
    //     cl("menu extra0");
    if(!this.props.extra2) return;
    //     cl(this.props.extra0);
    return (
      <Menu
      id="menu-zs-appbar-extra2"
      anchorEl={this.state.anchorElExtra2}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={this.openExtra2}
      onClose={this.handleCloseExtra2}
      >
      {this.props.extra2.menuEntries.map((r, i)=>{
        return(
          <MenuItem key={i} id={r.val} onClick={e=>{
            this.props.extra2.func(r.val);
            this.handleCloseExtra2();
          }}>{r.text}</MenuItem>
        );
      })}
      </Menu>
    );
  }
  
  handleMenuExtra3 = event => {
    //     cl("handleExtra0");
    this.openExtra3=true;
    this.setState({ anchorElExtra3: event.currentTarget });
  };
  
  handleCloseExtra3 = (e) => {
    //     cl("close2");
    this.openExtra3=false;
    this.setState({ anchorElExtra3: null });
  };
  
  menuExtra3=()=>{
    //     cl("menu extra0");
    if(!this.props.extra3) return;
    //     cl(this.props.extra0);
    return (
      <Menu
      id="menu-zs-appbar-extra3"
      anchorEl={this.state.anchorElExtra3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={this.openExtra3}
      onClose={this.handleCloseExtra3}
      >
      {this.props.extra3.menuEntries.map((r, i)=>{
        return(
          <MenuItem key={i} id={r.val} onClick={e=>{
            this.props.extra3.func(r.val);
            this.handleCloseExtra3();
          }}>{r.text}</MenuItem>
        );
      })}
      </Menu>
    );
  }
  
  zoneSettingsMenu=()=>{
    // this.open = false;
    if (this.props.zoneSettings) return(
      <Menu
        id="menu-zs-appbar"
        anchorEl={this.state.anchorEl2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={this.open}
        onClose={this.handleClose2}
      >
        <MenuItem key="1" id="zone_Stages" onClick={this.handleZoneSelect}>Stages</MenuItem>
        <MenuItem key="2" id="zone_Fallback" onClick={this.handleZoneSelect}>Fallback</MenuItem>
        <MenuItem key="3" id="zone_Output" onClick={this.handleZoneSelect}>Output</MenuItem>
        <MenuItem key="4"  id="zone_History" onClick={this.handleZoneSelect}>History</MenuItem>
        <MenuItem key="5"  id="zone_Units" onClick={this.handleZoneSelect}>Units</MenuItem>
        <MenuItem key="6"  id="zone_Irrigation" onClick={this.handleZoneSelect}>Irrigation</MenuItem>
        <MenuItem key="7"  id="zone_Lighting" onClick={this.handleZoneSelect}>Lighting</MenuItem>
        <MenuItem key="8"  id="zone_Alarms" onClick={this.handleZoneSelect}>Alarms</MenuItem>
        <MenuItem key="9"  id="zone_Smartcool" onClick={this.handleZoneSelect}>Smartcool</MenuItem>
        <MenuItem key="10"  id="zone_H-C_Demand" onClick={this.handleZoneSelect}>H/C Demand</MenuItem>
        <MenuItem key="11"  id="zone_Setpoints" onClick={this.handleZoneSelect}>Setpoints</MenuItem>
        <MenuItem key="12"  id="zone_SP_Drive_to_Avg" onClick={this.handleZoneSelect}>SP Drive to Avg</MenuItem>
        <MenuItem key="13"  id="zone_SP_Influence_Factors" onClick={this.handleZoneSelect}>SP Influence Factors</MenuItem>
        <MenuItem key="14"  id="zone_SP_Retractable_Greenhouse" onClick={this.handleZoneSelect}>SP Retractable Greenhouse</MenuItem>
        <MenuItem key="15"  id="zone_Hum_DeHum" onClick={this.handleZoneSelect}>Hum DeHum</MenuItem>
        <MenuItem key="16"  id="zone_Aux_Controls" onClick={this.handleZoneSelect}>Aux Controls</MenuItem>
        <MenuItem key="17"  id="zone_Pump_Schedule" onClick={this.handleZoneSelect}>Pump Schedule</MenuItem>
        <MenuItem key="18"  id="zone_Sensors" onClick={this.handleZoneSelect}>Sensors</MenuItem>
      </Menu>
    );
  }

  mainMenu=()=>{
    if (this.state.mainMenu) return(
      <Menu
      id="menu-appbar"
      anchorEl={this.state.anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={this.open}
      onClose={this.handleClose}
      >
      <MenuItem key="1" id="ParamEditor" onClick={this.handleSelect}>Parameters</MenuItem>
      <MenuItem key="2" id="TableEditor" onClick={this.handleSelect}>Tables2</MenuItem>
      <MenuItem key="3" id="FuiEditor" onClick={this.handleSelect}>FUI Editor</MenuItem>
      <MenuItem key="4" id="TemplateEditor" onClick={this.handleSelect}>Template Editor</MenuItem>
      <MenuItem key="5"  id="Login" onClick={this.handleSelect}>Log in</MenuItem>
      
      
      </Menu>
    );
  }
  
  setLoggedIn=(loggedIn)=>{// accessed externall through globs.menuBar
//     cl(loggedIn);
    this.setState({loggedIn: loggedIn});
  }
  
  loggedIn=()=>{
    return(
      <IconButton id="loginout" color="inherit" onClick={loginoutUsa}>
      {this.state.loggedIn && 
        <Tooltip title="Log Out">
        <Stop/>
        </Tooltip>
        
      }
      {!this.state.loggedIn && 
        <Tooltip title="Log In">
          <PlayArrow/>
        </Tooltip>
      }
      </IconButton>
    );
  }
  
  unitSettingsMenu=()=>{
    // cl(this.props)
    // this.open = false;
    if (this.props.unitSettings) return(
      <Menu
        id="menu-zs-appbar"
        anchorEl={this.state.anchorEl2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={this.open2}
        onClose={this.handleClose2}
      >
        <MenuItem key="1" id="unit_Input_Mapping" onClick={this.handleZoneSelect}>Input Mapping</MenuItem>
        <MenuItem key="2" id="unit_Analog_Temp_Mapping" onClick={this.handleZoneSelect}>Analog Temp Mapping</MenuItem>
        <MenuItem key="3" id="unit_Irrigation_Sensor_Mapping" onClick={this.handleZoneSelect}>Irrigation Sensor_Mapping</MenuItem>
        <MenuItem key="4"  id="unit_Vent_Position_Mapping" onClick={this.handleZoneSelect}>Vent Position Mapping</MenuItem>
        <MenuItem key="5"  id="unit_Mixing_Tanks" onClick={this.handleZoneSelect}>Mixing Tanks</MenuItem>
        <MenuItem key="6"  id="unit_Generic_Mapping" onClick={this.handleZoneSelect}>Generic Mapping</MenuItem>
        <MenuItem key="7"  id="unit_Network_Sensors" onClick={this.handleZoneSelect}>Network Sensors</MenuItem>
        <MenuItem key="8"  id="unit_Accumulator" onClick={this.handleZoneSelect}>Accumulator</MenuItem>
        <MenuItem key="9"  id="unit_Input_Calibration" onClick={this.handleZoneSelect}>Input Calibration</MenuItem>
        <MenuItem key="10"  id="unit_Analog_Temp_Calibration" onClick={this.handleZoneSelect}>Analog Temp Calibration</MenuItem>
        <MenuItem key="11"  id="unit_Soil_Moisture_Calibration" onClick={this.handleZoneSelect}>Soil Moisture Calibration</MenuItem>
        <MenuItem key="12"  id="unit_Vent_Position_Calibration" onClick={this.handleZoneSelect}>Vent Position Calibration</MenuItem>
        <MenuItem key="13"  id="unit_Mixing_Tank_Calibration" onClick={this.handleZoneSelect}>Mixing Tank Calibration</MenuItem>
        <MenuItem key="14"  id="unit_Generic_Calibration" onClick={this.handleZoneSelect}>Generic Calibration</MenuItem>
        <MenuItem key="15"  id="unit_Input_Multipliers" onClick={this.handleZoneSelect}>Input Multipliers</MenuItem>
        <MenuItem key="16"  id="unit_Miscellaneous" onClick={this.handleZoneSelect}>Miscellaneous</MenuItem>
      </Menu>
    );
  }




  handleSelect = e=>{
    switch(e.target.id){
      case "ParamEditor":
        // cl("ae");
        history.push("/params")
        break;
      case "TableEditor":
        // cl("ae");
        history.push("/paramtab")
        break;
      case "FuiEditor":
        // cl("ae");
        history.push("/fui")
        break;
      case "TemplateEditor":
        // cl("ae");
        history.push("/tmp")
        break;
      case "Login":
        history.push("/usa/login")
        break;
      default:
        break;

    }
  }

  handleZoneSelect = e=>{
    cl(e.target.id)
    cl(this.props.zone)
    let pageType = e.target.id;
    let zone = this.props.zone;
// http://localhost:3000/fui/live/two/0-0-0-0
    let url = "/fui/live/" + pageType + "/" + zone + "-0-0-0";
      history.push(url)
    cl(url)
    switch(e.target.id){
      // case "ParamEditor":
      //   // cl("ae");
      //   history.push("/params")
      //   break;
      // case "TableEditor":
      //   // cl("ae");
      //   history.push("/paramtab")
      //   break;
      // case "FuiEditor":
      //   // cl("ae");
      //   history.push("/fui")
      //   break;
      // case "Login":
      //   history.push("/li")
      //   break;
      default:
        break;

    }
  }
  render(){
    const { anchorEl } = this.state;
    const { anchorEl2 } = this.state;
    const open = Boolean(anchorEl);
//     this.open = Boolean(anchorEl2);
    // cl(this.open)
    var style;
//     cl(this.props);
    if (this.props.width === undefined){
      style = {};
    } else {
      style = {width: parseInt(this.props.width)}
    }
//     cl(style);
//     style.height=100
    return(
      <div style={style}>
      <MuiThemeProvider>
      <AppBar position="static"
      >
        <Toolbar>
        {this.leftButtons()}
          <Typography
            variant="h6"
            color="inherit"
            style={{flexGrow: 1}}
            >
            {this.props.title + this.state.addTitle}
          </Typography>
          {this.getButtons()}
          {this.zoneSettingsMenu()}
          {this.unitSettingsMenu()}
          {this.menuExtra0()}
          {this.menuExtra1()}
          {this.menuExtra2()}
          {this.menuExtra3()}
          {this.addOnMenus2()}
          {this.mainMenu()}
          {this.loggedIn()}
          {this.props.menu &&
                <IconButton
                  color="inherit"
                  aria-owns={this.open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  >
                  <MenuIcon/>
                </IconButton>}

        </Toolbar>
      </AppBar>
      </MuiThemeProvider>
      </div>
    )
  }
}
  export default MainBar ;
