import React from 'react';
import { loadZonesInfo, getZoneInfo, saveTable } from './C18utils';
import { cl, globs } from '../../components/utils/utils';
import { wsTrans } from '../utils/utils'
class C18ModbusDevicesEdit00 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            registerOpts: {
                abcd: {// device id
                    efgh: {// register id
                        registerId: "efgh",
                        name: "Register One",
                        type: 0,
                        func: 0,
                        addr: 23,
                        conv: "mult",
                        cParm: 10,
                        unit: "min",
                        alLo: 50,
                        alHi: 70,
                    },
                    ijkl: {// register id
                        registerId: "ijkl",
                        name: "Register Two",
                        type: 0,
                        func: 0,
                        addr: 23,
                        conv: "mult",
                        cParm: 10,
                        unit: "min",
                        alLo: 50,
                        alHi: 70,
                    }
                }
            },
            typeOpts: {
                abcd: {
                    name: "Type One",
                },
                xyz: {
                    name: "Type Two",
                }
            },
            typeSel: "abcd",
            registerSel: "efgh",
            regTypeSel: "rod",
            indexNameOpts: {
                1: { deviceType: 0, name: "Index One" }
            },
            indexValueOpts: {
                1: {
                    0: { t: "ind zero" },
                    1: { t: "ind one" },
                },
            },
            indexNameSel: 1,
            deviceOpts: {
                "skuU0AhGtLpcbjU6": {// device id
                    "123": {
                        name: "Device 1",
                        zone: 3,
                        unit: 0,
                        addr: 4,
                    }
                }
            },
            deviceSel: "123",
        }
        this.setBreadCrumbs()
        this.loadData()
        this.subscribe_savePageEvent = globs.events.subscribe("savePageEvent", this.saveModbus)
        this.props.parms.onChange({ cmd: "savePage", data: { savePage: true } })
    }
    saveModbus = (cmd) => {
        if (cmd == "save") {
            this.saveDevice()
        }
    }
    saveType = async () => {
        let typeId = +this.state.typeSel
        let type = Object.assign({}, this.state.typeOpts[typeId], { gatewayId: this.gatewayId, typeId: typeId })
        let r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusTypes", method: "update",
            sessionId: globs.userData.session.sessionId,
            body: type
        })
        await saveTable("modbusTypes", this.gatewayId)
    }
    saveDevice = async () => {
        let deviceId = this.state.deviceSel
        let device = Object.assign({}, (this.state.deviceOpts[this.state.typeSel] || {})[deviceId],
            { gatewayId: this.gatewayId, typeId: this.state.typeSel, deviceId: deviceId })
        cl(device)
        let r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusDevices", method: "update",
            sessionId: globs.userData.session.sessionId,
            body: device
        })
        await saveTable("modbusDevices", this.gatewayId)
        globs.events.publish("saveOK", true)
    }
    saveRegisters = async () => {
        //     cl(this.state.registerOpts)
        let ro = this.state.registerOpts[this.state.typeSel] || {}
        //     cl(ro)
        //     cl(this.props.parms)
        //     cl(this.props.parms.zone)
        //     cl(getZoneInfo(this.props.parms.zone))
        //     let gatewayId=getZoneInfo(this.props.parms.zone).gatewayId
        //     cl(gatewayId)
        let registers = []
        Object.keys(ro).forEach(registerId => {
            registers.push(ro[registerId])
        })
        //     cl(registers)
        let r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusRegisters", method: "update",
            sessionId: globs.userData.session.sessionId,
            body: { gatewayId: this.gatewayId, typeId: +this.state.typeSel, regs: registers }
        })
        await saveTable("modbusRegisters", this.gatewayId)
        globs.events.publish("saveOK", true)
    }
    saveIndexes = async () => {
        //index names
        // +----------------+-------------+------+-----+---------+----------------+
        // | Field          | Type        | Null | Key | Default | Extra          |
        // +----------------+-------------+------+-----+---------+----------------+
        // | name           | varchar(32) | NO   | UNI | NULL    |                |
        // | device_type_id | int(11)     | NO   |     | NULL    |                |
        // | conv_index_id  | int(11)     | NO   | PRI | NULL    | auto_increment |
        // +----------------+-------------+------+-----+---------+----------------+
        // modbus_conv_index_values
        // +---------------------+-------------+------+-----+---------+----------------+
        // | Field               | Type        | Null | Key | Default | Extra          |
        // +---------------------+-------------+------+-----+---------+----------------+
        // | conv_index_id       | int(11)     | NO   | MUL | NULL    |                |
        // | ivindex             | int(11)     | NO   |     | NULL    |                |
        // | value               | varchar(32) | NO   |     | NULL    |                |
        // | conv_index_value_id | int(11)     | NO   | PRI | NULL    | auto_increment |
        // +---------------------+-------------+------+-----+---------+----------------+
        //     cl(this.state)
        let st = this.state
        cl(st)
        let inNames = []
        let indValues = []
        Object.keys(st.indexNameOpts).forEach(k => {// k is for each index
            let ind = st.indexNameOpts[k]
            inNames.push({
                name: ind.name,
                deviceType: +st.typeSel,
                convIndexId: +k,
            })
            let values = st.indexValueOpts[k]
            if (values) {
                let uniques = {}
                cl(values)
                Object.keys(values).forEach(k1 => {// k1 is for each value
                    let key = 100 * k + k1
                    cl(key)
                    if (!uniques[key]) {
                        uniques[key] = 1
                        indValues.push({
                            indexId: +k,
                            ivIndex: +k1,
                            value: values[k1].t,
                            level: values[k1].l,
                            enable: (values[k1].e) ? 1 : 0,
                        })
                    }
                })
            }
        })
        cl(inNames)
        let r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusIndexNames", method: "update",
            sessionId: globs.userData.session.sessionId,
            body: { gatewayId: this.gatewayId, typeId: +st.typeSel, inNames: inNames }
        })
        /* each device type can have multiple indexes 
        each index has multiple values
        indexValueOpts and indexNameOpts are specific to the selected deviceType
        
         */
        cl(indValues)
        //     let indValues=[]
        //     Object.keys(st.indexNameOpts).forEach(k0=>{
        //       
        //     })
        //     let stIndValues=st.indexValueOpts[st.typeSel]
        //     Object.keys(stIndValues).forEach(k=>{
        //       indValues.push({
        //         name:stIndValues[k].t,
        //         ivIndex:k,
        //         indexId:st.indexNameSel,
        //       })
        //     })
        r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusIndexValues", method: "update",
            sessionId: globs.userData.session.sessionId,
            body: {
                gatewayId: this.gatewayId,
                //         typeId:this.state.typeSel,
                indexId: st.indexNameSel,
                indValues: indValues
            }
        })
        await saveTable("modbusConvIndexNames", this.gatewayId)
        await saveTable("modbusConvIndexValues", this.gatewayId)
    }
    setBreadCrumbs = () => {
        if (this.props.parms) {
            this.props.parms.onChange(
                {
                    cmd: "breadcrumbs",
                    data:
                    {
                        breadcrumbs: [
                            { t: "Sites", url: "/usa/c18/sites" },
                            { t: "Modbus", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/modbus/devices` },
                            { t: "ModbusEdit", url: `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/modbusDevicesEdit` }
                        ]
                    },
                },
            )
        }
    }
    onChange = async (type, vals) => {
        cl(type, vals)
        var ino, res, ro, reg
        //     cl(this.state)
        let st = this.state
        switch (type) {
            case "typeSel":// also needs to reset deviceId
                //         cl(this.props.parms)
                let typeSel = vals.typeSel
                let devType = this.props.parms.pageType == "devices"
                let saveType = (devType) ? "this Device" : "these Type"
                res = (this.props.parms.pageModified) ? await this.props.parms.getPopup({ text: `Do you want to save the changes to this ${saveType}?`, buttons: ["Cancel", "Yes"] }) : "Yes"
                if (res == "Yes") {
                    if (devType) {
                        this.saveDevice()
                    } else {
                        this.saveType()
                        this.saveRegisters()
                    }
                }
                let deviceSel = Object.keys(this.state.deviceOpts[typeSel] || {})[0]
                let registerSel = Object.keys(this.state.registerOpts[typeSel] || {})[0]
                //         cl(st)
                //         cl(registerSel)
                //         cl((st.registerOpts[typeSel]||{})[registerSel])
                let indexNameOpts = this.getIndexNames(typeSel)
                //         cl(indexNameOpts)
                let indexNameSel = ((st.registerOpts[typeSel] || {})[registerSel] || {}).cParm
                //         cl(indexNameSel)
                if (!indexNameOpts[indexNameSel]) {
                    indexNameSel = Object.keys(indexNameOpts)[0];
                    ((st.registerOpts[typeSel] || {})[registerSel] || {}).cParm = indexNameSel
                }
                //         cl(indexNameSel)
                Object.assign(vals, {
                    deviceSel: +deviceSel,
                    registerSel: registerSel,
                    indexNameSel: indexNameSel,//st.registerOpts[typeSel][registerSel].cParm,
                    indexNameOpts: indexNameOpts,//this.getIndexNames(typeSel),
                    indexValueOpts: this.getIndexValues(typeSel)
                })
                //         vals["indexNameOpts"]=this.getIndexNames(typeSel)
                //         vals.indexValueOpts=this.getIndexValues(typeSel)
                //         cl(vals)
                this.setState(vals)
                break
            case "regSel":
                //         globs.events.publish("savePageEnable",true)
                //         this.selectRegister(vals.registerSel)
                reg = st.registerOpts[st.typeSel][vals.registerSel]
                cl(reg)
                vals.indexNameSel = reg.cParm
                cl(st.indexValueOpts)
                this.setState(vals)
                break
            case "deviceSel":
                //         globs.events.publish("savePageEnable",true)
                cl(this.props.parms)
                res = (this.props.parms.pageModified) ? await this.props.parms.getPopup({ text: "Do you want to save the changes to this Device?", buttons: ["Cancel", "Yes"] }) : "Yes"
                if (res == "Yes") {
                    this.saveDevice()
                    this.setState(vals)
                }
                break
            case "deviceUpd":
                globs.events.publish("savePageEnable", true)
                let dop = this.state.deviceOpts
                //         cl(this.state.typeSel)
                //         cl(this.state.deviceSel)
                Object.assign(dop[this.state.typeSel][this.state.deviceSel], vals)
                this.setState({ deviceOpts: dop })
                break
            case "typeUpd":
                globs.events.publish("savePageEnable", true)
                let to = this.state.typeOpts
                Object.assign(to[this.state.typeSel], vals)
                this.setState({ typeOpts: to })
                break
            case "regUpd":
                cl(vals)
                globs.events.publish("savePageEnable", true)
                ro = Object.assign({}, this.state.registerOpts)
                reg = ro[this.state.typeSel][this.state.registerSel]
                cl(reg)
                let vk = Object.keys(vals)
                if (vk.includes("func")) { reg.type = (reg.type & 0xFF) | (vals.func << 8) }
                if (vk.includes("type")) { reg.type = (reg.type & 0xFF00) | (vals.type) }
                let key = vk[0]
                if (["alLo", "alHi", "alDB"].includes(key)) {
                    vals[key] = Math.round((+vals[key] * 10))
                    cl(vals)
                }
                delete vals.type
                Object.assign(reg, vals)
                this.setState({ registerOpts: ro })
                break
            case "index":// needs to set cParm
                globs.events.publish("savePageEnable", true)
                //         cl(vals)
                //         cl(vals.indexNameSel)
                //         this.setState(vals)
                ro = Object.assign({}, this.state.registerOpts)
                ro[st.typeSel][st.registerSel].cParm = vals.indexNameSel
                this.setState({ registerOpts: ro, indexNameSel: vals.indexNameSel })
                //         cl(this.state.indexNameSel)
                break
            case "indexEdit":
                globs.events.publish("savePageEnable", true)
                cl(type, vals)
                ino = Object.assign({}, this.state.indexNameOpts || {})
                if (!ino[this.state.indexNameSel]) { ino[this.state.indexNameSel] = {} }
                cl(ino)
                Object.assign((ino[this.state.indexNameSel]), vals)
                this.setState({ indexNameOpts: ino })
                break
            case "indexValue":
                cl(vals)
                globs.events.publish("savePageEnable", true)
                let ivo = Object.assign({}, this.state.indexValueOpts)
                if (!ivo[this.state.indexNameSel]) { ivo[this.state.indexNameSel] = {} }
                if (!ivo[this.state.indexNameSel][vals.index]) { ivo[this.state.indexNameSel][vals.index] = {} }
                cl(ivo)
                Object.assign(ivo[this.state.indexNameSel][vals.index], vals)
                //         ivo[this.state.indexNameSel][vals.index]={t:vals.name}
                this.setState({ indexValueOpts: ivo })
                break
            case "addIndex":
                globs.events.publish("savePageEnable", true)
                let indexId = this.nextIndexId++
                //         let max=0
                //         this.allIndexNames.forEach(ind=>{
                //           if(max<+ind.convIndexId){max=+ind.convIndexId}
                //         })
                //         cl(max)
                ino = Object.assign({}, this.state.indexNameOpts)
                //         let ind=max+1
                ino[indexId] = { name: "New Index", deviceType: +st.typeSel }
                ro = Object.assign({}, this.state.registerOpts)
                ro[st.typeSel][st.registerSel].cParm = indexId
                //         this.setState({registerOpts:ro,indexNameSel:vals.indexNameSel})
                this.setState({ indexNameOpts: ino, registerOpts: ro, indexNameSel: indexId })
                break
            case "deleteIndex":
                globs.events.publish("savePageEnable", true)
                break
            case "addRegister":
                globs.events.publish("savePageEnable", true)
                cl(type, vals)
                this.addRegister()
                break
            case "deleteRegister":
                globs.events.publish("savePageEnable", true)
                this.deleteRegister()
                break
            case "deleteDevice":
                //         globs.events.publish("savePageEnable",true)
                this.deleteDevice()
                break
            case "addDevice":
                res = (this.props.parms.pageModified) ? await this.props.parms.getPopup({ text: "Do you want to save the changes to this Device?", buttons: ["Cancel", "Yes"] }) : "Yes"
                if (res == "Yes") {
                    this.saveDevice()
                    this.addDevice()
                }
                break
            case "deleteType":
                cl("deletetype")
                //         globs.events.publish("savePageEnable",true)
                this.deleteType()
                break
            case "addType":
                globs.events.publish("savePageEnable", true)
                cl(type, vals)
                this.addType()
                break
        }
    }
    getMaxId = (data, field) => {
        let max = 0
        data.forEach(da => { if (max < +da[field]) { max = +da[field] } })
        return 1 + max
    }
    loadData = async () => {
        //     cl(this.state)
        //     cl("load data")
        await loadZonesInfo()
        cl(this.props.parms.zone)
        this.gatewayId = getZoneInfo(this.props.parms.zone).gatewayId
        cl(this.gatewayId)
        let r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusTypes", method: "retrieve",
            sessionId: globs.userData.session.sessionId,
            body: { gatewayId: this.gatewayId }
        })
        this.nextTypeId = this.getMaxId(r.data, "typeId")
        //     cl(this.maxTypeId)
        let to = {}
        //     cl(r.data)
        r.data.forEach(ty => {// somehow "null" values came from LinkConn
            if ((ty.typeId || (ty.typeId == 0)) && (ty.typeId != "null")) { to[ty.typeId] = ty }
        })
        //     cl(to)
        let typeSel = Object.keys(to)[0]
        r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusRegisters", method: "retrieve",
            sessionId: globs.userData.session.sessionId,
            body: { gatewayId: this.gatewayId }
        })
        this.nextRegisterId = this.getMaxId(r.data, "registerId")
        //     cl(r.data)
        this.allRegisters = r.data
        let ro = {}// registerOpts
        r.data.forEach(reg => {
            if (!ro[reg.typeId]) { ro[reg.typeId] = {} }
            ro[reg.typeId][reg.registerId] = reg
        })
        //     let indName
        //     cl(ro)
        let registerSel = Object.keys(ro[typeSel] || {})[0]
        let indexNameSel = (ro[typeSel] || {})[registerSel]?.cParm
        //     cl(registerSel)
        //     let regData=ro[typeSel][registerSel]
        //     cl(regData)
        r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusDevices", method: "retrieve",
            sessionId: globs.userData.session.sessionId,
            body: { gatewayId: this.gatewayId }
        })
        //     cl(r.data)
        this.nextDeviceId = this.getMaxId(r.data, "deviceId")
        let dop = {}
        r.data.forEach(device => {
            cl(device)
            //       cl(device.typeId)
            //       cl(device.deviceId)
            if ((device.typeId) && (device.deviceId)) {
                if (!dop[device.typeId]) { dop[device.typeId] = {} }
                dop[device.typeId][device.deviceId] = device
            }
        })
        cl(dop)
        var deviceSel = -1
        if (Object.keys(dop).length) {
            //       cl(dop)
            //       cl(typeSel)
            //       cl()
            deviceSel = Object.keys(dop[typeSel] || {})[0]
        }
        r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusIndexNames", method: "retrieve",
            sessionId: globs.userData.session.sessionId,
            body: { gatewayId: this.gatewayId }
        })
        this.nextIndexId = this.getMaxId(r.data, "convIndexId")
        let tempNames = {}
        r.data.forEach(r => { tempNames[r.convIndexId] = r })// to remove duplicate IDs
        //     cl(r.data)
        this.allIndexNames = Object.values(tempNames)//r.data
        //     cl(this.allIndexNames)
        let indNames = this.getIndexNames(typeSel)
        //     cl(indexNameSel)
        //     cl(indNames)
        if (!indNames[indexNameSel]) {
            //       cl(Object.keys(indNames))
            //       let keys=Object.keys(indNames)
            //       cl(keys)
            //       cl(keys[0])
            indexNameSel = Object.keys(indNames)[0];
            ((ro[typeSel] || {})[registerSel] || {}).cParm = indexNameSel
        }
        //     cl(r.data)
        r = await wsTrans("usa", {
            cmd: "cRest", uri: "/s/modbusIndexValues", method: "retrieve",
            sessionId: globs.userData.session.sessionId,
            body: { gatewayId: this.gatewayId }
        })
        this.nextIndexValueId = this.getMaxId(r.data, "convIndexValueId")
        this.allIndexValues = r.data
        //     cl(this.allIndexValues)
        let indValues = this.getIndexValues(typeSel)
        //     cl(indValues)
        //     cl(r.data)
        this.setState({
            loaded: true, typeOpts: to, typeSel: typeSel, registerOpts: ro, registerSel: registerSel,
            zoneOpts: this.loadZones(), deviceOpts: dop, deviceSel: deviceSel, indexNameOpts: indNames,
            indexNameSel: +indexNameSel, indexValueOpts: indValues
        })
        if (this.props.parms.tab && this.props.parms.type) {
            this.onChange("typeSel", { typeSel: this.props.parms.type })
            this.onChange("deviceSel", { deviceSel: this.props.parms.tab })
        }
        else {
            this.onChange("addDevice")
        }

        //     cl(this.state)
        //     cl([this.nextTypeId,this.nextRegisterId,this.nextDeviceId,this.nextIndexId,this.nextIndexValueId])
    }

    showDeviceSelect = () => {
        //     if(!this.state.deviceOpts[this.state.typeSel]){return null}
        let dop = this.state.deviceOpts[this.state.typeSel] || {}
        //     cl(dop)
        return (
            <div>
                <span className="custom-select">
                    <label htmlFor="modbus-device">Devices</label>
                    <select id="modbus-device"
                        value={this.state.deviceSel}
                        onChange={e => this.onChange("deviceSel", { deviceSel: e.currentTarget.value })}
                    >
                        {Object.keys(dop).map((deviceId, i) => {
                            //             cl(dop[deviceId])
                            return (
                                <option key={i} value={deviceId}>{dop[deviceId].name || ""}</option>
                            )
                        })}
                    </select>
                    <span className="material-icons down-arrow">
                        keyboard_arrow_down
                    </span>
                    <span className="save-message hidden">Please save before changing</span>
                </span>
                <button type="button" className="material-icons trash after-selector" aria-label="delete device"
                    disabled={!this.props.parms.saveOK}
                    onClick={e => this.onChange("deleteDevice")}
                >
                    delete_outline
                </button>

                <button type="button" style={{ fontSize: '36px',
                color: '#1F8DED'}} className="material-icons-outlined after-selector" aria-label="add device"
                    disabled={!this.props.parms.saveOK}
                    onClick={e => this.onChange("addDevice")}
                >
                    add
                </button>
                <span className="save-message hidden">Please save before changing</span>

                <div className="clearfloat"></div>
                <br /><hr /><br />
            </div>
        )
    }

    showDeviceEdit = () => {
        cl(this.state)
        if (!this.state.deviceOpts[this.state.typeSel]) {
            cl("return")
            return null
        }
        //     cl(this.state.deviceOpts[this.state.typeSel])
        let dop = this.state.deviceOpts[this.state.typeSel]
        let units = ["Master", "Slave 1", "Slave 2", "Slave 3"]
        //     cl(this.state.deviceSel)
        return (
            <div>
                <label htmlFor="idoser-name">Name</label>
                <input id="idoser-name" type="text"
                    value={dop[this.state.deviceSel]?.name || ""}
                    onChange={e => this.onChange("deviceUpd", { name: e.currentTarget.value })}
                />
                <br />
                <span className="custom-select">
                    <label htmlFor="modbus-zone">Zone</label>
                    <select id="modbus-zone"
                        value={dop[this.state.deviceSel]?.zone}
                        onChange={e => this.onChange("deviceUpd", { zone: e.currentTarget.value })}
                    >
                        {this.state.zoneOpts?.map((z, i) => {
                            return (
                                <option key={i} value={z}>{z + 1}</option>
                            )
                        })}
                    </select>
                    <span className="material-icons down-arrow">
                        keyboard_arrow_down
                    </span>
                </span>
                <span className="custom-select">
                    <label htmlFor="modbus-slave">Slave ID</label>
                    <select id="modbus-slave"
                        value={dop[this.state.deviceSel]?.unit}
                        onChange={e => this.onChange("deviceUpd", { unit: e.currentTarget.value })}
                    >
                        {units.map((u, i) => {
                            return (
                                <option key={i} value={i}>{u}</option>
                            )
                        })}
                    </select>
                    <span className="material-icons down-arrow">
                        keyboard_arrow_down
                    </span>
                </span>
                <br />
                <label htmlFor="modbus-addr">ModBus Addr (0-255)</label>
                <input id="modbus-addr" type="number" step="1" min="0" max="255"
                    value={dop[this.state.deviceSel]?.addr > 255 ? 255 : dop[this.state.deviceSel]?.addr || 0}
                    onChange={e => this.onChange("deviceUpd", { addr: e.currentTarget.value })}
                />
            </div>
        )
    }
    showTypeSelect = (parms) => {
        //     cl(this.state)
        let to = this.state.typeOpts
        return (
            <div>
                <span className="custom-select">
                    <label htmlFor="modbus-device">Device Type</label>
                    <select id="modbus-device"
                        value={this.state.typeSel}
                        onChange={e => this.onChange("typeSel", { typeSel: e.currentTarget.value })}
                    >
                        {Object.keys(to).map((typeId, i) => {
                            return (
                                <option key={i} value={typeId}>{to[typeId].name}</option>
                            )
                        })}
                    </select>
                    <span className="material-icons down-arrow">
                        keyboard_arrow_down
                    </span>
                    <span className="save-message hidden">Please save before changing</span>
                </span>
                {parms.edit &&
                    <>
                        <button type="button" className="material-icons trash after-selector" aria-label="delete type"
                            disabled={!this.props.parms.saveOK}
                            onClick={e => this.onChange("deleteType")}
                        >
                            delete_outline
                        </button>
                        <button type="button" className="material-icons-outlined add after-selector" aria-label="add type"
                            disabled={!this.props.parms.saveOK}
                            onClick={e => this.onChange("addType")}
                        >
                            add
                        </button>
                        <div className="clearfloat"></div>
                        <br /><hr /><br />
                    </>
                }
            </div>
        )
    }
    getIndexNames = (deviceType) => {
        let indNames = {}
        this.allIndexNames.forEach(na => {
            if (na.deviceType == deviceType) {
                indNames[na.convIndexId] = {
                    deviceType: na.deviceType,
                    name: na.name,
                }
            }
        })
        return indNames
        //     cl(indNames)
    }
    getIndexValues = (deviceType) => {
        let indIds = []
        //     cl(this.allIndexNames)
        //     cl(deviceType)
        this.allIndexNames.forEach(na => {
            if (+na.deviceType == deviceType) { indIds.push(+na.convIndexId) }
        })
        //     cl(indIds)
        let indValues = {}
        this.allIndexValues.forEach(va => {
            if (indIds.includes(va.indexId)) {
                //         cl(va)
                if (!indValues[va.indexId]) { indValues[va.indexId] = {} }
                indValues[va.indexId][va.ivIndex] = {
                    t: va.value,
                    e: va.enable,
                    l: va.level,
                }
                //         cl(va)
            }
        })
        //     cl(indValues)
        return indValues
    }
    loadZones = () => {
        let zo = []
        globs.zonesInfo.info.forEach(z => {
            if (z.siteId == globs.userData.session.siteId) {
                //         cl(z)
                zo.push(z.siteZoneIndex)
            }
        })
        zo.sort()
        //     cl(zo)
        return zo
    }
    addDevice = () => {
        cl(this.state)
        let deviceName = this.state.typeOpts[this.state.typeSel].name
        let deviceId = this.nextDeviceId++
        let device = {
            deviceId: deviceId,
            name: `New ${deviceName} Device`,
            zone: 0,
            unit: 0,
            addr: 0,
        }
        let dop = Object.assign({}, this.state.deviceOpts)
        if (!dop[this.state.typeSel]) { dop[this.state.typeSel] = {} }
        dop[this.state.typeSel][deviceId] = device
        this.setState({ deviceSel: deviceId, deviceOpts: dop })
    }
    render() {
        return (
            <div>
                {this.showTypeSelect({ edit: false })}
                {this.showDeviceSelect()}
                {this.showDeviceEdit()}
            </div>
        )
    }
}
export default C18ModbusDevicesEdit00;
