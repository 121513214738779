// eslint-disable-line eqeqeq
import React from 'react';
// import C18DateFormat00 from './C18DateFormat00'
// import C18Button00 from './C18Button00'
// import C18Anchor00 from './C18Anchor00'
import C18Select01 from './C18Select01'
import {getParamId2,getPearlUsed,getPearlNumIO} from '../utils/utils'
import {pInd} from '../../components/utils/paramIds'
import {dbVals,putZValue,sendArray} from '../../components/utils/http';
import {lc,pearlSensorIds} from '../../components/utils/landruConstants'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadSiteData,getChannelType,
  getSiteName,getZoneName,} from './C18utils'
// import {getEquipmentStatus} from './C18EquipmentStatus00'
// import {dbVals,getZoneControllers} from '../../components/utils/http';
import {cl,globs,getTimeI,constant} from '../../components/utils/utils';
// import {pInd} from '../../components/utils/paramIds';
//
// import history from "../../history"

class C18PearlMapping00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.inputIds={
      "0":"None",
      "1":"Serial Sensor 1",
      "2":"Serial Sensor 2",
      "3":"Analog Input 1",
      "4":"Analog Input 2",
      "5":"Analog Input 3",
      "6":"Analog Input 4",
      "7":"Analog Input 5",
      "8":"Analog Input 6",
      "9":"Analog Input 7",
      "10":"Analog Input 8",
      "11":"Fast Digital 1",
      "12":"Fast Digital 2",
      "13":"Fast Digital 3",
      "14":"Digital Input 1",
      "15":"Digital Input 2",
      "16":"Digital Input 3",
      "17":"Digital Input 4",
      "18":"Digital Input 5",
      "19":"Digital Input 6",
      "20":"Digital Input 7",
      "21":"Digital Input 8",
      "22":"Software",
      "23":"Remote",
      "24":"Exp Analog 1",
      "25":"Exp Analog 1",
      "26":"Sensor Module",
      "27":"Digital Temp / Hum",
      "28":"Sensor Module Backup",
      "29":"Modbus Input",
      "100":"Zone 1",
      "101":"Zone 2",
      "102":"Zone 3",
      "103":"Zone 4",
      "104":"Zone 5",
      "105":"Zone 6",
      "106":"Zone 7",
      "107":"Zone 8",
      "108":"Zone 9",
      "109":"Zone 10",
      "110":"Zone 11",
      "111":"Zone 12",
      "112":"Zone 13",
      "113":"Zone 14",
      "114":"Zone 15",
      "115":"Zone 16",
      "116":"Zone 17",
      "117":"Zone 18",
      "118":"Zone 19",
      "119":"Zone 20",
      "120":"Zone 21",
      "121":"Zone 22",
      "122":"Zone 23",
      "123":"Zone 24",
      "124":"Zone 25",
      "125":"Zone 26",
      "126":"Zone 27",
      "127":"Zone 28",
      "128":"Zone 29",
      "129":"Zone 30",
      "130":"Zone 31",
      "131":"Zone 32",
    }
    this.genericTypes=[
      {v:0,t:"None"},
      {v:1,t:"Generic"},
      {v:2,t:"Software"},
      {v:3,t:"Temp"},
      {v:4,t:"Hum"},
      {v:5,t:"DHT Temp"},
      {v:6,t:"DHT Hum"},
      {v:7,t:"Rain"},
      {v:8,t:"Wind Speed"},
      {v:9,t:"Wind Dir"},
      {v:10,t:"Voltage"},
      {v:11,t:"Light"},
      {v:12,t:"BHeat"},
      {v:13,t:"Moisture"},
    ]
    this.inputTypes={
      inTemp:[0,1,2,26,27,29,22,23,26,],
      inHum:[0,1,2,27,29,22,23,26],
      inLight:[0,3,4,5,6,7,29,22,23,26,29],
      inCo2:[0,22,23,26,29],
      outTemp:[0,1,2,27,29,22,23],
      bpTemp:[0,1,2,3,4,5,6,7,29,22,23],
      outHum:[0,1,2,27,29,22,23],
      outLight:[0,29,22,23,26],
      wndSpd:[0,29,22,23],
      wndDir:[0,9,29,22,23],
      rain:[0,11,12,13,29,22,23],
      snow:[0,14,15,16,17,18,19,20,21,29,22,23],
      fbInTemp:[0,1,2,26,27,29,22,23,26,],
      diffP:[0,3,4,5,6,7,29,22],
      baro:[0,3,4,5,6,7,29,22],
      buTemp:[0,1,2,26,27,29,22,23],
      irrTrig:[0,26,29,22,23],
      anTemp1:[0,1,2,26,27,29,22,23],
      anTemp2:[0,1,2,26,27,29,22,23],
      anTemp3:[0,1,2,26,27,29,22,23],
      anTemp4:[0,1,2,26,27,29,22,23],
      anTemp5:[0,1,2,26,27,29,22,23],
      ventPos1:[0,26,29,22,23],
      ventPos2:[0,26,29,22,23],
      ventPos3:[0,26,29,22,23],
      ventPos4:[0,26,29,22,23],
      ventPos5:[0,26,29,22,23],
      soil1:[0,26,29,22,23],
      soil2:[0,26,29,22,23],
      soil3:[0,26,29,22,23],
      soil4:[0,26,29,22,23],
      soil5:[0,26,29,22,23],
      gen1:[0,26,29,22,23],
      gen2:[0,26,29,22,23],
      loTemp:[0,1,2,26,27,29,22,23],
      volt1:[0,29],
      volt2:[0,29],
      volt3:[0,29],
      volt4:[0,29],
      volt5:[0,29],
      volt6:[0,29],
      volt7:[0,29],
      volt8:[0,29],
      exTemp1:[0,29],
      exTemp2:[0,29],
      exTemp3:[0,29],
      exTemp4:[0,29],
      exTemp5:[0,29],
      exTemp6:[0,29],
      exTemp7:[0,29],
      exTemp8:[0,29],
      exTemp9:[0,29],
      exTemp10:[0,29],
      exTemp11:[0,29],
      exTemp12:[0,29],
      exTemp13:[0,29],
      exTemp14:[0,29],
      exTemp15:[0,29],
      exTemp16:[0,29],
      exTemp17:[0,29],
      exTemp18:[0,29],
      exTemp19:[0,29],
      genSens1:[0,29],
      genSens2:[0,29],
      genSens3:[0,29],
      genSens4:[0,29],
      genSens5:[0,29],
      genSens6:[0,29],
      genSens7:[0,29],
      genSens8:[0,29],
      genSens9:[0,29],
      genSens10:[0,29],
      genSens11:[0,29],
      genSens12:[0,29],
      genSens13:[0,29],
      genSens14:[0,29],
      genSens15:[0,29],
      genSens16:[0,29],
      genSens17:[0,29],
      genSens18:[0,29],
      genSens19:[0,29],
      genSens20:[0,29],
      genSens21:[0,29],
      genSens22:[0,29],
      genSens23:[0,29],
      genSens24:[0,29],
      genSens25:[0,29],
      genSens26:[0,29],
      genSens27:[0,29],
      genSens28:[0,29],
      genSens29:[0,29],
      genSens30:[0,29],
      genSens31:[0,29],
      genSens32:[0,29],
      genSens33:[0,29],
      genSens34:[0,29],
      genSens35:[0,29],
      genSens36:[0,29],
      genSens37:[0,29],
      genSens38:[0,29],
      genSens39:[0,29],
      genSens40:[0,29],
      genSens41:[0,29],
      genSens42:[0,29],
      genSens43:[0,29],
      genSens44:[0,29],
      genSens45:[0,29],
      genSens46:[0,29],
      genSens47:[0,29],
      genSens48:[0,29],
      genSens49:[0,29],
      genSens50:[0,29],
      genSens51:[0,29],
      genSens52:[0,29],
      genSens53:[0,29],
      genSens54:[0,29],
      genSens55:[0,29],
      genSens56:[0,29],
      genSens57:[0,29],
      genSens58:[0,29],
      genSens59:[0,29],
      genSens60:[0,29],
      genSens61:[0,29],
      genSens62:[0,29],
      genSens63:[0,29],
      genSens64:[0,29],
      genSens65:[0,29],
      genSens66:[0,29],
      genSens67:[0,29],
      genSens68:[0,29],
      genSens69:[0,29],
      genSens70:[0,29],
      genSens71:[0,29],
      genSens72:[0,29],
      genSens73:[0,29],
      genSens74:[0,29],
      genSens75:[0,29],
      genSens76:[0,29],
      genSens77:[0,29],
      genSens78:[0,29],
      genSens79:[0,29],
      genSens80:[0,29],
      genSens81:[0,29],
      genSens82:[0,29],
      genSens83:[0,29],
      genSens84:[0,29],
      genSens85:[0,29],
      genSens86:[0,29],
      genSens87:[0,29],
      genSens88:[0,29],
      genSens89:[0,29],
      genSens90:[0,29],
      genSens91:[0,29],
      genSens92:[0,29],
      genSens93:[0,29],
      genSens94:[0,29],
      genSens95:[0,29],
      genSens96:[0,29],
      genSens97:[0,29],
      genSens98:[0,29],
      genSens99:[0,29],
      genSens100:[0,29],
      genSens101:[0,29],
      genSens102:[0,29],
      genSens103:[0,29],
      genSens104:[0,29],
      genSens105:[0,29],
      genSens106:[0,29],
      genSens107:[0,29],
      genSens108:[0,29],
      genSens109:[0,29],
      genSens110:[0,29],
      genSens111:[0,29],
      genSens112:[0,29],
      genSens113:[0,29],
      genSens114:[0,29],
      genSens115:[0,29],
      genSens116:[0,29],
      genSens117:[0,29],
      genSens118:[0,29],
      genSens119:[0,29],
      genSens120:[0,29],
      genSens121:[0,29],
      genSens122:[0,29],
      genSens123:[0,29],
      genSens124:[0,29],
      genSens125:[0,29],
      genSens126:[0,29],
      genSens127:[0,29],
      genSens128:[0,29],

//       loTemp:[0,1,2,26,27,29,22,23],

    }
    this.state={
      loaded:false,
    }
//     cl("load info")
    this.loadInfo()
    this.setBreadCrumbs()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
  }

  setBreadCrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: [
            {t:"Sites", url:"/usa/c18/sites"},
            {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
            {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
            {t:`Sensor Settings`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/settings/sensor1800`},
            {t:`Input Mapping`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/settings/mapping`},
            {t:`Pearl Mapping`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/pearl/mapping`},
          ]},
      },
    )
  }

  componentWillUnmount=()=>{
    if(this.subscribe_savePageEvent){this.subscribe_savePageEvent.remove()}
  }

  savePage=(cmd)=>{
    let zInd=this.zone.siteZoneIndex
    let ch=240
    let arr=[]
    let now=getTimeI()
    var putVal=(nVals,oVals,name,ofs,id)=>{
//       cl(nVals)
//       cl(oVals)
      if(nVals[name]!=oVals[name]){
//         cl(name)
        let i=ofs+this.inpMapIds[id]
//         cl(nVals,name)
        let d=nVals[name]

        if(putZValue(zInd,ch,i,d)){
//           cl("push")
          arr.push({
            c:ch,
            d:d,
            f:2,
            i:i,
            t:now,
            z:zInd,
          })
        }
      }
    }
    if(cmd=="save"){
//       cl("save")
      let st=this.state
//       cl(st)
      let inputs=st.inputs.slice(0)
      let imi=this.inpMapIds;
//       let ici=this.inpCfgIds
//       cl(this.initInputs[79])
//       cl(inputs)
      inputs.forEach((inp,i)=>{
        if(i<imi.entCnt){
          if(inp!=this.initInputs[i]){
//             cl(inp)
            let ofs=i*this.inpMapIds.entSize
            let inpI=this.initInputs[i]
            putVal(inp,inpI,"mapping",ofs,"mappingId")
            putVal(inp,inpI,"channel",ofs,"channelId")
            putVal(inp,inpI,"board",ofs,"boardId")
            this.initInputs[i]=Object.assign({},inp)// update "old" values
          }
        }else{
//           cl(inp)
          if(inp!=this.initInputs[i]){
//             cl(inp)
            let ofs=(i-imi.entCnt)*imi.entSize2
//             let ofs=i*this.inpMapIds.entSize
            let inpI=this.initInputs[i]
            putVal(inp,inpI,"mapping",ofs,"mappingId2")
            putVal(inp,inpI,"channel",ofs,"channelId2")
            putVal(inp,inpI,"board",ofs,"boardId2")
            putVal(inp,inpI,"sensorType",ofs,"typeId2")
            this.initInputs[i]=Object.assign({},inp)
          }
        }
//         cl(this.initInputs[i])
//         cl(inp)
//         this.initInputs[i]=Object.assign({},inp)
//         cl(this.initInputs[i], inp)
//         this.initInputs[i]=Object.assign({},inp[i])
//         if(i==78){
//           cl(inp, this.initInputs[i])
//         }

      })
//       cl(arr)
//       cl(arr[0]?.d)
      sendArray(arr,this.zone.virtual,this.zone.gatewayType).then(r=>{
        globs.events.publish("saveOK",true)
      })
    }
  }

  loadInputMap=()=>{
    let gwType=this.zone?.gatewayType||1900
    let zInd=this.zone.siteZoneIndex
    let ch=240
    let tab=pInd[gwType]?.pearl_sensor_config
    let tab2=pInd[gwType]?.pearl_gen_sens_conf||{}
    this.inpMapIds={
      mappingId:getParamId2(1900,"pearl_sensor_config","mapping"),
      channelId:getParamId2(1900,"pearl_sensor_config","channel"),
      boardId:getParamId2(1900,"pearl_sensor_config","board"),
      base:tab ? tab[0] : tab,
      entSize:tab ? tab[2]: tab,
      entCnt:tab? tab[3]: tab,

      mappingId2:getParamId2(1900,"pearl_gen_sens_conf","mapping"),
      channelId2:getParamId2(1900,"pearl_gen_sens_conf","channel"),
      boardId2:getParamId2(1900,"pearl_gen_sens_conf","board"),
      typeId2:getParamId2(1900,"pearl_gen_sens_conf","sensorType"),
      entSize2:tab2[2],
      entCnt2:tab2[3],

    }
    let imi=this.inpMapIds;

    let inputs

    if (imi.entCnt && imi.entCnt2) {
      inputs=[...Array(imi.entCnt+imi.entCnt2)].map((ic,i)=>{
        //     let inputs=[...Array(imi.entCnt)].map((ic,i)=>{
              if(i<imi.entCnt){
                let ofs=i*imi.entSize
                return{
                  mapping:dbVals.z[zInd][ch][imi.mappingId+ofs],
                  channel:dbVals.z[zInd][ch][imi.channelId+ofs],
                  board:dbVals.z[zInd][ch][imi.boardId+ofs],
                }
              }else{
                let ofs=(i-imi.entCnt)*imi.entSize2
        //         cl(dbVals.z[zInd][ch][imi.mappingId2+ofs]||0)
                return{
                  mapping:dbVals.z[zInd][ch][imi.mappingId2+ofs]||0,
                  channel:dbVals.z[zInd][ch][imi.channelId2+ofs]||0,
                  board:dbVals.z[zInd][ch][imi.boardId2+ofs]||0,
                  sensorType:dbVals.z[zInd][ch][imi.typeId2+ofs]||0,
                }
              }
            })
    }
//     cl(imi)
//     cl(imi.entCnt+imi.entCnt2)
   
//     cl(inputs[78])
    return inputs
  }

  loadExpansions=()=>{
    let gwType=this.zone.gatewayType
    let zInd=this.zone.siteZoneIndex
    let ch=240
    let tab=pInd[gwType]?.config_expansion_boards
//     cl(tab)
    let startInputId=getParamId2(gwType,"configuration_expansion_boards","startInput")
    let numInputId=getParamId2(gwType,"configuration_expansion_boards","numInputs")
    let boardTypeId=getParamId2(gwType,"configuration_expansion_boards","boardType")
    let mult=tab ? tab[2] : tab
    let cnt=tab ? tab[3] : tab
    this.c2bc={}
    this.bc2c={}
    this.b2type={};
    [...Array(cnt)].forEach((x,i)=>{
//       cl(i)
      let ofs=i*mult
      let pid=startInputId+ofs
      let board=i
      let start=+dbVals.z[zInd][ch][startInputId+ofs]||0
//       let count=+dbVals.z[zInd][ch][numInputId+ofs]||0
//       cl("here")
      let bType=+dbVals.z[zInd][ch][boardTypeId+ofs]||0
//       cl(bType)
//       cl(getPearlNumIO(bType))
      let count=(getPearlNumIO(bType)||{})[1]
      if(true||(bType!=0)){this.b2type[i]=bType}
      if(true||bType){
        this.bc2c[i]={}
        for(let j=0;j<count;j++){
          let ch=start+j// j is the channel on the board, ch is global, i is the board
          this.c2bc[ch]=[i,j]
          this.bc2c[i][j]=ch
        }
      }
    })
//     cl("here")
  }

  loadInfo=()=>{
// get mapping, board, chan for 128 entries:
    let maps=[]
    let pa=this.props.parms
    this.zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    let inputs=this.loadInputMap()
    this.initInputs=inputs?.slice(0)
//     cl("here")
    this.loadExpansions()
//     cl("here")
    this.pearlUsed=getPearlUsed(this.zone.siteZoneIndex)
//     cl(this.pearlUsed)
    Object.assign(this.state,{loaded:true,inputs:inputs})
//     this.setState({loaded:true,inputs:inputs})

  }

  onChange=(type,vals)=>{
    let st=this.state
//     cl(st)
//     cl(type,vals)
    var id,ind,inputs,bc
    switch(type){
      case "map":
        globs.events.publish("savePageEnable",true)
        id=Object.keys(vals)[0]
//         cl(vals,id)
        ind=pearlSensorIds[id].i
        inputs=st.inputs.slice(0)
        let board=inputs[ind].board
//         cl(vals[id])
        if([lc.setup_t_InputMap_INPUTNAME_SENSOR_DTH,
          lc.setup_t_InputMap_INPUTNAME_MBUS_INPUT]
          .includes(+vals[id])/*==lc.setup_t_InputMap_INPUTNAME_SENSOR_DTH*/){
          board=Object.keys(this.bc2c)[0]
//           cl(this.bc2c)
//           cl(board)
        }
        inputs[ind]=Object.assign({},inputs[ind],{
          mapping:vals[id],
          board:board})// creates new object
//         cl(board)
//         cl(ind)
//         cl(inputs[ind])
//         cl(inputs)
        this.setState({inputs:inputs})
        break
      case "sensorType":
//         cl(this.initInputs[79])
        globs.events.publish("savePageEnable",true)
        id=Object.keys(vals)[0]
        inputs=st.inputs.slice(0)
        ind=pearlSensorIds[id].i
        inputs[ind]=Object.assign({},inputs[ind],{sensorType:vals[id]})
//         cl(st)
        this.setState({inputs:inputs})
        break
      case "board":

        globs.events.publish("savePageEnable",true)
        id=Object.keys(vals)[0]
        ind=pearlSensorIds[id].i
        inputs=st.inputs.slice(0)
        bc=this.bc2c[vals[id]]// keyval of the channels on the board
//         cl(bc)
//         cl(id)
//         cl(vals[id])
        inputs[ind]=Object.assign({},inputs[ind],
          {board:+vals[id],
          channel:+(Object.keys(bc)[0])||0})// creates new object
        this.setState({inputs:inputs})
        break
      case "bChan":
        globs.events.publish("savePageEnable",true)
        id=Object.keys(vals)[0]
        ind=pearlSensorIds[id].i
        inputs=st.inputs.slice(0)
        inputs[ind]=Object.assign({},inputs[ind],
          {channel:+vals[id]})// creates new object
        this.setState({inputs:inputs})
        break
      case "chan":
        globs.events.publish("savePageEnable",true)
        id=Object.keys(vals)[0]
        ind=pearlSensorIds[id].i
        inputs=st.inputs.slice(0)
        bc=this.c2bc[vals[id]]
//         inputs[ind].board=bc[0]
//         inputs[ind].channel=bc[1]
        inputs[ind]=Object.assign({},inputs[ind],{board:bc[0],channel:bc[1]})// creates new object
        this.setState({inputs:inputs})
        break
    }
  }

  showSensorMappingSelect=(id)=>{
    let st=this.state
    let opts=Object.keys(this.inputIds).filter(k=>{return this.inputTypes[id].includes(+k)})
      .map(k=>{return{v:k,t:this.inputIds[k]}})
    let inp0=pearlSensorIds[id]

    let inp1=st?.inputs ? st?.inputs[inp0.i] : st?.inputs
    let types=this.inputTypes[id]
//     let info=pearlSensorIds[id]
    let parms={
      label:inp0.t,//inTemp:{i:1,t:"Inside Temperature"},
      valueName:id,
      opts:opts,
      onChange:(t,v)=>this.onChange("map",v),
    }
    parms[id]=+inp1?.mapping
    return(
      <div style={{display:"inline-block"}}>
        <C18Select01 parms={parms}/>
      </div>
    )
  }

  showGenericSensorTypeSelect=(id)=>{
    let st=this.state
    let inp0=pearlSensorIds[id]
    let inp1=st.inputs[inp0.i]
//     cl(id)
//     cl(inp0)
//     cl(inp1)
    let parms={
      label:"",//inTemp:{i:1,t:"Inside Temperature"},
      valueName:id,
//       sensorType:inp1.sensorType,
      opts:this.genericTypes,
      onChange:(t,v)=>this.onChange("sensorType",v),
    }
    parms[id]=(+inp1.sensorType)&0x0FF
//     cl(parms)
    return(
      <div style={{display:"inline-block"}}>
        <C18Select01 parms={parms}/>
      </div>
    )
  }

  showSensorChanSelect=(id)=>{
    let bTypes={
      "0":"None",					//  0: MB_SLAVE_TYPE_NONE
      "1":"24 Out",	//  1: MB_SLAVE_TYPE_24OUT
      "2":"8 Out",		//  2: MB_SLAVE_TYPE_8OUT
      "3":"4 Out",		//  3: MB_SLAVE_TYPE_4OUT
      "4":"2 Out",		//  4: MB_SLAVE_TYPE_2OUT
      "5":"EC/pH",				//  5: MB_SLAVE_TYPE_EC_PH
      "6":"2 An Out",		//  6: MB_SLAVE_TYPE_ANALOG_2OUT
      "7":"1 An Out",		//  7: MB_SLAVE_TYPE_ANALOG_1OUT
      "8":"3 An Out",		//  8: MB_SLAVE_TYPE_ANALOG_3OUT
      "9":"5 An Out",		//  9: MB_SLAVE_TYPE_ANALOG_5OUT
      "10":"Sensor",				// 10: MB_SLAVE_TYPE_SENSOR_MODULE
      "11":"An 16-In",				// 11: MB_SLAVE_TYPE_ANALOG_16INPUT
      "12":"A 8I/D 8O",		// 12:  MB_SLAVE_TYPE_CONTROLLER
      "13":"8 An+DTH",        // 13:  MB_SLAVE_TYPE_ANALOG_10IN_DHT
      "14":"4A/4D Out",		// 14:  MB_SLAVE_TYPE_4A_4D_OUTPUT
      "15":"DTH",		// 15:  MB_SLAVE_TYPE_DTH
      "16":"5 An+DTH",		// 16:  MB_SLAVE_TYPE_ANALOG_7IN_DHT
      "17":"2.5 DTH",		// 16:  MB_SLAVE_TYPE_DTH_5
      "18":"4 DO+DTH",		// 16:  MB_SLAVE_TYPE_4D_DTH
      "19":"8Out DTH",		// 16:  MB_SLAVE_TYPE_8OUT_DTH
      "20":"Cont",		// 17:  MB_SLAVE_TYPE_CONTROLLER
      "101":"Pearl An",		// 17:  MB_SLAVE_TYPE_PEARL_ANALOG
    }
    let dthTypes=[15,16,17,18]
    let tmpTypes=[0,1,6,7,8,9,10,11,45,58,59,60,61,62,63,64,65,66,67,
      68,69,70,71,72,73,74,75,76]
    let humTypes=[2,44,46]
    var showBoardSelect=()=>{
      let boards=Object.keys(this.bc2c).map(b=>{
        return{v:b,t:`Bd ${+b+1} (${bTypes[this.b2type[b]]})`}})
      let parms={
        valueName:id,
        opts:boards,
        onChange:(t,v)=>this.onChange("board",v),
      }
      parms[id]=inp1.board
//       cl(parms)
      return(
        <div style={{display:"inline-block"}}>
          <C18Select01 parms={parms}/>
        </div>
      )
    }
    var showChanSelect=()=>{
      let bdType=this.b2type[+inp1.board]
      var arr=[]
      let chPref="Ch"
      let sensorType=pearlSensorIds[id].i
//       cl(sensorType)
//       cl(inp1)
      let isDth=lc.setup_t_InputMap_INPUTNAME_SENSOR_DTH==+inp1.mapping
      if((isDth)&&
        dthTypes.includes(bdType)){
        if(tmpTypes.includes(sensorType)){chPref="T"}
        if(humTypes.includes(sensorType)){chPref="H"}
      }
//       if(id=="inTemp"){
//         cl(id,sensorType)
//       }
      if(isDth&&[constant.MB_SLAVE_TYPE_DTH_5,constant.MB_SLAVE_TYPE_ANALOG_7IN_DHT]
      .includes(bdType)){
        if(humTypes.includes(sensorType)){arr=[0,1]}else{arr=[0,1,2]}

      }else{
        arr=Object.keys((this.bc2c[inp1.board]||{}))
      }
      let chans=arr.map(c=>{
        return{v:c,t:`${chPref} ${+c+1}`}
      })
      let parms={
        valueName:id,
        opts:chans,
        onChange:(t,v)=>this.onChange("bChan",v),// "board channel"
      }
      parms[id]=inp1.channel
//       cl(parms)
      return(
        <div style={{display:"inline-block"}}>
          <C18Select01 parms={parms}/>
        </div>
      )
    }
// now, show board and channel
    let st=this.state
//     cl(id)

    let chans=Object.keys(this.c2bc).sort().map(c=>{
      return{v:c,t:`Input Channel ${(+c)+1}`}
    })
    let opts=Object.keys(this.inputIds).filter(k=>{return this.inputTypes[id].includes(+k)})
      .map(k=>{return{v:k,t:this.inputIds[k]}})
    let inp0=pearlSensorIds[id]// the input type info

    let inp1=st.inputs[inp0.i]//the actual input info
    let isModbus=((+inp1.mapping)==lc.setup_t_InputMap_INPUTNAME_MBUS_INPUT)
//     cl(id,isModbus)
//     cl(inp1)
//     cl(this.b2type)
//     cl(this.b2type[+inp1.board],constant.MB_SLAVE_TYPE_DTH_5)
//     if(id=="inTemp"){
//       cl(inp1)
//       cl(this.b2type)
//     }
    let isDth5=(inp1.mapping==lc.setup_t_InputMap_INPUTNAME_SENSOR_DTH)&&
    [constant.MB_SLAVE_TYPE_DTH_5,constant.MB_SLAVE_TYPE_ANALOG_7IN_DHT]
      .includes(this.b2type[+inp1.board])
//       (this.b2type[+inp1.board]==constant.MB_SLAVE_TYPE_DTH_5)
//       ([])
    return(
      <div style={{display:"inline-block"}}>
        {showBoardSelect()}
        {(isModbus||isDth5)&&showChanSelect()}
      </div>
    )
  }

  showSensorChanSelectO2=(id)=>{
    let st=this.state
    let chans=Object.keys(this.c2bc).sort().map(c=>{
      return{v:c,t:`Input Channel ${(+c)+1}`}
    })
//     cl(chans)
    let opts=Object.keys(this.inputIds).filter(k=>{return this.inputTypes[id].includes(+k)})
      .map(k=>{return{v:k,t:this.inputIds[k]}})
    let inp0=pearlSensorIds[id]

    let inp1=st.inputs[inp0.i]
    let types=this.inputTypes[id]
//     let info=pearlSensorIds[id]
    let parms={
//       label:inp0.t,//inTemp:{i:1,t:"Inside Temperature"},
      valueName:id,
      opts:chans,
      onChange:(t,v)=>this.onChange("chan",v),
    }
    parms[id]=(this.bc2c[+inp1.board]||{})[+inp1.channel]

    return(
      <div style={{display:"inline-block"}}>
        <C18Select01 parms={parms}/>
      </div>
    )
  }

  showSensorChanSelectO=(id)=>{
    let st=this.state
    let inp0=pearlSensorIds[id]
    let inp1=st.inputs[inp0.i]
    let val="6"
    return(
      <div>chan
      <input type="number" min="1" max="128" step="1"
        value={val}
        onChange={e=>this.onChange("chan",e.currentTarget.value)}
      />
      </div>
    )
  }

  showSensor=(id,sensorId,i)=>{
//     cl(id,sensorId,i)
    let st=this.state
//     cl(st)
    let inp0=pearlSensorIds[id]
    let inp1=st?.inputs ? st?.inputs[inp0.i] : st?.inputs
    let isMbDth=[lc.setup_t_InputMap_INPUTNAME_MBUS_INPUT,lc.setup_t_InputMap_INPUTNAME_SENSOR_DTH]
      .includes(+inp1?.mapping)
    let isGSens=(sensorId>=lc.setup_t_InputMap_INPUTIDX_GSNS1)&&
      (sensorId<=lc.setup_t_InputMap_INPUTIDX_GSNS128)
//     cl(id)
//     cl(inp1)
    return(
      <tr key={i}>
        <td>
          {this.showSensorMappingSelect(id)}
          {(isMbDth)&&
            this.showSensorChanSelect(id)}
          {(isMbDth&&isGSens)&&
            this.showGenericSensorTypeSelect(id)}
        </td>
      </tr>
    )
  }

  showSensors=()=>{
//     cl(lc.setup_t_InputMap_INPUTIDX_GSNS1)
    let st=this.state
//     cl(pearlSensorIds)
    let sensorList=[""]
    let sensors=Object.keys(pearlSensorIds).filter(s=>{return this.inputTypes[s]})
    .map((s,i)=>{
//       cl(s)
      return this.showSensor(s,pearlSensorIds[s].i,i)
    })
//     cl(sensors)
//     inTemp outTemp inHum inCo2 outLight winSpd anTemp1 anTemp2 anTemp3 anTemp4 anTemp5 fbInTemp
//     winDir soil1 soil2 soil3 soil4 soil5 rain irrTrig diffP inLight vent1 vent2 vent3 vent4 vent5
//     gen1 gen2 outHum locInTemp locInHum snow bpTemp baro volt1 volt2 volt3 volt4 volt5 volt6 volt7 volt8


    return(
      <table><tbody>
      {sensors}
      </tbody></table>
    )
  }

  render(){
    if(this.state.loaded){
      return(
        <div><h3>Sensor Mapping</h3>
        {this.showSensors()}
        </div>
      )
    }else{
      return <div id="content-area">loading equipment. . .</div>
    }
  }
}

export default C18PearlMapping00;
