import React from 'react';
import C18Input00 from './C18Input00'
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import C18InputError00 from './C18InputError00'
import C18Graphing00 from './C18Graphing00';
import Gauge2 from '../../visualization/components/Gauge01';
import C18AlarmLog00 from './C18AlarmLog00';
import LiveFui from '../../fui/LiveFui';
import {wsTrans,sensorIds,initSensorIds,setSensorIdNames} from '../utils/utils'
import {loadZonesInfo,getZoneIndex,loadSiteData,getSiteName,getZoneName,addToAdminLog, saveToAdminLog,loadUsersInfo, getUserIndex, loadDevicesInfo,loadAccountInfo,privs,
  updateSensor,loadSensorsInfo,getZoneInfo,getTankNames,loadMBInfo,
} from './C18utils';
// import {sensorIds} from ''
import {cl, globs, constant, allCountries, allStates, allTimezones, allThemes, getTime} from '../../components/utils/utils';
import {dbVals} from '../../components/utils/http'
import{pi,pInd} from '../../components/utils/paramIds';
import history from '../../history'

class C18Sensor00 extends React.Component{
  constructor(props) {
// Graph
// Mapping / Calibrate
// Enable LiveFui on a partial page
// Alarms table:
// alarm enable
// alarm low
// alarm high
// alarm deadband
// alarm delay
// alarm level
// notify whom
// notify how often
// how many / day
// enable snooze
    
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      showSuper:privs("super","",constant.AREA_PRIVS_READ),
      name:"",
      email:"some",
    }
    this.roles=[
      {v:"superman",t:"Superman"},
      {v:"superadmin",t:"Super Admin"},
      {v:"mortal",t:"Mortal"},
    ]
//     this.sensors={
//       inT:{pid:"inTemperature",title:"Inside Temperature"},
//       inH:{pid:"inHumidity",title:"Inside Humidity"},
//       inL:{pid:"inLight",title:"Inside Light"},
//       inC:{pid:"co2",title:"Inside CO2"},
//       ouT:{pid:"outTemperature",title:"Outside Temperature"},
//       ouH:{pid:"outHumidity",title:"Outside Humidity"},
//       ouL:{pid:"outLight",title:"Outside Light"},
//       bpT:{pid:"outTemperatureSecondary",title:"Black Plate Temp"},
//       oWs:{pid:"windSpeed",title:"Wind Speed"},
//       oWd:{pid:"windDirection",title:"Wind Direction"},
//       dPr:{pid:"differentialPressure",title:"Differential Pressure"},
//       bPr:{pid:"barometricPressure",title:"Barometric Pressure"},
//       ran:{pid:"rain",title:"Rain"},
//       sno:{pid:"snow",title:"Snow"},
//       vpd:{pid:"vpd",title:"VPD"},
//       at0:{pid:"analogTemperature1",title:"Analog Temp 1"},
//       at1:{pid:"analogTemperature2",title:"Analog Temp 2"},
//       at2:{pid:"analogTemperature3",title:"Analog Temp 3"},
//       at3:{pid:"analogTemperature4",title:"Analog Temp 4"},
//       at4:{pid:"analogTemperature5",title:"Analog Temp 5"},
//       vp0:{pid:"ventPosition1",title:"Vent Position 1"},
//       vp1:{pid:"ventPosition2",title:"Vent Position 2"},
//       vp2:{pid:"ventPosition3",title:"Vent Position 3"},
//       vp3:{pid:"ventPosition4",title:"Vent Position 4"},
//       vp4:{pid:"ventPosition5",title:"Vent Position 5"},
//       sm0:{pid:"soilMoisture1",title:"Soil Moisture 1"},
//       sm1:{pid:"soilMoisture2",title:"Soil Moisture 2"},
//       sm2:{pid:"soilMoisture3",title:"Soil Moisture 3"},
//       sm3:{pid:"soilMoisture4",title:"Soil Moisture 4"},
//       sm4:{pid:"soilMoisture5",title:"Soil Moisture 5"},
//     }
//     let base=pInd[1800].snapshots[0]
//     Object.keys(this.sensors).forEach((se,i)=>{
//       let s=this.sensors[se]
//       s.pid=base+pi[1800].snapshots[s.pid]// convert to numeric values
// //       cl(this.sensors[se])
//     })
    this.loadInfo()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveSensor)
//     this.props.notify({id: "newSensor", func: this.newSensor})
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
  }
  
//   componentWillUnmount=()=>{
//     this.props.notify({id: "newSensor", func: this.newSensor, unMount: true})
//   }
  
//   newSensor=(sensor)=>{
//     cl(sensor)
//   }
  
  setBreadCrumbs=()=>{
//     cl(this.props.parms)
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(p){
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
//               {t:"iDoser", url:`/usa/c18/idoser/nutrients`},
              {t:"Sensor", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/sensor/${this.props.parms.pageType}`},
            ]},
        },
      )
    }
  }
  
//   componentWillUnmount=()=>{
//     this.subscribe_savePageEvent.remove()
//   }
  
  loadInfo=async()=>{
//     cl(this.props)
//     cl(globs.userData.session.siteId)
    let pa=this.props.parms
    await loadZonesInfo()
    await loadAccountInfo()
//     cl(globs.accountInfo.info)
    await loadUsersInfo()
    await loadSiteData(pa.site)
    await loadSensorsInfo()
    await loadMBInfo(pa.site)
    let zInd=getZoneInfo(pa.zone).siteZoneIndex
//     cl(zInd)
    initSensorIds(zInd)
    await setSensorIdNames(pa.zone)
    this.tankNames=getTankNames(zInd)
//     cl(this.props)
//     cl(pa.site)
//     cl(dbVals.z[0][240][5202])
//     let parts=pa.url.split("/")
//     this.userId=parts[5]
    this.userId=pa.adminInfo
//     cl(globs.accountInfo.info)
//     cl(globs.accountInfo.info.owner==pa.adminInfo)
    var user={email:""}
//     cl(user)
    if(this.userId){
      globs.usersInfo.info.forEach(u=>{
        if(u.userId==this.userId){user=u}
      })
      this.name=user.name||""
//       cl(user)
    }
    this.setBreadCrumbs()
    user.owner=globs.accountInfo.info.owner==pa.adminInfo
//     let active=user.active
//     cl(active)
    this.setState({
      loaded:true,
      name:user.name||"",
      email:user.email,
      role:user.role||"mortal",
      active:user.active||false,
      owner:user.owner||false,
      site:pa.site,
      zone:pa.zone,
    })
//     cl(this.state.owner)
//     cl(dbVals.z[0][240][5202])
  }
  
  saveSensor=(cmd)=>{
//     cl(cmd)
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
//     globs.events.publish("savePageEnable",true)
    switch(type){
      case "upd":
      case "input":
        let st=Object.assign({},this.state,vals)
        this.
        checkFields(st)
        this.setState(vals)
        break
      case "graphing"://ignore  pageTitle and breadcrumbs
        break
      default:
        break
    }
  }
  
  showGraph=()=>{
//     cl(this.state)
    return(
      <C18Graphing00 parms={{
        site: this.state.site,
        zone:this.state.zone,
        mode:"sensor",
        sensorId:this.props.parms.pageType,
        pageType:"viewGraph",
        sensors:this.state.sensors,
        period:this.state.period,
        onChange:vals=>this.onChange("graphing",vals),
        getPopup:o=>this.getPopup("admin",o),
      }}
      notify={this.props.notify}
      />
    )
  }
  
/************** Code to Display Guage *********************/
  
  setGauge=(s)=>{
/*s:
al
cUn
cVal
foot
id
name
name2
site
type
val
vals
zone
*/
/*colors:
x
lo/hi: b0d9ff
mid: 67aded
pnt: 4bd087
cnt: f3f7ff
edg: e8efff
txt: a8a8a9
ttl: 6e6b7b
val: 393d46
unt: afb0b1

splow: c8c3ff
spmid: 0e01ac
sppnt: 0e01ac
*/
//       {name: "Inside Relative Humidity", type: "ga", vals:[-20, 0, 45, 65], cVal: 36, cUn: "% RH", val: 36, foot: "Normal"},
    let dark=(globs.device?.deviceTheme=="originalDark")?2:0
    let alarm=(s.al)?1:0
//     cl([dark,alarm])
//     cl(globs)
//     cl(dark)
//     cl(s)
    let val=s.val
//     cl(s)
    let bgColor=["#FFFFFF","#F4E5E5","#202020","#2b080c"][dark+alarm]
    let p={
      dec:1,
      mult:1,
      bgColor: bgColor,//(dark)?"#202020":"#FFFFFF",
      pntColor:"#4bd087",
      cntColor:(dark)?"#202020":"#f3f7ff",
      untColor:"#afb0b1",
      outRadius: 85,
      inRadius: 75,
      minVal: s.vals[0],
      maxVal:s.vals[s.vals.length-1],
    }
    let size=166
    let ga={}
    ga.value=s.val
    ga.centerValue=s.cVal
    ga.alarm=s.al
    ga.unit=s.cUn
    ga.unitColor=p.untColor
    ga.width=0.8*size-10//p.w-10,// sets size
    ga.height=0.62*size
    ga.fontSize=0.065*size//0.6*size,//12,
    ga.bgColor=p.bgColor
    ga.pointerColor=p.pntColor
    ga.centerColor=p.cntColor
    ga.ticks=s.vals
    if(s.type=="sp"){
      ga.tod=true
      ga.minAngle=0
      ga.maxAngle=360
      ga.colors=s.vals.map(v=>{return [v.start,v.col]})
//       ga.colors.unshift([0,"#c8c3ff"])
      ga.colors.push([1440+s.vals[0].start,"#FFFFFF"])
      ga.ticks=s.vals.map(v=>{return v.start})
//       cl(ga.colors)
//       s.vals.forEach(v=>{})
//       ga.colors=[
//       [0,"#c8c3ff"],
//       [s.vals[0],"#c8c3ff"],
//       [s.vals[1],"#0e01ac"],
//       [s.vals[2],"#c8c3ff"],
//       [1440,"#c8c3ff"],
//       [s.vals[3],"#FFFFFF"],
//       ]
      ga.min=0
      ga.max=1440
    }else{
      ga.minAngle=-120
      ga.maxAngle=120
      if(ga.ticks.length>2){
        ga.colors=[
        [s.vals[0],"#b0d9ff"],
        [s.vals[1],"#67aded"],
        [s.vals[2],"#b0d9ff"],
        [s.vals[3],"#FFFFFF"],
        ]
      }else{
        ga.colors=[
        [s.vals[0],"#b0d9ff"],
        [s.vals[1],"#FFFFFF"],
        ]
      }
      ga.min=p.minVal
      ga.max=p.maxVal
//       cl(ga.ticks)
    }

    ga.pntRadius=p.outRadius
    ga.ringInset=(size/2)*(100-p.outRadius)/100
    ga.ringWidth=(size/2)*(p.outRadius-p.inRadius)/100
//     ga.value=p.dataPoints.dp
//     ga.ticks=s.vals
    ga.update=this.update
    return ga
  }
  
  sensorClick=(e,id)=>{
    e.preventDefault()
  }
  
  showSensor=()=>{
//     cl(this.props)
//     cl(this.state)
    let pa=this.props.parms
    // cl(pa)
    let zIndex=getZoneIndex(pa.zone)
    let zone=globs.zonesInfo.info[zIndex]
    let zInd=zone.siteZoneIndex
    let gw=globs.gatewaysInfo.info.filter(g=>{return g.gatewayId==zone.gatewayId})[0]
//     cl(gw)
//     cl(pa)
    // cl(zInd)
    let gwType=gw?.gatewayType||1800
    
    let s=updateSensor(this,gwType,pa.pageType,pa.site,zInd)
    // cl(s)
    
    let i=0
//     let s={name:"temperature",id:"int",}
      let ga=this.setGauge(s)
      let sensorBoxClass={lo:"low-alarm-on",hi:"high-alarm-on",
        lohi:"low-alarm-on high-alarm-on"}[s.al]||""
      let stageClass=(s.hilo)?((s.hilo=="hi")?"heat":"cool"):""
    return(
      <div style={{float: "left"}}>
      <div className="sensor-grid">
      <C18Anchor00 to="" key={i} className={`sensor-box ${sensorBoxClass}`} aria-label={s.name} onClick={e=>this.sensorClick(e,s.id)}>
        <div className="name">{s.name}</div>
        <Gauge2 config={ga}/>
        <div className={`stage ${stageClass}`}>{s.foot}</div>
        <div className="low-alarm alarm-icon">i</div>
        <div className="high-alarm alarm-icon">i</div>
      </C18Anchor00>
      </div>
      </div>
    )
  }
  
/************** End Code to Display Guage *********************/
  
  
  showFui=()=>{
//     cl(this.props.parms)
    let pa=this.props.parms
//     cl(pa)
    let pt=pa.pageType
    if(["ec","ph"].includes(pt.substring(2,4))){pt=pt.substring(0,4)}
    let unit=+pt.substring(1,2)
    let sid=sensorIds[pt]//pa.pageType
//     cl(sid)
    if(!sid){return}
//     cl(sensorIds)
//     cl(sid)
//     cl(pt)
//     cl(pa.pageType)
    let mapId=sid.map
    let calId=sid.cal
    let sInd=sid.sensorIndex
    let PageName = sid.name
//     cl(sInd)
//     cl(globs.zonesInfo.info)
    let zInd=globs.zonesInfo.info[getZoneIndex(pa.zone)].siteZoneIndex
//     cl(zInd)
    let pageType=(sid.type=="addedSensor")?"added_sensor":"intemp_sensor"
    let chan=(sid.type=="addedSensor")?sid.c:240
//     cl(sid)
//     cl(pageType)
//       engaging fui
    return(
      <div>
      <LiveFui parms={{
        getPopup:o=>this.getPopup("admin",o),
        mode:"c18",
        onChange:o=>this.onChange("fui",o),
        pageModified:false,
        overrides:{mapping:mapId,calibration:calId}
//         pidOverride: sensorIds[this.props.parms.pageType].id
      }} match={{
        params:{
          pageType:pageType,
          zuci:`${zInd}-${unit}-240-${sInd}`,
          url:`/usa/c18/fui/intemp_sensor/0-${unit}-240-${sInd}`,
          pageTitle:PageName
        }
      }}
      notify={this.props.notify}
      />
      </div>
    )
  }
  
  showAlarms=()=>{
    let p=this.props.parms
//     cl(p)
    return(
      <div>
      {this.showSensor()}
      <C18AlarmLog00 parms={{
        site:p.site,
        zone:p.zone,
        sensor:p.pageType,
        onChange:this.onChange,
        mode:"embedded",
      }}
      notify={this.props.notify}
      />
      </div>
    )
  }

  goToManageAlarms=()=>{
    let url = `/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/sensor/${this.props.parms.pageType}/manageAlarms2/levels`
    history.push(`${url}`)
    this.props.parms.onChange({cmd:"levels", data:{savePage:true}})
  }
   
  render(){
//               <button type="button" className="outlined left-margin">Cancel and Return</button>
//     cl(((dbVals.z[0]||{})[240]||{})[5202])
//     cl(globs.userData.session.siteId)
    let showSuper=privs("super","",constant.AREA_PRIVS_READ)
    if(this.state.loaded){
      let st=this.state
//       cl(st)
//       cl(st)
      return(
        <div id="zone/sensorTile">
        {this.showGraph()}
        {this.showAlarms()}
        {this.showFui()}
        <br></br>
        <C18Button00 type="button" className="filled"
        onClick={()=>this.goToManageAlarms()}>Manage Alarms</C18Button00>
        </div>
      );
    } else{
      return <div>loading...</div>
    }
  } 
}
  
export default C18Sensor00 ;
 
