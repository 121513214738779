import React from 'react';
import C18Button00 from './C18Button00'
import {RGBtoHSV,HSVtoRGB,loadTagsInfo} from './C18utils'
import CreatableSelect from 'react-select/creatable'
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';
import {colorToRGB}from'../../visualization/utils/utils'
import {wsTrans} from '../utils/utils'

class C18Tags01 extends React.Component{
  constructor(props) {
    super(props);
//     cl(globs.tagsInfo.info)
//     cl(props)
    let filterTags=(props.value||[]).map(t=>{return{value:t,label:t}})
//     cl(filterTags)
    this.state={
      loaded:true,
      tags:Object.assign({},globs.tagsInfo.info),
//       tags:Object.assign(globs.tagsInfo.info),
      filterTags:filterTags,
      editTags:false,
      tagName:"",
      hue:0,
      sat:0,
      val:0,
    }
    this.oldTags=this.state.tags
//     this.loadInfo()
  }

  setMyState=(location,st)=>{
//     cl(location,st)
    this.setState(st)
  }
  
  componentDidMount=()=>{
//     cl("did mount")
    if(this.props.notify){this.props.notify({id: "useTags", func: this.useTags})}
    if(this.props.notify){this.props.notify({id: "setTags", func: this.setTags})}
    this.subscribe_editTags=globs.events.subscribe("editTags",this.editTags)
  }
  
  componentWillUnmount=()=>{
//     cl("will unmount")
    this.subscribe_editTags.remove()
    if(this.props.notify){this.props.notify({id: "useTags",func:this.useTags,unMount:true})}
    if(this.props.notify){this.props.notify({id: "setTags",func:this.setTags,unMount:true})}
  }
  
  useTags=(cmd,data)=>{
//     cl("save tags")
    this.saveTags()
  }
  
  setTags=(cmd,data)=>{
    let filterTags=(data||[]).map(t=>{return{value:t,label:t}})
    this.setMyState("setTags",{filterTags:filterTags})
  }
  
  editTags=async()=>{
    function v2i(v){return Math.round(16*v)}// value to index
    let st=this.state
    cl(st.filterTags)
    if(st.filterTags.length){
      let tag=st.filterTags[0].value
      let color=st.tags[tag].color
      let rgb=colorToRGB(color)
      let hsv=RGBtoHSV(rgb[0],rgb[1],rgb[2])
      let rgb2=HSVtoRGB(hsv.h,hsv.s,hsv.v)
      await this.setMyState("editTags",{editTags:true,
        hue:v2i(hsv.h),sat:v2i(hsv.s),val:v2i(hsv.v),})
    }
  }
  
//   loadInfo=async()=>{
//     await loadTagsInfo()
// //     cl("loadInfo")
// //     let stockTags={
// //       equipment:{color:"#3c6478"},
// //       sensors:{color:"#107896"},
// //       plants:{color:"#21B52D"},
// //       pests:{color:"#000"},
// //       nutrients:{color:"#da621e"},
// //       power:{color:"#c02f1d"},
// //       personnel:{color:"#bca136"},
// //       supplies:{color:"#43abc9"},
// //       maintenance:{color:"#613fb5"},
// //       other:{color:"#829356"},
// //     }
//     
// //     let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/tags", method: "retrieve", 
// //       sessionId: globs.userData.session.sessionId, body: {}})
// //     let tags={}
// //     let gti=globs.tagsInfo.info
// //     Object.keys(gti).forEach(k=>{
// //       tags[k]={color:gti[k].color}
// //     })
//     let tags=Object.assign(globs.tagsInfo.info)
//     
// //     if(res.data.length>0){
// //       res.data.forEach(re=>{
// //         tags[re.tag]={color:re.color}
// //       })
// //       this.oldTags=tags
// //     }else{
// //       tags=stockTags
// //       this.oldTags={}
// //     }
// //     cl(tags)
//     this.setMyState("loadInfo",{loaded:true,tags:tags})
//   }
  
  makeTagOptions=()=>{
    let st=this.state
//     cl(Object.keys(st.tags).length,st.tags)
//     cl(Object.keys(st.tags)[2])
    let opts=[]
    Object.keys(st.tags).forEach(k=>[
      opts.push({value:k,label:k})
    ])
//     cl(opts)
    return opts
  }
  
  saveTags=async()=>{// add new tags that have been used
// this should update globs.tagsInfo
//     cl(globs.tagsInfo.info)
    let tagsToSave=[]
    let st=this.state
//     cl(st)
//     cl(this.oldTags)
//     cl(st.tags)
    Object.keys(this.state.tags).forEach(k=>{
      if((this.oldTags[k]||{}).color!=st.tags[k].color){
        tagsToSave.push({tag:k,color:st.tags[k].color})
      }
    })
//     cl(tagsToSave)
    tagsToSave.forEach(t=>{ globs.tagsInfo.info[t.tag]=t})//{tag:t,color:"556677" }
    if(tagsToSave.length){
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/tags", method: "update", 
        sessionId: globs.userData.session.sessionId, body: {tags:tagsToSave}})
      globs.events.publish("tagsUpdate")
    }
//     cl("publish")
  }
  
//   makeChartElements=()=>{
// //{value: label:}
//     return []
//     
//   }

  saveTag=()=>{// update a single tag
    let st=this.state
    cl(st)
    let rgb=HSVtoRGB(st.hue/16,st.sat/16,st.val/16)
    let color=this.rgbToCol(rgb)
    let tag=st.filterTags[0].value
    let query={tags:[{tag:tag,color:color}]}
    wsTrans("usa", {cmd: "cRest", uri: "/s/tags", method: "update", 
      sessionId: globs.userData.session.sessionId, body: query})
//     cl([tag,color])
    let tags=Object.assign({},st.tags)
    let ta={color:color}
    tags[tag]=ta
    cl(tag)
    globs.tagsInfo.info[tag]=ta
    this.setMyState("saveTag",{tags:tags})
    globs.events.publish("tagsUpdate")
//     cl("publish")
  }
  
  deleteTag=()=>{
    let st=this.state
    let tag=st.filterTags[0].value
    let query={tag:tag}
    wsTrans("usa", {cmd: "cRest", uri: "/s/tags", method: "delete", 
      sessionId: globs.userData.session.sessionId, body: query})
    cl(st.filterTags[0])
    let filterTags=st.filterTags.slice(0)
    filterTags.splice(0,1)
    cl(filterTags[0])
//     filterTags.splice(0,1)
    let tags=Object.assign({},st.tags)
    delete tags[tag]
    this.setMyState("deleteTag",{tags:tags,filterTags:filterTags})
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    let st=this.state
//     cl(st)
    let pa=this.props.parms
    switch(type){
      case "chartElements":
//         cl(vals)
        let adds={}
        let vals2=vals.map(v=>{return v.value})
        pa.onChange(vals2)
        for(let i=0;i<vals.length;i++){
          let v=vals[i]
          if(!st.tags[v.label]){// assume that only one can be new
            let tags=Object.assign({},this.state.tags)
            tags[v.label]={color:"#556677"}
            this.setMyState("chartElements",{filterTags:vals,tags:tags})
            return
          }
        }
        this.setMyState("chartElements2",{filterTags:vals,editTags:false})
        break
//       case "filter":
//         this.saveTags()
//       case "cancel":
//         vals.cmd=type
//         pa.onChange(vals)
//         break
      case "delete":
        this.deleteTag()
        this.setMyState("delete",{editTags:false})
        break
      case "save":
        this.saveTag()
      case "cancel":
        this.setMyState("cancel",{editTags:false})
        break
      case "tagName":
        this.setMyState("tagName",vals)
        break
      case "setHue":
//         cl(vals)
        this.setMyState("setHue",vals)
        break
    }
  }
  
  rgbToCol=(rgb)=>{
//     cl((13).toString(16))
//     cl(rgb)
    let col=(rgb.r<<16)+(rgb.g<<8)+rgb.b
    let col2="00"+col.toString(16)
    return `#${col2.slice(-6)}`
  }
  
  showColorSelect=()=>{
    let st=this.state
//     cl(st)
    let rows=[]
    let cols=[]
    let col2=[]
    for(let j=0;j<16;j++){
      let rgb=HSVtoRGB(j/16,1,1)
      let bs=(j==st.hue)?"solid":""
      cols.push(
        <td key={j} style={{width:10,height:10,backgroundColor:this.rgbToCol(rgb),
          borderWidth:1,borderStyle:bs,
        }}
          onClick={e=>this.onChange("setHue",{hue:j/*/16*/})}
        ></td>
      )
      col2.push(<td key={j} style={{height:10}}></td>)
    }
//     rows.push(cols)
    rows.push(<tr key="hue">{cols}</tr>)
    rows.push(<tr key="blank">{col2}</tr>)
    for(let i=0;i<16;i++){
      let cols=[]
      let sat=(15-i)///16
      for(let j=0;j<16;j++){
        let val=j///16
//         cl(st.hue,sat,val)
        let rgb=HSVtoRGB(st.hue/16,sat/16,val/16)
        let color=this.rgbToCol(rgb)
        let bs=((sat==st.sat)&&(val==st.val))?"solid":""
//         cl(color)
        cols.push(
          <td key={j} 
            style={{width:10,height:10,backgroundColor:this.rgbToCol(rgb),
              borderWidth:1,borderStyle:bs,
            }}
            onClick={e=>this.onChange("setHue",{val:val,sat:sat})}
          ></td>
        )
      }
      rows.push(<tr key={i}>{cols}</tr>)
    }
    return(
      <div style={{marginLeft:10}} className="floatright">
      <table><tbody>
      {rows}
      </tbody></table>
      </div>
    )
  }
  
  showEditTags=()=>{
// delete, rename, and color select
    let st=this.state
//     cl(st)
    let tag=st.filterTags[0]?.value
    if(st.editTags&&(st.filterTags.length>0)){
      let textColor=(st.val>13)?"black":"white"
//       cl([st.hue,st.sat,st.val])
      let rgb=HSVtoRGB(st.hue/16,st.sat/16,st.val/16)
//       cl(rgb)
      let color=this.rgbToCol(rgb)
//       cl(color)
      return(
        <div style={{
          position:"absolute",
//           width:400,
//           height:300,
          backgroundColor:"white",
          borderRadius:10,
          boxShadow:'5px 10px 10px #888888',
          padding:10,
          zIndex:4,
        }}>
        <div>
          <input type="text"
            style={{width:"100%",color:textColor,backgroundColor:color,height:44}}
            value={tag}
            onChange={e=>this.onChange("tagName",{tagName:e.currentTarget.value})}
          />
          <div className="clearfloat"></div><br/>
        </div>
        <div className="floatleft">
            <C18Button00 type="button" className="outlined danger"
              onClick={e=>this.onChange("delete",{})}>Delete</C18Button00>
            <div className="clearfloat"></div><br/>
            <C18Button00 type="button" className="outlined"
              onClick={e=>this.onChange("save",{})}>Save</C18Button00>
            <div className="clearfloat"></div><br/>
            <C18Button00 type="button" className="outlined"
              onClick={e=>this.onChange("cancel",{})}>Cancel</C18Button00>
        </div>
        {this.showColorSelect()}
        </div>
      )
    }else{return null}
  }
  
  
  render(){
  //       className="image-popup"
    let st=this.state
    let pa=this.props.parms
//     cl(st)
//     cl(pa)
//     let filterTags=(props.value||[]).map(t=>{return{value:t,label:t}})
//     cl(this.state.hue)
//     cl(st)
    if(st.loaded){
      let selId=(st.filterTags.length>0)?`{"cmd":"tags","type":0}`:"tags"
      return(
        <div className="chart-elements-container">
          <div className="floatleft"  style={{width:250}}>
            <CreatableSelect
              id={selId}
              value={this.state.filterTags}
              onChange={o=>{this.onChange("chartElements",o)}}
              options={this.makeTagOptions()}
              isMulti={true}
            />        
          </div>
          <div className="clearfloat"></div><br/>
          {this.showEditTags()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18Tags01;
