import React from 'react';
import C18Button00 from './C18Button00'
import {getParamId2,wsTrans} from '../utils/utils'
import {dbVals,putZValue,sendArray,getZoneControllers} from '../../components/utils/http';
import {loadSitesInfo,loadZonesInfo,loadSensorsInfo,getZoneIndex,loadXBoards,getSiteName,
  getZoneName,getZoneInfo} from './C18utils'
import {pi,pb,pInd,dpNames} from '../../components/utils/paramIds';
import {makeSensorList,getSetSensorName,fixSensorList} from '../utils/sensorNames'
import {cl,globs,getTimeI} from '../../components/utils/utils';

class C18AutoSetup00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      csv:""
    }
    this.chanTypes={
      none:0,
      onoff:1,
      irr:2,
      co2:3,
      light:4,
      mzone:5,
      pump:6,
      fvalve:7,
      vent:8,
      curt:9,
      mixv:10,
      mzone2:11,
      pid:12,
      varout:18,
      vent2:19,
      curt2:20,
      mixv2:21,
      mzone2:22,
      pid2:23,
      cravo2:24,
      mixva:28,
    }
    this.sensorIds=["inTemp","outTemp","inHum","","outLite","wndSpd","bHeat1","bHeat2","bHeat3",
      "bHeat4","bHeat5","fbInTemp","wndDir","soil1","soil2","soil3","soil4","soil5","rain",
      "irrTrig","diffP","inLite","","","","","","","","","","","ventP1","ventP2","ventP3",
      "ventP4","ventP5","","","","","","generic1","generic2","outHum","locInTemp","locInHum",
      "snow","bpTemp","baroP","volt1","volt2","volt3","volt4","volt5","volt6","volt7",
      "volt8","exTemp1","exTemp2","exTemp3","exTemp4","exTemp5","exTemp6","exTemp7",
      "exTemp8","exTemp9","exTemp10","exTemp11","exTemp12","exTemp13","exTemp14","exTemp15",
      "exTemp16","exTemp17","exTemp18","exTemp19"]
    this.sensorMap=["none","ser1","ser2","ana1","ana2","ana3","ana4","ana5","ana6","ana7",
    "ana8","fDi1","fDi2","fDi3","dig1","dig2","dig3","dig4","dig5","dig6","dig7","dig7",
    "sft","rem","xa1","xa2","sMd","dth","sMB","moB"]
    this.zoneChans=["configuration_setpoints","configuration_zone_settings","configuration_zones"]
    this.zoneNameId=getParamId2(1900,"config_zones","name")
    cl(this.zoneNameId)


    this.loadInfo()
    this.setBreadCrumbs()
  }

  setBreadCrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: [
            {t:"Sites", url:"/usa/c18/sites"},
            {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
            {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
            {t:`Sensor Settings`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/settings/sensor1900`},
            {t:`Auto Setup`, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/admin/autoSetup`},
          ]},
      },
    )
  }

  makeTempLookups=()=>{
    this.tempLU={
      gSense:{
        mapping:getParamId2(1900,"pearl_gen_sens_conf","mapping"),
        sensorType:getParamId2(1900,"pearl_gen_sens_conf","sensorType"),
        board:getParamId2(1900,"pearl_gen_sens_conf","board"),
        channel:getParamId2(1900,"pearl_gen_sens_conf","channel"),
        mult:16,
      },
      sensor:{
        mapping:getParamId2(1900,"pearl_sensor_config","mapping"),
        board:getParamId2(1900,"pearl_sensor_config","board"),
        channel:getParamId2(1900,"pearl_sensor_config","channel"),
        mult:3,
      },
      channel:{
        channelName:507,
        channelType:508,
        irrigation_mode:691,
        light_mode:697,
        lightStart1:758,
        lightStart2:759,
        lightStart3:760,
        lightStart4:761,
        lightStart5:762,
        lightStart6:763,
        vent_mode:797,
      },
      equip:{
        irrigation_mode:691
      },
      chModes:{
        irr_sched:0,
        irr_accumLight:1,
        irr_cycle:2,
        irr_trigger:3,
        irr_soilTrigger:4,
        irr_vpd:5,
        light_supplemental:0,
        light_sched:1,
        light_cycle:2,
        light_dli:3,
      },
      genSenseTypes:{none:0, generic:1, software:2, temp:3, hum:4, dhtTemp:5, dhtHum:6,
        rain:7, wndspd:8, wnddir:9, volt:10, light:11, bHeat:12, moist:13, diffP:14},
    }
    this.chModes=["irrigation_mode","light_mode","vent_mode"]

  }

  makeTestInfo=()=>{
//     this.inCsv="0\tgSense\n1\tgSense\n2\tgSense\n3\tgSense\n"
//     this.cfgCsv="inp\t0\t14\t58\n"
// gSense\t15\tmapTo\t3\n
    this.cfgCsv="channel\t4\tchannelName\tIrrigation Valve 11\nchannel\t4\tchannelType\t2\n\channel\t4\tirrigation_mode\t4\n"
//     "gSense\t15\tsensorType\t13\n"

  }

  makeIoChans=(xB)=>{
    let inputs={}
    let outputs={}
    let baseChan=0
//     cl(xB)
    xB.forEach((x,i)=>{
      if(x.numInps){
        for(let j=0;j<x.numInps;j++){
          inputs[x.startInp+j]={
//             inCh:x.startInp+j,
            bd:i,
            ch:j,
            ty:0,
          }
//           inputs.push(
//           )
        }
      }
      if(x.numOuts){
        for(let j=0;j<x.numOuts;j++){
          outputs[x.startCh+j]={
//             ouCh:x.startCh+j,
            bd:i,
            ch:j,
            ty:0,
          }
//           outputs.push({
//                )
        }
      }
//       cl(x)
    })
//     cl(inputs)
//     cl(outputs)
    this.inputs=inputs
    this.outputs=outputs
  }

  createSensors=async()=>{
    let pa=this.props.parms
    let sensorList=makeSensorList("selectSensors")
    let arr={}
//     cl(sensorList)
    fixSensorList(this.zoneControllers,sensorList)
//     cl(sensorList)
    await getSetSensorName("","",false,arr,sensorList)
//     cl(arr)
    let query={zoneId: pa.zone, siteId:pa.site, sensorNames:arr}
//     cl(query.sensorNames)
//     await wsTrans("usa", {cmd: "cRest", uri: "/s/sensors", method: "update",
//       sessionId: globs.userData.session.sessionId,
//       body: {zoneId: pa.zone, siteId:pa.site, sensorNames:arr}})
    return {sensorNames:arr}
  }

  loadInfo=async()=>{
    let pa=this.props.parms
//     cl(pa)
    this.makeTestInfo()
    this.makeTempLookups()
    await loadZonesInfo()
    await loadSensorsInfo()
    let zi=getZoneInfo(pa.zone)
//     cl(zi)
    let zInd=zi.siteZoneIndex
//     let zInd=0
    this.zoneControllers=getZoneControllers(zInd)
//     cl(globs.sensorsInfo.info)
    this.sensors=globs.sensorsInfo.info.filter(se=>{
      return (se.siteId==pa.site)&&(se.zoneId==pa.zone)})[0]
    if(!this.sensors){this.sensors=await this.createSensors()}
    cl(this.sensors.sensorNames)
    this.zInfo=globs.zonesInfo.info[getZoneIndex(this.props.parms.zone)]
    this.gwType=this.zInfo.gatewayType||1800
    this.chanConfig=pi[this.gwType].channels_configuration
    this.chanData=pi[this.gwType].config_channels_configuration
    this.xBoards=loadXBoards(this,this.zInfo)
    this.makeIoChans(this.xBoards)
    cl(dbVals.z[0][240])
    cl(dbVals.z[0][240][20535])
    let res=putZValue(0,240,20535,0)
    cl(dbVals.z[0][240][20535])
    cl(res)
//     cl(this.xBoards)
  }

  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "autoSetup":
        this.doAutoSetup()
        break
      case "upd":
        this.setState(vals)
        break
    }
  }

  readCsv=(csv)=>{
    let lines=csv.split("\n")
    return lines.map(l=>{return l.split("\t")})
  }

  saveSensorNames=async()=>{
//     cl("save sensor names")
    let pa=this.props.parms
    cl(this.sensors.sensorNames)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/sensors", method: "update",
      sessionId: globs.userData.session.sessionId,
      body: {zoneId: pa.zone, siteId:pa.site, sensorNames:this.sensors.sensorNames}})
  }

  saveToController=(arr)=>{
// function sendArray(arr,virtual,gwType,controllerId,localOnly){
//     cl(arr)
    sendArray(arr,false,1900,null,false)
    if(this.sensorNamesModified){this.saveSensorNames()}
  }

//   l2c[lc.setup_t_InputMap]=[pi.p.PID_1900_BASE_PEARL_SENSOR_CONFIG + lc.PCSEN_mapping, 3, 3];// type 3, like setPoint, with 3 values
//    p.PID_1900_BASE_PEARL_SENSOR_CONFIG=0x4400// 512 values, 128 entries of 3 values
// 0x4400 = 17408
//    pInd[1900].pearl_sensor_config = [p.PID_1900_BASE_PEARL_SENSOR_CONFIG, 0, 3, 128];



  procOneLine=(arr,line,zInd,now)=>{
    var putVal=(z,c,i,d)=>{
      let force=true
      if(putZValue(z,c,i,d)||force){
        arr.push({
          c:c,
          d:d,
          f:1,
          i:i,
          t:now,
          z:z,
        })
      }
    }
/****************************************/
    var procGSense=()=>{
//       cl(this.inputs)
//       cl(val)
        switch(key){
          case "mapTo":// gSense	48	mapTo	60  1
// ch is the genericSensor id, val is the input channel to use
            if(val>=0){// starting channel = -1 means remove genSense
              cl(val,val2)
              val=(+val)+(+val2)-1
              let inpInfo=this.inputs[val]
              cl(val)
              cl(inpInfo)
              if(inpInfo){
                putVal(zInd,240,+lu.board+lu.mult*ch,inpInfo.bd)
                putVal(zInd,240,+lu.channel+lu.mult*ch,inpInfo.ch)
                putVal(zInd,240,+lu.mapping+lu.mult*ch,29)
              }
            }else{
              putVal(zInd,240,+lu.board+lu.mult*ch,0)
              putVal(zInd,240,+lu.channel+lu.mult*ch,0)
              putVal(zInd,240,+lu.mapping+lu.mult*ch,0)
              putVal(zInd,240,+lu["sensorType"]+lu.mult*ch,0)
            }
            break
          case "sensorName":
//             cl(val)
            let id=`e0gn${ch}`
            cl(id)
            cl(this.sensors)
            this.sensors.sensorNames[`e0gn${ch}`]=val
//             cl(id)
//             cl(this.sensors)
            this.sensorNamesModified=true
            break
          case "sensorType":
            val=this.tempLU.genSenseTypes[val]||val
          default:
            putVal(zInd,240,+lu[key]+lu.mult*ch,val)
            break
        }
    }
/****************************************/
    var procSensor=()=>{
      cl(ch,key)
      cl(lu)
//       cl(this.sensorIds)
      let ind=this.sensorIds.findIndex(x=>x==ch)
      cl(ind)
      switch(key){
        case "map":
          let mapInd=this.sensorMap.findIndex(x=>x==val)
          cl(mapInd)
          putVal(zInd,240,+lu.mapping+lu.mult*ind,mapInd)
          break
        case "mapTo":
          cl(val,val2)
          val=+val+(+val2)-1
          let inpInfo=this.inputs[val]
          cl(val,inpInfo)
          putVal(zInd,240,+lu.board+lu.mult*ind,inpInfo.bd)
          putVal(zInd,240,+lu.channel+lu.mult*ind,inpInfo.ch)
          cl(val)
          break
        default:
          break
      }
    }
/****************************************/
    var procWeekSched=(sched)=>{
      var schedPids=[184,182,186,187,185,181,183,191,189,193,194,192,188,190]
      var base=pb[this.gwType].config_channels_configuration
      let i1=0
      for(let i=0;i<sched.length;i++){
        let char=sched[i]
        if(char!=" "){
          let val=(char=="x")?0:1
          let pid=base+schedPids[i1]
          putVal(zInd,+ch,pid,val)
          i1+=1
        }
      }
    }
/****************************************/
    var procChannel=()=>{
      let pid=+lu[key]
      if(key=="channelType"){
        this.chType=val
        val=this.chanTypes[val]||val}
      if(key=="week_sched"){
        return procWeekSched(val)
      }
      if(this.chanConfig[key]){// if a channelData key
//         cl(key)
        pid=getParamId2(this.gwType,"configuration_channels",key)
      }
      if(this.chanData[key]){// if a channelData key
        if(this.chModes.includes(key)){
          let chMode=this.tempLU.chModes[`${this.chType}_${val}`]
          val=this.tempLU.chModes[`${this.chType}_${val}`]||val
        }else{
          pid=getParamId2(this.gwType,"configuration_channel_data",key)
//           cl(pid)
        }
      }
      putVal(zInd,+ch,pid,val)
    }
/****************************************/
    var putZoneName=async(name)=>{
      let body={zoneId: this.zInfo.zoneId,zoneName:name}
      await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update",
        sessionId: globs.userData.session.sessionId,
        body: body})
      this.zInfo.zoneName=name
    }
/****************************************/
    var procTabCol=()=>{
// type is table, ch is column, key is the index, val is val
      let type2=dpNames[type]
      let tab=pInd[this.gwType][type2]
      let pid=tab[0]+tab[2]*(+key)+pi[this.gwType][type2][ch]
      let chan=(this.zoneChans.includes(type))?255:240
      if(pid==this.zoneNameId){putZoneName(val)}
      putVal(zInd,chan,pid,val)

    }
/****************************************/
    let [type,ch,key,val,val2,val3]=line
    cl(type,ch,key,val,val2,val3,arr.length)
    if(!isNaN(ch)){ch=+ch-1}// ch in the CSV is 1-based
    let lu=this.tempLU[type]
    if(dpNames[type]){
      cl("got table name")
      procTabCol()
    }else{
      switch(type){
        case "gSense"://['gSense', '13', 'mapTo', '0']
          procGSense()
          break
        case "sensor":
          procSensor()
          break
        case "channel":
          procChannel()
          break
      }
    }
  }

  procCsv=async(cfg)=>{
    let cfgLengths={mapTo:1}// only those values that are *not* 2: key:val
    var procComboLine=(line)=>{
      while (line.length>3){
//         cl(line)
        this.procOneLine(arr,line,zInd,now)
        let cfgSize=(cfgLengths[line[2]]||0)+2
        line.splice(2,cfgSize)
      }
    }

    let arr=[]
    let now=getTimeI()
    let zInd=this.zInfo.siteZoneIndex
    this.sensorNamesModified=false
    cfg.forEach(c=>{
      if(c.length>=4){
        procComboLine(c)
      }
    })
    cl(arr)
    await this.saveToController(arr)
    if(arr.length>0){
      let res=await this.props.parms.getPopup({text:"New Settings have been Accepted", buttons:["OK"]})
    }
  }

  doAutoSetup=()=>{
    let cfg=this.readCsv(this.state.csv)
    this.procCsv(cfg)
  }

  showAutoButton=()=>{
    let st=this.state
    return(
      <div>
      <h3>Paste CSV Data Here:</h3>
        <textarea
          style={{width: 500,height:300}}
          value={st.csv}
          onChange={e=>this.onChange("upd",{csv:e.currentTarget.value})}
        /><br/>
        <C18Button00 type="button" className="danger"
          onClick={e=>{this.onChange("autoSetup")}}
        >Auto Setup</C18Button00>
      </div>
    )
  }


  render(){
    return(
      <div>
      {this.showAutoButton()}
      </div>
    )
  }
}

export default C18AutoSetup00;
