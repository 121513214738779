import React from 'react';
import UsaIcon from './UsaIcon';
import IconButton from '@material-ui/core/IconButton';
import UsaSelect00 from './UsaSelect00'
import Camera from '@material-ui/icons/Camera';
import {wsTrans,doGetPostBasic} from '../utils/utils'
import {cl, globs, getTime,constant,getRandomString} from '../../components/utils/utils';

class CreateMessage00 extends React.Component{
  constructor(props) {// 
    super(props);
//     cl(props)
    this.state={
      subject: "",
      body: "",
      to: "",
      images:[],
      loaded: false,
    }
    this.loadUsers()
  }
  
  loadUsers=async()=>{
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {}})
//     cl(r.data)
    this.users=r.data
    this.userOpts={}
    this.users.forEach(u=>{
      this.userOpts[u.userId]=u.name
    })
    this.setState({loaded: true, to: this.users[0].userId})
  }
  
  onChange=(type,vals)=>{
//     cl([type,vals])
    this.setState(vals)
  }
  
  result=(type)=>{
//     cl(type)
    switch(type){
      case "Message-OK":
        this.saveMessage()
      case "Message-Cancel":
        this.props.onChange({showCreateMessage: false})
        break
      default:
        break
    }
  }
  
  userSelect=()=>{
    return(
      <UsaSelect00 parms={{
        title: "To:",
        select: true, // !this.state.editMode,
        valueId: "to",//valueId, // "widgetSel",
        value: this.state.to, //value, // this.state.widgetSel,
        opts: this.userOpts,
        onChange: (vals)=>this.onChange("to", vals),
      }}/>
    )
  }
  
  subjectEnter=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Message\u00A0Subject",
        select: false, // !this.state.editMode,
        valueId: "subject",//valueId, // "widgetSel",
        value: this.state.subject, //value, // this.state.widgetSel,
//         textId: "widgetName",
        onChange: (vals)=>this.onChange("subject", vals),
      }}/>
    )
  }
  
  bodyEnter=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Message\u00A0Body",
        multiline: true,
        rowsMax: 8,
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "body",//valueId, // "widgetSel",
        value: this.state.body, //value, // this.state.widgetSel,
//         textId: "widgetName",
        onChange: (vals)=>this.onChange("body", vals),
      }}/>
    )
  }
  
  sendImages=()=>{
    if(this.state.images.length){
      let data = new FormData()
      this.state.images.forEach((img,i)=>{
        data.append(`id${i}`, img.id)
        data.append(`file${i}`, img.image)
      })
      let url=`${constant.expressUrl}/usa/images`
      let method="POST"
      let type="multipart/form-data"
      cl(url)
      return doGetPostBasic(url, method, data, type)// promise
    }
  }
  
  markImage=(e)=>{
    let expImage={id: getRandomString(16), image: e.target.files[0]}// expanded image, w/ our name
    let images=this.state.images.slice(0)
    images.push(expImage)
    cl(images)
    this.setState({body: this.state.body+`{File:${expImage.id}}`, images: images})
  }
  
  saveMessage=async()=>{
    let images=[]
    this.state.images.forEach(img=>{
      images.push({name: img.image.name, id: img.id, size: img.image.size})
    })
    let r=await this.sendImages()
    let r2=await wsTrans("usa", {cmd: "cRest", uri: "/s/messages", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: {
        threadId: getRandomString(16),
        to: this.state.to,
        body: this.state.body,
        subject: this.state.subject,
        modTime: Math.floor(getTime()),//Math.floor(this.state.dispTime.getTime()/1000),
        images: images,
    }})
    cl(r2)
  }
  
  showCreateMessage=()=>{
    return(
      <div>
      <h3 style={{marginLeft: 10}}>New Message
      <UsaIcon icon="Message-OK" result={this.result} inline/>
      <UsaIcon icon="Message-Cancel" result={this.result} inline/>
      <IconButton
        variant="contained"
        component="label"
        title="Upload Image"
      >
        <Camera/>
        <input hidden multiple type="file" onChange={this.markImage}/>
      </IconButton>
      </h3>
      <div style={{height: 10}}/>
      {this.userSelect()}
      {this.subjectEnter()}
      {this.bodyEnter()}
      </div>
      
    )
  }
    
  render(){
//     cl(this.state)
    if(this.state.loaded){
      return this.showCreateMessage()
    }else{
      return <div>loading...</div>
    }
  }
}

export default CreateMessage00 ;

