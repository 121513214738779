import React from 'react';
import {getParamId2,wsTrans} from '../utils/utils'
import {cl,globs,getLocalStorage, constant} from '../../components/utils/utils';
import {getSuperUserFlags} from './C18utils'
import C18Button00 from './C18Button00'


class C18QrCode00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
        logInfo:"start 2\n",
        code: null
    }
//     this.logInfo="starting"
    this.sendQrCode()
  }

  cl2=(str)=>{
    this.setState({logInfo:this.state.logInfo+=str+"\n"})
//       this.logInfo+=str
  }

  sendQrCode=async()=>{
    let session=getLocalStorage("session")
    this.cl2(this.props.parms.search)
    let parts=this.props.parms.search.substring(1).split("&")
    parts.forEach(p=>{
        let parts2=p.split("=")
        if(parts2[0]=="code"){
          cl("code sent")
            wsTrans("usa", {cmd: "cRest", uri: "/s/qrcodeListeners", method: "update",
            sessionId: globs.userData.session.sessionId,body: {code:parts2[1]}})
            this.state.code = parts2[1] 
            this.cl2("code sent")
        }
    })
}

  render(){
    cl(this.state)
    cl(this.state.code)
    return(
      <div>
        {this.state.code != null && 
          <div>
            <div>
              <b>Success!</b> QR code with ID <i>{this.state.code}</i> has been sent. 
            Make sure you're logged in with the same user and check the QR code receiver on your desktop PC.
            </div>
            <br/>
            <C18Button00 type="button" className="outlined" onClick={e=>this.sendQrCode()}>
              Resend QR
            </C18Button00>
          </div>
        }
        {getSuperUserFlags()&(constant.SUPER_PRIVS_ADMIN) && 
          <pre>
          {this.state.logInfo}
          </pre>
        }
      </div>
    )
  }
}

export default C18QrCode00;
