import React from 'react';
// import SettingsApplications from '@material-ui/icons/SettingsApplications';
import Message from '@material-ui/icons/Message';
import Icon from '@material-ui/core/Icon';
import UsaIcon from '../../usa/components/UsaIcon'
import parse from 'html-react-parser'
import {cl, globs, constant, makeShortDayDateStr} from '../../components/utils/utils';

class MessagingFeedEntry00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      closed: true,
    }
//     cl(props)
  }
  
  result=(type)=>{
    cl(type)
    switch(type){
      case "entry-More":
        this.setState({closed: false})
        break
      case "entry-Close":
        this.setState({closed: true})
        break
      case "entry-Reply":
        this.props.onChange({msgReply: true, entry: this.props.parms.entry})
        break
      default:
        break
    }
  }
  
  showClosedEntry=(path, dateStr, subject)=>{
    return(
      <table style={{textAlign: "left"}}><tbody>
      <tr><td><img src={path} height="40"/></td><td>
        <table><tbody>
        <tr><td>
        <Icon component={Message} style={{padding: 0, fontSize: 18, verticalAlign: "middle"}}/>
        <span style={{verticalAlign: "middle"}}>{dateStr}</span></td></tr>
        <tr><td>{parse(subject)}
        <UsaIcon icon={"entry-More"} result={this.result}/>
        </td></tr>
        </tbody></table>
      </td></tr>
      </tbody></table>
    )
  }
  
//   showBody=(entry)=>{
//     return(
//       <div>{entry.body}</div>
//     )
//   }

/******************Image Functions******************************/
  showBody=(entry)=>{
    let body=entry.body
    let parts=body.split("{File:")
    if (parts.length == 1) parts = body.split("{Img:")
    let sects=[]
    parts.forEach((p,i)=>{
      if(i>0){
        let p2=p.split("}")
//         let imageInfo=this.state.images[p2[0]]
        let imageInfo={w:100, h:50}
        sects.push({i:p2[0], t:p2[1], w: imageInfo.w, h: imageInfo.h, x: 100+50*i, y: 100+50*i})// x, y is where it will appear large
      }else{
        sects.push({i: false, t:p})
      }
    })
//     cl(sects)
    return sects.map((s,i)=>{
//       cl(s)
      var path
      if(s.i){
//         path=this.makeImagePath(s.i, this.state.images[s.i].ext)
        path=this.makeImagePath(s.i, "jpg")
      }
      return(
          <div key={i}>
          {s.i &&
            <img width="100" src={`${constant.expressUrl}/usa/images/uploads/${path}`}
              onClick={e=>{this.showImage(path, s)}}/>
          }
          <div>{s.t}</div>
          </div>
      )
    })
    return
  }
  
  makeImagePath=(id, ext)=>{
    return `${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`// .${ext}
  }
  
  showImage=(path,s)=>{
    cl(this.props)
//     cl(path)
//     cl(s)
    this.props.onChange({type: "showImage", path: path, section: s})
  }
//                 onClick={e=>{this.showImage(path, s)}}/>

/******************End of Image Functions******************************/
  
showOpenEntry=(path, dateStr, subject, entry)=>{
    return(
      <table style={{textAlign: "left"}}><tbody>
      <tr><td><img src={path} height="40"/></td><td>
        <table><tbody>
        <tr><td>
        <Icon component={Message} style={{padding: 0, fontSize: 18, verticalAlign: "middle"}}/>
        <span style={{verticalAlign: "middle"}}>{dateStr}</span>
        <UsaIcon space="1" icon={"entry-Close"} result={this.result}/>
        <UsaIcon space="0.5" icon={"entry-Like"} result={this.result}/>
        <UsaIcon space="0.5" icon={"entry-Reply"} result={this.result}/>
        <UsaIcon space="0.5" icon={"entry-Share"} result={this.result}/>
        </td></tr>
        <tr><td>{parse(subject)}
        </td></tr>
        <tr><td>
        {this.showBody(entry)}
        </td></tr>
        </tbody></table>
      </td></tr>
      </tbody></table>
    )
  }

  render=()=>{
    let entry=this.props.parms.entry
//     cl(entry)
    let av=entry.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
//     let name="none"//getDatapointName(entry.i)
    let dateStr=makeShortDayDateStr(entry.t)
//     let desc=`${name}: ${entry.d}`
    let subject=`<strong>${this.props.parms.entry.subject}</strong>`
    if(this.state.closed && !this.props.parms.reply){
      return this.showClosedEntry(path, dateStr, subject)
    }else{
      return this.showOpenEntry(path, dateStr, subject, entry)
    }
  }
}

export default MessagingFeedEntry00;
